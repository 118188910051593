import * as React from "react";
import styled from "styled-components";


const SpecialMarkerWrapper = styled.div`
  position: relative;
  display: flex;
  width: ${({ width }) => width ? width + "px" : "150px"};
  width: ${({ width }) => width ? width + "px" : "150px"};
  top: ${({ width }) => width ? "calc(-" + width + "px * 0.85)" : "calc(-150px * 0.85)"};
  left: -50%;
  transform-origin: 50% 85%;
  transition: transform 300ms ease-in-out, box-shadow 200ms ease-in-out;
  transform: scale(${({ mouse_in }) => mouse_in === true ? 1.1 : 1.0});

  & > div {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    & > svg {
      width: 100%;
      height: 100%;
    }
  }
`;

const SpecialMarkerCircle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  position: absolute;
  cursor: pointer;

  top: ${({ width }) => width ? (width * 0.18) + "px" : "27px"};
  left: ${({ width }) => width ? ((width * 0.65) / 2) + "px" : "48.75px"};
  width: ${({ width }) => width ? (width * 0.35) + "px" : "52.5px"};
  height: ${({ width }) => width ? (width * 0.35) + "px" : "52.5px"};

  border: 3px solid white;
  background-color: transparent;
  font-weight: bold;
  font-size: ${({ width }) => width ? (width * 0.1) + "pt" : "auto"};
`;

class SpecialMarker extends React.Component<{}> {
  state: {
    mouseIn: boolean,
    mouseDown: boolean,
  } = {
    mouseIn: false,
    mouseDown: false
  };

  render() {
    return (
      <SpecialMarkerWrapper mouse_down={this.state.mouseDown}
                            mouse_in={this.state.mouseIn}
                            width={this.props.width2 * 1.176}
                            {...this.props}
      >
        <div>
          <Icon/>
          <SpecialMarkerCircle width={this.props.width2 * 1.176}
                               onMouseEnter={() => this.setState({ mouseIn: true })}
                               onMouseLeave={() => this.setState({ mouseIn: false })}
                               onMouseDown={() => this.setState({ mouseDown: true })}
                               onMouseUp={() => this.setState({ mouseDown: false })}
                               onClick={() => this.props.handleShopClick && this.props.handleShopClick()}
          >
            {this.props.text}
          </SpecialMarkerCircle>
        </div>
      </SpecialMarkerWrapper>
    );
  }
}

class Icon extends React.Component<{}> {
  render() {
    return (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 117 152"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...this.props}
      >
        <g filter="url(#prefix__filter0_bd_320_1560)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M85.41 79.16A36.368 36.368 0 0095 54.5C95 34.342 78.658 18 58.5 18S22 34.342 22 54.5c0 10.504 4.437 19.972 11.54 26.632l25.366 43.935 26.503-45.906z"
            fill="url(#prefix__paint0_linear_320_1560)"
            shapeRendering="crispEdges"
          />
        </g>
        <defs>
          <linearGradient
            id="prefix__paint0_linear_320_1560"
            x1={22}
            y1={-2.5}
            x2={76}
            y2={136.5}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={"#61e3cd"}/>
            <stop offset={1} stopColor={"#4eb8a4"}/>
          </linearGradient>
          <filter
            id="prefix__filter0_bd_320_1560"
            x={-11}
            y={-15}
            width={139}
            height={173.067}
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix"/>
            <feGaussianBlur in="BackgroundImage" stdDeviation={16.5}/>
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_320_1560"
            />
            <feColorMatrix
              in="SourceAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy={4}/>
            <feGaussianBlur stdDeviation={11}/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix values="0 0 0 0 0.0705882 0 0 0 0 0.0705882 0 0 0 0 0.0705882 0 0 0 0.23 0"/>
            <feBlend
              in2="effect1_backgroundBlur_320_1560"
              result="effect2_dropShadow_320_1560"
            />
            <feBlend
              in="SourceGraphic"
              in2="effect2_dropShadow_320_1560"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    );
  }
}

export default SpecialMarker;

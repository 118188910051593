import React, { Component } from "react";
import styled from "styled-components";
import EventSystem from "../utils/EventSystem";
import HeadBarContent, { FixedHeadBar, FixedHeadBarContainer, HeadBar } from "../components/HeadBar";

import { BlundeeButton } from "../components/Form";
import ContextSystem from "../utils/ContextSystem";
import Language, { Names } from "../utils/Language";
import WhatIsBlundeeComponent from "../model/WhatIsBlundeeComponent";
import Config, { ConfigKeys } from "../utils/Config";

const NextButton = styled(BlundeeButton)`
  position: fixed;
  bottom: ${({bottom}) => bottom};
`;

const Wrapper = styled.div`
  width: 100%;
  overflow-y: auto;
  position: relative;

  @media screen and (min-width: 801px) {
    &::-webkit-scrollbar-thumb {
      background-color: var(--blundee_scroll_thumb_color);
      border-radius: 100px;
    }

    &::-webkit-scrollbar {
      width: 15px;
      background-color: transparent;
      //border-left: 1px solid rgb(20, 20, 20);
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  width: calc(100% - 48px);
  margin: 15px 24px 90px 24px;

  h2 {
    font-family: var(--blundee_font_medium);
    //letter-spacing: 0.25rem;
    margin: 50px 0 20pt;
  }

  h3 {
    font-family: var(--blundee_font_medium);
    //letter-spacing: 0.2rem;
    font-size: 18pt;
  }

  h4 {
    font-family: var(--blundee_font_medium);
    //letter-spacing: 0.15rem;
    font-size: 16pt;
  }

  & > hr {
    width: 80%;
    height: 2px;
    border-radius: 5px;
    background-color: #9e9e9e;
    border: none;
  }

  p {
    max-width: 700px;
    text-align: justify;
  }

  @media screen and (max-width: 1000px) {
    width: calc(100% - 24px);
    margin: 5px 12px 90px 12px;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;


export default class WhatItIsAboutPage extends Component {
  timeoutHandle: number;
  eventIDs: number[] = [];

  state: {
    showFixedHeadBar: boolean,
    mobile: boolean,
    statusBarSpaceHeight: number,
    language: number,
  } = {
    showFixedHeadBar: false,
    mobile: false,
    statusBarSpaceHeight: ContextSystem.statusBarSpaceHeight,
    language: ContextSystem.language
  }

  componentWillUnmount() {
    for (let eventID of this.eventIDs) {
      EventSystem.unsubscribe(eventID);
    }
    clearTimeout(this.timeoutHandle);
  }

  componentDidMount() {
    this.eventIDs = [];

    let whatsc: number = localStorage.getItem("whatsc") === undefined ? 0 : parseInt(localStorage.getItem("whatsc"));
    if (!Number.isInteger(whatsc))
      whatsc = 0;
    whatsc++;
    localStorage.setItem("whatsc", whatsc + "");

    this.timeoutHandle = setTimeout(() => {
      localStorage.setItem("whatsr", "1");
    }, 5000);

    let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({statusBarSpaceHeight, language}) => {
      if (statusBarSpaceHeight !== undefined)
        this.setState({statusBarSpaceHeight});
      if (language !== undefined) {
        this.setState({language});
      }
    });

    this.eventIDs.push(eid);
  }

  handleWrapperScroll(e) {
    let scrollPosition: number = e.target.scrollTop;
    let showFixedHeadBar: boolean = scrollPosition >= 60;
    if (showFixedHeadBar !== this.state.showFixedHeadBar)
      this.setState({showFixedHeadBar});
  }

  handleBackClick() {
    EventSystem.publish(EventSystem.events.redirectIntent, {
      redirectPath: null,
      queryParams: null,
      goBack: true
    });
  }

  goToBrowsePage() {
    let forceLoginStart: boolean = Config.getBoolean(ConfigKeys.start_with_login);
    EventSystem.publish(EventSystem.events.redirectIntent, {redirectPath: forceLoginStart ? "/bongeszes" : "/kezdolap"});
  }

  render() {
    let navbarHeight: number = ContextSystem.hideNavBar ? 0 : ContextSystem.mobileNavbarHeight;
    return (
      <>
        <FixedHeadBar top={this.state.statusBarSpaceHeight} show={this.state.showFixedHeadBar}>
          <FixedHeadBarContainer show={this.state.showFixedHeadBar}>
            <HeadBarContent title={Language.getName(Names.WhatIsPageTitle)} showTitle={true} mobile={this.state.mobile}
                            handleBackClick={() => this.handleBackClick()}/>
          </FixedHeadBarContainer>
        </FixedHeadBar>
        <Wrapper onScroll={(e) => this.handleWrapperScroll(e)}>
          <HeadBar>
            <HeadBarContent title={Language.getName(Names.WhatIsPageTitle)} showTitle={false} mobile={this.state.mobile}
                            handleBackClick={() => this.handleBackClick()}/>
          </HeadBar>

          <ContentWrapper>
            <WhatIsBlundeeComponent/>
            <ButtonWrapper>
              <NextButton bottom={(navbarHeight + 24) + "px"} onClick={() => this.goToBrowsePage()}>
                {Language.getName(Names.WhatIsPageButtonText)}
              </NextButton>
            </ButtonWrapper>
          </ContentWrapper>
        </Wrapper>
      </>
    );
  }
}

export default function fill() {
  Date.prototype.toHunFormatMinutes = function () {
    let mm = this.getMonth() + 1; // getMonth() is zero-based
    let dd = this.getDate();
    let hh = this.getHours();
    let MM = this.getMinutes();

    return (
      this.getUTCFullYear() +
      "." +
      (mm > 9 ? "" : "0") +
      mm +
      "." +
      (dd > 9 ? "" : "0") +
      dd +
      ". " +
      (hh > 9 ? "" : "0") +
      hh +
      ":" +
      (MM > 9 ? "" : "0") +
      MM
    );
  };

  Date.prototype.addSeconds = function (seconds) {
    this.setSeconds(this.getSeconds() + seconds);
    return this;
  };

  Date.prototype.addMinutes = function (minutes) {
    this.setMinutes(this.getMinutes() + minutes);
    return this;
  };

  Date.prototype.addHours = function (hours) {
    this.setHours(this.getHours() + hours);
    return this;
  };

  Date.prototype.addDays = function (days) {
    this.setDate(this.getDate() + days);
    return this;
  };

  Date.prototype.addWeeks = function (weeks) {
    this.addDays(weeks * 7);
    return this;
  };

  Date.prototype.addMonths = function (months) {
    let dt = this.getDate();
    this.setMonth(this.getMonth() + months);
    let currDt = this.getDate();
    if (dt !== currDt) {
      this.addDays(-currDt);
    }
    return this;
  };

  Date.prototype.addYears = function (years) {
    let dt = this.getDate();
    this.setFullYear(this.getFullYear() + years);
    let currDt = this.getDate();
    if (dt !== currDt) {
      this.addDays(-currDt);
    }
    return this;
  };
}

import AbstractModel from "./AbstractModel";

export const HourTypes = {
  OPENING: 1,
  DELIVERY: 2,
  ORDER_ACCEPT: 3,
  ORDER_ACCEPT_FOR_PICKUP: 4,
}
export type HourType = $Values<typeof HourTypes>;

export class Hour extends AbstractModel{
  day: number;
  hourType: HourType;
  partnerID: number;
  openHour: Date;
  closeHour: Date;
}

export const HourStatuses = {
  NO_DATA: -1,
  LOADING: 0,
  OPEN: 1,
  CLOSED: 2,
};

export type HourStatus = $Values<typeof HourStatuses>;
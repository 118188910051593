import { withRouter } from "react-router-dom";
import { Component } from "react";
import EventSystem from "./EventSystem";
import ContextSystem from "./ContextSystem";

class ScrollToTop extends Component {
  componentDidUpdate(prevProps, prevState, snapShot) {
    if (this.props.location !== prevProps.location) {
      // EventSystem.publish(EventSystem.events.scrollContainer, { scrollTo: 0 });
      scroll();
      this.initNavIndex();
    }
  }

  componentDidMount() {
    this.initNavIndex();
  }

  initNavIndex() {
    let url = window.location.pathname.toLowerCase();

    let navIndexes = {
      "/bongeszes": 1,
      "/kezdolap": 0,
      "/asztal": 2,
      "/table": 2,
      "/profil": 3,
      "/etterem": 1,
      "/shop": 1,
      "/e": 1,
      "/s": 1,

      "/aszf": -1,
      "/start": -1
    };

    ContextSystem.navIndexes.forEach(n => navIndexes[n.path] = n.index);

    let navIndex = -1;

    for (let navIndexesKey in navIndexes) {
      if (url.startsWith(navIndexesKey)) {
        navIndex = navIndexes[navIndexesKey];
        break;
      }
    }
    if (ContextSystem.selectedNavIndex !== navIndex)
      EventSystem.publish(EventSystem.events.navbarChanged, { navIndex });
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);

export function scroll() {
  try {
    window.scrollTo({ top: 0, behavior: "smooth" });
  } catch (e) {
    window.scrollTo(0, 0);
  }
}

import APICaller from "../APICaller";

function send(quite, name, email, phoneNumber, restaurantName, when, cb) {
  let data = {
    name: name,
    email: email,
    tel: phoneNumber,
    restaurantName: restaurantName,
    dayPart: parseInt(when),
  };

  APICaller.ownFetch(quite, "PUT", "/callback", data, (data) => {
    let res = JSON.parse(data);
    if (cb)
      cb(res);
  });
}

export default { send };

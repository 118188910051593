import React, {Component} from "react";
import styled, {css} from "styled-components";
import AnimateHeight from "./AnimateHeight";
import Language, {Names} from "../utils/Language";
import {Extra, ExtraGroup, Product, ProductExtra, TranslatableString} from "../model/Product";
import {GoPencil} from "react-icons/go";
import ContextSystem from "../utils/ContextSystem";
import EventSystem from "../utils/EventSystem";
import Toast from "./Toast";
import Cart from "./cart/Cart";
import {AiOutlineMinus, AiOutlinePlus} from "react-icons/ai";

const Wrapper = styled.div`
  width: 100%;
`;

export const VersionWrapper = styled.div`
  width: 100%;
  ${({selected, last}) => last === true && selected === false && css`
    margin-bottom: 8px;
  `}
`;

export const Version = styled.div`
  width: calc(100% - 24px);
  padding: 18px 24px;
  margin: 6px 12px;
  user-select: none;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 6px;
  background-color: var(--blundee_background_light);
  box-shadow: var(--blundee_button_shadow);

  & > span:nth-child(1) {
    width: 75%;
  }

  & > span:nth-child(2) {
    width: 25%;
  }

  & > span:nth-child(3) {
    opacity: 0;
    width: 0;
    font-size: 16pt;
  }

  transition: color 100ms ease-in-out, background-color 100ms ease-in-out, width 50ms ease-in-out;

  ${({selected}) => selected && css`
    background-color: var(--blundee_background_lighter);
    color: var(--blundee_color_normal);
    margin: 0;
    width: 100%;

    & > span:nth-child(2) {
      width: 15%;
    }

    & > span:nth-child(3) {
      opacity: 1;
      width: 10%;
    }
  `}
  &:hover {
    background-color: var(--blundee_background_lighter);
  }

  &:hover, &:active {
    cursor: pointer;
  }
`;


const ExtrasListWrapper = styled.div`
  width: 100%;
  display: block;
`;

const ExtrasList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;


export const WrapperBase = styled.div`
  & > span {
    width: 100%;
    padding: 10px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: rgb(250, 250, 250);

    & > svg {
      font-size: 14pt;

      &:hover {
        cursor: pointer;
      }
    }
  }
`;

export const VersionsList = styled(WrapperBase)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
`;


const ExtraDiv = styled.div`
  width: 100%;
  padding: 0 24px;
  margin: 6px 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  transition: background-color 200ms ease-in-out;

  & > span:nth-child(1) {
    width: 65%;
  }

  & > span:nth-child(2) {
    width: 15%;
  }

  & > span:nth-child(2) {
    width: 20%;
  }

  ${({selected}) => selected === true && css`
    background-color: var(--blundee_extra_selection_bg);
  `};

  @media screen and (max-width: 1000px) {
    padding: 0 12px;

    & > span:nth-child(1) {
      width: 65%;
    }

    & > span:nth-child(2) {
      width: 20%;
    }

    & > span:nth-child(2) {
      width: 20%;
    }
  }
`;

const ExtraButtonsList = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const ExtraButton = styled.div`
  padding: 3px 7px;
  margin: 0 5px;
  border-radius: 5px;
  transition: background-color 300ms ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: var(--blundee_color_tag);
  }

  @media screen and (max-width: 1000px) {
    margin: 0;

    &:active {
      background-color: var(--blundee_color_tag);
    }
  }
`;


const ExtraGroupDiv = styled.div`
  position: relative;
  width: calc(100% - 24px);
  margin: 12px 24px 0 24px;
  border-radius: 12px;
  overflow: hidden;
  padding: 0;
  user-select: none;
  background-color: var(--blundee_background_light);
  box-shadow: var(--blundee_button_shadow);
  color: var(--blundee_color_normal);
  font-family: var(--blundee_font_medium);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const ExtraGroupTitle = styled.div`
  position: sticky;
  top: 0;
  padding: 5px;
  width: 100%;
  text-align: center;
  margin-bottom: 7px;
  background-color: var(--blundee_background_light);

  transition: background-color 200ms ease-in-out;

  & > span {
    font-weight: bold;
    font-size: 16px;
  }

  & > div {
    font-size: 13px;
  }

  @media screen and (max-width: 1000px) {
    & > span {
      font-size: 15px;
    }

    & > div {
      font-size: 13px;
    }
  }

  ${({valid}) => valid !== null && css`
    background-color: ${valid === true ? "var(--blundee_extra_selection_bg)" : "var(--blundee_extra_selection_bg_wrong)"}
  `}
`;

const InnerWrapper = styled(WrapperBase)`
  width: 100%;
  position: relative;
  padding-bottom: 50px;
`;

type Props = {
  product: Product,
  onSelectionChanged: (product: Product)=>{}
}

type State = {
  clickedDoneOnce: boolean,
  product: Product,
  language: number,
  selectedVersion: number,
  showVersions: boolean,
  selectedExtras: ProductExtra[],
}

export default class ExtraSelector extends Component<Props> {
  state: State = {
    clickedDoneOnce: false,
    product: undefined,
    language: ContextSystem.language,
    selectedVersion: 0,
    showVersions: true,
    selectedExtras: [],
  }

  selectVersion(versionID: number) {
    if (this.state.selectedVersion === versionID) {
      this.setState({selectedVersion: 0}, () => this.updateParent());
      return;
    }
    this.setState({selectedVersion: versionID, showVersions: false}, () => this.updateParent());
  }

  isExtraGroupValid(extraGroupID: number) {
    if (this.state.selectedVersion === 0)
      return false;

    for (let eg: ExtraGroup of this.state.product.extraGroups) {
      if (eg.id === extraGroupID) {
        let totalQty = 0;
        for (let oe of this.state.selectedExtras) {
          if (oe.extraGroupID === eg.id) {
            totalQty += oe.qty;
          }
        }
        return totalQty >= eg.minQty && (totalQty <= eg.maxQty || eg.maxQty <= 0);
      }
    }
    return false;
  }

  isSelectedExtrasValid() {
    if (this.state.selectedVersion === 0)
      return false;

    for (let eg of this.state.product.extraGroups) {
      if (eg.versionID === this.state.selectedVersion) {
        let totalQty = 0;
        for (let oe of this.state.selectedExtras) {
          if (oe.extraGroupID === eg.id) {
            totalQty += oe.qty;
          }
        }
        if (totalQty < eg.minQty || (totalQty > eg.maxQty && eg.maxQty > 0))
          return false;
      }
    }
    return true;
  }

  loadProps() {
    if (this.state.product?.id !== this.props.product?.id) {
      this.setState({product: this.props.product, clickedDoneOnce: false, showVersions: true, selectedVersion: 0});
      if (this.props.product.versions.length === 1)
        this.selectVersion(this.props.product.versions[0].id);
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot) {
    this.loadProps();
  }

  updateParent() {
    if (!this.props.onSelectionChanged)
      return;

    let product = {...this.state.product};
    let leadOP = Cart.getLeadOrderProduct(product.orderProduct);
    leadOP.extras = this.state.selectedExtras;
    leadOP.qty = 1;
    leadOP.versionID = this.state.selectedVersion;
    this.props.onSelectionChanged(product);
  }

  componentWillUnmount() {
    this.eventIDs.forEach(id => EventSystem.unsubscribe(id));
  }

  eventIDs: number[] = [];

  componentDidMount() {
    this.loadProps();

    this.eventIDs = [];

    let eid: number = EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({language}) => {
      if (language !== undefined)
        this.setState({language});
    });
    this.eventIDs.push(eid);
  }

  getExtra(product: Product, extraID: number): Extra | null {
    if (!product)
      return;

    for (let e of product.availableExtras) {
      if (e.id === extraID)
        return e;
    }
    return null;
  }

  getSelected(extraID: number, extraGroupID: number): ProductExtra {
    for (let selectedExtra of this.state.selectedExtras) {
      if (selectedExtra.id === extraID && selectedExtra.extraGroupID === extraGroupID)
        return selectedExtra;
    }
    return null;
  }


  removeExtra(extra: Extra, extraGroup: ExtraGroup) {
    let orderExtras = [];

    for (let oe of this.state.selectedExtras) {
      if (oe.id !== extra.id || oe.extraGroupID !== extraGroup.id) orderExtras.push({...oe});
    }
    this.setState({selectedExtras: orderExtras}, () => this.updateParent());
  }

  addExtra(extra: Extra, productExtra: ProductExtra, extraGroup: ExtraGroup, addition: number = 1) {
    let selectedExtras = [];
    if (!extraGroup || !extra || !productExtra) {
      Toast.showToast(Language.getName(Names.ErrorInShopMenuText));
      return;
    }

    let found = false;
    let totalQty = 0;
    for (let orderExtra of this.state.selectedExtras) {
      let newOE = {...orderExtra};
      if (newOE.extraGroupID === extraGroup.id)
        totalQty += newOE.qty;

      if (newOE.extraID === extra.id && newOE.extraGroupID === extraGroup.id) {
        found = true;
        newOE.qty += addition;
      }
      if (newOE.qty < 0)
        newOE.qty = 0;
      if (newOE.qty > productExtra.maxQty)
        newOE.qty = productExtra.maxQty;

      if (newOE.qty > 0)
        selectedExtras.push(newOE);
    }

    if (totalQty >= extraGroup.maxQty && extraGroup.maxQty > 0) {
      let msg: string = Language.getName(Names.ToppingMaxReachedTextPart1) + extraGroup.maxQty + Language.getName(Names.ToppingMaxReachedTextPart2);
      Toast.showToast(msg, 2000);
      return;
    }

    if (!found) {
      let orderExtra = {...extra.orderExtra};
      orderExtra.qty = addition;
      orderExtra.extraGroupID = extraGroup.id;
      orderExtra.saleID = -1;
      selectedExtras.push(orderExtra);
    }
    this.setState({selectedExtras}, () => this.updateParent());
  }

  getExtraGroupElements(version) {
    return this.state.product.extraGroups.map((group, j) => {
      if (version.id === group.versionID) {
        let extras = group.groupExtras.map((extra, k) => {
          let extraData: Extra = this.getExtra(this.state.product, extra.extraID);
          let selectedExtra: ProductExtra = this.getSelected(extra.extraID, group.id);
          return (
            <ExtraDiv selected={selectedExtra ? selectedExtra.qty > 0 : false} key={k}>
              <span>{TranslatableString.get(extra.shownName)}</span>
              <span>{extraData?.price ?? "--"}Ft</span>
              <ExtraButtonsList>
                <ExtraButton onClick={() => this.addExtra(extraData, extra, group, -1)}>
                  <AiOutlineMinus/>
                </ExtraButton>
              </ExtraButtonsList>
              <span>
                {selectedExtra ? selectedExtra.qty : "0"}/{extra.maxQty}
              </span>
              <ExtraButtonsList>
                <ExtraButton onClick={() => this.addExtra(extraData, extra, group, +1)}>
                  <AiOutlinePlus/>
                </ExtraButton>
              </ExtraButtonsList>
            </ExtraDiv>
          );
        });
        if (extras.length === 0)
          return <React.Fragment key={j}/>;

        let optionsText;

        let freeQtyText = group.freeQty > 0 ? Language.getName(Names.ToppingFreeQtyTextPart1) + group.freeQty + Language.getName(Names.ToppingFreeQtyTextPart2) : "";
        let minMaxRange;
        if (group.minQty === group.maxQty)
          minMaxRange = group.minQty === 0 ? Language.getName(Names.ToppingMinMaxQtyTextPart1) : group.minQty;
        else if (group.minQty === 0 && group.maxQty > 0)
          minMaxRange = Language.getName(Names.ToppingMinMaxQtyTextPart2) + group.maxQty + Language.getName(Names.ToppingMinMaxQtyTextPart3);
        else
          minMaxRange = (group.maxQty > 0 ? group.minQty + " - " + group.maxQty : Language.getName(Names.ToppingMinMaxQtyTextPart4) + group.minQty) + Language.getName(Names.ToppingMinMaxQtyTextPart5);

        optionsText = Language.getName(Names.ToppingQtyTextPart1) + minMaxRange + Language.getName(Names.ToppingQtyTextPart2) + (freeQtyText !== "" ? "(" + freeQtyText + ")" : "");

        if (group.maxQty <= group.freeQty && group.maxQty > 0 && group.freeQty > 0) {
          optionsText = Language.getName(Names.ToppingQtyTextPart1) + group.maxQty + Language.getName(Names.ToppingQtyTextPart3);
        }

        return (
          <ExtraGroupDiv key={j}>
            <ExtraGroupTitle valid={this.state.clickedDoneOnce ? this.isExtraGroupValid(group.id) : null}>
              <span>{TranslatableString.get(group.name)}</span>
              <br/>
              <div>{optionsText}</div>
            </ExtraGroupTitle>
            {extras}
          </ExtraGroupDiv>
        );
      }
      return <React.Fragment key={j}/>;
    });
  }

  getVersionElement(groups, oneVersion = false, i: number) {
    let isValidGroup = false;
    for (let group of groups) {
      if (group) {
        isValidGroup = true;
        break;
      }
    }
    if (!isValidGroup) return null;
    return (
      <InnerWrapper key={i}>
        <ExtrasListWrapper>
          <ExtrasList>
            {groups}
          </ExtrasList>
        </ExtrasListWrapper>
      </InnerWrapper>
    );
  }

  render() {
    let versions;
    let isVersionThatHasExtra = false;
    if (this.state.product?.versions.length === 1) {
      versions = this.state.product?.versions.map((version, i) => {
        let groups = this.getExtraGroupElements(version);
        return this.getVersionElement(groups, true, i);
      });
    } else {
      versions = this.state.product?.versions.map((version, i) => {
        let groups = this.getExtraGroupElements(version);
        let versionElement = this.getVersionElement(groups);
        if (versionElement)
          isVersionThatHasExtra = true;

        let opened = this.state.selectedVersion === 0 || this.state.selectedVersion === version.id;
        let last = i === this.state.product.versions.length - 1;
        return (
          <VersionWrapper key={i} selected={this.state.selectedVersion > 0} last={last}
                          className={"VersionWrapper"}>
            <AnimateHeight opened={opened}>
              <VersionsList>
                <Version selected={this.state.selectedVersion === version.id}
                         onClick={() => this.selectVersion(version.id)}>
                  <span>{TranslatableString.get(version.name)}</span>
                  <span>{this.state.product.price + version.price} Ft</span>
                  <span>
                    <GoPencil/>
                  </span>
                </Version>
              </VersionsList>
            </AnimateHeight>
            <AnimateHeight opened={versionElement && this.state.selectedVersion === version.id}>
              {versionElement}
            </AnimateHeight>
          </VersionWrapper>
        );
      });
    }

    return (
      <Wrapper>
        {versions}
      </Wrapper>
    );
  }
}

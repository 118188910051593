import React from "react";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import EventSystem from "../../utils/EventSystem";
import ContextSystem from "../../utils/ContextSystem";

class DeepLinkListener extends React.Component<any> {
  intents: { redirectPath: string }[] = [];

  constructor() {
    super();
    this.runIntent();
  }

  runIntent() {
    console.log("intents: ", JSON.stringify(this.intents));
    if (this.intents && this.intents.length > 0) {
      let i = this.intents.shift();
      console.log("intent published: ", JSON.stringify(i));
      EventSystem.publish(EventSystem.events.redirectIntent, i);
    }
    if (!ContextSystem.navBarLoaded || this.intents.length > 0) {
      console.log("runIntent call in 250ms");
      setTimeout(() => {
        this.runIntent();
      }, 250);
    }
  };

  addIntent(intent: { redirectPath: string }) {
    if (ContextSystem.navBarLoaded)
      EventSystem.publish(EventSystem.events.redirectIntent, intent);
    else
      this.intents.push(intent);
  };

  render() {
    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      // Example url: https://www.enniakarok.hu/path/to/resource
      // slug should be /path/to/resource
      console.log(event.url);
      let url: URL = new URL(event.url);
      console.log("url", url);
      let pathHash: string = url.pathname + url.search + url.hash;
      console.log("pathHash", pathHash);
      if (pathHash) {
        this.addIntent({ redirectPath: pathHash });
        //history.push(slug);
      }
      // If no match, do nothing - let regular routing
      // logic take over
    });

    return (
      <></>
    );
  }
}

export default DeepLinkListener;

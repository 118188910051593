import APICaller from "../APICaller";
import { NonprofitOrganization } from "../../model/NonprofitOrganization";

function getNonprofitOrganization(quite: boolean, cb: ({ error: number, type: number, nonprofitOrganization: NonprofitOrganization[] })=>{}) {
  APICaller.ownFetch(quite, "GET", "/nonprofit/organizations", {}, (data) => {
    let res = JSON.parse(data);
    if (cb)
      cb(res);
  });
}

export default { getNonprofitOrganization };

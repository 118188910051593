import React, { Component } from "react";
import styled from "styled-components";
import ownLogo from "../assets/ownLogos/blundee_text.png";
import arubaLogo from "../assets/aruba_logo_h141.png";
import awsLogo from "../assets/logos/awslogo.png";
import hetznerLogo from "../assets/logos/hetzner-logo.svg";
import EventSystem from "../utils/EventSystem";
import HeadBarContent, { FixedHeadBar, FixedHeadBarContainer, HeadBar } from "../components/HeadBar";
import ContextSystem from "../utils/ContextSystem";
import Language, { Names } from "../utils/Language";
import Config, {ConfigKeys} from "../utils/Config";

const Wrapper = styled.div`
  width: 100%;
  overflow-y: auto;
  position: relative;

  @media screen and (min-width: 801px) {
    &::-webkit-scrollbar-thumb {
      background-color: var(--blundee_scroll_thumb_color);
      border-radius: 100px;
    }

    &::-webkit-scrollbar {
      width: 15px;
      background-color: transparent;
      //border-left: 1px solid rgb(20, 20, 20);
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  width: 80%;
  margin: 15px 10% 120px;

  h2 {
    font-family: var(--blundee_font_bold);
    margin: 50px 0 20pt;
  }

  h3 {
    font-family: var(--blundee_font_bold);
    font-size: 18pt;
  }

  h4 {
    font-family: var(--blundee_font_bold);
    font-size: 16pt;
  }

  & > hr {
    width: 80%;
    height: 2px;
    border-radius: 5px;
    background-color: #9e9e9e;
    border: none;
  }
`;

const Logo = styled.img`
  height: 30px;
  align-self: center;
`;

const SmallLogo = styled.img`
  height: ${({height}) => height ? height : "60px"};
  margin-bottom: 15px;
`;

const Title = styled.h1`
  font-family: var(--blundee_font_bold);
`;

export default class AszfPage extends Component {
  eventIDs: number[] = [];

  state: {
    showFixedHeadBar: boolean,
    mobile: boolean,
    statusBarSpaceHeight: number,
    language: number,
  } = {
    showFixedHeadBar: false,
    mobile: false,
    statusBarSpaceHeight: ContextSystem.statusBarSpaceHeight,
    language: ContextSystem.language
  }

  handleWrapperScroll(e) {
    let scrollPosition = e.target.scrollTop;
    let showFixedHeadBar = scrollPosition >= 60;
    if (showFixedHeadBar !== this.state.showFixedHeadBar)
      this.setState({showFixedHeadBar});
  }

  handleBackClick() {
    EventSystem.publish(EventSystem.events.redirectIntent, {
      redirectPath: null,
      queryParams: null,
      goBack: true
    });
  }

  componentWillUnmount() {
    for (let eventID of this.eventIDs) {
      EventSystem.unsubscribe(eventID);
    }
  }

  componentDidMount() {
    this.eventIDs = [];
    let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({statusBarSpaceHeight, language}) => {
      if (statusBarSpaceHeight !== undefined)
        this.setState({statusBarSpaceHeight});
      if (language !== undefined)
        this.setState({language});
    });
    this.eventIDs.push(eid);
  }

  render() {
    return (
      <>
        <FixedHeadBar top={this.state.statusBarSpaceHeight} show={this.state.showFixedHeadBar}>
          <FixedHeadBarContainer show={this.state.showFixedHeadBar}>
            <HeadBarContent title="ÁSZF" showTitle={true} mobile={this.state.mobile}
                            handleBackClick={() => this.handleBackClick()}/>
          </FixedHeadBarContainer>
        </FixedHeadBar>
        <Wrapper onScroll={(e) => this.handleWrapperScroll(e)}>
          <HeadBar>
            <HeadBarContent title={Language.getName(Names.TermsAndConditions)} showTitle={false} mobile={this.state.mobile}
                            handleBackClick={() => this.handleBackClick()}/>
          </HeadBar>

          <ContentWrapper>
            <Title>{Language.getName(Names.ASZFPageTitle)}</Title>
            <Logo alt={"logo"} src={ownLogo}/>
            <hr/>
            <h2>Üdvözöljük BlundeeFam!</h2>
            <p>
              Ön a megrendelésének elküldése előtt köteles megismerni a jelen ÁSZF rendelkezéseit. A webáruházunkon
              keresztül történő vásárlással Ön
              elfogadja a jelen ÁSZF rendelkezéseit, és az ÁSZF maradéktalanul az Ön és az Eladó között létrejövő
              szerződés részét képezi.
            </p>
            <p>
              Az aktuális ÁSZF és Adatkezelés dokumentumot <a
              target={"_blank"}
              href="https://www.blundee.com/data/docs/ÁSZF.pdf">ezen</a> a linken
              keresztül letöltheti.
            </p>
            <h2>1. Adatkezelő szervezetek</h2>
            <h3>Szolgáltató adatai</h3>
            <SmallLogo height={"15px"} src={ownLogo}/>
            <p>
              Név: XYZ OpThink Group Kft.<br/>
              Székhely: 2314 Halásztelek, Táncsics M. u. 82<br/>
              Levelezési cím: 2314 Halásztelek, Táncsics M. u. 82<br/>
              Adószám: 27900879-2-13<br/>
              Telefonszám: {Config.getString(ConfigKeys.customer_service_phone)}<br/>
              E-mail: info@blundee.com<br/>
              Honlap: www.blundee.com<br/>
            </p>
            <h3>Tárhely szolgáltatók és adatkezelők adatai</h3>
            <p>Minden szerver és infrastruktúra szolgáltatónk az Európai Unió területén belül szolgáltatja az
              adattárolásra szükséges erőforrásokat.</p>
            <SmallLogo height={"25px"} src={hetznerLogo}/>
            <p className="font-size-16">
              1. Hetzner<br/>
              Cégnév: Hetzner Online GmbH.<br/>
              Székhely: Industriestr. 25 91710 Gunzenhausen Germany<br/>
              Ügyfélszolgálati cím: Industriestr. 25 91710 Gunzenhausen Germany (Ansbach Registration Office, HRB
              6089)<br/>
              Levelezési cím: Industriestr. 25 91710 Gunzenhausen Germany (Ansbach Registration Office, HRB 6089)<br/>
              Cégjegyzék száma: DE812871812<br/>
              Adatkezelő weboldala: https://www.hetzner.com/<br/>
              E-mail címe: info@hetzner.com<br/>
              Adatkezelés: https://www.hetzner.com/rechtliches/agb
            </p>
            <SmallLogo height={"auto"} src={awsLogo}/>
            <p className="font-size-16">
              2. Amazon Web Services<br/>
              Cégnév: Amazon Web Services, Inc.<br/>
              Székhely: 410 Terry Avenue North Seattle, WA 98109, United States<br/>
              Ügyfélszolgálati cím: P.O. Box 81226, Seattle, WA 98108, USA<br/>
              Levelezési cím: P.O. Box 81226, Seattle, WA 98108, USA<br/>
              Cégjegyzék száma: 000-22513, DELAWARE<br/>
              Adatkezelő weboldala: https://aws.amazon.com/<br/>
              E-mail címe: abuse@amazonaws.com<br/>
              Adatkezelés: https://aws.amazon.com/legal/
            </p>
            <SmallLogo src={arubaLogo}/>
            <p className="font-size-16">
              3. Aruba Cloud Forpsi<br/>
              Cégnév: BlazeArts Kft.<br/>
              Székhely: 6090 Kunszentmiklós, Damjanich utca 36. 1. em. 8.<br/>
              Ügyfélszolgálati cím: 6090 Kunszentmiklós, Damjanich utca 36. 1. em. 8.<br/>
              Levelezési cím: 6090 Kunszentmiklós, Damjanich utca 36. 1. em. 8.<br/>
              Cégjegyzék száma: Kecskeméti Törvényszék, 03-09-109150<br/>
              Adatkezelő weboldala: http://forpsi.hu/, www.arubacloud.hu<br/>
              E-mail címe: info@forpsi.hu, admin@forpsi.hu<br/>
              Kapcsolatfelvételi űrlap: https://support.forpsi.hu/Main/Default.aspx
            </p>
            <h2>2. Szerződés</h2>
            <p>
              Eladó és Vevő között a Honlap és elektronikus levelezés igénybevételével létrejövő adásvételi szerződés.
              Távollévők között kötött
              szerződés: Olyan fogyasztói szerződés, amelyet a szerződés szerinti termék vagy szolgáltatás nyújtására
              szervezett távértékesítési
              rendszer keretében a felek egyidejű fizikai jelenléte nélkül úgy kötnek meg, hogy a szerződés megkötése
              érdekében a szerződő felek
              kizárólag távollévők közötti kommunikációt lehetővé tévő eszközt alkalmaznak.
            </p>
            <h3>Vonatkozó jogszabályok</h3>
            <p>
              <ul>
                <li>1997. évi CLV. törvény a fogyasztóvédelemről;</li>
                <li>
                  2001. évi CVIII. törvény az elektronikus kereskedelmi szolgáltatások, valamint az információs
                  társadalommal összefüggő
                  szolgáltatások egyes kérdéseiről;
                </li>
                <li>2013. évi V. törvény a Polgári Törvénykönyvről (PTK);</li>
                <li>151/2003. (IX. 22.) Korm. rendelet az egyes tartós fogyasztási cikkekre vonatkozó kötelező
                  jótállásról;
                </li>
                <li>45/2014 (II.26) kormányrendelet a fogyasztó és a vállalkozás közötti szerződések részletes
                  szabályairól;
                </li>
                <li>
                  19/2014. (IV. 29.) NGM rendelet a fogyasztó és vállalkozás közötti szerződés keretében eladott
                  dolgokra vonatkozó szavatossági és
                  jótállási igények intézésének eljárási szabályairól;
                </li>
              </ul>
            </p>
            <h3>A szolgáltatás leírása</h3>
            <p>
              A Blundee Szolgáltatás használatával Ön (a Felhasználó) elfogadja a jelen Szolgáltatási Feltételeket
              („Szolgáltatási Feltételek”).
            </p>
            <p>
              A Blundee egy olyan felületet biztosít (továbbiakban: Blundee szolgáltatás), amelyen a Felhasználók
              ételeket vásárolhatnak, és
              kiszállítási szolgáltatásokat vehetnek igénybe az általuk kiválasztott Partnertől.
            </p>
            <p>
              Partnerek a termékeikről és szolgáltatásiakról szóló tájékoztatást a Blundee Szolgáltatáson keresztül
              nyújtják, ideértve az
              étlapokkal és a termékek árával kapcsolatos tájékoztatásokat is. A Partnerek termékeinek és
              szolgáltatásainak értékesítése és
              megvásárlása további, a Partner által meghatározott szerződési feltételekhez lehet kötve az
              Szolgáltatás értelmében. Amikor a
              Felhasználó megvásárlásra kiválasztja a Partner termékeit és szolgáltatásait, a Felhasználó kötelező erejű
              megrendelést ad le a termékek
              és szolgáltatások Partnertől történő megvásárlására azon általános szerződési feltételek mellett,
              amelyeket a Felhasználónak az
              Blundee Szolgáltatásban a rendelés leadása előtt ismertetnek (a „Rendelés”). A Rendelés beérkezését
              követően a Blundee továbbítja
              a Rendelés részleteit a Partner felé. Amikor a Partner elfogadja a Rendelést, és a Blundee a Partner
              nevében a Felhasználó részére
              visszaigazolja a rendelést, akkor a Felhasználó vagy a Vásárló Szervezet és a Partner között Vásárlási
              Megállapodás jön létre.
            </p>
            <p>
              A Felhasználó által kiválasztott Partner elkészíti (és kiszállítja, amennyiben kiszállítási szolgáltatást
              is rendeltek) a Rendelésben
              szereplő termékeket a Felhasználónak. A Blundee nem felel a Felhasználó felé a Vásárlási Megállapodás
              Partner részéről történő
              megfelelő teljesítéséért.
            </p>
            <p>
              A Felhasználó a Rendelés leadását követően a Rendelést nem törölheti. Továbbiakban a Felhasználó a
              termékekre vagy kiszállítási
              szolgáltatásokra leadott Rendelését azok megrendelését követően nem vonhatja vissza és nem törölheti. A
              termék vagy szolgáltatás
              megrendelésére vonatkozó Rendelés leadása előtt gondosan ellenőrizze a választását.
            </p>
            <h3>Felhasználói fiók</h3>
            <p>
              Önnek egy korlátozott, nem kizárólagos, nem átruházható és nem tovább-engedélyezhető joga van az időről
              időre biztosított Blundee
              Szolgáltatás használatára, kizárólag az itt meghatározott célokra.
            </p>
            <p>
              A Felhasználó biztosítja, hogy a Felhasználói Fiók belépési adatai és ezzel egyenértékű információk,
              amelyek a Felhasználó vagy a
              Vásárló Szervezet Felhasználói Fiókjához való hozzáféréshez szükségesek, bizalmasan vannak kezelve és
              biztonságosan kerülnek
              használatra, harmadik felek részéről nem hozzáférhető módon. Egy Felhasználó csak egy felhasználói fiókkal
              rendelkezhet.
            </p>
            <p>
              Amennyiben annak gyanúja merül fel, hogy bármely jogosulatlan személy hozzáfért a Felhasználó belépési
              adataihoz vagy magához a
              felhasználói fiókhoz, a Felhasználó arról haladéktalanul köteles tájékoztatni a Blundeet. A
              Felhasználó felel a Blundee
              Szolgáltatás minden olyan használatáért és minden olyan tevékenységért, amely a Felhasználó felhasználói
              fiókjával történik.
            </p>
            <p>
              A Blundee Szolgáltatás használatához meg kell adnia egy érvényes bankkártya vagy más fizetési eszköz
              adatait a Blundeenak. A
              Blundee nem tárolja a fizetési eszköz adatait, mivel ezt a Blundee által használt harmadik fél
              fizetési szolgáltatója végzi. A
              Felhasználó vállalja, hogy a Blundee Szolgáltatás a Felhasználó általi használatából eredő minden
              vásárlást kifizet.
            </p>
            <h3>Fizetés</h3>
            <p>
              A Felhasználó vagy a Vásárló Szervezet fizetési kötelezettsége a Rendelés Blundee Szolgáltatáson
              keresztüli leadásával keletkezik.
            </p>
            <p>A Felhasználó fizetési kötelezettségének csak a Blundee Szolgáltatásban biztosított fizetési módokkal
              tehet eleget.</p>
            <p>
              A fizetés automatikusan terhelésre kerül a Felhasználó bankkártyáján, a Blundee jogosult zárolni az
              összeget a Partner nevében a
              Felhasználó bankkártyáján.
            </p>
            <p>A fizetések feldolgozásra a Blundee harmadik fél fizetési szolgáltatót vesz igénybe.</p>
            <h3>Kiszállítás</h3>
            <p>
              Amennyiben a Felhasználó a Rendelés kiszállítását a Blundee Szolgáltatáson megrendeli, a Rendelést
              arra a címre szállítják ki,
              amelyet a Felhasználó a Blundee Szolgáltatáson megadott.
            </p>
            <p>
              A Felhasználónak tudnia kell fogadni a hívásokat azon a telefonszámon, amelyet a Felhasználó a Blundee
              Szolgáltatáson megadott.
              Amennyiben a megadott telefonszámon a Felhasználó nem érhető el, a Blundee vagy a Partner a Rendelést
              törölheti és a Felhasználónak vagy a Vásárló Szervezetnek a teljes ára kiszámlázható.
            </p>
            <p>
              A Felhasználónak jelen kell lennie a Rendelésben megadott kiszállítási címen a Rendelés leadása és a
              Rendelésben szereplő termékek
              átvétele közötti időben. Amennyiben a Felhasználó nem található az általa megadott címen a megrendelt
              termékek kiszállítását követő öt
              percen belül és nem válaszol a kiszállítást végző futár két kapcsolat felvételi kísérletére, a Blundee
              vagy a Partner a Rendelést
              törölheti és a Felhasználónak vagy a Vásárló Szervezetnek a Rendelés teljes ára kiszámlázható.
            </p>
            <h3>A Rendelésnek a Partner értékesítési helyén történő átvétele</h3>
            <p>
              Amennyiben a Felhasználó nem rendelte meg a Partner Rendelésben szereplő termékeinek kiszállítását, hanem
              azokat a Partner értékesítési
              helyén kívánja átvenni, a termékeket a Partnernek azon értékesítési helyén lehet átvenni, amelyet a
              Felhasználó a Rendelés leadásakor
              választott. A Felhasználó külön elektronikus megerősítést kap, amikor a termékek átvehetők. A Partner vagy
              a Blundee feltételeket
              írhat elő a Felhasználó azonosítására a Rendelésben szereplő termékek átvételekor.
            </p>
            <p>
              A Partner a megrendelt termékeket 60 percig őrzi attól az időponttól számítva, amikortól a Partner
              értesítette a Felhasználót arról,
              hogy Rendelése átvehető. Mindazonáltal ez a kötelezettség nem terjed ki az adott Partner értékesítési
              helyének nyitvatartási idején
              túlra, a Rendelést a Partner zárási ideje előtt át kell venni.
            </p>
            <h3>Becsült határidők</h3>
            <p>
              A Partner vagy a Blundee által a Blundee Szolgáltatásban a Felhasználó számára megadott minden
              kiszállítási, átvételi vagy egyéb
              várható időpont csak becsült idő. Nincs garancia arra, hogy a Rendelés a becsült kiszállítási időben
              kiszállításra kerül illetve
              átvehető lesz a megadott időpontban. A termékek kiszállítási idejére olyan szempontok is hatással
              lehetnek, mint a forgalmi akadályok, a
              csúcsforgalom vagy az időjárási körülmények.
            </p>
            <h3>Szellemi tulajdonjog</h3>
            <p>
              Minden Szellemi Tulajdonjog, amelyet a Blundee Szolgáltatás tartalmaz, vagy ami ahhoz kapcsolódik,
              továbbá valamennyi kapcsolódó
              dokumentáció és annak minden része vagy másolata a Blundee és/vagy alvállalkozóinak/licencadóinak
              egyedüli és kizárólagos tulajdonát
              képezik.
            </p>
            <p>
              A jelen Szolgáltatási Feltételek nem biztosítanak semmiféle Szellemi Tulajdonjogot a Felhasználónak az
              Blundee Szolgáltatásban és
              minden jog, ami itt nem kerül kifejezetten átadásra, a Blundee és alvállalkozói/licencadói részére van
              fenntartva.
            </p>
            <h3>Használattal kapcsolatos szabályok</h3>
            <p>A Blundee Szolgáltatás csak a 18. életévüket betöltött személyek számára érhetők el.</p>
            <p>
              a Blundee folyamatosan fejleszti a Blundee Szolgáltatást és a Blundee jogosult részben vagy
              teljesen megváltoztatatni vagy eltávolítani a Blundee Szolgáltatás bizonyos részeit, ideértve az
              Blundee Szolgáltatásban elérhető
              funkciókat, termékeket és Partnereket.
            </p>
            <p>
              A Blundee Szolgáltatás használata során a Felhasználó olyan tartalommal vagy információkkal
              találkozhat, amelyek esetleg
              pontatlanok, hiányosak, elkésettek, félrevezetőek, illegálisak, sértőek vagy egyéb módon károsak. A
              Blundee alapvetően nem vizsgálja
              felül a Partnerei által biztosított tartalmat. a Blundee nem felelős harmadik felek (így Partnerei)
              tartalmáért vagy
              tájékoztatásáért, vagy az olyan károkért, amelyek az azokra történő hagyatkozás eredményeként merültek
              fel.
            </p>
            <p>
              A Felhasználó felelőssége a Blundee Szolgáltatáshoz való hozzáféréshez és azok használatához szükséges
              mindennemű készülék,
              berendezés (pl. telefon), valamint kapcsolat beszerzése, fenntartása és az azokhoz kapcsolódó díjak
              megfizetése.
            </p>
            <p>
              A Felhasználó
              <ol type={"i"}>
                <li>
                  Nem használja vagy próbálja meg használni más személy Blundee felhasználói fiókját és/vagy más
                  személy fizetési adatait a
                  Blundee Szolgáltatásban vagy más személy fizetési kártyáját a Blundee Szolgáltatás használata
                  során az érintett személy
                  hozzájárulása nélkül;
                </li>
                <li>
                  Nem másolhatja le és nem módosíthatja a Blundee Szolgáltatást vagy más kapcsolódó technológiát és
                  nem készíthet belőle
                  származékos művet;
                </li>
                <li>
                  Nem fejtheti vissza, nem dekódolhatja és semmilyen más módon sem kísérelheti megszerezni a Blundee
                  Szolgáltatás vagy a
                  kapcsolódó technológiák forráskódját;
                </li>
                <li>
                  Nem távolíthat el semmilyen olyan szerzői jogi hivatkozást, védjegyet vagy más tulajdonjogra utaló
                  jelzést, amelyet a Blundee
                  Szolgáltatás tartalmaz vagy azon szerepel;
                </li>
                <li>Nem távolíthat el vagy rejthet el semmilyen reklámot, amelyet a Blundee Szolgáltatás magában
                  foglal;
                </li>
                <li>
                  a Blundee hozzájárulása nélkül nem gyűjthet, használhat, másolhat, adhat át semmilyen információt,
                  amely a Blundee
                  Szolgáltatáson keresztül megismer;(vi) nem használhat botokat és egyéb automatizált módszereket az
                  Blundee Szolgáltatás
                  igénybevételére;
                </li>
                <li>
                  Nem hozhat létre Blundee felhasználói fiókot hamis személyazonossággal vagy más személy
                  személyazonosságát felhasználva; valamint
                </li>
                <li>
                  Nem férhet hozzá a Blundee Szolgáltatáshoz, leszámítva az azokon az interfészeken való
                  hozzáférést, amelyeket a Blundee
                  kifejezetten biztosít A Blundee jogosult azonnali hatállyal eltávolítani a Felhasználót az
                  Blundee Szolgáltatásból, illetve
                  elutasítani vagy törölni a Felhasználó bármely Rendelését, amennyiben
                  <ol type={"a"}>
                    <li>
                      A Felhasználó visszaél a Blundee Szolgáltatással vagy bárminemű kárt vagy hátrányt okoz az
                      Blundee Szolgáltatásnak vagy a
                      Partnereknek és/vagy a Blundeenak vagy a Blundee munkavállalóinak;
                    </li>
                    <li>
                      A Blundeenak ésszerű meggyőződése, hogy a Felhasználó a Blundee Szolgáltatás használata
                      során csalárd cselekményeket
                      végez;
                    </li>
                    <li>
                      A Felhasználó hamis Rendelést ad le (pl. nem fizet vagy nincs jelen a kiszállításkor vagy az
                      átvételi helyen a Rendelés
                      fogadásához) vagy más módon elmulasztja a jelen Szolgáltatási Feltételek szerinti kötelezettségei
                      teljesítését;
                    </li>
                    <li>Ésszerű kétség merül fel a Rendelés helyességével vagy hitelességével kapcsolatban.</li>
                  </ol>
                </li>
              </ol>
            </p>
            <h3>Időtartam</h3>
            <p>
              A jelen Szolgáltatási Feltételek eltérő rendelkezésig, mint kötelező megállapodás hatályban maradnak, amíg
              a Felhasználó igénybe veszi
              a Blundee Szolgáltatást.
            </p>
            <p>
              Felhasználó bármikor felhagyhat a Blundee Szolgáltatás igénybevételével. a Blundee bármikor
              véglegesen vagy ideiglenesen
              felhagyhat a Blundee Szolgáltatás nyújtásával.
            </p>
            <h3>Panasz</h3>
            <p>
              A Blundee Szolgáltatás bármikor megszakítható vagy végérvényesen megszüntethető. a Blundee
              Szolgáltatás ideiglenesen
              felfüggesztésre is kerülhet. Ne használja a Blundee Szolgáltatást adatok biztonsági tárolására. Noha a
              Blundee minden
              erőfeszítést megtesz a szolgáltatás zavartalan és problémamentes működtetése érdekében (beleértve az új
              és/vagy átmeneti
              szolgáltatásokat, például a messengeren keresztül történő rendelés leadást), nem tudja ígérni vagy
              garantálni a Blundee által kínált
              bármely szolgáltatás megbízhatóságát, funkcionalitását, pontosságát, minőségét vagy alkalmasságát. A
              Blundee nem ígér vagy garantál
              semmit, ami itt nem került megemlítésre.
            </p>
            <p>
              A Partner köteles a Felhasználó által megrendelt módokon elkészíteni a Rendelést a Blundee
              Szolgáltatásban feltüntetett időn belül.
              Amennyiben bárminemű hiba felmerül a Rendeléssel vagy annak kiszállításával kapcsolatban, a Felhasználó
              kapcsolatba léphet a Blundee
              Vásárlói szolgálatával az info@blundee.com címre írva, illetve közvetlenül magával a Partnerrel is.
            </p>
            <p>
              A Partner kizárólagosan felel a Rendelés tartalmi hibáiért és elkészítéséért, illetve a Vásárlási
              Megállapodás egyéb hiányosságaiért.
            </p>
            <p>
              A Felhasználó, ha az Vásárlási Megállapodás hiányosságát állapítja meg, köteles panaszait haladéktalanul
              megküldeni a Blundeenak vagy
              a Partnernek, az említett hibák egyértelmű leírásával.
            </p>
            <h3>Módosítások</h3>
            <p>A jelen Blundee Általános Szolgáltatási Feltételek módosíthatók.</p>
            <p>
              a Blundee jogosult a Felhasználó előzetes hozzájárulása nélkül az itt meghatározott összes vagy
              bármely jogát vagy kötelezettségét
              részben vagy egészben átengedni leányvállalatának vagy jogutódjának vagy a Blundee Szolgáltatással
              kapcsolatos vállalkozási vagyona
              megvásárlójának vagy megszerzőjének.
            </p>
            <p>
              A Felhasználó nem jogosult az itt meghatározott jogait vagy kötelezettségeit egészben vagy részben
              engedményezni, átengedni vagy
              átruházni.
            </p>
            {/*--------------------------------*/}
            <h2>3. Adatvédelem</h2>
            <h3> A COOKIE - k használatával kapcsolatos tájékoztatás </h3>
            <p>
              Az Adatkezelő a honlap látogatása során úgynevezett cookie-kat (sütiket) használ. A cookie betűből és
              számokból álló információcsomag,
              amit honlapunk az Ön böngészőjének küld el azzal a céllal, hogy elmentse bizonyos beállításait,
              megkönnyítse a honlapunk használatát és
              közreműködik abban, hogy néhány releváns, statisztikai jellegű információt gyűjtsünk a látogatóinkról. A
              cookie-k (sütik) nem
              tartalmaznak személyes információkat, és nem alkalmasak az egyéni felhasználó azonosítására. A cookie-k
              (sütik) gyakran olyan egyéni
              azonosítót tartalmaznak - egy titkos, véletlenül generált számsort - amelyet az Ön eszköze tárol. Néhány
              cookie (süti) a honlap bezárása
              után megszűnik, néhány pedig hosszabb időre tárolásra kerül számítógépén. A cookie-k jogszabályi háttere
              és jogalapja: Az adatkezelés
              hátterét az információs önrendelkezési jogról és az információszabadságról szóló 2011. évi CXII. törvény
              (Infotv.) és az elektronikus
              kereskedelmi szolgáltatások, valamint az információs társadalommal összefüggő szolgáltatások egyes
              kérdéseiről szóló 2001. évi CVIII.
              törvény rendelkezései jelentik. Az adatkezelés jogalapja az Infotv. 5. § (1) bekezdés a) pontjával
              összhangban az Ön hozzájárulása. A
              honlap által használt cookie-k főbb jellemzői: Google Analytics cookie: A Google Analytics a Google elemző
              eszköze, amely abban segít a
              weboldalak és alkalmazások tulajdonosainak, hogy pontosabb képet kapjanak látogatóik tevékenységeiről. A
              szolgáltatás cookie-kat
              használhat, hogy információkat gyűjtsön és jelentést készítsen a weboldal használatára vonatkozó
              statisztikai adatokból anélkül, hogy
              egyénileg azonosítaná a látogatókat a Google számára. A Google Analytics által használt fő cookie a „__ga”
              cookie. A webhelyhasználati
              statisztikai adatokból készülő jelentések mellett a Google Analytics – az előbbiekben ismertetett néhány
              hirdetési cookie-val együtt –
              felhasználható arra is, hogy relevánsabb hirdetéseket jelenítsünk meg a Google-termékekben (például a
              Google Keresésben) és szerte az
              interneten. A működéshez szigorúan szükséges cookie-k: Ezek a cookie-k a weboldal használatához
              nélkülözhetetlenek, és lehetővé teszik a
              weboldal alapvető funkcióinak használatát. Ezek hiányába az oldal számos funkciója nem lesz elérhető az Ön
              számára. Ezen típusú cookie-k
              élettartama kizárólag a munkamenet idejére korlátozódik. A felhasználói élmény javítását szolgáló
              cookie-k: Ezek a cookie-k
              információkat gyűjtenek a felhasználó weboldalhasználatáról, például, hogy mely oldalakat látogatja
              leggyakrabban, vagy milyen
              hibaüzenetet kap a weboldalról. Ezek a cookie-k nem gyűjtenek a látogatót azonosító információkat, vagyis
              teljesen általános, névtelen
              információkkal dolgoznak. Az ezekből nyert adatokat a weboldal teljesítményének javítására használjuk.
              Ezen típusú cookie-k élettartama
              kizárólag a munkamenet idejére korlátozódik. Munkamenet cookie: Ezek a sütik a látogató tartózkodási
              helyét, a böngésző nyelvét, a
              fizetés pénznemét tárolják, élettartamuk a böngésző bezárása, vagy maximum 2 óra. Utoljára megtekintett
              kategória cookie: Rögzítit az
              utoljára megtekintett kategóriát. Élettartama 60 nap. Cookie elfogadás cookie: Az oldalra érkezéskor a
              figyelmeztető ablakban elfogadja
              a cookiek tárolásáról szóló nyilatkozatot. Élettartama 365 nap. Amennyiben Ön nem fogadja el a cookie-k
              használatát, akkor bizonyos
              funkciók nem lesznek elérhetőek az Ön számára. Megrendelés, számlázás adatkezelése
            </p>
            <h3>Az adatkezelés jogszabályi háttere és jogalapja</h3>
            <p>
              Az adatkezelés hátterét az információs önrendelkezési jogról és az információszabadságról szóló 2011. évi
              CXII. törvény (Infotv.) és a
              számvitelről szóló 2000. évi C. törvény (Sztv.) rendelkezései jelentik. Az adatkezelés jogalapja az
              Infotv. 5. § (1) bekezdés a)
              pontjával összhangban az Ön hozzájárulása, valamint – hozzájárulása visszavonása esetén – az Infotv. 6 §
              (5) bekezdése a) pontja alapján
              az Adatkezelőt terhelő, Sztv-ben megfogalmazott jogi kötelezettség teljesítése.
            </p>
            <h3>Az adatkezelés célja</h3>
            <p>
              A jogszabályoknak megfelelő számla kiállítása és a számviteli bizonylat-megőrzési kötelezettség
              teljesítése. Az Sztv. 169. § (1)-(2)
              bekezdése alapján a gazdasági társaságoknak a könyvviteli elszámolást közvetlenül és közvetetten
              alátámasztó számviteli bizonylatot meg
              kell őrizniük. A kezelt adatok köre:
              <br/> Név, cím, e-mail cím, telefonszám.
            </p>
            <h3>Az adatkezelés időtartama</h3>
            <p>
              A kiállított számlákat az Sztv. 169. § (2) bekezdése alapján a számla kiállításától számított 8 évig meg
              kell őrizni. Tájékoztatjuk
              arról, hogy amennyiben a számla kiállításához adott hozzájárulását visszavonja, az Adatkezelő az Infotv.
              6. § (5) bekezdés a) pontja
              alapján jogosult a számla kiállítása során megismert személyes adatait 8 évig megőrizni.
            </p>
            <h3>Szállításhoz kapcsolódó adatkezelés</h3>
            <h4>Az adatkezelés jogszabályi háttere és jogalapja</h4>
            <p>
              Az adatkezelés hátterét az információs önrendelkezési jogról és az információszabadságról szóló 2011. évi
              CXII.törvény(Infotv.)
              előírásai jelentik.Az adatkezelés jogalapja az Infotv. 5. § (1) bekezdés a ) pontjával összhangban az Ön
              hozzájárulása. <br/>
              Az adatkezelés célja: Az áruszállítás esetében az adatkezelés célja az, hogy a megrendelt árut az Ön
              igényéhez alkalmazkodva szerződéses
              partnerünk közreműködésével kiszállítsuk az Ön számára. <br/> A kezelt adatok köre:
              <br/> Név, cím, e - mail cím, telefonszám.
            </p>
            <h3>Regisztrációs adatkezelés</h3>
            <h4>Az adatkezelés jogszabályi háttere és jogalapja</h4>
            <p>
              Az adatkezelés hátterét az információs önrendelkezési jogról és az információszabadságról szóló 2011. évi
              CXII. törvény (Infotv.) és a
              Polgári Törvénykönyvről szóló 2013. évi V. törvény (Ptk.) jelentik. Az adatkezelés jogalapja az Infotv. 5.
              § (1) bekezdés a) pontjával
              összhangban az Ön hozzájárulása.
            </p>
            <h4>Az adatkezelés célja</h4>
            <p>
              A regisztráció során megadott adatok tárolásával az Adatkezelő kényelmesebb szolgáltatást tud biztosítani
              (pl. az érintett adatait újabb
              vásárláskor nem kell ismét megadni)
            </p>
            <h4>A kezelt adatok köre</h4>
            <p>
              Az adatkezelés során az Adatkezelő az Ön nevét, lakcímét, telefonszámát, e-mail címét, a megvásárolt
              termék jellemzőit és a vásárlás
              időpontját kezeli.
            </p>
            <h3>Az adatkezelés időtartama</h3>
            <p>A hozzájárulásának visszavonásáig</p>
            <h3>Hírlevéllel kapcsolatos adatkezelés</h3>
            <h4>Az adatkezelés jogszabályi háttere és jogalapja</h4>
            <p>
              Az adatkezelés hátterét az információs önrendelkezési jogról és az információszabadságról szóló 2011. évi
              CXII. törvény (Infotv.) és a
              gazdasági reklámtevékenység alapvető feltételeiről és egyes korlátairól szóló 2008. évi XLVIII. törvény
              (Grt.) jelentik. Az adatkezelés
              jogalapja az Infotv. 5. § (1) bekezdés a) pontjával és a Grt. 6. § (1)-(2) bekezdésével összhangban az Ön
              hozzájárulása.
            </p>
            <h4>Az adatkezelés célja</h4>
            <p>
              Az adat kezelésének célja az, hogy értesüljön a rendelései állapotáról, a legfrissebb és legjobb
              ajánlatokról, akciókról.
              Tájékoztatjuk arról, hogy a hírlevélben
              nem csak az Adatkezelő, hanem más gazdasági társaságok reklámjait is elhelyezzük, azonban számukra az Ön
              személyes adatait nem adjuk át,
              nem továbbítjuk.
            </p>
            <h4>A kezelt adatok köre</h4>
            <p>Név, cím, e-mail cím, telefonszám, megrendelések, megrendelés részletek és megjegyzések.</p>
            <h4>Az adatkezelés időtartama</h4>
            <p>Az érintett hozzájárulásának visszavonásáig.</p>
            {/*<p>*/}
            {/*  Kérjük olvassa el a <NavLink to={"hogyan-vasaroljunk"}>"Hogyan vásároljunk"</NavLink> és a{" "}*/}
            {/*  <NavLink to={"/szallitas"}>"Fizetés és szállítás"</NavLink> dokumentumokat is, mert ezen dokumentumok is*/}
            {/*  az ÁSZF részét képezik!*/}
            {/*</p>*/}
          </ContentWrapper>
        </Wrapper>
      </>
    );
  }
}

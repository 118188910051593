import React, { Component } from "react";
import styled, { css } from "styled-components";
import ReactResizeDetector from "react-resize-detector";

const AnimateHeightWrapper = styled.div`
  width: 100%;
  height: fit-content;
  max-height: 0;
  overflow: hidden;
  transition: max-height ${({duration}) => duration ?? "200ms"} ease-in-out;
    // margin ${({duration}) => duration ?? "200ms"} ease-in-out;

  ${(props) => props.opened === true && css`
    max-height: ${(props) => (props.max === 0 ? props.initmax ?? 500 : props.max) + "px"};
    //margin-bottom: 5px;
  `};
`;

const InnerWrapper = styled.div`
  width: 100%;
  height: fit-content;
`;

export default class AnimateHeight extends Component<{ opened: boolean }> {
  ref;

  state: {
    max: number,
    initmax: number,
  } = {
    max: 0,
    initmax: 0
  };

  constructor(props) {
    super(props);
    this.state = {
      max: 0,
      initmax: props.initmax
    };
    AnimateHeight.i++;
  }

  componentDidMount() {
    if (this.props.opened) {
      this.setState({initmax: this.ref.clientHeight});
    }
  }

  innerDivResized(width, height) {
    this.setState({max: height});
  }

  render() {
    return (
      <AnimateHeightWrapper
        opened={this.props.opened}
        max={this.state.max}
        initmax={this.state.initmax}
        duration={this.props.duration}
      >
        <ReactResizeDetector handleHeight onResize={(w, h) => this.innerDivResized(w, h)}>
          <InnerWrapper ref={r => this.ref = r}>
            {this.props.children}
          </InnerWrapper>
        </ReactResizeDetector>
      </AnimateHeightWrapper>
    );
  }
}

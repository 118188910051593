import React, { Component } from "react";
import styled from "styled-components";
import EventSystem from "../utils/EventSystem";
import { Button } from "../components/Form";
import ContextSystem from "../utils/ContextSystem";
import Language, { Names } from "../utils/Language";
import OrderDetails from "../components/profile/OrderDetails";
import { OrdersAPI } from "../utils/api/OrdersAPI";
import { TableReservation } from "../model/Order";
import { Product } from "../model/Product";
import Config, {ConfigKeys} from "../utils/Config";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  padding: 30px 10%;
  width: 100%;

  color: var(--blundee_color_normal);

  & > h1 {
    font-family: var(--blundee_font_bold);
    font-size: 30pt;
    //letter-spacing: 0.3rem;
    font-weight: 700;
    margin-bottom: 30px;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    padding: 25px 12px 60px 12px;
  }
  align-items: center;
`;

const OrderDetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  @media screen and (max-width: 800px) {
    justify-content: center;
  }
`;

export default class PaymentDonePage extends Component {
  eventIDs: number[] = [];

  state: {
    order: Order,
    txid: number,
    status: string,
    orderNumber: string,
    openRegisterFromOrder: boolean,
    language: number,
  } = {
    order: undefined,
    txid: -1,
    status: "ERR",
    orderNumber: -1,
    openRegisterFromOrder: false,
    language: ContextSystem.language
  };

  componentWillUnmount() {
    for (let eventID of this.eventIDs) {
      EventSystem.unsubscribe(eventID);
    }
  }

  componentDidMount() {
    this.eventIDs = [];

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    let txid = urlParams.get("txid");
    let status = urlParams.get("status");
    let order: Order = JSON.parse(localStorage.getItem("order"));

    if (order) {
      if (order.tableReservationID > 0) {
        let tableReservation: TableReservation = JSON.parse(localStorage.getItem("tableReservation"));
        let qrid: number = parseInt(localStorage.getItem("atPlaceQRID"));
        let atPlaceTableElement: Element = JSON.parse(localStorage.getItem("atPlaceTableElement"));
        ContextSystem.setAtPlace2(tableReservation, qrid, atPlaceTableElement);
      }
      OrdersAPI.fixDates(order);
      if (ContextSystem.orders.find(o => o.number === order.number)) {
        order = ContextSystem.orders.find(o => o.number === order.number);
      }

      let products: Product[] = JSON.parse(localStorage.getItem("products"));
      ContextSystem.mergeOrders([order], products);
    }

    let orderNumber = window.localStorage.getItem("txid") === txid ? window.localStorage.getItem("orderNumber") : -1;
    this.setState({order, txid, status, orderNumber});
    if (status === "ACK") {
      EventSystem.publish(EventSystem.events.online_payment_successful, {
        order,
        orderNumber: orderNumber,
        profileID: ContextSystem?.profile?.id
      });
    }

    let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({language, orders}) => {
      if (language !== undefined) {
        this.setState({language});
      }
      if (orders !== undefined) {
        let order: Order = orders.find(o => o.number === this.state.order.number);
        if (order) {
          this.setState({order});
        }
      }
    });
    this.eventIDs.push(eid);
  }

  handleBackButton() {
    EventSystem.publish(EventSystem.events.redirectIntent, {
      redirectPath: "/kezdolap",
      queryParams: {txid: undefined, status: undefined}
    });
  }

  handleBackToTableButton() {
    EventSystem.publish(EventSystem.events.redirectIntent, {
      redirectPath: "/asztal/" + localStorage.getItem("atPlaceQRID"),
      queryParams: {txid: undefined, status: undefined}
    });
  }

  render() {
    return (
      <Wrapper>
        <h1>{Language.getName(Names.ThanksForOrdering)}</h1>
        <p>
          {this.state.status === "ACK" && (
            <span>
              {Language.getName(Names.ThanksForOrderingPaymentSuccess)}
            </span>
          )}
          {this.state.status !== "ACK" && (
            <span>
              {Language.getName(Names.ThanksForOrderingPaymentWasNotSuccess)}
            </span>
          )}
          <br/>
          {Language.getName(Names.TransactionNumber)}: {this.state.txid}<br/>
          {Language.getName(Names.OrderNumber)}: {this.state.orderNumber}
        </p>
        <div>
          {Language.getName(Names.AfterOrderText1)}
          <a
            href={"tel:" + Config.getString(ConfigKeys.customer_service_phone)?.replaceAll(" ", "")}>
            {Config.getString(ConfigKeys.customer_service_phone)}
          </a>
          {Language.getName(Names.AfterOrderText2)}
          <br/>
          <br/>
        </div>
        <OrderDetailsWrapper>
          <OrderDetails tableReservationUI={false} order={this.state.order} openedDefault={true} shop={undefined}/>
        </OrderDetailsWrapper>
        <br/>
        {this.state.order && this.state.order.tableReservationID > 0 &&
          <Button onClick={() => this.handleBackToTableButton()}>{Language.getName(Names.BackToTable)}</Button>
        }
        {this.state.order && this.state.order.tableReservationID <= 0 &&
          <Button onClick={() => this.handleBackButton()}>{Language.getName(Names.BackToHomeScreen)}</Button>
        }
      </Wrapper>
    );
  }
}

import APICaller from "../APICaller";
import ErrorMessages from "./ErrorMessages";
import type { TransactionInfo } from "../../components/cart/OrderFinishing";
import { MessageBase } from "../ws/MessageHandler";
import { ZipCode } from "../../model/Address";
import { TableReservation } from "../../model/Order";
import { Element } from "../../model/BluePrint";
import { Shop } from "../../model/Shop";
import { MessageSender } from "../ws/MessageSender";
import { Product } from "../../model/Product";

export type SendOrderMessageType =
  MessageBase
  & { order: Order, products: Product[], orderNumber: string, profileID: number, transactionInfo: TransactionInfo, paymentMethod: number, tableReservation: TableReservation };

function sendOrder(order: Order, cb: SendOrderMessageType) {
  APICaller.ownFetch(false, "PUT", "/order", { ...order }, (data) => {
    let res = JSON.parse(data);
    if (cb)
      cb(res);
  });
}

function createPayment(orderNumber: string, cb: (res: MessageBase & { transactionInfo: TransactionInfo })=>{}) {
  APICaller.ownFetch(false, "PUT", "/payment", { orderNumber }, (data) => {
    let res = JSON.parse(data);
    if (cb)
      cb(res);
  });
}

function getOrders(quite: boolean, firstIndex: number, lastIndex: number, cb: (res: MessageBase & { orders: Order[], products: Product[], cities: City[], zipCodes: ZipCode[], donations: Donation[] })=>{}) {
  if (firstIndex === undefined || lastIndex === undefined || firstIndex > lastIndex || lastIndex - firstIndex > 20) {
    if (cb)
      cb({
        error: ErrorMessages.PARAMETER_NOT_VALID,
        message: "not from server: firstIndex, lastIndex values: >= 0 && diff max. 20"
      });
    return;
  }

  APICaller.ownFetch(quite, "POST", "/orders", { firstIndex, lastIndex }, (data) => {
    let res = JSON.parse(data);

    if (res.error === 0)
      res.orders.forEach(o => fixDates(o));

    if (cb)
      cb(res);
  });
}

function getOrder(quite: boolean, orderNumber: string, randomToken: string, cb: ()=>{}) {
  APICaller.ownFetch(quite, "POST", "/order", { orderNumber, randomToken }, (data) => {
    let res = JSON.parse(data);

    if (res.error === 0)
      res.orders.forEach(o => fixDates(o));

    if (cb)
      cb(res);
  });
}

export type GetTableQRID =
  MessageBase
  & { table: Element, reservation: TableReservation, shop: Shop, orders: Order[], products: Product[], cities: City[], zipcodes: ZipCode[] };

function loadTableQRID(quite: boolean, qrid: number, cb: (res: GetTableQRID)=>{}) {
  APICaller.ownFetch(quite, "GET", "/qr/table?QRID=" + qrid, {}, (data) => {
    let res = JSON.parse(data);

    if (res.error === 0 && res.tableReservationID > 0) {
      MessageSender.sendRegisterForTableReservation(res.tableReservationID);
    }

    if (cb)
      cb(res);
  });
}

function finishTableReservation(tableReservationID: number, cb: (res: GetTableQRID)=>{}) {
  APICaller.ownFetch(false, "POST", "/tableReservation", { tableReservationID }, (data) => {
    let res = JSON.parse(data);

    if (cb)
      cb(res);
  });
}

function bookTable(shopID: number, time: Date, ppl: number, stay: number, comment: string, cb: (res: GetTableQRID)=>{}) {
  APICaller.ownFetch(false, "PUT", "/table/book",
    {
      shopID,
      date: time.getTime(),
      ppl,
      stay,
      comment
    },
    (data) => {
      let res = JSON.parse(data);

      if (cb)
        cb(res);
    });
}

function fixDates(order: Order) {
  if (!order)
    return;
  order.date = new Date(order.date);
  if (order.scheduleDate)
    order.scheduleDate = new Date(order.scheduleDate);
  order.lastState.dateTime = new Date(order.lastState.dateTime);
  for (let orderState of order.orderStates)
    orderState.dateTime = new Date(orderState.dateTime);
}

export const OrdersAPI = {
  sendOrder,
  getOrders,
  getOrder,
  createPayment,
  loadTableQRID,
  fixDates,
  finishTableReservation,
  bookTable
};

import React, { Component } from "react";
import styled from "styled-components";
import { GoCheck, GoLocation } from "react-icons/go";
import { ImCross } from "react-icons/im";
import ContextSystem from "../../utils/ContextSystem";
import EventSystem from "../../utils/EventSystem";
import SavedAddressPicker from "./SavedAddressPicker";
import { FaTimes } from "react-icons/fa";
import { SelectedAddress } from "../../pages/BrowsePage/SearchScreen";
import { AddressAPI } from "../../utils/api/AddressAPI";
import ToggleButton from "react-toggle-button";
import { ShippingPrice } from "../../model/ShippingPrice";
import { Address, City } from "../../model/Address";
import Language, { Names } from "../../utils/Language";
import ScheduleDatePicker from "./ScheduleDatePicker";
import { TranslatableString } from "../../model/Product";

const AddressesWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 6;

  & > h4 {
    margin: 0 0 5px 0;
    font-weight: normal;
    font-size: 10pt;
    font-family: var(--blundee_font_medium);
  }
`;

const CloseButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  font-size: 19pt;
  flex-shrink: 0;

  & > h4 {
    margin: 0 0 5px 0;
    font-size: 14pt;
  }

  & > svg {
    &:hover,
    &:active {
      cursor: pointer;
    }
  }
`;

const WarningText = styled.div`
  color: var(--blundee_color_normal);
  font-size: 11pt;
  width: 100%;
`;

const HeadContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

const ToggleWrapper = styled.div`
  padding: 0 3px 0 0;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  & > span {
    vertical-align: center;
    margin: 0 0 3px;
    font-size: 10pt;
  }
`;

const SchedulerWrapper = styled.div`
  width: 70%;
  padding: 0 0 0 3px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  & > p {
    margin: 0 0 3px 0;
    padding: 0;
    font-size: 10pt;
    flex-shrink: 0;
  }
`;

export default class FloatingAddressPicker extends Component<{ handleCloseButton: (fireNotSetupEvent: boolean)=>{} }> {
  state: {
    selectedCity: City | null,
    selectedAddress: Address | null,
    availableShippingPrices: ShippingPrice[],
    pickup: boolean,
    language: number,
    shop: Shop,
    selectedNavIndex: number,
    mobile: boolean,
  } = {
    selectedCity: ContextSystem.selectedCity,
    selectedAddress: ContextSystem.selectedAddress,
    availableShippingPrices: ContextSystem.availableShippingPrices,
    pickup: ContextSystem.pickup,
    language: ContextSystem.language,
    shop: ContextSystem.navigatingPartner,
    selectedNavIndex: ContextSystem.selectedNavIndex,
    mobile: ContextSystem.mobileLayout
  };

  componentDidMount() {
    EventSystem.subscribe(EventSystem.events.addressSelected, ({ address }) => {
      this.setState({ selectedAddress: address });
    });
    EventSystem.subscribe(EventSystem.events.citySelected, ({ city }) => {
      this.setState({ selectedCity: city });
    });

    EventSystem.subscribe(EventSystem.events.contextSystemChanged,
      ({
         availableShippingPrices,
         selectedAddress,
         selectedCity,
         pickup,
         language,
         navigatingPartner,
         selectedNavIndex,
         mobileLayout
       }) => {
        if (selectedAddress !== undefined)
          this.setState({ selectedAddress });
        if (mobileLayout !== undefined)
          this.setState({ mobile: ContextSystem.mobileLayout });
        if (selectedCity !== undefined)
          this.setState({ selectedCity });
        if (availableShippingPrices !== undefined)
          this.setState({ availableShippingPrices });
        if (pickup !== undefined)
          this.setState({ pickup });
        if (language !== undefined)
          this.setState({ language });
        if (navigatingPartner !== undefined)
          this.setState({ shop: navigatingPartner });
        if (selectedNavIndex !== undefined)
          this.setState({ selectedNavIndex });
      });
  }

  close(fireNotSetupEvent: boolean = false) {
    this.props.handleCloseButton(fireNotSetupEvent);
  }

  openSelectAddressPopup() {
    this.close(false);
    EventSystem.publish(EventSystem.events.open_address_picker_popup, {
      address: null,
      cb: (selectedAddress) => {
        AddressAPI.saveAddress(selectedAddress, (res) => {
          EventSystem.publish(EventSystem.events.addressSelected, { address: res.address });
        });
      }
    });
  }

  stopPropagation(e) {
    e.stopPropagation();
    e.preventDefault();
    return false;
  }

  toggleTakeaway(enable: boolean) {
    ContextSystem.setPickup(enable);
  }

  render() {
    return (
      <AddressesWrapper onClick={(e) => this.stopPropagation(e)}>
        <CloseButtonWrapper>
          <h4>{Language.getName(Names.SelectedAddress)}</h4>
          <FaTimes onClick={() => this.close(true)}/>
        </CloseButtonWrapper>
        <HeadContentWrapper>
          <ToggleWrapper>
            <span>{Language.getName(Names.ForTakeaway)}:</span>
            <ToggleButton
              height={40}
              inactiveLabel={<ImCross color={"var(--blundee_yellow)"}/>}
              activeLabel={<GoCheck strokeWidth={2} color={"var(--blundee_background_card_4)"}/>}
              value={this.state.pickup || false}
              colors={{
                activeThumb: {
                  base: "var(--blundee_background_card_4)",
                  color: "var(--blundee_background_card_4)"
                },
                inactiveThumb: {
                  base: 'var(--blundee_background_card_4)',
                  color: "var(--blundee_background_card_4)"
                },
                active: {
                  base: "var(--blundee_yellow)",
                  color: "var(--blundee_background_card_4)"
                  //hover: "rgb(0, 255, 0)",
                },
                inactive: {
                  base: "var(--blundee_background_card_3)",
                  color: "var(--blundee_background_card_4)"
                  //hover: "rgb(0, 0, 255)",
                }
              }}
              onToggle={(value) => this.toggleTakeaway(!value)}
            />
          </ToggleWrapper>
          <SchedulerWrapper>
            <p>{Language.getName(Names.ScheduleOrder) + Language.getName(Names.ScheduleOrderPlusMinus10)}:</p>
            <ScheduleDatePicker/>
          </SchedulerWrapper>
        </HeadContentWrapper>
        {!this.state.pickup &&
        <>
          {!this.state.selectedAddress && !this.state.selectedCity && (
            <SelectedAddress italic={true}>
              <GoLocation/>
              <span>{Language.getName(Names.NoAddressSelected)}</span>
              <span style={{ flexBasis: "100%", marginBottom: "5px" }}/>
              <button onClick={() => this.openSelectAddressPopup()}>{Language.getName(Names.EnterExactAddress)}</button>
            </SelectedAddress>
          )}
          {this.state.selectedAddress && (
            <SelectedAddress>
              <GoLocation/>
              <span>{this.state.selectedAddress.street}</span>
              <span style={{ flexBasis: "100%", marginBottom: "5px" }}/>
              <button
                onClick={() => this.openSelectAddressPopup()}>{Language.getName(Names.EnterAnotherAddress)}</button>
            </SelectedAddress>
          )}
          {(this.state.availableShippingPrices === undefined || this.state.availableShippingPrices.length <= 0) && (
            <WarningText>
              {!this.state.selectedAddress && Language.getName(Names.YouShouldEnterExactAddress)}
              {this.state.selectedAddress && Language.getName(Names.PartnerShopDoesntShipHere)}
            </WarningText>
          )}
          {this.state.selectedCity && !this.state.selectedAddress && (
            <SelectedAddress>
              <GoLocation/>
              <span>{TranslatableString.get(this.state.selectedCity.name)}</span>
              {/*<FaTimes onClick={() => this.removeSelectedCity()}/>*/}
              <span style={{ flexBasis: "100%", marginBottom: "5px" }}/>
              <button onClick={() => this.openSelectAddressPopup()}>{Language.getName(Names.EnterExactAddress)}</button>
            </SelectedAddress>
          )}
          <h4>{Language.getName(Names.SelectFromYourSavedAddresses)}:</h4>
          <SavedAddressPicker fontsize={"s"} modifyOnClick={false} showModifyIcons={false}/>
        </>
        }
      </AddressesWrapper>
    );
  }
}

import React, { Component } from "react";
import styled from "styled-components";
import { Shop } from "../model/Shop";
import Config from "../utils/Config";
import ShopComponent from "./ShopComponent";
import ProductDiv from "./ProductComponent";
import { DynamicOffer } from "../model/nonpersistent/DynamicOffer";
import Loader from "react-loader-spinner";
import {
  handlePartnerProfileClicked,
  handleProductClicked,
  sortRestaurantsAndFoodsByOpenStatus
} from "../pages/BrowsePage/SearchScreen";
import { Swiper, SwiperSlide } from "swiper/react";
import { LoaderWrapper } from "../pages/StartPage";

const SwiperContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  text-align: left;
`;

export default class DynamicOffersDiv extends Component<{ recommendations: DynamicOffer[], partnerProfiles: Shop[], loading: boolean }> {
  swiperRefs = [];

  state: {
    recommendations: DynamicOffer[],
    loading: boolean,
    partnerProfiles: Shop[],
    mobile: boolean,
  } = {
    recommendations: [],
    loading: false,
    partnerProfiles: [],
    mobile: window.innerWidth <= Config.mobileMaxWidth
  };

  loadMobile() {
    this.setState({ mobile: window.innerWidth <= Config.mobileMaxWidth });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", () => this.loadMobile());
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
    if (prevProps !== this.props)
      this.reloadProps();
  }

  reloadProps() {
    this.setState({
      recommendations: this.props.recommendations,
      loading: this.props.loading,
      partnerProfiles: this.props.partnerProfiles
    });
  }

  componentDidMount() {
    window.addEventListener("resize", () => this.loadMobile());
  }

  static getSlidesPerView(hasProducts: boolean, hasPartners: boolean) {
    let width = window.innerWidth;
    if (hasPartners) {
      if (width >= 0 && width <= Config.mobileMaxWidth) {
        if (width < 500)
          return 2.2;
        else if (width < 800 || width <= Config.mobileMaxWidth)
          return 2.4;
      } else {
        if (width < 1000) return 2;
        else if (width < 1400) return 3;
        else if (width < 1600) return 4;
        else return 5;
      }
    } else if (hasProducts) {
      if (width >= 0 && width <= Config.mobileMaxWidth) {
        if (width < 500)
          return 2.2;
        else if (width < 800 || width <= Config.mobileMaxWidth)
          return 2.4;
      } else {
        if (width < 1000) return 2;
        else if (width < 1200) return 3;
        else if (width < 1600) return 4;
        else return 5;
      }
    } else {
      return 3;
    }
  }

  getPartner(partnerID: number): Shop | null {
    if (!this.state.partnerProfiles)
      return null;

    for (let p of this.state.partnerProfiles) {
      if (p.id === partnerID)
        return p;
    }
    return null;
  }

  render() {
    return (
      <>
        {this.state.loading && (
          <LoaderWrapper>
            <Loader visible={this.state.loading} type="ThreeDots" color="rgb(80,80,80)" height={20} width={100}/>
          </LoaderWrapper>
        )}
        {this.state.loading === false && this.state.recommendations?.map((record, i) => {
          let hasPartners = record.partnerProfiles !== undefined && record.partnerProfiles.length > 0;
          let hasProducts = record.products !== undefined && record.products.length > 0;
          return (
            <>
              <h2>{record.title}</h2>
              {!hasPartners && !hasProducts && <p>{record.emptyTitle}</p>}
              {(hasPartners || hasProducts) && (
                <SwiperContainer>
                  <Swiper
                    grabCursor
                    autoHeight={true}
                    initialSlide={0}
                    spaceBetween={10}
                    slidesOffsetBefore={30}
                    slidesOffsetAfter={30}
                    slidesPerView={DynamicOffersDiv.getSlidesPerView(hasProducts, hasPartners)}
                    onSwiper={(s) => (this.swiperRefs[i] = s)}
                    navigation={{}}
                  >
                    {hasProducts && record.products.sort((x, y) => sortRestaurantsAndFoodsByOpenStatus(this.getPartner(x.partnerID), this.getPartner(y.partnerID))).map((product, j) => {
                      let partnerProfile = this.getPartner(product.partnerID);
                      return (
                        <SwiperSlide>
                          <ProductDiv
                            key={j}
                            product={product}
                            partnerProfile={partnerProfile}
                            layout={"tall"}
                            handleClick={() => handleProductClicked(product, partnerProfile)}
                          />
                        </SwiperSlide>
                      );
                    })
                    }
                    {hasPartners && record.partnerProfiles.sort(sortRestaurantsAndFoodsByOpenStatus).map((partnerProfile, j) => {
                      if (partnerProfile.list)
                        return (
                          <SwiperSlide key={j}>
                            <ShopComponent
                              onClick={() => handlePartnerProfileClicked(partnerProfile)}
                              partnerProfile={partnerProfile}
                              density={1}
                              width={"100%"}
                            />
                          </SwiperSlide>
                        );
                      else
                        return <React.Fragment key={j}/>;
                    })}
                  </Swiper>
                </SwiperContainer>
              )}
            </>
          );
        })
        }
      </>
    );
  }
}

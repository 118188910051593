import React, { Component } from "react";
import styled, { css } from "styled-components";
import EventSystem from "../../utils/EventSystem";
import AddressPicker from "../addresspicker/AddressPicker";
import ContextSystem from "../../utils/ContextSystem";
import { Address } from "../../model/Address";
import Language, { Names } from "../../utils/Language";

const OuterWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 22;
  transform: translateY(100%);
  transition: transform 500ms ease-in-out, background-color 400ms ease-in-out;
  //backdrop-filter 100ms ease-in-out 500ms;

  ${({opened}) => opened === true && css`
    transform: translateY(0);
    backdrop-filter: blur(2px);
    background-color: rgba(0,0,0,0.20);
  `};

  color: var(--blundee_color_normal);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 800px) {
    align-items: flex-end;
    justify-content: flex-end;
  }
`;

const Wrapper = styled.div`
  width: 500px;
  background-color: var(--blundee_background);
  padding: 20px;
  border-radius: 10px;
  min-height: 50vh;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: auto;

  & > h1 {
    font-family: var(--blundee_font_bold);
    font-size: 16pt;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
    justify-content: flex-start;
  }
`;

export default class AddressPickerPopup extends Component {
  static addressPickerID = ContextSystem.getNextUniqueID();
  timeOut = undefined;
  cb = undefined;

  state: {
    opened: boolean,
    address: Address | null,
    language: number,
  } = {
    opened: false,
    address: null,
    language: ContextSystem.language,
  };

  close(fireNotSetupEvent: boolean) {
    if (fireNotSetupEvent) {
      EventSystem.publish(EventSystem.events.addressPickerClosedWithoutSetup);
    }
    this.setState({opened: false, address: null});

    //Timeout for design only (transition time)
    this.timeOut = setTimeout(() => {
      EventSystem.publish(EventSystem.events.emptyAddressPicker, {callerID: AddressPickerPopup.addressPickerID});
    }, 200);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", (e) => {
      if (this.state.opened && e.key === "Escape") {
        this.close(true);
      }
    });
  }

  componentDidMount() {
    EventSystem.subscribe(EventSystem.events.open_address_picker_popup, ({address, cb}) => {
      if (!cb)
        return;
      this.cb = cb;

      if (this.timeOut)
        clearTimeout(this.timeOut);

      this.setState({opened: true, address: address ? address : undefined});
    });

    EventSystem.subscribe(EventSystem.events.addressPicked, ({selectedAddress, callerID}) => {
      if (callerID !== AddressPickerPopup.addressPickerID)
        return;

      this.close(false);
      if (this.cb)
        this.cb(selectedAddress);
      this.cb = undefined;
    });

    EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({language}) => {
      if (language !== undefined) {
        this.setState({language});
      }
    });

    document.addEventListener("keydown", (e) => {
      if (this.state.opened && e.key === "Escape") {
        this.close(true);
      }
    });
  }

  render() {
    return (
      <OuterWrapper opened={this.state.opened} onClick={() => this.close(true)}>
        {this.state.opened &&
        <Wrapper onClick={(e) => e.stopPropagation()}>
          <h1>{Language.getName(Names.SelectAddress)}</h1>
          <AddressPicker address={this.state.address} callerID={AddressPickerPopup.addressPickerID}/>
        </Wrapper>
        }
      </OuterWrapper>
    );
  }
}

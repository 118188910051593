import React, { Component } from "react";
import Language, { Names } from "../utils/Language";
import ContextSystem from "../utils/ContextSystem";
import EventSystem from "../utils/EventSystem";

export default class NotThereYet extends Component {
  eventIDs: number[] = [];

  state: {
    language: number,
  } = {
    language: ContextSystem.language,
  }

  componentWillUnmount() {
    for (let eventID of this.eventIDs) {
      EventSystem.unsubscribe(eventID);
    }
  }

  componentDidMount() {
    this.eventIDs = [];
    let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({language}) => {
      if (language !== undefined) {
        this.setState({language});
      }
    });
    this.eventIDs.push(eid);
  }

  render() {
    return (
      <p>
        {Language.getName(Names.NotThereYet)}
      </p>
    );
  }
}

import React from "react";
import { Route, Switch } from "react-router-dom";
import HomePage from "../pages/HomePage";
import BrowsePage from "../pages/BrowsePage/BrowsePage";
import ASZFPage from "../pages/AszfPage";
import RestaurantPage from "../pages/RestaurantPage";
import ErrorPage from "../pages/ErrorPage";
import ProfilePage from "../pages/ProfilePage";
import PaymentDonePage from "../pages/PaymentDonePage";
import JoinUsPage from "../pages/JoinUsPage";
import WhatItIsAboutPage from "../pages/WhatItIsAboutPage";
import PasswordResetPage from "../pages/PasswordResetPage";
import StartPage from "../pages/StartPage";
import ReservationPage from "../pages/ReservationPage";
import BugReportPage from "../pages/_notused_AboutPages/BugReportPage";


function WebRoutes() {
  return (
    <Switch>
      <Route path={"/"} component={BrowsePage} exact/>
      <Route path={"/start"} component={StartPage} exact/>
      <Route path={"/kezdolap"} component={HomePage} exact/>
      <Route path={"/bongeszes"} component={BrowsePage} exact/>
      <Route path={"/aszf"} component={ASZFPage} exact/>
      <Route path={"/fizetes-vege"} component={PaymentDonePage} exact/>

      <Route path={"/etterem/:restaurant/:product?"} component={RestaurantPage} exact/>
      <Route path={"/e/:restaurant/:product?"} component={RestaurantPage} exact/>
      <Route path={"/shop/:restaurant/:product?"} component={RestaurantPage} exact/>
      <Route path={"/s/:restaurant/:product?"} component={RestaurantPage} exact/>
      <Route path={"/asztal/:qrid"} component={RestaurantPage} exact/>
      <Route path={"/table/:qrid"} component={RestaurantPage} exact/>

      <Route path={"/profil"} component={ProfilePage} exact/>
      <Route path={"/foglalas"} component={ReservationPage} exact/>
      <Route path={"/mi-ez-a-blundee"} component={WhatItIsAboutPage} exact/>
      <Route path={"/csatlakozas"} component={JoinUsPage} exact/>
      <Route path={"/uj-jelszo/:token"} component={PasswordResetPage} exact/>
      <Route path={"/hiba"} component={BugReportPage} exact/>
      {/*<Route path={"/:restaurant/:product?"} component={RestaurantPage} exact/>*/}
      <Route component={ErrorPage}/>
    </Switch>
  );
}

export default WebRoutes;

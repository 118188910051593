import { CartStorage } from "./CartStorage";
import Config from "./Config";

export function upgradeLocalstorageIfNeeded() {
  if (!window.localStorage.getItem("cartVersionNumber")) {
    CartStorage.clearCart();
  } else {
    if (parseInt(window.localStorage.getItem("cartVersionNumber")) < Config.cartVersionNumber) {
      CartStorage.clearCart();
    }
  }
  window.localStorage.setItem("cartVersionNumber", Config.cartVersionNumber);
}

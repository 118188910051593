// noinspection JSUnusedGlobalSymbols

import { Profile } from "./Profile";
import { Address } from "./Address";
import AbstractModel from "./AbstractModel";
import Language, { Names } from "../utils/Language";
import { ShippingMethods } from "./ShippingPrice";
import { Coupon } from "./Coupon";
import { TranslatableString } from "./Product";

export class OrderState extends AbstractModel {
  static WAITING_FOR_ONLINE_PAYMENT: number = 2;
  static NEW: number = 1;
  static CONFIRMED: number = 3;
  static DECLINED: number = 4;
  static DELETED: number = 5;
  static READY: number = 6;
  static FAILED_TRANS: number = 7;
  static DONE: number = 8;
  static PREPARING: number = 9;

  comment: string;
  dateTime: Date;
  orderID: number;
  status: number;

  static getName(status: number, shippingMode: number, scheduled: boolean = false) {
    switch (status) {
      case OrderState.NEW:
        return Language.getName(Names.OrderStateNew);
      case OrderState.CONFIRMED:
        if (scheduled)
          return Language.getName(Names.OrderStateConfirmedScheduled);
        else
          return Language.getName(Names.OrderStateConfirmed);
      case OrderState.DECLINED:
        return Language.getName(Names.OrderStateDeclined);
      case OrderState.DELETED:
        return Language.getName(Names.OrderStateDeleted);
      case OrderState.DONE:
        if (shippingMode === ShippingMethods.AT_PLACE)
          return Language.getName(Names.OrderStateDoneAtPlace);
        return Language.getName(Names.OrderStateDone);
      // case OrderState.PAYED_ONLINE:
      //     return "Online fizetve";
      case OrderState.READY:
        if (shippingMode === ShippingMethods.PICKUP)
          return Language.getName(Names.OrderStateReadyTakeaway);
        else if (shippingMode === ShippingMethods.DELIVERY)
          return Language.getName(Names.OrderStateReadyDelivery);
        else if (shippingMode === ShippingMethods.AT_PLACE)
          return Language.getName(Names.OrderStateReadyAtPlace);
        return "";
      case OrderState.WAITING_FOR_ONLINE_PAYMENT:
        return Language.getName(Names.WaitingForOnlinePayment);
      case OrderState.FAILED_TRANS:
        return Language.getName(Names.OrderStateFailedTrans);
      case OrderState.PREPARING:
        return Language.getName(Names.Preparing);
      default:
        return Language.getName(Names.OrderState3Dot);
    }
  }
}

export class OrderExtra extends AbstractModel {
  name: TranslatableString;
  partnerID: number;
  orderID: number;
  qty: number;
  price: number;
  orderProductID: number;
  extraID: number;
  extraGroupID: number;
  saleID: number;
  total: number;
}

export class OrderProduct extends AbstractModel {
  name: TranslatableString;
  details: TranslatableString;
  image1: string;
  image2: string;
  image3: string;
  categoryID: number;
  qty: number;
  partnerID: number;
  offerID: number;
  menuID: number;
  orderID: number;
  price: number;
  extrasPrice: number;
  itemTotalPrice: number;
  extras: OrderExtra[];
  type: number;
  productIndex: number;
  productID: number;
  versionID: number;

  /**
   * @param op OrderProduct which it's applied on
   * @param percentage like 15% -> 15.0, 50% -> 50.0
   */
  static applyCouponPercentage(op: OrderProduct, percentage: number) {
    if (!op)
      return;

    let multiplier = (1 - percentage / 100);
    op.price = op.originalPrice * multiplier;
    op.extrasPrice = op.originalExtrasPrice * multiplier;
    op.itemTotalPrice = op.originalItemTotalPrice * multiplier;
  }
}

export const OrderCouponModelTypes = {
  ORDER: 1,
  ORDER_PRODUCT: 2,
  ORDER_EXTRA: 3
};

export type OrderCouponModelType = $Values<typeof OrderCouponModelTypes>;

export class OrderUsedCouponID extends AbstractModel {
  usedCouponID: number;
  modelID: number;
  modelType: number;
  orderID: number;
  addedDate: Date;
  amount: number;
}

export class Order extends AbstractModel {
  number: string;
  comment: string;
  profile: Profile;
  productList: OrderProduct[];
  orderTotalPrice: number;
  date: Date;
  orderStates: OrderState[];
  partnerID: number;
  shippingMethod: number;
  shippingPrice: number;
  paymentMethod: number;
  address: Address;
  shippingPriceID: number;
  countryID: number;
  lastState: OrderState;
  dailyNumber: string;
  randomToken: string;
  tableReservationID: number;
  scheduleDate: Date;
  usedCoupons: OrderUsedCouponID[];
  _useCoupons: Coupon[];

  static isPaidFully(o: Order): boolean {
    if (!o)
      return false;

    if ([OrderState.DELETED, OrderState.DECLINED].includes(o.lastState.status))
      return true;

    return Order.totalPayment(o) >= o.orderTotalPrice;
  }

  static totalPayment(o: Order): number {
    if (!o)
      return 0;

    let total: number = 0;
    o.payments.forEach(p => p.enabled && p.payed && (total += p.amount - p.creditedAmount));
    o.subOrders.forEach(subOrder => total += Order.totalPayment(subOrder));
    return total;
  }
}

export class OrderPayment extends AbstractModel {
  orderID: number;
  txid: number;
  amount: number;
  creditedAmount: number;
  payed: boolean;
  paymentType: number;
}

export class OrderProductPayment extends AbstractModel {
  orderID: number;
  txid: number;
  amount: number;
  creditedAmount: number;
  orderPaymentID: number;
  orderProductID: number;
}

export class TableReservation extends AbstractModel {
  tableID: number;
  partnerID: number;
  profileID: number;
  orderIDs: number;
  numberOfPeople: number;
  addedDate: Date;
  start: Date;
  end: Date;
  changes: TableReservationChange[];
  comment: string;
  acceptedByRestaurant: boolean;
  note: string;
}

export class TableReservationChange extends AbstractModel {
  reservationID: number;
  lastStart: Date;
  lastEnd: Date;
  newStart: Date;
  newEnd: Date;
  addedDate: Date;
  partnerProfileID: number;
  cancelled: boolean;
}

export default class EventSystem {
  static events = {
    popFloatingAddressPicker: 1,
    open_register_popup: 2,
    open_pass_forgot_popup: 3,
    selectGlobalCategory: 4,
    hard_open_product_popup_intent: 5,
    order_placed: 7,
    online_payment_successful: 8,
    addressPickerClosedWithoutSetup: 9,
    toast: 10,
    restaurant_shipping_prices_loaded: 11,
    open_restaurant_info: 12,
    open_donation_selector: 13,
    redirectToProduct: 14,
    openRedirectToProduct: 15,
    show_confirmation_modal: 16,
    open_profile_details_popup: 17,
    redirectIntent: 18,
    contextSystemChanged: 19,
    emptyAddressPicker: 20,
    open_address_picker_popup: 21,
    open_login_popup: 22,
    citySelected: 23,
    addressSelected: 24,
    addressPicked: 25,
    hideCartChanged: 26,
    hideNavBarChanged: 27,
    navbarChanged: 28,
    googlePlacesScriptLoaded: 29,
    authentication_changed: 30,
    urlChanged: 31,
    cartUpdated: 32,
    restaurant_page_unmounted: 33,
    restaurant_page_mounted: 34,
    restaurant_loaded: 35,
    msg_sdk_loaded: 36,
    select_extras_finished: 37,
    sideMenu: 38,
    city_selected: 39,
    city_unselect: 40,
    popup_product: 43,
    addCombinedMenu: 42,
    cartCleared: 44,
    footer_unmounted: 45,
    footer_mounted: 46,
    openOrderFinishing: 47,
    open_floating_address_picker_popup: 48,
    open_reservation_popup: 49,
    open_start_page: 50,
    open_google_forms_modal: 51
  };

  static queue = {};
  static lastValues = {};
  static idCounter = 0;

  static publish(event, data): void {
    if (EventSystem?.queue === undefined || typeof EventSystem?.queue === "undefined" || typeof EventSystem.queue === "undefined") {
      return;
    }
    if (Object.keys(EventSystem.queue).length > 0) {
      if (typeof EventSystem.queue[event] !== "undefined" && EventSystem.queue[event].length > 0)
        for (let a of EventSystem.queue[event])
          a.callback(data);
    }
    EventSystem.lastValues[event] = data;
  }

  static unsubscribe(id) {
    let eventName;
    let index = -1;
    A: for (let event in EventSystem.queue) {
      let eventSubscribers = EventSystem.queue[event];
      for (let i in eventSubscribers) {
        if (eventSubscribers[i].id === id) {
          index = i;
          eventName = event;
          break A;
        }
      }
    }
    if (index >= 0) {
      EventSystem.queue[eventName].splice(index, 1);
    }
  }

  static subscribe(event, callback) {
    if (typeof EventSystem.queue[event] === "undefined") {
      EventSystem.queue[event] = [];
    }

    let id = EventSystem.idCounter;
    EventSystem.queue[event].push({callback, id});

    // const lastValue = EventSystem.lastValues[event];
    // if (typeof lastValue !== "undefined")
    //   callback(lastValue);

    EventSystem.idCounter++;
    return id;
  }
}

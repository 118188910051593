import React, { Component } from "react";
import ContextSystem from "../utils/ContextSystem";
import { Form as Form_Org, Input as Input_Org } from "./about/FormElements";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import Loader from "react-loader-spinner";
import {
  FormDonePopUpOuterWrapper,
  FormDonePopUpWrapper,
  LoaderOuterWrapper,
  LoaderWrapper
} from "../pages/JoinUsPage";
import styled from "styled-components";
import { BlundeeButton as BlundeeButton_Org } from "./Form";
import validateEmail from "../utils/EmailValidator";
import { RestaurantAPI } from "../utils/api/RestaurantAPI";
import Toast from "./Toast";
import EventSystem from "../utils/EventSystem";
import Language, { Names } from "../utils/Language";

const Form = styled(Form_Org)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;

  width: 90%;

  @media screen and (min-width: 801px) and (max-width: 1400px) {
    width: 93%;
  }

  @media screen and (max-width: 800px) {
    width: 98%;
  }
`;

const InputGroup = styled.div`
  padding: 10px 12px;
  margin: ${({border}) => border ? "10px 0" : "0"};
  box-shadow: ${({border}) => border ? "0 0 5px 1px #222222" : "none"};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: ${({justifycontent}) => justifycontent ? justifycontent : "space-between"};
  width: ${({width}) => width != null ? width : "48%"};

  p {
    width: 100%;
  }

  @media screen and (max-width: 800px) {
    width: ${({mwidth}) => mwidth != null ? mwidth : "100%"};
    box-shadow: none;
    margin: 0;
  }
`;

const BlundeeButton = styled(BlundeeButton_Org)`
  margin-left: 7px;
  margin-right: 7px;
  @media screen and (max-width: 1400px) and (min-width: 801px) {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px 25px;
    font-size: 16px;
  }
`;

const Input = styled(Input_Org)`
  width: ${({width}) => width ? width : "100%"};

  @media screen and (max-width: 1400px) and (min-width: 801px) {
    margin: 5px 0;
    padding: 10px;
  }
`;

export default class PartnerRegForm extends Component {
  uniqueID;

  state: {
    contactFirstName: string,
    contactLastName: string,
    contactTel: string,
    contactEmail: string,

    restaurantAddressPostalCode: string,
    restaurantAddressCity: string,
    restaurantAddressStreet: string,

    restaurantName: string,
    // restaurantURL: string,

    loading: boolean,
    donePopUpOpened: boolean,
    language: number,
  } = {
    contactFirstName: "",
    contactLastName: "",
    contactTel: "",
    contactEmail: "",

    restaurantAddressPostalCode: "",
    restaurantAddressCity: "",
    restaurantAddressStreet: "",

    restaurantName: "",
    // restaurantURL: "",

    loading: false,
    donePopUpOpened: false,
    language: ContextSystem.language,
  };

  eventIDs: number[] = [];

  componentWillUnmount() {
    for (let eventID of this.eventIDs) {
      EventSystem.unsubscribe(eventID);
    }
  }

  componentDidMount() {
    this.eventIDs = [];
    let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({language}) => {
      if (language !== undefined) {
        this.setState({language});
      }
    });
    this.eventIDs.push(eid);
  }

  constructor(props) {
    super(props);
    this.uniqueID = ContextSystem.getNextUniqueID();
  }

  send(e) {
    e.preventDefault();
    this.setState({loading: true});

    if (
      this.state.contactFirstName === "" ||
      this.state.contactLastName === "" ||
      this.state.contactTel === "" ||
      this.state.contactEmail === "" ||
      this.state.restaurantAddressPostalCode === "" ||
      this.state.restaurantAddressCity === "" ||
      this.state.restaurantAddressStreet === "" ||
      this.state.restaurantName === ""
    ) {
      this.setState({loading: false});
      Toast.showToast(Language.getName(Names.YouShouldntLeaveAnyFieldEmpty));
      return;
    }

    if (this.state.contactFirstName.length < 2 || this.state.contactFirstName.length > 50 || this.state.contactLastName.length < 2 || this.state.contactLastName.length > 50) {
      this.setState({loading: false});
      Toast.showToast(Language.getName(Names.GivenNameIsNotValid));
      return;
    }
    if (!validateEmail(this.state.contactEmail)) {
      this.setState({loading: false});
      Toast.showToast(Language.getName(Names.GivenEmailIsNotValid));
      return;
    }
    let phoneNumber = parsePhoneNumberFromString(this.state.contactTel, "HU");
    if (!(phoneNumber && phoneNumber.country === "HU" && phoneNumber.isValid() === true)) {
      this.setState({loading: false});
      Toast.showToast(Language.getName(Names.GivenPhoneNumberIsNotValid));
      return;
    }
    if (this.state.restaurantAddressPostalCode.length !== 4 || !this.state.restaurantAddressPostalCode.match("^[0-9]{4}$")) {
      this.setState({loading: false});
      Toast.showToast(Language.getName(Names.GivenPostalCodeIsNotValid));
      return;
    }
    if (this.state.restaurantAddressCity.length < 2) {
      this.setState({loading: false});
      Toast.showToast(Language.getName(Names.GivenPostalCodeIsTooShort));
      return;
    }
    if (this.state.restaurantAddressStreet.length < 5 || this.state.restaurantAddressStreet.length > 50) {
      this.setState({loading: false});
      Toast.showToast(Language.getName(Names.GivenAddressTextIsNotValid));
      return;
    }
    if (this.state.restaurantName.length < 3 || this.state.restaurantName.length > 30) {
      this.setState({loading: false});
      Toast.showToast(Language.getName(Names.GivenShopNameIsNotValid));
      return;
    }

    RestaurantAPI.register(
      this.state.contactFirstName, this.state.contactLastName, this.state.contactTel, this.state.contactEmail, this.state.restaurantAddressPostalCode, this.state.restaurantAddressCity, this.state.restaurantAddressStreet, this.state.restaurantName,
      (res) => {
        if (res.error === 0) {
          this.setState({
            contactFirstName: "",
            contactLastName: "",
            contactTel: "",
            contactEmail: "",
            restaurantAddressPostalCode: "",
            restaurantAddressCity: "",
            restaurantAddressStreet: "",
            restaurantName: "",
            donePopUpOpened: true
          });
        }
        this.setState({loading: false});
      }
    );
  }

  render() {
    return (
      <>
        <LoaderOuterWrapper>
          <LoaderWrapper loading={this.state.loading}>
            <Loader visible={this.state.loading} type="ThreeDots" color="#DE0B18" height={20} width={100}/>
          </LoaderWrapper>
          <Form id={"regForm" + this.uniqueID} onSubmit={(e) => this.send(e)}>
            <InputGroup border={true}>
              <h3>{Language.getName(Names.ContactDetails)}</h3>
              <Input
                id={"contactLastName"}
                name={"contactLastName"}
                value={this.state.contactLastName}
                type="text"
                autoComplete="family-name"
                onChange={(e) => this.setState({contactLastName: e.target.value})}
                placeholder={Language.getName(Names.LastName)}
              />
              <Input
                id={"contactFirstName"}
                name={"contactFirstName"}
                value={this.state.contactFirstName}
                type="text"
                autoComplete="given-name"
                onChange={(e) => this.setState({contactFirstName: e.target.value})}
                placeholder={Language.getName(Names.FirstName)}
              />
              <Input
                id={"contactTel"}
                name={"contactTel"}
                value={this.state.contactTel}
                type="text"
                autoComplete="tel"
                onChange={(e) => this.setState({contactTel: e.target.value})}
                placeholder={Language.getName(Names.PhoneNumber)}
              />
              <Input
                id={"contactEmail"}
                name={"contactEmail"}
                value={this.state.contactEmail}
                type="email"
                autoComplete="email"
                onChange={(e) => this.setState({contactEmail: e.target.value})}
                placeholder={Language.getName(Names.EmailAddress)}
              />
            </InputGroup>
            <InputGroup border={true}>
              <h3>{Language.getName(Names.RestaurantDetails)}</h3>
              <Input
                id={"restaurantName"}
                name={"restaurantName"}
                value={this.state.restaurantName}
                type="text"
                autoComplete="off"
                onChange={(e) => this.setState({restaurantName: e.target.value})}
                placeholder={Language.getName(Names.RestaurantName)}
              />
              {/*<p>*/}
              {/*  URL cím az étterem linkjét jelöli. Fontos, ez később nem változtatható meg!<br/>*/}
              {/*  Példa: https://www.enniakarok.hu/etterem/az-en-ettermem<br/>*/}
              {/*  Étterem URL címe:*/}
              {/*</p>*/}
              {/*<Input*/}
              {/*  id={"restaurantURL"}*/}
              {/*  name={"restaurantURL"}*/}
              {/*  value={this.state.restaurantURL}*/}
              {/*  type="text"*/}
              {/*  autoComplete="off"*/}
              {/*  onChange={(e) => this.setState({ restaurantURL: e.target.value })}*/}
              {/*  placeholder="az-en-ettermem"*/}
              {/*/>*/}
              <h3>{Language.getName(Names.RestaurantAddress)}</h3>
              <Input
                id={"restaurantAddressPostalCode"}
                name={"restaurantAddressPostalCode"}
                value={this.state.restaurantAddressPostalCode}
                width={"33%"}
                type="text"
                autoComplete="postal-code"
                onChange={(e) => this.setState({restaurantAddressPostalCode: e.target.value})}
                placeholder={Language.getName(Names.PostalCode)}
              />
              <Input
                id={"restaurantAddressCity"}
                name={"restaurantAddressCity"}
                value={this.state.restaurantAddressCity}
                width={"64%"}
                type="text"
                autoComplete="address-level2"
                onChange={(e) => this.setState({restaurantAddressCity: e.target.value})}
                placeholder={Language.getName(Names.City)}
              />
              <Input
                id={"restaurantAddressStreet"}
                name={"restaurantAddressStreet"}
                value={this.state.restaurantAddressStreet}
                type="text"
                autoComplete="street-address"
                onChange={(e) => this.setState({restaurantAddressStreet: e.target.value})}
                placeholder={Language.getName(Names.RestaurantAddressStreet)}
              />
            </InputGroup>
            <InputGroup width={"100%"} border={false} justifycontent={"center"}>
              <BlundeeButton style={{alignSelf: "center"}}>{Language.getName(Names.CreateRestaurant)}</BlundeeButton>
            </InputGroup>
          </Form>
        </LoaderOuterWrapper>

        <FormDonePopUpOuterWrapper opened={this.state.donePopUpOpened}>
          <FormDonePopUpWrapper>
            <h2 style={{margin: "10px"}}>
              {Language.getName(Names.RestaurantAddedThanks)}
            </h2>
            <p>
              {Language.getName(Names.RestaurantAddedThanksDetails)}
            </p>
            <BlundeeButton onClick={() => this.setState({popUpRegOpened: false, donePopUpOpened: false})}>{Language.getName(Names.Ok)}</BlundeeButton>
          </FormDonePopUpWrapper>
        </FormDonePopUpOuterWrapper>
      </>
    );
  }
}

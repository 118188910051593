import React, { Component } from "react";
import { Map as MapCore } from "pigeon-maps";
// import Marker from "pigeon-marker";
// import Overlay from "pigeon-overlay";
import { FaMapMarkerAlt } from "react-icons/fa";
import styled from "styled-components";
import { maptiler } from "pigeon-maps/providers";

// function mapTilerProvider(x: number, y: number, z: number, dpr: number) {
//   return `https://a.tile.openstreetmap.org/${z}/${x}/${y}.png`;
// }

const mapTilerProvider = maptiler(/*Config.mapTilerAPIKey*/"", "streets");

interface MapProps {
  width: number;
  height: number;
  center: [number, number];
  zoom: number;
  onBoundsChanged: ({ center: [number, number], zoom: number })=>{}
}

interface MapState {
  children: any[];
  width: number;
  height: number;
}

export default class Map extends Component<MapProps, MapState> {
  state: MapState = {
    children: [],
    width: 0,
    height: 0
  };
  childrenID: number = 0;
  children: any = [];

  addCurrentPosition(
    point: [number, number],
    style: { sizeCircle: number; sizeBorder: number; colorBorder: string; colorFill: string } = {}
  ): number {
    let r: number = (style.sizeCircle ?? 24) / 2;
    let border: number = style.sizeBorder ?? 4;
    let c: number = r + border;
    let viewBoxMax: number = c * 2;
    let viewBox: string = "0 0 " + viewBoxMax + " " + viewBoxMax;
    let overlayChild = (
      <div style={{ width: viewBoxMax + "px", height: viewBoxMax + "px" }}>
        <svg viewBox={viewBox}>
          <circle cx={c} cy={c} r={r} stroke={style.colorBorder ?? "#34a4eb"} strokeWidth={border}
                  fill={style.colorFill ?? "#0e388c"}/>
        </svg>
      </div>
    );
    let offset = [-c, -c];
    return this.addOverlay(point, overlayChild, true, offset);
  }

  addMarker(
    point: [number, number],
    style: { markerSize: string; color: string; colorHover: string; colorActive: string } = {},
    onClick: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>, anchor: [number, number]) => void) | null = null
  ): number {
    // noinspection RequiredAttributes
    this.children[this.childrenID] = (
      <Marker
        key={this.childrenID}
        anchor={point}
        markerSize={style.markerSize}
        color={style.color}
        colorHover={style.colorHover}
        colorActive={style.colorActive}
        onClick={(event, anchor: [number, number]) => {
          let e3: React.MouseEvent<HTMLDivElement, MouseEvent> = event;
          if (onClick)
            onClick(event, anchor);
        }}
      />
    );
    this.setState({ children: this.children });
    return this.childrenID++;
  }

  addOverlay(point: [number, number], overlayChildren: any, center = false, offset = [0, 0]): number {
    // noinspection RequiredAttributes
    this.children[this.childrenID] = (
      <Overlay key={this.childrenID} anchor={point} offset={[offset[0] * -1, offset[1] * -1]} center={center}>
        {overlayChildren}
      </Overlay>
    );
    this.setState({ children: this.children });
    return this.childrenID++;
  }

  // noinspection JSUnusedGlobalSymbols
  addPolygon(coordinatesArray: number[][], style: React.CSSProperties, maxLat: number = -90, minLong: number = 180): number {
    if (maxLat === -90 || minLong === 180) {
      for (let currCoordinate of coordinatesArray) {
        if (currCoordinate[0] > maxLat) {
          maxLat = currCoordinate[0];
        }
        if (currCoordinate[1] < minLong) {
          minLong = currCoordinate[1];
        }
      }
    }
    // noinspection RequiredAttributes
    this.children[this.childrenID] = (
      <Overlay key={this.childrenID} anchor={[maxLat, minLong]} isPolygonOverlay>
        <Polygon coordinatesArray={coordinatesArray} style={style}/>
      </Overlay>
    );
    this.setState({ children: this.children });
    return this.childrenID++;
  }

  removeChild(childrenID: number) {
    this.children[childrenID] = null;
    this.setState({ children: this.children });
    return this.childrenID++;
  }

  // noinspection JSUnusedGlobalSymbols
  getChildPoint(index: number): [number, number] | undefined {
    if (!this.state.children || !this.state.children[index]) {
      return undefined;
    }

    let child = this.state.children[index];
    return child.props.anchor;
  }

  static isPointInsidePolygon(point: [number, number], polygon: number[][]): boolean {
    let x = point[0];
    let y = point[1];

    let inside = false;
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      let xi = polygon[i][0];
      let yi = polygon[i][1];
      let xj = polygon[j][0];
      let yj = polygon[j][1];

      let intersect1 = yi > y;
      let intersect2 = yj > y;
      let intersect3 = x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      let intersect = intersect1 !== intersect2 && intersect3;
      if (intersect) inside = !inside;
    }

    return inside;
  }

  componentDidUpdate(prevProps: MapProps, prevState: MapState, snapshot: any) {
    if (this.props.height !== this.state.height)
      this.setState({ height: this.props.height });
    if (this.props.width !== this.state.width)
      this.setState({ width: this.props.width });
  }

  componentDidMount() {
    this.setState({ height: this.props.height, width: this.props.width });
  }

  render() {
    return (
      <MapCore
        //provider={mapTilerProvider}
        center={this.props.center || [47.481983, 19.050775]}
        minZoom={3}
        zoom={this.props.zoom || 14}
        maxZoom={19}
        width={this.state.width}
        height={this.state.height}
        onBoundsChanged={({ center, zoom, bounds, initial }) => {
          this.setState({ zoom, center });
          if (this.props.onBoundsChanged)
            this.props.onBoundsChanged({ center, zoom, bounds, initial });
        }}
      >
        {this.props.children}
      </MapCore>
    );
  }
}

const MarkerWrapper = styled.div.attrs({ className: "pigeon-click-block" })`
  width: ${({ markerSize }) => markerSize ?? "32px"};
  height: ${({ markerSize }) => markerSize ?? "32px"};

  & > svg {
    font-size: ${({ markerSize }) => markerSize ?? "32px"};
    color: ${({ color }) => color ?? "#7ba2b1"};

    &:hover {
      color: ${({ colorHover }) => colorHover ?? "#95c5d8"};
    }

    &:active {
      color: ${({ colorActive }) => colorActive ?? "#74919c"};
    }
  }
`;

interface MarkerProps {
  left: number;
  top: number;
  latLngToPixel: any;
  pixelToLatLng: any;
  anchor: [number, number];
  markerSize: string;
  color: string;
  colorHover: string;
  colorActive: string;
  onClick: any;
  onContextMenu: any;
  onMouseOver: any;
  onMouseOut: any;
}

export class Marker extends Component<MarkerProps> {
  handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    this.props.onClick && this.props.onClick(event, this.props.anchor);
  }

  handleContextMenu(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    this.props.onContextMenu && this.props.onContextMenu(event, this.props.anchor);
  }

  handleMouseOver(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    this.props.onMouseOver && this.props.onMouseOver(event, this.props.anchor);
  }

  handleMouseOut(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    this.props.onMouseOut && this.props.onMouseOut(event, this.props.anchor);
  }

  render() {
    let left = this.props.markerSize ? (parseInt(this.props.markerSize) / 8) * 3 + 4 : 16;
    let top = this.props.markerSize ? parseInt(this.props.markerSize) : 32;
    const style: any = {
      position: "absolute",
      transform:
        "translate(" + (this.props.left ? this.props.left - left : left * -1) + "px, " + (this.props.top ? this.props.top - top : top * -1) + "px)"
    };

    return (
      <MarkerWrapper
        style={style}
        onClick={this.handleClick.bind(this)}
        onContextMenu={this.handleContextMenu.bind(this)}
        onMouseOver={this.handleMouseOver.bind(this)}
        onMouseOut={this.handleMouseOut.bind(this)}
        markerSize={this.props.markerSize}
        color={this.props.color}
        colorHover={this.props.colorHover}
        colorActive={this.props.colorActive}
      >
        <FaMapMarkerAlt/>
      </MarkerWrapper>
    );
  }
}

interface OverlayProps {
  left: number;
  top: number;
  latLngToPixel: any;
  pixelToLatLng: any;
  anchor: [number, number];
  offset: [number, number];
  className: string;
  style: any;
  isPolygonOverlay: boolean;
  center: boolean;
}

export class Overlay extends Component<OverlayProps> {
  ref: HTMLDivElement | null;
  initialized: boolean = false;

  render() {
    const childrenWithProps = React.Children.map(this.props.children, (child) => {
      if (this.props.isPolygonOverlay && React.isValidElement(child)) {
        return React.cloneElement(child, {
          latLngToPixel: this.props.latLngToPixel,
          pixelToLatLng: this.props.pixelToLatLng,
          left: this.props.left,
          top: this.props.top
        });
      }
      return child;
    });

    let left = 0;
    let top = 0;
    if (this.props.center) {
      left = (this.ref?.offsetWidth ?? 0) / 2;
      top = (this.ref?.offsetHeight ?? 0) / 2;
    }

    if (!this.initialized) {
      this.initialized = true;
      this.setState({});
    }

    return (
      <div
        style={{
          position: "absolute",
          transform:
            "translate(" +
            (this.props.left ? this.props.left - left : left * -1) +
            "px, " +
            (this.props.top ? this.props.top - top : top * -1) +
            "px)",
          ...this.props.style
        }}
        className={this.props.className || ""}
        ref={(r) => (this.ref = r)}
      >
        {childrenWithProps}
      </div>
    );
  }
}

interface PolygonProps {
  left: number;
  top: number;
  latLngToPixel: any;
  pixelToLatLng: any;
  coordinatesArray: number[][];
  style: React.CSSProperties;
}

interface PolygonState {
  width: number;
  height: number;
}

export class Polygon extends Component<PolygonProps, PolygonState> {
  ref: SVGSVGElement | null;
  state = { width: 0, height: 0 };
  resizeTimeout: NodeJS.Timeout;

  componentDidMount() {
    this.resizeSVG();
  }

  componentDidUpdate(prevProps: Readonly<PolygonProps>, prevState: Readonly<PolygonState>, snapshot) {
    if (prevProps.left !== this.props.left || prevProps.top !== this.props.top) {
      this.resizeSVG();
    }
  }

  resizeSVG() {
    if (!this.resizeTimeout)
      this.resizeTimeout = setTimeout(() => {
        this.resizeTimeout = undefined;
        if (this.ref) {
          let bbox = this.ref?.getBBox();
          let width = bbox.x + bbox.width + bbox.x;
          let height = bbox.y + bbox.height + bbox.y;
          if (width > this.state.width || height > this.state.height) {
            this.setState({ width: width, height: height });
          }
        } else {
          this.resizeSVG();
        }
      }, 100);
  }

  render() {
    if (this.props.coordinatesArray.length < 2) {
      return <></>;
    }

    let points: string = "";
    for (let coordinateIndex = 0; coordinateIndex < this.props.coordinatesArray.length; coordinateIndex++) {
      let pixel = this.props.latLngToPixel(this.props.coordinatesArray[coordinateIndex]);
      points += pixel[0] - (this.props.left ? this.props.left : 0) + "," + (pixel[1] - (this.props.top ? this.props.top : 0)) + " ";
    }
    points = points.trimEnd();
    return (
      <svg width={this.state.width} height={this.state.height} ref={(r) => (this.ref = r)}>
        <polygon points={points} style={this.props.style || {
          stroke: "rgb(255, 0, 0)",
          strokeWidth: 2,
          fill: "rgba(127, 0, 0, 0.3)"
        }}/>
      </svg>
    );
  }
}

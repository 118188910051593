import React, { Component } from "react";
import styled, { css } from "styled-components";
import { Input, BlundeeButton } from "../Form";
import { FaLocationArrow } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import EventSystem from "../../utils/EventSystem";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { GoogleMap, Marker, withGoogleMap } from "react-google-maps";
import { googleMapsCenterBP } from "../restaurantpage/RestaurantSidePanel";
import ContextSystem from "../../utils/ContextSystem";
import Toast from "../Toast";
import { Geolocation } from "@capacitor/geolocation";
import { Address } from "../../model/Address";
import { TranslatableString } from "../../model/Product";
import Language, { Names } from "../../utils/Language";
import { languages } from "../../pages/StartPage";
import { CountryLocale, CountryLocales } from "../../utils/Locale";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  position: relative;
`;

const InputWrapper = styled.div`
  height: 50px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  width: 100%;
`;

const LocationButton = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 18px;
  background-color: var(--blundee_background_light);
  font-size: 14pt;
  transition: background-color 200ms ease-in-out, color 200ms ease-in-out;

  ${({ isEmpty }) => isEmpty === true && css`
    &:hover {
      cursor: pointer;
      background-color: var(--blundee_yellow);
      color: var(--blundee_grey);
    }

    &:active {
      cursor: pointer;
      background-color: var(--blundee_yellow_active);
      color: var(--blundee_grey);
    }
  `}

  ${({ light }) => light === true && css`
    background-color: var(--blundee_background_light);
  `}
`;

const AddressInput = styled(Input)`
  background-color: var(--blundee_background_light);
  height: 100%;
  width: 220px;
  font-size: 10pt;
  font-family: var(--blundee_font_medium);
  margin: 0;
  padding: 25px 15px;
  border: none;
  border-radius: 0 4px 4px 0;
  color: var(--blundee_color_normal);

  transition: width 330ms ease-in-out, font-size 300ms ease-in-out;

  &:focus {
    border: none;
    box-shadow: none;
    width: 100%;
    font-size: 13.5pt;
  }
`;

const CommentInput = styled(AddressInput)`
  width: 0;
  height: 0;
  opacity: 0;
  margin: 0;
  padding: unset;
  border-radius: 4px;
  transition: width 330ms ease-in-out, font-size 300ms ease-in-out, opacity 200ms ease-in-out;

  ${({ show }) => show === true && css`
    width: 100%;
    height: 50px;
    opacity: 1;
    margin-top: 10px;
    padding: 25px 15px;
  `}
`;

const FinalizeButton = styled(BlundeeButton)`
  height: 40px;
  width: 110px;
  transition: opacity 200ms cubic-bezier(0.85, 0, 0.15, 1);

  position: sticky;
  bottom: 0;

  font-size: 11pt;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 15px 0 0 auto;
  opacity: 1;

  ${({ showButton }) => showButton === false && css`
    transition: opacity 200ms cubic-bezier(0.85, 0, 0.15, 1), width 0ms ease-in-out 200ms, margin 0ms ease-in-out 200ms,
    padding 0ms ease-in-out 200ms;
    width: 0;
    opacity: 0;
    padding-left: 0;
    padding-right: 0;
    border: none;
  `};

  @media screen and (max-width: 600px) {
    margin-left: auto;
    margin-top: 10px;
  }
`;

const Suggestion = styled.div`
  padding: 8px 10px;
  border-radius: 3px;
  font-family: var(--blundee_font_medium);
  font-size: 10pt;

  &:hover {
    background-color: var(--blundee_background);
    cursor: pointer;
  }

  ${({ active }) => active === true && css`
    background-color: var(--blundee_background_light);
  `}
`;

const UseMyLocationWrapper = styled.div`
  width: 0;
  height: 50px;
  position: absolute;
  top: 80px;
  left: 0;
  background-color: var(--blundee_background_light);
  border-radius: 4px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;

  opacity: 0;
  transition: filter 200ms ease-in-out, opacity 200ms ease-in-out, top 200ms ease-in-out, width 0ms ease-in-out 200ms;

  ${({ show }) => show === true && css`
    min-width: 170px;
    transition: filter 200ms ease-in-out, opacity 200ms ease-in-out, top 200ms ease-in-out, width 0ms ease-in-out;
    opacity: 1;
    top: 60px;
    width: calc(100% - 140px);
  `}
  & > span {
    flex-grow: 2;
    text-align: center;
    font-family: var(--blundee_font_medium);
  }

  ${({ showmap }) => showmap === false && css`
    &:hover {
      transition: filter 200ms ease-in-out, opacity 200ms ease-in-out, top 200ms ease-in-out, width 0ms ease-in-out;
      opacity: 1;
      top: 60px;
      width: calc(100% - 140px);
      min-width: 150px;
      filter: brightness(95%);
      cursor: pointer;
    }
  `}
`;

const Suggestions = styled.div`
  padding: 0;
  width: 100%;
  position: absolute;
  top: 60px;
  left: 0;
  background-color: var(--blundee_background_light);
  z-index: 3;
  height: 0;

  overflow: hidden;

  transition: height 100ms ease-in-out, top 300ms ease-in-out;

  ${({ show }) => show === true && css`
    height: fit-content;
  `}
  ${({ nohousenumberwarning }) => nohousenumberwarning === true && css`
    top: 75px;
  `}
`;

const MapContainer = styled.div`
  height: ${({ show }) => (show === true ? "450px" : "0")};
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 10px;
  transition: height 100ms ease-in-out;

  @media screen and (max-width: 600px) {
    height: ${({ show }) => (show === true ? "280px" : "0")};
  }
`;

const MyMapComponent = withGoogleMap((props) => (
  <GoogleMap zoom={props.zoom} center={props.center} defaultZoom={props.defaultZoom}
             defaultCenter={props.defaultCenter}
             defaultOptions={{ gestureHandling: "cooperative" }}
  >
    {props.children}
  </GoogleMap>
));

const NoHouseNumberWarning = styled.p`
  margin: 4px 0 0 4px;
  width: 100%;
  color: var(--blundee_color_thin);
  font-family: var(--blundee_font_medium);
  font-size: 11pt;
`;

class AddressPicker extends Component {
  state: {
    text: string,
    focused: boolean,
    googleApiLoaded: boolean,
    selectedAddress: Address & { longitude: number, latitude: number } | undefined,
    locationInFocus: boolean,
    gpsCoordinates: { lat: number, lng: number },
    comment: string,
    noHouseNumber: boolean,
    language: number,
  } = {
    text: "",
    focused: false,
    googleApiLoaded: ContextSystem.googleAPILoaded,
    selectedAddress: undefined,
    locationInFocus: false,
    gpsCoordinates: { lat: -1, lng: -1 },
    comment: "",
    noHouseNumber: false,
    language: ContextSystem.language
  };

  static async getLocation(): { lat: number, lng: number } | null {
    let pos: { lat: number, lng: number } = null;

    await Geolocation.getCurrentPosition(
      { enableHighAccuracy: true, timeout: 3000, maximumAge: 30000 }
    ).then(res => {
      pos = {};
      pos.lat = res.coords.latitude;
      pos.lng = res.coords.longitude;
    }).catch(reason => {
      console.log(reason);
    });

    return pos;
  }

  setAddress(address: Address): void {
    if (!address)
      return;

    let zipCode = ContextSystem.getZipCode(address.zipCodeID);
    let city = ContextSystem.getCityByID(zipCode ? zipCode.cityID : address.cityID);

    let text = address.street;
    if (city)
      text += ", " + TranslatableString.get(city.name);
    if (zipCode)
      text += " " + zipCode.zipcode;

    let selectedAddress = {
      street: address.street,
      city: city ? TranslatableString.get(city.name) : "",
      zipCode: zipCode ? zipCode.zipcode : "",
      placeID: address.googlePlaceID,
      latitude: address.coordinates.latitude,
      longitude: address.coordinates.longitude,
      comment: address.comment
    };

    this.setState({
      text,
      gpsCoordinates: { lat: address.coordinates.latitude, lng: address.coordinates.longitude },
      selectedAddress,
      comment: address.comment
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.address !== prevProps.address) {
      this.setAddress(this.props.address);
    }
  }

  constructor(props) {
    super(props);
    if (!props.callerID)
      throw new Error("Please provide a callerID, you can use AddressPicker.getID()");
  }

  componentDidMount() {
    EventSystem.subscribe(EventSystem.events.emptyAddressPicker, ({ callerID }) => {
      if (callerID !== this.props.callerID) return;
      this.setState({
        text: "",
        focused: false,
        googleApiLoaded: ContextSystem.googleAPILoaded,
        selectedAddress: undefined,
        locationInFocus: false,
        gpsCoordinates: { lat: -1, lng: -1 },
        comment: ""
      });
    });

    EventSystem.subscribe(EventSystem.events.googlePlacesScriptLoaded, () => this.setState({ googleApiLoaded: true }));

    EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ language }) => {
      if (language !== undefined) {
        this.setState({
          language
        });
      }
    });

    if (this.props.address)
      this.setAddress(this.props.address);
  }

  handleChange = (text) => {
    this.setState({ text, selectedAddress: undefined });
    this.activeSuggestion = -1;
  };

  async handleSelect(text) {
    if (this.activeSuggestion >= 0 && this.filteredSuggestions[this.activeSuggestion]) {
      text = this.filteredSuggestions[this.activeSuggestion].description;
    } else if (this.filteredSuggestions[0]) {
      text = this.filteredSuggestions[0].description;
    }

    this.handleChange(text);
    let ac;
    await geocodeByAddress(text)
      .then(async function f(results) {
        if (!results || results.length <= 0) return;
        let r = results[0];
        let latlon;
        await getLatLng(r).then((r) => (latlon = r));
        // noinspection JSUnusedAssignment
        console.log("latlon", latlon, "r", r);

        let streetNumber;
        let route;
        let cityName;
        let zipCode;

        for (let comp of r.address_components) {
          for (let t of comp.types) {
            if (t === "street_number") {
              streetNumber = comp.long_name;
            } else if (t === "route") {
              route = comp.long_name;
            } else if (t === "locality") {
              cityName = comp.long_name;
            } else if (t === "postal_code") {
              zipCode = comp.long_name;
            }
          }
        }

        if (streetNumber === undefined)
          return;

        // noinspection JSUnusedAssignment
        ac = {
          street: route + " " + streetNumber,
          city: cityName,
          zipCode: zipCode,
          placeID: r.place_id,
          latitude: latlon.lat,
          longitude: latlon.lng
        };
      }).catch((error) => {
        Toast.showToast(Language.getName(Names.SomethingWentWrong) + error);
      });

    if (ac)
      ac.comment = this.state.comment;

    // noinspection JSUnusedAssignment
    this.setState({ selectedAddress: ac });
  }

  handleFocused(focused) {
    this.setState({ focused });
  }

  async handleLocationClick() {
    // noinspection ES6RedundantAwait
    let location: { lat: number, lng: number } | null = await AddressPicker.getLocation();
    if (!location) {
      Toast.showToast(Language.getName(Names.LocationAccessError));
      return;
    }
    this.setState({ gpsCoordinates: location });
    // eslint-disable-next-line no-undef
    const geocoder = new google.maps.Geocoder();
    this.geocodeLatLng(geocoder, location);
  }

  geocodeLatLng(geocoder, latlng) {
    // noinspection JSUnresolvedFunction
    geocoder.geocode({ location: latlng }, (results, status) => {
      let found = false;
      let ac;

      if (status === "OK") {
        for (let r of results) {
          let hasHouseNumber = false;
          for (let t of r.types) {
            if (t === "street_address" || t === "establishment" || t === "premise") {
              found = true;
              hasHouseNumber = true;
              break;
            }
          }

          if (hasHouseNumber === false)
            continue;

          let streetNumber = "";
          let route = "";
          let cityName = "";
          let zipCode = "";

          for (let comp of r.address_components) {
            for (let t of comp.types) {
              if (t === "street_number") {
                streetNumber = comp.long_name;
              } else if (t === "route") {
                route = comp.long_name;
              } else if (t === "locality") {
                cityName = comp.long_name;
              } else if (t === "postal_code") {
                zipCode = comp.long_name;
              }
            }
          }

          ac = {
            street: route + " " + streetNumber,
            city: cityName,
            zipCode: zipCode,
            placeID: r.place_id,
            latitude: latlng.lat,
            longitude: latlng.lng,
            comment: this.state.comment
          };
          break;
        }
        if (ac)
          this.setState({
            text: ac.street + "," + ac.city + " " + ac.zipCode,
            selectedAddress: ac
          });
      }
      if (!found)
        Toast.showToast(Language.getName(Names.LocationReadError));
    });
  }

  handleDoneClicked() {
    if (!this.state.selectedAddress)
      return;

    EventSystem.publish(EventSystem.events.addressPicked, {
      selectedAddress: this.state.selectedAddress,
      callerID: this.props.callerID
    });
  }

  filteredSuggestions = [];
  activeSuggestion = -1;

  filterSuggestions(suggestions) {
    let filteredSuggestions = [];
    let noHouseNumber = true;
    for (let s of suggestions) {
      if (s && s.types && s.terms) {
        for (let type of s.types) {
          if (type === "street_address" || type === "establishment" || type === "premise") {
            noHouseNumber = false;
          }

          if (type === "street_address" || type === "establishment" || type === "premise") {
            s.description = "";
            for (let term of s.terms) {
              //TODO
              if (
                term.value.toLowerCase() !== "magyarország" &&
                term.value.toLowerCase() !== "magyarorszag" && //á -> a
                term.value.toLowerCase() !== "hungary"
              ) {
                if (!s.description.length <= 0) s.description += ", ";
                s.description += term.value;
              }
            }
            filteredSuggestions.push(s);
          }
        }
      }
    }
    this.filteredSuggestions = filteredSuggestions;
    return {
      filteredSuggestions,
      noHouseNumber
    };
  }

  handleCommentChange(e) {
    if (e.target.value.length >= 200) {
      Toast.showToast(Language.getName(Names.MaxCharactersComment));
      return;
    }

    if (this.state.selectedAddress) {
      let selectedAddress = { ...this.state.selectedAddress };
      selectedAddress.comment = e.target.value;
      this.setState({ selectedAddress });
    }
    this.setState({ comment: e.target.value });
  }

  render() {
    let showButton: boolean = !!this.state.selectedAddress;
    let isEmpty = this.state.text.length === 0;

    let useCountry: CountryLocale = CountryLocales.HU;
    for (let key in CountryLocales) {
      let cl: CountryLocale = CountryLocales[key];
      if (cl.id === ContextSystem?.navigatingPartner?.countryID) {
        useCountry = cl;
        break;
      }
    }

    return (
      <Wrapper>
        {this.state.googleApiLoaded && (
          <InputWrapper>
            <LocationButton onClick={() => !!isEmpty && this.handleLocationClick()} isEmpty={isEmpty}>
              {isEmpty && <FaLocationArrow/>}
              {!isEmpty && <MdLocationOn/>}
            </LocationButton>
            <PlacesAutocomplete
              value={this.state.text}
              onChange={this.handleChange}
              onSelect={this.handleSelect.bind(this)}
              debounce={700}
              searchOptions={{
                componentRestrictions: {
                  country: useCountry.code
                },
                language: languages.find(l => l.id === this.state.language)?.languageCode ?? "hu"
              }}
            >
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
                let filterResult = this.filterSuggestions(suggestions);
                let filteredSuggestions = filterResult.filteredSuggestions;
                let noHouseNumber = filterResult.noHouseNumber;
                if (this.state.noHouseNumber !== noHouseNumber) {
                  this.setState({ noHouseNumber });
                }
                return (
                  <>
                    <AddressInput
                      {...getInputProps({
                        className: "location-search-input",
                        placeholder: Language.getName(Names.Address) + "..."
                      })}
                      value={this.state.text}
                      onFocus={() => this.handleFocused(true)}
                      onBlur={() => this.handleFocused(false)}
                    />
                    <Suggestions
                      nohousenumberwarning={noHouseNumber}
                      show={!isEmpty && ((suggestions && suggestions.length > 0) || loading === true)}
                      className={"autocomplete-dropdown-container"}
                    >
                      {loading && <Suggestion>{Language.getName(Names.PleaseWait)}...</Suggestion>}
                      {!loading && filteredSuggestions.length === 0 &&
                        <Suggestion>{Language.getName(Names.NoResults)}.</Suggestion>}
                      {filteredSuggestions.length > 0 &&
                        filteredSuggestions.map((suggestion, i) => {
                          if (suggestion.active) this.activeSuggestion = i;

                          const className = suggestion.active ? "suggestion-item--active" : "suggestion-item";
                          return (
                            <Suggestion {...getSuggestionItemProps(suggestion, { className })}
                                        active={suggestion.active}
                                        key={i}
                            >
                              <span>{suggestion.description}</span>
                            </Suggestion>
                          );
                        })}
                    </Suggestions>
                  </>
                );
              }}
            </PlacesAutocomplete>
          </InputWrapper>
        )}
        {this.state.noHouseNumber && !isEmpty && !this.state.selectedAddress &&
          <NoHouseNumberWarning>{Language.getName(Names.HouseNumberMissing)}</NoHouseNumberWarning>
        }
        <UseMyLocationWrapper
          tabIndex={-1}
          onClick={() => this.handleLocationClick()}
          onFocus={() => {
            this.setState({ locationInFocus: true });
            console.log("focus");
          }}
          onBlur={() => {
            this.setState({ locationInFocus: false });
            console.log("unfocus");
          }}
          showmap={showButton}
          show={(isEmpty && this.state.focused) || this.state.locationInFocus}
        >
          <LocationButton light isEmpty>
            <FaLocationArrow/>
          </LocationButton>
          <span>{Language.getName(Names.UseMyLocation)}</span>
        </UseMyLocationWrapper>
        {this.state.googleApiLoaded && (
          <MyMapComponent
            loadingElement={<div style={{ height: `100%` }}/>}
            containerElement={<MapContainer show={showButton}/>}
            defaultZoom={this.state.selectedAddress ? 18 : 10}
            zoom={this.state.selectedAddress ? 18 : 10}
            defaultCenter={
              this.state.selectedAddress
                ? {
                  lat: this.state.selectedAddress.latitude,
                  lng: this.state.selectedAddress.longitude
                }
                : googleMapsCenterBP
            }
            center={
              this.state.selectedAddress
                ? {
                  lat: this.state.selectedAddress.latitude,
                  lng: this.state.selectedAddress.longitude
                }
                : googleMapsCenterBP
            }
            mapElement={<div style={{ height: `100%` }}/>}
          >
            {this.state.selectedAddress && (
              <Marker
                position={{ lat: this.state.selectedAddress.latitude, lng: this.state.selectedAddress.longitude }}
              />
            )}
          </MyMapComponent>
        )}
        <CommentInput
          type="text"
          placeholder={Language.getName(Names.AddressCommentPlaceholder)}
          maxLength="200"
          value={this.state.comment}
          onChange={(e) => this.handleCommentChange(e)}
          show={showButton}
        />
        <FinalizeButton onClick={() => this.handleDoneClicked()} showButton={showButton}>
          {Language.getName(Names.Next)}
        </FinalizeButton>
      </Wrapper>
    );
  }
}

export default AddressPicker;

import * as React from "react";

function BlundeeHomeIconEmpty(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 39 38"
      fill={"currentColor"}
      stroke={"currentColor"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M19.1562 29.705V24.2603" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M15.652 2.15474L3.07478 12.2274C1.65916 13.3527 0.751712 15.7302 1.06025 17.5088L3.47406 31.9554C3.90964 34.5326 6.3779 36.6197 8.99136 36.6197H29.3182C31.9135 36.6197 34.3999 34.5144 34.8355 31.9554L37.2493 17.5088C37.5397 15.7302 36.6322 13.3527 35.2348 12.2274L22.6575 2.17289C20.7156 0.612075 17.5758 0.612075 15.652 2.15474Z" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" fill="transparent"/>
    </svg>
);
}

export default BlundeeHomeIconEmpty;

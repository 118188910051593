import React, { Component } from "react";
import EventSystem from "../utils/EventSystem";
import { Button as ButtonOrg } from "./Form";
import styled from "styled-components";
import { OuterWrapper, Panel as PanelOrg } from "./popups/ProfileDetailsPopup";

const Panel = styled(PanelOrg)`
  min-height: 300px;
  max-width: 100%;
`;

const Title = styled.h1`
  width: 100%;
  font-size: 16pt;
  margin: 0;
  padding: 5px 5px 8px 5px;
`;

const Details = styled.div`
  width: 100%;
  margin-top: 10px;
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
  place-items: stretch;
`;

const Button = styled(ButtonOrg)`
  margin: 5px 10px;
  background-color: var(--blundee_button_bg_color);
  color: var(--blundee_color_normal);
  
  @media screen and (max-width: 800px) {
    margin: 5px 10px;
    min-width: auto;
  }
`;

export default class ConfirmationModal extends Component {
  state: {
    showModal: boolean,
    title: string,
    text: string,
    noText: string,
    yesText: string,
    cancelCb: ()=>{} | undefined,
    proceedCb: ()=>{} | undefined,
  } = {
    showModal: false,
    title: "",
    text: "",
    noText: "",
    yesText: "",
    cancelCb: undefined,
    proceedCb: undefined
  };

  cancel() {
    this.setState({ showModal: false });
    if (this.state.cancelCb) this.state.cancelCb();
  }

  proceed() {
    this.setState({ showModal: false });
    if (this.state.proceedCb) this.state.proceedCb();
  }

  componentDidMount() {
    window.addEventListener("keyup", (e) => {
      if (this.state.opened && e.key === "Escape") {
        this.cancel();
      }
    });
    EventSystem.subscribe(EventSystem.events.show_confirmation_modal,
      ({ title, text, yesText, noText, cancelCb, proceedCb }) => {
        this.setState({
          showModal: true,
          title,
          text,
          yesText,
          noText,
          cancelCb,
          proceedCb
        });
      });
  }

  render() {
    return (
      <OuterWrapper opened={this.state.showModal} onClick={() => this.cancel()}>
        <Panel onClick={(e) => e.stopPropagation()}>
          <Title>{this.state.title}</Title>
          <Details>{this.state.text}</Details>
          <Footer>
            {this.state.noText &&
            <Button onClick={() => this.cancel()}>
              {this.state.noText}
            </Button>
            }
            {this.state.yesText &&
            <Button onClick={() => this.proceed()}>
              {this.state.yesText}
            </Button>
            }
          </Footer>
        </Panel>
      </OuterWrapper>
    );
  }
}

import AbstractModel from "../model/AbstractModel";

export class CountryLocale {
  id: number;
  code: string;
  currencyID: number;
  defaultLanguageID: number;
  mapFilters: string[];
}

export const CountryLocales: { [keys: string]: CountryLocale } = {
  HU: {
    id: 1,
    code: "hu",
    currencyID: 1,
    defaultLanguageID: 1,
    mapFilters: ["magyarorszag", "magyarország", "hungary", "hu"]
  },
  DK: {
    id: 2,
    code: "dk",
    currencyID: 3,
    defaultLanguageID: 2,
    mapFilters: ["denmark", "danmark", "dánia", "dkk"]
  },
  AE: {
    id: 3,
    code: "ae",
    currencyID: 4,
    defaultLanguageID: 1,
    mapFilters: ["united arab emirates", "uae", "ae", "emirates", "arab emirates"]
  }
};

export class Language extends AbstractModel {
  name: string;
  code: string;
}

export class Currency extends AbstractModel {
  name: string;
  code: string;
  leftSign: string;
  rightSign: string;
  round: number;
  smallestCoin: number;
}

export const currencies: Currency[] = [
  {
    id: 1,
    enabled: true,
    name: "Magyar Forint",
    code: "HUF",
    leftSign: "",
    rightSign: " Ft",
    round: 0,
    smallestCoin: 5
  },
  {
    id: 2,
    enabled: true,
    name: "Euro",
    code: "EUR",
    leftSign: "€ ",
    rightSign: "",
    round: 2,
    smallestCoin: 0.01
  },
  {
    id: 3,
    enabled: true,
    name: "Danish Kroner",
    code: "DKK",
    leftSign: "",
    rightSign: " kr",
    round: 1,
    smallestCoin: 0.05
  },
  {
    id: 4,
    enabled: true,
    name: "Emirates Dirham",
    code: "AED",
    leftSign: "",
    rightSign: " aed",
    round: 2,
    smallestCoin: 0.01
  }
];

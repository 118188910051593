import React, { Component } from "react";
import styled, { css } from "styled-components";
import Config from "../utils/Config";
import { BsThreeDotsVertical } from "react-icons/bs";
import ContextSystem from "../utils/ContextSystem";
import BlundeeBackIcon from "./icons/BlundeeBackIcon";
import BlundeeHeartIconFilled from "./icons/BlundeeHeartIconFilled";
import BlundeeHeartIconEmpty from "./icons/BlundeeHeartIconEmpty";

export const FixedHeadBar = styled.div`
  width: 100%;
  height: 0;
  background-color: var(--blundee_navbar_bg_color);
  box-shadow: var(--blundee_shadow);
  z-index: 2;
  overflow: hidden;
  position: absolute;
  top: ${({top}) => top ?? ContextSystem.statusBarSpaceHeight}px;
  left: 0;

  transition: height 100ms ease-in-out 100ms;

  ${({show}) => show === true && css`
    transition: height 100ms ease-in-out;
    height: 60px;
  `};

  @media screen and (min-width: 801px) {
    margin-right: 15px;
    width: calc(100% - 15px);
    box-shadow: var(--blundee_shadow);
  }
`;

export const FixedHeadBarContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  transform: translateY(100%);

  transition: transform 100ms ease-in-out;

  ${({show}) => show === true && css`
    transition: transform 100ms ease-in-out 50ms;
    transform: translateY(0);
  `}
`;

export const HeadBar = styled.div`
  background-color: var(--blundee_navbar_bg_color);
  box-shadow: var(--blundee_shadow);
  width: 100%;
  padding: 0;
  position: ${({position}) => (position ? position : "auto")};
  top: 0;
  left: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  max-height: 60px;

  @media screen and (max-width: 767px) {
    padding: 5px 10px;
  }
`;

const SmallTitle = styled.div`
  font-size: 15pt;
  font-weight: 600;
  user-select: none;

  text-overflow: ellipsis;
  font-family: var(--blundee_font_medium);
  color: var(--blundee_color_normal);
  text-wrap: none;
  white-space: nowrap;
  overflow: hidden;
  margin: 0 5px;
  max-width: 55%;

  transition: width 100ms ease-in-out;

  ${({mobile, searching}) => mobile && searching && css`
    width: 0;
  `};
`;

export const HeadButton = styled.div`
  user-select: none;
  //background-color: rgba(239,239,239,0.79);
  //background-color: var(--blundee_background_secondary_2);
  color: var(--blundee_color_2);
  border-radius: 100px;
  height: 40px;
  width: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 8px 9px;
  padding: 0;
  transition: background-color 100ms ease-in-out,
  width 200ms ease-in-out,
  height 200ms ease-in-out,
  filter 200ms ease-in-out,
  opacity 200ms ease-in-out;

  & > svg {
    margin: 0;
    padding: 0;
    font-size: 18pt;
    color: var(--blundee_color_normal);
    //color:black;
    stroke-width: ${({strokewidth}) => (strokewidth === undefined ? "initial" : strokewidth)};
    position: relative;
    left: ${({offsetleft}) => (offsetleft === undefined ? "0" : offsetleft)};
    top: ${({offsettop}) => (offsettop === undefined ? "0" : offsettop)};
  }

  &:hover, &:active {
    filter: brightness(95%);
    cursor: pointer;
  }

  @media screen and (max-width: 600px) {
    width: 43px;
    height: 43px;
    margin: 8px 4px;
    & > svg {
      font-size: 15pt;
    }
  }

  ${({hide}) => hide === true && css`
    width: 0;
    height: 0;
    opacity: 0;
  `};
`;

const HeadRightAlign = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
`;

export default class HeadBarContent extends Component {
  // searcherRef;

  state = {
    mobile: window.innerWidth <= Config.mobileMaxWidth,
    title: undefined,
    searching: false,
    hearted: false,
    showTitle: true,
    showHeartedButton: false,
    showDetailsButton: false
  };

  // handleSearchClick() {
  //   this.setState({ searching: !this.state.searching });
  //   if (!this.state.searching) {
  //     this.searcherRef.focus();
  //   }
  // }
  //
  // handleSearchKeyDown(e) {
  //   if (e.keyCode === 27) {
  //     this.handleSearchClick();
  //   }
  // }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.title !== prevProps.title)
      this.setState({title: this.props.title});
    if (this.props.showTitle !== prevProps.showTitle)
      this.setState({showTitle: this.props.showTitle});
    if (this.props.mobile !== prevProps.mobile)
      this.setState({mobile: this.props.mobile});
    if (this.props.hearted !== prevProps.hearted)
      this.setState({hearted: this.props.hearted});
    if (this.props.showHeartedButton !== prevProps.showHeartedButton)
      this.setState({showHeartedButton: this.props.showHeartedButton});
    if (this.props.showDetailsButton !== prevProps.showDetailsButton)
      this.setState({showDetailsButton: this.props.showDetailsButton});
  }

  componentDidMount() {
    if (this.props.title !== undefined)
      this.setState({title: this.props.title});
    if (this.props.showTitle !== undefined)
      this.setState({showTitle: this.props.showTitle});
    if (this.props.mobile !== undefined)
      this.setState({mobile: this.props.mobile});
    if (this.props.hearted !== undefined)
      this.setState({hearted: this.props.hearted});
    if (this.props.showHeartedButton !== undefined)
      this.setState({showHeartedButton: this.props.showHeartedButton});
    if (this.props.showDetailsButton !== undefined)
      this.setState({showDetailsButton: this.props.showDetailsButton});
  }

  render() {
    return (
      <>
        <HeadButton
          hide={this.state.mobile && this.state.searching}
          onClick={() => this.props.handleBackClick()}
        >
          <BlundeeBackIcon/>
        </HeadButton>
        {this.state.showTitle && <SmallTitle searching={this.state.searching}>{this.state.title}</SmallTitle>}
        <HeadRightAlign>
          {/*<RestaurantSearcherContainer searching={this.state.searching}>*/}
          {/*  <SearchButton onClick={() => this.handleSearchClick()}*/}
          {/*                searching={this.state.searching}*/}
          {/*                strokewidth={3}*/}
          {/*  >*/}
          {/*    {!this.state.searching &&*/}
          {/*    <FiSearch/>*/}
          {/*    }*/}
          {/*    {this.state.searching &&*/}
          {/*    <FaTimes/>*/}
          {/*    }*/}
          {/*  </SearchButton>*/}
          {/*  <RestaurantSearcher onKeyDown={e => this.handleSearchKeyDown(e)}*/}
          {/*                      ref={r => this.searcherRef = r}*/}
          {/*                      searching={this.state.searching}*/}
          {/*                      type={"text"}*/}
          {/*                      id={"restaurant-searcher"}*/}
          {/*                      autoComplete={"search"}*/}
          {/*  />*/}
          {/*</RestaurantSearcherContainer>*/}
          {this.state.showHeartedButton && (
            <HeadButton
              hide={this.state.mobile && this.state.searching}
              onClick={() => this.props.handleHeartClicked()}
              offsetleft={"0"}
              offsettop={"2px"}
              strokewidth={"0"}
            >
              {!this.state.hearted && <BlundeeHeartIconEmpty/>}
              {this.state.hearted && <BlundeeHeartIconFilled/>}
            </HeadButton>
          )}
          {this.state.showDetailsButton && (
            <HeadButton onClick={() => this.props.handleOpenInfoClick()}>
              <BsThreeDotsVertical/>
            </HeadButton>
          )}
        </HeadRightAlign>
      </>
    );
  }
}

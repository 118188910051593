import React from "react";
import ContextSystem from "../utils/ContextSystem";
import EventSystem from "../utils/EventSystem";
import styled, { css } from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import HomePageAPI from "../utils/api/HomePageAPI";
import Config from "../utils/Config";
import Loader from "react-loader-spinner";
import ShopComponent from "../components/ShopComponent";
import ProductComponent from "../components/ProductComponent";
import {
  handlePartnerProfileClicked,
  handleProductClicked,
  sortRestaurantsAndFoodsByOpenStatus
} from "./BrowsePage/SearchScreen";
import DynamicOffersDiv from "../components/DynamicOffers";
import Language, { Names } from "../utils/Language";
import { Profile } from "../model/Profile";
import { Shop } from "../model/Shop";
import { DynamicOffer } from "../model/nonpersistent/DynamicOffer";
import { BlundeeButton, SmallButton } from "../components/Form";
import { LoaderWrapper } from "./StartPage";

const Wrapper = styled.div`
  //display: flex;
  //flex-direction: column;
  //justify-content: flex-start;
  //align-items: flex-start;
  padding: 0 0 15px 0;
  flex-wrap: nowrap;

  width: 100%;
  margin-bottom: 48px;

  text-align: left;

  & > h4 {
    font-size: 16pt;
    color: var(--blundee_color_normal);
    font-family: var(--blundee_font_medium);
    //letter-spacing: 0.1rem;
    //white-space: nowrap;
    margin: 24px 0 12px 0;
    padding: 0 12px;
  }

  & > p {
    font-size: 11pt;
    color: var(--blundee_color_normal);
    padding: 0 12px;
    font-family: var(--blundee_font_medium);
  }

  @media screen and (max-width: 600px) {
    & > h4 {
      font-size: 14pt;
    }
  }

  ${({registered}) => registered === false && css`
    height: 100%;
  `}
`;

const HomePageButton = styled(SmallButton)`
  padding: 6px 18px;
  background-image: linear-gradient(90deg, var(--blundee_homepage_button_bg1), var(--blundee_homepage_button_bg2));
  border-radius: 600px;
`;

const HomePageHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 130px;
  border-radius: 0 0 40px 40px;
  margin: 0 0 24px 0;
  background-color: var(--blundee_background_secondary_2);

  & > h1 {
    width: 100%;
    font-family: var(--blundee_font_bold);
    font-size: 25pt;
    color: var(--blundee_color_2);
    text-align: center;
    max-width: 100%;
    margin: 0 0 12px 0;
  }

  @media screen and (min-width: 800px) {
    height: 150px;
    width: 80%;
    margin: 0 10% 24px 10%;
  }
`;

// noinspection JSUnusedLocalSymbols
const PleaseLoginText = styled.h3`
  font-size: 13pt;
  color: rgb(220, 220, 220);
  font-style: italic;
  font-weight: normal;
  margin-left: 15px;

  @media screen and (max-width: 800px) {
    margin-left: 0;
    text-align: left;
    font-size: 11pt;
  }
`;

const SwiperContainer = styled.div`
  width: 100%;
  margin-top: 3px;
  text-align: left;
`;


class HomePage extends React.Component {
  //static addressPickerID = ContextSystem.getNextUniqueID();
  swiperRefs = [];
  eventIDs = [];

  state: {
    profile: Profile | undefined | null,
    mobile: boolean,
    recommendations: DynamicOffer[],
    partnerProfiles: Shop[],
    loading: boolean,
    language: number,
  } = {
    profile: ContextSystem.profile,
    mobile: window.innerWidth <= Config.mobileMaxWidth,
    recommendations: [],
    partnerProfiles: [],
    loading: true,
    language: ContextSystem.language
  };

  loadMobile() {
    this.setState({mobile: window.innerWidth <= Config.mobileMaxWidth});
  }

  load() {
    this.setState({loading: true});
    let selectedAddress = ContextSystem.selectedAddress;
    HomePageAPI.getRecommendation(true, selectedAddress ? selectedAddress.id : undefined, (data) => {
      ContextSystem.mergeShops(data.partnerProfiles);
      this.setState({recommendations: data.respond, loading: false, partnerProfiles: data.partnerProfiles});
    });
  }

  componentWillUnmount() {
    for (let eventID of this.eventIDs)
      EventSystem.unsubscribe(eventID);

    window.removeEventListener("resize", () => this.loadMobile);
  }

  componentDidMount() {
    this.eventIDs = [];
    let eid;
    eid = EventSystem.subscribe(EventSystem.events.authentication_changed, () => {
      this.setState({profile: ContextSystem.profile});
    });
    this.eventIDs.push(eid);

    let lastSelectedAddress = ContextSystem.selectedAddress;
    eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged,
      ({selectedAddress, profile, language}) => {
        if (profile !== undefined && this.state.profile !== profile) {
          this.setState({profile});
          this.load();
        }
        if (selectedAddress !== undefined && selectedAddress !== lastSelectedAddress) {
          lastSelectedAddress = selectedAddress;
          this.load();
        }
        if (language !== undefined) {
          this.setState({language});
        }
      });

    this.eventIDs.push(eid);
    this.load();
    window.addEventListener("resize", () => this.loadMobile);
  }

  getPartner(partnerID: number): Shop {
    if (!this.state.partnerProfiles)
      return null;

    for (let p of this.state.partnerProfiles) {
      if (p.id === partnerID)
        return p;
    }
    return null;
  }

  redirect(path) {
    EventSystem.publish(EventSystem.events.redirectIntent, {redirectPath: path});
  }

  handleHomePageButtonClick() {
    this.redirect("/mi-ez-a-blundee");
  }

  render() {
    let greetings = Language.getName(Names.GreetingsDefault);
    let h = new Date().getHours();
    if (h >= 0 && h < 4) {
      greetings = Language.getName(Names.Greetings0_4);
    } else if (h >= 4 && h < 9) {
      greetings = Language.getName(Names.Greetings4_9);
    } else if (h >= 9 && h < 14) {
      greetings = Language.getName(Names.Greetings9_14);
    } else if (h >= 14 && h < 18) {
      greetings = Language.getName(Names.Greetings14_18);
    } else if (h >= 18 && h < 21) {
      greetings = Language.getName(Names.Greetings18_21);
    } else if (h >= 21) {
      greetings = Language.getName(Names.Greetings21_0);
    }
    return (
      <Wrapper>
        <HomePageHeader>
          <h1>{greetings}</h1>
          <HomePageButton onClick={() => this.handleHomePageButtonClick()}>
            {Language.getName(Names.HomePageButtonText)}
          </HomePageButton>
        </HomePageHeader>
        <>
          {this.state.loading && (
            <LoaderWrapper>
              <Loader visible={this.state.loading} type="ThreeDots" color="rgb(80,80,80)" height={20} width={100}/>
            </LoaderWrapper>
          )}
          {this.state.loading === false && this.state.recommendations?.map((record, i) => {
            let hasPartners = record.partnerProfiles !== undefined && record.partnerProfiles.length > 0;
            let hasProducts = record.products !== undefined && record.products.length > 0;
            return (
              <React.Fragment key={i}>
                <h4>{record.title}</h4>
                {!hasPartners && !hasProducts && <p>{record.emptyTitle}</p>}
                {(hasPartners || hasProducts) && (
                  <SwiperContainer>
                    <Swiper
                      grabCursor
                      autoHeight={true}
                      initialSlide={0}
                      height={1}
                      spaceBetween={12}
                      slidesOffsetBefore={12}
                      slidesOffsetAfter={12}
                      slidesPerView={DynamicOffersDiv.getSlidesPerView(hasProducts, hasPartners)}
                      onSwiper={(s) => (this.swiperRefs[i] = s)}
                      navigation={{}}
                    >
                      {hasProducts && record.products.sort((x, y) => sortRestaurantsAndFoodsByOpenStatus(this.getPartner(x.partnerID), this.getPartner(y.partnerID))).map((product, j) => {
                        let partnerProfile = this.getPartner(product.partnerID);
                        // noinspection RequiredAttributes
                        return (
                          <SwiperSlide key={j}>
                            <ProductComponent
                              key={j}
                              product={product}
                              partnerProfile={partnerProfile}
                              layout={"tall"}
                              in_slide={true}
                              handleClick={() => handleProductClicked(product, partnerProfile)}
                            />
                          </SwiperSlide>
                        );
                      })
                      }
                      {hasPartners && record.partnerProfiles.sort(sortRestaurantsAndFoodsByOpenStatus).map((partnerProfile, j) => {
                        if (partnerProfile.list)
                          return (
                            <SwiperSlide key={j}>
                              <ShopComponent
                                onClick={() => handlePartnerProfileClicked(partnerProfile)}
                                partnerProfile={partnerProfile}
                                density={1}
                                width={"100%"}
                                in_slide={true}
                              />
                            </SwiperSlide>
                          );
                        else return <React.Fragment key={j}/>;
                      })}
                    </Swiper>
                  </SwiperContainer>
                )}
              </React.Fragment>
            );
          })
          }
          {Config.getBoolean("enable_homepage_google_forms") === true &&
            <>
              <BlundeeButton style={{alignSelf: "flex-end", margin: "0 24px", width: "calc(100% - 48px)"}}
                             onClick={() => EventSystem.publish(EventSystem.events.open_google_forms_modal)}>
                {Language.getName(Names.RecommendARestaurant)}
              </BlundeeButton>
            </>
          }
        </>
      </Wrapper>
    );
  }
}

export default HomePage;

import React, { Component } from "react";
import styled, { css } from "styled-components";
import { RestaurantAPI } from "../utils/api/RestaurantAPI";
import Config, { ConfigKeys } from "../utils/Config";
import EventSystem from "../utils/EventSystem";
import { withRouter } from "react-router-dom";
import * as HoursCalc from "../utils/HoursCalc";
import ContextSystem from "../utils/ContextSystem";
import { BsClockFill } from "react-icons/bs";
import { FaMapMarkerAlt, FaTimes } from "react-icons/fa";
import Cart from "../components/cart/Cart";
import ProductDiv from "../components/ProductComponent";
import { CartStorage } from "../utils/CartStorage";
import HeadBarContent, { FixedHeadBar, FixedHeadBarContainer, HeadBar } from "../components/HeadBar";
import { Helmet } from "react-helmet";
import { City, GeoMultiPolygon } from "../model/Address";
import { ShippingPrice } from "../model/ShippingPrice";
import { Shop } from "../model/Shop";
import { Category, Product, ProductTypes, TranslatableString } from "../model/Product";
import Toast from "../components/Toast";
import { HourStatuses, HourTypes } from "../model/Hour";
import Language, { Names } from "../utils/Language";
import { FiSearch } from "react-icons/fi";
import WelcomeScreen from "./BrowsePage/WelcomeScreen";
import { RestaurantHeart } from "../model/RestaurantHeart";
import { languages, ReactFlagsSelect } from "./StartPage";
import Analytics from "../components/Analytics";
import { getOpeningState } from "../utils/HoursCalc";
import { handlePartnerProfileClicked } from "./BrowsePage/SearchScreen";

const LanguageSelectorWrapper = styled.div`
  width: 100%;
  padding: 6px 12px 0 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`;

const Wrapper = styled.div`
  width: 100%;
  background-color: var(--blundee_background);
  transform-style: preserve-3d;
  perspective: 100px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  @media screen and (min-width: 801px) {
    &::-webkit-scrollbar-thumb {
      background-color: var(--blundee_scroll_thumb_color);
      border-radius: 100px;
    }

    &::-webkit-scrollbar {
      width: 15px;
      background-color: transparent;
      //border-left: 1px solid rgb(20, 20, 20);
    }
  }

  @media screen and (max-width: 800px) {
    overflow-y: ${({scrollable}) => (scrollable === true ? "auto" : "hidden")};
  }
`;

const ParallaxWrapper = styled.div`
  width: 100%;
  height: 50vh;
  z-index: -1;
  background-color: rgba(33, 33, 33, 0.45);
  position: relative;
  transform-style: preserve-3d;
  transform: translateZ(-100px) translateX() scale(2);
  transform-origin: bottom center;

  @media screen and (max-width: 800px) {
    //transform: translateZ(-100px) translateY(50px) scale(2);
    transform: none;
  }
`;

const ParallaxBGImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  //transform: translateZ(-200px) translateX() scale(4);
`;

const ParallaxInsideDiv = styled.div`
  width: 100%;
  height: calc(100% - 59px);

  background: linear-gradient(rgba(33,33,33,0),rgba(33,33,33,0) 33%,rgba(33,33,33,1));

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const ParallaxInsideBottomContainer = styled.div`
  width: 100%;
  margin-top: auto;
`;

const RestaurantName = styled.div`
  position: initial;
  background-color: transparent; // linear-gradient(-90deg, rgb(255,255,255), #841a1e);
  z-index: auto;
  //text-align: right;
  width: 100%;
  padding: 0 3% 0 3%;

  & > h1 {
    position: relative;
    margin: 0;
    font-size: 5vw;
    color: var(--blundee_color_2);
    font-weight: 600;
    font-family: var(--blundee_font_medium);
    z-index: auto;
  }

  @media screen and (max-width: 1200px) and (min-width: 1001px) {
    & > h1 {
      font-size: ${({long}) => (long ? "4.5vw" : "5.5vw")};
    }
  }

  @media screen and (max-width: 1000px) and (min-width: 768px) {
    & > h1 {
      font-size: ${({long}) => (long ? "5vw" : "6vw")};
    }
  }

  @media screen and (max-width: 767px) {
    & > h1 {
      font-size: ${({long}) => (long ? "2.7rem" : "3.2rem")};
    }
  }
`;

const ContentWrapper = styled.div`
  color: var(--blundee_color_normal);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0;
  background-color: var(--blundee_background);
`;

const CouponsScrollWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  margin: 6px 0;
  padding: 0 0 12px 0;
`;

const CouponsWrapper = styled.div`
  width: 100%;
`;

const CouponDiv = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  max-width: 350px;

  width: calc(${({width}) => width ?? "200px"} - 2 * 24px);
  height: 110px;
  margin: 12px 24px;
  padding: 6px 12px;
  border-radius: 15px;
  background-color: ${({background_color}) => background_color ?? "white"};
  box-shadow: var(--blundee_card_shadow);
  position: relative;

  @media screen and (max-width: 480px) {
    max-width: calc(${({width}) => width ?? "200px"} - 2 * 12px);
    width: calc(${({width}) => width ?? "200px"} - 2 * 12px);
    margin: 12px 12px;
    height: fit-content;
  }
`;

const CouponSideCrop = styled.div`
  position: absolute;
  left: ${({left}) => left ?? "0px"};
  right: ${({right}) => right ?? "0px"};
  border-radius: 1000px;
  width: 15px;
  height: 30px;
  top: calc(50% - 30px / 2);
  background-color: ${({background_color}) => background_color ?? "var(--blundee_background)"};

  ${({position}) => position === "left" && css`
    box-shadow: inset -1px 0 4px -2px #b2b2b2;
    border-radius: 0 100% 100% 0/0 50% 50% 0;
    left: 0;
  `};

  ${({position}) => position === "right" && css`
    box-shadow: inset 1px 0 4px -2px #b2b2b2;
    border-radius: 100% 0 0 100%/50% 0 0 50%;
    left: auto;
    right: 0;
  `};

  &:after {
    content: '';
    visibility: visible;
    display: block;
    position: relative;
    top: 0;
    left: -10px;
    width: 10px;
    height: 100%;
    background-color: var(--blundee_background);

    ${({position}) => position === "left" && css`
      left: -10px;
    `};

    ${({position}) => position === "right" && css`
      left: 100%;
    `};
  }
`;

const CouponDetails = styled.div`
  height: 100%;
  width: 100%;
  padding: 6px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const CouponTitle = styled.h4`
  margin: 0 0 6px 0;
  color: rgb(45, 45, 45);
  font-size: 18pt;
  text-align: center;
  font-family: var(--blundee_font_bold) !important;
`;

const CouponDescription = styled.p`
  color: rgb(45, 45, 45);
  font-size: 11pt;
  margin: 3px 0;
  text-align: center;
  font-family: var(--blundee_font_medium);
`;

const CouponValidity = styled.p`
  color: rgb(145, 145, 145);
  font-size: 11pt;
  margin: auto 0 0 0;
  font-style: italic;
  font-family: var(--blundee_font_medium);
  text-align: center;
`;

const RestaurantHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  //margin-bottom: 15px;

  & > h2 {
    color: var(--blundee_color_normal);
    font-weight: bold;
    font-size: 1.6vw;
    font-family: var(--blundee_font_medium);
    margin: 0 30px 0 0;
  }

  @media screen and (max-width: 600px) {
    flex-wrap: wrap-reverse;
    justify-content: center;
    & > h2 {
      text-align: center;
      font-size: 7vw;
      width: 100%;
      margin-bottom: 0;
      margin-top: 10px;
      margin-right: 0;
    }
  }
`;

const RestaurantInfos = styled.div`
  color: var(--blundee_color_2);
  padding: 5px 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-image: linear-gradient(45, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));

  & > span {
    margin: 3px 0;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
    //margin-bottom: 5px;
    //padding-bottom: 5px;
    //border-bottom: 1px solid #dbdbdb;
    & > span:nth-child(1) {
      font-size: 12pt;
    }

    & > span:nth-child(2) {
      font-size: 12pt;
    }
  }
`;

const ContentSplitWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
`;

const CartPaneWrapper = styled.div`
  margin: 10px 0 0 0;
  width: 39%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 70px;
`;

const CartPane = styled.div`
  background-color: var(--blundee_background);
  border-radius: 7px;
  overflow: hidden;

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  transition: top 100ms ease-in-out;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.5);
`;

const ProductsAndCategoriesWrapper = styled.div`
  width: 58%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: ${({paddingtop}) => paddingtop + "px"} 0 0 0;
`;

const CategoriesWrapper = styled.div`
  background-color: var(--blundee_background);
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;

  transition: top 100ms ease-in-out;

  position: sticky;
  top: 59px;
  z-index: 2;
`;

const CategoriesContainer = styled.div`
  height: 45px;
  overflow-y: hidden;
  overflow-x: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  padding: 0;

  @media screen and (min-width: 801px) {
    &::-webkit-scrollbar-thumb {
      background-color: var(--blundee_scroll_thumb_color);
      border-radius: 100px;
    }

    &::-webkit-scrollbar {
      height: 5px;
      background-color: transparent;
    }
  }
`;

const CategoryDiv = styled.div`
  height: 100%;
  width: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  padding: 5px 13px;
  margin: 0 2px;
  flex-grow: 1;
  user-select: none;
  border-radius: 6px;
  color: var(--blundee_color_normal);
  transition: filter 100ms ease-in-out;
  cursor: pointer;

  ${({selected}) => selected === true && css`
    background-color: var(--blundee_background_light);
  `}
  ${({sub}) => sub === true && css`
    color: var(--blundee_color_thin);
    font-size: 10pt;
  `}
  &:hover {
    filter: brightness(95%);
  }

  &:active {
    filter: brightness(98%);
  }
`;

const ProductListsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  @media screen and (min-width: 1201px) {
    padding: 0 5%;
  }
`;

const ProductWrapper = styled.div`
  width: 100%;
`;

const ProductsList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  & > h3, & > h2 {
    padding: 0 12px;
    color: var(--blundee_color_normal);
    font-family: var(--blundee_font_medium);
    width: 100%;
    height: 35px;
    user-select: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  & > h2 {
    font-size: 22pt;
    margin: 12px 0 0 0;
  }

  & > h3 {
    font-size: 18pt;
    padding-left: 24px;
    margin: 12px 0 0 0;
  }

  ${({maincategory, first}) => !first && maincategory === true && css`
    margin-bottom: 15px;
    //border-top: 1px solid rgb(80, 80, 80);
  `}
`;

const OpenHourContainer = styled.div`
  & > svg {
    color: ${({open}) => (open === false ? "rgb(239,0,0)" : "rgb(9,186,30)")};
    margin-right: 5px;
  }

  display: flex;
  align-items: center;
`;

const RestaurantNote = styled.div`
  width: calc(100% - 24px);
  margin: 12px;
  padding: 6px 12px;
  border-radius: 12px;
  background-color: var(--blundee_restaurant_note_background);
  box-shadow: var(--blundee_card_shadow);
  text-align: ${({align}) => align ?? "center"};
`;

const SearchFilterWrapper = styled.div`
  display: flex;
  height: fit-content;
  width: calc(100% - 24px);
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  margin: 6px 12px;
  justify-content: flex-start;

  position: sticky;
  top: 110px;
  z-index: 2;

  @media screen and (min-width: 800px) {
    width: 350px;
    margin: 12px 0;
  }

  & > svg:nth-of-type(1) {
    position: absolute;
    left: 9px;
    font-size: 16pt;
    font-weight: bold;
    top: auto;
    bottom: auto;
    color: rgb(134, 134, 134);
  }
`;

const SearchTextInput = styled.input`
  width: 90%;
  padding: 12px 12px 12px 36px;
  margin: 0;
  outline: none;
  border: none;
  background-color: var(--blundee_input_bg_1);
  color: var(--blundee_color_normal);
  border-radius: 12px;
  font-family: inherit;
  font-size: 11pt;

  box-shadow: var(--blundee_card_shadow);
  transition: box-shadow 300ms ease-in-out, width 300ms ease-in-out;

  @media screen and (max-width: 600px) {
    width: ${({mwidth}) => (mwidth ? mwidth : "100%")};
  }

  &:focus {
    outline: none;
    border: none;
    box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.5);
  }

  &::placeholder {
    color: rgb(134, 134, 134);
    //color: rgb(52,52,52);
  }
`;

const ClearSearchWrapper = styled.div`
  margin: 0 0 0 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & > svg {
    font-size: 14pt;
    cursor: pointer;
    color: #444440;
  }
`;

class RestaurantPage extends Component {
  eventIDs = [];

  wrapperRef;
  // swiperRef;
  // enableSwiperStopPropagation = false;
  contentWrapperRef;
  categoriesWrapperRef;
  categoriesLabelRef = [];

  scrollCategoryRefs = [];
  productRefs = [];

  state: {
    mobile: boolean,
    restaurantName: string,
    search: string,
    products: Product[],
    categories: Category[],
    partner: Shop | null,
    shippingPrices: ShippingPrice[],
    cities: City[],
    mounted: boolean,
    selectedCity: City | null,
    searching: boolean,
    menuListTouched: boolean,
    polygons: GeoMultiPolygon[],
    hearted: boolean,

    showingProductMobile: number,
    showMenuCombiner: boolean,
    swiperPaneScrollable: boolean,
    wrapperScrollable: boolean,
    activeSwiperPanelIndex: number,

    selectedCategory: number,
    showFixedHeadBar: boolean,
    maxProductsContainerHeight: string,
    containerPaddingBottom: string,

    selectedProductID: number,
    statusBarSpaceHeight: number,
    language: number,
  } = {
    mobile: window.innerWidth <= Config.mobileMaxWidth,

    restaurantName: null,
    search: "",
    products: [],
    categories: [],
    partner: null,
    shippingPrices: [],
    cities: [],
    mounted: false,
    selectedCity: null,
    searching: false,
    menuListTouched: false,
    polygons: [],
    hearted: false,

    showingProductMobile: 1,
    showMenuCombiner: false,
    swiperPaneScrollable: false,
    wrapperScrollable: true,
    activeSwiperPanelIndex: 0,

    selectedCategory: 0,
    showFixedHeadBar: false,
    maxProductsContainerHeight: "60vh",
    containerPaddingBottom: "0px",

    selectedProductID: -1,
    statusBarSpaceHeight: ContextSystem.statusBarSpaceHeight,
    language: ContextSystem.language,
  };

  static getCategoryByID(categories, id) {
    if (typeof id === "string") id = parseInt(id);

    if (!categories)
      return null;

    for (let c of categories)
      if (c.id === id)
        return c;

    return null;
  }

  sortCategories(categories: Category[], products: Product[]): Category[] {
    let sortedCategories: Category[] = [];
    let leftCategories: Category[] = [];
    for (let category of categories)
      leftCategories.push(category);

    if (products.find(p => p.type === ProductTypes.MENU)) {
      sortedCategories.push({
        id: -1,
        enabled: true,
        name: TranslatableString.create2(Names.SpecialOffers),
        image: "",
        parent: 0,
        partnerID: this.state?.partner?.id ?? -1,
        orderNumber: -10,
        specials: true
      });
    }

    let currentCategory = null;
    A: while (leftCategories.length > 0) {
      if (currentCategory === null) {
        for (let c of leftCategories) {
          if (c.parent === 0) {
            currentCategory = c;
            if (sortedCategories.indexOf(c) === -1) {
              leftCategories.remove(c);
              sortedCategories.push(c);
            }
            continue A;
          }
        }
      } else {
        if (sortedCategories.indexOf(currentCategory) === -1) {
          leftCategories.remove(currentCategory);
          sortedCategories.push(currentCategory);
        }

        //check if it has children
        for (let c of leftCategories) {
          if (c.parent === currentCategory.id) {
            leftCategories.remove(c);
            sortedCategories.push(c);
            continue A;
          }
        }
        //has no child --> step to parent
        if (currentCategory.parent === 0) {
          currentCategory = null;
        } else {
          for (let c of categories) {
            if (c.id === currentCategory.parent) {
              currentCategory = c;
              continue A;
            }
          }
        }
        //end
      }
    }
    //end of tree preorder sort
    return sortedCategories;
  }

  loadShopFromContext() {
    let partner = ContextSystem.navigatingPartner;
    let products: Product[] = ContextSystem.products
      .filter(p => p.enabled && p.partnerID === ContextSystem.navigatingPartner?.id)
      .filter(p => {
        if (!partner.hideNotAvailableProducts)
          return true;
        let openStatus = getOpeningState(p.weekSchedule, ContextSystem.scheduleDate);
        let isDisabled: boolean = openStatus.status !== HourStatuses.OPEN && openStatus.status !== HourStatuses.NO_DATA;
        return !isDisabled;
      })
      .sort((p1, p2) => p1.orderPlace - p2.orderPlace);
    let categories = ContextSystem.categories.filter(c => c.partnerID === ContextSystem.navigatingPartner?.id)
      .sort((a, b) => a.orderNumber - b.orderNumber);
    let restaurantHeart: RestaurantHeart = ContextSystem.restaurantHearts.find(rh => rh?.enabled && rh.partnerID === ContextSystem.navigatingPartner?.id && rh.profileID === ContextSystem.profile?.id);

    let sortedCategories: Category[] = this.sortCategories(categories, products);
    let selectedCategory = -1;

    this.setState(
      {
        partner,
        products,
        categories: sortedCategories,
        selectedCategory,
        hearted: restaurantHeart,
        search: ""
      },
      () => {
        EventSystem.publish(EventSystem.events.restaurant_loaded, {
          partner,
          categories: sortedCategories,
          restaurantHeart,
          products
        });
      }
    );
  }

  componentWillUnmount() {
    for (let eventID of this.eventIDs) {
      EventSystem.unsubscribe(eventID);
    }
    EventSystem.publish(EventSystem.events.restaurant_page_unmounted);
  }

  updateScrollShowingFixedHeadBar(scrollPosition) {
    if (!this.contentWrapperRef) return;
    let showFixedHeadBar = scrollPosition >= 60; //this.contentWrapperRef.offsetTop - 60;
    if (showFixedHeadBar !== this.state.showFixedHeadBar)
      this.setState({showFixedHeadBar});
  }

  updateScrollShowingCategory(scrollPosition) {
    for (let categoryID in this.scrollCategoryRefs) {
      // noinspection JSUnfilteredForInLoop
      let scrollCategoryRef = this.scrollCategoryRefs[categoryID];
      // noinspection JSUnusedLocalSymbols,JSUnfilteredForInLoop
      let scrollCategoryLabelRef = this.categoriesLabelRef[categoryID];
      // noinspection JSUnfilteredForInLoop
      let c = RestaurantPage.getCategoryByID(this.state.categories, categoryID);

      if (!c || !scrollCategoryRef)
        continue;

      let min = scrollCategoryRef.offsetTop - 101;
      let max = min + scrollCategoryRef.getBoundingClientRect().height;

      if (scrollPosition >= min && scrollPosition <= max) {
        // noinspection JSUnfilteredForInLoop
        this.setState({selectedCategory: parseInt(categoryID)});
        this.scrollToCategoryLabel(parseInt(categoryID));
        break;
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let path = window.location.pathname.toLowerCase();
    let atPlace: boolean = (path.startsWith("/asztal") && !(path === "/asztal" || path === "/asztal/"))
      || (path.startsWith("/table") && !(path === "/table" || path === "/table/"));

    if (!atPlace) {
      if (this.state.restaurantName !== this.props.match.params.restaurant) {
        let restaurantName = this.props.match.params.restaurant;
        this.setState({restaurantName});
        ContextSystem.loadRestaurant(restaurantName);
        this.wrapperRef.scrollTo({top: 0, behavior: "smooth"});
      }
    }
  }

  componentDidMount() {
    let path = window.location.pathname.toLowerCase();
    let atPlace: boolean = (path.startsWith("/asztal") && !(path === "/asztal" || path === "/asztal/"))
      || (path.startsWith("/table") && !(path === "/table" || path === "/table/"));

    this.eventIDs = [];
    let eid: number;
    let maxProductsContainerHeight: number = ContextSystem.containerHeight - 60 + 40;

    //this.updateScrollables();
    if (!atPlace) {

    } else {
      //maxProductsContainerHeight = ContextSystem.containerHeight - 60;
    }

    this.setState({
      mounted: true,
      maxProductsContainerHeight: maxProductsContainerHeight + "px"
    });

    window.addEventListener("resize", () => {
      this.setState({
        mobile: window.innerWidth <= Config.mobileMaxWidth
      });
    });

    eid = EventSystem.subscribe(EventSystem.events.hard_open_product_popup_intent, ({product}) => {
      this.handleProductClick(product, true);
    });
    this.eventIDs.push(eid);

    eid = EventSystem.subscribe(EventSystem.events.openRedirectToProduct, ({productID}) => {
      if (this.state.products) {
        let product;
        for (let p of this.state.products) {
          if (p.id === productID) {
            product = p;
            break;
          }
        }
        if (product) {
          setTimeout(() => {
            EventSystem.publish(EventSystem.events.popup_product, {
              product,
              addCb: () => this.handleAddToCartClick(product)
            });
            this.scrollToProduct(productID);
          }, 300);
        }
      }
    });
    this.eventIDs.push(eid);

    eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged,
      ({path, loggedIn, containerHeight, hideCart, statusBarSpaceHeight, language, navigatingPartner}) => {
        if (path !== undefined && (path.startsWith("/etterem/") || path.startsWith("/e/") || path.startsWith("/shop/") || path.startsWith("/s/"))) {
          let restaurantName = this.props.match.params.restaurant;
          if (restaurantName !== this.state.restaurantName) {
            this.setState({restaurantName});
            ContextSystem.loadRestaurant(restaurantName);
          }
        }

        if (navigatingPartner !== undefined)
          this.loadShopFromContext();

        if (containerHeight !== undefined)
          this.setState({maxProductsContainerHeight: ContextSystem.containerHeight - 60 + 40 - 40 + "px"});

        if (hideCart !== undefined) {
          if (hideCart === false && window.innerWidth <= Config.mobileMaxWidth) {
            this.setState({
              containerPaddingBottom: ContextSystem.mobileCartHeight + "px"
            });
          } else {
            this.setState({containerPaddingBottom: "0"});
          }
        }
        if (loggedIn !== undefined && loggedIn === false) {
          // this.load(this.state.restaurantName);
          this.setState({hearted: undefined});
        }

        if (statusBarSpaceHeight !== undefined)
          this.setState({statusBarSpaceHeight});

        if (language !== undefined)
          this.setState({language});
      });
    this.eventIDs.push(eid);

    if (atPlace === true) {
      let qrid: number = this.props.match.params.qrid;
      ContextSystem.setAtPlace(qrid, (res) => {
        ContextSystem.loadRestaurant(res.shop.url);
      });
    } else if (!atPlace) {
      let restaurantName = this.props.match.params.restaurant;
      this.setState({restaurantName});
      if (restaurantName && restaurantName.length > 0) {
        ContextSystem.setLastRestaurantURL(restaurantName);
      } else if (ContextSystem.lastRestaurantURL) {
        restaurantName = ContextSystem.lastRestaurantURL;
      }
      ContextSystem.loadRestaurant(restaurantName);
    }

    EventSystem.publish(EventSystem.events.restaurant_page_mounted);
  }

  preAddToCartIntent() {
    if (!ContextSystem.pickup && ContextSystem.atPlaceQRID <= 0 && !ContextSystem.selectedAddress) {
      EventSystem.publish(EventSystem.events.popFloatingAddressPicker);
    }
  }

  handleAddToCartClick(product: Product) {
    if (!product)
      return;

    this.preAddToCartIntent();
    this.addProductToCart(product);
  }

  handleProductClick(product: Product, hard_open: boolean = false) {
    this.preAddToCartIntent();
    EventSystem.publish(EventSystem.events.popup_product, {
      product,
      addCb: (p) => this.handleAddToCartClick(p),
      hard_open
    });
  }

  closedOrPreOrderWarningCounter = 1;

  addProductToCart(product: Product) {
    if (this.openingStatus.closed && this.closedOrPreOrderWarningCounter <= 3) {
      Toast.showToast(Language.getName(Names.ClosedRestaurantText));
      this.closedOrPreOrderWarningCounter++;
    }
    if (this.openingStatus.preOrder && this.closedOrPreOrderWarningCounter <= 3) {
      Toast.showToast(Language.getName(Names.RestaurantOnlyTakesPreOrder));
      this.closedOrPreOrderWarningCounter++;
    }
    let leadOP = Cart.getLeadOrderProduct(product.orderProduct);
    leadOP.qty = 1;
    if (product.type === ProductTypes.PRODUCT)
      CartStorage.addProduct(product);
    else if (product.type === ProductTypes.MENU)
      CartStorage.addMenu(product);
  }

  handleCategoryClick(category) {
    this.setState({selectedCategory: category.id});
    this.scrollToCategory(category.id);
  }

  scrollToCategory(categoryID) {
    if (categoryID === undefined || !this.scrollCategoryRefs[categoryID])
      return;

    let scrollTo = this.scrollCategoryRefs[categoryID].offsetTop - 100;
    this.wrapperRef.scrollTo({top: scrollTo, behavior: "smooth"});
  }

  scrollToCategoryLabel(categoryID: number) {
    if (!categoryID || !this.categoriesLabelRef[categoryID])
      return;

    let scrollTo = this.categoriesLabelRef[categoryID].offsetLeft - 10;
    this.categoriesWrapperRef.scrollTo({left: scrollTo, behavior: "smooth"});
  }

  scrollToProduct(productID: number) {
    if (productID === undefined || !this.productRefs[productID]) {
      return;
    }
    let scrollTo = this.productRefs[productID].offsetTop - 200;
    this.wrapperRef.scrollTo({top: scrollTo, behavior: "smooth"});
    this.setState({selectedProductID: productID});
  }

  handleBackClick() {
    EventSystem.publish(EventSystem.events.redirectIntent, {
      redirectPath: null,
      queryParams: null,
      goBack: true
    });
  }

  handleOpenInfoClick() {
    EventSystem.publish(EventSystem.events.open_restaurant_info, {
      partner: this.state.partner
    });
  }

  handleHeartClicked() {
    RestaurantAPI.heart(!this.state.hearted, this.state.partner.id, (res) => {
      if (res.error === 0) {
        this.setState({hearted: !this.state.hearted});
      }
    });
  }

  scrollStart: number = -1;
  scrollDirection: number = 0; //positive === down, negative = up

  handleWrapperScroll(e) {
    if (e.target !== this.wrapperRef)
      return;

    let scrollPosition = e.target.scrollTop;
    if (this.scrollStart === -1) {
      this.scrollStart = scrollPosition;
    }
    if (Math.abs(this.scrollStart - scrollPosition) > 30) {
      if (this.scrollStart - scrollPosition > 0)
        this.scrollDirection = -1;
      else if (this.scrollStart - scrollPosition > 0)
        this.scrollDirection = +1;
    }

    //let scrollPercentage = (100 * scrollPosition) / (e.target.scrollHeight - e.target.offsetHeight);

    this.updateScrollShowingCategory(scrollPosition);
    this.updateScrollShowingFixedHeadBar(scrollPosition);
  }

  setSearch(search: string) {
    this.setState({search});
  }

  openingStatus = {
    closed: null,
    preOrder: null
  };

  filterProduct(p: Product): boolean {
    if (!p)
      return false;
    let s: string = this.state.search.trim();

    s = WelcomeScreen.convertString(s.trim().toLowerCase());

    if (s.length <= 0)
      return true;

    let globalCategory: Category = ContextSystem?.globalCategories?.find(gc => gc.id === p.globalCategoryID);
    if (!globalCategory)
      globalCategory = {name: TranslatableString.create("")};

    let category: Category = this.state.categories.find(c => c.id === p.categoryID);
    if (!category)
      category = {name: TranslatableString.create("")};

    return TranslatableString.check(p.name, n => WelcomeScreen.convertString(n.value).includes(s), false)
      || TranslatableString.check(p.details, n => WelcomeScreen.convertString(n.value).includes(s), false)
      || TranslatableString.check(globalCategory.name, n => WelcomeScreen.convertString(n.value).includes(s), false)
      || TranslatableString.check(category.name, n => WelcomeScreen.convertString(n.value).includes(s), false)
      || p.gtin.trim().toLowerCase().includes(s)
      || p.sku.trim().toLowerCase().includes(s);
  }

  downloadCouponImage(url: string) {
    Analytics.triggerGTMEvent("coupon_downloaded", {
      handle: this.state.partner?.url,
      name: this.state.restaurantName,
      url: url
    });
  }

  render() {
    let OpenStatus = HoursCalc.checkOpeningState(HourTypes.OPENING, this.state.partner);
    let AcceptingPickUpOrdersStatus = HoursCalc.checkOpeningState(HourTypes.ORDER_ACCEPT_FOR_PICKUP, this.state.partner);
    let DeliveringStatus = HoursCalc.checkOpeningState(HourTypes.DELIVERY, this.state.partner);
    let AcceptingOrdersStatus = HoursCalc.checkOpeningState(HourTypes.ORDER_ACCEPT, this.state.partner);

    let openingHoursText = HoursCalc.getOpeningHoursText(OpenStatus, AcceptingPickUpOrdersStatus, DeliveringStatus, AcceptingOrdersStatus);

    this.openingStatus = {closed: false, preOrder: false};

    if (openingHoursText.openStringDL === HourStatuses.NO_DATA) {
      this.openingStatus.closed = true;
    } else if (openingHoursText.openStringDL === HourStatuses.LOADING) {
      this.openingStatus.closed = false;
      this.openingStatus.preOrder = false;
    } else if (openingHoursText.openStringDL.includes(Language.getName(Names.Closed))) {
      this.openingStatus.closed = true;
    } else if (openingHoursText.openStringDL.includes(Language.getName(Names.ForPreOrder))) {
      this.openingStatus.preOrder = true;
    }

    if (openingHoursText.openStringDL === HourStatuses.NO_DATA)
      openingHoursText.openStringDL = Language.getName(Names.Closed);
    if (openingHoursText.openStringPK === HourStatuses.NO_DATA)
      openingHoursText.openStringPK = Language.getName(Names.Closed);
    if (openingHoursText.openStringDL === HourStatuses.LOADING)
      openingHoursText.openStringDL = "";
    if (openingHoursText.openStringPK === HourStatuses.LOADING)
      openingHoursText.openStringPK = "";

    let minShippingPrice = -1;

    for (let s of this.state.shippingPrices) {
      if (minShippingPrice === -1 || minShippingPrice > s.minimumOrder)
        minShippingPrice = s.minimumOrder;
    }
    if (minShippingPrice === -1) {
      // noinspection JSUnusedAssignment
      minShippingPrice = 0;
    }

    const languageSelector = (
      <LanguageSelectorWrapper align_items={"flex-end"} justify_content={"flex-start"}>
        <ReactFlagsSelect
          width={160}
          selected={languages[this.state.language].languageCode}
          countries={languages.map(l => l.languageCode)}
          customLabels={(() => {
            let o = {};
            languages.forEach(l => o[l.languageCode] = l.label);
            return o;
          })()}
          onSelect={code => ContextSystem.setLanguage(languages.find(l => l.languageCode === code).id)}
        />
      </LanguageSelectorWrapper>
    );

    const searchFilter = (
      <SearchFilterWrapper>
        <FiSearch/>
        <SearchTextInput
          autoComplete={"off"}
          value={this.state.search}
          type="text"
          placeholder={Language.getName(Names.Search) + "..."}
          onKeyUp={e => e.key === "Escape" && this.setSearch("")}
          onChange={(e) => this.setSearch(e.target.value)}
        />
        {this.state.search && this.state.search.length > 0 &&
          <ClearSearchWrapper>
            <FaTimes onClick={() => this.setSearch("")}/>
          </ClearSearchWrapper>
        }
      </SearchFilterWrapper>
    );

    const categoriesSelector = (
      <CategoriesWrapper>
        <CategoriesContainer ref={r => this.categoriesWrapperRef = r}>
          {this.state.categories.map((c, i) => {
            return (
              <React.Fragment key={i}>
                {c.parent === 0 && (
                  <>
                    <CategoryDiv selected={this.state.selectedCategory === c.id} key={i}
                                 onClick={() => this.handleCategoryClick(c)}
                                 ref={r => this.categoriesLabelRef[c.id] = r}
                    >
                      {TranslatableString.get(c.name)}
                    </CategoryDiv>
                    {/*subcategories 'cc' of the main 'c' category */}
                    {this.state.categories.map((subC, j) => (
                      <React.Fragment key={j}>
                        {subC.parent === c.id && (
                          <CategoryDiv selected={this.state.selectedCategory === subC.id} key={j}
                                       onClick={() => this.handleCategoryClick(subC)}
                                       ref={r => this.categoriesLabelRef[subC.id] = r}
                                       sub={true}
                          >
                            {TranslatableString.get(subC.name)}
                          </CategoryDiv>
                        )}
                      </React.Fragment>
                    ))}
                  </>
                )}
              </React.Fragment>
            );
          })}
        </CategoriesContainer>
      </CategoriesWrapper>
    );

    const productLists = (
      <ProductListsWrapper>
        {this.state.categories.map((c, j) => {
          let productsToShow: Product[] = this.state.products.filter(p => p.enabled && p.active && (p.categoryID === c.id || (c.specials && p.type === ProductTypes.MENU)) && p.availableOnline);
          let totalProductsIfParent: Product[] = [];
          if (c.parent === 0) {
            totalProductsIfParent = this.state.products.filter(p => {
              let productCategory: Category = this.state.categories.find(cat => cat.id === p.categoryID);
              if (!productCategory)
                return false;
              return p.enabled && p.active && (
                productCategory.id === p.categoryID // the product falls into this category
                || productCategory.parent === c.id  // or the product falls into a subcategory of the current category
              );
            });
          }
          if (this.state.search.trim().length > 0) {
            productsToShow = productsToShow.filter(p => this.filterProduct(p));
            totalProductsIfParent = totalProductsIfParent.filter(p => this.filterProduct(p));
          }
          if (c.parent === 0) {
            if (productsToShow.length <= 0 && totalProductsIfParent.length <= 0)
              return <React.Fragment key={j}/>;
          } else {
            if (productsToShow.length <= 0)
              return <React.Fragment key={j}/>;
          }

          return (
            <ProductsList key={j} ref={(r) => (this.scrollCategoryRefs[c.id] = r)} maincategory={c.parent === 0}
                          first={j === 0}>
              {c.parent === 0 && <h2>{TranslatableString.get(c.name)}</h2>}
              {c.parent !== 0 && <h3>{TranslatableString.get(c.name)}</h3>}
              {productsToShow.map((p, i) => (
                <ProductWrapper key={i} ref={(r) => (this.productRefs[p.id] = r)}>
                  <ProductDiv
                    key={i}
                    showLocation={false}
                    showOpenHours={false}
                    product={p}
                    partnerProfile={this.state.partner}
                    addToCart
                    handleClick={() => this.handleProductClick(p)}
                    handleButtonClick={() => this.handleAddToCartClick(p)}
                    selected={p.id === this.state.selectedProductID}
                  />
                </ProductWrapper>
              ))}
            </ProductsList>
          );
        })}
      </ProductListsWrapper>
    );

    let partner: Shop = this.state.partner;
    let restaurantName = partner ? partner.name : " ";
    if (!restaurantName)
      restaurantName = partner.name; //old model

    return (
      <>
        {this.state.partner &&
          <Helmet>
            <title>{restaurantName} - Blundee.com</title>
            <meta name="description" content={restaurantName + " - Blundee.com"}/>
          </Helmet>
        }
        {!this.state.partner &&
          <Helmet>
            <title>{Language.getName(Names.RestaurantPageTitle)}</title>
            <meta name="description" content={Language.getName(Names.RestaurantPageTitle)}/>
          </Helmet>
        }
        <FixedHeadBar top={this.state.statusBarSpaceHeight} show={this.state.showFixedHeadBar}>
          <FixedHeadBarContainer show={this.state.showFixedHeadBar}>
            <HeadBarContent
              title={restaurantName}
              showTitle={true}
              hearted={this.state.hearted}
              showHeartedButton={true}
              showDetailsButton={true}
              mobile={this.state.mobile}
              handleBackClick={() => this.handleBackClick()}
              handleHeartClicked={() => this.handleHeartClicked()}
              handleOpenInfoClick={() => this.handleOpenInfoClick()}
            />
          </FixedHeadBarContainer>
        </FixedHeadBar>
        <Wrapper
          // onTouchMove={(e) => this.handleTouchMove(e, "w")}
          // onTouchEnd={(e) => this.handleTouchEnd(e, "w")}
          // onTouchStart={(e) => this.handleTouchStart(e, "w")}
          onScroll={(e) => this.handleWrapperScroll(e)}
          ref={(r) => (this.wrapperRef = r)}
          scrollable={this.state.wrapperScrollable}
        >
          <ParallaxWrapper>
            <ParallaxBGImage
              src={this.state.partner ? Config.partnerImageUrlBase + (this.state.mobile ? this.state.partner.coverImageMobile?.fileName : this.state.partner.coverImagePC?.fileName) : ""}
              alt={"img"}/>
            <HeadBar mobile={this.state.mobile} searching={this.state.searching} /*position="fixed"*/>
              <HeadBarContent
                title={restaurantName}
                showTitle={false}
                hearted={this.state.hearted}
                showHeartedButton={true}
                showDetailsButton={true}
                mobile={this.state.mobile}
                handleBackClick={() => this.handleBackClick()}
                handleHeartClicked={() => this.handleHeartClicked()}
                handleOpenInfoClick={() => this.handleOpenInfoClick()}
              />
            </HeadBar>
            <ParallaxInsideDiv>
              {languageSelector}
              <ParallaxInsideBottomContainer>
                <RestaurantName
                  long={restaurantName.length > 10}>
                  <h1 style={{zIndex: this.openingStatus.preOrder || this.openingStatus.closed ? "-1" : "auto"}}>
                    {restaurantName}
                  </h1>
                </RestaurantName>
                <RestaurantHeader>
                  <RestaurantInfos>
                    <OpenHourContainer open={AcceptingOrdersStatus.status === HourStatuses.OPEN}>
                      <BsClockFill/>
                      {" " + openingHoursText.openStringDL}
                    </OpenHourContainer>
                    <span>
                      <FaMapMarkerAlt/>{" "}
                      {this.state.partner && !this.state.partner.address?.zipCodeID && this.state.partner.zip + " " + this.state.partner.city + ", " + this.state.partner.address}
                      {this.state.partner && this.state.partner.address?.zipCodeID && ContextSystem.getZipCode(this.state.partner.address.zipCodeID)?.zipcode + " " + TranslatableString.get(ContextSystem.getCityByID(this.state.partner.address.cityID)?.name) + ", " + this.state.partner.address.street}
                    </span>
                  </RestaurantInfos>
                </RestaurantHeader>
              </ParallaxInsideBottomContainer>
            </ParallaxInsideDiv>
          </ParallaxWrapper>
          <ContentWrapper ref={(r) => (this.contentWrapperRef = r)}>
            {Config.getBoolean(ConfigKeys.enable_rizi_kupon) && Config.getValue(ConfigKeys.rizi_coupon_handles)?.includes(this.state.partner?.url) &&
              <CouponsScrollWrapper>
                <CouponsWrapper>
                  <CouponDiv target={"_blank"}
                             href={Config.partnerImageUrlBase + Config.getString(ConfigKeys.rizi_coupon_img)}
                             width={"100%"}
                             onClick={() => this.downloadCouponImage(Config.getString(ConfigKeys.rizi_coupon_img))}
                  >
                    <CouponSideCrop position={"left"}/>
                    <CouponSideCrop position={"right"}/>
                    <CouponDetails>
                      <CouponTitle>{Language.getName(Config.getValue(ConfigKeys.rizi_coupon_title))}</CouponTitle>
                      <CouponDescription>{Language.getName(Config.getValue(ConfigKeys.rizi_coupon_description))}</CouponDescription>
                      <CouponValidity>{Language.getName(Config.getValue(ConfigKeys.rizi_coupon_validity))}</CouponValidity>
                    </CouponDetails>
                  </CouponDiv>
                </CouponsWrapper>
              </CouponsScrollWrapper>
            }
            {this.state.mobile && (
              <>
                {categoriesSelector}
                {searchFilter}
                {!this.state.partner?.orderAvailable &&
                  <RestaurantNote>
                    {Language.getName(Config.getValue(ConfigKeys.order_not_available_text))}
                  </RestaurantNote>
                }
                {productLists}
                {/*{swiper}*/}
              </>
            )}
            {!this.state.mobile && (
              <ContentSplitWrapper>
                <ProductsAndCategoriesWrapper>
                  {categoriesSelector}
                  {searchFilter}
                  {!this.state.partner?.orderAvailable &&
                    <RestaurantNote>
                      {Language.getName(Config.getValue(ConfigKeys.order_not_available_text))}
                    </RestaurantNote>
                  }
                  {productLists}
                </ProductsAndCategoriesWrapper>
                <CartPaneWrapper>
                  <CartPane>
                    <Cart toggleOpened={false} showCloseButton={null}/>
                  </CartPane>
                </CartPaneWrapper>
              </ContentSplitWrapper>
            )}
          </ContentWrapper>
        </Wrapper>
      </>
    );
  }
}

let redirectToProductEvents = [];

EventSystem.subscribe(EventSystem.events.redirectToProduct, ({productID, url}) => {
  console.log("redirectToProduct event captured");
  redirectToProductEvents.push(productID);
  EventSystem.publish(EventSystem.events.redirectIntent, {redirectPath: "/etterem/" + url});
});

EventSystem.subscribe(EventSystem.events.restaurant_loaded, () => {
  console.log("restaurant_loaded event captured");
  //TODO change if products will be loaded later
  if (redirectToProductEvents.length > 0) {
    let productID = redirectToProductEvents[redirectToProductEvents.length - 1];
    console.log("openRedirectToProduct event fired");
    EventSystem.publish(EventSystem.events.openRedirectToProduct, {productID});
    redirectToProductEvents = [];
  }
});

export default withRouter(RestaurantPage);

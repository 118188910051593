import "./Initializer";
import {initAppDesigns} from "./Initializer";

import React, {Component} from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import ScrollToTop from "./utils/ScrollToTop";

//Layouts
import WebRoutes from "./routes/WebRoutes";

import CookiesNotification from "./components/CookiesNotification";
import Analytics from "./components/Analytics";
import SEO from "./components/SEO";
import CartPopup from "./components/cart/CartPopup";
import styled, {css} from "styled-components";

import NavBar from "./components/navbar/Navbar";
import AddressPickerPopup from "./components/popups/AddressPickerPopup";
import DetailedProductPopUp from "./components/popups/DetailedProductPopUp";
import RestaurantSidePanel from "./components/restaurantpage/RestaurantSidePanel";
import ContextSystem from "./utils/ContextSystem";
import EventSystem from "./utils/EventSystem";
import ProfileDetailsPopup from "./components/popups/ProfileDetailsPopup";
import ConfirmationModal from "./components/ConfirmationModal";
import FloatingAddressButton from "./components/addresspicker/FloatingAddressButton";
import FloatingAddressPickerPopUp from "./components/popups/FloatingAddressPickerPopUp";
import OrderFinishing from "./components/cart/OrderFinishing";
import Config from "./utils/Config";
import Toast from "./components/Toast";
import DeepLinkListener from "./components/navbar/DeepLinkListener";
import DonationSelectorPopup from "./components/popups/DonationSelectorPopup";
import {Dots} from "loading-animations-react";
import ReservationPopup from "./components/popups/ReservationPopup";
import packageInfo from "../package.json";
import {CacheBusterOwn} from "./utils/CacheBusterOwn";
import GoogleFormsPopUp from "./components/popups/GoogleFormsPopUp";
import LoginPopup from "./components/popups/LoginPopup";

const LoadingApp = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  backdrop-filter: blur(6px);
  background-color: var(--blunde_loading_bg);

  transition: backdrop-filter 300ms ease-in-out, background-color 300ms ease-in-out;

  ${({show}) => show !== true && css`
    backdrop-filter: blur(0);
    pointer-events: none;
    background-color: transparent;
  `};
`;

const Container = styled.div`
  width: auto;
  height: 100%;
  flex-grow: 1;
  min-width: 70%;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  color: var(--blundee_color_normal);

  ${({scrollable}) => scrollable === false && css`
    overflow-y: hidden;
  `};

  @media screen and (max-width: 800px) {
    height: auto;
    flex-grow: 2;
    width: 100%;

    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    ${({paddingbottom}) => paddingbottom !== undefined && css`
      padding-bottom: ${paddingbottom};
    `};
  }

  @media screen and (min-width: 801px) {
    &::-webkit-scrollbar-thumb {
      background-color: var(--blundee_scroll_thumb_color);
      border-radius: 100px;
    }

    &::-webkit-scrollbar {
      width: 15px;
      background-color: transparent;
      //border-left: 1px solid rgb(20, 20, 20);
    }
  }
`;

export default class App extends React.Component {
  render() {
    return (
      <CacheBusterOwn
        currentVersion={packageInfo.version}
        isEnabled={true} //If false, the library is disabled.
        isVerboseMode={Config.TEST} //If true, the library writes verbose logs to console.
        //loadingComponent={<Loading/>} //If not pass, nothing appears at the time of new version check.
        metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
        onCacheClear={null}
      >
        <BrowserRouter>
          <DeepLinkListener/>
          <ScrollToTop>
            <SEO/>
            {/*<ConfiguredToastContainer/>*/}
            <Toast/>
            <Analytics/>
            <Switch>
              <Route component={WebLayout}/>
            </Switch>
          </ScrollToTop>
        </BrowserRouter>
      </CacheBusterOwn>
    );
  }
}

const VerticalWrapper = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  position: fixed;
  top: 0;
  left: 0;
`;

const BackgroundWrapper = styled.div`
  width: 100%;
  height: 100%;
  flex-shrink: 1;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;

  z-index: -12;
  background-color: var(--blundee_background);

  overflow: hidden;
`;

const StatusBarSpace = styled.div`
  width: 100%;
  height: ${({height}) => height ? height + "px" : "0px"};
  transition: height 300ms ease;
  background-color: transparent;
  flex-shrink: 0;
`;

const FullPageWrapper = styled.div`
  width: 100%;
  height: 100%;
  flex-shrink: 1;
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;

  z-index: -11;
  background-color: var(--blundee_background);

  overflow: hidden;

  @media screen and (max-width: 800px) {
    flex-direction: column-reverse;
  }
`;

class WebLayout extends Component {
  containerRef;
  fullPageWrapperRef;

  state: {
    enableContainerScrolling: boolean,
    statusBarSpaceHeight: number,
    cartTakesSpace: boolean,
    welcomeScreenEnabled: boolean,
    mobileContentPaddingBottom: number,
    url: string,
    appLoaded: boolean,
  } = {
    statusBarSpaceHeight: ContextSystem.statusBarSpaceHeight,
    enableContainerScrolling: ContextSystem.enableContainerScrolling,
    cartTakesSpace: window.innerWidth <= Config.mobileMaxWidth && ContextSystem.hideCart === false,
    welcomeScreenEnabled: ContextSystem.welcomeScreenEnabled,
    mobileContentPaddingBottom: ContextSystem.mobileContentPaddingBottom,
    url: window.location.href,
    appLoaded: ContextSystem.appLoaded
  };

  // handleScroll(e) {
  // if (e.target === this.containerRef)
  //   ContextSystem.updateScrollPosition(e.target.scrollTop, e.target.scrollHeight, e.target.offsetHeight);
  // }

  scrollToTop() {
    if (!this.containerRef)
      return;
    try {
      this.containerRef.scrollTo({top: 0, behavior: "smooth"});
    } catch (e) {
      this.containerRef.scrollTo(0, 0);
    }
  }

  componentDidMount() {
    initAppDesigns();

    EventSystem.subscribe(EventSystem.events.contextSystemChanged,
      ({
         enableContainerScrolling,
         hideCart,
         hideNavBar,
         welcomeScreenEnabled,
         statusBarSpaceHeight,
         mobileContentPaddingBottom,
         appLoaded
       }) => {
        if (enableContainerScrolling !== undefined) {
          console.log("Container" + (enableContainerScrolling === false ? " not" : "") + " scrolling");
          this.setState({enableContainerScrolling});
        }
        if (hideCart !== undefined) {
          this.updateCartTakesPlace();
        }
        if (hideNavBar !== undefined || hideCart !== undefined) {
          this.updateContainerHeightContext();
        }
        if (welcomeScreenEnabled !== undefined) {
          this.scrollToTop();
          this.setState({welcomeScreenEnabled});
        }
        if (statusBarSpaceHeight !== undefined) {
          this.setState({statusBarSpaceHeight});
        }
        if (mobileContentPaddingBottom !== undefined) {
          this.setState({mobileContentPaddingBottom});
        }
        if (appLoaded !== undefined) {
          this.setState({appLoaded});
        }
      });

    EventSystem.subscribe(EventSystem.events.urlChanged, () => {
      this.setState({url: window.location.href});
    });

    // EventSystem.subscribe(EventSystem.events.scrollContainer, ({ scrollTo }) => {
    //   if (this.containerRef === undefined || scrollTo === undefined)
    //     return;
    //   this.containerRef.scrollTo({ top: scrollTo, behavior: "smooth" });
    // });

    window.addEventListener("resize", () => {
      this.updateContainerHeightContext();
      this.updateCartTakesPlace();
    });

    setTimeout(() => this.updateContainerHeightContext(), 300);
    this.updateCartTakesPlace();
  }

  updateCartTakesPlace() {
    this.setState({cartTakesSpace: window.innerWidth <= Config.mobileMaxWidth && ContextSystem.hideCart === false});
  }

  updateContainerHeightContext() {
    if (this.containerRef) {
      ContextSystem.setContainerHeight(this.containerRef.clientHeight);
    }
  }

  render() {
    return (
      <>
        <div id={"safeareas"}/>
        <VerticalWrapper>
          <CookiesNotification/>
          <BackgroundWrapper>
            {/*<StatusBarSpace height={this.state.welcomeScreenEnabled ? 0 : this.state.statusBarSpaceHeight}/>*/}
            <FullPageWrapper ref={(r) => (this.fullPageWrapperRef = r)}>

              <FloatingAddressButton usedInNavBar={false}/>

              <FloatingAddressPickerPopUp/>
              <CartPopup/>
              <ReservationPopup/>
              <ProfileDetailsPopup/>
              <AddressPickerPopup/>
              <GoogleFormsPopUp/>
              <DonationSelectorPopup/>
              <RestaurantSidePanel/>

              <DetailedProductPopUp/>
              <OrderFinishing/>
              <ConfirmationModal/>
              <LoginPopup/>

              <NavBar/>
              <Container
                scrollable={this.state.enableContainerScrolling}
                ref={(r) => (this.containerRef = r)}
                // onScroll={(e) => this.handleScroll(e)}
              >
                <StatusBarSpace height={this.state.statusBarSpaceHeight}/>
                {/*<GradientBG/>*/}
                <WebRoutes/>
              </Container>
            </FullPageWrapper>
          </BackgroundWrapper>
        </VerticalWrapper>
        <LoadingApp show={!this.state.appLoaded}>
          {this.state.appLoaded !== true &&
            <Dots dotColors={[
              "var(--loading_dots_1)", "var(--loading_dots_2)",
              "var(--loading_dots_3)", "var(--loading_dots_4)",
              "var(--loading_dots_5)", "var(--loading_dots_6)",
            ]}
                  text={""}
                  className="appLoadingDots"
            />
          }
        </LoadingApp>
      </>
    );
  }
}

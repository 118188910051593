import AbstractModel from "./AbstractModel";

export class NonprofitOrganization extends AbstractModel {
  name: string;
  details: string;
  longDetails: string;
  tags: string[];
  partnerProfileContactID: number;
  profilePictureID: number;
  coverImagePCID: number;
  coverImageMobileID: number;
}

export class Donation extends AbstractModel {
  nonProfitOrganizationID: number;
  orderID: number;
  type: DonationType;
  value: number;
  message: string;
  orderNumber: string;
}

export const DonationTypes = {
  FIX: 1,
  PERCENTAGE: 2
};

export type DonationType = $Values<typeof DonationTypes>;

import React, { ChangeEvent, Component, PropsWithChildren } from "react";
import styled, { css } from "styled-components";
import EventSystem from "../../utils/EventSystem";
import { Button as ButtonOrg, Input } from "../Form";
import Cart from "./Cart";
import type { SendOrderMessageType } from "../../utils/api/OrdersAPI";
import { OrdersAPI } from "../../utils/api/OrdersAPI";
import { CartStorage } from "../../utils/CartStorage";
import { googleMapsCenterBP } from "../restaurantpage/RestaurantSidePanel";
import { BsCaretDownFill, BsCaretUpFill, BsChevronLeft } from "react-icons/bs";
import { FaTimes } from "react-icons/fa";
import { GoCheck, GoLocation } from "react-icons/go";
import { ImCross } from "react-icons/im";
import ContextSystem from "../../utils/ContextSystem";
import { GoogleMap, Marker, withGoogleMap } from "react-google-maps";
import { AddressAPI } from "../../utils/api/AddressAPI";
import khcimke from "../../assets/kkh_cimke_300x86.png";
import kh from "../../assets/kh.png";
import cardLogos from "../../assets/mastervisa.jpg";
import vpay from "../../assets/vpay.png";
import jcb from "../../assets/jcb.jpg";
import AnimateHeight from "../AnimateHeight";
import { Profile } from "../../model/Profile";
import { Shop } from "../../model/Shop";
import { Address, City } from "../../model/Address";
import { Product, TranslatableString } from "../../model/Product";
import { Helmet } from "react-helmet/es/Helmet";
import { Form, Select as SelectOrg } from "../about/FormElements";
import CartContent from "./CartContent";
import { ShippingMethods, ShippingPrice } from "../../model/ShippingPrice";
import { SelectedAddress } from "../../pages/BrowsePage/SearchScreen";
import SavedAddressPicker from "../addresspicker/SavedAddressPicker";
import {
  AllPaymentMethod,
  PaymentMethods,
  PaymentMethodSetting,
  PaymentMethodTypes
} from "../../model/PaymentMethodSetting";
import validateEmail from "../../utils/EmailValidator";
import Loader from "react-loader-spinner";
import ToggleButton from "react-toggle-button";
import Config, { ConfigKeys } from "../../utils/Config";
import Toast from "../Toast";
import Language, { Names } from "../../utils/Language";
import OrderDetails from "../profile/OrderDetails";
import { NonprofitOrganization } from "../../model/NonprofitOrganization";
import ScheduleDatePicker from "../addresspicker/ScheduleDatePicker";
import { getOpeningState } from "../../utils/HoursCalc";
import { HourStatuses } from "../../model/Hour";
import type { Order } from "../../model/Order";

const Select = styled(SelectOrg)`
  width: fit-content;
  padding: 0 6px;
  margin: 0 0 0 auto;
  outline: none;
  border: none;
  background-color: var(--blundee_background);
  color: inherit;

  & > option {
    padding: 4px 6px;
    background-color: inherit;
    color: inherit;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: ${(({align}) => align ? align : "space-between")};
  margin: 5px 15px 15px 0;
  flex-shrink: 0;

  a {
    color: var(--blundee_color_normal);
    font-family: var(--blundee_font_bold);
  }
`;

const PayButton = styled.div`
  outline: none;

  max-width: 300px;
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-size: 17pt;

  background-color: #1eb5ea;
  margin: 15px auto 15px auto;
  border-radius: 10px;
  border: 1px solid #1eb5ea;
  user-select: none;
  color: #efefef;

  transition: background-color 200ms ease-in-out, color 200ms ease-in-out;

  &:hover, &:active {
    background-color: #097ca1;
    color: white;
    cursor: pointer;
  }

  @media screen and (max-width: 800px) {
    font-size: 15pt;
    max-width: 40vw;
  }
`;

const Button = styled(ButtonOrg)`
  padding: 10px 10px;
  background-color: var(--blundee_button_bg_color);
  color: var(--blundee_color_normal);
  font-family: var(--blundee_font_bold);
`;

const ProcessingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(2px);
  z-index: 100;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div.attrs({className: "bg-dark"})`
  width: 100%;
  height: 100%;
  z-index: 21;
  position: fixed;
  top: 0;
  left: 0;
  transition: top 800ms ease-in-out, opacity 500ms ease-in-out;

  box-shadow: var(--blundee_popup_shadow);
  background-color: var(--blundee_background);
  color: var(--blundee_color_normal);

  ${({opened}) => !opened && css`
    transition: top 500ms ease-in-out, opacity 1000ms ease-in-out;
    top: -100vh;
    opacity: 0;
    box-shadow: none;
  `};

  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: ${({statusBarSpaceHeight}) => statusBarSpaceHeight ?? 0}px
`;

const OrderDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar-thumb {
    background-color: var(--blundee_scroll_thumb_color);
    border-radius: 100px;
  }

  &::-webkit-scrollbar {
    width: 15px;
    background-color: transparent;
    //border-left: 1px solid rgb(20, 20, 20);
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: center;

    &::-webkit-scrollbar {
      width: 5px;
    }
  }
`;

const OrderDataWrapper = styled.div`
  width: calc(60% - 75px);
  margin: 10px 0 10px 75px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @media screen and (max-width: 800px) {
    width: 100%;
    margin: 10px 0;
    align-items: center;
  }
`;

const OrderSumUpWrapper = styled.div`
  width: calc(40% - 50px);
  margin: 45px 35px 10px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: sticky;
  top: 5px;

  @media screen and (max-width: 800px) {
    width: 100%;
    align-items: center;
    margin: 10px 0 50px 0;
    padding: 0 10px;
  }
`;

const OrderSumUp = styled.div`
  width: 100%;

  background-color: var(--blundee_background);
  border-radius: 12px;
  padding: 15px;
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  & > h2 {
    width: 100%;
    padding-right: 15px;
    font-family: var(--blundee_font_medium);
    font-size: 15pt;
    margin: 0 0 8px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;

const NonprofitDonationPart = styled.div`
  width: calc(100% - 10px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 3px 5px;
  border-radius: 5px;
  background-color: var(--blundee_background);
  padding: 12px 7px;
`;

const NonprofitInnerPart = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 4px 0;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const FixDonationPart = styled.div`
  width: 100%;
  display: flex;
  //flex-direction: row;
  //justify-content: flex-end;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 4px 0;
`;

const DonationDetails = styled.div`
  font-size: 10pt;
  width: 100%;
`;

const FixDonations = styled.div`
  width: 98%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const FixDonation = styled.button`
  width: 80px;
  height: 40px;
  margin: 8px 5px;
  border-radius: 4px;

  box-shadow: var(--blundee_button_shadow);
  color: var(--blundee_color_normal);
  background-color: ${({selected}) => selected ? "rgb(40, 40, 40)" : "rgb(73, 73, 73)"};
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 10pt;
  outline: none;
  transition: background-color 150ms ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: rgb(40, 40, 40);
  }

  &:focus {
    outline: none;
  }
`;

const OrderProductTotal = styled.div`
  width: calc(100% - 10px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 3px 5px;
  border-radius: 5px;

  background-color: var(--blundee_background_card_3);
  padding: 12px 7px;

  ${({total}) => total === true && css`
    font-weight: bold;

    & > div:nth-of-type(1) {
      font-size: 16pt;
      padding-left: 15px;
    }

    & > div:nth-of-type(2) {
      font-size: 18pt;
    }
  `};
`;

const ScheduleDateWrapper = styled(OrderProductTotal)`
  flex-wrap: nowrap;

  & > div:nth-of-type(2) {
    width: fit-content;
  }
`;

const PaymentMethodWrapper = styled(OrderProductTotal)`
  flex-wrap: wrap;

  & > br {
    width: 100%;
    padding: 0;
    margin: 0;
    border: 0;
    height: 1px;
    background-color: transparent;
  }
`;

const DataGroupWrapper = styled.div`
  width: 95%;

  background-color: var(--blundee_background_card_3);
  border-radius: 12px;
  padding: 15px;
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  h3 {
    margin: 0;
  }

  & > h2 {
    width: 100%;
    padding-right: 15px;
    font-family: var(--blundee_font_medium);
    font-size: 15pt;
    margin: 0 0 8px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    ${({clickable}) => clickable && css`
      cursor: pointer;
    `};

    & > svg {
      font-size: 19pt;
      flex-shrink: 0;
      stroke-width: 2px;
      padding: 5px;
      cursor: pointer;

      transition: transform 100ms ease-in-out, color 100ms ease-in-out;

      &:active {
        transform: scale(0.98);
        color: rgb(200, 200, 200);
      }
    }
  }
`;

const FixData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 8px 0;

  & > span {
    display: block;
    width: 100%;

    & > b {
      display: inline-block;
      width: 30%;
      max-width: 200px;
    }

    @media screen and (max-width: 1000px) {
      margin-bottom: 10px;
      word-wrap: break-word;

      & > b {
        display: inline-block;
        width: 100%;
        max-width: unset;
      }
    }
  }
`;

const SmallGreyButton = styled.button`
  user-select: none;
  outline: none;
  border: none;
  align-self: flex-end;

  padding: 6px 9px;
  margin: ${({margin}) => margin ?? "0 30px 20px 0"};
  border-radius: 3px;
  box-shadow: 0 0 2px 1px #404040;
  background-color: rgb(120, 120, 120);
  color: rgb(230, 230, 230);

  font-size: 10pt;
  transition: color 100ms ease-in-out, background-color 100ms ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: rgb(90, 90, 90);
    color: white;
  }

  &:active {
    background-color: rgb(70, 70, 70);
    color: rgb(220, 220, 220);
  }

  @media screen and (min-width: 801px) {
    &:hover {
      //color: white;
      background-color: rgb(80, 80, 80);
    }
  }

  &:active {
    //color: rgb(170, 170, 170);
    background-color: rgb(50, 50, 50);
  }
`;

const Header = styled.div`
  width: 100%;
  padding: 10px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-shrink: 0;
  z-index: 1;
  box-shadow: 0 0 5px 1px black;

  & > span {
    padding: 5px 8px;
    border-radius: 100px;
    font-size: 20pt;
    display: flex;
    flex-direction: row;
    align-items: center;

    &:hover, &:active {
      cursor: pointer;
      background-color: var(--gray);
    }

    & > i {
      margin: 0 5px;
      font-size: 25pt;
    }
  }

  @media screen and (max-width: 600px) {
    & > span {
      font-size: 15pt;

      & > span {
        font-size: 14pt;
      }
    }
  }
`;

const OrderDoneContainer = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 12px;

  & > h1 {
    font-size: 22pt;
    font-family: var(--blundee_font_medium);
    color: var(--primary);
    margin-bottom: 5px;
  }
`;

const AddressSelectorWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

// noinspection JSUnusedLocalSymbols
const RegisterActionWrapper = styled.div`
  width: 100%;
  height: 40px;
  margin-top: auto;
  padding: 10px 15px;
  background-color: rgb(60, 60, 60);
  overflow: hidden;
  transition: height 700ms ease-in-out;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  ${({smallsize}) => smallsize === false && css`
    padding: 5px 15px;
    height: 80px;
  `};

  ${({show}) => show === false && css`
    padding: 0;
    height: 0;
  `};
`;

const MyMapComponent = withGoogleMap((props: PropsWithChildren<{ zoom: number, center: string }>) => (
  <GoogleMap zoom={props.zoom} center={props.center} defaultZoom={props.zoom} defaultCenter={props.center}
             defaultOptions={{gestureHandling: "cooperative"}}
  >
    {props.children}
  </GoogleMap>
));

const MapContainer = styled.div`
  height: 300px;
  width: 60%;
  margin: 10px auto 0 auto;
  border-radius: 10px;
  overflow: hidden;
  transition: height 100ms ease-in-out;

  @media screen and (max-width: 800px) {
    height: 300px;
    width: 100%;
  }
`;

const CreditCards = styled.div`
  text-align: center;
  margin: 15px;
  padding: 10px;
  background-color: white;
  border-radius: 4px;
`;

const TransactionInfoDiv = styled.div`
  width: 70%;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid rgb(80, 80, 80);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  a, a:hover, a:visited, a:focus, a:active {
    text-decoration: none;
  }

  & > div {
    width: 95%;

    & > p {
      width: 100%;

      & > a {
        width: 100%;
        word-wrap: break-word;
        margin: 0 auto;
      }
    }
  }

  img {
    margin: 8px;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
    max-height: 100%;
    border: none;
    overflow: auto;

    padding: 0;
    font-size: 10pt;
    img {
      max-height: 50px;
      max-width: 100%;
    }
  }
`;

const ShippingMethodSwitch = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 5px 0 15px;
`;

export type TransactionInfo = { url: string, txid: string };

export default class OrderFinishing extends Component {
  state: {
    opened: boolean,

    availableShippingPrices: ShippingPrice[],
    loadingAvailableShippingPrices: boolean,
    transactionInfo: TransactionInfo,
    transactionPaymentMethod: {},
    profileID: number,
    orderNumber: string,
    openRegisterFromOrder: boolean,
    cities: [],
    shippingPrices: [],
    profile: Profile,
    partner: Shop,
    orderDone: boolean,
    currentStep: number,

    processing: boolean,
    googleApiLoaded: boolean,

    menuOpened: {},
    orderSumUpOpened: boolean,
    myDataPanelOpened: boolean,
    fulfillmentOpened: boolean,
    editMyData: boolean,
    scheduleDate: Date,

    products: [],
    shippingMethod: number,
    selectedAddress: Address,
    selectedCity: City | undefined,
    editFirstName: string,
    editLastName: string,
    editTel: string,
    editEmail: string,
    editComment: string,
    editSelectedPaymentMethod: number,
    statusBarSpaceHeight: number,
    language: number,
    finalOrder: Order | undefined,
    selectedOrganization: NonprofitOrganization | undefined,
    donationValue: number
  } = {
    opened: false,
    availableShippingPrices: ContextSystem.availableShippingPrices,
    loadingAvailableShippingPrices: ContextSystem.loadingAvailableShippingPrices,
    transactionInfo: undefined,
    transactionPaymentMethod: undefined,
    profileID: undefined,
    orderNumber: undefined,
    openRegisterFromOrder: false,
    cities: [],
    shippingPrices: [],
    profile: undefined,
    partner: null,
    orderDone: false,
    currentStep: 0,

    processing: false,
    googleApiLoaded: ContextSystem.googleAPILoaded,

    menuOpened: {},
    orderSumUpOpened: true,
    myDataPanelOpened: true,
    fulfillmentOpened: !ContextSystem.mobileLayout,
    editMyData: false,
    scheduleDate: ContextSystem.scheduleDate,

    products: [],
    shippingMethod: ContextSystem.pickup ? ShippingMethods.PICKUP : ShippingMethods.DELIVERY,
    selectedAddress: undefined,
    selectedCity: undefined,
    editFirstName: "",
    editLastName: "",
    editTel: "",
    editEmail: "",
    editComment: "",
    editSelectedPaymentMethod: -1,
    statusBarSpaceHeight: ContextSystem.statusBarSpaceHeight,
    language: ContextSystem.language,
    finalOrder: undefined,
    selectedOrganization: undefined,
    donationValue: 0
  };

  // validateTel(tel) {
  //   const phoneNumber = parsePhoneNumberFromString(tel, "HU");
  //   return phoneNumber && phoneNumber.country === "HU" && phoneNumber.isValid() === true;
  // }

  validateName(name) {
    return !(!name || name.length < 1);
  }

  validateAddress() {
    if (this.state.loadingAvailableShippingPrices)
      return false;
    if (this.state.availableShippingPrices === undefined || this.state.availableShippingPrices.length <= 0)
      return false;
    // noinspection RedundantIfStatementJS
    if (!this.state.selectedAddress)
      return false;

    return true;
  }

  urlChanged() {
    if (OrderFinishing.openedFromClick)
      return;

    let path = window.location.pathname;
    let searchParamsText = window.location.search === "" ? "" : window.location.search.substring(1);
    let params = new URLSearchParams(searchParamsText);
    if (params.has("kosar") && params.get("kosar") === "1") {
      if (!!ContextSystem.profile) {
        if (!(path.startsWith("/etterem") || path.startsWith("/e"))) {
          EventSystem.publish(EventSystem.events.redirectIntent, {queryParams: {kosar: undefined}});
          return;
        }
        if (OrderFinishing.lastOrder)
          this.showCart(OrderFinishing.lastOrder, false);
      } else {
        if (this.state.opened === true)
          this.toggleOpened(false);
        EventSystem.publish(EventSystem.events.redirectIntent, {queryParams: {kosar: undefined}});
      }
    } else {
      if (this.state.opened === true)
        this.toggleOpened(false);
    }
  }

  componentDidMount() {
    this.setState({
      selectedAddress: ContextSystem.selectedAddress,
      googleApiLoaded: ContextSystem.googleAPILoaded
    });

    EventSystem.subscribe(EventSystem.events.googlePlacesScriptLoaded, () => this.setState({googleApiLoaded: true}));

    EventSystem.subscribe(EventSystem.events.urlChanged, () => {
      this.urlChanged();
    });

    EventSystem.subscribe(EventSystem.events.openOrderFinishing, orderData => this.showCart(orderData, true));

    EventSystem.subscribe(EventSystem.events.addressSelected, ({address}) => {
      this.setState({selectedAddress: address});
    });

    EventSystem.subscribe(EventSystem.events.contextSystemChanged,
      ({
         loadingAvailableShippingPrices, availableShippingPrices,
         googleAPILoaded, loggedIn, profile, pickup, statusBarSpaceHeight, language,
         orders, scheduleDate
       }) => {
        if (OrderFinishing.initialized === false && ContextSystem.googleAPILoaded && ContextSystem.cartPartner) {
          OrderFinishing.initialized = true;
          if (ContextSystem.cart && ContextSystem.cart.length > 0 && ContextSystem.cartPartner) {
            OrderFinishing.lastOrder = {
              products: ContextSystem.cart,
              shippingMethod: ContextSystem.pickup ? ShippingMethods.PICKUP : ShippingMethods.DELIVERY,
              selectedCity: ContextSystem.selectedCity,
              cities: ContextSystem.cities,
              // shippingPrices: this.state.shippingPrices,
              partner: ContextSystem.cartPartner,
              currentStep: 0
            };
          }
          this.urlChanged();
        }

        if (googleAPILoaded !== undefined) {
          this.setState({googleAPILoaded});
        }
        if (availableShippingPrices !== undefined) {
          this.setState({availableShippingPrices});
        }
        if (loadingAvailableShippingPrices !== undefined) {
          this.setState({loadingAvailableShippingPrices});
        }
        if (loggedIn !== undefined) {
          this.setState({profile: loggedIn === true ? profile : undefined});
        }
        if (pickup !== undefined) {
          this.setState({shippingMethod: pickup ? ShippingMethods.PICKUP : ShippingMethods.DELIVERY});
        }
        if (statusBarSpaceHeight !== undefined) {
          this.setState({statusBarSpaceHeight});
        }
        if (language !== undefined) {
          this.setState({language});
        }
        if (scheduleDate !== undefined) {
          this.setState({scheduleDate});
          if (scheduleDate !== null && this.state.editSelectedPaymentMethod > 0 && !PaymentMethods.isOnline(this.state.editSelectedPaymentMethod) && this.state.partner.preparedOrderOnlyOnlinePayment === true) {
            //toast msg doesn't need to be configurable (silent parameter), since this method is only called on manual click (order finishing called)
            Toast.showToast(Language.getName(Names.AutomaticScheduleOnlyOnlinePaymentNotificationText), 6000);
            this.setState({editSelectedPaymentMethod: -1});
          }
        }
        if (orders !== undefined) {
          if (this.state.finalOrder) {
            let o: Order = ContextSystem.orders.find(o => o.number === this.state.finalOrder.number);
            this.setState({finalOrder: o});
          }
        }
      }
    );

    window.addEventListener("keydown", (e) => {
      if (this.state.opened && e.key === "Escape") {
        this.toggleOpened(true);
      }
    });
  }

  toggleOpened(fromClick: boolean, forceOpen: boolean = false) {
    if (this.state.orderDone) {
      this.setState({orderDone: false});
    }

    if (this.state.opened && this.state.finalOrder && PaymentMethods.isOnline(this.state.finalOrder.paymentMethod)) {
      EventSystem.publish(EventSystem.events.show_confirmation_modal, {
        title: Language.getName(Names.StopOrderText),
        text: Language.getName(Names.OrderNotPayedYet),
        yesText: Language.getName(Names.OrderNotPayedYet_NoText),
        noText: Language.getName(Names.OrderNotPayedYet_YesText),
        cancelCb: () => this.toggleOpenedFunction(fromClick, forceOpen)
      });
      return;
    }
    this.toggleOpenedFunction(fromClick, forceOpen);
  }

  toggleOpenedFunction(fromClick: boolean, forceOpen: boolean = false) {
    let opened = forceOpen ? true : !this.state.opened;
    if (this.state.opened === opened)
      return;

    this.setState({opened});

    if (fromClick) {
      EventSystem.publish(EventSystem.events.redirectIntent, {
        redirectPath: undefined,
        queryParams: {kosar: opened ? "1" : undefined}
      });
    }
  }

  handleBackClicked() {
    EventSystem.publish(EventSystem.events.redirectIntent, {goBack: true, queryParams: {kosar: undefined}});
  }

  static processing = false;

  sendOrder() {
    if (OrderFinishing.processing)
      return;

    OrderFinishing.processing = true;
    this.setState({processing: true});

    let firstName = this.state.editFirstName;
    let lastName = this.state.editLastName;
    let tel = this.state.editTel;
    let email = this.state.editEmail;
    let comment = this.state.editComment;
    let paymentMethod = this.state.editSelectedPaymentMethod;
    if(paymentMethod === PaymentMethodTypes.PAY_LATER)
      paymentMethod = PaymentMethodTypes.CASH;

    for (let product: Product of this.state.products) {
      let isDisabled: boolean = false;

      if (product?.weekSchedule && product.weekSchedule.length > 0) {
        let openStatus = getOpeningState(product.weekSchedule, this.state.scheduleDate);
        isDisabled = openStatus.status !== HourStatuses.OPEN && openStatus.status !== HourStatuses.NO_DATA;
      }

      if (isDisabled) {
        Toast.showToast(Language.getName(Names.ProductNotAvailableNowAtCart), 5000);
        OrderFinishing.processing = false;
        this.setState({processing: false});
        return;
      }
    }

    if (this.state.profile) {
      firstName = this.state.profile.firstName;
      lastName = this.state.profile.lastName;
      tel = this.state.profile.tel;
      email = this.state.profile.email ?? email;
    }

    if (!this.state.partner.payLaterAvailable) {
      if (paymentMethod <= 0 || paymentMethod > PaymentMethods.ONLINE_SZEP_MKB) {
        Toast.showToast(Language.getName(Names.SelectPaymentMode) + "!");
        OrderFinishing.processing = false;
        this.setState({processing: false});
        return;
      }
    }

    if (!this.validateName(firstName) || !this.validateName(lastName)) {
      Toast.showToast(Language.getName(Names.GivenNameIsNotValid));
      OrderFinishing.processing = false;
      this.setState({processing: false});
      return;
    }
    if (!validateEmail(email)) {
      Toast.showToast(Language.getName(Names.GivenEmailIsNotValid));
      OrderFinishing.processing = false;
      this.setState({processing: false});
      return;
    }
    // if (!this.validateTel(tel)) {
    //   Toast.showToast(Language.getName(Names.GivenPhoneNumberIsNotValid));
    //   OrderFinishing.processing = false;
    //   this.setState({ processing: false });
    //   return;
    // }

    if (this.state.shippingMethod === ShippingMethods.DELIVERY) {
      if (!this.validateAddress()) {
        Toast.showToast(Language.getName(Names.GivenAddressIsNotValid));
        OrderFinishing.processing = false;
        this.setState({processing: false});
        return;
      }
    }

    if (this.state.scheduleDate) {
      if (this.state.partner.preparedOrderMaxDays <= 0) {
        Toast.showToast(Language.getName(Names.NoPreOrdersText));
        return;
      }

      let nowPlusOneH = new Date().addMinutes(75);
      let maxPerShop = new Date().addDays(this.state.partner.preparedOrderMaxDays);
      if (this.state.scheduleDate < nowPlusOneH) {
        Toast.showToast(Language.getName(Names.ScheduleDateMin1HourText));
        return;
      } else if (this.state.scheduleDate > maxPerShop) {
        Toast.showToast(Language.getName(Names.ScheduleDateMaxText) + this.state.partner.preparedOrderMaxDays + Language.getName(Names.ScheduleDateMaxText2));
        return;
      }
    }

    let nonprofitOrganizationID = this.state.selectedOrganization?.id ?? -1;

    let order: Order = {
      /*comment, products, partnerID, shippingMethod, paymentMethod*/
      comment,
      products: this.state.products,
      partnerID: this.state.partner ? this.state.partner.id : -1,
      shippingMethod: this.state.shippingMethod,
      paymentMethod,

      email,
      tel,
      firstName,
      lastName,
      nonprofitOrganizationID,
      donationValue: nonprofitOrganizationID === -1 ? 0 : this.state.donationValue,
      scheduleDate: this.state.scheduleDate?.toHunFormatMinutes() ? this.state.scheduleDate?.toHunFormatMinutes() + ":00" : null
    };

    if (this.state.shippingMethod === ShippingMethods.AT_PLACE && ContextSystem.atPlaceTableElement) {
      if (order.tableReservationID > 0 && ContextSystem.atPlaceTableReservation)
        order.tableReservationID = ContextSystem.atPlaceTableReservation.id;
      // noinspection JSUndefinedPropertyAssignment
      order.tableID = ContextSystem.atPlaceTableElement.id;
    }
    if (this.state.shippingMethod === ShippingMethods.DELIVERY) {
      // noinspection JSUndefinedPropertyAssignment
      order.addressID = this.state.selectedAddress.id;
    }

    OrdersAPI.sendOrder(order, (res: SendOrderMessageType) => {
      this.setState({processing: false});
      OrderFinishing.processing = false;

      if (res.error !== 0)
        return;

      let order: Order = res.order;
      OrdersAPI.fixDates(order);

      Toast.showToast(Language.getName(Names.OrderPlacedText));
      ContextSystem.orderPlaced(res.order, res.products);
      CartStorage.clearCart();
      ContextSystem.setScheduleDate(null);

      this.setState({
        processing: false,
        orderDone: true,
        products: [],
        shippingMethod: -1,
        selectedCity: null,
        cities: [],
        shippingPrices: [],
        profile: null,
        firstName: null,
        partner: null,

        transactionInfo: res.transactionInfo,
        transactionPaymentMethod: res.paymentMethod,
        profileID: res.profileID,
        orderNumber: res.orderNumber,
        finalOrder: res.order
      });

      if (res.tableReservation) {
        ContextSystem.setAtPlaceTableReservation(res.tableReservation);
        localStorage.setItem("tableReservation", JSON.stringify(res.tableReservation));
        localStorage.setItem("atPlaceQRID", ContextSystem.atPlaceQRID);
        localStorage.setItem("atPlaceTableElement", JSON.stringify(ContextSystem.atPlaceTableElement));
      }

      localStorage.setItem("order", JSON.stringify(res.order));
      localStorage.setItem("txid", res.txid);
      localStorage.setItem("products", JSON.stringify(res.products));

      EventSystem.publish(EventSystem.events.order_placed, {
        paymentMethod: res.paymentMethod,
        orderNumber: res.orderNumber,
        profileID: res.profileID
      });

      if (order.shippingMethod === ShippingMethods.AT_PLACE && !PaymentMethods.isOnline(res.order.paymentMethod)) {
        this.toggleOpened(true, false);
      }
    });
  }

  removeSelectedAddress() {
    EventSystem.publish(EventSystem.events.addressSelected, {address: null});
  }

  openSelectAddressPopup() {
    EventSystem.publish(EventSystem.events.open_address_picker_popup, {
      address: null,
      cb: (selectedAddress) => {
        AddressAPI.saveAddress(selectedAddress, (res) => {
          EventSystem.publish(EventSystem.events.addressSelected, {address: res.address});
        });
      }
    });
  }

  selectOrganization() {
    EventSystem.publish(EventSystem.events.open_donation_selector, ({
        cb: (n: NonprofitOrganization) => {
          this.setState({selectedOrganization: n});
        }
      }
    ));
  }

  setFixDonation(value: number) {
    this.setState({donationValue: value});
  }

  render() {
    let products = this.state.products;

    let orderProductsTotalPrice = Cart.calcOrderProductsTotalPrice(products);
    let shippingPrice = Cart.calcOrderShippingPrice(this.state.availableShippingPrices, orderProductsTotalPrice);
    let total = orderProductsTotalPrice;
    if (this.state.shippingMethod === ShippingMethods.DELIVERY)
      total += shippingPrice;
    if (this.state.selectedOrganization !== undefined)
      total += this.state.donationValue;

    if (this.state.transactionInfo) {
      window.localStorage.setItem("orderNumber", this.state.orderNumber);
      window.localStorage.setItem("txid", this.state.transactionInfo.txid);
    }

    let maxSteps: number = 3;
    if (this.state.shippingMethod === ShippingMethods.AT_PLACE)
      maxSteps = 2;

    return (
      <Wrapper statusBarSpaceHeight={this.state.statusBarSpaceHeight} opened={this.state.opened}>
        {this.state.processing &&
          <ProcessingWrapper>
            <Loader visible={this.state.processing} type="ThreeDots" color="var(--blundee_yellow)" height={35} width={150}/>
          </ProcessingWrapper>
        }
        {this.state.opened &&
          <>
            <Helmet>
              <title>{Language.getName(Names.OrderFinishingPageTitle)}</title>
              <meta name="description" content={Language.getName(Names.OrderFinishingPageTitle)}/>
            </Helmet>
            <Header>
            <span onClick={() => this.handleBackClicked()}>
              <BsChevronLeft strokeWidth={"2px"}/>
            </span>
              <span>{Language.getName(Names.Order)}</span>
              <span onClick={() => this.handleBackClicked()}>
            <FaTimes strokeWidth={"2px"}/>
          </span>
            </Header>
            {this.state.orderDone === true && (
              <OrderDoneContainer>
                <h1>{Language.getName(Names.OrderPlaced)}</h1>
                {this.state.transactionInfo && (
                  <TransactionInfoDiv>
                    <CreditCards>
                      <img src={khcimke} alt={"KH cimke"}/>
                      <img height={60} src={kh} alt={"K&H Bank"}/>
                      <img height={60} src={cardLogos} alt={"Bankkártyák"}/>
                      <img height={60} src={vpay} alt={"vPay"}/>
                      <img height={60} src={jcb} alt={"JCB"}/>
                    </CreditCards>
                    <div>
                      <p>
                        {Language.getName(Names.OnlinePaymentButtonBelowText)}
                        <br/>
                        <a href={this.state.transactionInfo.url}>
                          <PayButton>{Language.getName(Names.PayButton)}</PayButton>
                        </a>
                      </p>
                      {Config.TEST &&
                        <>
                          <p>
                            A bankkártyás fizetés révén kényelmesen és biztonságosan vásárolhatsz áruházunkban. A
                            fizetés
                            lebonyolításához a K&H Bank
                            weblapjára irányítunk át, ahol a Bank által használt, jelenleg legbiztonságosabbnak ítélt,
                            titkosítással lebonyolított tranzakción
                            keresztül fizethetsz bankkártyáddal, ahol a rendszernek továbbá megadhatod a kártya számát,
                            lejárati
                            dátumát és háromjegyű
                            biztonsági kódját megadják. A K&H Bank a VISA, VISA Electron, V-Pay, MasterCard, Maestro és
                            JCB
                            típusú kártyákat fogadja el.
                          </p>
                          <p>
                            A kizárólag elektronikus használatra kibocsátott bankkártyákat csak abban az esetben
                            fogadhatjuk
                            el,
                            amennyiben annak használatát
                            a kártyát kibocsátó bank engedélyezi! Kérjük, érdeklődj bankodnál, hogy az kártyád
                            felhasználható-e
                            interneten keresztül
                            lebonyolítandó vásárláshoz. A K&H Bank a sikeres vásárlást követően a tranzakcióról
                            engedélyezési
                            számot bocsát ki, amelyet
                            érdemes felírni vagy az egész oldalt kinyomtatni. Sikertelen tranzakció esetén a K&H Bank
                            hibaüzenetben közli a hiba okát.
                          </p>
                        </>
                      }
                    </div>
                  </TransactionInfoDiv>
                )}
                {!this.state.transactionInfo && (
                  <>
                    <OrderDetails tableReservationUI={false} order={this.state.finalOrder} openedDefault={true}
                                  shop={this.state.partner}
                    />
                    <div>
                      {Language.getName(Names.AfterOrderText1)}
                      <a
                        href={"tel:" + Config.getString(ConfigKeys.customer_service_phone)?.replaceAll(" ", "")}>
                        {Config.getString(ConfigKeys.customer_service_phone)}
                      </a>
                      {Language.getName(Names.AfterOrderText2)}
                    </div>
                  </>
                )}
              </OrderDoneContainer>
            )}
            {this.state.orderDone === false && (
              <OrderDetailsWrapper>
                <OrderDataWrapper show={this.state.currentStep === 0}>
                  <DataGroupWrapper clickable>
                    <h2 onClick={() => this.setState({orderSumUpOpened: !this.state.orderSumUpOpened})}>
                      1/{maxSteps} {Language.getName(Names.MyOrder)} - {orderProductsTotalPrice} Ft
                      {!this.state.orderSumUpOpened && <BsCaretDownFill/>}
                      {this.state.orderSumUpOpened && <BsCaretUpFill/>}
                    </h2>
                    <AnimateHeight opened={this.state.orderSumUpOpened}>
                      <CartContent products={products} showShippingPrices={false}/>
                    </AnimateHeight>
                  </DataGroupWrapper>
                  <DataGroupWrapper clickable>
                    <h2 onClick={() => this.setState({myDataPanelOpened: !this.state.myDataPanelOpened})}>
                      2/{maxSteps} {Language.getName(Names.MyInfo)}
                      {!this.state.myDataPanelOpened && <BsCaretDownFill/>}
                      {this.state.myDataPanelOpened && <BsCaretUpFill/>}
                    </h2>
                    <AnimateHeight opened={this.state.myDataPanelOpened}>
                      {this.state.profile &&
                        <FixData>
                      <span>
                       <b>{Language.getName(Names.EmailAddress)}:</b>{" " + this.state.profile.email}<br/>
                      </span>
                          {!this.state.editMyData &&
                            <>
                        <span>
                         <b>{Language.getName(Names.Name)}:</b>{" " + ((this.state.profile.lastName && this.state.profile.firstName) ? this.state.profile.lastName + " " + this.state.profile.firstName : Language.getName(Names.NotGiven))}<br/>
                        </span>
                              <span>
                         <b>{Language.getName(Names.PhoneNumber)}:</b>{" " + (this.state.profile.tel?.length > 0 ? this.state.profile.tel : Language.getName(Names.NotGiven))}<br/>
                        </span>
                              <SmallGreyButton onClick={() => this.handleEditMyDataClicked()}>
                                {Language.getName(Names.Modify)}
                              </SmallGreyButton>
                            </>
                          }
                          {this.state.editMyData &&
                            <>
                              <Form id={"orderFinishing" + this.uniqueID} onSubmit={(e: Event) => e.preventDefault()}
                                    method="post"
                                    encType="multipart/form-data"
                                    width={"100%"} direction={"row"} justify={"space-between"}
                                    wrap={"wrap"}
                              >
                                <Input
                                  width={"49%"}
                                  type="text"
                                  id={"lastName"}
                                  name="lastName"
                                  value={this.state.profile.lastName}
                                  autoComplete="family-name"
                                  onChange={(e: ChangeEvent) => this.setState({
                                    profile: {
                                      ...this.state.profile,
                                      lastName: e.target.value
                                    }
                                  })}
                                  placeholder={Language.getName(Names.LastName)}
                                />
                                <Input
                                  width={"49%"}
                                  type="text"
                                  id={"firstName"}
                                  name="firstName"
                                  value={this.state.profile.firstName}
                                  autoComplete="given-name"
                                  onChange={(e: ChangeEvent) => this.setState({
                                    profile: {
                                      ...this.state.profile,
                                      firstName: e.target.value
                                    }
                                  })}
                                  placeholder={Language.getName(Names.FirstName)}
                                />
                                <Input
                                  width={"100%"}
                                  type="text"
                                  id={"tel"}
                                  name="tel"
                                  value={this.state.profile.tel}
                                  autoComplete="tel"
                                  onChange={(e: ChangeEvent) => this.setState({
                                    profile: {
                                      ...this.state.profile,
                                      tel: e.target.value
                                    }
                                  })}
                                  placeholder={Language.getName(Names.PhoneNumber)}
                                />
                              </Form>
                              <SmallGreyButton
                                onClick={() => this.handleStopEditingMyDataClicked()}>{Language.getName(Names.Save)}
                              </SmallGreyButton>
                            </>
                          }
                        </FixData>
                      }
                      {!this.state.profile &&
                        <FixData>
                          <Form id={"orderFinishing" + this.uniqueID} onSubmit={(e: Event) => e.preventDefault()}
                                method="post"
                                encType="multipart/form-data"
                                width={"100%"} direction={"row"} justify={"space-between"}
                                wrap={"wrap"}
                          >
                            <Input
                              width={"49%"}
                              type="text"
                              id={"lastName"}
                              name="lastName"
                              value={this.state.editLastName}
                              autoComplete="family-name"
                              onChange={(e: ChangeEvent) => this.setState({editLastName: e.target.value})}
                              placeholder={Language.getName(Names.LastName)}
                            />
                            <Input
                              width={"49%"}
                              type="text"
                              id={"firstName"}
                              name="firstName"
                              value={this.state.editFirstName}
                              autoComplete="given-name"
                              onChange={(e: ChangeEvent) => this.setState({editFirstName: e.target.value})}
                              placeholder={Language.getName(Names.FirstName)}
                            />
                            <Input
                              width={"100%"}
                              type="text"
                              id={"email"}
                              name="email"
                              value={this.state.editEmail}
                              autoComplete="email"
                              onChange={(e: ChangeEvent) => this.setState({editEmail: e.target.value})}
                              placeholder={Language.getName(Names.EmailAddress)}
                            />
                            {/*<Input*/}
                            {/*  width={"100%"}*/}
                            {/*  type="text"*/}
                            {/*  id={"tel"}*/}
                            {/*  name="tel"*/}
                            {/*  value={this.state.editTel}*/}
                            {/*  autoComplete="tel"*/}
                            {/*  onChange={(e: ChangeEvent) => this.setState({ editTel: e.target.value })}*/}
                            {/*  placeholder={Language.getName(Names.PhoneNumber)}*/}
                            {/*/>*/}
                          </Form>
                          <SmallGreyButton
                            onClick={() => this.handleStopEditingFixDataClicked()}>{Language.getName(Names.Save)}
                          </SmallGreyButton>
                        </FixData>
                      }
                    </AnimateHeight>
                  </DataGroupWrapper>
                  {this.state.shippingMethod !== ShippingMethods.AT_PLACE &&
                    <DataGroupWrapper clickable>
                      <h2 onClick={() => this.setState({fulfillmentOpened: !this.state.fulfillmentOpened})}>
                        3/{maxSteps} {Language.getName(Names.Shipping)}
                        {this.state.shippingMethod === ShippingMethods.DELIVERY &&
                          <>
                            {!this.state.fulfillmentOpened && <BsCaretDownFill/>}
                            {this.state.fulfillmentOpened && <BsCaretUpFill/>}
                          </>
                        }
                      </h2>
                      {this.state.shippingMethod === ShippingMethods.PICKUP &&
                        <div>
                          <h3>{Language.getName(Names.TakeawayAtShop)}</h3>
                        </div>
                      }
                      {this.state.shippingMethod === ShippingMethods.DELIVERY &&
                        <>
                          {!this.state.selectedAddress && !this.state.selectedCity && (
                            <>
                              <SelectedAddress italic={true}>
                                <GoLocation/>
                                <span>{Language.getName(Names.NoAddressSelected)}</span>
                                <button
                                  onClick={() => this.openSelectAddressPopup()}>{Language.getName(Names.EnterMyAddress)}</button>
                              </SelectedAddress>
                              <SavedAddressPicker fontsize={"s"} modifyOnClick={false} showModifyIcons={false}/>
                            </>
                          )}
                          {this.state.selectedAddress && (
                            <>
                              <SelectedAddress>
                                <GoLocation/>
                                <span>
                            {
                              this.state.selectedAddress.street +
                              ", " +
                              TranslatableString.get(ContextSystem.getCityByID(this.state.selectedAddress.cityID)?.name) +
                              " " +
                              ContextSystem.getZipCode(this.state.selectedAddress.zipCodeID)?.zipcode
                            }
                            </span>
                              </SelectedAddress>
                            </>
                          )}
                          {this.state.selectedAddress?.comment && this.state.selectedAddress?.comment.length > 0 &&
                            <div>
                              <b>{Language.getName(Names.Comment)}:</b> {this.state.selectedAddress?.comment}
                            </div>
                          }
                        </>
                      }
                      {this.state.partner?.enablePersonalOrder &&
                        <ShippingMethodSwitch>
                          <ToggleButton
                            value={this.state.shippingMethod === ShippingMethods.PICKUP}
                            inactiveLabel={<ImCross color={"#ffef42"}/>}
                            activeLabel={<GoCheck strokeWidth={2} color={"#444440"}/>}
                            colors={{
                              activeThumb: {
                                base: "#444440",
                                color: "#444440"
                              },
                              inactiveThumb: {
                                base: "#444440",
                                color: "#444440"
                              },
                              active: {
                                base: "#ffef42",
                                color: "#444440"
                                //hover: "rgb(0, 255, 0)",
                              },
                              inactive: {
                                base: "rgb(120, 120, 120)",
                                color: "#444440"
                                //hover: "rgb(0, 0, 255)",
                              }
                            }}
                            onToggle={(value: boolean) => ContextSystem.setPickup(!value)}
                          />
                          <span style={{marginLeft: "20px"}}>{Language.getName(Names.ForTakeaway)}</span>
                        </ShippingMethodSwitch>
                      }
                      {this.state.shippingMethod === ShippingMethods.PICKUP &&
                        <div>
                          {this.state.partner.address.street &&
                            <h3>{Language.getName(Names.Address)}: {this.state.partner.address.street + ","} {ContextSystem.getZipCode(this.state.partner.address.zipCodeID)?.zipcode} {TranslatableString.get(ContextSystem.getCityByID(this.state.partner.address.cityID)?.name)}</h3>
                          }
                          {!this.state.partner.address.street &&
                            <h3>{Language.getName(Names.Address)}: {this.state.partner.address + ", " + this.state.partner.zip + " " + this.state.partner.city}</h3>
                          }
                          <br/>
                        </div>
                      }
                      {this.state.shippingMethod === ShippingMethods.DELIVERY &&
                        <AnimateHeight opened={this.state.fulfillmentOpened}>
                          {this.state.shippingMethod === ShippingMethods.DELIVERY && this.state.selectedAddress && (
                            <>
                              <SelectedAddress>
                                <button
                                  onClick={() => this.openSelectAddressPopup()}>{Language.getName(Names.Modify)}</button>
                              </SelectedAddress>
                            </>
                          )}
                          {this.state.shippingMethod === ShippingMethods.DELIVERY &&
                            <AddressSelectorWrapper>
                              {this.state.googleApiLoaded && (
                                <MyMapComponent
                                  mapElement={<div style={{height: `100%`}}/>}
                                  loadingElement={<div style={{height: `100%`}}/>}
                                  containerElement={<MapContainer/>}
                                  zoom={this.state.selectedAddress ? 14 : 10}
                                  center={
                                    this.state.selectedAddress
                                      ? {
                                        lat: this.state.selectedAddress?.coordinates?.latitude,
                                        lng: this.state.selectedAddress?.coordinates?.longitude
                                      }
                                      : googleMapsCenterBP
                                  }
                                >
                                  {this.state.selectedAddress && (
                                    <Marker
                                      position={{
                                        lat: this.state.selectedAddress.coordinates.latitude,
                                        lng: this.state.selectedAddress.coordinates.longitude
                                      }}
                                    />
                                  )}
                                </MyMapComponent>
                              )}
                            </AddressSelectorWrapper>
                          }
                        </AnimateHeight>
                      }
                    </DataGroupWrapper>
                  }
                </OrderDataWrapper>
                <OrderSumUpWrapper show={this.state.currentStep === 1}>
                  <OrderSumUp>
                    <h2>{Language.getName(Names.SumUpOrder)}</h2>
                    <OrderProductTotal>
                      <div>{Language.getName(Names.ProductsTotal)}</div>
                      <div>{orderProductsTotalPrice} Ft</div>
                    </OrderProductTotal>
                    <OrderProductTotal>
                      {this.state.shippingMethod === ShippingMethods.PICKUP &&
                        <>
                          <div>{Language.getName(Names.ShippingMode)}:</div>
                          <div>{Language.getName(Names.TakeawayAtShop)}</div>
                        </>
                      }
                      {this.state.shippingMethod === ShippingMethods.AT_PLACE &&
                        <>
                          <div>{Language.getName(Names.TableName)}:</div>
                          <div>"{ContextSystem.atPlaceTableElement?.name}"</div>
                        </>
                      }
                      {this.state.shippingMethod === ShippingMethods.DELIVERY &&
                        <>
                          <div>{Language.getName(Names.ShippingPrice)}:</div>
                          <div>{shippingPrice} Ft</div>
                        </>
                      }
                    </OrderProductTotal>
                    {(this.state.scheduleDate || (this.state?.partner?.preparedOrderMaxDays ?? 0) > 0) &&
                      <ScheduleDateWrapper>
                        <div>{Language.getName(Names.ScheduleOrder)}:</div>
                        <ScheduleDatePicker/>
                      </ScheduleDateWrapper>
                    }
                    {!this.state.partner.payLaterAvailable &&
                      <PaymentMethodWrapper>
                        <div>{Language.getName(Names.PaymentMode)}</div>
                        <Select id={"paymentMethod" + this.uniqueID}
                                name="paymentMethod"
                                value={"" + this.state.editSelectedPaymentMethod}
                                onChange={(e) => this.setState({editSelectedPaymentMethod: parseInt(e.target.value)})}
                        >
                          <option value="-1" disabled defaultValue>{Language.getName(Names.SelectPaymentMode)}:</option>
                          {this.state.shippingMethod === ShippingMethods.AT_PLACE && this.state.partner.payLaterAvailable &&
                            <option value={PaymentMethodTypes.PAY_LATER} >{Language.getName(Names.PayLater)}:</option>
                          }
                          {AllPaymentMethod.map((ps, i) => {
                            if (!ps.availableShippingMethods.includes(this.state.shippingMethod))
                              return <React.Fragment key={i}/>;

                          if (!this.state.partner || !this.state.partner.paymentMethods || this.state.partner.paymentMethods.length <= 0)
                            return <React.Fragment key={i}/>;

                          let psm: PaymentMethodSetting = this.state.partner.paymentMethods.find(pm => pm.paymentID === ps.type);

                          if (!psm || !psm.enabled || !psm.active)
                            return <React.Fragment key={i}/>;

                          if (this.state.partner.preparedOrderOnlyOnlinePayment === true && this.state.scheduleDate !== null && !PaymentMethods.isOnline(psm.paymentID))
                            return <React.Fragment key={i}/>;

                            return (
                              <option key={i} value={psm.paymentID}>{PaymentMethods.getName(psm.paymentID)}</option>
                            );
                          })}
                        </Select>
                        <br/>
                      </PaymentMethodWrapper>
                    }
                    {Config.getBoolean(ConfigKeys.enable_nonprofit_donation_on_order) && (this.state.shippingMethod === ShippingMethods.PICKUP || this.state.shippingMethod === ShippingMethods.DELIVERY) &&
                      <NonprofitDonationPart>
                        <NonprofitInnerPart>
                          <div>
                            {Language.getName(Names.DonationText)}<br/>
                            <DonationDetails>{Language.getName(Names.DonationDetails)}</DonationDetails>
                          </div>
                        </NonprofitInnerPart>
                        <FixDonationPart>
                          {this.state.selectedOrganization &&
                            <>{this.state.selectedOrganization.name}</>
                          }
                          <SmallGreyButton margin={"0 0 0 10px"} onClick={() => this.selectOrganization()}>
                            {Language.getName(Names.SelectDonationButton)}<br/>
                          </SmallGreyButton>
                          {this.state.selectedOrganization && PaymentMethods.isOnline(this.state.editSelectedPaymentMethod) &&
                            <>
                              <DonationDetails>
                                {Language.getName(Names.MoreDonationText)}<br/>
                              </DonationDetails>
                              <FixDonations>
                                <FixDonation
                                  selected={this.state.selectedOrganization === undefined || this.state.donationValue === 0}
                                  onClick={() => this.setFixDonation(0)}>
                                  {Language.getName(Names.NoMoreDonationButton)}
                                </FixDonation>
                                <FixDonation
                                  selected={this.state.selectedOrganization !== undefined && this.state.donationValue === 100}
                                  onClick={() => this.setFixDonation(100)}>
                                  100Ft
                                </FixDonation>
                                <FixDonation
                                  selected={this.state.selectedOrganization !== undefined && this.state.donationValue === 250}
                                  onClick={() => this.setFixDonation(250)}>
                                  250Ft
                                </FixDonation>
                                <FixDonation
                                  selected={this.state.selectedOrganization !== undefined && this.state.donationValue === 500}
                                  onClick={() => this.setFixDonation(500)}>
                                  500Ft
                                </FixDonation>
                                <FixDonation
                                  selected={this.state.selectedOrganization !== undefined && this.state.donationValue === 1000}
                                  onClick={() => this.setFixDonation(1000)}>
                                  1000Ft
                                </FixDonation>
                                <FixDonation
                                  selected={this.state.selectedOrganization !== undefined && this.state.donationValue === 1500}
                                  onClick={() => this.setFixDonation(1500)}>
                                  1500Ft
                                </FixDonation>
                              </FixDonations>
                            </>
                          }
                        </FixDonationPart>
                      </NonprofitDonationPart>
                    }
                    <OrderProductTotal total={true}>
                      <div>{Language.getName(Names.GrandTotal)}</div>
                      <div>{total} Ft</div>
                    </OrderProductTotal>
                    <ButtonWrapper align={ContextSystem.mobileLayout ? "center" : "flex-end"}>
                      <Button valid={true} border={"none"} validcolor={"#ffef42"}
                              onClick={() => this.sendOrder()}
                      >
                        {Language.getName(Names.OrderButtonText)}
                      </Button>
                    </ButtonWrapper>
                    <ButtonWrapper align={"flex-end"}>
                        <span>
                          {Language.getName(Names.OrderASZF)}
                        </span>
                    </ButtonWrapper>
                  </OrderSumUp>
                </OrderSumUpWrapper>
              </OrderDetailsWrapper>
            )}
          </>
        }
      </Wrapper>
    );
  }

  static initialized: boolean = false;
  static lastOrder: {
    products: Product[],
    shippingMethod: number,
    selectedCity: City,
    cities: City[],
    // shippingPrices: this.state.shippingPrices,
    partner: Shop
  };

  static openedFromClick: boolean = false;

  showCart(orderData, fromClick: boolean) {
    if (fromClick) {
      OrderFinishing.openedFromClick = true;
      OrderFinishing.lastOrder = orderData;
      setTimeout(() => OrderFinishing.openedFromClick = false, 500);
    }
    this.setState({
      orderSumUpOpened: true,
      myDataPanelOpened: true,
      fulfillmentOpened: !ContextSystem.mobileLayout
    });
    if (ContextSystem.mobileLayout) {
      setTimeout(() => {
        this.setState({
          orderSumUpOpened: false
        });
      }, 3000);
    }
    let menuOpened = {};
    for (let index in orderData.products) {
      // noinspection JSUnfilteredForInLoop
      menuOpened[index] = true;
    }

    this.setState({
      products: orderData.products,
      shippingMethod: orderData.shippingMethod,
      cities: orderData.cities,
      // shippingPrices: orderData.shippingPrices,
      lastQuestionNumbers: [-1],
      questionNumber: 0,
      partner: orderData.partner,
      menuOpened
    });
    this.toggleOpened(fromClick, true);
  }

  handleEditMyDataClicked() {
    this.setState({editMyData: true});
  }

  handleStopEditingMyDataClicked() {
    this.setState({editMyData: false});
  }

  handleStopEditingFixDataClicked() {
    let firstName = this.state.editFirstName;
    let lastName = this.state.editLastName;
    // let tel = this.state.editTel;
    let email = this.state.editEmail;
    this.setState({
      editFirstName: firstName,
      editLastName: lastName,
      // editTel: tel,
      editEmail: email
    });

    if (!this.validateName(firstName) || !this.validateName(lastName)) {
      Toast.showToast(Language.getName(Names.GivenNameIsNotValid));
      return;
    }
    if (!validateEmail(email)) {
      Toast.showToast(Language.getName(Names.GivenEmailIsNotValid));
      return;
    }
    // if (!this.validateTel(tel)) {
    //   Toast.showToast(Language.getName(Names.GivenPhoneNumberIsNotValid));
    //   return;
    // }

    this.setState({myDataPanelOpened: false});
  }
}

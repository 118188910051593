import React, { Component } from "react";
import EventSystem from "../../utils/EventSystem";
import { Input, OuterWrapper, Panel, TextArea } from "./ProfileDetailsPopup";
import Language, { Names } from "../../utils/Language";
import ContextSystem from "../../utils/ContextSystem";
import { Profile } from "../../model/Profile";
import styled from "styled-components";
import { FaLocationArrow } from "react-icons/fa";
import { HourStatuses, HourTypes } from "../../model/Hour";
import { BsFillDoorClosedFill, BsFillDoorOpenFill } from "react-icons/bs";
import * as HoursCalc from "../../utils/HoursCalc";
import { addLeadingZero, checkOpeningState, getShortDayName, OpenStatus } from "../../utils/HoursCalc";
import { MdDeliveryDining } from "react-icons/md";
import { GiPaperBagOpen } from "react-icons/gi";
import ReservationPage, {
  ShopAddress as ShopAddressOrg,
  ShopOccupancy as ShopOccupancyOrg,
  ShopOpeningInfo as ShopOpeningInfoOrg,
  StartReservationButton
} from "../../pages/ReservationPage";
import type { DataSetRecord } from "../addresspicker/ScheduleDatePicker";
import { Select as Select2 } from "../addresspicker/ScheduleDatePicker";
import Toast from "../Toast";
import { ButtonWrapper } from "../cart/OrderFinishing";
// noinspection NpmUsedModulesInstalled
import { Swiper, SwiperSlide } from "swiper/react";
import { Slide, SwiperWrapper, SwipeSlideWrapper as SwipeSlideWrapperOrg } from "../../pages/StartPage";
import { OrdersAPI } from "../../utils/api/OrdersAPI";
import { TranslatableString } from "../../model/Product";

const ShopReviews = styled.div`
  width: 100%;
  min-height: 300px;
`;

const SwipeSlideWrapper = styled(SwipeSlideWrapperOrg)`
  padding: 0 0 12px 0;
`;

const ShopOccupancy = styled(ShopOccupancyOrg)`
  width: 49%;
`;

const ShopAddress = styled(ShopAddressOrg)`
  width: 100%;
`;

const ShopOpeningInfo = styled(ShopOpeningInfoOrg)`
  width: ${({ width }) => width ?? "49%"};
`;

const Button = styled(StartReservationButton)`
  margin-top: 24px;
`;

const Select = styled(Select2)`
  color: var(--blundee_turkiz);
  font-size: 13pt;
  font-weight: bold;

  & > option:focus, & > option:active, & > option:checked {
    background-color: var(--blundee_input_bg_3) !important;
    background: var(--blundee_input_bg_3) linear-gradient(0deg, var(--blundee_input_bg_3) 0%, var(--blundee_input_bg_3) 100%) !important;
    transition: background-color 200ms ease-in-out !important;
  }
`;

const ShopDetails = styled.div`
  width: 100%;
  position: relative;
  margin: 12px 0 24px 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
  flex-wrap: wrap;
`;

const DatePickerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export default class ReservationPopup extends Component {
  formID1 = ContextSystem.getNextUniqueID();
  booking: boolean = false;

  state: {
    opened: boolean,
    shop: Shop,
    time: Date,
    ppl: number,
    stay: number,
    profile: Profile,
    occupancyRate: number,
    freeTables: number,
    hoursDataSet: DataSetRecord[],
    activeSlide: number,
    editComment: string,
  } = {
    opened: false,
    shop: undefined,
    time: undefined,
    ppl: -1,
    stay: -1,
    profile: ContextSystem.profile,
    occupancyRate: 0,
    freeTable: 0,
    hoursDataSet: [],
    activeSlide: 0,
    editComment: ""
  };

  buildHourDataSet() {
    let hoursDataSet: DataSetRecord[] = [];

    let date: Date = new Date();
    let dateUntil: Date = new Date();
    let now: Date = new Date();

    let checkHourType = HourTypes.OPENING;

    for (let i = 0; i <= 15; i++) {
      let minutes: Date[] = [];

      for (let h = 0; h < 24; h++) {
        for (let m = 0; m < 60; m += 30) {
          date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), h, m, 0, 0);
          dateUntil = new Date(date.getFullYear(), date.getMonth(), date.getDate(), h, m, 0, 0).addMinutes(this.state.stay);

          if (date < new Date().addMinutes(30)) {
            continue;
          }

          let status: OpenStatus = checkOpeningState(checkHourType, this.state.shop, date);
          let statusUntil: OpenStatus = checkOpeningState(checkHourType, this.state.shop, dateUntil);
          if (status && status.status === HourStatuses.OPEN && statusUntil && statusUntil.status === HourStatuses.OPEN) {
            minutes.push(new Date(date.getTime()));
          }
        }
      }
      if (minutes && minutes.length > 0) {
        hoursDataSet.push({
          label:
            addLeadingZero(date.getMonth() + 1) + "." + addLeadingZero(date.getUTCDate()) + " "
            + "(" + getShortDayName(date.getDay() === 0 ? 7 : date.getDay()) + ")",
          value: new Date(date.getTime()),
          minutes,
          id: i
        });
      }
      date.addDays(1);
    }

    this.setState({ hoursDataSet });
  }

  close() {
    this.setState({ opened: false });
  }

  handleKeyDownEvent(e) {
    if (this.state.opened && e.key === "Escape") {
      this.close();
    }
  }

  handleDayChange(e) {
    let recId: number = parseInt(e.target.value);
    let rec: DataSetRecord = this.state.hoursDataSet.find(d => d.id === recId);

    let min: Date = rec.minutes.find(m => m.getHours() === this.state.time.getHours() && m.getMinutes() === this.state.time.getMinutes());
    if (!min)
      min = rec.minutes[0];

    this.handleChange(min);
  }

  handleChange(time: Date) {
    if (!time)
      return;

    let now = new Date();
    let nowPlus = new Date().addMinutes(30);
    if (time <= nowPlus) {
      Toast.showToast(Language.getName(Names.TableForMin1HourText), 6000);
      return;
    }
    this.setState({ time });
  }

  componentDidMount() {
    EventSystem.subscribe(EventSystem.events.open_reservation_popup, (data) => {
      let details: { shop: Shop, time: Date, ppl: number, stay: number, freeTable: number, occupancyRate: number } = data;
      this.setState({
        activeSlide: 0,
        opened: true,
        ...details,
        editComment: ""
      }, () => this.buildHourDataSet());
    });

    EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ profile }) => {
      if (profile !== undefined) {
        this.setState({ profile });
      }
    });

    document.addEventListener("keydown", (e) => this.handleKeyDownEvent(e));
    document.addEventListener("resize", () => this.updateBgColor());
  }

  book() {
    if (this.booking === true)
      return;

    this.booking = true;
    OrdersAPI.bookTable(this.state.shop.id, this.state.time, this.state.ppl, this.state.stay, this.state.editComment, res => {
      this.booking = false;

      if (res.error !== 0)
        return;

      Toast.showToast(Language.getName(Names.BookCompleted));

      this.setState({
        opened: false,
        shop: undefined,
        time: undefined,
        ppl: -1,
        stay: -1,
        profile: ContextSystem.profile,
        occupancyRate: 0,
        freeTable: 0,
        hoursDataSet: [],
        activeSlide: 0,
        editComment: ""
      });
    });
  }

  render() {
    try {
      let selectedDay: DataSetRecord = undefined;
      if (this.state.time) {
        selectedDay = this.state.hoursDataSet.find(rec => rec.value.getDate() === this.state.time.getDate());
      }

      let dayOfWeek: number = new Date().getDay();
      if (dayOfWeek === 0)
        dayOfWeek = 7;

      let shopOpenStatus: OpenStatus = HoursCalc.checkOpeningState(HourTypes.OPENING, this.state.shop);
      let AcceptingPickUpOrdersStatus: OpenStatus = HoursCalc.checkOpeningState(HourTypes.ORDER_ACCEPT_FOR_PICKUP, this.state.shop);
      let DeliveringStatus: OpenStatus = HoursCalc.checkOpeningState(HourTypes.DELIVERY, this.state.shop);
      let AcceptingOrdersStatus: OpenStatus = HoursCalc.checkOpeningState(HourTypes.ORDER_ACCEPT, this.state.shop);

      let openingHoursText = HoursCalc.getOpeningHoursText(shopOpenStatus, AcceptingPickUpOrdersStatus, DeliveringStatus, AcceptingOrdersStatus);

      let openingStatus: { closed: boolean, preOrder: boolean } = { closed: false, preOrder: false };

      if (openingHoursText.openStringDL === HourStatuses.NO_DATA) {
        openingStatus.closed = true;
      } else if (openingHoursText.openStringDL === HourStatuses.LOADING) {
        openingStatus.closed = false;
        openingStatus.preOrder = false;
      } else if (openingHoursText.openStringDL.includes(Language.getName(Names.Closed))) {
        openingStatus.closed = true;
      } else if (openingHoursText.openStringDL.includes(Language.getName(Names.ForPreOrder))) {
        openingStatus.preOrder = true;
      }

      if (openingHoursText.openStringDL === HourStatuses.NO_DATA)
        openingHoursText.openStringDL = Language.getName(Names.Closed);
      if (openingHoursText.openStringPK === HourStatuses.NO_DATA)
        openingHoursText.openStringPK = Language.getName(Names.Closed);
      if (openingHoursText.openStringDL === HourStatuses.LOADING)
        openingHoursText.openStringDL = "";
      if (openingHoursText.openStringPK === HourStatuses.LOADING)
        openingHoursText.openStringPK = "";
      // noinspection RequiredAttributes
      return (
        <OuterWrapper opened={this.state.opened} onClick={() => this.close()}>
          {this.state.opened &&
          <Panel onClick={(e) => e.stopPropagation()}>
            <SwiperWrapper>
              <Swiper
                // style={{ height: "100%" }}
                grabCursor
                autoHeight={true}
                pagination={{
                  clickable: true,
                  renderBullet: (index, className) => {
                    className += " slider-bullet";
                    return `<span class="${className}"></span>`;
                  }
                }}
                initialSlide={this.state.activeSlide}
                spaceBetween={10}
                slidesPerView={1}
                onSlideChange={(s) => this.setState({ activeSlide: s.activeIndex })}
                onSwiper={(s) => (this.swiperRef = s)}
              >
                <SwiperSlide>
                  <SwipeSlideWrapper onClick={(e) => e.stopPropagation()}>
                    <Slide>
                      <h1>{Language.getName(Names.Reservation)}</h1>
                      <form id={this.formID1} autoComplete={"on"} action={"#"} onSubmit={(e) => e.preventDefault()}>
                        <h4>{this.state.shop?.name}</h4>
                        <ShopDetails>
                          <ShopAddress onClick={() => ReservationPage.handleAddressClick(this.state.shop)}>
                            <FaLocationArrow/>
                            <p>
                              {
                                this.state.shop?.address?.street + ", "
                                + TranslatableString.get(ContextSystem.getCityByID(this.state.shop?.address?.cityID)?.name)
                              }
                            </p>
                          </ShopAddress>
                          <ShopOpeningInfo open_status={shopOpenStatus}>
                            {shopOpenStatus.status === HourStatuses.OPEN &&
                            <BsFillDoorOpenFill/>
                            }
                            {shopOpenStatus.status !== HourStatuses.OPEN &&
                            <BsFillDoorClosedFill/>
                            }
                            <p>
                              {shopOpenStatus.status === HourStatuses.OPEN &&
                              <>
                                {Language.getName(Names.OpenOpenHoursTextPart1)}
                                {shopOpenStatus.nextCloseD !== dayOfWeek &&
                                <>{Language.getName(Names.DayNamesShort, shopOpenStatus.nextCloseD) + ". "}</>
                                }
                                {addLeadingZero(shopOpenStatus.nextCloseH) + ":" + addLeadingZero(shopOpenStatus.nextCloseM)}
                                {Language.getName(Names.OpenOpenHoursTextPart2)}
                              </>
                              }
                              {shopOpenStatus.status !== HourStatuses.OPEN &&
                              <>
                                {Language.getName(Names.ClosedOpenHoursText)}
                                {shopOpenStatus.nextOpenD !== dayOfWeek &&
                                <>{Language.getName(Names.DayNamesShort, shopOpenStatus.nextOpenD) + ". "}</>
                                }
                                {addLeadingZero(shopOpenStatus.nextOpenH) + ":" + addLeadingZero(shopOpenStatus.nextOpenM)}
                              </>
                              }
                            </p>
                          </ShopOpeningInfo>
                          {this.state.shop?.enableDeliveryOrder &&
                          <ShopOpeningInfo open_status={AcceptingOrdersStatus}>
                            <MdDeliveryDining/>
                            <p>
                              {Language.getName(Names.DeliveryShort) + ": "}
                              {AcceptingOrdersStatus.status === HourStatuses.OPEN &&
                              <>
                                {AcceptingOrdersStatus.nextCloseD !== dayOfWeek &&
                                <>{Language.getName(Names.DayNamesShort, AcceptingOrdersStatus.nextCloseD) + ". "}</>
                                }
                                {addLeadingZero(AcceptingOrdersStatus.nextCloseH) + ":" + addLeadingZero(AcceptingOrdersStatus.nextCloseM)}
                                {Language.getName(Names.OpenOpenHoursTextPart2)}
                              </>
                              }
                              {AcceptingOrdersStatus.status !== HourStatuses.OPEN &&
                              <>
                                {Language.getName(Names.ClosedOpenHoursText)}
                                {AcceptingOrdersStatus.nextOpenD !== dayOfWeek &&
                                <>{Language.getName(Names.DayNamesShort, AcceptingOrdersStatus.nextOpenD) + ". "}</>
                                }
                                {addLeadingZero(AcceptingOrdersStatus.nextOpenH) + ":" + addLeadingZero(AcceptingOrdersStatus.nextOpenM)}
                              </>
                              }
                            </p>
                          </ShopOpeningInfo>
                          }
                          {this.state.shop?.enablePersonalOrder &&
                          <ShopOpeningInfo open_status={AcceptingPickUpOrdersStatus}>
                            <GiPaperBagOpen/>
                            <p>
                              {Language.getName(Names.Takeaway) + ": "}
                              {AcceptingPickUpOrdersStatus.status === HourStatuses.OPEN &&
                              <>
                                {AcceptingPickUpOrdersStatus.nextCloseD !== dayOfWeek &&
                                <>{Language.getName(Names.DayNamesShort, AcceptingPickUpOrdersStatus.nextCloseD) + ". "}</>
                                }
                                {addLeadingZero(AcceptingPickUpOrdersStatus.nextCloseH) + ":" + addLeadingZero(AcceptingPickUpOrdersStatus.nextCloseM)}
                                {Language.getName(Names.OpenOpenHoursTextPart2)}
                              </>
                              }
                              {AcceptingPickUpOrdersStatus.status !== HourStatuses.OPEN &&
                              <>
                                {Language.getName(Names.ClosedOpenHoursText)}
                                {AcceptingPickUpOrdersStatus.nextOpenD !== dayOfWeek &&
                                <>{Language.getName(Names.DayNamesShort, AcceptingPickUpOrdersStatus.nextOpenD) + ". "}</>
                                }
                                {addLeadingZero(AcceptingPickUpOrdersStatus.nextOpenH) + ":" + addLeadingZero(AcceptingPickUpOrdersStatus.nextOpenM)}
                              </>
                              }
                            </p>
                          </ShopOpeningInfo>
                          }
                          {this.state.occupancyRate >= 0 &&
                          <ShopOccupancy>
                            <div/>
                            <p>
                              {Language.getName(Names.Occupancy) + ": "}
                              {this.state.occupancyRate}
                              %, {this.state.freeTable} {Language.getName(Names.FreeTable)}
                            </p>
                          </ShopOccupancy>
                          }
                        </ShopDetails>

                        <h4>{Language.getName(Names.PersonalData)}</h4>
                        <Input
                          disabled
                          id={"lastName-" + this.formID1}
                          value={this.state.profile?.lastName}
                          type="text"
                          width={"49%"}
                          mwidth={"49%"}
                          placeholder={Language.getName(Names.LastName)}
                          autoComplete={"off"}
                        />
                        <Input
                          disabled
                          id={"firstName-" + this.formID1}
                          value={this.state.profile?.firstName}
                          type="text"
                          width={"49%"}
                          mwidth={"49%"}
                          placeholder={Language.getName(Names.FirstName)}
                          autoComplete={"off"}
                        />
                        <Input
                          disabled
                          id={"tel-" + this.formID1}
                          value={this.state.profile?.tel}
                          type="text"
                          width={"49%"}
                          mwidth={"39%"}
                          placeholder={Language.getName(Names.PhoneNumber)}
                          autoComplete={"off"}
                        />
                        <Input
                          disabled
                          id={"email-" + this.formID1}
                          value={this.state.profile?.email}
                          type="text"
                          width={"49%"}
                          mwidth={"59%"}
                          placeholder={Language.getName(Names.EmailAddress)}
                          autoComplete={"off"}
                        />
                        <p
                          onClick={() => EventSystem.publish(EventSystem.events.open_profile_details_popup, { showLogout: false })}
                        >
                          {Language.getName(Names.ToChangeYourDetailsClickHere)}
                        </p>
                        <h4>{Language.getName(Names.NumberOfGuests)}</h4>
                        <Input
                          id={"ppl-" + this.formID1}
                          value={this.state.ppl}
                          type="number"
                          width={"100%"}
                          mwidth={"100%"}
                          placeholder={Language.getName(Names.Guests)}
                          autoComplete={"off"}
                          onChange={e => {
                            let ppl = e.target.value;
                            if (ppl <= 0)
                              ppl = 1;
                            if (ppl > 20)
                              ppl = 20;
                            if (e.target.value === "")
                              ppl = "";
                            this.setState({ ppl }, () => this.buildHourDataSet());
                          }}
                        />
                        <h4>{Language.getName(Names.SpecialRequest)}</h4>
                        <TextArea
                          id={"comment-" + this.formID1}
                          rows={4}
                          value={this.state.editComment}
                          type="text"
                          width={"100%"}
                          mwidth={"100%"}
                          placeholder={Language.getName(Names.TableReservationCommentPlaceholder)}
                          autoComplete={"off"}
                          onChange={e => this.setState({ editComment: e.target.value })}
                        />

                        <h4>{Language.getName(Names.TimeAndPlannedStay)}</h4>
                        <DatePickerWrapper>
                          {this.state.hoursDataSet.length > 0 &&
                          <Select
                            id={"schdp-d-" + this.uniqueID}
                            onChange={e => this.handleDayChange(e)}
                            value={selectedDay?.id}
                          >
                            {this.state.hoursDataSet.map((record: DataSetRecord, i) => {
                              if (record.minutes.length <= 0)
                                return <React.Fragment key={i}/>;

                              return (
                                <option key={i} value={record.id}>
                                  {record.label}
                                </option>
                              );
                            })}
                          </Select>
                          }

                          <Select
                            onChange={e => this.handleChange(new Date(e.target.value))}
                            value={this.state.time}
                            id={"schdp-t-" + this.uniqueID}
                          >
                            {selectedDay && selectedDay.minutes.length > 0 && selectedDay.minutes.map((time, i) => {
                              return (
                                <option key={i} value={time}>
                                  {addLeadingZero(time.getHours()) + ":" + addLeadingZero(time.getMinutes())}
                                </option>
                              );
                            })}
                          </Select>

                          <Select
                            onChange={e => this.setState({ stay: e.target.value }, () => this.buildHourDataSet())}
                            value={this.state.stay}
                            id={"stay-" + this.uniqueID}
                          >
                            <option value={30}>{Language.getName(Names._30min)}</option>
                            <option value={60}>{Language.getName(Names._1h)}</option>
                            <option value={90}>{Language.getName(Names._1_5h)}</option>
                            <option value={120}>{Language.getName(Names._2h)}</option>
                            <option value={180}>{Language.getName(Names._3h)}</option>
                          </Select>
                        </DatePickerWrapper>
                        <Button onClick={() => this.book()}>
                          {Language.getName(Names.Book)}
                        </Button>
                        <ButtonWrapper align={"flex-end"}>
                        <span>
                          {Language.getName(Names.ReservationASZF)}
                        </span>
                        </ButtonWrapper>
                      </form>
                    </Slide>
                  </SwipeSlideWrapper>
                </SwiperSlide>
                <SwiperSlide>
                  <SwipeSlideWrapper onClick={(e) => e.stopPropagation()}>
                    <Slide>
                      <h1>{Language.getName(Names.Reviews)}</h1>
                      <p>{Language.getName(Names.NoReviewsYet)}</p>
                      <ShopReviews>

                      </ShopReviews>
                    </Slide>
                  </SwipeSlideWrapper>
                </SwiperSlide>
              </Swiper>
            </SwiperWrapper>
          </Panel>
          }
        </OuterWrapper>
      );
    } catch (e) {
      console.log(e);
      return (
        <OuterWrapper opened={this.state.opened} onClick={() => this.close()}>

        </OuterWrapper>
      );
    }
  }
}

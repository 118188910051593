import React, {Component} from "react";
import MenuCombiner from "../MenuCombiner";
import {AiFillCaretDown, AiFillCaretRight} from "react-icons/ai";
import {FiMinus} from "react-icons/fi";
import {GoPlus} from "react-icons/go";
import {IoIosTimer} from "react-icons/io";
import {FaTimes, FaTrashAlt} from "react-icons/fa";
import styled, {css} from "styled-components";
import {CartStorage} from "../../utils/CartStorage";
import EventSystem from "../../utils/EventSystem";
import {Button, ScheduleIconTimerWrapper} from "../Form";
import * as HoursCalc from "../../utils/HoursCalc";
import {getOpeningState} from "../../utils/HoursCalc";
import ContextSystem from "../../utils/ContextSystem";
import {AddressAPI} from "../../utils/api/AddressAPI";
import {Product, ProductTypes, TranslatableString, Version} from "../../model/Product";
import {ShippingMethods, ShippingPrice} from "../../model/ShippingPrice";
import {Order, OrderProduct, TableReservation} from "../../model/Order";
import {Element} from "../../model/BluePrint";
import {Shop} from "../../model/Shop";
import Toast from "../Toast";
import {HourStatuses, HourTypes} from "../../model/Hour";
import {Address, City} from "../../model/Address";
import Language, {Names} from "../../utils/Language";
import OrderDetails from "../profile/OrderDetails";
import CartPopup, {FinishAtPlaceOrderButton, FinishAtPlaceOrderButtonWrapper, TableName} from "./CartPopup";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  .close {
    align-self: flex-end;
    margin-top: 0.5em;
    margin-right: 0.5em;
  }

  & > h2 {
    font-size: 15pt;
    margin: 4px 0;
    flex-shrink: 0;
  }
`;

const DisabledDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 3px 0;
  font-size: 10pt;
  width: 100%;
  color: #ff8442;
  font-weight: bold;
`;

const SmallButton = styled.button`
  user-select: none;
  outline: none;
  border: none;

  margin-top: 3px;
  margin-left: 3px;
  padding: 6px 8px;
  font-size: 10pt;
  background-color: rgb(120, 120, 120);
  color: rgb(230, 230, 230);
  border-radius: 4px;

  transition: background-color 100ms ease-in-out, color 100ms ease-in-out;
  cursor: pointer;

  &:hover {
    color: white;
    background-color: rgb(90, 90, 90);
  }

  &:active {
    color: rgb(220, 220, 220);
    background-color: rgb(70, 70, 70);
  }
`;

export const ProductDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  background-color: var(--blundee_background_light);
  border-radius: 6px;
  font-size: 11pt;
  margin-top: 5px;
  color: var(--blundee_color_normal);

  & > div:nth-of-type(1) {
    width: 40%;
    text-align: left;
  }

  & > div:nth-of-type(2) {
    width: 25%;
    margin-left: 5px;
    text-align: right;
  }

  & > div:nth-of-type(3) {
    width: 15%;
    margin-right: 5px;
    text-align: right;
  }

  & > div:nth-of-type(4) {
    width: 15%;
    margin-right: 5px;
    text-align: right;
  }

  & > span {
    margin-left: 5px;
    padding: 2px 5px;
    border-radius: 3px;
    transition: background-color 100ms ease-in-out;

    &:hover, &:active {
      background-color: rgb(35, 35, 35);
      cursor: pointer;
    }
  }

  ${({header}) => header === true && css`
    background-color: transparent;
  `};

  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
    & > div:nth-of-type(1) {
      width: 100%;
      margin-bottom: 5px;
    }

    & > div:nth-of-type(2) {
      width: 25%;
      margin-left: 0;
    }

    & > div:nth-of-type(3) {
      width: 30%;
    }

    & > div:nth-of-type(4) {
      width: 30%;
    }
  }
`;

export const Menu = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 5px;
  background-color: rgb(60, 60, 60);
  border-radius: 6px;
  font-size: 11pt;
  margin-top: 5px;
  color: rgb(220, 220, 220);
`;

const OrderExtraWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: calc(100% - 15px);
  margin: 10px 0 0 15px;

  padding: 7px 10px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
`;

const OrderExtraDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  & > div:nth-of-type(1) {
    width: 40px;
  }

  & > div:nth-of-type(2) {
    width: calc(100% - 40px);
    ${({first}) => first === false && css`
      border-top: 1px solid #adadad;
      padding-top: 3px;
      margin-top: 3px;
    `}
  }
`;

export const MenuHead = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &:hover {
    cursor: pointer;
  }

  & > svg {
    width: 10px;
    margin-right: 5px;
  }

  & > div:nth-of-type(1) {
    width: calc(40% - 15px);
  }

  & > div:nth-of-type(2) {
    width: 25%;
    margin-left: 5px;
    text-align: right;
  }

  & > div:nth-of-type(3) {
    width: 15%;
    margin-right: 5px;
    text-align: right;
  }

  & > div:nth-of-type(4) {
    width: 15%;
    margin-right: 5px;
    text-align: right;
  }

  & > span {
    margin-left: 5px;
    padding: 2px 5px;
    border-radius: 3px;
    transition: background-color 100ms ease-in-out;

    &:hover, &:active {
      background-color: rgb(35, 35, 35);
      cursor: pointer;
    }
  }

  ${({finish}) => finish === true && css`
    & > div:nth-of-type(1) {
      width: calc(40% - 23px);
    }
  `};

  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
    & > div:nth-of-type(1) {
      width: calc(100% - 15px);
      margin-bottom: 5px;
    }

    & > div:nth-of-type(2) {
      width: 25%;
      margin-left: 0;
    }

    & > div:nth-of-type(3) {
      width: 30%;
    }

    & > div:nth-of-type(4) {
      width: 30%;
    }
  }
`;

export const MenuBody = styled.div`
  width: 100%;
  padding-left: 5%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;
  max-height: 0;
  transition: max-height 200ms ease-in-out;
  margin-top: 5px;

  ${({show}) => show === true && css`
    max-height: 100px;
  `};
`;

export const MenuProduct = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & > span:first-child {
    width: 80%;
  }

  & > span:nth-child(2) {
    width: 20%;
    text-align: right;
    padding: 0 5px;

    &:hover, &:active {
      background-color: rgb(35, 35, 35);
      cursor: pointer;
    }
  }
`;

const Bottom = styled.div`
  width: 100%;
  margin-top: auto;
  position: relative;
  padding: 10px;
  bottom: 0;
  left: 0;
  flex-shrink: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const OrderButton = styled(Button)`
  background-color: var(--blundee_button_bg_color);
  flex-grow: 2;
  color: #444440;
  font-weight: bold;
  min-width: 80px;

  &:disabled {
    background-color: var(--blundee_button_disabled_bg);
    color: var(--blundee_button_disabled_color);
    cursor: default;
  }
`;

const OrderButtonRow = styled.div`
  font-size: 13pt;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > span {
    flex-shrink: 0;
    margin-right: 6px;
  }
`;

export const ProductsWrapper = styled.div`
  max-height: 65vh;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 4px;
  padding: 0 5px;
  overflow-y: auto;

  ${({atplace}) => atplace === true && css`
    max-height: 32vh;

    @media screen and (max-width: 800px) {
      max-height: 48%;
    }
  `};

  @media screen and (max-width: 800px) {
    display: block;
    max-height: unset;
    overflow-y: auto;
  }

  @media screen and (min-width: 801px) {
    &::-webkit-scrollbar-thumb {
      background-color: var(--blundee_scroll_thumb_color);
      border-radius: 100px;
    }

    &::-webkit-scrollbar {
      width: 5px;
      background-color: transparent;
    }
  }
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  font-size: 19pt;
  flex-shrink: 0;

  ${({margintop}) => margintop !== undefined && css`
    margin-top: ${margintop};
  `}

  ${({atplace}) => atplace && css`
    background-color: var(--blundee_background_light);
    color: var(--blundee_color_normal);
  `};

  & > h2 {
    font-size: 12pt;
    font-weight: normal;
    margin: 0 10px;
    flex-shrink: 1;

    max-width: 100%;
    overflow: hidden;

    @media screen and (min-width: 801px) {
      max-width: calc(100% - 40px);
    }

    & > button {
      outline: none;
      border: none;

      padding: 4px 3px;
      border-radius: 3px;
      background-color: var(--blundee_button_bg_color);
      user-select: none;

      font-size: 12.5pt;
      color: var(--blundee_color_normal);
      transition: filter 100ms ease-in-out, color 100ms ease-in-out, background-color 100ms ease-in-out;

      &:hover, &:active {
        cursor: pointer;
      }

      @media screen and (min-width: 801px) {
        &:hover {
          filter: brightness(95%);
        }
      }

      &:active {
        filter: brightness(98%);
      }
    }
  }

  & > svg {
    flex-shrink: 0;
    stroke-width: 1px;
    margin: 5px;
    font-size: 15pt;

    transition: transform 100ms ease-in-out, color 100ms ease-in-out;

    &:hover, &:active {
      cursor: pointer;
    }

    &:active {
      transform: scale(0.98);
      color: rgb(200, 200, 200);
    }

    @media screen and (min-width: 801px) {
      color: white;
      transform: scale(1.02);
    }
  }
`;

const ShippingPriceItemName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > div {
    position: relative;
    width: fit-content;
  }

  & > button {
    margin-left: 12px;
  }
`;

export default class Cart extends Component<{ showCloseButton: boolean, toggleOpened: ()=>{} | undefined }> {
  openFinishingOrderClicked: false;
  eventIDs = [];

  state: {
    products: Product[],
    menuOpened: { [keys: number]: boolean },
    url: string,
    editShipping: boolean,
    total: number,
    selectedCity: City,
    selectedAddress: Address,
    shippingPrices: ShippingPrice[],
    cities: City[],
    partner: Shop,
    availableShippingPrices: ShippingPrice[],
    loadingAvailableShippingPrices: boolean,
    shippingMethod: number,
    language: number,
    atPlaceQRID: number,
    atPlaceTableElement: Element,
    atPlaceTableReservation: TableReservation,
    atPlaceOrders: Order[],
    scheduleDate: Date,
  } = {
    products: [],
    menuOpened: {},
    url: window.location.pathname,
    editShipping: false,
    total: 0,
    selectedCity: ContextSystem.selectedCity,
    selectedAddress: ContextSystem.selectedAddress,
    shippingPrices: [],
    cities: [],
    partner: ContextSystem.cartPartner,
    availableShippingPrices: ContextSystem.availableShippingPrices,
    loadingAvailableShippingPrices: ContextSystem.loadingAvailableShippingPrices,
    shippingMethod: ContextSystem.pickup ? ShippingMethods.PICKUP : ShippingMethods.DELIVERY,
    language: ContextSystem.language,
    atPlaceQRID: ContextSystem.atPlaceQRID,
    atPlaceTableElement: ContextSystem.atPlaceTableElement,
    atPlaceTableReservation: ContextSystem.atPlaceTableReservation,
    atPlaceOrders: Cart.getAtPlaceOrders(),
    scheduleDate: ContextSystem.scheduleDate
  };

  static getAtPlaceOrders(): Order[] {
    if (ContextSystem.atPlaceQRID < 0 || !ContextSystem.atPlaceTableReservation)
      return [];

    return ContextSystem.orders.filter(o => o.tableReservationID === ContextSystem.atPlaceTableReservation.id);
  }

  openFinishingOrder() {
    if (!this.state.partner && this.state.products && this.state.products.length > 0) {
      /*Toast.showToast("Valami hiba történt :( Megpróbáljuk egy újratöltés után.");
      setTimeout(() => {
        window.location.reload();
      }, 2500);
      return;*/
      //This is a hotfix, somehow the partnerprofile associated with the cart is not available. This will reload the
      //partnerprofile and open the "orderFinishing" afterwards.
      let partnerID = parseInt(this.state.products[0].partnerID);
      ContextSystem.loadPartnerProfile(false, partnerID, () => this.setState({partner: ContextSystem.cartPartner}, () => this.openFinishingOrderCB()));
    } else {
      this.openFinishingOrderCB();
    }
  }

  openFinishingOrderCB() {
    if (this.state.partner.closedForToday) {
      Toast.showToast(Language.getName(Names.TodayClosedRestaurantText));
      return;
    }

    if (this.state.partner.hours) {
      let AcceptingOrdersStatus = HoursCalc.checkOpeningState(HourTypes.ORDER_ACCEPT, this.state.partner);
      let AcceptingPickupOrdersStatus = HoursCalc.checkOpeningState(HourTypes.ORDER_ACCEPT_FOR_PICKUP, this.state.partner);
      if (
        (ContextSystem.pickup && AcceptingPickupOrdersStatus.status !== HourStatuses.OPEN)
        || (!ContextSystem.pickup && AcceptingOrdersStatus.status !== HourStatuses.OPEN)
      ) {
        Toast.showToast(Language.getName(Names.ClosedRestaurantText));
        return;
      }
    }

    //TODO partner.deactivated, még meg kell nézni a listázásnál meg az restaurantPage-nél
    // if (this.state.partner.deactivated) {
    //   Toast.showToast("Az étterem ideiglenesen be van zárva, emiatt a rendelést nem lehet most leadni.");
    //   return;
    // }

    if (!this.state.products || this.state.products.length <= 0) {
      Toast.showToast(Language.getName(Names.CartEmpty));
      return;
    }

    for (let product: Product of this.state.products) {
      let isDisabled: boolean = false;

      if (product?.weekSchedule && product.weekSchedule.length > 0) {
        let openStatus = getOpeningState(product.weekSchedule, this.state.scheduleDate);
        isDisabled = openStatus.status !== HourStatuses.OPEN && openStatus.status !== HourStatuses.NO_DATA;
      }

      if (isDisabled) {
        Toast.showToast(Language.getName(Names.ProductNotAvailableNowAtCart), 5000);
        return;
      }
    }

    let shippingMethod = ContextSystem.pickup ? ShippingMethods.PICKUP : ShippingMethods.DELIVERY;
    if (ContextSystem.atPlaceQRID > 0)
      shippingMethod = ShippingMethods.AT_PLACE;

    if (this.checkLoginMiddleware()) {
      if (shippingMethod === ShippingMethods.DELIVERY) {
        if (!ContextSystem.selectedAddress) {
          this.openFinishingOrderClicked = true;
          if (ContextSystem.profile && ContextSystem.profile.addresses.length > 0) {
            EventSystem.publish(EventSystem.events.open_floating_address_picker_popup, {autoClose: true});
          } else {
            EventSystem.publish(EventSystem.events.open_address_picker_popup, {
              address: null,
              cb: (selectedAddress) => {
                AddressAPI.saveAddress(selectedAddress, (res) => {
                  EventSystem.publish(EventSystem.events.addressSelected, {address: res.address});
                });
              }
            });
          }
          return;
        }

        this.checkOrderShippingAddress(false, (shippingAvailable) => {
          if (shippingAvailable)
            this.continueOrderFinishing(shippingMethod);
        });
      } else if (shippingMethod === ShippingMethods.PICKUP) {
        if (!this.state.partner.enablePersonalOrder) {
          Toast.showToast(Language.getName(Names.TakeawayNotAvailableText));
          debugger;
          return;
        }
        this.continueOrderFinishing(shippingMethod);
      } else if (shippingMethod === ShippingMethods.AT_PLACE) {
        this.continueOrderFinishing(shippingMethod);
      }
    }
  }

  continueOrderFinishing(shippingMethod: number){
    EventSystem.publish(EventSystem.events.openOrderFinishing, {
      products: this.state.products,
      shippingMethod,
      selectedCity: this.state.selectedCity,
      cities: this.state.cities,
      // shippingPrices: this.state.shippingPrices,
      partner: this.state.partner
    });
  }

  checkLoginMiddleware(): boolean {
    if (!ContextSystem.profile || !ContextSystem.loggedIn) {
      Toast.showToast(Language.getName(Names.PleaseLogInToOrder));
      let redirectPath = window.location.pathname;
      EventSystem.publish(EventSystem.events.open_login_popup, {
        cb: () => {
          EventSystem.publish(EventSystem.events.redirectIntent, {redirectPath});
          this.openFinishingOrder();
        }
      });
      return false;
    }
    return true;
  }

  checkOrderShippingAddress(waitForNewShippingPrices: boolean = false, cb: (shippingAvailable: boolean)=>{}) {
    if (!cb)
      return;

    if (!ContextSystem.selectedAddress) {
      Toast.showToast(Language.getName(Names.YouShouldEnterExactAddress));
      cb(false);
      return;
    }

    /*Fully async shit*/
    if (ContextSystem.availableShippingPrices === undefined || ContextSystem.availableShippingPrices.length <= 0) {
      if (waitForNewShippingPrices) {
        let c;
        let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged,
          ({availableShippingPrices, scheduleDate}) => {
            if (availableShippingPrices !== undefined) {
              this.checkOrderShippingAddress(false, (shippingAvailable) => cb(shippingAvailable));
              clearTimeout(c);
              EventSystem.unsubscribe(eid);
            }
            if (scheduleDate !== undefined) {
              this.setState({scheduleDate});
            }
          }
        );
        c = setTimeout(() => {
          EventSystem.unsubscribe(eid);
          Toast.showToast(Language.getName(Names.NoShipToAddressText));
          cb(false);
        }, 2000);
        return;
      } else {
        Toast.showToast(Language.getName(Names.NoShipToAddressText));
        cb(false);
        return;
      }
    }

    cb(true);
  }

  static isIterable(obj) {
    // checks for null and undefined
    if (obj == null) {
      return false;
    }
    return typeof obj[Symbol.iterator] === "function";
  }

  static getLeadOrderProduct(orderProduct: OrderProduct): OrderProduct {
    if (!orderProduct)
      return null;
    if (!this.isIterable(orderProduct))
      return orderProduct;
    else if (orderProduct.length <= 0)
      return null;

    if (orderProduct.length === 1)
      return orderProduct[0];
    for (let op of orderProduct)
      if (op.type === ProductTypes.MENU)
        return op;
  }

  static getOrderTotalQty(products) {
    let orderTotalQty = 0;
    if (products && products.length > 0) {
      for (let product of products) {
        let leadOP = Cart.getLeadOrderProduct(product.orderProduct);
        orderTotalQty += leadOP.qty;
      }
    }
    return orderTotalQty;
  }

  static calcOrderShippingPrice(availableShippingPrices: ShippingPrice[], orderProductsTotal: number) {
    let shippingPrice = 0;
    if (availableShippingPrices && availableShippingPrices.length > 0) {
      availableShippingPrices.sort((a, b) => a.price - b.price);

      shippingPrice = availableShippingPrices[0].price;
      if (availableShippingPrices[0].price2Min > 0 && orderProductsTotal >= availableShippingPrices[0].price2Min)
        shippingPrice = availableShippingPrices[0].price2;
    }
    return shippingPrice;
  }

  static calcOrderProductsTotalPrice(products: Product[]) {
    let total = 0;
    if (!products || products.length <= 0)
      return 0;

    for (let p of products) {
      let leadOP = Cart.getLeadOrderProduct(p.orderProduct);
      total += leadOP.qty * (leadOP.price + MenuCombiner.getOrderProductExtrasPrice(leadOP, p));
    }
    return total;
  }

  getOrderTotalPrice() {
    let orderTotalPrice = 0;
    if (this.state.products && this.state.products.length > 0) {
      for (let product of this.state.products) {
        let leadOP = Cart.getLeadOrderProduct(product.orderProduct);
        orderTotalPrice += leadOP.qty * (leadOP.price + MenuCombiner.getOrderProductExtrasPrice(leadOP, product));
      }
    }
    return orderTotalPrice;
  }

  removeProduct(product) {
    CartStorage.removeProduct(product);
  }

  isMenuOpened(index) {
    return this.state.menuOpened[index];
  }

  static getVersionByID(versions: Version[], versionID: number): Version {
    for (let v of versions) {
      if (v.id === versionID)
        return v;
    }
    return null;
  }

  load(cart) {
    console.log("loading cart component...");
    console.log("partner value in Context: ", ContextSystem.cartPartner);
    this.setState({partner: ContextSystem.cartPartner});

    if (!cart) cart = CartStorage.getCart();

    let total = 0;
    let menuOpened = {};

    if (cart.length > 0) {
      for (let product of cart) {
        let leadOP = Cart.getLeadOrderProduct(product.orderProduct);
        total += leadOP.qty * (leadOP.price + MenuCombiner.getOrderProductExtrasPrice(leadOP, product));
      }

      for (let index in cart) {
        // noinspection JSUnfilteredForInLoop
        menuOpened[index] = true;
      }
    }

    this.setState({products: cart, total, menuOpened});
  }

  componentWillUnmount() {
    for (let eventID of this.eventIDs)
      EventSystem.unsubscribe(eventID);
    this.eventIDs = [];
  }

  componentDidMount() {
    console.log("cart component mounted");
    this.eventIDs = [];
    this.load();

    let eid;
    eid = EventSystem.subscribe(EventSystem.events.cartUpdated, (cart) => {
      this.load(cart);
    });
    this.eventIDs.push(eid);

    eid = EventSystem.subscribe(EventSystem.events.urlChanged, () => {
      this.setState({url: window.location.pathname});
    });
    this.eventIDs.push(eid);

    eid = EventSystem.subscribe(EventSystem.events.cartCleared, () => {
      this.load([]);
    });
    this.eventIDs.push(eid);

    eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged,
      ({
         cartPartner, availableShippingPrices, loadingAvailableShippingPrices, pickup,
         selectedAddress, selectedCity, language,
         atPlaceQRID, atPlaceTableElement, atPlaceTableReservation, orders
       }) => {
        if (cartPartner !== undefined)
          this.setState({partner: cartPartner});
        if (availableShippingPrices !== undefined)
          this.setState({availableShippingPrices});
        if (loadingAvailableShippingPrices !== undefined)
          this.setState({loadingAvailableShippingPrices});
        if (pickup !== undefined)
          this.setState({shippingMethod: ContextSystem.pickup ? ShippingMethods.PICKUP : ShippingMethods.DELIVERY});
        if (selectedCity !== undefined)
          this.setState({selectedCity});
        if (selectedAddress !== undefined)
          this.setState({selectedAddress});
        if (language !== undefined)
          this.setState({language});
        if (atPlaceQRID !== undefined)
          this.setState({
            atPlaceQRID,
            atPlaceOrders: Cart.getAtPlaceOrders()
          });
        if (atPlaceTableElement !== undefined)
          this.setState({
            atPlaceTableElement,
            atPlaceOrders: Cart.getAtPlaceOrders()
          });
        if (atPlaceTableReservation !== undefined)
          this.setState({
            atPlaceTableReservation,
            atPlaceOrders: Cart.getAtPlaceOrders()
          });
        if (orders !== undefined)
          this.setState({
            atPlaceOrders: Cart.getAtPlaceOrders()
          });
      });
    this.eventIDs.push(eid);

    eid = EventSystem.subscribe(EventSystem.events.addressSelected, ({address}) => {
      if (this.openFinishingOrderClicked && address) {
        //continue the order
        this.checkOrderShippingAddress(true, shippingAvailable => {
          if (shippingAvailable) {
            let shippingMethod = ContextSystem.pickup ? ShippingMethods.PICKUP : ShippingMethods.DELIVERY;
            if (ContextSystem.atPlaceQRID > 0)
              shippingMethod = ShippingMethods.AT_PLACE;
            EventSystem.publish(EventSystem.events.openOrderFinishing, {
              products: this.state.products,
              shippingMethod,
              selectedCity: ContextSystem.selectedCity,
              cities: this.state.cities,
              // shippingPrices: this.state.shippingPrices,
              partner: this.state.partner
            });
          }
        });
      }
      this.openFinishingOrderClicked = false;
    });
    this.eventIDs.push(eid);

    eid = EventSystem.subscribe(EventSystem.events.addressPickerClosedWithoutSetup, () => {
      if (this.openFinishingOrderClicked) {
        this.openFinishingOrderClicked = false;
      }
    });

    this.eventIDs.push(eid);
  }

  toggleMenuOpen(index) {
    let menuOpened = {...this.state.menuOpened};
    menuOpened[index] = menuOpened[index] !== true;
    this.setState({menuOpened});
  }

  redirectToCartPartnerPage() {
    EventSystem.publish(EventSystem.events.redirectIntent, {redirectPath: "/etterem/" + this.state.partner?.url});
    if (ContextSystem.mobileLayout) {
      this.props.toggleOpened();
    }
  }

  static decreaseQuantity(p) {
    console.log("-");
    if (p.type === ProductTypes.PRODUCT) {
      CartStorage.removeProductQuantity(p);
    } else if (p.type === ProductTypes.MENU) {
      CartStorage.removeMenuQuantity(p);
    }
  }

  static increaseQuantity(p) {
    console.log("+");
    if (p.type === ProductTypes.PRODUCT) {
      CartStorage.addProductQuantity(p);
    } else if (p.type === ProductTypes.MENU) {
      CartStorage.addMenuQuantity(p);
    }
  }

  static renderOrderProductCart(p: Product, i: number, isMenuOpened: (i: number)=>{}, toggleMenuOpen: (i: number)=>{}, modificationButtons: boolean = true, orderProduct: OrderProduct | null = null) {
    if (!p)
      return <React.Fragment key={i}/>;

    let leadOP = Cart.getLeadOrderProduct(p.orderProduct);
    if (orderProduct !== null)
      leadOP = orderProduct;

    let versionName = "";
    let v = Cart.getVersionByID(p.versions, leadOP.versionID);
    if (p.versions.length > 1 && v) versionName = "(" + TranslatableString.get(v.name) + ")";

    let isDisabled: boolean = false;

    if (p?.weekSchedule && p.weekSchedule.length > 0) {
      let openStatus = getOpeningState(p.weekSchedule, ContextSystem.scheduleDate);
      isDisabled = openStatus.status !== HourStatuses.OPEN && openStatus.status !== HourStatuses.NO_DATA;
    }

    if (p.type && p.type === ProductTypes.PRODUCT) {
      return (
        <ProductDiv key={i}>
          <div>
            {TranslatableString.get(leadOP.name)} {versionName}
            {leadOP.extras.length > 0 &&
              Cart.renderOrderExtrasInCart(leadOP)
            }
          </div>
          <div onClick={(e: MouseEvent) => e.stopPropagation()} style={{userSelect: "none"}}>
            {modificationButtons &&
              <>
                <FiMinus style={{cursor: "pointer"}} onClick={() => Cart.decreaseQuantity(p)}/>{" "}
              </>
            }
            {leadOP.qty < 10 && <span style={{visibility: "hidden"}}>0</span>}
            {leadOP.qty} db
            {modificationButtons &&
              <>
                <GoPlus style={{cursor: "pointer", marginLeft: "5px"}} onClick={() => Cart.increaseQuantity(p)}/>
              </>
            }
          </div>
          <div>{leadOP.price + MenuCombiner.getOrderProductExtrasPrice(leadOP, p) + " Ft"}</div>
          <div>{(leadOP.price + MenuCombiner.getOrderProductExtrasPrice(leadOP, p)) * leadOP.qty + " Ft"}</div>
          {modificationButtons &&
            <span onClick={() => CartStorage.removeProduct(p)}>
            <FaTrashAlt/>
          </span>
          }
          {isDisabled &&
            <DisabledDiv>
              {Language.getName(Names.ProductNotAvailableNow)}
            </DisabledDiv>
          }
        </ProductDiv>
      );
    } else if (p.type && p.type === ProductTypes.MENU) {
      return (
        <Menu key={i}>
          <MenuHead onClick={() => toggleMenuOpen(i)}>
            {!isMenuOpened(i) && <AiFillCaretRight onClick={() => toggleMenuOpen(i)}/>}
            {isMenuOpened(i) && <AiFillCaretDown onClick={() => toggleMenuOpen(i)}/>}
            {p.id < 0 && <div>{Language.getName(Names.CombinedMenu)}</div>}
            {p.id > 0 && (
              <div>
                {TranslatableString.get(p.name) + " " + versionName}
                {Cart.renderOrderExtrasInCart(leadOP)}
              </div>
            )}
            <div onClick={(e) => e.stopPropagation()} style={{userSelect: "none"}}>
              {modificationButtons &&
                <>
                  <FiMinus style={{cursor: "pointer"}} onClick={() => Cart.decreaseQuantity(p)}/>{" "}
                </>
              }
              {leadOP.qty < 10 && <span style={{visibility: "hidden"}}>0</span>}
              {leadOP.qty} db
              {modificationButtons &&
                <>
                  <GoPlus style={{cursor: "pointer"}} onClick={() => Cart.increaseQuantity(p)}/>
                </>
              }
            </div>
            <div>{leadOP.price + MenuCombiner.getOrderProductExtrasPrice(leadOP, p) + " Ft"}</div>
            <div>{(leadOP.price + MenuCombiner.getOrderProductExtrasPrice(leadOP, p)) * leadOP.qty + " Ft"}</div>
            {modificationButtons &&
              <span onClick={() => CartStorage.removeMenu(p)}>
              <FaTrashAlt/>
            </span>
            }
          </MenuHead>
          <MenuBody show={isMenuOpened(i)}>
            {p && p.products.map((subProduct, j) => {
              let subLeadOP = Cart.getLeadOrderProduct(subProduct.orderProduct);
              return (
                <MenuProduct key={j}>
                  <span>
                    {TranslatableString.get(subProduct.name)}
                    {" (" +
                      subProduct.qty +
                      Language.getName(Names.pcs) +
                      (p.id < -1 && subLeadOP.extras.length > 0 ? ", " + subLeadOP.extras.length + " " + Language.getName(Names.KindTopping) : "") +
                      ")"}
                  </span>
                  {p.id < -1 && (
                    <span onClick={() => CartStorage.removeProduct(subProduct)}>
                      <FaTrashAlt/>
                    </span>
                  )}
                </MenuProduct>
              );
            })}
          </MenuBody>
          {isDisabled &&
            <DisabledDiv>
              {Language.getName(Names.ProductNotAvailableNow)}
            </DisabledDiv>
          }
        </Menu>
      );
    } else {
      return <React.Fragment key={i}/>;
    }
  }

  static renderOrderExtrasInCart(leadOP: OrderProduct) {
    return (
      <>
        {leadOP.extras.length > 0 &&
          <OrderExtraWrapper>
            {leadOP.extras.map((extra, k) => {
              return (
                <OrderExtraDiv first={k === 0}>
                  <div>{extra.qty + " " + Language.getName(Names.pcs)}</div>
                  <div>{TranslatableString.get(extra.name)}</div>
                </OrderExtraDiv>
              );
            })}
          </OrderExtraWrapper>
        }
      </>
    );
  }

  openSelectAddressPopup() {
    EventSystem.publish(EventSystem.events.open_address_picker_popup, {
      address: null,
      cb: (selectedAddress) => {
        AddressAPI.saveAddress(selectedAddress, (res) => {
          EventSystem.publish(EventSystem.events.addressSelected, {address: res.address});
        });
      }
    });
  }

  changeToTakeAway() {
    ContextSystem.setPickup(true, false);
  }

  changeToDelivery() {
    ContextSystem.setPickup(false, false);
  }

  static backToTable() {
    let url = window.location.pathname.toLowerCase();
    if (url.startsWith("/asztal/") || url.startsWith("/table/"))
      return;

    let path = "/asztal/" + ContextSystem.atPlaceQRID;
    EventSystem.publish(EventSystem.events.redirectIntent, {redirectPath: path});
  }

  render() {
    let products = this.state.products;
    let shippingPrice = 0;
    let selectedAddressOrCity = this.state.selectedCity || this.state.selectedAddress;

    if (this.state.shippingMethod === ShippingMethods.DELIVERY && selectedAddressOrCity && this.state.availableShippingPrices.length > 0) {
      shippingPrice = Cart.calcOrderShippingPrice(this.state.availableShippingPrices, this.state.total);
    }

    let atPlace: boolean = this.state.atPlaceQRID > 0 && !!this.state.atPlaceTableElement;

    let itemPcs: number = 0;
    if (atPlace === true && this.state.atPlaceOrders && this.state.atPlaceOrders.length > 0) {
      for (let atPlaceOrder: Order of this.state.atPlaceOrders) {
        for (let orderProduct of atPlaceOrder.productList) {
          itemPcs += orderProduct.qty;
        }
      }
    }

    return (
      <Container>
        <Header atplace={atPlace}>
          <h2>
            {!atPlace && Language.getName(Names.Cart)}
            {atPlace && Language.getName(Names.PrevOrders)}
            {!atPlace && this.state.partner !== undefined && (
              <>
                {" - "}
                <button
                  onClick={() => this.redirectToCartPartnerPage()}>{this.state.partner?.name ?? this.state.partner?.restaurantName}
                </button>
              </>
            )}
          </h2>
          {atPlace &&
            <>
              <TableName onClick={() => Cart.backToTable()}>
                <div>
                  {this.state.atPlaceTableElement.name}
                </div>
              </TableName>
              {itemPcs > 0 &&
                <FinishAtPlaceOrderButtonWrapper>
                  <FinishAtPlaceOrderButton onClick={e => CartPopup.finishAtPlaceButtonClicked(e)}>
                    {(this.state.url.startsWith("/asztal/") || this.state.url.startsWith("/asztal/")) &&
                      Language.getName(Names.FinishTableReservationText)
                    }
                    {!(this.state.url.startsWith("/asztal/") || this.state.url.startsWith("/asztal/")) &&
                      Language.getName(Names.BackToTable)
                    }
                  </FinishAtPlaceOrderButton>
                </FinishAtPlaceOrderButtonWrapper>
              }
            </>
          }
          {this.props.showCloseButton && <FaTimes onClick={() => this.props.toggleOpened()}/>}
        </Header>
        {atPlace &&
          <ProductsWrapper onTouchMove={(e) => e.stopPropagation()} onTouchStart={(e) => e.stopPropagation()}
                           onTouchEnd={(e) => e.stopPropagation()}
                           atplace={atPlace}
          >
            {(!this.state.atPlaceOrders || this.state.atPlaceOrders.length <= 0) &&
              <div>{Language.getName(Names.TableStillEmpty)}</div>
            }
            {this.state.atPlaceOrders.map((order: Order, i: number) => {
              return <OrderDetails order={order} openedDefault={false} tableReservationUI={true} key={i} shop={this.state.partner}/>;
            })}
          </ProductsWrapper>
        }

        {atPlace &&
          <Header atplace={atPlace} margintop={"10px"}>
            <h2>{Language.getName(Names.CurrentOrder)}</h2>
          </Header>
        }
        <ProductsWrapper onTouchMove={(e) => e.stopPropagation()} onTouchStart={(e) => e.stopPropagation()}
                         onTouchEnd={(e) => e.stopPropagation()}
                         atplace={atPlace}
        >
          {(!products || products.length <= 0) &&
            <div>{Language.getName(Names.CartStillEmpty)}</div>
          }
          {products && products.map((p, i) => {
            return (
              <React.Fragment key={i}>
                {Cart.renderOrderProductCart(p, i, (i) => this.isMenuOpened(i), (i) => this.toggleMenuOpen(i), true)}
              </React.Fragment>
            );
          })}
          {!atPlace &&
            <ProductDiv>
              {this.state.shippingMethod === ShippingMethods.PICKUP &&
                <ShippingPriceItemName>
                  <div>{Language.getName(Names.TakeawayAtShop)}</div>
                  {this.state.partner?.enableDeliveryOrder &&
                    <SmallButton
                      onClick={() => this.changeToDelivery()}>{Language.getName(Names.Delivery)}
                    </SmallButton>
                  }
                  {this.state.scheduleDate &&
                    <>
                      <ScheduleIconTimerWrapper>
                        <IoIosTimer/>
                      </ScheduleIconTimerWrapper>
                      <span>{this.state.scheduleDate.toHunFormatMinutes()}</span>
                    </>
                  }
                </ShippingPriceItemName>
              }
              {this.state.shippingMethod === ShippingMethods.DELIVERY &&
                <>
                  <ShippingPriceItemName>
                    <div>{Language.getName(Names.ShippingPrice)}</div>
                    {this.state.scheduleDate &&
                      <>
                        <ScheduleIconTimerWrapper>
                          <IoIosTimer/>
                        </ScheduleIconTimerWrapper>
                        <span>{this.state.scheduleDate.toHunFormatMinutes()}</span>
                      </>
                    }
                  </ShippingPriceItemName>
                  {selectedAddressOrCity &&
                    <>
                      {this.state.selectedAddress &&
                        <div>{TranslatableString.get(ContextSystem.getCityByID(this.state.selectedAddress.cityID)?.name)}</div>
                      }
                      {!this.state.selectedAddress && this.state.selectedCity &&
                        <div>{TranslatableString.get(this.state.selectedCity.name)}</div>
                      }
                      <div>{shippingPrice} Ft</div>
                    </>
                  }
                  {!selectedAddressOrCity &&
                    <>
                      <SmallButton
                        onClick={() => this.openSelectAddressPopup()}>{Language.getName(Names.EnterExactAddress)}
                      </SmallButton>
                      {this.state.partner?.enablePersonalOrder &&
                        <SmallButton
                          onClick={() => this.changeToTakeAway()}>{Language.getName(Names.TakeawayAtShop)}
                        </SmallButton>
                      }
                    </>
                  }
                </>
              }
            </ProductDiv>
          }
          <ProductDiv>
            <div>
              <b>{Language.getName(Names.Total)}:</b>
            </div>
            <div>
              {(this.state.products?.length ?? -1) > 0 &&
                <b>
                  {this.state.total + shippingPrice}
                  {" Ft"}
                </b>
              }
            </div>
          </ProductDiv>
        </ProductsWrapper>
        <Bottom>
          <OrderButtonRow>
            <OrderButton onClick={() => this.openFinishingOrder()}
                         disabled={!this.state.products || this.state.products.length === 0}
            >
              {Language.getName(Names.ToCashier)}
            </OrderButton>
          </OrderButtonRow>
        </Bottom>
      </Container>
    );
  }

}

import * as React from "react";

function BlundeeBackIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 93 189"
      fill={"none"}
      stroke={"currentColor"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M85.2773 181.697L13.3691 109.789C4.87695 101.297 4.87695 87.4003 13.3691 78.9081L85.2773 7" stroke-width="14" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
);
}

export default BlundeeBackIcon;

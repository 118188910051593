import React, { Component } from "react";
import styled, { css, keyframes } from "styled-components";
import ContextSystem from "../utils/ContextSystem";
import EventSystem from "../utils/EventSystem";
import Language, { Names } from "../utils/Language";
// noinspection NpmUsedModulesInstalled
import { Swiper, SwiperSlide } from "swiper/react";
import PhoneInput2 from "react-phone-input-2";
import hu from "react-phone-input-2/lang/hu.json";
import en from "../assets/en.json";
import { Button } from "../components/Form";
import { AuthAPI } from "../utils/api/AuthAPI";
import ReactFlagsSelect2 from "react-flags-select";
import { Input as InputOrg } from "../components/about/FormElements";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { CounterInnerWrapper, TimerWrapper as TimerWrapperOrg } from "../components/profile/OrderDetails";
import { addLeadingZero } from "../utils/HoursCalc";
import { Input } from "./PasswordResetPage";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import EmailValidator from "../utils/EmailValidator";
import Toast from "../components/Toast";
import pizzaImg from "../assets/welcomeScreenPics/slide1/slide1_pizza.png";
import burgerImg from "../assets/welcomeScreenPics/slide1/slide1_burger.png";
import frenchFriesImg from "../assets/welcomeScreenPics/slide1/slide1_frenchFries.png";
import person1 from "../assets/welcomeScreenPics/slide2/person1.jpg";
import person2 from "../assets/welcomeScreenPics/slide2/person2.png";
import person3 from "../assets/welcomeScreenPics/slide2/person3.png";
import person5 from "../assets/welcomeScreenPics/slide2/person5.jpg";
import { FaTimes } from "react-icons/fa";
import { getCSSProperty } from "../Initializer";

const CloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: auto;
  padding: 5px;
  font-size: 19pt;
  flex-shrink: 0;
  cursor: pointer;
  border-radius: 100px;
  transition: background-color 300ms ease-in-out;
  color: var(--blundee_color_normal);

  &:hover {
    background-color: var(--blundee_navbar_bg_color);
  }

  & > svg {
    font-size: 16pt;
  }
`;

const ShakeKeyframes = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`;

const TimerWrapper = styled(TimerWrapperOrg)`
  margin-top: 24px;
`;

const VerificationCodeInput = styled(InputOrg)`
  margin: 72px 0 24px 0;
  width: 100%;
  background-color: transparent;
  border: none;
  font-family: var(--blundee_font_bold);
  font-weight: bold;
  padding: 12px 15px;
  letter-spacing: 45px;
  text-indent: 55px;
  font-size: 52pt;
  text-align: center;
  color: transparent;
  text-shadow: 0 0 0 var(--blundee_color_normal);

  &::selection {
    background: transparent;
  }

  &:focus {
    &::placeholder {
      color: var(--blundee_color_tag);
    }
  }

  &::placeholder {
    transition: color 200ms ease-in-out;
  }

  &:hover, &:active, &:focus {
    outline: none;
  }

  @media screen and (max-width: 800px) {
    margin: 24px 0 18px 0;
    font-size: 25pt;
    letter-spacing: 25px;
    text-indent: 35px;
  }

  &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }

  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;

  ${({shake}) => shake === true && css`
    animation: ${ShakeKeyframes} 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite alternate;

    &::placeholder {
      color: var(--blundee_error);
      opacity: 1;
    }
  `};

  @media screen and (max-height: 700px) {
    margin: 12px 0 12px 0;
  }
`;

export const ReactFlagsSelect = styled(ReactFlagsSelect2)`
  width: ${({width}) => width ?? 230}px;

  button {
    background-color: var(--blundee_navbar_bg_color) !important;
    border: none;
    color: var(--blundee_color_normal) !important;

    &:after {
      border-top-color: var(--blundee_color_2) !important;
      border-bottom-color: var(--blundee_color_2) !important;
    }
  }

  ul {
    background-color: var(--blundee_navbar_bg_color) !important;
    border: none;

    li {
      transition: background-color 150ms ease-in-out;
      padding: 10px;

      svg {
        font-size: 20pt;
      }
    }

    li:focus, li:hover {
      background-color: var(--blundee_input_bg_3) !important;
    }
  }
`;

const PhoneInput = styled(PhoneInput2)`
  width: 100%;
  margin-top: 24px;

  ${({shake}) => shake === true && css`
    animation: ${ShakeKeyframes} 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite alternate;

    &::placeholder {
      color: var(--blundee_error);
      opacity: 1;
    }
  `};

  & > input {
    width: 100% !important;
    background-color: var(--blundee_input_bg_1) !important;
    border-color: transparent !important;
    color: var(--blundee_color_normal);
    font-weight: bold;
    font-size: 14pt !important;
    height: 50px !important;
    font-family: var(--blundee_font_medium) !important;
  }

  .divider {
    background-color: var(--blundee_input_bg_1) !important;
  }

  .flag-dropdown, .flag-dropdown .open {
    height: 50px;

    &:hover, &:focus {
      background-color: var(--blundee_input_bg_1) !important;
      border-color: transparent !important;
      border-right: 1px solid var(--blundee_input_bg_3) !important;
    }

    background-color: var(--blundee_input_bg_1) !important;
    border-color: transparent !important;
    border-right: 1px solid var(--blundee_input_bg_3) !important;
  }

  li {
    background-color: var(--blundee_grey_dark);
    color: white;

    &.search {
      background-color: var(--blundee_grey_dark) !important;
      color: white !important;
    }

    &.highlight, &:hover {
      background-color: var(--blundee_grey) !important;
    }

    & > .flag {

    }

    & > .country_name {
      font-weight: bold;
    }

    & > .dial-code {

    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  background-color: var(--blundee_background);
`;

export const SwiperWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

export const SwipeSlideWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  box-sizing: border-box;
  padding: 24px;
  border-radius: 8px;

  @media screen and (max-height: 800px) {
    padding: 12px 12px;
  }


`;

const FirstSlideImageWrapper = styled.div`
  margin: 36px auto 0 auto;
  height: 400px;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: visible;
  background: radial-gradient(circle, rgb(74, 166, 169) 0%, rgba(255, 255, 255, 0) 70%);

  & > h1 {
    font-family: var(--blundee_font_bold) !important;
    color: var(--blundee_color_normal);
    font-size: 28pt;
    text-align: center;
  }

  & > img {
    width: 150px;
    position: absolute;
  }

  & > img:nth-of-type(1) {
    top: 0;
    left: calc(50% - 75px);
  }

  & > img:nth-of-type(2) {
    top: 60%;
    left: calc(25% - 100px);
  }

  & > img:nth-of-type(3) {
    top: 60%;
    left: calc(75% - 0px);
  }

  @media screen and (max-width: 800px) {
    height: 350px;
    width: 350px;
    margin-top: 0;
  }

  @media screen and (max-height: 850px) {
    height: 350px;
    width: 350px;
    margin-top: 12px;
    background: radial-gradient(circle, rgba(74, 169, 151, 1) 0%, rgba(18, 18, 18, 0) 60%);

    & > img {
      width: 120px;
    }
  }
`;

const SlideText = styled.p`
  margin-top: 96px;
  font-size: 12pt;
  color: var(--blundee_text_color_4);
  font-weight: bold;
  font-family: var(--blundee_font_medium) !important;

  span {
    font-size: 15pt;
  }

  @media screen and (max-height: 850px) {
    margin-top: 24px;
    margin-bottom: 12px;
    font-size: 11pt;
  }
`;

const SMSDescription = styled.p`
  margin-top: 12px;
  font-size: 10pt !important;
  font-family: var(--blundee_font_medium) !important;
  color: var(--blundee_color_thin);

  span {
    font-size: 11pt;
  }

  @media screen and (max-height: 850px) {
    margin-top: 12px;
    margin-bottom: 12px;
    font-size: 9pt !important;
  }

  @media screen and (max-height: 700px) {
    margin-top: 6px;
    margin-bottom: 6px;
    font-size: 8pt !important;
  }
`;

const SecondSlideImageWrapper = styled(FirstSlideImageWrapper)`
  background: radial-gradient(circle, var(--blundee_navbar_bg_color) 0%, rgba(255, 255, 255, 0) 70%);

  & > img {
    position: absolute;
    border-radius: 1000px;
    object-fit: cover;
    box-shadow: var(--blundee_start_page_person_shadow);
  }

  & > img:nth-of-type(1) {
    width: 200px;
    height: 200px;
    top: 50%;
    left: -3%;
  }

  & > img:nth-of-type(2) {
    width: 170px;
    height: 170px;
    top: 17%;
    left: 53%;
  }

  & > img:nth-of-type(3) {
    width: 150px;
    height: 150px;
    top: 6%;
    left: 9%;
  }

  & > img:nth-of-type(4) {
    width: 150px;
    height: 150px;
    top: 58%;
    left: 90%;
  }

  @media screen and (max-width: 800px) {
    & > img:nth-of-type(1) {
      width: 170px;
      height: 170px;
      top: 53%;
      left: -3%;
    }

    & > img:nth-of-type(2) {
      width: 150px;
      height: 150px;
      top: 17%;
      left: 50%;
    }

    & > img:nth-of-type(3) {
      width: 115px;
      height: 115px;
      top: 11%;
      left: 9%;
    }

    & > img:nth-of-type(4) {
      width: 115px;
      height: 115px;
      top: 61%;
      left: 78%;
    }
  }

  @media screen and (max-height: 850px) {
    background: radial-gradient(circle, var(--blundee_navbar_bg_color) 0%, rgba(18, 18, 18, 0) 60%);

    & > img:nth-of-type(1) {
      width: 150px;
      height: 150px;
      top: 53%;
      left: -3%;
    }

    & > img:nth-of-type(2) {
      width: 130px;
      height: 130px;
      top: 17%;
      left: 50%;
    }

    & > img:nth-of-type(3) {
      width: 100px;
      height: 100px;
      top: 11%;
      left: 9%;
    }

    & > img:nth-of-type(4) {
      width: 100px;
      height: 100px;
      top: 62%;
      left: 65%;
    }
  }
`;

export const SlideHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const Slide = styled.div`
  width: 100%;
  height: 100%;
  max-width: 600px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  color: var(--blundee_color_normal);

  & > h1 {
    font-family: var(--blundee_font_bold) !important;
    color: var(--blundee_color_normal);
    font-size: 30pt;
    font-weight: 600;
    height: 38px;
    margin: 0;
  }

  & > p {
    font-family: var(--blundee_font_medium) !important;
    font-size: 12pt;
    width: 100%;
    text-align: left;
  }

  @media screen and (max-width: 600px) {
    & > h1 {
      font-size: 25pt;
      padding: 0;
    }

    & > p {
      font-size: 10pt;
    }
  }
`;

const NextButton = styled(Button)`
  position: fixed;
  bottom: 20px;
  left: 0;

  border-radius: 30px;
  margin: auto 10px 20px 10px;
  font-weight: bold;
  cursor: pointer;
  width: calc(100% - 20px);
  max-width: unset;

  transition: background-color 300ms ease-in-out, box-shadow 150ms ease-in-out;

  box-shadow: 0 0 8px -3px var(--blundee_input_bg_1);
  background-color: var(--blundee_button_disabled_bg);
  color: var(--blundee_button_disabled_color);
  border: none;

  ${({disabled_style}) => disabled_style !== true && css`
    background-color: var(--blundee_button_bg_color);
    box-shadow: 0 0 8px -3px var(--blundee_button_bg_color);
  `};

  &:hover {
    background-color: var(--blundee_button_disabled_bg);
    color: var(--blundee_button_disabled_color);
    border: none;
    ${({disabled_style}) => disabled_style !== true && css`
      background-color: var(--blundee_yellow);
      box-shadow: 0 0 6px 1px var(--blundee_turkiz);
    `};
  }

  &:active {
    box-shadow: 0 0 8px -3px var(--blundee_input_bg_1);
    background-color: var(--blundee_input_bg_3);
    color: var(--blundee_text_color_3);
    border: none;
    ${({disabled_style}) => disabled_style !== true && css`
      background-color: var(--blundee_yellow);
      box-shadow: 0 0 2px 1px var(--blundee_turkiz);
    `};
  }
  
  @media screen and (min-width: 800px){
    margin: auto 30% 20px 30%;
    width: 40%;
  }
  
  @media screen and (min-width: 1000px){
    margin: auto 35% 20px 35%;
    width: 30%;
  }
`;

const ResendSMS = styled.div`
  padding: 6px;
  margin: 3px auto;
  text-align: center;
  font-size: 10pt;
  color: var(--blundee_color_thin);
  cursor: pointer;

  ${({shake}) => shake === true && css`
    animation: ${ShakeKeyframes} 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite alternate;
  `};

  ${({disabled_style}) => disabled_style === true && css`
    color: var(--blundee_color_disabled);
  `}
`;

export const languages = [
  {id: 0, code: "HU-HU", languageCode: "HU", label: "Magyar", countryNamesForPhoneComponent: hu},
  {id: 1, code: "EN-GB", languageCode: "GB", label: "English", countryNamesForPhoneComponent: en}
];

export default class StartPage extends Component {
  uniqueID: number = ContextSystem.getNextUniqueID();
  formID: number;
  eventIDs: number[] = [];
  phoneInputRef;
  sendSMSTimeOut: number = 35;

  componentWillUnmount() {
    if (this.timeTimeout)
      clearTimeout(this.timeTimeout);

    for (let eventID of this.eventIDs) {
      EventSystem.unsubscribe(eventID);
    }
  }

  state: {
    editPhoneNumber: string,
    editVerificationCode: string,

    editFirstName: string,
    editLastName: string,
    editEmail: string,
    editPassword: string,

    language: number,
    activeSlide: number,

    smsRequired: Date,
    seconds: number,
    tryTimes: number,
    tempToken: string,
    shakeInput: boolean,
    shakeTel: boolean,
    shakeResend: boolean,
    profile: Profile,
    startPageDone: boolean,
    time: Date,
    redirected: boolean
  } = {
    editPhoneNumber: "36",
    editVerificationCode: "",

    editFirstName: "",
    editLastName: "",
    editEmail: "",
    language: ContextSystem.language,
    activeSlide: 0,

    smsRequired: undefined,
    seconds: -1,
    tryTimes: 0,
    tempToken: "",
    shakeInput: false,
    shakeTel: false,
    shakeResend: false,
    profile: ContextSystem.profile,
    startPageDone: ContextSystem.startPageDone,
    time: new Date(),
    redirected: redirected
  };

  timeTimeout;

  startTime() {
    this.timeTimeout = setTimeout(() => {
      this.setState({
        time: new Date(),
        seconds: this.state.seconds === -1 ? -1 : this.state.seconds - 1
      });
      this.startTime();
    }, 1000);
  }

  componentDidMount() {
    this.eventIDs = [];
    this.startTime();

    if (ContextSystem.profile?.firstName?.length > 0) {
      this.setState({
        editFirstName: ContextSystem.profile.firstName,
        editLastName: ContextSystem.profile.lastName,
        editEmail: ContextSystem.profile.email,
      });
      this.swiperRef.slideTo(ContextSystem.profile?.firstName?.length > 0 ? 1 : 0);
      this.handleDoneClicked();
    }

    let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({language, profile, startPageDone}) => {
      if (language !== undefined)
        this.setState({language});
      if (profile !== undefined) {
        this.setState({profile});
        this.setState({
          editFirstName: ContextSystem.profile?.firstName,
          editLastName: ContextSystem.profile?.lastName,
          editEmail: ContextSystem.profile?.email,
        });
        this.swiperRef.slideTo(ContextSystem.profile?.firstName?.length > 0 ? 1 : 0);
        this.handleDoneClicked();
      }
      if (startPageDone !== undefined)
        this.setState({startPageDone});
    });
    this.eventIDs.push(eid);
  }

  handlePhoneKeyUp(e) {
    if (e.key === "Enter") {
      this.handleSendSMS(false);
    }
  }

  handleVerificationKeyUp(e) {
    if (e.key === "Enter") {
      this.handleVerifyCode();
    }
  }

  handleSendSMS(resend: boolean = false) {
    if (this.state.tryTimes > 3) {
      Toast(Language.getName(Names.PleaseTryAgainLater));
      return;
    }

    if (resend && new Date() < new Date(this.state.smsRequired).addSeconds(this.sendSMSTimeOut)) {
      this.setState({shakeResend: true});
      setTimeout(() => {
        this.setState({shakeResend: false});
      }, 1000);
      return;
    }

    if (!parsePhoneNumberFromString(this.state.editPhoneNumber, "HU")?.isValid()) {
      this.setState({shakeTel: true});
      setTimeout(() => {
        this.setState({shakeTel: false});
      }, 1000);
      return;
    }

    AuthAPI.requireSMS(this.state.editPhoneNumber, resend, res => {
      if (res.error !== 0)
        return;

      this.sendSMSTimeOut = 30 + 15 * this.state.tryTimes;

      this.setState({
          smsRequired: new Date(res.smsRequired),
          seconds: res.seconds,
          tryTimes: this.state.tryTimes + 1
        }
      );
      this.verificationInputRef.focus();
    });
  }

  saveProfileDetails() {
    if (!EmailValidator(this.state.editEmail)) {
      Toast.showToast(Language.getName(Names.GivenEmailIsNotValid));
      return;
    }
    if (this.state.editLastName.length < 2) {
      Toast.showToast(Language.getName(Names.GivenNameIsNotValid));
      return;
    }
    if (this.state.editFirstName.length < 2) {
      Toast.showToast(Language.getName(Names.GivenNameIsNotValid));
      return;
    }

    AuthAPI.saveProfileDetails(this.state.editFirstName, this.state.editLastName, this.state.editEmail, (res) => {
      if (res.error !== 0)
        return;
      Toast.showToast(Language.getName(Names.Saved));
      this.handleDoneClicked();
    });
  }

  handleVerifyCode() {
    if (this.state.editVerificationCode.length <= 5) {
      this.setState({shakeInput: true});
      setTimeout(() => {
        this.setState({shakeInput: false});
      }, 1000);
      return;
    }

    AuthAPI.verifySMSCode(this.state.editVerificationCode, this.state.editPhoneNumber, res => {
      if (res.error !== 0)
        return;

      window.localStorage.setItem("usertoken", res.tempToken);
      AuthAPI.reloadLoginDetails();
      ContextSystem.resetWebSocket();

      if (redirected && redirectCb) {
        AuthAPI.checkLogin(true, () => {
          if (this.isDone()) {
            redirectCb();
          }
        });
      }
    });
  }

  isDone(): boolean {
    return ContextSystem.profile
      && ContextSystem.profile?.firstName?.length > 0
      && ContextSystem.profile?.lastName?.length > 0
      && ContextSystem.profile?.email?.length > 0;
  }

  handleDoneClicked() {
    if (!this.isDone())
      return;

    ContextSystem.setStartPageDone(true);

    if (redirected && redirectCb)
      redirectCb();
  }

  render() {
    let phoneValid = false;
    if (this.state.editPhoneNumber.length > 0) {
      let number = parsePhoneNumberFromString("+" + this.state.editPhoneNumber, "HU");
      phoneValid = number?.isValid() ?? false;
    }

    let timerColor = getCSSProperty("--blundee_button_bg_color");

    return (
      <Wrapper>
        <SwiperWrapper>
          <Swiper
            style={{height: "100%"}}
            grabCursor
            // autoHeight={true}
            pagination={{
              clickable: true,
              renderBullet: (index, className) => {
                className += " slider-bullet";
                return `<span class="${className}"></span>`;
              }
            }}
            initialSlide={this.state.activeSlide}
            spaceBetween={10}
            slidesPerView={1}
            onSlideChange={(s) => this.setState({activeSlide: s.activeIndex})}
            onSwiper={(s) => (this.swiperRef = s)}
          >
            {!this.state.profile &&
              <>
                <SwiperSlide>
                  <SwipeSlideWrapper onClick={(e) => e.stopPropagation()}>
                    <Slide>
                      <SlideHeader>
                        <ReactFlagsSelect
                          selected={languages[this.state.language].languageCode}
                          countries={languages.map(l => l.languageCode)}
                          customLabels={(() => {
                            let o = {};
                            languages.forEach(l => o[l.languageCode] = l.label);
                            return o;
                          })()}
                          onSelect={code => ContextSystem.setLanguage(languages.find(l => l.languageCode === code).id)}
                        />
                        {this.state.redirected &&
                          <CloseButton onClick={() => redirectCb && redirectCb()}>
                            <FaTimes/>
                          </CloseButton>
                        }
                      </SlideHeader>
                      <FirstSlideImageWrapper>
                        <h1>{Language.getName(Names.Slide1Title)}</h1>
                        <img alt={"pizza"} src={pizzaImg}/>
                        <img alt={"burger"} src={burgerImg}/>
                        <img alt={"frenchFries"} src={frenchFriesImg}/>
                      </FirstSlideImageWrapper>
                      <SlideText>{Language.getName(Names.Slide1Text)}</SlideText>
                      <NextButton onClick={() => this.swiperRef.slideTo(1)}>
                        {Language.getName(Names.Next)}
                      </NextButton>
                    </Slide>
                  </SwipeSlideWrapper>
                </SwiperSlide>
                <SwiperSlide>
                  <SwipeSlideWrapper onClick={(e) => e.stopPropagation()}>
                    <Slide>
                      <h1>{Language.getName(Names.WhoWeAre)}</h1>
                      <SecondSlideImageWrapper>
                        <img alt={"person1"} src={person1}/>
                        <img alt={"person2"} src={person2}/>
                        <img alt={"person3"} src={person3}/>
                        <img alt={"person5"} src={person5}/>
                      </SecondSlideImageWrapper>
                      <SlideText>{Language.getName(Names.Slide2Text)}</SlideText>
                      <NextButton onClick={() => this.swiperRef.slideTo(2)}>
                        {Language.getName(Names.RegisterButton)}
                      </NextButton>
                    </Slide>
                  </SwipeSlideWrapper>
                </SwiperSlide>
                <SwiperSlide>
                  <SwipeSlideWrapper onClick={(e) => e.stopPropagation()}>
                    <Slide>
                      <h1>{Language.getName(Names.SignIn)}</h1>
                      <p>{Language.getName(Names.PleaseGiveYourPhoneNumber)}</p>
                      <PhoneInput
                        key={this.state.language}
                        disabled={this.state.smsRequired !== undefined}
                        autoComplete={"off"}
                        preferredCountries={["hu"]}
                        regions={"europe"}
                        placeholder={"+36 30 123 4567"}
                        searchPlaceholder={Language.getName(Names.Search) + "..."}
                        searchNotFound={Language.getName(Names.NoResults)}
                        autoFormat={true}
                        enableAreaCodeStretch={true}
                        areaCodes={{hu: ["30", "20", "70", "50", "51"]}}
                        masks={{hu: ".. ... ...."}}
                        enableSearch={true}
                        disableSearchIcon={true}
                        localization={languages.find(l => l.id === this.state.language).countryNamesForPhoneComponent}
                        country={"hu"}
                        value={this.state.editPhoneNumber}
                        inputProps={{onKeyUp: e => this.handlePhoneKeyUp(e)}}
                        onKeyDown={e => this.handlePhoneKeyUp(e)}
                        onChange={editPhoneNumber => this.setState({editPhoneNumber})}
                        shake={this.state.shakeTel}
                        ref={r => this.phoneInputRef = r}
                      />
                      {this.state.smsRequired !== undefined &&
                        <>
                          <TimerWrapper>
                            <CountdownCircleTimer
                              key={this.state.tryTimes}
                              isPlaying={this.state.seconds > 0}
                              size={100}
                              strokeWidth={4}
                              duration={this.state.seconds}
                              colors={timerColor}
                            >
                              {({remainingTime}) => {
                                let min: number = Math.floor(remainingTime / 60);
                                let sec: number = remainingTime - (min * 60);
                                return (
                                  <CounterInnerWrapper>
                                    <div>{Language.getName(Names.RemainingTime)}</div>
                                    <div>{(min > 0 ? addLeadingZero(min) + ":" : "") + addLeadingZero(sec)}</div>
                                  </CounterInnerWrapper>
                                );
                              }}
                            </CountdownCircleTimer>
                          </TimerWrapper>
                          <ResendSMS
                            disabled_style={this.state.time < new Date(this.state.smsRequired).addSeconds(this.sendSMSTimeOut)}
                            onClick={() => this.handleSendSMS(true)}
                            shake={this.state.shakeResend}
                          >
                            {Language.getName(Names.ResendSMS)}
                            {this.state.time < new Date(this.state.smsRequired).addSeconds(this.sendSMSTimeOut) &&
                              <>
                                {" "}
                                {Math.round((new Date(this.state.smsRequired).addSeconds(this.sendSMSTimeOut) - this.state.time) / 1000)}
                                {"..."}
                              </>
                            }
                          </ResendSMS>
                          <VerificationCodeInput
                            value={this.state.editVerificationCode}
                            type={"number"}
                            autoComplete={"off"}
                            min={0}
                            max={999999}
                            pattern={"^[0-9]{6}$"}
                            inputMode={"numeric"}
                            step={1}
                            onKeyDown={e => this.handleVerificationKeyUp(e)}
                            onChange={(e) => this.setState({editVerificationCode: e.target.value.substring(0, 6)})}
                            placeholder={"______"}
                            shake={this.state.shakeInput}
                            ref={r => this.verificationInputRef = r}
                          />
                          <SMSDescription>
                            {Language.getName(Names.SMSDescription)}
                          </SMSDescription>
                          <NextButton disabled_style={this.state.editVerificationCode.length <= 5}
                                      onClick={() => this.handleVerifyCode()}
                          >
                            {Language.getName(Names.Next)}
                          </NextButton>
                        </>
                      }
                      {this.state.smsRequired === undefined &&
                        <>
                          <SMSDescription>
                            {Language.getName(Names.SMSDescription)}
                          </SMSDescription>
                          <NextButton disabled_style={!phoneValid} onClick={() => this.handleSendSMS()}>
                            {Language.getName(Names.Send)}
                          </NextButton>
                        </>
                      }
                    </Slide>
                  </SwipeSlideWrapper>
                </SwiperSlide>
              </>
            }
            {this.state.profile &&
              <>
                <SwiperSlide>
                  <SwipeSlideWrapper onClick={e => e.stopPropagation()}>
                    <Slide>
                      <h1>{Language.getName(Names.WelcomeScreenGreetings1)}!</h1>
                      {/*<p>{Language.getName(Names.VerificationSuccessful)}</p>*/}
                      <Input
                        autoComplete="given-name"
                        value={this.state.editFirstName}
                        type="text"
                        placeholder={Language.getName(Names.FirstName)}
                        onChange={(e) => this.setState({editFirstName: e.target.value})}
                      />
                      <Input
                        autoComplete="family-name"
                        value={this.state.editLastName}
                        type="text"
                        placeholder={Language.getName(Names.LastName)}
                        onChange={(e) => this.setState({editLastName: e.target.value})}
                      />
                      <Input
                        autoComplete="email"
                        value={this.state.editEmail}
                        type="email"
                        placeholder={Language.getName(Names.EmailAddress)}
                        onChange={(e) => this.setState({editEmail: e.target.value})}
                      />
                      <NextButton onClick={() => this.saveProfileDetails()}>
                        {Language.getName(Names.LetsGo)}
                      </NextButton>
                    </Slide>
                  </SwipeSlideWrapper>
                </SwiperSlide>
              </>
            }
          </Swiper>
        </SwiperWrapper>
      </Wrapper>
    );
  }
}

let redirected: boolean = false;
let redirectCb: ()=>{} = undefined;

EventSystem.subscribe(EventSystem.events.open_start_page, ({cb}) => {
  redirected = true;
  redirectCb = () => {
    redirected = false;
    if (cb)
      cb();
    else
      EventSystem.publish(EventSystem.events.redirectIntent, { goBack: true, queryParams: { } });
  };
  EventSystem.publish(EventSystem.events.redirectIntent, {redirectPath: "/start"});
});
export const LoaderWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 10px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

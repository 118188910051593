import React, { Component } from "react";
import styled, { css } from "styled-components";
import Config from "../utils/Config";
import ContextSystem from "../utils/ContextSystem";
import { getOpeningState } from "../utils/HoursCalc";
import EventSystem from "../utils/EventSystem";
import { Shop } from "../model/Shop";
import Language, { Names } from "../utils/Language";
import { HourStatuses } from "../model/Hour";
import Toast from "./Toast";
import { GlobalCategory, Product, ProductTypes, TranslatableString, Version } from "../model/Product";
import { BottomImageTag, OverlayWrapper } from "./ShopComponent";

const Wrapper = styled.div`
  height: fit-content;
  width: calc(100% - 24px);
  position: relative;

  padding: 0 6px 0 0;
  background-color: var(--blundee_background_light);
  box-shadow: var(--blundee_product_card_shadow);
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  margin: 3px 12px;
  user-select: none;
  border-radius: 7px;

  transition: transform 100ms ease-in-out, background-color 300ms ease-in-out, border-radius 300ms ease-in-out;

  &:hover {
    ${({is_disabled}) => is_disabled === false && css`
      cursor: pointer;
      background-color: var(--blundee_background_lighter);
    `};
  }

  &:active {
    ${({is_disabled}) => is_disabled === false && css`
      cursor: pointer;
      transform: scale(0.98);
    `};
  }

  @media screen and (min-width: 800px) {
    padding: 0;
    &:hover {
      ${({is_disabled}) => is_disabled === false && css`
        //transform: scale(1.012);
      `};
    }
  }

  ${({layout}) => layout === "tall" && css`
    background-image: linear-gradient(45deg, var(--blundee_background_restaurant_card_special_1), var(--blundee_background_restaurant_card_special_2));
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-bottom: none;
    border-radius: 6px;
    overflow: hidden;
    padding: 0;
    margin: 0;
    @media screen and (max-width: 800px) {
      //height: 300px;
    }
  `}

  ${({selected}) => selected === true && css`
    background-image: linear-gradient(45deg, var(--blundee_product_selected_bg1), var(--blundee_product_selected_bg2)); // #841a1e
  `}

  ${({in_slide}) => in_slide === true && css`
    width: 100% !important;
    margin: 0 !important;
  `}
`;

const DisabledHover = styled.div`
  position: absolute;
  z-index: 2;
  background-color: rgb(158 158 158 / 33%);
  border-radius: 15px;
  width: 100%;
  height: 100%;
`;

const Details = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  width: 0;
  min-width: 0;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin: 6px 0;
  padding: 0 3px;

  ${({with_image}) => with_image !== true && css`
    padding: 0 3px 0 9px;
  `};

  ${({layout}) => layout === "tall" && css`
    width: 100%;
    margin: 0;
    padding: 0 6px 6px 6px;
    flex-grow: 0;
    height: fit-content;
  `};
`;

const Restaurant = styled.div`
  font-family: var(--blundee_font_bold);
  font-size: 8pt;
  color: var(--blundee_color_thin);
  margin: 0;
  padding: 3px 0 0 0;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  //noinspection CssUnknownProperty
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  svg {
    font-size: 13pt;
  }

  ${({show}) => show === false && css`
    display: none;
  `}
`;

const DetailsText = styled.div`
  font-size: 8pt;
  width: 100%;
  margin-top: 3px;
  font-family: var(--blundee_font_medium);
  color: ${({is_disabled}) => is_disabled ? "var(--blundee_color_normal)" : "var(--blundee_color_thin)"};
  display: -webkit-box;
  text-overflow: ellipsis;
  //noinspection CssUnknownProperty
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;

  ${({show}) => show === false && css`
    display: none;
  `}
`;

const Name = styled.h2`
  overflow: hidden;
  font-family: var(--blundee_font_bold);
  font-size: 11pt;
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
  //border-bottom: 1px solid #404040;
  color: ${({is_disabled}) => is_disabled ? "var(--blundee_color_thin)" : "var(--blundee_color_normal)"};

  display: -webkit-box;
  //noinspection CssUnknownProperty
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const ImageWrapper = styled.div`
  width: 33%;
  max-width: 250px;
  height: auto;
  overflow: hidden;
  min-height: 70px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 0 6px 0 0;
  position: relative;
  padding-top: calc(30% * 56.25%);

  & > img {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px 0 0 6px;
    overflow: hidden;
  }

  & > svg {
    font-size: 20pt;
    margin-bottom: 6px;
  }

  & > span {
    font-family: var(--blundee_font_medium);
    font-size: 12pt;
    padding: 0;
    text-align: center;
  }

  ${({layout}) => layout === "tall" && css`
    width: 100%;
    padding-top: 56.25%;
    max-width: unset;
    height: 50%;
    margin: 0 0 3px 0;

    & > img {
      border-radius: 6px 6px 0 0;
    }
  `}
`;

const LastPanel = styled.div`
  width: 100%;
  margin-top: auto;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;

  ${({layout}) => layout === "tall" && css`
    margin-top: 0;
  `}
`;

const PricesWrapper = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-grow: 1;
  flex-shrink: 0;
`;

const Price = styled.div`
  font-size: 10pt;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  color: ${({is_disabled}) => is_disabled ? "rgb(100,100,100)" : "rgb(200,200,200)"};
  text-align: right;
  margin-top: 3px;
`;

const PriceText = styled.div`
  background-color: var(--blundee_price_bg);
  border-radius: 80px;
  margin-left: 4px;
  padding: 3px 8px;
  color: ${({is_disabled}) => is_disabled ? "var(--blundee_color_thin)" : "var(--blundee_color_normal)"};
  font-family: var(--blundee_font_bold);
  font-size: 9pt;

  ${({have_sale}) => have_sale === true && css`
    background-color: var(--blundee_special_price_bg);
    color: var(--blundee_special_price_color);
    font-size: 12pt;
    font-weight: bold;
    padding: 1px 6px;
  `};

  ${({layout}) => layout === "tall" && css`
    background-color: var(--blundee_background);
  `};

  ${({sale}) => sale === true && css`
    background-color: var(--blundee_price_bg);
    position: relative;
    font-size: 10pt;
    color: ${({is_disabled}) => is_disabled ? "var(--blundee_color_thin)" : "var(--blundee_color_normal)"};

    &::after {
      position: absolute;
      content: "";
      left: 5%;
      top: calc(50% - 1px);
      right: 5%;
      border-top: 2px solid;
      border-color: var(--blundee_special_price_crossing);
      transform: rotate(-10deg);
    }
  `};
`;

export const OverlayWrapperBase = styled.div`
  position: absolute;
  background-color: rgba(96, 96, 96, 0.2);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: var(--blundee_font_medium);
  font-weight: 600;
  backdrop-filter: blur(1px);
  top: 0;
  z-index: 1;
`;

export default class ProductComponent extends Component<{
  showLocation: boolean,
  showOpenHours: boolean,
  product: Product,
  partnerProfile: Shop,
  addToCart: boolean,
  handleClick: ()=>{},
  handleButtonClick: ()=>{},
  selected: boolean,
}> {
  eventIDs: number[] = [];

  componentWillUnmount() {
    for (let eventID of this.eventIDs) {
      EventSystem.unsubscribe(eventID);
    }
  }

  state: {
    product: Product | undefined,
    partnerProfile: Shop | undefined,
    addToCart: ()=>{} | undefined,
    showLocation: boolean | undefined,
    showOpenHours: boolean | undefined,
    mobile: boolean,
    layout: string,
    language: number,
    scheduleDate: Date,
  } = {
    product: undefined,
    partnerProfile: undefined,
    addToCart: undefined,
    showLocation: undefined,
    showOpenHours: undefined,
    mobile: window.innerWidth <= Config.mobileMaxWidth,
    layout: "wide",
    language: ContextSystem.language,
    scheduleDate: ContextSystem.scheduleDate
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.product !== prevProps.product)
      this.setState({product: this.props.product});
    if (this.props.partnerProfile !== prevProps.partnerProfile)
      this.setState({partnerProfile: this.props.partnerProfile});
    if (this.props.addToCart !== prevProps.addToCart)
      this.setState({addToCart: this.props.addToCart});
    if (this.props.showLocation !== prevProps.showLocation)
      this.setState({showLocation: this.props.showLocation});
    if (this.props.showOpenHours !== prevProps.showOpenHours)
      this.setState({showOpenHours: this.props.showOpenHours});
    if (this.props.layout !== prevProps.layout)
      this.setState({layout: this.props.layout});
  }

  componentDidMount() {
    if (this.props.product !== undefined)
      this.setState({product: this.props.product});
    if (this.props.partnerProfile !== undefined)
      this.setState({partnerProfile: this.props.partnerProfile});
    if (this.props.addToCart !== undefined)
      this.setState({addToCart: this.props.addToCart});
    if (this.props.layout !== undefined)
      this.setState({layout: this.props.layout});
    //
    if (this.props.showLocation !== undefined)
      this.setState({showLocation: this.props.showLocation});
    else
      this.setState({showLocation: true});
    //
    if (this.props.showOpenHours !== undefined)
      this.setState({showOpenHours: this.props.showOpenHours});
    else
      this.setState({showOpenHours: true});
    //
    //
    window.addEventListener("resize", () => {
      this.setState({mobile: window.innerWidth <= Config.mobileMaxWidth});
    });
    this.setState({mobile: window.innerWidth <= Config.mobileMaxWidth});

    this.eventIDs = [];
    let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged,
      ({language, scheduleDate}) => {
        if (language !== undefined)
          this.setState({language});
        if (scheduleDate !== undefined) {
          this.setState({scheduleDate});
        }
      }
    );
    this.eventIDs.push(eid);
  }

  /*
  handleProductLabelClick(e, globalCategory: GlobalCategory) {
    e.stopPropagation();
    if (!globalCategory)
      return;
    EventSystem.publish(EventSystem.events.selectGlobalCategory, {globalCategory});
  }

  openingStatus = {
    closed: false,
    preOrder: false
  };
  */

  render() {
    let product = this.state.product;
    let partnerProfile = this.state.partnerProfile;

    if (!product || !partnerProfile || this.state.showLocation === undefined)
      return <></>;
    if (product.type === ProductTypes.MENU && !product.menuSelected)
      return <></>;


    /*
    let addressLocation;
    if (ContextSystem.selectedAddress?.coordinates) {
      addressLocation = {
        lat: ContextSystem.selectedAddress.coordinates.latitude,
        lon: ContextSystem.selectedAddress.coordinates.longitude
      };
    } else if (ContextSystem.selectedCity) {
      addressLocation = {
        lat: ContextSystem.selectedCity.center.latitude,
        lon: ContextSystem.selectedCity.center.longitude
      };
    }

    let distance, distanceText;

    if (this.state.showLocation && addressLocation !== undefined) {
      distance = airDistnace(partnerProfile?.position.latitude, partnerProfile?.position.longitude, addressLocation.lat, addressLocation.lon);
      distanceText = distance ? "~" + (distance > 1000 ? Math.round(distance / 1000) + "km" : Math.round(distance / 100) * 100 + "m") : "";
    }

    let OpenStatus = HoursCalc.checkOpeningState(HourTypes.OPENING, partnerProfile);
    let AcceptingPickUpOrdersStatus = HoursCalc.checkOpeningState(HourTypes.ORDER_ACCEPT_FOR_PICKUP, partnerProfile);
    let DeliveringStatus = HoursCalc.checkOpeningState(HourTypes.DELIVERY, partnerProfile);
    let AcceptingOrdersStatus = HoursCalc.checkOpeningState(HourTypes.ORDER_ACCEPT, partnerProfile);
    let openState = HoursCalc.getOpeningHoursText(OpenStatus, AcceptingPickUpOrdersStatus, DeliveringStatus, AcceptingOrdersStatus);

    this.openingStatus = {closed: false, preOrder: false};

    if (openState.openStringDL === HourStatuses.NO_DATA) {
      this.openingStatus.closed = true;
    } else if (openState.openStringDL === HourStatuses.LOADING) {
      this.openingStatus.closed = false;
      this.openingStatus.preOrder = false;
    } else if (openState.openStringDL.includes(Language.getName(Names.Closed))) {
      this.openingStatus.closed = true;
    } else if (openState.openStringDL.includes(Language.getName(Names.ForPreOrder))) {
      this.openingStatus.preOrder = true;
    }
   */

    let globalCategory: GlobalCategory = {name: TranslatableString.create("")};
    if (ContextSystem.globalCategories) {
      for (let gc of ContextSystem?.globalCategories) {
        if (gc.id === product.globalCategoryID) {
          globalCategory = gc;
          break;
        }
      }
    }

    let isDisabled: boolean = false;

    if (product?.weekSchedule && product.weekSchedule.length > 0) {
      let openStatus = getOpeningState(product.weekSchedule, this.state.scheduleDate);
      isDisabled = openStatus.status !== HourStatuses.OPEN && openStatus.status !== HourStatuses.NO_DATA;
    }


    let needsImageSpace = product.image && product.image !== "";

    let cheapestVersion: Version = undefined;
    if (this.state?.product?.versions?.length > 0) {
      cheapestVersion = this.state.product.versions[0];
      for (let v: Version of this.state.product.versions) {
        if (v.price < cheapestVersion.price)
          cheapestVersion = v;
      }
    }

    let priceComponent = (
      <>
        {product.showPrice <= 0 &&
          <>
            {product?.versions?.length <= 0 &&
              <Price is_disabled={isDisabled}>
                {product.originalPrice > 0 &&
                  <PriceText layout={this.state.layout}
                             sale={true}>{product.originalPrice.toLocaleString()}Ft</PriceText>
                }
                <PriceText layout={this.state.layout} have_sale={product.originalPrice > 0}>
                  {product.price.toLocaleString()}Ft
                </PriceText>
              </Price>
            }
            {cheapestVersion && product?.versions?.length > 0 &&
              <Price is_disabled={isDisabled}>
                {product.originalPrice > 0 && product.originalPrice !== product.originalPrice + cheapestVersion.price &&
                  <PriceText
                    layout={this.state.layout}
                    sale={true}>{(product.originalPrice + cheapestVersion.price).toLocaleString()}Ft
                  </PriceText>
                }
                <PriceText
                  layout={this.state.layout}
                  have_sale={product.originalPrice > 0}>{(product.price + cheapestVersion.price).toLocaleString()}Ft
                </PriceText>
              </Price>
            }
          </>
        }
      </>
    );

    return (
      <Wrapper is_disabled={isDisabled} {...this.props}
               onClick={() => {
                 if (isDisabled) {
                   Toast.showToast(Language.getName(Names.ProductNotAvailableNow));
                   return;
                 }
                 if (this.props.handleClick)
                   this.props.handleClick();
               }}
      >
        {isDisabled &&
          <DisabledHover/>
        }
        {needsImageSpace &&
          <ImageWrapper layout={this.state.layout}>
            {this.props.layout === "tall" &&
              <OverlayWrapper>
                <BottomImageTag>
                  {priceComponent}
                </BottomImageTag>
              </OverlayWrapper>
            }
            {product.image && product.image !== "" && (
              <img src={Config.productImageUrlBase + product.image} alt={"image"}
                   onError={(e) => (e.target.style.display = "none")}/>
            )}
          </ImageWrapper>
        }
        <Details with_image={needsImageSpace} layout={this.state.layout}>
          <Name is_disabled={isDisabled}>{TranslatableString.get(product?.name)}</Name>
          <Restaurant is_disabled={isDisabled} show={this.state.showLocation}>
            <span>{partnerProfile ? partnerProfile.name ? partnerProfile.name : partnerProfile.name : ""}</span>
          </Restaurant>
          <DetailsText is_disabled={isDisabled} show={this.state.layout !== "tall"}>
            {TranslatableString.get(product?.details)}
          </DetailsText>
          <LastPanel layout={this.state.layout}>
            <PricesWrapper>
              {product.showPrice > 0 &&
                <>
                  <Price is_disabled={isDisabled}>
                    {product.originalPrice > 0 &&
                      <PriceText layout={this.state.layout}
                                 sale={true}>{product.originalPrice.toLocaleString()}Ft</PriceText>
                    }
                    <PriceText layout={this.state.layout}
                               have_sale={product.originalPrice > 0}>{product.showPrice.toLocaleString()}Ft</PriceText>
                  </Price>
                </>
              }
              {this.props.layout !== "tall" && priceComponent}
            </PricesWrapper>
          </LastPanel>
        </Details>
      </Wrapper>
    );
  }
}

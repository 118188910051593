import React, { Component } from "react";
import styled from "styled-components";
import ContextSystem from "../utils/ContextSystem";
import EventSystem from "../utils/EventSystem";
import Language, { Names } from "../utils/Language";

const Error = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  font-size: 40pt;
  text-align: center;
  color: dimgrey;
  font-family: var(--blundee_font_medium);

  @media screen and (max-width: 600px) {
    font-size: 22pt;
  }
`;

export default class ErrorPage extends Component {
  eventIDs: number[] = [];

  state: {
    language: number,
  } = {
    language: ContextSystem.language,
  }

  componentWillUnmount() {
    for (let eventID of this.eventIDs) {
      EventSystem.unsubscribe(eventID);
    }
  }

  componentDidMount() {
    this.eventIDs = [];
    let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({language}) => {
      if (language !== undefined) {
        this.setState({language});
      }
    });
    this.eventIDs.push(eid);
  }

  render() {
    return (
      <Error>
        {Language.getName(Names.Error404Page)}
      </Error>
    );
  }
}

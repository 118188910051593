import React, { Component } from "react";
import styled, { css } from "styled-components";
import EventSystem from "../../utils/EventSystem";
import { Swiper, SwiperSlide } from "swiper/react";
import { GoogleMap, Polygon, withGoogleMap } from "react-google-maps";
import ContextSystem from "../../utils/ContextSystem";
import * as HoursCalc from "../../utils/HoursCalc";
import { BsChevronLeft } from "react-icons/bs";
import { FaShippingFast, FaStore } from "react-icons/fa";
import { FiPhoneCall } from "react-icons/fi";
import { IoIosSend } from "react-icons/io";
import Config from "../../utils/Config";
import { HourStatuses, HourTypes } from "../../model/Hour";
import { ShippingMethods, ShippingPrice } from "../../model/ShippingPrice";
import { City, GeoMultiPolygon, Point } from "../../model/Address";
import { Shop } from "../../model/Shop";
import Language, { Names } from "../../utils/Language";
import { RestaurantAPI } from "../../utils/api/RestaurantAPI";

const MyMapComponent = withGoogleMap((props: PropsWithChildren<{ defaultZoom: number, defaultCenter: string }>) => (
  <GoogleMap
    // zoom={props.defaultZoom}
    // center={props.defaultCenter}
    defaultZoom={props.defaultZoom}
    defaultCenter={props.defaultCenter}
    defaultOptions={{ gestureHandling: "cooperative" }}
  >
    {props.children}
  </GoogleMap>
));

const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  background-color: transparent;

  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(100%);
  transition: transform 300ms ease-in-out 80ms, background-color 100ms ease-in-out;
  z-index: 10;

  ${({ opened }) => !!opened && css`
    transition: transform 300ms ease-in-out, background-color 100ms ease-in-out 250ms;
    background-color: rgba(0, 0, 0, 0.49);
    transform: translateX(0);
  `}
`;

const HeadBar = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 60px;
  margin-bottom: 5px;
  padding: 12px 24px;
  background-color: var(--blundee_navbar_bg_color);
  font-family: var(--blundee_font_medium);

  @media screen and (max-width: 600px) {
    padding: 6px 12px;
  }
`;

const HoursPane = styled.div`
  width: calc(100% - 12px);
  background-color: var(--blundee_background_light);
  margin: 6px 6px 48px 6px;
  border-radius: 7px;
  padding: 6px;
  min-height: 60px;
`;

const OpeningDayRecord = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 5px 7%;
`;

const OpeningDayText = styled.div`
  width: 60px;
  flex-shrink: 0;
  padding: 3px 4px;
`;

const OpeningRecordTextsInDay = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
`;

const OpeningRecordText = styled.div`
  padding: 3px 4px;
  flex-shrink: 0;
`;

const PaneTitle = styled.div`
  font-size: 20pt;
  font-family: var(--blundee_font_bold);
  color: var(--blundee_color_2);
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const HeadButton = styled.div`
  background-color: transparent;
  border-radius: 100px;
  height: 40px;
  width: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 3px;
  padding: 0;
  transition: background-color 100ms ease-in-out;

  & > svg {
    margin: 0;
    padding: 0;
    font-size: 18pt;
    color: var(--blundee_color_2);
    position: relative;
  }

  @media screen and (max-width: 600px) {
    width: 30px;
    height: 30px;
    & > svg {
      font-size: 15pt;
    }
  }

  &:hover, &:active {
    background-color: rgba(80, 80, 80, 0.21);
    cursor: pointer;
  }
`;

const Wrapper = styled.div`
  width: 35%;
  height: 100%;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  color: var(--blundee_color_normal);
  background-color: var(--blundee_background);
  padding: 0 0 ${({ padding_bottom }) => padding_bottom}px 0;
  box-shadow: var(--blundee_card_shadow);

  transition: width 200ms ease-in-out;

  @media screen and (max-width: 600px) {
    width: 95%;
    padding-top: ${({ topmargin }) => Config.mobile ? topmargin ?? 0 : 0}px;
  }

  @media screen and (max-width: 1000px) and (min-width: 601px) {
    width: 50%;
  }
`;

const ScrollWrapper = styled.div`
  h1 {
    width: 100%;
    font-size: 14pt;
    font-family: var(--blundee_font_medium);
    color: var(--blundee_color_normal);
    margin: 0 0 6px 0;
  }

  font-family: var(--blundee_font_medium);
  font-size: 11pt;

  overflow-y: auto;
  overflow-x: hidden;

  padding: 0 20px 20px 20px;

  @media screen and (max-width: 600px) {
    padding: 0 10px;
  }

  @media screen and (min-width: 600px) {
    &::-webkit-scrollbar-thumb {
      background-color: var(--blundee_scroll_thumb_color);
      border-radius: 100px;
    }

    &::-webkit-scrollbar {
      width: 15px;
      background-color: rgb(80, 80, 80);
    }
  }
`;

const RestaurantDetailsText = styled.div`
  width: 100%;
`;

const OpeningStatuses = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0;

  & > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 6px 0;

    & > span:nth-child(1) {
      width: 45%;
    }

    & > span {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin: 0 3px;

      & > svg {
        font-size: 14pt;
        margin-right: 10px;
      }
    }
  }
`;

const HourTabsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`;

const HourTab = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${({ active }) => (active ? "var(--blundee_color_thin)" : "var(--blundee_color_disabled)")};
  background-color: ${({ active }) => (active ? "var(--blundee_button_bg_color)" : "transparent")};
  min-height: 30px;
  border-radius: 5px 5px 0 0;

  font-weight: ${({ active }) => (active ? "500" : "normal")};

  transition: color 100ms ease-in-out, background-color 100ms ease-in-out;

  &:hover, &:active {
    cursor: pointer;
    color: var(--blundee_color_normal);
  }
`;

const ShippingAddressesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  margin-top: 10px;
`;

export const googleMapsCenterBP = {
  lat: 47.495031,
  lng: 19.055796
};

const ContactWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;
  margin-top: 10px;
  font-family: var(--blundee_font_medium);

  svg {
    font-size: 18pt;
  }

  & > h1 {
    margin-bottom: 5px;
  }


  & > p {
    width: 100%;
    font-size: 11pt;
    margin: 0 0 5px 0;
  }

  & > div {
    background-color: var(--blundee_background_light);
    border-radius: 7px;
    padding: 10px;
    transition: filter 100ms ease-in-out;
    margin: 10px 0 0 0;

    &:hover {
      filter: brightness(95%);
    }

    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    font-size: 11pt;

    & > span:nth-of-type(1) {
      width: 40%;
      padding-right: 4px;
    }

    & > span:nth-of-type(2) {
      width: 60%;
      padding-left: 4px;
    }

    & > span {
      display: flex;
      flex-direction: row;
      align-items: center;

      svg {
        margin: 0 5px;
      }

      & > a {
        transition: color 100ms ease-in-out;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        &:hover, &:active, &:visited {
          text-decoration: none;
        }

        &:hover {
          color: var(--blundee_color_thin);
        }
      }
    }

    & > span:nth-child(1) {
      justify-content: flex-start;
    }

    & > span:nth-child(2) {
      justify-content: flex-end;
      color: var(--blundee_color_tag);
    }
  }
`;

const MapContainer = styled.div`
  height: 350px;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;

  @media screen and (max-width: 600px) {
    height: 250px;
  }
`;

export default class RestaurantSidePanel extends Component {
  swiperRef;

  state: {
    partnerIdLoaded: number,
    partner: Shop | undefined,
    cities: City[],
    shippingPrices: ShippingPrice[],
    polygons: GeoMultiPolygon[],
    opened: boolean,
    hoursActiveTab: number,
    googleAPILoaded: boolean,
    statusBarSpaceHeight: number,
    language: number,
    mobileNavbarHeight: number,
  } = {
    partnerIdLoaded: -1,
    partner: undefined,
    cities: [],
    shippingPrices: [],
    polygons: [],
    opened: false,
    hoursActiveTab: 0,
    googleAPILoaded: ContextSystem.googleAPILoaded,
    statusBarSpaceHeight: ContextSystem.statusBarSpaceHeight,
    language: ContextSystem.language,
    mobileNavbarHeight: ContextSystem.mobileNavbarHeight
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.hoursActiveTab !== prevState.hoursActiveTab) {
      this.swiperRef.slideTo(this.state.hoursActiveTab);
      //console.log('Swiper: ', this.swiper)
    }
  }

  load(partner: Shop) {
    if (this.state.partner && this.state.partner.id === partner.id && this.state.partnerIdLoaded === partner.id) {
      this.setState({ opened: true });
      return;
    }

    this.setState({ partnerIdLoaded: partner.id, opened: true });

    RestaurantAPI.getShippingPrices(false, partner.id, (res) => {
      if (res.error !== 0)
        return;

      let cities: City[] = res.cities;
      let shippingPrices: ShippingPrice[] = res.shippingPrices;
      let polygons: GeoMultiPolygon[] = res.polygons;
      this.setState({ partner, cities, shippingPrices, polygons });
    });
  }

  componentDidMount() {
    EventSystem.subscribe(EventSystem.events.open_restaurant_info, ({ partner }) => {
      this.load(partner);
    });
    EventSystem.subscribe(EventSystem.events.googlePlacesScriptLoaded, () => {
      this.setState({ googleAPILoaded: true });
    });

    EventSystem.subscribe(EventSystem.events.contextSystemChanged,
      ({ statusBarSpaceHeight, language, mobileNavbarHeight }) => {
        if (statusBarSpaceHeight !== undefined)
          this.setState({ statusBarSpaceHeight });
        if (mobileNavbarHeight !== undefined)
          this.setState({ mobileNavbarHeight });
        if (language !== undefined)
          this.setState({ language });
      }
    );
  }

  handleBackClick() {
    console.log("handleCloseSidePanel");
    this.setState({ opened: false });
  }

  handleHourTabsClick(hoursActiveTab) {
    this.setState({ hoursActiveTab });
  }

  getPolygons() {
    let polygons: {
      paths: { lat: number, lng: number }[],
      strokeColor: string,
      strokeOpacity: number,
      strokeWeight: number,
      fillColor: string,
      fillOpacity: number
    }[] = [];

    let minLat = 500, minLng = 500, maxLat = -1, maxLng = -1;

    for (let polygon: GeoMultiPolygon of this.state.polygons) {
      let found = false;
      for (let sh of this.state.shippingPrices) {
        if (sh.polyID === polygon.id && sh.active === true && sh.enabled) {
          found = true;
          break;
        }
      }
      if (!found)
        continue;

      if (polygon.allCoordinates === undefined) { //old version of GeoPolygon model
        let c = polygon.coordinates;
        polygon.coordinates = [];
        polygon.coordinates.push(c);
        c = polygon.holes;
        polygon.holes = [];
        polygon.holes.push(c);
      }

      for (let coordinate: Point[] of polygon.coordinates) {
        let coordinates = [];
        for (let co: Point of coordinate) {
          if (co.latitude > maxLat) maxLat = co.latitude;
          if (co.latitude < minLat) minLat = co.latitude;
          if (co.longitude > maxLng) maxLng = co.longitude;
          if (co.longitude < minLng) minLng = co.longitude;

          coordinates.push({ lat: co.latitude, lng: co.longitude });
        }

        let p = {
          paths: coordinates,
          strokeColor: "blue",
          strokeOpacity: 0.85,
          strokeWeight: 0,
          fillColor: "blue",
          fillOpacity: 0.25
        };
        polygons.push(p);
      }
    }

    if (minLat === -1)
      return {
        polygons,
        minLat: googleMapsCenterBP.lat,
        maxLat: googleMapsCenterBP.lat,
        minLng: googleMapsCenterBP.lng,
        maxLng: googleMapsCenterBP.lng
      };
    else
      return { polygons, minLat, maxLat, minLng, maxLng };
  }

  getHours(shippingMethod) {
    if (!this.state.partner || !this.state.partner.hours)
      return [];

    let hourType = -1;
    if (shippingMethod === ShippingMethods.DELIVERY)
      hourType = HourTypes.ORDER_ACCEPT;
    else if (shippingMethod === ShippingMethods.PICKUP)
      hourType = HourTypes.ORDER_ACCEPT_FOR_PICKUP;
    else return [];

    let days = {};

    for (let hourRecord: Hour of this.state.partner.hours[hourType]) {
      if (!days[hourRecord.day])
        days[hourRecord.day] = [];
      days[hourRecord.day].push(hourRecord);
    }

    return days;
  }

  renderHoursTable(openHourDayRecords) {
    return (
      <>
        {Object.keys(openHourDayRecords).map((dayNumber, i) => {
          let dayRecords = openHourDayRecords[dayNumber];
          if (!dayRecords || dayRecords.length <= 0)
            return (
              <OpeningDayRecord key={i}>
                <OpeningDayText>{HoursCalc.getDayName(dayRecords[0].day)}</OpeningDayText>
                <OpeningRecordTextsInDay>
                  <OpeningRecordText>{Language.getName(Names.Closed)}</OpeningRecordText>
                </OpeningRecordTextsInDay>
              </OpeningDayRecord>
            );
          return (
            <OpeningDayRecord key={i}>
              <OpeningDayText>{HoursCalc.getDayName(dayRecords[0].day)}</OpeningDayText>
              <OpeningRecordTextsInDay>
                {dayRecords.map((record, j) => {
                  let oHH = record.openHour.hour > 9 ? record.openHour.hour : "0" + record.openHour.hour;
                  let oMM = record.openHour.minute > 9 ? record.openHour.minute : "0" + record.openHour.minute;
                  let cHH = record.closeHour.hour > 9 ? record.closeHour.hour : "0" + record.closeHour.hour;
                  let cMM = record.closeHour.minute > 9 ? record.closeHour.minute : "0" + record.closeHour.minute;
                  return (
                    <OpeningRecordText key={j}>
                      {oHH}:{oMM} - {cHH}:{cMM}
                    </OpeningRecordText>
                  );
                })}
              </OpeningRecordTextsInDay>
            </OpeningDayRecord>
          );
        })}
      </>
    );
  }

  render() {
    let OpenStatus = HoursCalc.checkOpeningState(HourTypes.OPENING, this.state.partner);
    let AcceptingPickUpOrdersStatus = HoursCalc.checkOpeningState(HourTypes.ORDER_ACCEPT_FOR_PICKUP, this.state.partner);
    let DeliveringStatus = HoursCalc.checkOpeningState(HourTypes.DELIVERY, this.state.partner);
    let AcceptingOrdersStatus = HoursCalc.checkOpeningState(HourTypes.ORDER_ACCEPT, this.state.partner);

    let openingHoursText = HoursCalc.getOpeningHoursText(OpenStatus, AcceptingPickUpOrdersStatus, DeliveringStatus, AcceptingOrdersStatus);

    if (openingHoursText.openStringDL === HourStatuses.NO_DATA)
      openingHoursText.openStringDL = Language.getName(Names.Closed);
    if (openingHoursText.openStringPK === HourStatuses.NO_DATA)
      openingHoursText.openStringPK = Language.getName(Names.Closed);
    if (openingHoursText.openStringDL === HourStatuses.LOADING)
      openingHoursText.openStringDL = "";
    if (openingHoursText.openStringPK === HourStatuses.LOADING)
      openingHoursText.openStringPK = "";

    let polygons = this.getPolygons();

    let deliveryOpeningDayRecords = this.getHours(ShippingMethods.DELIVERY);
    let pickUpOpeningDayRecords = this.getHours(ShippingMethods.PICKUP);

    // noinspection JSUnusedGlobalSymbols
    return (
      <Container opened={this.state.opened} onClick={() => this.handleBackClick()}>
        {this.state.opened &&
        <Wrapper topmargin={this.state.statusBarSpaceHeight} onClick={(e) => e.stopPropagation()}>
          <HeadBar>
            <PaneTitle>{Language.getName(Names.Information)}</PaneTitle>
            <HeadButton onClick={() => this.handleBackClick()}>
              <BsChevronLeft/>
            </HeadButton>
          </HeadBar>
          {this.state.partner && (
            <ScrollWrapper>
              <RestaurantDetailsText>
                <h1>{this.state.partner.name}</h1>
                <p>{this.state.partner.details}</p>
              </RestaurantDetailsText>
              <OpeningStatuses>
                <h1>{Language.getName(Names.OrderAcceptHours)}:</h1>
                <div>
                  <span>
                    <FaShippingFast/>
                    {Language.getName(Names.Delivery)}
                  </span>
                  <span>{openingHoursText?.openStringDL}</span>
                </div>
                <div>
                  <span>
                    <FaStore/>
                    {Language.getName(Names.ForTakeaway)}
                  </span>
                  {this.state.partner.enablePersonalOrder &&
                  <span>{openingHoursText?.openStringPK}</span>
                  }
                  {!this.state.partner.enablePersonalOrder &&
                  <span> - </span>
                  }
                </div>
              </OpeningStatuses>
              <HourTabsContainer>
                <HourTab onClick={() => this.handleHourTabsClick(0)} active={this.state.hoursActiveTab === 0}>
                  {Language.getName(Names.Delivery)}
                </HourTab>
                <HourTab onClick={() => this.handleHourTabsClick(1)} active={this.state.hoursActiveTab === 1}>
                  {Language.getName(Names.ForTakeaway)}
                </HourTab>
              </HourTabsContainer>
              <Swiper
                grabCursor
                autoHeight={true}
                initialSlide={this.state.hoursActiveTab}
                pagination={{
                  clickable: true,
                  renderBullet: (index, className) => {
                    className += " slider-bullet";
                    return `<span class="${className}"></span>`;
                  }
                }}
                spaceBetween={10}
                slidesPerView={1}
                onSlideChange={(s) => this.setState({ hoursActiveTab: s.activeIndex })}
                onSwiper={(s) => (this.swiperRef = s)}
              >
                <SwiperSlide>
                  <HoursPane>
                    {this.state.partner.enableDeliveryOrder && this.renderHoursTable(deliveryOpeningDayRecords)}
                    {!this.state.partner.enableDeliveryOrder &&
                    <p>{Language.getName(Names.DeliveryNotAvailableText)}</p>
                    }
                  </HoursPane>
                </SwiperSlide>
                <SwiperSlide>
                  <HoursPane>
                    {this.state.partner.enablePersonalOrder && this.renderHoursTable(pickUpOpeningDayRecords)}
                    {!this.state.partner.enablePersonalOrder &&
                    <p>{Language.getName(Names.TakeawayNotAvailableText)}</p>
                    }
                  </HoursPane>
                </SwiperSlide>
              </Swiper>
              <ShippingAddressesContainer>
                <h1>{Language.getName(Names.ShippingPlaces)}</h1>
                {!this.state.partner.enableDeliveryOrder &&
                <p>{Language.getName(Names.DeliveryNotAvailableText)}</p>
                }
                {this.state.partner.enableDeliveryOrder && this.state.googleAPILoaded && (
                  <MyMapComponent
                    loadingElement={<div style={{ height: `100%` }}/>}
                    containerElement={<MapContainer/>}
                    defaultZoom={11}
                    defaultCenter={{
                      lat: (polygons.minLat + polygons.maxLat) / 2,
                      lng: (polygons.minLng + polygons.maxLng) / 2
                    }}
                    mapElement={<div style={{ height: `100%` }}/>}
                  >
                    {polygons.polygons.map((polyProps, i) => (
                      <Polygon key={i} options={polyProps}/>
                    ))}
                  </MyMapComponent>
                )}
              </ShippingAddressesContainer>
              <ContactWrapper>
                <h1>{Language.getName(Names.Contact)}</h1>
                <p>
                  {Language.getName(Names.AllergyContactDescription)}
                </p>
                <div>
                  <span>{Language.getName(Names.MobileCall)}</span>
                  <span>
                    <a href={"tel:" + this.state.partner.publicTel}>
                      <FiPhoneCall/>
                      {this.state.partner.publicTel}
                    </a>
                  </span>
                </div>
                {this.state.partner?.publicEmail && this.state.partner.publicEmail.length > 0 &&
                <div>
                  <span>{Language.getName(Names.EmailAddress)}</span>
                  <span>
                    <a href={"mailto:" + this.state.partner.publicEmail}>
                      <IoIosSend/>
                      {this.state.partner.publicEmail}
                    </a>
                  </span>
                </div>
                }
              </ContactWrapper>
            </ScrollWrapper>
          )}
        </Wrapper>
        }
      </Container>
    );
  }
}

import * as React from "react";

function BlundeeSearchIconEmpty(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 19 19"
      fill={"none"}
      stroke={"currentColor"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M8.64876 16.2975C12.8731 16.2975 16.2975 12.8731 16.2975 8.64876C16.2975 4.42447 12.8731 1 8.64876 1C4.42447 1 1 4.42447 1 8.64876C1 12.8731 4.42447 16.2975 8.64876 16.2975Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M15.3821 16.8838C15.8326 18.2436 16.8609 18.3795 17.6513 17.1897C18.3737 16.1019 17.8977 15.2095 16.5889 15.2095C15.6201 15.201 15.0762 15.9574 15.3821 16.8838Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
}

export default BlundeeSearchIconEmpty;

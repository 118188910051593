import Config from "./utils/Config";
import "./polyfills";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

// Import styles
import "swiper/css";
import "swiper/css/pagination";
import "react-phone-input-2/lib/style.css";
import "./scss/index.scss";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

import SwiperCore, { A11y, Mousewheel, Navigation, Pagination, Scrollbar } from "swiper";
import { upgradeLocalstorageIfNeeded } from "./utils/VersionManager";
import ContextSystem from "./utils/ContextSystem";
import EventSystem from "./utils/EventSystem";
import TagManager from "react-gtm-module";
import DatePolyFill from "./utils/DatePolyfill";
import VersionAPI from "./utils/api/VersionAPI";
import { NavigationBar } from "@ionic-native/navigation-bar";
import { Capacitor } from "@capacitor/core";
import { AppTrackingStatusResponse, AppTrackingTransparency } from "capacitor-plugin-app-tracking-transparency";

let initialized = false;
let googlePlacesAPILoaded = false;

DatePolyFill();

// noinspection JSUnusedGlobalSymbols
Array.prototype.peek = function () {
  if (this.length <= 0)
    return null;
  else
    return this[this.length - 1];
};

Array.prototype.remove = function () {
  let what,
    a = arguments,
    L = a.length,
    ax;
  while (L && this.length) {
    what = a[--L];
    while ((ax = this.indexOf(what)) !== -1) {
      this.splice(ax, 1);
    }
  }
  return this;
};

async function checkVersion() {
  await VersionAPI.checkVersion((res) => {
    if (res.error !== 0)
      return;
    if (Config.version < res.appVersion.version && res.appVersion.hasToUpdate) {
      // noinspection JSDeprecatedSymbols
      // noinspection JSUnresolvedFunction
      window.location.reload(true);
      return;
    }

    Config.configurations = res.configurations;
  });
}

function initialize() {
  Sentry.init({
    dsn: "https://fb797ae4bfd7486ab5abab3a73ea0376@o902080.ingest.sentry.io/5842533",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0
  });

  // window.screen.orientation.lock("natural").then(r => console.log(r));
  SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Mousewheel]);

  if (initialized)
    return;

  initialized = true;

  upgradeLocalstorageIfNeeded();
  loadGoogleApisScript();
  ContextSystem.init();
  initTagManager();
  initFacebookSdk().then(() => console.log("fb sdk loaded"));
}

function loadGoogleApisScript() {
  // noinspection JSUnresolvedReference
  if (googlePlacesAPILoaded || (window.google && window.google.maps)) return;
  googlePlacesAPILoaded = true;
  let src = "https://maps.googleapis.com/maps/api/js?key=" + Config.googleApiKey + "&libraries=places&language=HU";
  const script = document.createElement("script");
  script.src = src;
  script.async = true;
  script.onload = () => EventSystem.publish(EventSystem.events.googlePlacesScriptLoaded);
  document.head.appendChild(script);
}

function initTagManager() {
  // noinspection JSUnresolvedFunction
  TagManager.initialize({
    gtmId: Config.tagManagerID,
    events: {
      sendUserInfo2: "userInfo22"
    }
  });
}

function initFacebookSdk() {
  return new Promise(() => {
    // wait for facebook sdk to initialize before starting the React app
    window.fbAsyncInit = function () {
      // noinspection JSUnresolvedVariable
      window.FB.init({
        appId: Config.fbAppID,
        cookie: true,
        xfbml: true,
        version: "v11.0"
      });
    };

    // load facebook sdk script
    (function (d, s, id) {
      let js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, "script", "facebook-jssdk"));
  });
}

function initStatusBarHeight() {
  let ios = Config.ios;
  let android = Config.android;
  let mobile = Config.mobile;

  let safeAreasContainer: HTMLDivElement = document.getElementById("safeareas");
  if (!safeAreasContainer)
    return;

  let sat = Math.max(parseInt(window?.getComputedStyle(safeAreasContainer, null)?.getPropertyValue("padding-top") ?? "0"), 0);
  //let sar = Math.max(parseInt(window?.getComputedStyle(safeAreasContainer, null)?.getPropertyValue("padding-right") ?? "0"), 0);
  let sab = Math.max(parseInt(window?.getComputedStyle(safeAreasContainer, null)?.getPropertyValue("padding-bottom") ?? "0"), 0);
  //let sal = Math.max(parseInt(window?.getComputedStyle(safeAreasContainer, null)?.getPropertyValue("padding-left") ?? "0"), 0);
  // console.log("--stop", sat);
  // console.log("--sright", sar);
  // console.log("--sbottom", sab);
  // console.log("--sleft", sal);

  //let sat: number = Math.max(parseInt(getComputedStyle(document.documentElement).getPropertyValue("--sat")), 0);
  //let sar: number = Math.max(parseInt(getComputedStyle(document.documentElement).getPropertyValue("--sar")), 0);
  //let sab: number = Math.max(parseInt(getComputedStyle(document.documentElement).getPropertyValue("--sab")), 0);
  //let sal: number = Math.max(parseInt(getComputedStyle(document.documentElement).getPropertyValue("--sal")), 0);

  /*
  if (ios) {
    sat = safeAreaInsets.top;
    sar = safeAreaInsets.right;
    sab = safeAreaInsets.bottom;
    sal = safeAreaInsets.left;
    console.log("sasuppor:", safeAreaInsets.support);
  }
   */

  //sat += 20;
  sab += Config.mobileNavbarHeightDefault;

  // console.log("sattt values:", sat, sar, sab, sal);

  if (mobile) {
    ContextSystem.setStatusBarSpaceHeight(sat);
    if (ios) {
      // iOS only
      // window.addEventListener("statusTap", function() {
      //   console.log("statusbar tapped");
      // });
      ContextSystem.setMobileNavbarHeight(sab);

      /*

      Device.getInfo().then((value: DeviceInfo) => {
        console.log("devicemodel:", value.model);
        console.log("devicemanufact.:", value.manufacturer);
      }, (reason) => {
        console.log('device info error', reason);
      });

      (async () => {
        try {
          const info = await Device.getInfo() // ... ok
          console.log("devicemodel:", info.model);
          console.log("devicemanufact.:", info.manufacturer);
        } catch (e) {
          console.log(e);
        }
      })();
       */

      try {
        // noinspection JSDeprecatedSymbols
        Capacitor.Plugins.IosSwipeBack.enable();
      } catch (e) {
        //console.log(e);
        //no problem
      }
    }

    if (android) {
      // Display content under transparent status bar (Android only)
      // StatusBar.setOverlaysWebView({ overlay: true });
    }
  } else { //neither ios nor android --> web
    ContextSystem.setStatusBarSpaceHeight(0);
  }
}

function initAndroid() {
  if (!Config.android)
    return;

  NavigationBar.hideNavigationBar().then(() => {
  });
}

async function getStatus(): Promise<AppTrackingStatusResponse> {
  // { status: 'authorized' } for example
  return await AppTrackingTransparency.getStatus();
}

async function requestPermission(): Promise<AppTrackingStatusResponse> {
  const response = await AppTrackingTransparency.requestPermission();

  console.log(response);
  // { status: 'authorized' } for example

  return response;
}

function initIos() {
  if (!Config.ios)
    return;

  setTimeout(() => {
    getStatus().then((res: AppTrackingStatusResponse) => {
      if (!(res.status === "authorized" || res.status === "restricted")) {
        requestPermission().then(() => {
          //cool
        }).catch(reason => {
          console.log(reason);
        });
      }
    }).catch(reason => {
      console.log(reason);
    });
  }, 3000);
}

/* You should call this when the render is completed*/
export function initAppDesigns() {
  initStatusBarHeight();
  initAndroid();
  initialStatusBarHeightSetter();
  initIos();
}

// noinspection JSUnusedLocalSymbols
function setCSSProperty(key, value) {
  document.documentElement.style.setProperty(key, value);
}

export function getCSSProperty(key: string): string{
  return getComputedStyle(document.documentElement).getPropertyValue(key);
}

let statusSetTryCounter = 0;

function initialStatusBarHeightSetter() {
  statusSetTryCounter++;
  if (statusSetTryCounter > 10)
    return;

  setTimeout(() => {
    initialStatusBarHeightSetter();
  }, 1000);

  initStatusBarHeight();
}

checkVersion().then(() => initialize());
import React, { Component } from "react";
import styled, { css } from "styled-components";
import ContextSystem from "../utils/ContextSystem";
import EventSystem from "../utils/EventSystem";
import SavedAddressPicker from "../components/addresspicker/SavedAddressPicker";
import { FaInfo } from "react-icons/fa";
import { IoIosMore } from "react-icons/io";
import { Order, OrderState } from "../model/Order";
import { Profile } from "../model/Profile";
import { Product } from "../model/Product";
import Language, { Names } from "../utils/Language";
import OrderDetails from "../components/profile/OrderDetails";
import { OrdersAPI } from "../utils/api/OrdersAPI";
import HeadBarContent, { FixedHeadBar, FixedHeadBarContainer, HeadBar } from "../components/HeadBar";
import { languages, ReactFlagsSelect } from "./StartPage";
import { BlundeeButton } from "../components/Form";
import type { Shop } from "../model/Shop";

const PageContainer = styled.div`
  width: 100%;
  overflow-y: auto;
  position: relative;

  @media screen and (min-width: 801px) {
    &::-webkit-scrollbar-thumb {
      background-color: var(--blundee_scroll_thumb_color);
      border-radius: 100px;
    }

    &::-webkit-scrollbar {
      width: 15px;
      background-color: transparent;
      //border-left: 1px solid rgb(20, 20, 20);
    }
  }
`;


const Wrapper = styled.div`
  //display: flex;
  //flex-direction: column;
  //justify-content: flex-start;
  //align-items: flex-start;
  padding: 12px 24px 0 24px;
  flex-wrap: nowrap;
  overflow-y: auto;
  position: relative;

  width: 100%;
  min-height: 50vh;

  text-align: center;

  & > h1 {
    font-family: var(--blundee_font_medium);
    //letter-spacing: 0.3rem;
    font-size: 30pt;
    font-weight: 700;

    width: 100%;
    text-align: left;
    color: rgb(240, 240, 240);

    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
  }

  h3 {
    font-family: var(--blundee_font_medium);
    font-weight: bold;
    font-size: 13pt;
    margin: 24px 0 6px 0;
  }

  @media screen and (max-width: 600px) {
    padding: 12px 12px 0 12px;
    & > h1 {
      font-size: 25pt;
      padding: 0;
    }
  }

  ${({registered}) => registered === false && css`
    height: calc(100vh - 60px);
  `}
`;

const LoginOrRegisterWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

const LanguageSelectorWrapper = styled.div`
  margin: 0 0 24px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--blundee_color_normal);

  & > h3 {
    margin: 0 0 12px 0;
  }
`;

const ProfileSimpleDetails = styled.div`
  background-color: var(--blundee_background);
  box-shadow: var(--blundee_card_shadow);
  border-radius: 10px;
  width: 100%;
  padding: 12px 100px 15px 10px;
  margin: 5px 0 5px 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: var(--blundee_color_normal);
  transition: filter 100ms ease-in-out, background-color 100ms ease-in-out;
  user-select: none;

  & > h1 {
    font-family: var(--blundee_font_medium);
    font-size: 20pt;
    font-weight: bold;
    margin: 5px 0;

    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
    margin: 6px auto;
    border-radius: 6px;

    & > h1 {
      font-size: 16pt;
    }
  }

  @media screen and (min-width: 801px) {
    &:hover {
      filter: brightness(97%);
      cursor: pointer;
    }
  }

  &:active {
    filter: brightness(95%);
    cursor: pointer;
  }
`;

const SimplePhoneNumber = styled.div`
  font-family: var(--blundee_font_medium);
  font-style: italic;
  font-size: 13pt;
  @media screen and (max-width: 800px) {
    font-size: 10pt;
  }
`;

const SimpleEmail = styled.div`
  font-family: var(--blundee_font_medium);
  font-size: 13pt;
  @media screen and (max-width: 800px) {
    font-size: 10pt;
  }
`;

const SavedAddressesWrapper = styled.div`
  width: 80%;
  margin: 5px 0 5px 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @media screen and (max-width: 800px) {
    width: 100%;
    margin: 6px auto;
  }
`;

export const Button = styled.button`
  width: fit-content;
  padding: 3px 0;
  background-color: transparent;
  border: none;
  outline: none;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 45px;

  font-size: 17px;
  font-family: var(--blundee_font_medium);
  color: var(--blundee_color_normal);

  transition: background-color 150ms ease-in-out, color 150ms ease-in-out, font-size 150ms ease-in-out;

  &:hover {
    cursor: pointer;
    color: white;
  }

  &:active {
    cursor: pointer;
    color: rgb(120, 120, 120);
  }

  text-decoration: none;

  &:hover,
  &:active,
  &:visited {
    text-decoration: none;
  }

  ${({selected}) => selected === true && css`
    color: white;
    font-weight: bold;
  `}
  ${({padding}) => padding && css`
    padding: ${padding};
  `}
  & > svg {
    font-size: 19pt;
    margin-right: 5px;
  }

  & > span {
    margin-left: 12px;
  }

  @media screen and (max-width: 800px) {
    & > span {
      font-size: 9pt;
      margin-left: 0;
      margin-top: 2px;
    }
  }
`;

const LoadMoreButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & > svg {
    font-size: 17pt;
    margin: 0;
  }

  & > p {
    font-size: 10pt;
    margin: -4px 0 0 0;
    padding: 0;
  }
`;

const OrdersWrapper = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 10px 0;
  padding: 15px 15px 35px 15px;

  @media screen and (max-width: 800px) {
    width: 100%;
    padding: 10px 0 30px 0;
  }
`;

const LastOrderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  & > p {
    margin: 0;
  }

  @media screen and (max-width: 800px) {
    align-items: center;
  }
`;

export default class ProfilePage extends Component {
  eventIDs = [];

  state: {
    profile: Profile,
    orders: Order[],
    products: Product[],
    selectedScreen: number,
    mobile: boolean,
    ordersOpened: { [keys: string]: boolean },
    language: number,
    lastOrder: Order | undefined,
    showFixedHeadBar: boolean,
    statusBarSpaceHeight: number,
  } = {
    profile: ContextSystem.profile,
    orders: ContextSystem.orders,
    products: ContextSystem.products,
    selectedScreen: 0,
    mobile: ContextSystem.mobileLayout,
    ordersOpened: {},
    language: ContextSystem.language,
    lastOrder: ContextSystem.getLastLocalOrder(),
    showFixedHeadBar: false,
    statusBarSpaceHeight: ContextSystem.statusBarSpaceHeight
  };

  componentWillUnmount() {
    for (let eventID of this.eventIDs)
      EventSystem.unsubscribe(eventID);
  }

  componentDidMount() {
    this.eventIDs = [];

    let lastOrder = ContextSystem.getLastLocalOrder();
    if (lastOrder) {
      lastOrder = ContextSystem.orders.find(o => o.number === lastOrder.number) ?? lastOrder;
      OrdersAPI.fixDates(lastOrder);
      if (ContextSystem.orders.find(o => o.number === lastOrder.number)) {
        lastOrder = ContextSystem.orders.find(o => o.number === lastOrder.number);
      }

      let products: Product[] = JSON.parse(localStorage.getItem("products"));
      ContextSystem.mergeOrders([lastOrder], products);

      this.setState({lastOrder});
    }

    let eid;
    eid = EventSystem.subscribe(EventSystem.events.authentication_changed, () => {
      this.setState({profile: ContextSystem.profile});
      // don't waste network you little shit
      // this.load();
    });
    this.eventIDs.push(eid);

    eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged,
      ({profile, language, mobileLayout, orders, products, statusBarSpaceHeight}) => {
        if (profile !== undefined)
          this.setState({profile});
        if (language !== undefined)
          this.setState({language});
        if (mobileLayout !== undefined)
          this.setState({mobile: ContextSystem.mobileLayout});
        if (statusBarSpaceHeight !== undefined)
          this.setState({statusBarSpaceHeight: ContextSystem.statusBarSpaceHeight});
        if (orders !== undefined) {
          if (ContextSystem.loggedIn) {
            let ordersOpened: { [keys: string]: boolean } = {...this.state.ordersOpened};
            for (let order: Order of orders) {
              if (ordersOpened[order.number] === undefined)
                ordersOpened[order.number] = false;
            }
            this.setState({orders, ordersOpened});
          } else {
            let lastOrder: Order = orders.find(o => o.number === this.state.lastOrder.number);
            if (lastOrder) {
              this.setState({lastOrder});
            }
          }
        }
        if (products !== undefined)
          this.setState({products});
      });
    this.eventIDs.push(eid);
  }

  handleDetailsClick() {
    EventSystem.publish(EventSystem.events.open_profile_details_popup, {showLogout: true});
  }

  redirect(path) {
    EventSystem.publish(EventSystem.events.redirectIntent, {redirectPath: path});
  }

  handleWrapperScroll(e) {
    let scrollPosition: number = e.target.scrollTop;
    let showFixedHeadBar: boolean = scrollPosition >= 60;
    if (showFixedHeadBar !== this.state.showFixedHeadBar)
      this.setState({showFixedHeadBar});
  }

  handleBackClick() {
    EventSystem.publish(EventSystem.events.redirectIntent, {
      redirectPath: null,
      queryParams: null,
      goBack: true
    });
  }

  loginClicked() {
    EventSystem.publish(EventSystem.events.open_start_page, {});
  }

  render() {
    let shopForLastOrders: Shop = undefined;
    if (this.state.lastOrder)
      shopForLastOrders = ContextSystem.shops?.find(s => s.id === this.state.lastOrder?.partnerID);

    return (
      <>
        <FixedHeadBar top={this.state.statusBarSpaceHeight} show={this.state.showFixedHeadBar}>
          <FixedHeadBarContainer show={this.state.showFixedHeadBar}>
            <HeadBarContent title={Language.getName(Names.Account)} showTitle={true} mobile={this.state.mobile}
                            handleBackClick={() => this.handleBackClick()}/>
          </FixedHeadBarContainer>
        </FixedHeadBar>
        <PageContainer onScroll={(e) => this.handleWrapperScroll(e)}>
          <HeadBar>
            <HeadBarContent title={Language.getName(Names.Account)} showTitle={true}
                            mobile={this.state.mobile}
                            handleBackClick={() => this.handleBackClick()}/>
          </HeadBar>
          <Wrapper registered={this.state.profile !== undefined}>
            <LanguageSelectorWrapper>
              <h3>{Language.getName(Names.SelectYourLanguage)}</h3>
              <ReactFlagsSelect
                selected={languages[this.state.language].languageCode}
                countries={languages.map(l => l.languageCode)}
                customLabels={(() => {
                  let o = {};
                  languages.forEach(l => o[l.languageCode] = l.label);
                  return o;
                })()}
                onSelect={code => ContextSystem.setLanguage(languages.find(l => l.languageCode === code).id)}
              />
            </LanguageSelectorWrapper>
            {!this.state.profile && (
              <>
                {this.state.lastOrder && [OrderState.NEW, OrderState.PREPARING, OrderState.READY, OrderState.CONFIRMED, OrderState.WAITING_FOR_ONLINE_PAYMENT].includes(this.state.lastOrder?.lastState?.status) &&
                  <LastOrderWrapper>
                    <p>{Language.getName(Names.LastOrder)}</p>
                    <OrderDetails tableReservationUI={false} order={this.state.lastOrder} openedDefault={true}
                                  shop={shopForLastOrders}/>
                  </LastOrderWrapper>
                }

                <LoginOrRegisterWrapper>
                  <BlundeeButton onClick={() => this.loginClicked()}>
                    {Language.getName(Names.SignIn)}
                  </BlundeeButton>
                </LoginOrRegisterWrapper>
              </>
            )}
            {this.state.profile && (
              <>
                <ProfileSimpleDetails onClick={() => this.handleDetailsClick()}>
                  <h1>
                    {this.state.profile.lastName} {this.state.profile.firstName}
                  </h1>
                  <SimpleEmail>{this.state.profile.email}</SimpleEmail>
                  <SimplePhoneNumber>{this.state.profile.tel}</SimplePhoneNumber>
                </ProfileSimpleDetails>
                <SavedAddressesWrapper>
                  <h3>{Language.getName(Names.SavedAddresses)}</h3>
                  <SavedAddressPicker modifyOnClick={true} showModifyIcons={false}/>
                </SavedAddressesWrapper>
                {this.state.mobile && (
                  <>
                    <Button onClick={() => this.redirect("/csatlakozas")}>
                      <FaInfo/>
                      <span>{Language.getName(Names.ForRestaurants)}</span>
                    </Button>
                    <Button onClick={() => this.redirect("/aszf")}>
                      <FaInfo/>
                      <span>{Language.getName(Names.Information)}</span>
                    </Button>
                  </>
                )}
                <Button onClick={() => this.redirect("/mi-ez-a-blundee")}>
                  <FaInfo/>
                  <span>{Language.getName(Names.WhatIsThisApp)}</span>
                </Button>
                <OrdersWrapper>
                  <h3>{Language.getName(Names.MyOrders)}</h3>
                  {this.state.orders.map((order: Order, i) => {
                    let shop: Shop = ContextSystem.shops?.find(s => s.id === order?.partnerID);
                    return (
                      <OrderDetails tableReservationUI={false} key={i} order={order} openedDefault={false} shop={shop}/>
                    );
                  })}
                  <LoadMoreButtonWrapper onClick={() => ContextSystem.loadMoreOrders(3)}>
                    <IoIosMore/>
                    <p>{Language.getName(Names.More)}</p>
                  </LoadMoreButtonWrapper>
                </OrdersWrapper>
              </>
            )}
          </Wrapper>
        </PageContainer>
      </>
    );
  }
}

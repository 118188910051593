import APICaller from "../APICaller";
import type { MessageBase } from "../ws/MessageHandler";
import { City } from "../../model/Address";

export type GetCitiesType = MessageBase & { cities: City[], zipcodes: ZipCode[] };

function getCities(cb: (res: GetCitiesType)=>{}) {
  APICaller.ownFetch(false, "POST", "/city", {}, (data) => {
    let res = JSON.parse(data);
    if (cb)
      cb(res);
  });
}

export default { getCities };

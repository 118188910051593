import React, { Component } from "react";
import EventSystem from "../utils/EventSystem";
import ContextSystem from "../utils/ContextSystem";

class Analytics extends Component {
  componentDidMount() {
    EventSystem.subscribe(EventSystem.events.cartCleared, () => {
      Analytics.triggerGTMEvent("cart_cleared");
    });
    EventSystem.subscribe(EventSystem.events.cartUpdated, (cart) => {
      Analytics.triggerGTMEvent("cartUpdated", { cart });
    });
    EventSystem.subscribe(EventSystem.events.openOrderFinishing,
      ({ products, shippingMethod, selectedCity, cities, partner }) => {
        Analytics.triggerGTMEvent("open_checkout", {
          products, shippingMethod, selectedCity, partner,
          selectedAddress: ContextSystem.selectedAddress
        });
      }
    );
    EventSystem.subscribe(EventSystem.events.popup_product, ({ product }) => {
      Analytics.triggerGTMEvent("popup_product", { product });
    });
    EventSystem.subscribe(EventSystem.events.city_selected, ({ selected }) => {
      let eventName = selected ? "city_selected" : "city_unselect";
      Analytics.triggerGTMEvent(eventName, { selectedCity: selected });
    });
    EventSystem.subscribe(EventSystem.events.authentication_changed, (loggedIn) => {
      let eventName: string = loggedIn ? "logged_in" : "logged_out";
      Analytics.triggerGTMEvent(eventName);
    });
    EventSystem.subscribe(EventSystem.events.addressSelected, ({ address }) => {
      let eventName = address ? "address_unselected" : "address_selected";
      Analytics.triggerGTMEvent(eventName, { address });
    });
    EventSystem.subscribe(EventSystem.events.selectGlobalCategory, ({ globalCategory }) => {
      Analytics.triggerGTMEvent("selectGlobalCategory", { globalCategory });
    });
    EventSystem.subscribe(EventSystem.events.order_placed, ({ paymentMethod, profileID, orderNumber }) => {
      Analytics.triggerGTMEvent("order_placed", { paymentMethod, profileID, orderNumber });
    });
    EventSystem.subscribe(EventSystem.events.online_payment_successful, ({ profileID, orderNumber }) => {
      Analytics.triggerGTMEvent("online_payment_successful", { profileID, orderNumber });
    });
  }

  static triggerGTMEvent(eventName: string, data: {}) {
    if (!window.dataLayer || !window.dataLayer.push)
      return;

    if (!data)
      data = { empty: true };

    window.dataLayer.push({
      "event": eventName,
      data
    });
  }

  render() {
    return <React.Fragment/>;
  }
}

export default Analytics;

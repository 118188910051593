import APICaller from "../APICaller";
import { AppVersion } from "../../model/AppVersion";
import type { MessageBase } from "../ws/MessageHandler";
import type { Configuration } from "../../model/Configuration";

type VersionResponse = MessageBase & { appVersion: AppVersion, configurations: Configuration[] };

async function checkVersion(cb: (v: VersionResponse)=>{}) {
  return APICaller.ownFetch(true, "POST", "/version", {}, (data) => {
    let res: VersionResponse = JSON.parse(data);
    if (cb) {
      if (res.configurations) {
        try {
          //backend might send the value as a json string, if not, keep it as string
          res.configurations.forEach(c => {
            try {
              c.value = JSON.parse(c.value);
            } catch {
              //ignore
            }
          });
        } catch {
          //ignore
        }
      }
      cb(res);
    }
  });
}

export default {checkVersion};

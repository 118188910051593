import React, { Component } from "react";
import styled from "styled-components";
import EventSystem from "../../utils/EventSystem";
import HeadBarContent, { FixedHeadBar, FixedHeadBarContainer, HeadBar } from "../../components/HeadBar";
import ContextSystem from "../../utils/ContextSystem";

const Wrapper = styled.div`
  width: 100%;
  overflow-y: auto;
  overflow-x: auto;
  position: relative;
  
  @media screen and (min-width: 801px) {
    &::-webkit-scrollbar-thumb {
      background-color: var(--blundee_scroll_thumb_color);
      border-radius: 100px;
    }
    &::-webkit-scrollbar {
      width: 15px;
      background-color: transparent;
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  width: 100%;
  //overflow-y: auto;
  padding: 12px;

  & > h1 {
    font-family: var(--blundee_font_medium);
    color: rgb(240, 240, 240);
    font-size: 30pt;
    font-weight: 600;

    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    margin-top: 0;
  }

  & > h2 {
    font-size: 15pt;
    cursor: pointer;
    margin: 0;
    margin-bottom: 10pt;
  }

  @media screen and (max-width: 800px) {
    //padding: 10pt;

    & > h1 {
      font-size: 25pt;
      padding: 0;
      margin-left: -6pt;
      //margin-top: 45pt;
    }

    & > h2 {
      padding: 0;
    }
  }
`;

export default class AboutPagesFrame extends Component {
  state: {
    showFixedHeadBar: boolean,
    mobile: boolean,
    statusBarSpaceHeight: number,
  } = {
    showFixedHeadBar: false,
    mobile: false,
    statusBarSpaceHeight: ContextSystem.statusBarSpaceHeight,
  }

  handleWrapperScroll(e) {
    let scrollPosition = e.target.scrollTop;
    let showFixedHeadBar = scrollPosition >= 60;
    if (showFixedHeadBar !== this.state.showFixedHeadBar)
      this.setState({ showFixedHeadBar });
  }

  handleBackClick() {
    EventSystem.publish(EventSystem.events.redirectIntent, {
      redirectPath: null,
      queryParams: null,
      goBack: true,
    });
  }

  componentDidMount() {
    EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({statusBarSpaceHeight}) => {
      if (statusBarSpaceHeight !== undefined)
        this.setState({statusBarSpaceHeight});
    });
  }

  render() {
    let title = this.props.hideTitle ? <></> : <h1>{this.props.title}</h1>;
    return (
      <>
        <FixedHeadBar top={this.state.statusBarSpaceHeight} show={this.state.showFixedHeadBar}>
          <FixedHeadBarContainer show={this.state.showFixedHeadBar}>
            <HeadBarContent title={this.props.title} showTitle={true} mobile={this.state.mobile} handleBackClick={() => this.handleBackClick()} />
          </FixedHeadBarContainer>
        </FixedHeadBar>
        <Wrapper onScroll={(e) => this.handleWrapperScroll(e)}>
          <HeadBar>
            <HeadBarContent title={this.props.title} showTitle={false} mobile={this.state.mobile} handleBackClick={() => this.handleBackClick()} />
          </HeadBar>
          <ContentWrapper>
            {title}
            {this.props.children}
          </ContentWrapper>
        </Wrapper>
      </>
    );
  }
}

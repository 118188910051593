import React, { Component } from "react";
import Language from "../utils/Language";
import Config, { ConfigKeys } from "../utils/Config";
import { TranslatableString } from "./Product";
import styled from "styled-components";
import { languages, ReactFlagsSelect } from "../pages/StartPage";
import ContextSystem from "../utils/ContextSystem";
import EventSystem from "../utils/EventSystem";

const Title = styled.h1`
  font-family: var(--blundee_font_medium);
  //letter-spacing: 0.3rem;
  font-size: 30pt;
  font-weight: 700;

  @media screen and (max-width: 1000px) {
    font-size: 25pt;
  }
`;


type WhatIsBlundeeStructureType = {
  title: TranslatableString,
  text: TranslatableString,
  list: TranslatableString[],
};
export default class WhatIsBlundeeComponent extends Component {
  eventIDs: number[] = []
  state: {
    language: number;
  } = {
    language: ContextSystem.language
  }

  componentDidMount() {
    let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged,
      ({language}) => {
        if (language !== undefined)
          this.setState({language});
      });
    this.eventIDs.push(eid);
  }

  render() {
    let structure: WhatIsBlundeeStructureType[] = Config.getValue(ConfigKeys.what_is_blundee_page_structure);

    return (
      <>
        <ReactFlagsSelect
          selected={languages[this.state.language].languageCode}
          countries={languages.map(l => l.languageCode)}
          customLabels={(() => {
            let o = {};
            languages.forEach(l => o[l.languageCode] = l.label);
            return o;
          })()}
          onSelect={code => ContextSystem.setLanguage(languages.find(l => l.languageCode === code).id)}
        />
        <Title>{Language.getName(Config.getValue(ConfigKeys.what_is_blundee_page_title))}</Title>
        {structure && structure.length > 0 && structure[0].title && structure.map((s, i) => (
          <React.Fragment key={i}>
            <h2>{TranslatableString.get(s.title)}</h2>
            {s.text && <p>{TranslatableString.get(s.text)}</p>}
            {s.list && (
              <ul>
                {s.list.map((item, i) => <li key={i}>{TranslatableString.get(item)}</li>)}
              </ul>
            )}
          </React.Fragment>
        ))}
      </>
    )
  }
}
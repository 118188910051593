import APICaller from "../APICaller";
import { DynamicOffer } from "../../model/nonpersistent/DynamicOffer";
import { Shop } from "../../model/Shop";

function getRecommendation(quite: boolean, selectedAddressID: number, cb: ({ error: number, type: number, respond: DynamicOffer[], partnerProfiles: Shop[] })=>{}) {
  let data = {};
  if (selectedAddressID !== undefined)
    data.selectedAddressID = selectedAddressID;

  APICaller.ownFetch(quite, "POST", "/home", data, (data) => {
    let res = JSON.parse(data);
    if (cb)
      cb(res);
  });
}

export default { getRecommendation };

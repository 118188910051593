import ErrorMessage from "../api/ErrorMessages";
import WSConnection from "./WSConnection";
import MessageType from "./MessageType";

function sendOk() {
  WSConnection.getInstance().sendMessage(MessageType.OK, {});
}

function sendError(error: ErrorMessage) {
  WSConnection.getInstance().sendMessage(MessageType.ERROR, {error});
}

function sendPong() {
  let socket = WSConnection.getInstance();
  socket.sendMessage(MessageType.PONG, {
    // this is not needed here, only for the courier
    // geo_lat: ContextSystem.currentPosition.latitude.toString(),
    // geo_lon: ContextSystem.currentPosition.longitude.toString(),
  });
}

function sendRegisterForTableReservation(tableReservationID: number = -1) {
  if(tableReservationID < 0)
    return;

  let socket = WSConnection.getInstance();
  socket.sendMessage(MessageType.REGISTER_TABLE_RESERVATION_UPDATE, {
    tableReservationID
  });
}

// function acceptOrRefuseJobOffer(jobID: number = -1, accepted: boolean = true, comment?: string) {
//   WSConnection.getInstance().sendMessage(MessageType.JOB_OFFER, {jobID, accepted, comment});
// }

export const MessageSender = {
  sendOk,
  sendError,
  sendPong,
  sendRegisterForTableReservation
};

import React, { Component } from "react";
import Config, { ConfigKeys } from "../utils/Config";
import styled, { css } from "styled-components";
import ContextSystem from "../utils/ContextSystem";
import * as HoursCalc from "../utils/HoursCalc";
import { OverlayWrapperBase } from "./ProductComponent";
import { Shop } from "../model/Shop";
import { HourStatuses, HourTypes } from "../model/Hour";
import Language, { Names } from "../utils/Language";
import EventSystem from "../utils/EventSystem";
import { PaymentMethodTypes } from "../model/PaymentMethodSetting";
import type { ShopLabel } from "../utils/Config";

const Wrapper = styled.div`
  height: fit-content;
  margin: 0;
  width: 250px;

  background-image: linear-gradient(45deg, var(--blundee_background_card_1), var(--blundee_background_card_2));
  border-radius: 7px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-shadow: var(--blundee_card_shadow);

  transition: background-color 100ms ease-in-out, transform 100ms ease-in-out, width 100ms ease-in-out;

  user-select: none;

  &:hover {
    cursor: pointer;
  }

  &:active {
    cursor: pointer;
    transform: scale(0.98);
  }

  @media screen and (min-width: 800px) {
    &:hover {
      transform: scale(1.02);
    }
  }

  @media screen and (max-width: 500px) {
    margin: 5px 2%;
    width: calc(50% - 2 * 2%);
  }

  @media screen and (min-width: 501px) and (max-width: 800px) {
    margin: 5px;
    width: calc(40% - 2 * 5px);
  }

  @media screen and (min-width: 801px) and (max-width: 1000px) {
    margin: 5px;
    width: calc(100% - 2 * 5px);
  }

  @media screen and (min-width: 1001px) and (max-width: 1300px) {
    width: 90%;
    margin: 10px 5%;
  }

  @media screen and (min-width: 1301px) and (max-width: 1701px) {
    width: 45%;
    margin: 10px 2.5%;
  }

  @media screen and (min-width: 1701px) and (max-width: 2200px) {
    margin: 10px;
    width: calc(33% - 2 * 10px);
  }
  @media screen and (min-width: 2201px) {
    margin: 15px;
    width: calc(25% - 2 * 15px);
  }

  ${({width}) => width !== undefined && css`
    width: ${width} !important;
  `}

  ${({with_margin}) => with_margin !== true && css`
    margin: 0;
  `}

  ${({in_slide}) => in_slide === true && css`
    width: 100% !important;
    margin: 0 !important;
  `}
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 150px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  overflow: hidden;
  position: relative;

  @media screen and (max-width: 800px) {
    height: 90px;
  }

  & > img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
  }
`;

export const BottomImageTag = styled.div`
  position: absolute;
  bottom: 6px;
  right: 0;
  width: fit-content;
  min-width: 10px;
  min-height: 10px;
  color: var(--blundee_color_normal);
  font-family: var(--blundee_font_medium);
  font-size: 8pt;
  border-radius: 60px 0 0 60px;
  margin-right: 6px;

  ${({with_padding}) => with_padding === true && css`
    margin-right: unset;
    background-color: var(--blundee_tag_bg_color);
    padding: 3px 3px 3px 6px;
  `}

  ${({bg}) => bg === true && css`
    background-color: ${bg};
  `}
`;

export const Tags = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
  padding: 6px 3px 3px 3px;
`;

export const Tag = styled.div`
  min-width: 10px;
  min-height: 10px;
  background-color: var(--blundee_background_light);
  color: var(--blundee_color_normal);
  margin: 0 3px;
  padding: 2px 3px;
  font-family: var(--blundee_font_bold);
  text-transform: uppercase;
  font-size: 8pt;
  border-radius: 4px;
`;

const Name = styled.h2`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-family: var(--blundee_font_medium);
  font-size: 16pt;
  padding: 0;
  margin: 0;
  width: 100%;

  & > span {
    margin: 0 5px;
  }

  svg {
    font-size: 13pt;
  }

  @media screen and (max-width: 500px) {
    font-size: 11pt;
  }
`;

const Details = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 3px 6px;

  ${({special}) => special && css`
    background-image: linear-gradient(45deg, var(--blundee_background_restaurant_card_special_1), var(--blundee_background_restaurant_card_special_2));
  `};

  ${({special}) => !special && css`
    background-image: linear-gradient(45deg, var(--blundee_background), var(--blundee_background));
  `};
`;

const Footer = styled.div`
  width: 100%;
  padding: 3px 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const FooterRight = styled.div`
  margin: 0 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const FooterTag = styled.div`
  font-family: var(--blundee_font_bold);
  font-size: 8pt;
  color: var(--blundee_color_thin);
  margin: 0;

  ${({inner}) => inner && css`
    display: inline-block;
  `};

  ${({light}) => light && css`
    color: var(--blundee_color_tag);
  `};
`;

const DetailsText = styled.h3`
  font-weight: normal;
  font-family: var(--blundee_font_medium);
  color: var(--blundee_color_thin);
  font-size: 9pt;
  font-style: italic;
  margin: 3px 0;
  padding: 0;
  width: 100%;

  @media screen and (max-width: 500px) {
    padding: 3px 0 0 0;
    font-size: 8pt;
    font-style: normal;
  }
`;

export const OverlayWrapper = styled(OverlayWrapperBase)`
  height: 100%;
  border-radius: 6px 0 0 6px;
  font-size: 30pt;
  background-color: transparent;
  backdrop-filter: blur(0px);

  @media screen and (max-width: 800px) {
    font-size: 22pt;
  }
`;

export default class ShopComponent extends Component {
  eventIDs: number[] = [];

  componentWillUnmount() {
    for (let eventID of this.eventIDs) {
      EventSystem.unsubscribe(eventID);
    }
  }

  state: {
    partnerProfile: Shop,
    width: number,
    language: number,
  } = {
    partnerProfile: undefined,
    width: undefined,
    language: ContextSystem.language
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.partnerProfile !== prevProps.partnerProfile)
      this.setState({partnerProfile: this.props.partnerProfile});
    if (this.props.width !== prevState.width)
      this.setState({width: this.props.width});
  }

  componentDidMount() {
    if (this.props.partnerProfile)
      this.setState({partnerProfile: this.props.partnerProfile});
    if (this.props.width)
      this.setState({width: this.props.width});

    this.eventIDs = [];
    let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({language}) => {
      if (language !== undefined) {
        this.setState({language});
      }
    });
    this.eventIDs.push(eid);
  }

  openingStatus = {
    closed: false,
    preOrder: false
  };

  render() {
    let partnerProfile = this.state.partnerProfile;

    if (!partnerProfile)
      return <></>;

    let OpenStatus = HoursCalc.checkOpeningState(HourTypes.OPENING, partnerProfile);
    let AcceptingPickUpOrdersStatus = HoursCalc.checkOpeningState(HourTypes.ORDER_ACCEPT_FOR_PICKUP, partnerProfile);
    let DeliveringStatus = HoursCalc.checkOpeningState(HourTypes.DELIVERY, partnerProfile);
    let AcceptingOrdersStatus = HoursCalc.checkOpeningState(HourTypes.ORDER_ACCEPT, partnerProfile);

    let openState = HoursCalc.getOpeningHoursText(OpenStatus, AcceptingPickUpOrdersStatus, DeliveringStatus, AcceptingOrdersStatus, true);

    this.openingStatus = {closed: false, preOrder: false};

    if (openState.openStringDL === HourStatuses.NO_DATA) {
      this.openingStatus.closed = true;
    } else if (openState.openStringDL === HourStatuses.LOADING) {
      this.openingStatus.closed = false;
      this.openingStatus.preOrder = false;
    } else if (openState.openStringDL.includes(Language.getName(Names.Closed))) {
      this.openingStatus.closed = true;
    } else if (openState.openStringDL.includes(Language.getName(Names.ForPreOrder))) {
      this.openingStatus.preOrder = true;
    }

    let special: boolean = false; //this.props.i % 2===0;

    let imageFileName = partnerProfile?.image;
    if (!imageFileName && partnerProfile)
      imageFileName = ContextSystem.mobileLayout ? partnerProfile?.coverImageMobile?.fileName : partnerProfile?.coverImagePC?.fileName;

    let szeps = [PaymentMethodTypes.LOCAL_SZEP_CARD_MKB, PaymentMethodTypes.LOCAL_SZEP_CARD_KH, PaymentMethodTypes.LOCAL_SZEP_CARD_OTP,
      PaymentMethodTypes.COD_SZEP_CARD_KH, PaymentMethodTypes.COD_SZEP_CARD_OTP, PaymentMethodTypes.COD_SZEP_CARD_OTP,
      PaymentMethodTypes.ONLINE_SZEP_MKB, PaymentMethodTypes.ONLINE_SZEP_OTP, PaymentMethodTypes.ONLINE_SZEP_KH
    ];
    let isSZEP: boolean = partnerProfile.paymentMethods.find(pm => pm.active && szeps.includes(pm.paymentID)) !== undefined;

    let label: ShopLabel = Config.getValue(ConfigKeys.shop_labels)?.find(l => l.id === partnerProfile?.id);

    return (
      <Wrapper {...this.props}>
        <ImageWrapper>
          <OverlayWrapper>
            {isSZEP &&
              <Tags>
                <Tag>SZÉP</Tag>
              </Tags>
            }
            {label &&
              <BottomImageTag with_padding={true} bg={label.bg}>
                {Language.getName(label.label)}
              </BottomImageTag>
            }
          </OverlayWrapper>
          <img src={Config.partnerImageUrlBase + imageFileName} alt={"cover"}
               onError={(e) => (e.target.style.display = "none")}/>
        </ImageWrapper>
        <Details special={special}>
          <Name>
            {partnerProfile.name ? partnerProfile.name : partnerProfile.restaurantName}
          </Name>
          <DetailsText>
            {partnerProfile.details}
          </DetailsText>
          <Footer>
            <FooterTag>{openState.openStringPK}</FooterTag>
            <FooterRight>
              <FooterTag>$$<FooterTag inner={true} light={true}>$$</FooterTag></FooterTag>
            </FooterRight>
          </Footer>
        </Details>
      </Wrapper>
    );
  }
}

import * as React from "react";

function BlundeeHeartIconEmpty(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 209 188"
      fill={"none"}
      stroke={"currentColor"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M110.647 179.175C107.36 180.334 101.948 180.334 98.6615 179.175C70.6319 169.606 8 129.688 8 62.0296C8 32.1635 32.0669 8 61.7397 8C79.3307 8 94.892 16.5056 104.654 29.6505C114.416 16.5056 130.074 8 147.569 8C177.241 8 201.308 32.1635 201.308 62.0296C201.308 129.688 138.676 169.606 110.647 179.175Z" stroke-width="14.4981" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
);
}

export default BlundeeHeartIconEmpty;

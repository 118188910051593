import React, { Component } from "react";
import styled, { css, keyframes } from "styled-components";
import { FaBriefcase, FaExclamation, FaHome } from "react-icons/fa";
import { GoLocation } from "react-icons/go";
import { IoIosTimer } from "react-icons/io";
import ContextSystem from "../../utils/ContextSystem";
import EventSystem from "../../utils/EventSystem";
import Config from "../../utils/Config";
import FloatingAddressPicker from "./FloatingAddressPicker";
import { Address, AddressTypes, City } from "../../model/Address";
import { ShippingPrice } from "../../model/ShippingPrice";
import { ScheduleIconTimerWrapper } from "../Form";

const WrapperForMenu = styled.div`
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: opacity 100ms ease-in-out,
  width 300ms ease-in-out,
  height 300ms ease-in-out,
  background-color 100ms ease-in-out;

  & > svg {
    font-size: 21pt;
  }

  //align with NavBar.js::NavElement, this will be instead of the svg inside
  width: fit-content;
  height: fit-content;
`;

const Wrapper = styled.div`
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  //padding: 15px;
  border-radius: 100px;
  position: absolute;
  right: 10px;

  background-color: var(--blundee_background_card_1);
  box-shadow: var(--blundee_popup_shadow);
  color: var(--blundee_color_normal);
  z-index: ${({zindex}) => (zindex ? zindex : "21")};
  ${({top}) => top !== undefined && css`
    top: ${top}px;
  `};
  ${({bottom}) => bottom !== undefined && css`
    bottom: ${bottom}px;
  `};

  ${({used_in_nav_bar, open}) => used_in_nav_bar === true && open === true && css`
    position: fixed;
  `};


  transition: opacity 100ms ease-in-out,
  width 300ms ease-in-out,
  height 300ms ease-in-out,
  background-color 100ms ease-in-out,
  left 300ms ease-in-out,
  right 300ms ease-in-out,
  bottom 300ms ease-in-out,
    //border-radius 100ms ease-in-out,
  transform 150ms ease-in-out,
  top 300ms ease-in-out;

  & > svg {
    font-size: 14pt;
  }

  ${({open, show}) => !(open && show) && css`
    width: 45px;
    height: 45px;
  `};

  ${({open, show}) => open && show && css`
    width: 96% !important;
    right: 2% !important;
    ${({top}) => top !== undefined && css`
      top: ${top - 10}px;
    `};
    ${({bottom}) => bottom !== undefined && css`
      bottom: ${bottom}px;
    `};
    height: auto;
    max-width: 400px;
    max-height: 350px;
    border-radius: 4px;
    padding: 0 10px 10px 10px;
    //overflow: hidden;
  `};

  ${({pop, open}) => pop === true && open === false && css`
    transform: scale(1.25);
  `};

  ${({show}) => show === false && css`
    opacity: 0;
    width: 0;
    height: 0;
    padding: 0;
  `};

  @media screen and (min-width: 801px) {
    width: auto;
    min-width: 55px;
    height: 55px;
    bottom: 100px;
    right: unset;
    left: 25px;
    top: unset;
    padding: 15px;

    ${({show}) => show === false && css`
      opacity: 0;
      width: 0;
      height: 0;
      padding: 0;
    `};

    & > span {
      width: fit-content;
      margin: 0 10px;
    }

    & > svg {
      font-size: 16pt;
    }

    ${({open, show}) => open === true && show === true && css`
      width: 400px;
      height: fit-content;
      top: unset;
      bottom: 135px;
      right: unset;
      left: 30px;
    `};
  }

  ${({open}) => open === false && css`
    cursor: pointer;

    &:active {
      background-color: var(--blundee_background_card_3);
    }

    @media screen and (min-width: 800px) {
      &:hover {
        background-color: var(--blundee_background_card_3);
      }
    }
  `};
`;

const ExclamationMarkAnimationKeyframes = keyframes`
  from {
    transform: scale(1.0) translateY(0);
  }
  to {
    transform: scale(1.45) translateY(-10px);
  }
`;

const ExclamationMarkAnimationKeyframesMobile = keyframes`
  from {
    transform: scale(1.0) translateY(0);
  }
  to {
    transform: scale(1.3) translateY(-7px);
  }
`;

const ExclamationMarkContainer = styled.div`
  & > svg {
    font-size: 15pt;
    position: absolute;
    top: -5px;
    right: -5px;
    color: var(--blundee_yellow);
    animation: ${ExclamationMarkAnimationKeyframes} 0.5s ease-in-out infinite alternate;

    @media screen and (max-width: 800px) {
      left: -5px;
      right: unset;
      animation: ${ExclamationMarkAnimationKeyframesMobile} 0.5s ease-in-out infinite alternate;
    }
  }
`;

interface Props {
  usedInNavBar: boolean,
}

interface State {
  selectedCity: City | null,
  selectedAddress: Address | null,
  mobile: boolean,
  selectedNavIndex: number,
  open: boolean,
  welcomeScreenEnabled: boolean,
  pop: boolean,
  availableShippingPrices: ShippingPrice[],
  searchScreenFiltersOpened: boolean,
  mobileCartOpened: boolean,
  pickup: boolean,
  language: number,
  statusBarSpaceHeight: number,
  atPlaceQRID: number,
  scheduleDate: Date,
  usedInNavBar: boolean,
  hideNavBar: boolean,
}


export default class FloatingAddressButton extends Component<Props, State> {
  state: State = {
    selectedCity: ContextSystem.selectedCity,
    selectedAddress: ContextSystem.selectedAddress,
    mobile: window.innerWidth <= Config.mobileMaxWidth,
    selectedNavIndex: ContextSystem.selectedNavIndex,
    open: false,
    welcomeScreenEnabled: ContextSystem.welcomeScreenEnabled,
    pop: false,
    availableShippingPrices: ContextSystem.availableShippingPrices,
    searchScreenFiltersOpened: ContextSystem.searchScreenFiltersOpened,
    mobileCartOpened: ContextSystem.mobileCartOpened,
    pickup: ContextSystem.pickup,
    language: ContextSystem.language,
    statusBarSpaceHeight: ContextSystem.statusBarSpaceHeight,
    atPlaceQRID: ContextSystem.atPlaceQRID,
    scheduleDate: ContextSystem.scheduleDate,
    usedInNavBar: false,
    hideNavBar: ContextSystem.hideNavBar
  };

  pop() {
    let repeat = 3;
    let restTime = 300;
    let interval = 150;
    let times = 2;

    for (let j = 0; j < repeat; j++) {
      let zeroTimeDelta = j * (interval * times * 2 + restTime);

      for (let i = 0; i < times; i++) {
        setTimeout(() => {
          this.setState({pop: true});
        }, zeroTimeDelta + 2 * i * interval);
        setTimeout(() => {
          this.setState({pop: false});
        }, zeroTimeDelta + (2 * i + 1) * interval);
      }
    }
  }

  loadProps() {
    this.setState({usedInNavBar: this.props.usedInNavBar})
  }

  componentDidUpdate(prevProps: Readonly, prevState: Readonly, snapshot) {
    if (this.state.usedInNavBar !== this.props.usedInNavBar)
      this.loadProps();
  }

  static exactAddressCounter = 0;

  componentDidMount() {
    this.loadProps();

    EventSystem.subscribe(EventSystem.events.popup_product, () => {
      this.setState({open: false});
    });
    EventSystem.subscribe(EventSystem.events.hard_open_product_popup_intent, () => {
      this.setState({open: false});
    });
    EventSystem.subscribe(EventSystem.events.addressSelected, ({address}) => {
      this.setState({selectedAddress: address});
    });
    EventSystem.subscribe(EventSystem.events.citySelected, ({city}) => {
      this.setState({selectedCity: city});
    });

    EventSystem.subscribe(
      EventSystem.events.contextSystemChanged,
      ({
         searchScreenFiltersOpened,
         availableShippingPrices,
         welcomeScreenEnabled,
         //emptySearchScreenEnabled,
         selectedAddress,
         selectedNavIndex,
         selectedCity,
         pickup,
         mobileCartOpened,
         language,
         statusBarSpaceHeight,
         atPlaceQRID,
         scheduleDate,
         hideNavBar,
       }) => {
        if (atPlaceQRID !== undefined)
          this.setState({atPlaceQRID});
        if (mobileCartOpened !== undefined)
          this.setState({mobileCartOpened});
        if (selectedAddress !== undefined)
          this.setState({selectedAddress});
        if (selectedNavIndex !== undefined)
          this.setState({selectedNavIndex});
        if (selectedCity !== undefined)
          this.setState({selectedCity});
        // if (emptySearchScreenEnabled !== undefined)
        //   this.setState({ emptySearchScreenEnabled });
        if (welcomeScreenEnabled !== undefined)
          this.setState({welcomeScreenEnabled});
        if (availableShippingPrices !== undefined)
          this.setState({availableShippingPrices});
        if (searchScreenFiltersOpened !== undefined)
          this.setState({searchScreenFiltersOpened});
        if (pickup !== undefined)
          this.setState({pickup});
        if (language !== undefined)
          this.setState({language});
        if (statusBarSpaceHeight !== undefined)
          this.setState({statusBarSpaceHeight});
        if (scheduleDate !== undefined)
          this.setState({scheduleDate});
        if (hideNavBar !== undefined)
          this.setState({hideNavBar});
      }
    );
    EventSystem.subscribe(EventSystem.events.popFloatingAddressPicker, () => {
      if (this.state.open === false) {
        // FloatingAddressButton.exactAddressCounter++;
        // if (FloatingAddressButton.exactAddressCounter <= 3) {
        //   Toast.showToast("Kérlek add meg a pontos címedet, hogy ellenőrizni tudjuk a kiszállítást!");
        //   EventSystem.publish(EventSystem.events.show_confirmation_modal, {
        //     title: "Szállítási cím",
        //     text: (
        //       <>
        //         Kérlek add meg a pontos címedet, hogy ellenőrizni tudjuk a kiszállítást!
        //       </>
        //     ),
        //     yesText: "Ok"
        //   });
        // }
      }
      setTimeout(() => {
        this.pop();
      }, 200);
    });

    window.addEventListener("resize", () => this.setState({mobile: window.innerWidth <= Config.mobileMaxWidth}));
  }

  // forcePopUpFloatingAddressButton = true;

  render() {
    let icon;
    if (!this.state.selectedAddress)
      icon = <GoLocation/>;
    else if (this.state.selectedAddress.type === AddressTypes.HOME)
      icon = <FaHome/>;
    else if (this.state.selectedAddress.type === AddressTypes.WORK)
      icon = <FaBriefcase/>;
    else if (this.state.selectedAddress.type === AddressTypes.OTHER)
      icon = <GoLocation/>;
    else if (this.state.selectedAddress.type === AddressTypes.TEMP)
      icon = <GoLocation/>;

    let path = window.location.pathname;
    let show = this.state.selectedNavIndex === 2 || (this.state.selectedNavIndex === 1 && this.state.welcomeScreenEnabled === false);

    show = this.state.atPlaceQRID <= 0 && !this.state.mobile && show;
    show = show && !path.startsWith("/start");

    let top = 65;
    if (this.state.selectedNavIndex === 1) {
      show = show && !this.state.searchScreenFiltersOpened;
      if (this.state.searchScreenFiltersOpened)
        top = 135;
      else
        top = 25;
    }
    if (Config.mobile)
      top += this.state.statusBarSpaceHeight;

    let z_index = !this.state.selectedAddress ? 21 : 6;
    if (this.state.mobileCartOpened) {
      z_index = 6;
    }

    let UsedWrapper = this.state.usedInNavBar ? WrapperForMenu : Wrapper;

    let bottom: number = undefined;
    if (this.state.usedInNavBar && this.state.open)
      bottom = Config.mobileNavbarHeightDefault + (ContextSystem.hideCart ? 0 : ContextSystem.mobileCartHeight) + 10;

    return (
      <>
        <UsedWrapper zindex={z_index} bottom={bottom} pop={this.state.pop} top={top} show={show} open={this.state.open}
                     onClick={() => this.setState({open: this.state.usedInNavBar ? !this.state.open : true})}>
          {(this.state.open === false || this.state.usedInNavBar) && (
            <>
              {!this.state.pickup && (!this.state.selectedAddress || this.state.availableShippingPrices.length <= 0)
                && this.state.selectedNavIndex === 2 && /* = restaurant */
                (
                  <ExclamationMarkContainer>
                    <FaExclamation/>
                  </ExclamationMarkContainer>
                )}
              {icon}
              {this.state.scheduleDate &&
                <ScheduleIconTimerWrapper absolute={true}>
                  <IoIosTimer/>
                </ScheduleIconTimerWrapper>
              }
            </>
          )}
          {!this.state.usedInNavBar && this.state.open === true &&
            <FloatingAddressPicker handleCloseButton={() => this.setState({open: false})}/>
          }
        </UsedWrapper>
        {this.state.usedInNavBar && this.state.open === true && !this.state.hideNavBar &&
          <Wrapper zindex={z_index} onMouseClick={e => e.stopPropagation()} used_in_nav_bar={true} pop={this.state.pop}
                   top={undefined} bottom={bottom} show={true} open={true}>
            <FloatingAddressPicker handleCloseButton={() => this.setState({open: false})}/>
          </Wrapper>
        }
      </>
    );
  }
}

// noinspection CssInvalidPropertyValue

import React, { Component } from "react";
import styled, { css } from "styled-components";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { BlundeeButton as RedButton_Org } from "../components/Form";
import ContextSystem from "../utils/ContextSystem";
import AboutPagesFrame from "../components/about/AboutPagesFrame";
import validateEmail from "../utils/EmailValidator";
import {
  Form,
  FormWrapper as FormWrapperBase,
  Input as Input_Org,
  Select as Select_Org
} from "../components/about/FormElements";
import { FaTimes } from "react-icons/fa";
import { BsChevronCompactDown } from "react-icons/bs";
import { HiPhone } from "react-icons/hi";
import { IoMdMailUnread } from "react-icons/io";
import Loader from "react-loader-spinner";
import ForPartnersAPI from "../utils/api/ForPartnersAPI";
import PartnerRegForm from "../components/PartnerRegForm";
import { Swiper, SwiperSlide } from "swiper/react";
import Toast from "../components/Toast";
import Language, { Names } from "../utils/Language";
import EventSystem from "../utils/EventSystem";
import Config, { ConfigKeys } from "../utils/Config";
import { TranslatableString } from "../model/Product";
import DynamicReactIcons from "../components/DynamicReactIcons";
import WhatIsBlundeeComponent from "../model/WhatIsBlundeeComponent";

const Offer = styled.div`
  background-color: var(--blundee_background_light);
  color: var(--blundee_color_normal);
  width: 33%;
  min-width: 280px;
  max-width: 400px;
  height: fit-content;

  margin: 0 0 30px 0;
  padding: 10px;

  border-radius: 11px;
  box-shadow: var(--blundee_card_shadow);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  ${({selected}) => selected === true && css`
    box-shadow: 0 0 8px 1px #ffef42;
  `}
`;

const OfferTopTitle = styled.div`
  font-size: 16px;
  font-family: var(--blundee_font_medium);
  font-weight: 600;
  color: black;
`;

const OfferSubTitle = styled.div`
  font-size: 12px;
  font-family: var(--blundee_font_medium);
  color: rgba(0, 0, 0, 0.55);
  margin-top: 0;
  text-transform: uppercase;
`;

const OfferTitle = styled.div`
  font-size: 28pt;
  font-family: var(--blundee_font_medium);
  color: #ffef42;
  background-color: #444440;
  border-radius: 8px;
  padding: 0 6px;
  margin: 4px 0;

  & > span:nth-of-type(1) {
    text-decoration: line-through;
    margin-right: 13px;
  }

  & > span:nth-of-type(2) {
    font-size: 28pt;
    vertical-align: super;
  }
`;

const OfferDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const OfferFeature = styled.div`
  width: 100%;
  text-align: center;
  font-size: 12px;
  font-family: var(--blundee_font_medium);

  padding: 2px 0 1px 0;

  & > svg, & > b {
    margin-right: 5px;
  }

  ${({missing}) => missing === true && css`
    color: #b1b1b1;
  `};
`;

const OfferDetailHeader = styled.div`
  font-size: 16px;
  font-weight: bold;
  padding-top: 5px;
  margin-top: 5px;
  border-top: 1px solid #d7d7d7;
  margin-bottom: 3px;
`;

const RedButton = styled(RedButton_Org)`
  margin-left: 7px;
  margin-right: 7px;
  @media screen and (max-width: 1400px) and (min-width: 801px) {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px 25px;
    font-size: 16px;
  }
  @media screen and (max-width: 1400px) and (min-width: 801px) {
    font-size: 13px;
  }
`;

const Input = styled(Input_Org)`
  @media screen and (max-width: 1400px) and (min-width: 801px) {
    margin: 5px 0;
    padding: 10px;
  }
`;

const Select = styled(Select_Org)`
  @media screen and (max-width: 1400px) and (min-width: 801px) {
    margin: 5px 0;
    padding: 10px;
  }
`;

const Wrapper = styled.div`
  font-family: var(--blundee_font_medium);
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;

  @media screen and (max-width: 1300px) {
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 0;
  }
`;

const InnerWrapper = styled.div`
  width: 100%;
  min-width: 500px;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media screen and (max-width: 800px) {
    min-width: 200px;
  }
`;

// const WallpaperWrapper = styled.div`
//   width: 100%;
//   height: auto;
//   display: flex;
//   justify-content: center;
//
//   & > img {
//     width: 100%;
//     height: 100%;
//     border-radius: 0 0 50px 0;
//     //object-fit: cover;
//     //max-width: 512px;
//     //max-height: 512px;
//   }
//
//   & > img:nth-of-type(1) {
//     display: unset;
//   }
//
//   & > img:nth-of-type(2) {
//     display: none;
//   }
//
//   & > img:nth-of-type(3) {
//     display: none;
//   }
//
//   @media screen and (max-width: 1400px) {
//     & > img:nth-of-type(1) {
//       display: none;
//     }
//
//     & > img:nth-of-type(2) {
//       display: unset;
//     }
//
//     & > img:nth-of-type(3) {
//       display: none;
//     }
//   }
//
//   @media screen and (max-width: 800px) {
//     padding-right: 5%;
//     & > img:nth-of-type(1) {
//       display: none;
//     }
//
//     & > img:nth-of-type(2) {
//       display: none;
//     }
//
//     & > img:nth-of-type(3) {
//       display: unset;
//     }
//   }
// `;

const PaddingWrapper = styled.div`
  max-width: 100%;
`;

// noinspection JSUnusedLocalSymbols
const FormPaddingWrapper = styled.div`
  top: 100px;
  position: sticky;
  padding-right: 10px;

  @media screen and (max-width: 1400px) and (min-width: 801px) {
    padding-left: 5%;
    top: 70px;
    width: 100%;
  }

  @media screen and (max-width: 800px) {
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 5%;
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  align-items: flex-start;

  & > h2:first-of-type {
    font-size: 40px;
    text-align: center;
    margin-bottom: 32pt;
    font-family: var(--blundee_font_medium);
    line-height: 1.45em;
    width: 100%;
  }

  & > h2:last-of-type {
    font-size: 30px;
    font-family: var(--blundee_font_medium);
  }

  @media screen and (min-width: 801px) and (max-width: 1400px) {
    & > h2:first-of-type {
      font-size: 25px;
    }

    & > h2:last-of-type {
      font-size: 22px;
    }
  }

  @media screen and (max-width: 800px) {
    margin-bottom: 30px;
    & > h2:first-of-type {
      font-size: 24px;
    }

    & > h2:last-of-type {
      font-size: 20px;
    }
  }
`;

const ContactOuterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 32pt;
  color: var(--blundee_color_normal);

  & > h2 {
    color: var(--blundee_yellow);
    font-size: 30px;
    font-family: var(--blundee_font_medium);
  }

  & > h2:nth-of-type(2) {
    font-size: 22px;
    margin-top: 0;
    font-family: var(--blundee_font_medium);
  }

  @media screen and (max-width: 800px) {
    margin-bottom: unset;
    & > h2 {
      font-size: 22px;
    }

    & > h2:nth-of-type(1) {
      margin-bottom: 0;
    }

    & > h2:nth-of-type(2) {
      font-size: 18px;
    }
  }
`;

const ContactWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;

  @media screen and (max-width: 800px) {
    font-size: 90%;
    flex-direction: column;
  }
`;

const ContactColumnWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  & > h3 {
    margin: 0;

    & > a {
      color: var(--blundee_link_color);
    }
  }

  @media screen and (max-width: 800px) {
    margin-bottom: 10px;
  }
`;

const VideoWrapper = styled.div`
  width: 100%;
  border: none;
  font-size: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;

  @media screen and (max-width: 800px) {
    margin-bottom: unset;
  }
`;

const FormWrapper = styled(FormWrapperBase)`
  //border: 1px solid;
  border-radius: 4px;
  box-shadow: 0 0 10px 1px black;
  background-color: #404040;
  margin-bottom: 20px;

  & > h2 {
    text-align: center;
    margin: 0;
  }

  & > h2:nth-of-type(2) {
    font-size: 60px;
    height: 60px;
  }

  & > p {
    display: flex;
    margin: 10px 25px;
    align-self: flex-start;
    justify-content: center;
    font-size: 18px;
    align-items: center;

    & > svg {
      flex-shrink: 0;
      margin-right: 6px;
      font-size: 30px;
      color: #2de956;
    }
  }

  @media screen and (min-width: 801px) and (max-width: 1400px) {
    & > p {
      margin: 5px 20px;
    }
  }

  @media screen and (max-width: 800px) {
    & > h2 {
      font-size: 19px;
    }

    & > h2:nth-of-type(2) {
      font-size: 40px;
    }

    & > p {
      margin: 10px 0;
      align-self: flex-start;
      font-size: 15px;
    }
  }
`;

const FormPopUpButtonWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const FormPopUpOuterWrapper = styled.div`
  width: 100vw;
  height: -webkit-fill-available;
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  transform: translateY(-100%);
  transition: transform 200ms ease-in-out, background-color 70ms ease-in-out;

  color: rgb(240, 240, 240);

  ${({opened}) => opened === true && css`
    transform: translateY(0);
    background-color: ${({bgcolor}) => (bgcolor ? bgcolor : "rgba(0,0,0,0.15)")};
    backdrop-filter: blur(2px);
  `};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FormPopUpWrapper = styled(FormWrapper)`
  top: unset;
  position: unset;
  width: 95%;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const ParagraphWrapper = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 16pt;

  @media screen and (max-width: 800px) {
    margin-bottom: unset;
  }
`;

const ParagraphIconWrapper = styled.div`
  width: 50px;
  height: 50px;
  font-size: 20pt;
  border: none;
  //border: solid 1px #ccc;
  box-shadow: var(--blundee_button_shadow);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  margin-right: 10px;
  background-color: var(--blundee_background_secondary);
  flex-shrink: 0;
  color: var(--blundee_color_2);

  @media screen and (max-width: 800px) {
    width: 40px;
    height: 40px;
    font-size: 16pt;
    margin-top: 0;
  }
`;

const ParagraphInnerWrapper = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  & > h2 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    text-align: left !important;
    height: 50px;
    display: flex;
    align-items: center;
    font-family: var(--blundee_font_medium);
    font-size: 26px;
  }

  & > p {
    text-align: justify;
    text-justify: inter-word;
    font-size: 18px;
    max-width: 700px;
  }

  @media screen and (min-width: 801px) and (max-width: 1400px) {
    & > h2 {
      font-size: 20px;
    }

    & > p {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 800px) {
    & > h2 {
      font-size: 20px;
    }

    & > p {
      font-size: 16px;
    }
  }
`;

export const LoaderOuterWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const LoaderWrapper = styled.div`
  width: calc(100% - 50px);
  height: 100%;
  top: -15px;
  display: flex;
  flex-direction: column;
  justify-self: flex-start;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: rgba(35, 35, 35, 0.7);
  border-radius: 10px;
  //background-color: transparent;
  backdrop-filter: blur(1px);

  ${({loading}) => loading === false && css`
    display: none;
  `};

  @media screen and (max-width: 800px) {
    width: 110%;
  }
`;

export const FormDonePopUpOuterWrapper = styled(FormPopUpOuterWrapper)`
`;

export const FormDonePopUpWrapper = styled(FormPopUpWrapper)`
  width: fit-content;

  & > h2 {
    font-size: 24px;
  }

  @media screen and (max-width: 800px) {
    & > h2 {
      font-size: 20px;
    }
  }
`;

const OffersWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
  text-align: left;
  overflow: hidden;
`;

type OfferType = {
  name: TranslatableString,
  topTitle: TranslatableString,
  subTitle: TranslatableString,
  features: { name: TranslatableString, value: TranslatableString, missing: boolean, header: boolean }[]
};

type StructureType = {
  title: TranslatableString,
  icon: string,
  text: TranslatableString,
};

type SoftwarePOSType = {
  title: TranslatableString,
  bullets: TranslatableString[]
};

export default class JoinUsPage extends Component {
  eventIDs: number[];

  state: {
    popUpOpened: boolean,
    popUpRegOpened: boolean,
    language: number,
    selectedOffer: any,
  } = {
    popUpOpened: false,
    popUpRegOpened: false,
    language: ContextSystem.language,
    selectedOffer: undefined
  };


  componentWillUnmount() {
    for (let eventID of this.eventIDs) {
      EventSystem.unsubscribe(eventID);
    }
  }

  componentDidMount() {
    this.eventIDs = [];
    let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({language}) => {
      if (language !== undefined) {
        this.setState({language});
      }
    });
    this.eventIDs.push(eid);
  }

  getSlidesPerView() {
    let width = window.innerWidth;
    if (width < 350)
      return 1;
    else if (width >= 350 && width < 500)
      return 1.1;
    else if (width >= 500 && width < 650)
      return 2;
    else if (width >= 650 && width < 800)
      return 2;
    else if (width >= 800 && width < 1180)
      return 1.8;
    else if (width >= 1180 && width < 1300)
      return 3.1;
    else if (width >= 1300 && width < 1400)
      return 3.5;
    else if (width >= 1400 && width < 1650)
      return 3.5;
    else if (width >= 1650)
      return 4;
  }

  render() {
    let salesPosObject: SoftwarePOSType = Config.getValue(ConfigKeys.sales_pos);
    let sales_paragraphs: StructureType[] = Config.getValue(ConfigKeys.sales_structure);

    let offers: OfferType[] = Config.getValue(ConfigKeys.sales_offers);
    return (
      <>
        <AboutPagesFrame title={Language.getName(Names.JoinUsPageTitle)} hideTitle>
          <Wrapper>
            <InnerWrapper>
              {/*<WallpaperWrapper>*/}
              {/*  <img src={salesLandingPagePC} alt="Csatlakozás"/>*/}
              {/*  <img src={salesLandingPageM} alt="Csatlakozás"/>*/}
              {/*  <img src={salesLandingPageMobile} alt="Csatlakozás"/>*/}
              {/*</WallpaperWrapper>*/}
              <PaddingWrapper>
                <ContentWrapper>
                  <h2>{Language.getName(Config.getValue(ConfigKeys.sales_main_title))}</h2>
                  {offers && offers?.length > 0 &&
                    <>
                      <h3>{Language.getName(Names.Plans)}</h3>
                      <OffersWrapper>
                        <Swiper
                          grabCursor
                          autoHeight={true}
                          initialSlide={0}
                          slidesOffsetBefore={12}
                          slidesOffsetAfter={12}
                          pagination={{
                            clickable: true,
                            renderBullet: (index, className) => {
                              className += " slider-bullet";
                              return `<span class="${className}"></span>`;
                            }
                          }}
                          spaceBetween={window.innerWidth < 350 ? 24 : 12}
                          slidesPerView={this.getSlidesPerView()}
                        >
                          {offers && offers?.length > 0 && offers.map((offer, j)=>{
                            return (
                              <SwiperSlide key={j}>{this.renderOffer(j)}</SwiperSlide>
                            );
                          })}
                        </Swiper>
                      </OffersWrapper>
                    </>
                  }
                  <FormPopUpButtonWrapper>
                    <RedButton
                      onClick={() => this.setState({popUpRegOpened: true})}>{Language.getName(Names.TryItOut)}</RedButton>
                  </FormPopUpButtonWrapper>
                  <h3>{TranslatableString.get(Config.getValue(ConfigKeys.sales_pos)?.title)}</h3>
                  <p>
                    <ul>
                      {salesPosObject?.bullets?.map((v, i) => <li key={i}>{TranslatableString.get(v)}</li>)}
                    </ul>
                  </p>
                  <WhatIsBlundeeComponent/>
                  {sales_paragraphs?.map((v, i) => (
                    <Paragraph key={i} icon={<DynamicReactIcons name={v.icon}/>} title={TranslatableString.get(v.title)}>
                      {TranslatableString.get(v.text)}
                    </Paragraph>
                  ))}
                  <FormPopUpButtonWrapper>
                    {/*<RedButton onClick={(e) => this.setState({popUpOpened: true})}>Kérjen visszahívást</RedButton>*/}
                    <RedButton
                      onClick={() => this.setState({popUpRegOpened: true})}>{Language.getName(Names.TryItOut)}</RedButton>
                  </FormPopUpButtonWrapper>
                  <ContactOuterWrapper>
                    <h2>{Language.getName(Names.OurContact)}:</h2>
                    <h2>{Language.getName(Names.ContactUs)}</h2>
                    <ContactWrapper>
                      <ContactColumnWrapper>
                        <h3>
                          <IoMdMailUnread/>
                          <a href={"mailto:" + Config.getString(ConfigKeys.sales_mail)}>
                            {Config.getString(ConfigKeys.sales_mail)}
                          </a>
                        </h3>
                      </ContactColumnWrapper>
                      <ContactColumnWrapper>
                        <h3>
                          <HiPhone/>
                          <a href={"tel:" + Config.getString(ConfigKeys.sales_phone)?.replaceAll(" ", "")}>
                            {Config.getString(ConfigKeys.sales_phone)}
                          </a>
                        </h3>
                      </ContactColumnWrapper>
                    </ContactWrapper>
                  </ContactOuterWrapper>
                  {Config.getBoolean(ConfigKeys.sales_video) === true &&
                    <>
                      <h2 style={{
                        color: "var(--blundee_color_normal)",
                        margin: "0"
                      }}>{Language.getName(Config.getValue(ConfigKeys.sales_video_title))}</h2>
                      <YouTube youtubeID={Config.getString(ConfigKeys.sales_video_yt_id)}/>
                    </>
                  }
                </ContentWrapper>
              </PaddingWrapper>
            </InnerWrapper>
            {/*
            <FormPaddingWrapper>
              <FormWrapper>
                <FormHelper/>
              </FormWrapper>
            </FormPaddingWrapper>
            */}
          </Wrapper>
        </AboutPagesFrame>
        {/*
        <FormPopUpOuterWrapper opened={this.state.popUpOpened} onClick={(e) => this.setState({popUpOpened: false})}>
          <FormPopUpWrapper onClick={(e) => e.stopPropagation()}>
            <p style={{alignSelf: "flex-end", marginTop: "0", marginBottom: "0"}}>
              <FaTimes size={15} color={"white"} onClick={() => this.setState({popUpOpened: false})}/>
            </p>
            <FormHelper/>
          </FormPopUpWrapper>
        </FormPopUpOuterWrapper>
        */}
        <FormPopUpOuterWrapper opened={this.state.popUpRegOpened}
                               onClick={() => this.setState({popUpRegOpened: false})}
        >
          <FormPopUpWrapper onClick={(e) => e.stopPropagation()}>
            <p style={{alignSelf: "flex-end", marginTop: "0", marginBottom: "0"}}>
              <FaTimes size={15} color={"white"} onClick={() => this.setState({popUpRegOpened: false})}/>
            </p>
            <PartnerRegForm/>
          </FormPopUpWrapper>
        </FormPopUpOuterWrapper>
      </>
    );
  }

  renderOffer(offerID) {
    let offers: OfferType[] = Config.getValue(ConfigKeys.sales_offers);
    if (!offers || offers?.length < offerID)
      return <React.Fragment/>

    let offer: OfferType = offers[offerID];
    if (!offer)
      return <React.Fragment/>

    return (
      <Offer selected={this.state.selectedOffer === 0}>
        <OfferTopTitle>{TranslatableString.get(offer?.name)}</OfferTopTitle>
        <OfferTitle>{TranslatableString.get(offer?.topTitle)}</OfferTitle>
        <OfferSubTitle>{TranslatableString.get(offer?.subTitle)}</OfferSubTitle>
        <OfferDetails>
          {offer?.features?.map((feature, i) => {
            return (
              <React.Fragment key={i}>
                {feature.header && <OfferDetailHeader>{TranslatableString.get(feature.name)}</OfferDetailHeader>}
                {!feature.header &&
                  <OfferFeature
                    missing={feature.missing === true}><b>{TranslatableString.get(feature.name)}</b> {TranslatableString.get(feature.value)}
                  </OfferFeature>}
              </React.Fragment>
            )
          })}
        </OfferDetails>
      </Offer>
    );
  }
}

class Paragraph extends Component {
  render() {
    return (
      <ParagraphWrapper>
        <ParagraphIconWrapper>{this.props.icon}</ParagraphIconWrapper>
        <ParagraphInnerWrapper>
          <h2 style={{color: "var(--blundee_color_normal)"}}>{this.props.title}</h2>
          <p>{this.props.children}</p>
        </ParagraphInnerWrapper>
      </ParagraphWrapper>
    );
  }
}

class ForPartnersForm extends Component {
  uniqueID;

  constructor() {
    super();
    this.uniqueID = ContextSystem.getNextUniqueID();
    this.state = {
      name: "",
      email: "",
      phoneNumber: "",
      restaurantName: "",
      when: "",
      loading: false,
      donePopUpOpened: false
    };
  }

  send(e) {
    e.preventDefault();
    if (
      this.state.name === "" ||
      this.state.email === "" ||
      this.state.phoneNumber === "" ||
      this.state.restaurantName === "" ||
      this.state.when === ""
    ) {
      Toast.showToast(Language.getName(Names.YouShouldntLeaveAnyFieldEmpty));
      return;
    }

    if (this.state.name.length < 4) {
      Toast.showToast(Language.getName(Names.GivenNameIsNotValid));
      return;
    }

    if (this.state.restaurantName.length < 3) {
      Toast.showToast(Language.getName(Names.GivenShopNameIsNotValid));
      return;
    }

    if (!validateEmail(this.state.email)) {
      Toast.showToast(Language.getName(Names.GivenEmailIsNotValid));
      return;
    }

    let phoneNumber = parsePhoneNumberFromString(this.state.phoneNumber, "HU");
    if (!(phoneNumber && phoneNumber.country === "HU" && phoneNumber.isValid() === true)) {
      Toast.showToast(Language.getName(Names.GivenPhoneNumberIsNotValid));
      return;
    }

    this.setState({loading: true});
    ForPartnersAPI.send(false, this.state.name, this.state.email, this.state.phoneNumber, this.state.restaurantName, this.state.when, (res) => {
      setTimeout(() => {
        this.setState({loading: false});
      }, 3000);
      if (res.error === 0) {
        this.setState({name: "", email: "", phoneNumber: "", restaurantName: "", when: "", donePopUpOpened: true});
      }
    });
  }

  nameHandleChange(e) {
    this.setState({name: e.target.value});
  }

  emailHandleChange(e) {
    this.setState({email: e.target.value});
  }

  phoneNumberHandleChange(e) {
    this.setState({phoneNumber: e.target.value});
  }

  restaurantNameHandleChange(e) {
    this.setState({restaurantName: e.target.value});
  }

  whenHandleChange(e) {
    this.setState({when: e.target.value});
  }

  render() {
    return (
      <>
        <LoaderOuterWrapper>
          <LoaderWrapper loading={this.state.loading}>
            <Loader visible={this.state.loading} type="ThreeDots" color="#DE0B18" height={20} width={100}/>
          </LoaderWrapper>
          <Form id={"forPartnersForm" + this.uniqueID} onSubmit={(e) => this.send(e)} method="post"
                encType="multipart/form-data">
            <Input
              type="text"
              id={"name" + this.uniqueID}
              name="name"
              value={this.state.name}
              onChange={(e) => this.nameHandleChange(e)}
              placeholder={Language.getName(Names.Name)}
            />
            <Input
              type="text"
              id={"email" + this.uniqueID}
              name="email"
              value={this.state.email}
              onChange={(e) => this.emailHandleChange(e)}
              placeholder={Language.getName(Names.EmailAddress)}
            />
            <Input
              type="text"
              id={"phoneNumber" + this.uniqueID}
              name="phoneNumber"
              value={this.state.phoneNumber}
              onChange={(e) => this.phoneNumberHandleChange(e)}
              placeholder={Language.getName(Names.PhoneNumber)}
            />
            <Input
              type="text"
              id={"restaurant" + this.uniqueID}
              name="restaurant"
              value={this.state.restaurantName}
              onChange={(e) => this.restaurantNameHandleChange(e)}
              placeholder={Language.getName(Names.RestaurantName)}
            />
            <Select id={"when" + this.uniqueID} name="when" value={this.state.when}
                    onChange={(e) => this.whenHandleChange(e)}>
              <option value="" disabled defaultValue>
                {Language.getName(Names.WhenCanWeCallYou)}
              </option>
              <option value="0">{Language.getName(Names.Morning)}</option>
              <option value="1">{Language.getName(Names.Afternoon)}</option>
              <option value="2">{Language.getName(Names.Evening)}</option>
            </Select>
            <RedButton style={{alignSelf: "center"}}>{Language.getName(Names.Submit)}</RedButton>
          </Form>
        </LoaderOuterWrapper>

        <FormDonePopUpOuterWrapper opened={this.state.donePopUpOpened}>
          <FormDonePopUpWrapper>
            <h2 style={{margin: "10px"}}>Köszönjük az érdeklődést, kollégáink hívni fogják a kért időpontban.</h2>
            <RedButton onClick={() => this.setState({donePopUpOpened: false})}>Oké</RedButton>
          </FormDonePopUpWrapper>
        </FormDonePopUpOuterWrapper>
      </>
    );
  }
}

// noinspection JSUnusedLocalSymbols
class FormHelper extends Component {
  render() {
    return (
      <>
        <h2>Kérjen visszahívást!</h2>
        <h2><BsChevronCompactDown/></h2>
        <ForPartnersForm/>
      </>
    );
  }
}

// noinspection JSUnusedLocalSymbols
class YouTube extends Component {
  state = {
    youtubeWidth: "100%",
    youtubeHeight: 0
  };
  wrapperComponent;

  componentDidMount() {
    this.setYoutubeHeight.bind(this);
    this.setYoutubeHeight();
    window.addEventListener("resize", () => this.setYoutubeHeight());
  }

  setYoutubeHeight() {
    if (this.wrapperComponent?.offsetWidth > 720) {
      this.setState({youtubeWidth: 720, youtubeHeight: 405});
    } else {
      this.setState({youtubeWidth: "100%", youtubeHeight: this.wrapperComponent?.offsetWidth * 0.5625});
    }
  }

  render() {
    return (
      <VideoWrapper ref={(r) => (this.wrapperComponent = r)}>
        <iframe
          title="Video"
          width={this.state.youtubeWidth}
          height={this.state.youtubeHeight}
          src={"https://www.youtube.com/embed/" + this.props.youtubeID}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </VideoWrapper>
    );
  }
}

// noinspection JSUnusedLocalSymbols

import React, { Component } from "react";
import { Button } from "../../components/Form";
import ContextSystem from "../../utils/ContextSystem";
import AboutPagesFrame from "../../components/about/AboutPagesFrame";
import validateEmail from "../../utils/EmailValidator";
import EventSystem from "../../utils/EventSystem";
import { FileUpload, FormWrapper, Input, TextArea } from "../../components/about/FormElements";
import Toast from "../../components/Toast";
import Language, { Names } from "../../utils/Language";

export default class BugReportPage extends Component {
  render() {
    return (
      <>
        <AboutPagesFrame title={Language.getName(Names.BugReport)}>
          <FormWrapper>
            <BugReportForm/>
          </FormWrapper>
        </AboutPagesFrame>
      </>
    );
  }
}

class BugReportForm extends Component {
  uniqueID: number;

  state: {
    email: string,
    subject: string,
    content: string,
    fileUploadError: boolean,
    fileUpload: boolean,
    uploadPercentage: number,
    status: string,
    loadedTotal: string
  } = {
    email: ContextSystem.profile ? ContextSystem.profile.email : "",
    subject: "",
    content: "",
    fileUploadError: false,
    fileUpload: false,
    uploadPercentage: 0,
    status: "",
    loadedTotal: ""
  };

  constructor() {
    super();
    this.uniqueID = ContextSystem.getNextUniqueID();
  }

  componentDidMount() {
    EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ profile }) => {
      if (profile !== undefined) {
        this.setState({ email: ContextSystem.profile?.email });
      }
    });
  }

  send(e) {
    e.preventDefault();
    if (this.state.email === "" || this.state.subject === "" || this.state.content === "") {
      Toast.showToast("Egyik beviteli mező sem lehet üres!");
    } else if (!validateEmail(this.state.email)) {
      Toast.showToast("Helytelen email cím lett megadva!</>");
    } else if (this.state.fileUploadError) {
      Toast.showToast("A képek nem megfelelő kiválasztása miatt a hibabejelentés nem lett elküldve!" +
        "Kérjük törölje, vagy válasszon megfelelő képeket a feltöltéshez!");
    } else {
      let ajax = new XMLHttpRequest();
      let formdata = new FormData();
      formdata.append("email", this.state.email);
      formdata.append("subject", this.state.subject);
      formdata.append("content", this.state.content);
      let files = this.fileUploadComponent.fileUploadInput.files;
      for (let fileIndex = 0; fileIndex < files.length; fileIndex++) {
        formdata.append("pictures[]", files[fileIndex]);
      }
      for (let pair of formdata.entries()) {
        console.log(pair);
      }
      ajax.addEventListener("load", (e) => this.sendCompleteHandler(e));
      ajax.addEventListener("error", (e) => this.sendErrorHandler(e));
      if (files.length > 0) {
        this.setState({ fileUpload: true });
        ajax.upload.addEventListener("progress", (e) => this.fileUploadProgressHandler(e));
        ajax.addEventListener("load", (e) => this.fileUploadCompleteHandler(e));
      }
      ajax.open("POST", "http://192.168.0.10/bugReport.php");
      ajax.send(formdata);
    }
  }

  sendCompleteHandler(e) {
    if (e.target.responseText === "") {
      Toast.showToast("Sikeres hibabejelentés!");
      this.setState({
        subject: "",
        content: ""
      });
      this.fileUploadComponent.clearButton.click();
    }
  }

  sendErrorHandler(e) {
    Toast.showToast("Sikertelen hibabejelentés! Ismeretlen hiba lépett fel!");
    this.setState({
      fileUpload: false,
      uploadPercentage: 0,
      status: "",
      loadedtotal: ""
    });
  }

  fileUploadProgressHandler(e) {
    let loadedtotal =
      "Feltöltve: " + Math.round((e.loaded / 1024 / 1024) * 100) / 100 + " MB / " + Math.round((e.total / 1024 / 1024) * 100) / 100 + " MB";
    let percent = (e.loaded / e.total) * 100;
    let uploadPercentage = Math.round(percent);
    let status = uploadPercentage + "% feltölve... Kérjük várjon!";

    this.setState({
      uploadPercentage: uploadPercentage,
      status: status,
      loadedtotal: loadedtotal
    });
  }

  fileUploadCompleteHandler(e) {
    this.setState({
      fileUpload: false,
      uploadPercentage: 0,
      status: "",
      loadedtotal: ""
    });
    if (e.target.responseText !== "") {
      Toast.showToast("Sikertelen képfeltöltés! Ismeretlen hiba lépett fel!");
    }
  }

  emailHandleChange(e) {
    this.setState({ email: e.target.value });
  }

  subjectHandleChange(e) {
    this.setState({ subject: e.target.value });
  }

  contentHandleChange(e) {
    this.setState({ content: e.target.value });
  }

  onFileUploadChangeHandler(e: Event) {
    let error = false;
    if (e.target.files.length > 5) {
      Toast.showToast("Maximum 5 fájl feltöltése lehetséges!");
      error = true;
    }

    let errorMessage = [];
    let size = 0;
    for (let index = 0; index < e.target.files.length; index++) {
      const element: File = e.target.files[index];
      if (element.type !== "image/png") {
        errorMessage = errorMessage.concat(element.name).concat(<br/>);
      } else {
        size += element.size;
      }
    }

    if (errorMessage !== "") {
      errorMessage = ["A következő fájlok feltöltése nem lehetséges, mivel nem PNG fájlok:"].concat("\r\n").concat(errorMessage);
      Toast.showToast(errorMessage);
      error = true;
    }

    if (size > 25 * 1024 * 1024) {
      Toast.showToast("A feltöltendő képek meghaladják a 25 MB limitet;!");
      error = true;
    }

    this.setState({ fileUploadError: error });
    if (error) {
      this.fileUploadComponent.clearButton.click();
    }
  }

  fileUploadComponent;

  render() {
    return (
      <>
        <form id={"bugReportForm" + this.uniqueID} onSubmit={(e) => this.send(e)} method="post"
              encType="multipart/form-data">
          <span>
            Írja le röviden a hibát!
            <br/>
            Amennyiben szükséges, töltsön fel legfeljebb 5 képet a hibáról!
            <br/>
            (PNG kiterjesztésben, összesítve maximum 25 MB méretben!)
          </span>
          <br/>
          <br/>
          <Input
            type="text"
            id={"email" + this.uniqueID}
            name="email"
            value={this.state.email}
            onChange={(e) => this.emailHandleChange(e)}
            placeholder={Language.getName(Names.EmailAddress)}
          />
          <Input
            type="text"
            id={"bugSubject" + this.uniqueID}
            name="bugSubject"
            value={this.state.subject}
            onChange={(e) => this.subjectHandleChange(e)}
            placeholder="Hiba tárgya"
          />
          <TextArea
            id={"bugContent" + this.uniqueID}
            name="bugContent"
            rows="14"
            value={this.state.content}
            onChange={(e) => this.contentHandleChange(e)}
            placeholder="Hiba leírása"
          />
          <FileUpload
            id={"bugPictures" + this.uniqueID}
            name="bugPictures"
            multiple
            accept="image/png"
            onChange={(e) => this.onFileUploadChangeHandler(e)}
            ref={(r) => (this.fileUploadComponent = r)}
            inProgress={this.state.fileUpload}
            uploadPercentage={this.state.uploadPercentage}
            status={this.state.status}
            loadedtotal={this.state.loadedTotal}
            title={"Csatolt képek"}
          />
          <br/>
          <Button>{Language.getName(Names.Send)}</Button>
        </form>
      </>
    );
  }
}

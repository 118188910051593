import APICaller from "../APICaller";
import { AddressTypes } from "../../model/Address";

function get(addressID, cb) {
  APICaller.ownFetch(false, "POST", "/address", { addressID }, (data) => {
    let res = JSON.parse(data);
    if (cb) cb(res);
  });
}

function getAddresses(quite, cb) {
  APICaller.ownFetch(quite, "POST", "/addresses", {}, (data) => {
    let res = JSON.parse(data);
    if (cb) cb(res);
  });
}

function saveAddress(address, cb) {
  const addressBackendFormat = {
    comment: address.comment ? address.comment : "",
    street: address.street,
    zipcode: address.zipCode,
    coordinates: { latitude: address.latitude, longitude: address.longitude },
    googlePlaceID: address.placeID,
    type: address.type ? address.type : AddressTypes.TEMP,
  };

  APICaller.ownFetch(false, "POST", "/address", addressBackendFormat, (data) => {
    let res = JSON.parse(data);
    if (cb) cb(res);
  });
}

// function deleteAddress(addressID, cb) {
//   APICaller.ownFetch(false, "DELETE", "/address", { id: addressID }, (data) => {
//     let res = JSON.parse(data);
//     if (cb) cb(res);
//   });
// }

function checkShippingPrice(quite, partnerID, addressID, cb) {
  APICaller.ownFetch(quite, "POST", "/checkaddress", { addressID, partnerID }, (data) => {
    let res = JSON.parse(data);
    if (cb) cb(res);
  });
}

export const AddressAPI = {
  get,
  getAddresses,
  saveAddress,
  // deleteAddress,
  checkShippingPrice,
};

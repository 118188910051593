import CrossEventType from "./CrossEventType";
import EventSystem from "../EventSystem";
import ContextSystem from "../ContextSystem";
import { Order } from "../../model/Order";
import { Product } from "../../model/Product";
import { City, ZipCode } from "../../model/Address";

export type CrossEventDataBase = { type: number };
type HandlerType = (msg: CrossEventDataBase, type: number) => void;

// type PartnerMessage = CrossEventDataBase & { partner: Shop };
// type LoggedOutEventData = CrossEventDataBase & { id: number };
type OrdersChangedEventData =
  CrossEventDataBase
  & { orders: Order[], products: Product[], cities: City[], zipCodes: ZipCode[] };

// noinspection JSUnusedLocalSymbols
const HandleOrderChanged: HandlerType = (msg: OrdersChangedEventData, type: number) => {
  ContextSystem.mergeOrders(msg.orders, msg.products, msg.cities, msg.zipCodes);
};

const HandleShopOpenedClosed: HandlerType = (msg: PartnerMessage, type: number) => {
  EventSystem.publish(EventSystem.events.partnerOpened, {opened: type === CrossEventType.SHOP_OPENED, shop: msg.partner});
};

let crossEventHandlerTable: {
  [type: number]: HandlerType;
} = {};
crossEventHandlerTable[CrossEventType.ORDER_CHANGED] = HandleOrderChanged;

export default crossEventHandlerTable;

import * as React from "react";

function BlundeeProfileIconFilled(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 22"
      fill={"currentColor"}
      stroke={"currentColor"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7.87853 9.95507C7.77758 9.94498 7.65642 9.94498 7.54537 9.95507C5.1425 9.87431 3.23438 7.9056 3.23438 5.48258C3.23438 3.00909 5.23337 1 7.717 1C10.1905 1 12.1996 3.00909 12.1996 5.48258C12.1895 7.9056 10.2814 9.87431 7.87853 9.95507Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M2.83241 13.6805C0.389197 15.316 0.389197 17.9813 2.83241 19.6068C5.60878 21.4644 10.1621 21.4644 12.9384 19.6068C15.3817 17.9712 15.3817 15.3059 12.9384 13.6805C10.1722 11.8329 5.61888 11.8329 2.83241 13.6805Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
}

export default BlundeeProfileIconFilled;

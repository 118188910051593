import APICaller from "../APICaller";
import ContextSystem from "../ContextSystem";
import { Shop } from "../../model/Shop";
import { ShippingPrice } from "../../model/ShippingPrice";
import { City, Country, GeoMultiPolygon, ZipCode } from "../../model/Address";
import { RestaurantHeart } from "../../model/RestaurantHeart";
import EventSystem from "../EventSystem";
import { Category, Product } from "../../model/Product";
import type { MessageBase } from "../ws/MessageHandler";

export type GetRestaurantType = MessageBase & { products: Product[], partner: Shop, categories: Category[], restaurantHeart: RestaurantHeart, shippingPrices: ShippingPrice[], shippingCities: City[], productsSize: number, zipCodes: ZipCode[], countries: Country[]};
function get(restaurantName: string, cb: (res: GetRestaurantType)=>{}) {
  //newVersion --> polygon structure version GeoMultiPolygon or GeoPolygon
  APICaller.ownFetch(false, "PUT", "/restaurant", {
    url: restaurantName,
    newVersion: true,
    noShippingPrices: true
    //noProducts: true TODO later
  }, (data) => {
    let res = JSON.parse(data);
    // console.log("getRestaurantJSON: ", res);
    if (cb)
      cb(res);
  });
}

export type GetRestaurantByIDMessage = MessageBase & { partner: Shop, restaurantHeart: RestaurantHeart };

function getByID(quite: boolean, partnerID: number, cb: (res: GetRestaurantByIDMessage)=>{}) {
  APICaller.ownFetch(quite, "PUT", "/restaurant", {
    id: partnerID,
    newVersion: true,
    noShippingPrices: true
    //noProducts: true TODO later
  }, (data) => {
    let res = JSON.parse(data);
    // console.log("getRestaurantJSON: ", res);
    if (cb)
      cb(res);
  });
}

function getShippingPrices(quite: boolean, partnerID: number, cb: (res: MessageBase & { shippingPrices: ShippingPrice[], cities: City[], polygons: GeoMultiPolygon[] })=>{}) {
  APICaller.ownFetch(quite, "POST", "/restaurant/shippingPrices", { id: partnerID, newVersion: true }, (data) => {
    let res = JSON.parse(data);
    // console.log("getRestaurantJSON: ", res);
    if (cb)
      cb(res);
  });
}

function getHearts(cb: (res: MessageBase & { restaurantHearts: RestaurantHeart[] })=>{}) {
  APICaller.ownFetch(true, "POST", "/restauranthearts", {}, (data) => {
    let res = JSON.parse(data);
    if (cb)
      cb(res);
  });
}

function heart(hearted: boolean, partnerID: number, cb: (res: { error: number, type: number })=>{}) {
  if (ContextSystem.loggedIn === false) {
    let redirectPath = window.location.pathname;
    EventSystem.publish(EventSystem.events.open_login_popup, {
      cb: () => {
        EventSystem.publish(EventSystem.events.redirectIntent, { redirectPath });
        let method = hearted === true ? "POST" : "DELETE";
        APICaller.ownFetch(false, method, "/restaurantheart", { partnerID }, (data) => {
          let res = JSON.parse(data);
          // console.log("selectPartnerResJSON: ", res);
          if (cb)
            cb(res);
        });
      }
    });
    return;
  }
  let method = hearted === true ? "POST" : "DELETE";
  APICaller.ownFetch(false, method, "/restaurantheart", { partnerID }, (data) => {
    let res = JSON.parse(data);
    // console.log("selectPartnerResJSON: ", res);
    if (cb)
      cb(res);
  });
}

function register(contactFirstName, contactLastName, contactTel, contactEmail, restaurantAddressPostalCode, restaurantAddressCity, restaurantAddressStreet, restaurantName, cb) {
  APICaller.ownFetch(false, "PUT", "/partner",
    {
      contactFirstName,
      contactLastName,
      contactTel,
      contactEmail,
      restaurantAddressPostalCode,
      restaurantAddressCity,
      restaurantAddressStreet,
      restaurantName
    },
    data => {
      let res = JSON.parse(data);
      // console.log("selectPartnerResJSON: ", res);
      if (cb)
        cb(res);
    });
}

export const RestaurantAPI = {
  get,
  getByID,
  heart,
  register,
  getShippingPrices,
  getHearts
};

import ContextSystem from "./ContextSystem";
import React from "react";
import { NavLink } from "react-router-dom";
import Config from "./Config";
import { FaUserCircle } from "react-icons/fa";

export const Names = {
  HomePageButtonText: ["Olvass a Blundee appról", "Read about Blundee"],
  PayLater: ["Fizetés később", "Pay later"],
  ClearCart: ["Kosár törlése", "Clear cart"],
  RecommendARestaurant: ["Ajánlok éttermet", "Recommend a restaurant"],
  SavedAddressNotFound: ["Mentett cím nem található", "Saved address not found"],
  ZipCodeNotFound: ["Irányítószám nem található", "Zipcode not found"],
  OrderNumberNotFound: ["Rendelészám nem található", "Order number not found"],
  MixedCartShops: ["A kosárban már szerepel termék egy másik étterem kínálatából, így ha ez kosárba kerül, akkor törölni kell a mostani kosár tartalmát.", "The cart already contains products from another shop, so if this new product is added to the cart, the contents of the current cart must be cleared."],
  MixedProductsCartOrdered: ["A rendelt ételek nem egy étteremből származnak! Kérlek töröld a kosarad és próbáld újra.", "The order contains products from mixed restaurants. Please clear your cart and try again."],
  Max50Cart: ["Max. 50 db helyezhető egy adott a termékből a kosárba!", "You can only order 50 items at once."],
  SoftwareFunctions: ["Étteremmenedzsment szoftver (POS) funkciók", "Restaurant management software (POS) functions"],
  SelectYourLanguage: ["Applikáció nyelve", "Language of the app"],
  BookCompleted: ["Sikeres asztalfoglalás! ✔️", "Book completed! ✔️"],
  SpecialRequest: ["Speciális igények, megjegyzés", "Special requests, comment"],
  TableReservationCommentPlaceholder: ["A megjegyzést továbbítjuk, az étterem a lehető legtöbbet tesz meg annak megvalósítása érdekében, viszont nem garantált a Blundee részéről.", "We will pass on your special request, the restaurant's staff will do their best to make it happen, but it is not guaranteed by Blundee."],
  NoReviewsYet: ["Még nincsenek értékelések", "No reviews yet"],
  Reviews: ["Értékelések", "Reviews"],
  SureLogout: ["Biztosan ki szeretnél jelentkezni?🤨 Újra bejelentkezés feleslegesen macera lehet.🤔", "Are you sur about signing out?🤨 It could be uncomfortably tricky to sign in again.🤔"],
  FreeTable: ["szabad asztal", "free table"],
  BugReport: ["Hibabejelentés", "Bug report"],
  WhoWeAre: ["A csapat", "Meet the Team"],
  Slide1Title: ["Üdv a Blundee oldalán", "Welcome to the Blundee experience"],
  Slide1Text: [
    (
      <>
        A Blundee küldetése, hogy a vendéglátás hagyományai, és a digitalizáció adta lehetőségek között harmóniát
        teremtve, rendkívülivé varázsoljuk a kimozdulás élményét.
      </>
    ),
    (
      <>
        At Blundee, we're on a mission to transform your dining experience into something extraordinary. Say goodbye to
        the old ways of dining and hello to the future of food, technology, and unforgettable moments.
      </>
    )
  ],
  Slide2Text: [
    (
      <>
        Khaled és Marci együtt alkotják a Blundee szívét és lelkét. Küldetésük? Újradefiniálni az étkezés élményét,
        összekapcsolni a vendéglátóhelyeket és a kimozdulás szerelmeseit, egy teljesen új módon.
      </>
    ),
    (
      <>
        Together, Khaled and Marci are the brains and heart of Blundee. Their mission? To redefine dining experiences
        and connect restaurants and food lovers like never before.
      </>
    )
  ],
  SMSDescription: [
    (
      <>
        Készen állsz, hogy csatlakozz a Blundee élményhez?<br/>
        A folyamat nagyon egyszerű, add meg a telefonszámod és az SMS-ben kapott hitelesítő kód segítségével már be
        léphetsz.<br/>
        A személyes adataid a hatályos GDPR jogszabályoknak megfelelően, a legnagyobb biztonságban kezeljük.
      </>
    ),
    (
      <>
        Ready to join the Blundee revolution? 📱<br/>
        It's as easy as 1-2-3. To get started, we'll need your phone number. Don't worry; we value your privacy and
        security.
      </>
    )
  ],
  ResendSMS: ["SMS újraküldése", "Resend SMS"],
  LetsGo: ["Kezdjünk neki", "Let's get started!"],
  Send: ["Küldés", "Send"],
  VerifySMS: ["Ellenőrzés", "Verify"],
  PleaseCheckYourInternetConnection: ["Kérlek ellenőrizd az internetkapcsolatod.", "Please check your Internet connection"],
  EmailAddressNotValid: ["Hibás email cím!", "Email address is not valid"],
  PleaseLogIn: ["Kérlek jelentkezz be.", "Please sign in."],
  PleaseLogInToOrder: ["Rendelés leadásához kérlek lépj be előbb.", "Please sign in first to place your order."],
  DatabaseError: ["Adatbázis hiba.", "Database error"],
  EMAIL_REGISTERED: ["Megadott email címmel már létezik fiók! Ha elfelejtetted a jelszavadat, a bejelentkezés oldalon tudsz jelszó visszaállítást kérni.", "An account already exists with the email address you entered! If you forgot your password, you can request a password reset on the login page."],
  SHIPPING_PRICE_NOT_FOUND: ["Megadott email címmel már létezik fiók! Ha elfelejtetted a jelszavadat, a bejelentkezés oldalon tudsz jelszó visszaállítást kérni.", "An account already exists with the email address you entered! If you forgot your password, you can request a password reset on the login page.", "There is no transport municipality or area within the restaurant zones."],
  Occupancy: ["Foglaltság", "Occupancy"],
  _30min: ["30 perc", "30 minutes"],
  _1h: ["1 óra", "1 hour"],
  _1_5h: ["1,5 óra", "1.5 hour"],
  _2h: ["2 óra", "2 hours"],
  _3h: ["3 óra", "3 hours"],
  StartReservationBtnText: ["Foglalás", "Select"],
  Book: ["Lefoglalom", "Book"],
  ReservationFilterWhen: ["Foglalás napja", "Day"],
  RestaurantNotFound: ["Étterem nem található.", "Restaurat not found."],
  RESTAURANT_CLOSED_FOR_TODAY: ["Az étterem ideiglenesen bezárt, emiatt nem fogad most új rendeléseket.", "The restaurant is temporarily closed and therefore not accepting new orders at the moment."],
  Arrival: ["Érkezés", "Arrival"],
  TimeAndPlannedStay: ["Időpont és tervezett időtöltés", "Time and planned stay"],
  Time: ["Időpont", "Time"],
  NumberOfGuests: ["Vendégek száma", "Number of guests"],
  ToChangeYourDetailsClickHere: ["Személyes adataidat itt tudod módosítani.", "Change your profile details here."],
  Guests: ["Vendégek", "Guests"],
  Period: ["Időtartam", "Period"],
  PleaseZoomIn: ["Kérlek közelíts a térképen a szabad asztalokért.", "Please zoom in a little to seatch for avaiable tables."],
  ProductNotAvailableNow: ["A termék nem elérhető", "The product is not available"],
  ProductNotAvailableNowAtCart: ["A kosárban van olyan termék ami nem elérhető a kérd időpontra. Kérlek töröld a kosárból mielőtt leadod a rendelést.", "Your cart contains some products which are not available at this time. Please remove them before ordering."],
  ScheduleDateMaxText: ["Sajnos ilyen későre nem lehet időzített rendelést leadni. Kérlek módosítsd az időzítés dátumát (max. ", "Unfortunately it is not possible to place a pre-order that far. Please change the scheduled date (max. "],
  ScheduleDateMaxText2: [" nap).", " day(s) )."],
  TableForMin1HourText: ["Asztalokat minimum 30 perccel előtte foglalhatsz le.", "You can reserve tables at least 30 minutes in advance."],
  ScheduleDateMin1HourText: ["Rendelés időzítése minimum 75 perccel későbbre lehetséges. Ha 75 percen belül kéred a rendelésed, add le nem időzített rendelésként", "You can place a pre-order at least 75 minutes from now. If you would like to get the order in the next 75 minutes, please order it normally."],
  ScheduleOrderButtonText: ["Időzítés beállítása", "Set schedule"],
  ScheduleOrder: ["Rendelés időzítése", "Schedule order"],
  ScheduleOrderPlusMinus10: [" (± 10p)", " (± 10 min.)"],
  NoPreOrdersText: ["Időzített rendelés nem elérhető jelenleg.", "Pre-orders are not available at this time."],
  AtPlaceFinishedTitle: ["Köszönjük, hogy Blundeeztál egy jót 🤩", "Thanks for Blundee-ing"],
  AtPlaceFinishedText: ["Köszönjük a vásárlást! Ne feledd jelezni az étterem felé, hogy hamarosan rendeznétek a számlát. 😉", "Thank you for your purchase! Be sure to signal to the restaurant that you will settle the bill soon. 😉"],
  FinishAtPlaceConfirmationTitle: ["Asztal lezárása?", "Closing this table?"],
  FinishAtPlaceConfirmationText: ["Ha befejezted a rendeléseket, nyugodtan zárd le az asztalt, hogy rendezni tudd az étteremmel a számlát.", "Once you have completed your orders, feel free to close the table so you can settle the bill with the restaurant."],
  FinishAtPlaceYesButton: ["Végeztem", "I'm done"],
  TableName: ["Asztal", "Table"],
  LastOrder: ["Utolsó rendelés", "Last order"],
  Product: ["termék", "product"],
  TableEmpty: ["Nincs még leadott rendelés", "No orders placed yet"],
  FinishTableReservationText: ["Végeztem", "I'm done"],
  RemainingTime: ["Hátravan", "Remaining"],
  minutes: ["perc", "minutes"],
  min: ["p", "min"],
  OrderStateNew: ["Visszaigazolásra vár", "Waiting for confirmation"],
  OrderStateConfirmed: ["Hamarosan készítik", "Soon at the kitchen"],
  OrderStateConfirmedScheduled: ["Rendelés időzítve", "Scheduled"],
  OrderStateDeclined: ["Elutasítva", "Declined"],
  OrderStateDeleted: ["Törölve", "Deleted"],
  OrderStateDone: ["Kész", "Done"],
  OrderStateDoneAtPlace: ["Tálalva", "Served"],
  OrderStateReadyAtPlace: ["Hamarosan tálaljuk!", "Serve soon!"],
  OrderStateReadyDelivery: ["Futárnál!", "At delivery!"],
  OrderStateReadyTakeaway: ["Átvehető!", "Ready for takeaway!"],
  Preparing: ["Mindjárt kész", "Preparing"],
  OrderState3Dot: ["Állapot...", "State..."],
  OrderStateFailedTrans: ["Meghiúsult", "Failed"],
  WaitingForOnlinePayment: ["Online fizetésre vár", "Waiting for online payment"],
  DeliveryAddress: ["Szállítási cím", "Delivery to"],
  More: ["Továbbiak", "More"],
  AutomaticTakeawayChangeNotificationText: ["Az üzlet nem vállal házhozszállítást, így bekapcsolatam az 'Elvitel' módot.", "This shop doesn't take orders for delivery, so we have enabled the 'Takeaway' in the address selection box."],
  AutomaticDeliveryChangeNotificationText: ["Az üzlet nem fogad rendeléseket elvitelre, így az ki lett kapcsolva.", "This shop doesn't take orders for takeaway, so we have disabled it in the address selection box."],
  AutomaticScheduleRemovedNotificationText: ["Az üzlet nem fogad időzített rendeléseket, így azt kikapcsoltam.", "This shop doesn't take pre-orders, so we have disabled it for now."],
  AutomaticScheduleOnlyOnlinePaymentNotificationText: ["Az üzlet időzített rendelés esetén csak online fizetést fogad el. Kérlek válassz egy másik fizetési módot.", "This shop only takes online payments on pre-orders. Please select a payment method."],
  ForPreOrderOpenHoursText2: ["Előrendelésre, nyitás: ", "PreOrder, Shop opens at "],
  ForPreOrderOpenHoursText: ["Előrendelésre, kiszállítás kezdése: ", "PreOrder, Delivery starts at "],
  OpenOpenHoursTextPart2: ["-ig", ""],
  OpenOpenHoursTextPart1: ["Nyitva ", "Open until "],
  ClosedOpenHoursText: ["Zárva, nyitás: ", "Closed until "],
  SelectDonationButton: ["Kiválasztom", "Select an organization"],
  MoreDonationText: ["Szeretnél további összeget adományozni?", "Would you like to donate any extra?"],
  NoMoreDonationButton: ["Nem szeretnék", "No"],
  Error: ["Hiba", "Error"],
  DonationTotal: ["Támogatások", "Donations"],
  DonationText: ["Rendeléseddel támogass egy civilszervezetet! ❤️", "Support a nonprofit organization with your order! ❤️"],
  DonationDetails: ["Ajánld fel a rendelésed 1%-át! (Ez nem kerül extra költségbe számodra 😉)", "Offer 1% of your order! (It won't cost you extra 😉)"],
  DonationPopupTitle: ["Melyik szervezetet támogatnád? ❤️", "Which organizaton would you donate to? ❤️"],
  DonationPopupDetails: ["A rendelésed 1%-át mi elutaljuk a kiválasztott civilszervezetnek, ami természetesen Neked nem kerül extra költségbe.", "We will transfer 1% of your total to the selected nonprofit organization. This gentle move will not cost you any extra."],

  MonthNames: [
    ["Január", "Február", "Március", "Április", "Május", "Június", "Július", "Augusztus", "Szeptember", "Oktober", "November", "December"],
    ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  ],
  DayNames: [
    ["Hétfő", "Kedd", "Szerda", "Csütörtök", "Péntek", "Szombat", "Vasárnap"],
    ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
  ],
  DayNamesShort: [
    ["H", "K", "Sze", "Cs", "P", "Szo", "V"],
    ["M", "T", "W", "T", "F", "S", "S"]
  ],

  WhatIsPageButtonText: ["Lássunk neki!", "Let's get started!"],
  WhatIsPageTitle: ["Miről is szól a Blundee?", "So, what is Blundee about?"],
  WhatIsPagePost: [
    (
      <>
        <h2>👉 Üdvözöllek a Blundee közösségben!🙋</h2>
        <p>
          A Blundee nem egy átlagos ételrendelős, menünézegetős platform. A Blundee a vendéglátás jövője.
        </p>
        <p>
          Lépésről lépésre olyan megoldásokat hozunk el nektek, amivel, nem csak kényelmesebb lesz a kimozdulás, de
          sokkal nagyobb élmény is. Ez egy hosszú folyamat, aminek még az elején járunk.
        </p>
        <p>
          A Blundee csapata hisz abban, hogy az étkezés többről szól, mint egyszerű táplálkozásról: az együttlétről, a
          felfedezéséről, a maradandó élményekről, a kapcsolódásról és kikapcsolódásról.
        </p>
        <p>
          Éppen ezért célunk, hogy a Blundee ne csak egy platform legyen, de egy olyan közösség is, ahol az éttermek és
          a vendégek is kapcsolódni tudnak.
        </p>
        <p>
          Platformunk segítségével böngészheted a partnereink étlapját, felfedezhetsz csak Blundee felhasználóknak szóló
          exkluzív ajánlatokat és elviteles rendeléseket is leadhatsz.
        </p>
        <p>
          De ez még csak a kezdet. Azon dolgozunk, hogy harmóniát teremtve személyességben és digitalizációban még jobbá
          tegyük az éttermi kalandjaid.
        </p>
        <p>
          Hamarosan már közvetlenül a telefonodról rendelhetsz és fizethetsz, a vendéglátóhelyeken belül is, könnyedén
          foglalhatsz asztalt, írhatsz véleményt, oszthatod meg a rendelésed, szervezhetsz programokat és számos más
          izgalmas funkciót is kipróbálhatsz.
        </p>
        <h2>A Blundee története</h2>
        <p>
          Már egy pár hete “becsődölt” az EnniAkarok.hu, a Blundee-t megelőző vendéglátóipari startup, új piaci
          problémákat kerestünk hónapokig, amikor egy éttermi étkezés megváltoztatott mindent.
        </p>
        <p>
          Be akartunk ülni egy ebédelni nagyobb baráti társasággal augusztus 20.-án, a Bazilika környékén.
        </p>
        <p>
          Újak voltunk a belvárosban, foglalásunk nem volt, a telefont senki nem vette már fel, fogalmunk sem volt,
          melyik helyen, milyen árak, ételek és szokások vannak.
        </p>
        <p>
          Benéztünk egy pár helyre, de sehol sem kaptunk asztalt. Végül találtunk egyet, de nem volt elég étlap, szóval
          kaptunk 3-at, 7 főre. Nagyon elhúzódott emiatt a rendelés, az ételek is későn érkeztek.
        </p>
        <p>
          Aztán kiderült, hogy rosszul vették fel a rendelést, ami miatt az egyik tejérzékeny barátunk egy adag sajtot
          is kapott a spagettijére. Szóval neki meg kellett várnia az új adag érkezését is.
        </p>
        <p>
          Amikor végeztünk, egy negyed óráig integettünk a pincérnek mire észrevett minket, nem tudtuk megbontani a
          számlát, senkinek nem volt annyi a kártyáján, hogy egyben ki tudja fizetni, így készpénzben kellett
          összelegózni a végösszeget, majd azt, hogy ki-kinek mennyit fog utalni.
        </p>
        <p>
          Bármennyire is negatívan hangzik a történet, mi pozitívan álltunk az eseményekhez és tudtuk, hogy ezek mind
          olyan problémák, amikre nekünk már van megoldásunk, csak át kell alakítani az EnniAkarok.hu online működését,
          úgy hogy az étteremben, vagyis az ”offline” vendéglátásban is használható legyen.
        </p>
        <p>
          Ezután egy filmbe illő jelenet következett: “Az étteremből kijőve Khaled és Marci összenéztek és tudták, hogy
          ugyanarra gondolnak. Végig a jó megoldás volt a kezükben, csak rosszul próbálták alkalmazni.”
        </p>
        <p>
          Onnantól egészen hajnalig azon ötletéltünk, hogyan fogjuk átalakítani a szoftvert, hogy minél hamarabb el
          tudjunk kezdeni ezeket a problémákat javítani.
        </p>
        <p>
          Így született meg a cél, hogy építsünk egy appot, amivel legyen szó reggeliről, ebédről, vagy vacsoráról (azaz
          angolul “Breakfast, LUNch, DEEner”-ről, vagyis BLUNDEE-ról), a kimozdulás mindig élmény legyen.
        </p>
        <h2>Hogyan használd a Blundee appot</h2>
        <p>
          A főoldalon megtalálod a Blundee partnereket, tudsz keresni vendéglátóhelyre és konkrét finomságokra is.
          Kereső oldalon a szűrővel árkategóriát is tudsz meghatározni és nem csak éttermekre, de konkrét ételekre is
          kereshetsz (pl. hamburger).
        </p>
        <p>
          Ha egy partnernek van különleges ajánlattal, vagy Blundee ajánlattal is készül nektek, azt az étterem ikonján
          egy sárga matricával jelezzük. Ugyan így teszünk a SZÉP kártya elfogadásának lehetőségével is.
        </p>
        <h2>Partnereknek</h2>
        <p>
          Ha vendéglátóhelyed van és szeretnél Blundee partner lenni, jelentkezz ezen a kérdőíven és felvesszük veled a
          kapcsolatot. További információk a <NavLink style={{margin: "0 5px"}} to={"/csatlakozas"}>csatlakozás</NavLink> oldalon.
        </p>
      </>
    ),
    (
      <>
        <h2>👉 Welcome to the Blundee community!🙋</h2>
        <p>
          Blundee is not an ordinary food ordering and menu viewing platform. Blundee is the future of hospitality.
        </p>
        <p>
          Step by step, we bring you solutions that will not only make moving out more comfortable, but
          a much greater experience. It's a long process that we're just at the beginning of.
        </p>
        <p>
          The Blundee team believes that eating is about more than simple nutrition: being together, a
          about discovery, lasting experiences, connection and relaxation.
        </p>
        <p>
          That is why we aim to make Blundee not only a platform, but also a community where restaurants and
          guests can also connect.
        </p>
        <p>
          With the help of our platform, you can browse the menu of our partners and discover only for Blundee users
          you can also place exclusive offers and takeaway orders.
        </p>
        <p>
          But this is just the beginning. We are working to make it even better by creating harmony in terms of personality and digitalization
          let's make your restaurant adventures.
        </p>
        <p>
          You will soon be able to order and pay directly from your phone, easily even in restaurants
          you can reserve a table, write a review, share your order, organize programs and many others
          you can also try an exciting function.
        </p>
        <h2>History of Blundee</h2>
        <p>
          A few weeks ago, EnniAkarok.hu, the catering industry startup that preceded Blundee, went bankrupt, a new market
          we looked for problems for months when a restaurant meal changed everything.
        </p>
        <p>
          We wanted to sit down for lunch with a larger group of friends on August 20, around the Basilica.
        </p>
        <p>
          We were new in the city center, we didn't have a reservation, no one answered the phone, we had no idea,
          in which place, what prices, food and customs are there.
        </p>
        <p>
          We looked at a couple of places, but we didn't get a table anywhere. We finally found one, but there wasn't enough of a menu, so
          we got 3, for 7 people. Because of this, the order took a long time, and the food arrived late.
        </p>
        <p>
          Then it turned out that they had taken the order wrong, causing one of our dairy-sensitive friends to have a batch of cheese
          also got on his spaghetti. So he also had to wait for the new batch to arrive.
        </p>
        <p>
          When we finished, we waved to the waiter for a quarter of an hour before he noticed us, we couldn't break the
          bill, no one had enough on their card to pay it all at once, so they had to pay in cash
          sum up the total amount, and then how much you will transfer to whom.
        </p>
        <p>
          No matter how negative the story sounds, we were positive about the events and knew that they were all
          problems for which we already have a solution, we just need to transform the online operation of EnniAkarok.hu,
          so that it can also be used in the restaurant, that is, in "offline" hospitality.
        </p>
        <p>
          Then a scene fit for a movie followed: "Coming out of the restaurant, Khaled and Marci looked at each other and knew that
          they think the same thing. They had the right solution all along, they just tried to apply it wrongly."
        </p>
        <p>
          From then until dawn, we worked on the idea of how we were going to transform the software so that it could be done as soon as possible
          we can start fixing these problems.
        </p>
        <p>
          This is how the goal was born to build an app that can be used for breakfast, lunch or dinner (i.e.
          in English from "Breakfast, LUNch, DEEner", i.e. BLUNDEE), going out should always be an experience.
        </p>
        <h2>How to use the Blundee app</h2>
        <p>
          On the main page, you can find Blundee partners, you can also search for restaurants and specific delicacies.
          With the filter on the search page, you can also define a price category and not only for restaurants, but also for specific dishes
          you can search (e.g. hamburger).
        </p>
        <p>
          If a partner has a special offer, or Blundee is preparing an offer for you, you can see it on the restaurant icon
          indicated by a yellow sticker. We do the same with the possibility of accepting the SZÉP card.
        </p>
        <h2>For partners</h2>
        <p>
          If you have a restaurant and would like to become a Blundee partner, apply on this questionnaire and we will take you
          relationship. More information <NavLink style={{margin: "0 5px"}} to={"/csatlakozas"}>here</NavLink>.
        </p>
      </>
    )
  ],

  RestaurantPageTitle: ["Étteremoldal - Blundee", "Restaurant page - Blundee"],
  SpecialOffers: ["Kiemelt ajánlataink", "Special offers"],
  RegisterPageTitle: ["Regisztráció", "Sign up"],
  RegisterPageDescription: ["Csak néhány információra van szükség, és már készen is vagyunk!", "We're almost done! You need to enter a couple information."],

  WhatIsThisApp: ["Mi ez az app?", "What is Blundee?"],
  SavedAddresses: ["Elmentett címek", "Saved addresses"],
  Account: ["Profil", "Account"],
  BackToHomeScreen: ["Vissza a kezdőlapra", "Back to home screen"],
  BackToTable: ["Vissza az asztalhoz", "Back to table"],
  ToTable: ["Asztalhoz", "Go to table"],
  OrderNumber: ["Rendelésszám", "Order number"],
  TransactionNumber: ["Tranzakciószám", "Transaction number"],
  ThanksForOrderingPaymentWasNotSuccess: [
    (
      <>
        Hiba merült fel az online bankkártyás fizetés során! Kérlek lépj velünk kapcsolatba email-en:{" "}
        {Config.email_address_customer_service.replace("@", "[@]")}.<br/>
      </>
    ),
    (
      <>
        An error occured at the online credit card payment process. Please contact us via email:{" "}
        {Config.email_address_customer_service.replace("@", "[@]")}.<br/>
      </>
    )
  ],
  ThanksForOrderingPaymentSuccess: [
    (
      <>
        Sikeres online bankkártyás fizetés! Erről hamarosan egy email-t is küldünk.
        <br/>
        A rendelésed részleteit és aktuális állapotát bejelentkezés után a
        <span style={{
          display: "inline-flex",
          flexDirection: "row",
          alignItems: "center",
          fontSize: "13pt",
          margin: "2px 5px",
          color: "rgb(200,200,200)"
        }}>
          <FaUserCircle style={{fontSize: "16pt", marginRight: "5px"}}/> Profil
        </span> menüponton látod.
      </>
    ),
    (
      <>
        Online payment was successful! An email will be sent to you with all details shortly.
        <br/>
        You can see your order's details at
        <span style={{
          display: "inline-flex",
          flexDirection: "row",
          alignItems: "center",
          fontSize: "13pt",
          margin: "2px 5px",
          color: "rgb(200,200,200)"
        }}>
          <FaUserCircle style={{fontSize: "16pt", marginRight: "5px"}}/> Profile
        </span>
        menu item after login.
      </>
    )
  ],
  ThanksForOrdering: ["Köszönjük a vásárlást!", "Thank you for your order!"],

  EnterYourNewPasswordDescription: ["Kérlek add meg az új jelszavad, hogy be tudj lépni később az oldalra!", "Please enter your new password, so you can sign in later on."],
  EnterYourNewPassword: ["Add meg az új jelszavad!", "Enter your new password"],
  PasswordResetPageTitle: ["Blundee - Jelszó visszaállítás", "Blundee - Reset password"],

  IDontHaveAccount: ["Még nincs fiókom", "I don't have an account yet"],
  LoginPageTitle: ["Blundee - Bejelentkezés", "Blundee - Sign In"],
  ReservationPageTitle: ["Blundee - Asztalfoglalás", "Blundee - Table reservation"],
  Reservation: ["Foglalás", "Reservation"],

  TryItOut: ["Ajánlatot kérek", "Request for offer"],
  Morning: ["Délelőtt", "Morning times"],
  Afternoon: ["Délután", "Afternoon"],
  Evening: ["Kora esti órák", "Early evening"],
  WhenCanWeCallYou: ["Mikor kereshetjük?", "When can we call you?"],
  JoinUsYTVideoLabel: ["Nézze meg, hogyan működünk!", "See how it works!"],
  ContactUs: ["Lépjen velünk kapcsolatba!", "Contact Us!"],
  OurContact: ["Elérhetőségeink", "You can contact us here"],
  JoinUsSoftwareFunctions: [
    (
      <>
        <ul>
          <li>Cloud alapú rendszer, nem kell telepíteni, csak egy tablet / számítógép kell böngészővel</li>
          <li>Nyersanyagkezelés, raktárkezelés</li>
          <li>Automatikus étlapszinkronizáció Foodpanda, Wolttal</li>
          <li>Foodpanda, Wolt rendelés integráció - nem kell többé 3 eszköz</li>
          <li>Rendeléskezelés, vendég értesítés</li>
          <li>Asztalok, foglalások kezelése. Online automatikus foglalások</li>
          <li>QR kódos online étlap - a zöldebb és hatékonyabb étlap menedzsmentért</li>
          <li>Azonosított vendég-CRM kezelés (SMS azonosítással)</li>
          <li>Beépített házhozszállítás modul</li>
          <li>Beépített Pultos nézet</li>
          <li>Beépített Standolás és nyersanyagkészlet nyomkövetés</li>
          <li>Beépített KDS konyhakijelző</li>
          <li>Profi statisztika, átlátható étterem, könnyű kezelés</li>
          <li>Többnyelvűség támogatása</li>
          <li>Beépített futár menedzsment modul (fejlesztés alatt...)</li>
        </ul>
        Mindez 1.969Ft/hó áron
      </>
    ),
    (
      <>
        <ul>
          <li>Cloud alapú rendszer, nem kell telepíteni, csak egy tablet / számítógép kell böngészővel</li>
          <li>Nyersanyagkezelés, raktárkezelés</li>
          <li>Automatikus étlapszinkronizáció Foodpanda, Wolttal</li>
          <li>Foodpanda, Wolt rendelés integráció - nem kell többé 3 eszköz</li>
          <li>Rendeléskezelés, vendég értesítés</li>
          <li>Asztalok, foglalások kezelése. Online automatikus foglalások</li>
          <li>QR kódos online étlap - a zöldebb és hatékonyabb étlap menedzsmentért</li>
          <li>Azonosított vendég-CRM kezelés (SMS azonosítással)</li>
          <li>Beépített házhozszállítás modul</li>
          <li>Beépített Pultos nézet</li>
          <li>Beépített Standolás és nyersanyagkészlet nyomkövetés</li>
          <li>Beépített KDS konyhakijelző</li>
          <li>Profi statisztika, átlátható étterem, könnyű kezelés</li>
          <li>Többnyelvűség támogatása</li>
          <li>Beépített futár menedzsment modul (fejlesztés alatt...)</li>
        </ul>
        Mindez 1.969Ft/hó áron
      </>
    )
  ],
  JoinUsParagraph1Text: [
    (
      <>
        A Blundee hisz abban, hogy közösen mindent meg tudunk tenni a koronavírus miatt kialakult
        helyzetben, épp emiatt szeretnénk kivenni a részünket az éttermeken való segítségből. A további
        szükséges korlátozások miatt úgy döntöttünk, hogy a mai naptól (2021.02.05.) a 6%-os csomagú
        szolgáltatásunkat ingyenesen, 0% jutalékért biztosítjuk az összes étteremnek (köztük a meglévő
        partnereinknek is).<br/>
        *Az ingyenes szolgáltatás vége a bezártsági rendelet hatályon kívül helyezés időpontja + 30 nap
        (fellendülési időszak), de legfeljebb június elseje. Ezután minden étterem az eredetileg
        kiválasztott csomagjába kerül vissza.
      </>
    ),
    (
      <>
        Blundee believes that together we can do everything due to the coronavirus
        situation, which is why we want to play our part in helping restaurants. The further
        due to necessary restrictions, we have decided to start with the 6% package from today (05.02.2021)
        We provide our service free of charge for a 0% commission to all restaurants (including the existing
        to our partners as well). <br/>
        * The end of the free service is the date of repeal of the confinement ordinance + 30 days
        (recovery period), but not later than June 1. After that, all restaurants are originally
        will be returned to your selected package.
      </>
    )
  ],
  JoinUsParagraph2Text: [
    (
      <>
        Az Blundee felülete teljes mértékben felhő alapú, csupán egy böngészőt kell megnyitni. Étlapját a
        partner oldalon dinamikusan
        alakíthatja, új ételeket, menüket tölthet fel, módosíthatja és törölheti őket. Ön szabja meg az
        árakat, feltéteket, milyen
        sorrendben jelenljenek meg az ételek.{" "}
      </>
    ),
    (
      <>
        The platform of Blundee is completely cloud-based, you only need to open one browser. You can easily
        modify your menu and settings dinamically, add new foods, menus, modify and delete them. You control the
        prices, conditions, ordering, settings... etc.
      </>
    )
  ],
  JoinUsParagraph3Text: [
    (
      <>
        Csapatunk forradalmian új technológiával dolgozik, melyben a Vásárló akár applikáció letöltése
        nélkül is tud rendelni. A
        szoftvereinket és eszközeinket nap mint nap karbantartjuk, hogy minél egyszerűbb legyen a
        használata. Ezen felül partnereink
        véleményét és tanácsait szívesen vesszük, így az elkövetkező frissítésben lehetőség van az új
        funkciók vagy módosítások
        megvalósítására.
      </>
    ),
    (
      <>
        Our team works with a revolutionary new technology, in which the Customer can order even without downloading an
        application. We maintain our software and tools on a daily basis to make it as easy to use as possible. In
        addition, we welcome the opinions and advice of our partners, so there is an opportunity to implement new
        features or changes in the next update.
      </>
    )
  ],
  JoinUsParagraph4Text: [
    (
      <>
        Új, személyre szabott online felületet biztosítunk, ami nagyban segíti éttermének megtalálhatóságát az
        interneten. Az Blundee segítségével a vásárlók könnyedén, 1-2 perc alatt leadhatják a rendelésüket, az
        étterméről pedig átvállaljuk a felesleges terheket, hogy Ön többet tudjon foglalkozni azzal, amihez a legjobban
        ért.
      </>
    ),
    (
      <>
        We provide a new, personalized online interface that will greatly help you find your restaurant online. With the
        help of Blundee, customers can place their order easily in 1-2 minutes, and we take over the unnecessary
        burdens from the restaurant so that you can deal more with what you do best.
      </>
    )
  ],
  JoinUsParagraph1Title: ["Vírushelyzet szolgáltatási kedvezmények, 0%", "Pandemic discounts, 0%"],
  JoinUsParagraph2Title: ["Egyszerű használat, teljes szabadság", "Pandemic discounts, 0%"],
  JoinUsParagraph3Title: ["Folyamatos szofveres fejlesztés és támogatás", "Continuous software development and support"],
  JoinUsParagraph4Title: ["Hirdetések, kampányok és marketing", "Advertising, campaigns and marketing"],


  Plans: ["Csomagjaink", "Our plans for you"],
  JoinUsHeading: ["Magyarország legmodernebb felhőalapú rendszere az Ön vendéglátóegységének", "Hungary's most modern cloud-based system for your restaurant"],
  JoinUsPageTitle: ["Csatlakozás", "Join us"],
  GreetingsDefault: ["Jó napot!", "Hello there!"],
  Greetings0_4: ["Szép estét!", "Good night!"],
  Greetings4_9: ["Jó reggelt!", "Good morning!"],
  Greetings9_14: ["Jó napot!", "Hey there!"],
  Greetings14_18: ["Szép délutánt!", "Good afternoon!"],
  Greetings18_21: ["Szép estét!", "Good evening!"],
  Greetings21_0: ["Jó éjszakát!", "Good night!"],
  ASZFPageTitle: ["Általános Szerződési Feltételek (ÁSZF) és Adatvédelmi Szabályzat", "Terms and Conditions & Privacy Policy"],
  ASZFTitle: ["ÁSZF", "Terms and Conditions"],
  TermsAndConditions: ["Általános Szerződési Feltételek", "Terms and Conditions"],
  PrivacyPolicy: ["Adatvédelmi tájékoztató", "Privacy Policy"],
  WelcomeScreenASZF: [
    (
      <>
        Általános Szerződési Feltételeinket <NavLink style={{margin: "0 5px"}} to={"/aszf"}>itt</NavLink> olvashatod
        el.
      </>
    )
    ,
    (
      <>
        You can read our Terms and Conditions at <NavLink style={{margin: "0 5px"}} to={"/aszf"}>this</NavLink> page.
      </>
    )
  ],
  Error404Page: [
    (
      <>
        Ooupsz! :( Az oldal nem található!
        <br/>
        404-es hiba
      </>
    )
    ,
    (
      <>
        Ooupsz! :( Can't finde this page
        <br/>
        Error 404.
      </>
    )
  ],
  Budapest: ["Budapest", "Budapest"],
  Cities: ["Városok", "Cities"],
  WelcomeScreenAddressText1: ["Kezdésnek add meg a címed", "First, enter your location"],
  WelcomeScreenAddressText2: ["Vagy válassz a települések közül", "Or choose your city"],
  WelcomeScreenText2: ["Nézzük meg, mit lehet enni a környékeden!", "Let's see what's around you for delivery!"],
  WelcomeScreenGreetings1: ["Szia", "Hi there"],
  WelcomeScreenGreetings2: ["!", ","],
  WelcomeToBlundee: ["Üdv a Blundee világában!", "Welcome to Blundee!"],

  Clear: ["Törlés", "Clear"],
  Search: ["Keresés", "Search"],
  All: ["Összes kiválasztása", "Select all"],
  AllSelected: ["Összes kiválasztva", "All selected"],
  FilterCategoriesPlaceHolder: ["Szűrj kategóriákra...", "Select categories..."],
  FilterPlaceHolder: ["Szűrés...", "Search..."],
  Food: ["Étel", "Food"],
  Restaurant: ["Étterem", "Restaurant"],
  AllCategories: ["Összes kategória", "All categories"],
  AllRestaurants: ["Összes étterem", "All restaurants"],
  SignInDescriptionPersonalizedRec: ["Jelentkezz be, hogy személyre szabott ajánlatokat tudjunk megjeleníteni számodra!", "Sign in for personalized recommendations."],
  RecommendedCategories: ["Ajánlott kategóriák", "Recommended categories"],
  SearchCity: ["Keress városra", "Search for city"],
  Filters: ["Szűrők", "Filters"],
  Categories: ["Kategóriák", "Categories"],
  FindFoodOrRestaurantsPlaceholder: ["Étel, étterem keresése...", "Search for Food or Restaurant..."],
  FindFoodOrRestaurants: ["Keress étteremek és ételek között!", "Find your favourite food or restaurant!"],
  SearchScreenTitle: ["Böngészés - Blundee", "Search - Blundee"],
  UnknownError: ["Ismeretlen hiba lépett fel. Kérlek próbáld újra később.", "Unknown error occured. Please try again later."],
  PermissionDenied: ["Hozzáférés megtagadva.", "Permisson denied."],
  Table_QR_ID_NotFoundText: ["A beolvasott QR kódhoz nem található asztal 😔", "I couldn't find any table for this QR code 😔"],
  NO_TAKEAWAY_AT_THIS_SHOP: ["Az üzletnél nem lehet átvételre rendelni.", "It is not possible to order for delivery at the shop."],
  NO_DELIVERY_AT_THIS_SHOP: ["Az üzletnél sajnos nem működik a házhozszállítás jelenleg.", "Unfortunately, the shop does not offer home delivery at the moment."],
  CANT_DELETE_DATA_FOUND: ["A törlés nem ment végbe, mivel nem törölhető az adat.", "The deletion did not take place because the data cannot be deleted."],
  RESTAURANT_NAME_NOT_VALID: ["A megadott étteremnév helytelen!", "The restaurant name is not valid!"],
  PRODUCT_SCHEDULE_NOT_AVAILABLE: ["Az egyik rendelt termék nem elérhető az adott időpontban. 😔", "One of the ordered products is not available at the given time. 😔"],
  EmailOrPasswordError: ["Email cím vagy jelszó hibás.", "Invalid email or password"],
  RegisterSuccessful: ["Sikeres regisztráció!", "Registered!"],
  PleaseTryAgain: ["Kérlek próbáld újra", "Please try again"],
  PleaseTryAgainLater: ["Kérlek próbáld újra később.", "Please try again later."],
  RegisterButton: ["Regisztráció", "Sign up"],
  VerificationSuccessful: ["Sikeres azonosítás. A Blundee fiókod befejezéséhez add meg az alapvető adataidat.", "Verification successful. To complete your Blundee profile, please enter your name, email and a new password."],
  GiveYourAccountsNewPassword: ["Add meg a fiókod új jelszavát", "Enter a new password for your account"],
  GiveYourDetails: ["Kérlek add meg a fiókod adatait.", "Please enter your profile details."],
  Toppings: ["Feltétek", "Toppings"],
  Ok: ["Rendben", "OK"],
  Ok2: ["Ok", "OK"],
  Today: ["Ma", "Today"],
  Yesterday: ["Tegnap", "Yesterday"],
  Hours: ["Óra", "Hours"],
  Minutes: ["Perc", "Minutes"],
  Seconds: ["Másodperc", "Seconds"],
  MonthPattern: ["yyyy. MMMM", "MMM yyyy"],
  DayPattern: ["yyyy.MM.dd.", "dd MMM yyyy"],
  Last7Days: ["Elmúlt 7 nap", "Last 7 days"],
  RestaurantAddedThanksDetails: [
    (
      <>
        Igényét rögzítettük, hamarosan egy Blundee kolléga felkeresi Önt a megadott telefonszámon.
      </>
    ),
    (
      <>
        We have got your request! A Blundee colleague will get in touch with you as soon as possible.
      </>
    )
  ],
  NothingBasedOnFiltersDescription: [
    (
      <>
        Nem találtam szűrésnek megfelelőeket <span role={"img"} aria-label={"img"}>😕</span>,
        Nézd meg ezeket is <span role={"img"} aria-label={"img"}>👇</span>
      </>
    ),
    (
      <>
        Nothing to show based on these filters <span role={"img"} aria-label={"img"}>😕</span>,
        Why not going through these ones <span role={"img"} aria-label={"img"}>👇</span>?
      </>
    )
  ],
  RestaurantAddedThanks: ["Köszönjük az érdeklődését a Blundee szolgáltatásával kapcsolatban!", "Thank you for your interest in the Blundee service!"],
  CreateRestaurant: ["Csatlakozás a Blundeehoz", "Join Blundee"],
  City: ["Város", "City"],
  RestaurantAddressStreet: ["Utca, házszám", "Street, number"],
  PostalCode: ["Irányítószám", "Postal code"],
  RestaurantAddress: ["Étterem címe", "Address of restaurant"],
  RestaurantName: ["Étterem neve", "Name of restaurant"],
  Details: ["Részletek", "Details"],
  RestaurantDetails: ["Étterem adatai", "Restaurant details"],
  ContactDetails: ["Kapcsolattartó adatai", "Contact details"],
  YouShouldntLeaveAnyFieldEmpty: ["Kérlek ne hagyj üresen egy mezőt sem.", "Please don't leave any field empty."],
  CookiesDetails: [
    (<>
      Weboldalunk használatával elfogadod a cookie-k használatát a Cookie-kkal kapcsolatos irányelv értelmében. További
      részletek az <NavLink to={"/aszf"}>adatvédelmi tájékoztatóban</NavLink> olvashatók.
    </>)
    ,
    (<>
      By using our website, you accept the use of cookies in accordance with the Cookie Policy. See the <NavLink
      to={"/aszf"}>privacy statement</NavLink> for more details.
    </>)
  ],
  AllergyContactDescription: [
    "Allergia vagy diéta esetén a speciális igényekre az alábbi elérhetőségen tájékozódhatsz, hogy biztosan " +
    "az kerüljön bele az ételedbe, amit szeretnél.",
    "In case of allergies or diet, you can find out about special needs by contacting the restaurant below to make sure that your food includes exactly what you would like."
  ],
  MobileCall: ["Telefonos hívás", "Call"],
  Delivery: ["Házhozszállítás", "Delivery"],
  DeliveryShort: ["Szállítás", "Delivery"],
  AtPlace: ["Helyi fogyasztás", "Eat-in"],
  Contact: ["Kapcsolat", "Contact"],
  ShippingPlaces: ["Szállítási területek", "Shipping places"],
  OpeningHours: ["Nyitvatartás", "Opening hours"],
  OrderAcceptHours: ["Nyitvatartás, rendelésfelvételi idő", "Opening hours, ordering time"],

  Close: ["Bezárás", "Close"],
  Closed: ["Zárva", "Closed"],
  RestaurantClosed: ["Étterem zárva", "Restaurant closed"],
  ForPreOrder: ["Előrendelésre", "Pre-order"],
  SavePassword: ["Jelszó mentése", "Save password"],
  NewPassword: ["Új jelszó", "New password"],
  OldPassword: ["Régi jelszó", "Old password"],
  ChangePass: ["Jelszó módosítása", "Change password"],
  PersonalData: ["Személyes adatok", "Personal details"],
  Settings: ["Beállítások", "Settings"],
  NewPasswordSaved: ["Új jelszó mentve!", "Password saved!"],
  Saved: ["Mentve!", "Saved!"],

  PassResetOK: ["A jelszó visszaállításához szükséges információt emailben elküldtük.", "Please check your email account for the recovery link."],
  PassResetError: ["Kérlek próbált újra később vagy írj nekünk egy emailt!", "An error occurred. Please try again later or contact us."],
  AlreadyLoggedIn: ["Már be vagy jelentkezve.", "You are signed in already."],
  IForgotMyPass: ["Elfelejtettem a jelszavam", "Forgot your password?"],
  ForgotPassTitle: ["Jelszóemlékeztető küldése", "Send password recovery link"],
  ForgotPassDescription: ["Kérlek add meg az email címedet. Ha nem emlékeszel rá, írj egy emailt, hogy tudjunk segíteni!", "Please submit your email address so you change your password. If you have forgotten your email address, please call our customer service."],
  Submit: ["Küldés", "Submit"],
  NoImageUploaded: ["Nincs kép feltöltve", "No image"],
  WhichVersionText: ["Melyik változatot szeretnéd?", "Which one would you like to choose?"],
  AddToCart: ["Kosárba", "Add to cart"],
  ChooseToppings: ["Módosítási lehetőségek ", "Options available"],

  ToppingFreeQtyTextPart1: ["Ingyenes ", "Choose "],
  ToppingFreeQtyTextPart2: [" db!", " for free!"],
  ToppingMinMaxQtyTextPart1: ["akármennyi", "freely from these"],
  ToppingMinMaxQtyTextPart2: ["akár ", "at most"],
  ToppingMinMaxQtyTextPart3: ["db", "pcs from these"],
  ToppingMinMaxQtyTextPart4: ["min.", "min."],
  ToppingMinMaxQtyTextPart5: ["db", "pcs from these"],
  ToppingQtyTextPart1: ["Válassz ", "You can choose "],
  ToppingQtyTextPart2: [" db-ot!", "!"],
  ToppingQtyTextPart3: [" db-ot ingyen!", " for free."],


  ToppingMinNotReachedText: ["Nincs elég feltét kiválasztva!", "You need to select more toppings."],
  ToppingMaxReachedTextPart1: ["Ebből a csoportból legfeljebb ", "You can only select "],
  ToppingMaxReachedTextPart2: ["db feltétet választhatsz!", "pcs from this topping."],
  MenuSearch: ["Böngészés", "Search"],
  MenuRestaurant: ["Étterem", "Shop"],
  MenuProfile: ["Profil", "Account"],
  MenuTableReservation: ["Asztalfoglalás", "Reservation"],
  MenuHome: ["Blundee", "Blundee"],
  LogOut: ["Kijelentkezés", "Sign out"],
  KeepIn: ["Mégsem", "Keep me in"],
  Information: ["Információk", "Information"],
  SignIn: ["Bejelentkezés", "Sign in"],
  PleaseGiveYourPhoneNumber: ["Kérlek add meg a telefonszámod. Erre a számra küldünk egy 6 jegyű biztonsági kódot.", "Please enter your phone number. We will send you a 6-digit verification code."],
  ForRestaurants: ["Vendéglátóhelynek", "For restaurants"],
  MenuRedirectTo: ["Ez a menü fog visszairányítani az előzőleg megtekintett étterem oldalára.", "This menu is for going back for the last seen restaurant."],
  OrderASZF: [
    (
      <>
        {"Rendelés leadásával elfogadod az "}
        <NavLink to={"/aszf"}>ÁSZF-et</NavLink>
        {"."}
      </>
    ),
    (
      <>
        {"By placing this order, you accept the "}
        <NavLink to={"/aszf"}>Terms and Conditions</NavLink>
        {"."}
      </>
    )
  ],
  ReservationASZF: [
    (
      <>
        {"Foglalás véglegesítésével elfogadod az "}
        <NavLink to={"/aszf"}>ÁSZF-et</NavLink>
        {"."}
      </>
    ),
    (
      <>
        {"By booking this table, you accept the "}
        <NavLink to={"/aszf"}>Terms and Conditions</NavLink>
        {"."}
      </>
    )
  ],
  NotThereYet: [
    (
      <>
        Még nem tartunk ott 😢
        <br/>
        Ha szeretnéd, hogy a kedvenc éttermed is megjelenjen a Blundee-n, írj nekünk, hogy fel tudjuk venni az
        étteremmel a kapcsolatot:{" "}
        <a target={"_blank"} href={"mailto:" + Config.email_address_customer_service}>
          {Config.email_address_customer_service}
        </a>
      </>
    ),
    (
      <>
        Not there yet 😢
        <br/>
        If you would like your favorite restaurant to appear on Blundee, write to us so that we can contact
        the restaurant:{" "}
        <a target={"_blank"} href={"mailto:" + Config.email_address_customer_service}>
          {Config.email_address_customer_service}
        </a>
      </>
    )
  ],
  ContentOfMenu: ["Menü tartalma", "Content of this menu"],
  OrderButtonText: ["Rendelés leadása", "Place order"],
  GrandTotal: ["Végösszeg", "Grand total"],

  ONLINE_MOBILEPAY_DK: ["Mobilepay", "Mobilepay"],
  TAKEAWAY_CREDIT_CARD: ["Bankkártya helyszínen", "Credit card on premise"],
  LOCAL_SZEP_CARD_MKB: ["Szép-kártya helyszínen (MKB)", "Szép card on premise (MKB)"],
  LOCAL_SZEP_CARD_OTP: ["Szép-kártya helyszínen (OTP)", "Szép card on premise (OTP)"],
  LOCAL_SZEP_CARD_KH: ["Szép-kártya helyszínen (K&H)", "Szép card on premise (K&H)"],
  SZEP_ONLINE_MKB: ["Online Szép-kártya (MKB)", "Szép card online (MKB)"],
  SZEP_ONLINE_OTP: ["Online Szép-kártya (OTP)", "Szép card online (OTP)"],
  SZEP_ONLINE_KH: ["Online Szép-kártya (K&H)", "Szép card online (K&H)"],
  CREDITCARD_ONLINE: ["Online bankkártya", "Credit card"],
  COD_SZEP_KH: ["Helyszíni Szép kártya (K&H)", "Szép card on delivery (K&H)"],
  COD_SZEP_OTP: ["Helyszíni Szép kártya (OTP)", "Szép card on delivery (OTP)"],
  COD_SZEP_MKB: ["Helyszíni Szép kártya (MKB)", "Szép card on delivery (MKB)"],
  CODCredit: ["Helyszíni bankkártya", "Credit card on delivery"],
  Cash: ["Készpénz", "Cash"],
  CashBigBill: ["Készpénz (nagy címlet)", "Cash (big bill)"],

  PaymentMode: ["Fizetési mód", "Payment mode"],
  ShippingMode: ["Szállítási mód", "Shipping mode"],
  ProductsTotal: ["Termékek végösszege", "Total of products"],
  SumUpOrder: ["Rendelés összesítése", "Sum Up Order"],
  Address: ["Cím", "Address"],
  Comment: ["Megjegyzés", "Comment"],
  Shipping: ["Átvétel", "Shipping"],
  Save: ["Mentés", "Save"],
  FirstName: ["Keresztnév", "Given name"],
  LastName: ["Vezetéknév", "Last name"],
  Modify: ["Módosítom", "Modify"],
  PhoneNumber: ["Telefonszám", "Phone number"],
  NotGiven: ["Nincs megadva", "Not given"],
  Name: ["Név", "Name"],
  Password: ["Jelszó", "Password"],
  EmailAddress: ["E-mail cím", "E-mail address"],
  MyInfo: ["Adataim", "Basic info"],
  MyOrders: ["Rendeléseim", "My orders"],
  MyOrder: ["Rendelésem", "Order details"],
  AfterOrderRegisterLabel: ["Adj meg egy jelszót a Blundeednak!", "Create your Blundee with a new password!"],
  AfterOrderRegisterButton: ["Létrehozom a fiókom!", "Create my account!"],
  PayButton: ["Fizetés", "Start payment"],
  OnlinePaymentButtonBelowText: ["Online fizetés indításához kattints a lenti gombra:", "For completing your order, please start your online payment by clicking to the button below:"],
  Order: ["Rendelés", "Order"],
  OrderFinishingPageTitle: ["Blundee - Pénztár", "Blundee - Place order"],
  OrderPlaced: ["Rendelés leadva!", "Order placed!"],
  OrderPlacedText: ["Rendelés leadva! Hamarosan tájékoztatunk a továbbiakról!", "Order placed! We'll reach out to you soon with some updates!"],
  GivenAddressTextIsNotValid: ["A megadott cím helytelen!", "Please enter a valid address."],
  GivenAddressIsNotValid: ["Kérlek add meg a címed újra!", "Please enter your address again."],
  GivenPhoneNumberIsNotValid: ["A megadott telefonszám helytelen!", "Please enter a valid phone number."],
  GivenShopNameIsNotValid: ["A megadott étteremnév helytelen!", "Please enter a valid restaurant name."],
  GivenPostalCodeIsNotValid: ["A megadott irányítószám helytelen!", "Please enter a valid postal code."],
  GivenPostalCodeIsTooShort: ["A megadott város neve túl rövid!", "Please enter a longer city name."],
  GivenLastPasswordTooShort: ["A megadott régi jelszó túl rövid", "Please enter a longer password (old password)"],
  GivenNewPasswordTooShort: ["A megadott új jelszó túl rövid", "Please enter a longer password"],
  PasswordTooShortSafety: ["Kérlek egy legalább 5 karakterből álló jelszavat adj meg a biztonságod érdekében.", "Please enter a longer password for your own safety."],
  GivenEmailIsNotValid: ["A megadott email helytelen!", "Please enter a valid email address."],
  GivenNameIsNotValid: ["A megadott név hiányos!", "Please enter a valid name."],
  SelectPaymentMode: ["Válassz egy fizetési módot", "Please select a payment mode"],
  OrderNotPayedYet: [
    (
      <>
        A rendelést még nem fizetted ki, biztonsan kilépsz?<br/>
        Kilépés esetén a rendelésed nem lesz feldolgozva!
      </>
    ),
    (
      <>
        Your payment has not been completed yet! <br/>
        Are you sure you want to exit?<br/>
        If you exit, your order will not be processed.
      </>
    )
  ],
  AfterOrderText1: ["Kérdésed van? Hívd a ", "Got a questions? Please call"],
  AfterOrderText2: ["-es telefonszámot!", "."],
  OrderNotPayedYet_YesText: ["Igen, kilépek", "Yes, exit please"],
  OrderNotPayedYet_NoText: ["Nem, kifizetem", "Go back to payment"],
  StopOrderText: ["Biztos megszakítod a rendelést?", "Are you sure you want to exit?"],
  CityInputPlaceholder: ["Település vagy irányítószám...", "City or postalcode..."],
  TodayClosedRestaurantText: ["Az étterem ideiglenesen be van zárva, emiatt a rendelést nem lehet most leadni. 😥", "The restaurant is closed temporarily. 😥"],
  RestaurantOnlyTakesPreOrder: ["Az étterem csak előrendelést fogad! A rendelésed nem fog megérkezni, csak nyitás után!", "The restaurant is only accepting preorders now! Note that your order will be processed only after the restuarant is opened."],
  ClosedRestaurantText: ["Az étterem most nem vesz fel új rendeléseket. 😥", "The restaurant doesn't take orders at this time. 😥"],
  TakeawayNotAvailableText: ["Sajnos az üzlet nem fogad rendeléseket elvitelre. 😥", "Takeaway is not available at this shop. 😥"],
  DeliveryNotAvailableText: ["Sajnos az üzlet nem vállal házhozszállítást. 😥", "Delivery is not available at this shop. 😥"],
  NoShipToAddressText: ["Sajnos a kiválasztott címre nem szállít az étterem 😥", "The restaurant doesn't take delivery orders to the selected address 😥"],
  CombinedMenu: ["Kombinált Menü", "Combined menu"],
  KindTopping: ["féle feltét", "topping kind"],
  ProductName: ["Terméknév", "Product name"],
  Quantity: ["Mennyiség", "Quantity"],
  Price: ["Ár", "Price"],
  pcs: ["db", "pcs"],
  Cart: ["Kosár", "Shopping cart"],
  PrevOrders: ["Rendelések az asztalnál", "Orders at the table"],
  CurrentOrder: ["Rendelésfelvétel", "Placing an order"],
  Empty: ["Üres", "Empty"],
  CartEmpty: ["Üres a kosár", "Shopping cart is empty"],
  TableStillEmpty: ["Még üres az asztalod", "Your table is still empty"],
  CartStillEmpty: ["Még üres a kosarad", "Your cart is still empty"],
  TakeawayAtShop: ["Átvétel az étteremnél", "Takeaway"],
  Takeaway: ["Elvitel", "Takeaway"],
  Total: ["Összeg", "Total"],
  Total2: ["Összesen", "Total"],
  ShippingPrice: ["Szállítási díj", "Shipping fee"],
  ToCashier: ["Pénztárhoz", "Start order"],
  ErrorTextFacebookLogin: ["Nem sikerült facebook-kal bejelentkezni", "Couldn't sign in with Facebook."],
  SignInWithApple: ["Apple Sign In", "Sign in with Apple"],
  SignInWithFacebook: ["Bejelentkezés Facebook-kal", "Sign in with Facebook"],
  ErrorTextAppleLogin: ["Nem sikerült bejelentkezni az Apple ID-val.", "Couldn't sign in with Apple ID."],
  LoggedIn: ["Bejelentkezve!", "Signed In Successful!"],
  LoggedOut: ["Kijelentkezve!", "Signed out!"],
  SetUpOtherAddress: ["Egyéb cím", "Other address."],
  SetUpWorkAddress: ["Munkahelyi cím", "Work address."],
  SetUpHomeAddress: ["Otthoni cím", "Home address."],
  ToSaveAddressMustBeLoggedInOrRegister: ["Címek elmentéséhez jelentkezz be, vagy hozz létre egy profilt!", "Please log in or create a new account first to save your addresses."],
  ToSaveAddressMustBeLoggedIn: ["Címek elmentéséhez jelentkezz be!", "Please log in first to save your addresses."],
  PartnerShopDoesntShipHere: ["Erre a címre nem szállít az étterem!", "The restaurant doesn't accept delivery orders to this address."],
  YouShouldEnterExactAddress: ["Add meg a pontos címed!", "You should enter your address!"],
  YouShouldEnterExactAddress2: ["Kérlek add meg a pontos címedet, hogy ellenőrizzük a kiszállítást.", "Please enter your address so we can check the delivery to your place."],
  EnterExactAddress: ["Pontos cím megadása", "Enter exact address"],
  EnterAnotherAddress: ["Más címet adok meg", "Enter another address"],
  EnterMyAddress: ["Címem megadása", "Set my address"],
  NoAddressSelected: ["Nincs cím kiválasztva", "No address selected yet"],
  SelectAddress: ["Cím kiválasztása", "Select address"],
  SelectedAddress: ["Kiválasztott cím", "Selected address"],
  RemoveAddressDetailsText: ["Új cím beállításánál a mostani keresési feltételek törlődnek és a főoldalra irányít a weblap.", "By removing your selected address you will be redirected to the main page and all filters will be cleared."],
  RemoveAddressTitle: ["Cím törlése", "Remove address"],
  RemoveAddressYesButton: ["Új címet állítok be", "Change my address"],
  ForTakeaway: ["Elvitelre", "Takeaway"],
  Next: ["Tovább", "Next"],
  ShowMore: ["Mutass többet", "Show more"],
  HouseNumberMissing: ["Add meg a házszámod is!", "Please enter the house number as well."],
  UseMyLocation: ["Saját helyzet", "Use my location"],
  AddressCommentPlaceholder: ["Megjegyzés, pl.: 2. emelet, 3. ajtó", "Comment, e.g.: 2nd floor, door 3"],
  Yes: ["Igen", "Yes"],
  No: ["Nem", "No"],
  Cancel: ["Mégsem", "Cancel"],
  NoResults: ["Nincs találat", "No results"],
  PleaseWait: ["Kis türelmet", "Loading"],
  Notification: ["Értesítés", "Notification"],
  NoFilesSelected: ["Nincs kiválasztva", "No files selected"],
  FileUploadWaitingText: [" fájl feltöltésre vár", " files are uploading"],
  SelectFiles: ["Kiválasztás", "Select"],
  MaxCharactersComment: ["Maximum 200 karakter adható a megjegyzésnek!", "You should enter only 200 characters for a comment."],
  LocationAccessError: ["Nincs engedélyezve a helyzeted használata!", "We don't have permission to use your location."],
  SomethingWentWrong: ["Valami hiba került a rendszerbe :( Kérlek próbáld újra.", "Something went wrong :( Please try again later."],
  LocationReadError: ["Nem sikerült beolvasni az aktuális helyzeted. Kérlek próbáld meg beírni.", "We couldn't get your locaiton. Please try to enter it manually."],
  SelectFromYourSavedAddresses: ["Válassz a mentett címeid közül", "Select saved address"],
  ErrorInShopMenuText: ["Sajnos hibát találtunk az étlapban! Kérlek válassz a többi feltét közül.", "We have detected an error in this product. Please try choosing another topping."]
};

export default class Language {
  static getName(nameArray: string[] | string[][], subIndex: number | undefined): string {
    if (nameArray === undefined || nameArray === null) {
      return undefined;
    }

    let value: any = nameArray[ContextSystem.language];
    if (!value) {
      value = nameArray[0];
    }

    if (subIndex !== undefined && subIndex !== null) {
      return value[subIndex];
    }

    return value;
  }
}

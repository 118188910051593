import React, { Component } from "react";
import FullProduct from "./FullProduct";
import EventSystem from "../../utils/EventSystem";
import ContextSystem from "../../utils/ContextSystem";
import { Product } from "../../model/Product";
import { OuterWrapper, Panel } from "./ProfileDetailsPopup";

export default class DetailedProductPopUp extends Component {
  eventIDs = [];
  static openedFromClick: boolean = false;

  state: {
    product: undefined | Product,
    addCb: undefined | ()=>{},
    opened: boolean,
    language: number,
  } = {
    product: undefined,
    addCb: undefined,
    opened: false,
    language: ContextSystem.language
  };

  handleAddCb(product: Product) {
    this.handleCloseCb(true);
    if (this.state.addCb)
      this.state.addCb(product);
  }

  handleCloseCb(redirect) {
    this.setState({
      opened: false,
      product: undefined,
      addCb: undefined
    });
    if (redirect) {
      EventSystem.publish(EventSystem.events.redirectIntent, {
        // redirectPath: undefined,
        // queryParams: { termek: undefined },
        goBack: true
      });
    }
  }

  componentWillUnmount() {
    for (let eventID of this.eventIDs) {
      EventSystem.unsubscribe(eventID);
    }

    this.eventIDs = [];
    document.removeEventListener("keyup", (e) => this.handleKeyUp(e));
  }

  static initialized = false;

  componentDidMount() {
    let eid = EventSystem.subscribe(EventSystem.events.popup_product, ({product, addCb, hard_open}) => {
      this.setState({opened: true, product, addCb});
      DetailedProductPopUp.openedFromClick = true;
      setTimeout(() => DetailedProductPopUp.openedFromClick = false, 200);
      // setTimeout(() => {
      //transition
      // }, 100);
      if (!hard_open) { //infinite loop, url is already set
        EventSystem.publish(EventSystem.events.redirectIntent, {
          redirectPath: undefined,
          queryParams: {termek: product.id}
        });
      }
    });
    this.eventIDs.push(eid);
    eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({language}) => {
      if (!DetailedProductPopUp.initialized && ContextSystem.navigatingPartner && ContextSystem.products.filter(p => p.partnerID === ContextSystem.navigatingPartner?.id) > 0) {
        DetailedProductPopUp.initialized = true;
        this.urlChanged();
      }

      if (language !== undefined) {
        this.setState({language});
      }
    });
    this.eventIDs.push(eid);

    eid = EventSystem.subscribe(EventSystem.events.urlChanged, () => {
      this.urlChanged();
    });
    this.eventIDs.push(eid);

    document.addEventListener("keyup", (e) => this.handleKeyUp(e));
  }

  urlChanged() {
    if (DetailedProductPopUp.openedFromClick)
      return;

    let path = window.location.pathname;
    let searchParamsText = window.location.search === "" ? "" : window.location.search.substring(1);
    let params = new URLSearchParams(searchParamsText);
    if (params.has("termek") && params.get("termek")) {
      let productID = parseInt(params.get("termek"));
      let product = ContextSystem.products?.find(p => p.id === productID);

      if (!product || !(path.startsWith("/etterem") || path.startsWith("/e"))) {
        EventSystem.publish(EventSystem.events.redirectIntent, {queryParams: {termek: undefined}});
        return;
      }

      //we need the RestaurantPage to open this popup bcs of the callback functions
      EventSystem.publish(EventSystem.events.hard_open_product_popup_intent, {product});
    } else {
      if (this.state.opened === true)
        this.handleCloseCb(false);
    }
  }

  handleKeyUp(e) {
    if (!this.state.opened)
      return;
    if (this.state.opened && e.key === "Escape") {
      this.handleCloseCb(true);
    }
  }

  render() {
    return (
      <OuterWrapper z={20} opened={this.state.opened} onClick={() => this.handleCloseCb(true)}>
        {this.state.opened &&
          <Panel border_radius={"10px"} border_radius_m={"30px 30px 0 0"}  height={"80vh"} padding={"0"} onClick={(e) => e.stopPropagation()}>
            <FullProduct
              product={this.state.product}
              addCb={(product) => this.handleAddCb(product)}
              closeCb={() => this.handleCloseCb(true)}
              showAddButton={true}
              showCloseButton={true}
            />
          </Panel>
        }
      </OuterWrapper>
    );
  }
}

import APICaller from "../APICaller";
import { Address, City } from "../../model/Address";
import { Shop } from "../../model/Shop";
import { Category } from "../../model/Product";
import { Element } from "../../model/BluePrint";
import { Bounds } from "pigeon-maps";
import type { MessageBase } from "../ws/MessageHandler";

function search(quite: boolean, address: Address, city: City, searchExpression: string, selectedCategories: number[], cb: ({ error: number, type: number, tooLessFound: boolean, products: Product[], partnerProfiles: Shop[], sortedPartnerProfiles: Shop[] })=>{}) {
  let data = {
    query: searchExpression ? searchExpression : "",
    addressID: address?.id,
    cityID: city?.id,
    categoryIDs: selectedCategories
  };

  APICaller.ownFetch(quite, "POST", "/search", data, (data) => {
    let res = JSON.parse(data);
    if (cb)
      cb(res);
  });
}

export type SearchTableType = MessageBase & { shops: Shop[], tables: Element[], occupancy: { [keys: number]: number } };

function searchTable(quite: boolean, bounds: Bounds, ppl: number, arrival: Date, stay: number, cb: (res: SearchTableType)=>{}) {
  let data = {
    ne: { latitude: bounds.ne[0], longitude: bounds.ne[1] },
    sw: { latitude: bounds.sw[0], longitude: bounds.sw[1] },
    ppl: ppl,
    arrival: arrival.getTime(),
    stay: stay
  };

  APICaller.ownFetch(quite, "POST", "/table/search", data, (data) => {
    let res = JSON.parse(data);
    if (cb)
      cb(res);
  });
}

function searchOffers(quite: boolean, address: Address, city: City, cb: ({ error: number, type: number, offersRespond: DynamicOffer[], globalCategories: Category[], offeredGlobalCategories: Category[] })=>{}) {
  let data = {
    addressID: address?.id,
    cityID: city?.id
  };

  APICaller.ownFetch(quite, "PUT", "/search", data, (data) => {
    let res = JSON.parse(data);
    if (cb)
      cb(res);
  });
}

export default { search, searchOffers, searchTable };

import React, { Component } from "react";
import ContextSystem from "../../utils/ContextSystem";
import EventSystem from "../../utils/EventSystem";
import styled, { css, keyframes } from "styled-components";
import { Order, OrderState } from "../../model/Order";
import { BsCaretLeftFill, BsCheck, BsCheckAll } from "react-icons/bs";
import { IoIosTimer } from "react-icons/io";
import { ShippingMethods } from "../../model/ShippingPrice";
import Language, { Names } from "../../utils/Language";
import { PaymentMethods } from "../../model/PaymentMethodSetting";
import AnimateHeight from "../AnimateHeight";
import Cart, { ProductDiv, ProductsWrapper } from "../cart/Cart";
import { Button, ScheduleIconTimerWrapper } from "../Form";
import { OrdersAPI } from "../../utils/api/OrdersAPI";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { Donation, DonationTypes } from "../../model/NonprofitOrganization";
import { TranslatableString } from "../../model/Product";
import type { Shop } from "../../model/Shop";

export const CounterInnerWrapper = styled.div`
  width: 100%;
  padding: 3px;
  margin: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--blundee_color_thin);
`;

export const TimerWrapper = styled.div`
  width: 100%;
  padding: 5px;
  margin: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const OrderDivider = styled.div`
  display: block;
  width: 80%;
  justify-self: flex-start;
  height: 4px;
  background-color: var(--blundee_background_card_3);
  border-radius: 500px;
  margin: -5px 0 7px 0;
`;

const OrderDate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  flex-grow: 2;
  font-size: 10pt;
  flex-wrap: wrap;

  & > div {
    & > span {
      margin: 0 0 0 5px;
    }

    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 4px 0;
  }
`;

export const Pulse = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const OrderStatusIndicator = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 3px 5px;
  padding: 2px;

  & > div {
    width: 6px;
    height: 6px;
    border-radius: 100px;
    background-color: ${({color}) => color ?? "green"};
    ${({pulse}) => pulse === true && css`
      animation: ${Pulse} 0.5s ease infinite alternate;
    `}
  }
`;

const OrderHeaderRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const OrderOpenIcon = styled.div`
  transition: transform 300ms ease-in-out;
  transform: rotate(0deg);

  ${({opened}) => opened === true && css`
    transform: rotate(-90deg);
  `};

  & > svg {
    color: rgb(198, 198, 198);
    font-size: 22pt;
  }
`;

const OrderDiv = styled.div`
  max-width: 650px;

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  padding: 8px;
  border-radius: 7px;
  background-color: var(--blundee_background_card_3);
  margin: 8px 0;
  box-shadow: var(--blundee_button_shadow);

  @media screen and (max-width: 800px) {
    width: 100%;
  }

  & > h1 {
    & > span > svg {
      font-size: 22pt;
      color: green;
    }

    ${({clickable}) => clickable && css`
      cursor: pointer;
    `};

    width: 100%;
    padding-right: 15px;
    font-family: var(--blundee_font_medium);
    font-size: 15pt;
    margin: 0 0 8px 9px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: left;
  }

  & > h2 {
    width: 100%;
    padding-right: 15px;
    padding-left: 5px;
    font-family: var(--blundee_font_medium);
    font-size: 11pt;
    margin: 0 0 8px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    flex-wrap: wrap;

    & > div {
      margin: 0 4px;
    }

    & > divider {
      display: block;
      width: 100%;
      height: 1px;
      background-color: transparent;
    }

    & > svg {
      font-size: 19pt;
      flex-shrink: 0;
      stroke-width: 2px;
      padding: 5px;
      cursor: pointer;

      transition: transform 100ms ease-in-out, color 100ms ease-in-out;

      &:active {
        transform: scale(0.98);
        color: rgb(200, 200, 200);
      }
    }
  }
`;

const PayOnlineButton = styled(Button)`
  padding: 5px 7px;
  font-size: 11pt;
  min-width: 0;
  background-color: var(--blundee_button_bg_color);
  color: var(--blundee_color_2);


`;

export default class OrderDetails extends Component<{
  order: Order,
  openedDefault: boolean,
  tableReservationUI: boolean,
  shop: Shop
}> {
  eventIDs = [];
  pulseStatuses: number[] = [OrderState.NEW, OrderState.READY, OrderState.CONFIRMED, OrderState.WAITING_FOR_ONLINE_PAYMENT, OrderState.PREPARING];
  statusIndicatorColors: { state: number, color: string }[] = [
    {state: OrderState.WAITING_FOR_ONLINE_PAYMENT, color: "#ff0000"},
    {state: OrderState.NEW, color: "#00ff00"},
    {state: OrderState.CONFIRMED, color: "#426ff8"},
    {state: OrderState.DECLINED, color: "#ff0000"},
    {state: OrderState.DELETED, color: "#ff0000"},
    {state: OrderState.READY, color: "#00ff00"},
    {state: OrderState.FAILED_TRANS, color: "#ff0000"},
    {state: OrderState.DONE, color: "#00ff00"},
    {state: OrderState.PREPARING, color: "#ff8800"}
  ];

  state: {
    language: number,
    menuOpened: { [keys: number]: boolean },
    order: Order | undefined,
    opened: boolean,
    isPlaying: boolean,
    tableReservationUI: boolean,
    shop: Shop,
  } = {
    language: ContextSystem.language,
    menuOpened: {},
    order: undefined,
    opened: false,
    isPlaying: false,
    tableReservationUI: false,
    shop: undefined,
  };

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
    if (this.props.order !== prevProps.order) {
      this.setState({order: this.props.order});
    }
    if (this.props.shop !== prevProps.shop) {
      this.setState({shop: this.props.shop});
    }
    if (this.props.tableReservationUI !== prevProps.tableReservationUI) {
      this.setState({tableReservationUI: this.props.tableReservationUI});
    }
  }

  componentWillUnmount() {
    if (this.timeOut)
      clearTimeout(this.timeOut);
    for (let eventID of this.eventIDs)
      EventSystem.unsubscribe(eventID);
  }

  componentDidMount() {
    this.clock();

    if (this.props.openedDefault === true)
      this.setState({opened: true});
    if (this.props.tableReservationUI !== undefined)
      this.setState({tableReservationUI: this.props.tableReservationUI});

    this.eventIDs = [];
    this.setState({order: this.props.order});

    let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged,
      ({language}) => {
        if (language !== undefined)
          this.setState({language});
      }
    );
    this.eventIDs.push(eid);
  }

  changeOrderOpened(opened: boolean) {
    this.setState({opened});
  }

  toggleMenuOpen(orderNumber: string, index: number) {
    let menuOpened = {...this.state.menuOpened};
    menuOpened[index] = !(menuOpened[index] || false);
    this.setState({menuOpened});
  }

  isMenuOpened(orderNumber: string, index: number) {
    return this.state.menuOpened[index] || false;
  }

  createNewPayment(e: Event) {
    e.stopPropagation();

    OrdersAPI.createPayment(this.state.order.number, (res) => {
      if (res.error === 0) {
        window.location.href = res.transactionInfo.url;
      }
    });
  }

  timeOut: number;

  clock() {
    this.recalculateTimer();
    this.timeOut = setTimeout(() => {
      this.clock();
    }, 1000);
  }

  recalculateTimer(updateState: boolean = true): {
    showTimer: boolean,
    timeDiffSeconds: number,
    countDownColor: string
  } {
    let order = this.state.order;

    if (!order)
      return;

    let showTimer = order.lastState.status === OrderState.CONFIRMED || order.lastState.status === OrderState.PREPARING;
    if (this.state.shop)
      showTimer = this.state.shop.showOrderTimerForGuests && showTimer;
    let dateFromCalc: Date;

    let comment: { minutes: number, message: string };
    try {
      if (order.lastState.status === OrderState.CONFIRMED || order.scheduleDate && order.lastState.status === OrderState.PREPARING) {
        dateFromCalc = order.lastState.dateTime;
        comment = JSON.parse(order?.lastState?.comment);
      } else if (order.lastState.status === OrderState.PREPARING) {
        let confirmedStateUpdate: OrderState = order.orderStates.find(os => os.status === OrderState.CONFIRMED);
        dateFromCalc = confirmedStateUpdate?.dateTime ?? order.lastState.dateTime;
        try {
          comment = JSON.parse(confirmedStateUpdate?.comment);
        } catch (e) {
          let numb = confirmedStateUpdate?.comment.match(/\d/g);
          numb = numb?.join("");
          let minutes = parseInt(numb);
          comment = {minutes: minutes, message: confirmedStateUpdate.comment};
        }
      }
    } catch (e) {
      console.log(e);
    }

    if (!comment)
      comment = {minutes: 20, message: ""};

    if (!(dateFromCalc instanceof Date))
      dateFromCalc = new Date(dateFromCalc);

    let timeFinish: Date = new Date(dateFromCalc.getTime() + comment.minutes * 60 * 1000);
    let timeDiffSeconds: number = (timeFinish - new Date()) / 1000;
    let isPlaying = true;

    if (order.lastState.status === OrderState.CONFIRMED && timeDiffSeconds <= 3 * 60) {
      timeDiffSeconds = 3 * 60;
      isPlaying = false;
    }
    if (order.lastState.status === OrderState.PREPARING && timeDiffSeconds <= 0.5 * 60) {
      timeDiffSeconds = 0.5 * 60;
      isPlaying = false;
    }

    if (timeDiffSeconds <= 0) {
      isPlaying = false;
      timeDiffSeconds = 0;
      showTimer = false;
    }

    if (updateState) {
      this.setState({isPlaying});
    }

    let s: number = order.lastState.status;
    let countDownColor: string = "#E30813";
    if (s === OrderState.CONFIRMED)
      countDownColor = "#426ff8";
    else if (s === OrderState.PREPARING)
      countDownColor = "#ff8800";


    return {showTimer, timeDiffSeconds, countDownColor};
  }


  render() {
    let order: Order = this.state.order;
    if (!order)
      return (
        <></>
      );
    let s: number = order.lastState.status;
    let pulseStatusIndicator = this.pulseStatuses.includes(s);
    let statusColor = this.statusIndicatorColors.find(p => p.state === s)?.color;
    let opened = this.state.opened;

    let timerProps = this.recalculateTimer(false);

    let isPlaying = this.state.isPlaying;
    let timeDiffSeconds = timerProps.timeDiffSeconds;
    let showTimer = timerProps.showTimer;
    showTimer = showTimer && !(order.lastState.status === OrderState.CONFIRMED && order.scheduleDate);
    let countDownColor = timerProps.countDownColor;
    let donations: Donation[] = ContextSystem.donations.filter(d => d.orderNumber === order.number);

    let donationValueSum: number = 0;
    for (let donation of donations) {
      if (donation.type === DonationTypes.FIX)
        donationValueSum += donation.value;
      else if (donation.type === DonationTypes.PERCENTAGE)
        donationValueSum += order.orderTotalPrice * (donation.value / 100);
    }

    return (
      <OrderDiv clickable opened>
        <h1 onClick={() => this.changeOrderOpened(!opened)}>
          <span>
          {order.dailyNumber} | {OrderState.getName(order.lastState.status, order.shippingMethod, !!order.scheduleDate)}
            {s === OrderState.READY && <BsCheck/>}
            {s === OrderState.DONE && <BsCheckAll/>}
          </span>
          <OrderHeaderRight>
            {showTimer &&
              <CountdownCircleTimer
                isPlaying={isPlaying}
                size={30}
                strokeWidth={2}
                duration={timeDiffSeconds}
                colors={countDownColor}
              >
                {({remainingTime}) => {
                  return (
                    <span style={{fontSize: "9.5pt", color: "var(--blundee_color_normal)"}}>
                  {Math.ceil(remainingTime / 60)}
                </span>
                  );
                }}
              </CountdownCircleTimer>
            }
            {order.lastState.status === OrderState.WAITING_FOR_ONLINE_PAYMENT &&
              <PayOnlineButton onClick={(e) => this.createNewPayment(e)}>
                {Language.getName(Names.PayButton)}
              </PayOnlineButton>
            }
            <OrderStatusIndicator color={statusColor} pulse={pulseStatusIndicator}>
              <div/>
            </OrderStatusIndicator>
            <OrderOpenIcon opened>
              <BsCaretLeftFill/>
            </OrderOpenIcon>
          </OrderHeaderRight>
        </h1>
        <OrderDivider/>
        <h2>
          <div>{order.number}</div>
          <div>
            {donationValueSum > 0 &&
              <>{Language.getName(Names.DonationTotal)}: {donationValueSum}Ft</>
            }
          </div>

          <divider/>

          <>
            {order.shippingMethod === ShippingMethods.DELIVERY &&
              <div>{Language.getName(Names.Delivery)}</div>
            }
            {order.shippingMethod === ShippingMethods.PICKUP &&
              <div>{Language.getName(Names.Takeaway)}</div>
            }
            {order.shippingMethod === ShippingMethods.VENDOR_DELIVERY &&
              <div>{Language.getName(Names.Delivery)}</div>
            }
            {order.shippingMethod === ShippingMethods.AT_PLACE &&
              <div>{Language.getName(Names.AtPlace)}</div>
            }
          </>
          <OrderDate>
            <span>{order.date.toHunFormatMinutes()}</span>
            {order.scheduleDate &&
              <div>
                <ScheduleIconTimerWrapper>
                  <IoIosTimer/>
                </ScheduleIconTimerWrapper>
                <span>{order.scheduleDate.toHunFormatMinutes()}</span>
              </div>
            }
          </OrderDate>

          <divider/>

          <div>{PaymentMethods.getName(order.paymentMethod)}</div>
          <div>{order.orderTotalPrice} Ft</div>

          <divider/>
        </h2>
        {(order.shippingMethod === ShippingMethods.DELIVERY || order.shippingMethod === ShippingMethods.VENDOR_DELIVERY) && order.address &&
          <h2>
            {Language.getName(Names.DeliveryAddress)}:
            <divider/>
            {order.address.street}, {TranslatableString.get(ContextSystem.getCityByID(order.address.cityID).name)} ({ContextSystem.getZipCode(order.address.zipCodeID).zipcode})
          </h2>
        }
        <AnimateHeight opened={opened}>
          {showTimer &&
            <TimerWrapper>
              <CountdownCircleTimer
                isPlaying={isPlaying}
                size={100}
                strokeWidth={4}
                duration={timeDiffSeconds}
                colors={countDownColor}
              >
                {({remainingTime}) => (
                  <CounterInnerWrapper>
                    <div>{Language.getName(Names.RemainingTime)}</div>
                    <div>{Math.ceil(remainingTime / 60) + " " + Language.getName(Names.minutes)}</div>
                  </CounterInnerWrapper>
                )}
              </CountdownCircleTimer>
            </TimerWrapper>
          }
          <ProductsWrapper>
            {order.productList.map((op, i) =>
              Cart.renderOrderProductCart(ContextSystem.getProduct(op.productID), i, (i) => this.isMenuOpened(i), (i) => this.toggleMenuOpen(i), false, op)
            )}
            {(this.state.order.shippingMethod === ShippingMethods.AT_PLACE || !this.props.tableReservationUI) &&
              <ProductDiv>
                <div/>
                <div>{Language.getName(Names.ShippingPrice)}</div>
                <div/>
                <div>{order.shippingPrice + " Ft"}</div>
              </ProductDiv>
            }
          </ProductsWrapper>
        </AnimateHeight>
      </OrderDiv>
    );
  }

}

import {HourStatus, HourStatuses} from "../model/Hour";
import Language, {Names} from "./Language";
import {Shop} from "../model/Shop";

export function getOpeningHoursText(openStatus: OpenStatus, AcceptingPickUpOrdersStatus: OpenStatus, DeliveringStatus: OpenStatus, AcceptingOrdersStatus: OpenStatus, short: boolean = false): OpeningHourText {
  let openStringDL;
  let openStringPK;

  if (openStatus.status === HourStatuses.LOADING || AcceptingOrdersStatus.status === HourStatuses.LOADING ||
    DeliveringStatus.status === HourStatuses.LOADING || AcceptingOrdersStatus.status === HourStatuses.LOADING
  ) {
    return {
      openStringDL: HourStatuses.LOADING,
      openStringPK: HourStatuses.LOADING
    };
  }
  if (openStatus.status === HourStatuses.NO_DATA || AcceptingPickUpOrdersStatus.status === HourStatuses.NO_DATA)
    openStringPK = HourStatuses.NO_DATA;
  if (DeliveringStatus.status === HourStatuses.NO_DATA || AcceptingOrdersStatus.status === HourStatuses.NO_DATA)
    openStringDL = HourStatuses.NO_DATA;
  if (openStringPK === HourStatuses.NO_DATA && openStringDL === HourStatuses.NO_DATA) {
    return {
      openStringDL,
      openStringPK
    };
  }

  let dayOfWeek = new Date().getDay();
  if (dayOfWeek === 0) dayOfWeek = 7;
  let nextDayOfWeek = dayOfWeek + 1;
  if (nextDayOfWeek === 8) nextDayOfWeek = 1;

  let nextCloseHPK: string = addLeadingZero(AcceptingPickUpOrdersStatus.nextCloseH);
  let nextCloseMPK: string = addLeadingZero(AcceptingPickUpOrdersStatus.nextCloseM);
  let nextOpenHPK: string = addLeadingZero(AcceptingPickUpOrdersStatus.nextOpenH);
  let nextOpenMPK: string = addLeadingZero(AcceptingPickUpOrdersStatus.nextOpenM);

  let nextCloseH: string = addLeadingZero(AcceptingOrdersStatus.nextCloseH);
  let nextCloseM: string = addLeadingZero(AcceptingOrdersStatus.nextCloseM);
  let nextOpenH: string = addLeadingZero(AcceptingOrdersStatus.nextOpenH);
  let nextOpenM: string = addLeadingZero(AcceptingOrdersStatus.nextOpenM);

  if (AcceptingPickUpOrdersStatus.status === HourStatuses.OPEN && openStatus.status === HourStatuses.OPEN) {
    //Nyitva 18:00-ig  .   Open until 18.00
    openStringPK = Language.getName(Names.OpenOpenHoursTextPart1) + nextCloseHPK + ":" + nextCloseMPK + Language.getName(Names.OpenOpenHoursTextPart2);
  } else if (AcceptingPickUpOrdersStatus.status === HourStatuses.CLOSED && openStatus.status === HourStatuses.CLOSED) {
    //Zárva, nyitás: 18:00  .   Closed until 18.00
    openStringPK = Language.getName(Names.ClosedOpenHoursText) + nextOpenHPK + ":" + nextOpenMPK;
    if (!short && (AcceptingPickUpOrdersStatus.nextOpenD !== dayOfWeek && AcceptingPickUpOrdersStatus.nextOpenD !== nextDayOfWeek))
      //Zárva, nyitás: 18:00 (Kedd)  .   Closed until 18.00 (Tuesday)
      openStringPK += ` (${getDayName(AcceptingPickUpOrdersStatus.nextOpenD)})`;
  } else if (AcceptingPickUpOrdersStatus.status === HourStatuses.OPEN && openStatus.status === HourStatuses.CLOSED) {
    //Előrendelésre, nyitás: 18:00  .   PreOrder, Shop opens at 18.00
    let openRestaurantH = addLeadingZero(openStatus.nextOpenH);
    let openRestaurantM = addLeadingZero(openStatus.nextOpenM);
    if (short)
      if (openStatus.nextOpenD !== dayOfWeek && openStatus.nextOpenD !== nextDayOfWeek)
        //Zárva  .   Closed
        openStringPK = Language.getName(Names.Closed);
      else
        //Zárva, nyitás: 18:00  .   Closed until 18.00
        openStringPK = Language.getName(Names.ClosedOpenHoursText) + openRestaurantH + ":" + openRestaurantM;
    else
      openStringPK = Language.getName(Names.ForPreOrderOpenHoursText2) + openRestaurantH + ":" + openRestaurantM;
    if (!short && (openStatus.nextOpenD !== dayOfWeek && openStatus.nextOpenD !== nextDayOfWeek))
      //Előrendelésre, nyitás: 18:00 (Hétfő)  .   PreOrder, Shop opens at 18:00 (Monday)
      openStringPK += ` (${getDayName(openStatus.nextOpenD)})`;
  } else {
    //Zárva  .  Closed
    openStringPK = Language.getName(Names.Closed);
  }

  if (AcceptingOrdersStatus.status === HourStatuses.OPEN && DeliveringStatus.status === HourStatuses.OPEN) {
    openStringDL = Language.getName(Names.OpenOpenHoursTextPart1) + nextCloseH + ":" + nextCloseM + Language.getName(Names.OpenOpenHoursTextPart2);
  } else if (AcceptingOrdersStatus.status === HourStatuses.CLOSED && DeliveringStatus.status === HourStatuses.CLOSED) {
    openStringDL = Language.getName(Names.ClosedOpenHoursText) + nextOpenH + ":" + nextOpenM;
    if (AcceptingOrdersStatus.nextOpenD !== dayOfWeek && AcceptingOrdersStatus.nextOpenD !== nextDayOfWeek)
      openStringDL += ` (${getDayName(AcceptingOrdersStatus.nextOpenD)})`;
  } else if (AcceptingOrdersStatus.status === HourStatuses.OPEN && DeliveringStatus.status === HourStatuses.CLOSED) {
    let startDeliveringH = addLeadingZero(DeliveringStatus.nextOpenH);
    let startDeliveringM = addLeadingZero(DeliveringStatus.nextOpenM);
    openStringDL = Language.getName(Names.ForPreOrderOpenHoursText) + startDeliveringH + ":" + startDeliveringM;
    if (DeliveringStatus.nextOpenD !== dayOfWeek && DeliveringStatus.nextOpenD !== nextDayOfWeek)
      openStringDL += ` (${getDayName(DeliveringStatus.nextOpenD)})`;
  } else {
    openStringDL = Language.getName(Names.Closed);
  }

  return {openStringPK, openStringDL};
}

export type OpeningHourText = {
  openStringPK: string,
  openStringDL: string,
}

export function addLeadingZero(hourOrMinuteValue: number): string {
  if (hourOrMinuteValue > 9)
    return "" + hourOrMinuteValue;
  else
    return "0" + hourOrMinuteValue;
}

export function getDayName(dayOfWeek) {
  if (dayOfWeek <= 0 || dayOfWeek > 7)
    return Language.getName(Names.Error);

  return Language.getName(Names.DayNames, dayOfWeek - 1);
}

export type OpenStatus = {
  status: HourStatus,
  nextCloseM: number | undefined,
  nextCloseH: number | undefined,
  nextOpenH: number | undefined,
  nextOpenM: number | undefined,
  nextOpenD: number | undefined,
  nextCloseD: number | undefined
}

export function getOpeningState(hours: Hour[], dateTime: Date = new Date()): OpenStatus {
  if (!dateTime)
    dateTime = new Date();

  if (!hours || hours.length <= 0)
    return {status: HourStatuses.NO_DATA};

  dateTime.setSeconds(0, 0);
  dateTime.setMilliseconds(0);
  let dayOfWeek = dateTime.getDay();
  if (dayOfWeek === 0)
    dayOfWeek = 7;

  let status = HourStatuses.NO_DATA;
  let nextOpenH, nextOpenM, nextCloseH, nextCloseM, nextOpenD, nextCloseD;

  let todayLastInfo;

  //check if we find info today
  for (let h of hours) {
    if (!h)
      continue;

    if (h.day === dayOfWeek) {
      let openH = h.openHour.hour;
      let openM = h.openHour.minute;
      let closeH = h.closeHour.hour;
      let closeM = h.closeHour.minute;

      let openDate = new Date(dateTime.getTime());
      let closeDate = new Date(dateTime.getTime());
      openDate.setHours(openH, openM, 0, 0);
      closeDate.setHours(closeH, closeM, 0, 0);

      if (dateTime >= openDate && dateTime <= closeDate) {
        status = HourStatuses.OPEN;
        todayLastInfo = h;
        todayLastInfo.openDate = openDate;
        todayLastInfo.closeDate = closeDate;
        nextCloseH = closeH;
        nextCloseM = closeM;
        nextCloseD = dayOfWeek;
        break;
      } else if ((dateTime < openDate || dateTime > closeDate) && status !== HourStatuses.OPEN) {
        status = HourStatuses.CLOSED;
        if (!todayLastInfo || (todayLastInfo && (todayLastInfo.openDate > openDate || todayLastInfo.closeDate > closeDate))) {
          todayLastInfo = h;
          todayLastInfo.openDate = openDate;
          todayLastInfo.closeDate = closeDate;
        }
      }
    }
  }

  let searchNextInfoDay = dayOfWeek;
  let foundNext = false;
  for (let i = 0; i < 7; i++) {
    //check every day the next 7 days
    for (let h of hours) {
      if (todayLastInfo && h.id === todayLastInfo.id)
        continue;

      let openH = h.openHour.hour;
      let openM = h.openHour.minute;
      let closeH = h.closeHour.hour;
      let closeM = h.closeHour.minute;

      let openDate = new Date();
      let closeDate = new Date();
      openDate.setHours(openH, openM, 0, 0);
      closeDate.setHours(closeH, closeM, 0, 0);

      if (h.day === searchNextInfoDay) {
        if (dayOfWeek === searchNextInfoDay) {
          //todayLastInfo cannot be undefined
          if (openDate > todayLastInfo.openDate || closeDate > todayLastInfo.closeDate) {
            if (!nextOpenD) {
              nextOpenD = h.day;
              nextOpenM = openM;
              nextOpenH = openH;
            }
            if (!nextCloseD) {
              nextCloseD = h.day;
              nextCloseM = closeM;
              nextCloseH = closeH;
            }
            foundNext = true;
            break;
          }
        } else {
          if (!nextOpenD) {
            nextOpenD = h.day;
            nextOpenM = openM;
            nextOpenH = openH;
          }
          if (!nextCloseD) {
            nextCloseD = h.day;
            nextCloseM = closeM;
            nextCloseH = closeH;
          }
          foundNext = true;
          break;
        }
      }
    }
    //if found, stop, if not, let's check the next day
    if (foundNext)
      break;
    searchNextInfoDay++;
    if (searchNextInfoDay > 7)
      searchNextInfoDay = 1;
  }

  if (!foundNext && todayLastInfo) {
    //todayLastInfo cannot be undefined
    let openH = todayLastInfo.openHour.hour;
    let openM = todayLastInfo.openHour.minute;
    let closeH = todayLastInfo.closeHour.hour;
    let closeM = todayLastInfo.closeHour.minute;

    nextOpenD = todayLastInfo.day;
    nextCloseD = todayLastInfo.day;
    nextOpenH = openH;
    nextCloseH = closeH;
    nextOpenM = openM;
    nextCloseM = closeM;
  }

  return {
    status,
    nextCloseM,
    nextCloseH,
    nextOpenH,
    nextOpenM,
    nextOpenD,
    nextCloseD
  };
}

export function checkOpeningState(type: number, partner: Shop, dateTime: Date = new Date()): OpenStatus {
  if (!partner || !partner.hours)
    return {status: HourStatuses.LOADING};

  let hours = partner.hours[type];

  return getOpeningState(hours, dateTime);
}

export function getShortDayName(dayOfWeek: number): string {
  return Language.getName(Names.DayNamesShort, dayOfWeek - 1);
}

export function arrayRotate(arr, reverse = false, number = 1) {
  let array = [...arr];
  if (reverse)
    for (let i = 0; i < number; i++)
      array.unshift(array.pop());
  else
    for (let i = 0; i < number; i++)
      array.push(array.shift());
  return array;
}

import EventSystem from "./EventSystem";
import { AuthAPI } from "./api/AuthAPI";
import CitiesAPI from "./api/CitiesAPI";
import React, { ReactElement } from "react";
import type { GetRestaurantType } from "./api/RestaurantAPI";
import { RestaurantAPI } from "./api/RestaurantAPI";
import { CartStorage } from "./CartStorage";
import Config, { ConfigKeys } from "./Config";
import { AddressAPI } from "./api/AddressAPI";
import CategoriesAPI from "./api/CategoriesAPI";
import { Shop } from "../model/Shop";
import { Element } from "../model/BluePrint";
import { Category, GlobalCategory, Product, TranslatableString } from "../model/Product";
import { ShippingPrice } from "../model/ShippingPrice";
import Toast from "../components/Toast";
import Language, { Names } from "./Language";
import { Address, City, ZipCode } from "../model/Address";
import { Profile } from "../model/Profile";
import type { GetTableQRID } from "./api/OrdersAPI";
import { OrdersAPI } from "./api/OrdersAPI";
import MessageType from "./ws/MessageType";
import WSConnection from "./ws/WSConnection";
import NonprofitAPI from "./api/NonprofitAPI";
import { Donation, NonprofitOrganization } from "../model/NonprofitOrganization";
import { Order, TableReservation } from "../model/Order";
import { MessageSender } from "./ws/MessageSender";
import { RestaurantHeart } from "../model/RestaurantHeart";
import ErrorMessages from "./api/ErrorMessages";
import BlundeeTableIcon from "../components/icons/BlundeeTableIcon";
import BlundeeHomeIconEmpty from "../components/icons/BlundeeHomeIconEmpty";
import BlundeeHomeIconFilled from "../components/icons/BlundeeHomeIconFilled";
import BlundeeSearchIconEmpty from "../components/icons/BlundeeSearchIconEmpty";
import BlundeeSearchIconFilled from "../components/icons/BlundeeSearchIconFilled";
import BlundeeProfileIconEmpty from "../components/icons/BlundeeProfileIconEmpty";
import BlundeeProfileIconFilled from "../components/icons/BlundeeProfileIconFilled";

export type NavIndexType = {
  path: string,
  index: number,
  iconSelected: ReactElement,
  icon: ReactElement,
  name: ()=>{},
  selectable: boolean
};
export default class ContextSystem {
  static websocket: WSConnection = null;

  // indicates to the RestaurantPage if the page was loaded automatically (for example, the url is already given)
  // or it was loaded by a user click on a shop component (ShopComponent's handlePartnerProfileClicked)
  static manualShopRedirect: boolean = false;

  static navIndexes: NavIndexType[] = [];
  static data: {};

  static loadingApp: number = 0;
  static appLoaded: boolean = false;
  static googleAPILoaded: boolean = false;
  static hideNavBar: boolean = true;
  static hideCart: boolean = true;
  static selectedNavIndex: number = 0;
  static loggedIn: boolean = false;
  static loggedInLoaded: boolean = false;
  static navBarLoaded: boolean = false;
  // static loginChecked = false;
  static profile: Profile = undefined;
  static startPageDone: boolean = false;
  static selectedAddress: Address = undefined;
  static selectedCity: City = undefined;
  static pickup: boolean = false;   //indicates that we are in "ShippingMethod=PICKUP" mode
  static scheduleDate: Date = null; //if null, order is immediate
  static atPlaceQRID: number = -1;  //if > 0, we are in "ShippingMethod=AT_PLACE1" mode
  static atPlaceTableElement: Element = undefined;
  static atPlaceTableReservation: TableReservation = undefined;
  static cart = undefined;
  static cartPartner: Shop = undefined;
  static cartPartnerURL: string = undefined;
  static cartPartnerID: number = -1;
  static cities: City[] = [];
  static zipcodes: ZipCode[] = [];
  static uniqueID = 0;
  static scrollPosition = 0;
  static enableContainerScrolling = true;
  static lastRestaurantURL = undefined;
  static containerHeight = 0;
  static emptySearchScreenEnabled = false;
  static welcomeScreenEnabled = false;
  static mobileDevice: boolean = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  static mobileLayout = window.innerWidth <= Config.mobileMaxWidth;
  static availableShippingPrices: ShippingPrice[] = [];
  static loadingAvailableShippingPrices = false;
  static navigatingPartner: Shop = undefined;
  static globalCategories: GlobalCategory[] = undefined;
  static searchScreenFiltersOpened = false;
  static mobileCartOpened = false;
  static statusBarSpaceHeight = 0;
  static mobileNavbarHeight = Config.mobileNavbarHeightDefault;
  static mobileCartHeight = 40;
  static mobileContentPaddingBottom = 50;
  static language = parseInt(window.localStorage.getItem("language") ?? "0");
  static orders: Order[] = [];
  static restaurantHearts: RestaurantHeart[] = [];
  static donations: Donation[] = [];
  static products: Product[] = [];
  static nonprofitOrganizations: NonprofitOrganization[] = [];
  static shops: Shop[] = [];
  static categories: Category[] = [];

  static getNextUniqueID() {
    this.uniqueID++;
    return this.uniqueID;
  }

  static init() {
    console.log("Init ContextSystem");
    this.data = {
      loadingApp: this.loadingApp,
      appLoaded: this.appLoaded,
      googleAPILoaded: this.googleAPILoaded,
      hideNavBar: this.hideNavBar,
      hideCart: this.hideCart,
      selectedNavIndex: this.selectedNavIndex,
      loggedIn: this.loggedIn,
      navBarLoaded: this.navBarLoaded,
      profile: this.profile,
      selectedAddress: this.selectedAddress,
      selectedCity: this.selectedCity,
      pickup: this.pickup,
      scheduleDate: this.scheduleDate,
      atPlaceQRID: this.atPlaceQRID,
      atPlaceTableElement: this.atPlaceTableElement,
      atPlaceTableReservation: this.atPlaceTableReservation,
      cart: this.cart,
      cartPartner: this.cartPartner,
      cartPartnerURL: this.cartPartnerURL,
      cartPartnerID: this.cartPartnerID,
      cities: this.cities,
      zipcodes: this.zipcodes,
      addressPickerCallerID: this.uniqueID,
      scrollPosition: this.scrollPosition,
      enableContainerScrolling: this.enableContainerScrolling,
      lastRestaurantURL: this.lastRestaurantURL,
      containerHeight: this.containerHeight,
      emptySearchScreenEnabled: this.emptySearchScreenEnabled,
      welcomeScreenEnabled: this.welcomeScreenEnabled,
      mobileDevice: this.mobileDevice,
      mobileLayout: this.mobileLayout,
      loadingAvailableShippingPrices: this.loadingAvailableShippingPrices,
      availableShippingPrices: this.availableShippingPrices,
      navigatingPartner: this.navigatingPartner,
      globalCategories: this.globalCategories,
      mobileCartOpened: this.mobileCartOpened,
      statusBarSpaceHeight: this.statusBarSpaceHeight,
      mobileNavbarHeight: this.mobileNavbarHeight,
      mobileCartHeight: this.mobileCartHeight,
      mobileContentPaddingBottom: this.mobileContentPaddingBottom,
      language: this.language,
      orders: this.orders,
      restaurantHearts: this.restaurantHearts,
      donations: this.donations,
      products: this.products,
      nonprofitOrganizations: this.nonprofitOrganizations,
      startPageDone: this.startPageDone,
      shops: this.shops,
      categories: this.categories,
    };

    ContextSystem.buildNavIndexes();
    ContextSystem.loadFromLocalStorage();
    ContextSystem.calcNavIndex();
    ContextSystem.subscribe();
    ContextSystem.loadHideCart();
    ContextSystem.loadHideNavBar();
    ContextSystem.checkTimeOutDetails();

    ContextSystem.loadCities();
    ContextSystem.loadNonprofitOrganizations();
    ContextSystem.loadGlobalCategories();

    ContextSystem.resetLocalOrders();
    ContextSystem.resetWebSocket();

    if (ContextSystem.profile) {
      ContextSystem.loadRestaurantHearts();
      ContextSystem.loadOrders(0, 3);
    }
  }

  static checkTimeOutDetails() {
    let lastActivity = window.localStorage.getItem("lastActivity");
    if (!lastActivity)
      return;

    let limit = parseInt(lastActivity) + 30 * 60000;
    if (Date.now() > limit) {
      let city: City = window.localStorage.getItem("selectedCity");
      let address: Address | City = window.localStorage.getItem("selectedAddress");
      city = city === "" || city === "undefined" || city === "null" ? undefined : JSON.parse(city);
      address = address === "" || address === "undefined" || address === "null" ? undefined : JSON.parse(address);
      address = address ?? city;
      let cart = window.localStorage.getItem("cart");
      if (cart && cart !== "[]") {
        if (address) {
          this.showLastActivityCartModal(() => this.showLastActivityAddressModal(address.street ?? TranslatableString.get(address.name)));
        } else {
          this.showLastActivityCartModal();
        }
      } else {
        if (address) {
          this.showLastActivityAddressModal(address.street ?? TranslatableString.get(address.name));
        }
      }
    }
  }

  static showLastActivityCartModal(cb) {
    EventSystem.publish(EventSystem.events.show_confirmation_modal, {
      title: "Kosár törlése",
      text: (
        <>
          30 percnél régebbi a kosár tartalma.
          <br/>
          Megtartod az elemeket?
        </>
      ),
      yesText: "Igen",
      noText: "Nem",
      cancelCb: () => {
        CartStorage.clearCart();
        if (cb)
          cb();
      },
      proceedCb: () => {
        if (cb)
          cb();
      }
    });
  }

  static showLastActivityAddressModal(address) {
    EventSystem.publish(EventSystem.events.show_confirmation_modal, {
      title: "Cím megerősítése",
      text: (
        <>
          30 percnél régebben lett beállítva a szállítási cím.
          <br/>
          Még mindig ide szeretnél rendelni?
          <br/>
          Cím: {address}
        </>
      ),
      yesText: "Igen",
      noText: "Nem",
      cancelCb: () => {
        window.localStorage.removeItem("selectedAddress");
        EventSystem.publish(EventSystem.events.addressSelected, {address: null});
        window.localStorage.removeItem("selectedCity");
        EventSystem.publish(EventSystem.events.citySelected, {city: null});
      }
    });
  }

  static loadFromLocalStorage() {
    let sa = window.localStorage.getItem("selectedAddress");
    let sc = window.localStorage.getItem("selectedCity");
    let cart = window.localStorage.getItem("cart");
    let lastRestaurantURL = window.localStorage.getItem("lastRestaurantURL");
    let cartPartnerID = window.localStorage.getItem("cartPartnerID");
    let pickup: boolean = window.localStorage.getItem("takeaway");

    if (sa && sa !== "undefined")
      this.selectedAddress = JSON.parse(sa);
    if (sc && sc !== "undefined")
      this.selectedCity = JSON.parse(sc);
    if (cart)
      this.cart = JSON.parse(cart);
    if (lastRestaurantURL)
      this.lastRestaurantURL = lastRestaurantURL;

    if (this.cart !== undefined && this.cart.length > 0) {
      if (cartPartnerID) {
        this.cartPartnerID = parseInt(cartPartnerID);
        if (this.cartPartnerID === -1 && this.cart && this.cart.length > 0) {
          this.cartPartnerID = parseInt(this.cart[0].partnerID);
        }

        this.loadPartnerProfile(true, this.cartPartnerID);
      }
    }

    if (pickup !== undefined) {
      this.setPickup(pickup === true);
    }
  }

  static BUDAPEST_COUNTY_ID = 2;

  static loadCities() {
    CitiesAPI.getCities((res) => {
      if (res.error !== 0)
        return;

      let cities = res.cities;
      let zipcodes = res.zipcodes;
      cities.forEach((city) => (city.isBudapest = city.countyID === this.BUDAPEST_COUNTY_ID));
      cities.sort((c1, c2) => TranslatableString.get(c1.name).localeCompare(TranslatableString.get(c2.name)));
      ContextSystem.mergeCities(cities, zipcodes);
      ContextSystem.addLoadingApp();
    });
  }

  static loadNonprofitOrganizations() {
    NonprofitAPI.getNonprofitOrganization(true, (res) => {
      if (res.error !== 0)
        return;

      this.setNonprofitOrganizations(res.nonprofitOrganization);
      ContextSystem.addLoadingApp();
    });
  }

  static setNonprofitOrganizations(nonprofitOrganizations: NonprofitOrganization[]) {
    this.nonprofitOrganizations = nonprofitOrganizations;
    EventSystem.publish(EventSystem.events.contextSystemChanged, {nonprofitOrganizations});
  }

  static mergeCities(cities, zipcodes) {
    if (!cities || !zipcodes)
      return;

    if (cities.length <= 0 || zipcodes.length <= 0)
      return;

    if (!this.cities)
      this.cities = [];
    if (!this.zipcodes)
      this.zipcodes = [];

    let changed = false;
    A: for (let city of cities) {
      for (let c of this.cities) {
        if (c.id === city.id)
          continue A;
      }
      changed = true;
      this.cities.push(city);
    }
    A: for (let zipcode of zipcodes) {
      for (let z of this.zipcodes) {
        if (z.id === zipcode.id)
          continue A;
      }
      changed = true;
      this.zipcodes.push(zipcode);
    }

    if (changed) {
      EventSystem.publish(EventSystem.events.contextSystemChanged, {cities, zipcodes});
    }
  }

  static getCityByID(id): City | null {
    for (let city of this.cities) {
      if (city.id === id)
        return city;
    }
    return null;
  }

  static loadRoutes(redirectPath: string): string {
    let originalPath = redirectPath !== undefined ? redirectPath : window.location.pathname.toLowerCase();
    let currentPath = originalPath;
    let emptyRestaurantURL = currentPath === "/etterem" || currentPath === "/e" || currentPath === "/etterem/" || currentPath === "/e/" || currentPath === "/asztal" || currentPath === "/asztal/";

    let newPath = currentPath;
    let k = 0;

    let onShopPage: boolean = currentPath.startsWith("/e/") || currentPath.startsWith("/etterem/");

    if (!ContextSystem.appLoaded) {
      if (originalPath !== newPath)
        return newPath;
      else
        return undefined;
    }

    let forceLoginStart: boolean = Config.getBoolean(ConfigKeys.start_with_login);
    while (k === 0 || newPath !== currentPath) {
      k++;
      currentPath = newPath;

      let loggedInWithEmptyProfile: boolean = ContextSystem.loggedIn === true
        && (!ContextSystem.profile
          || !ContextSystem.profile?.firstName
          || !ContextSystem.profile?.lastName
          || !ContextSystem.profile?.email
          //|| !ContextSystem.profile?.addresses
          //|| ContextSystem.profile?.addresses.length <= 0
        )
        && ContextSystem.startPageDone === false;

      if ( //you are not logged in, and you want to go to a page which needs login --> redirect to /start
        (forceLoginStart && (ContextSystem.loggedIn === false || loggedInWithEmptyProfile))
        && !currentPath.startsWith("/uj-jelszo")
        && !currentPath.startsWith("/aszf")
        && !currentPath.startsWith("/csatlakozas")
        && !currentPath.startsWith("/mi-ez-a-blundee")
        && !currentPath.startsWith("/asztal")
        && !currentPath.startsWith("/table")
        && !currentPath.startsWith("/fizetes-vege")
        // && !this.selectedAddress
        // && !this.selectedCity
        // && this.selectedNavIndex !== 1
        // && !currentPath.startsWith("/regisztracio")
        // && !currentPath.startsWith("/bejelentkezes")
      ) {
        if ((onShopPage && emptyRestaurantURL) || !onShopPage) {
          let forceLoginStart: boolean = Config.getBoolean(ConfigKeys.start_with_login);
          newPath = forceLoginStart ? "/start" : "/kezdolap";
        }
      }

      if (currentPath === "/") {
        //you are logged in, or you are going to a page without the need of login --> DEFAULT page is /kezdolap
        newPath = "/kezdolap";
      }

      if (emptyRestaurantURL) { // if URL is broken (e.g. "/etterem/" --> go to DEFAULT apge
        newPath = "/kezdolap";
      }
      if (this.loggedIn && !loggedInWithEmptyProfile && currentPath === "/start") { //you're logged in, but you want the /start page
        //should not happen
        newPath = "/kezdolap";
      }

      if (forceLoginStart && currentPath.startsWith("/kezdolap") && !this.loggedIn && this.mobileLayout) {
        newPath = "/start";
        EventSystem.publish(EventSystem.events.addressSelected, {address: null});
        EventSystem.publish(EventSystem.events.citySelected, {city: null});
      }
    }

    if (originalPath !== newPath)
      return newPath;
    else
      return undefined;
  }

  static firstProfileLoad: boolean = true;

  static reload(contextChanged: boolean, loginChanged: boolean, urlChanged: boolean) {
    if (contextChanged || urlChanged) {
      this.loadHideCart();
      this.loadHideNavBar();
    }

    if (!loginChanged) { //so it's not running twice here and 10 lines below
      let redirectPath = this.loadRoutes();
      if (redirectPath !== undefined/*queryParams !== undefined*/)
        EventSystem.publish(EventSystem.events.redirectIntent, {redirectPath/*, queryParams */});
    }

    if (loginChanged || this.firstProfileLoad === true) {
      this.firstProfileLoad = false;

      this.loadProfile(() => {
        this.loadHideCart();
        this.loadHideNavBar();
        this.loadRestaurantHearts();
        let redirectPath = this.loadRoutes();

        ContextSystem.resetWebSocket();

        if (!ContextSystem.loggedIn) {
          // just logged out
          this.orders = [];
          this.products = [];
          EventSystem.publish(EventSystem.events.contextSystemChanged, {
            orders: this.orders,
            products: this.products
          });
          ContextSystem.resetLocalOrders();
        } else {
          // just logged in
          this.emptyLocalOrders();
          if (!ContextSystem.selectedAddress && ContextSystem.profile?.addresses?.length > 0)
            EventSystem.publish(EventSystem.events.addressSelected, {address: ContextSystem.profile.addresses[0]});
        }
        ContextSystem.addLoadingApp();

        if (redirectPath !== undefined/*queryParams !== undefined*/)
          EventSystem.publish(EventSystem.events.redirectIntent, {redirectPath/*, queryParams */});
      });
    }
  }

  static setNavIndex(selectedNavIndex) {
    if (this.selectedNavIndex === selectedNavIndex)
      return;
    this.selectedNavIndex = selectedNavIndex;
    EventSystem.publish(EventSystem.events.contextSystemChanged, {selectedNavIndex});
  }

  static subscribe() {
    window.addEventListener("resize", () => {
      this.setMobileLayout();
    });
    let lastSelectedNavIndex = -1;
    EventSystem.subscribe(EventSystem.events.contextSystemChanged, (data) => {
      window.localStorage.setItem("lastActivity", Date.now());

      let changed = false;
      for (let key in data) {
        // noinspection JSUnfilteredForInLoop
        if (this.data[key] !== data[key]) {
          changed = true;
        }
        // noinspection JSUnfilteredForInLoop
        this.data[key] = data[key];
      }
      if (changed)
        this.reload(true, false, false);

      // own subscriptions
      if (data.selectedNavIndex !== undefined && data.selectedNavIndex !== lastSelectedNavIndex) {
        lastSelectedNavIndex = data.selectedNavIndex;
      }
    });

    EventSystem.subscribe(EventSystem.events.googlePlacesScriptLoaded, () => {
      this.googleAPILoaded = true;
      EventSystem.publish(EventSystem.events.contextSystemChanged, {googleAPILoaded: true});
    });

    EventSystem.subscribe(EventSystem.events.navbarChanged, ({navIndex}) => {
      this.setNavIndex(navIndex);
    });

    EventSystem.subscribe(EventSystem.events.authentication_changed, (auth) => {
      if (!this.loggedInLoaded || this.loggedIn !== auth) {
        this.loggedInLoaded = true;
        this.reload(false, true, false);
        if (auth)
          this.loadOrders(0, 3);
        if (!ContextSystem.selectedAddress)
          EventSystem.publish(EventSystem.events.addressSelected, {address: this.selectedAddress});
      }
    });

    EventSystem.subscribe(EventSystem.events.urlChanged, () => {
      this.reload(false, false, true);
    });

    EventSystem.subscribe(EventSystem.events.addressSelected, ({address}) => {
      if (address === this.selectedAddress)
        return;

      this.selectedAddress = address;
      window.localStorage.setItem("selectedAddress", JSON.stringify(address));
      EventSystem.publish(EventSystem.events.contextSystemChanged, {selectedAddress: address});

      let partnerID = -1;
      if (this.navigatingPartner !== undefined)
        partnerID = this.navigatingPartner.id;
      else if (this.cartPartnerID)
        partnerID = this.cartPartnerID;
      ContextSystem.loadAvailableShippingAddresses(partnerID, true);
    });

    EventSystem.subscribe(EventSystem.events.citySelected, ({city}) => {
      this.selectedCity = city;
      window.localStorage.setItem("selectedCity", JSON.stringify(city));
      EventSystem.publish(EventSystem.events.contextSystemChanged, {selectedCity: city});
    });

    EventSystem.subscribe(EventSystem.events.cartUpdated, (cart) => {
      this.cart = cart;
      if (cart === undefined || cart.length <= 0) {
        this.cartPartner = undefined;
        this.cartPartnerID = -1;
        this.cartPartnerURL = undefined;
        window.localStorage.setItem("cartPartnerID", this.cartPartnerID + "");
        EventSystem.publish(EventSystem.events.contextSystemChanged, {
          cartPartnerID: this.cartPartnerID,
          cartPartnerURL: this.cartPartnerURL,
          cartPartner: this.cartPartner
        });
      }
      EventSystem.publish(EventSystem.events.contextSystemChanged, {cart});
    });
  }

  static loadProfile(cb: ()=>{}) {
    // The below created bug :( : after login you have to reload the page, since the new profile is not broadcasted the
    // right way
    // if (this.loginChecked && !this.profile)
    //   return;
    // this.loginChecked = true;
    AuthAPI.checkLogin(true, (loggedIn, profile) => {
      this.loggedIn = loggedIn;
      this.profile = profile;
      EventSystem.publish(EventSystem.events.contextSystemChanged, {loggedIn, profile});
      if (cb)
        cb();
    });
  }

  static loadPartnerProfile(quite: boolean, partnerID: number, cb: ()=>{}) {
    RestaurantAPI.getByID(quite, partnerID, (res) => {
      if (res.error === 0) {
        ContextSystem.cartPartner = res.partner;
        ContextSystem.cartPartnerID = res.partner.id;
        ContextSystem.cartPartnerURL = res.partner.url;

        if (res.partner.preparedOrderMaxDays <= 0) {
          ContextSystem.setScheduleDate(null);
          if (!quite)
            Toast.showToast(Language.getName(Names.AutomaticScheduleRemovedNotificationText), 6000);
        }

        if (!res.partner.enablePersonalOrder) {
          if (!quite && ContextSystem.pickup)
            Toast.showToast(Language.getName(Names.AutomaticDeliveryChangeNotificationText), 6000);
          ContextSystem.setPickup(false);
        }
        if (!res.partner.enableDeliveryOrder) {
          if (!quite && !ContextSystem.pickup && ContextSystem.atPlaceQRID <= 0)
            Toast.showToast(Language.getName(Names.AutomaticTakeawayChangeNotificationText), 6000);
          ContextSystem.setPickup(true);
        }

        window.localStorage.setItem("cartPartnerID", this.cartPartnerID + "");
        EventSystem.publish(EventSystem.events.contextSystemChanged, {
          cartPartnerID: this.cartPartnerID,
          cartPartnerURL: this.cartPartnerURL,
          cartPartner: this.cartPartner
        });
        if (cb)
          cb();
      }
    });
  }

  static loadHideNavBar() {
    let path = window.location.pathname.toLowerCase();
    this.setHideNavBar(
      path.startsWith("/start")
    );
  }

  static loadHideCart() {
    let path = window.location.pathname.toLowerCase();
    //hide if: (new user at welcomepage) or (cart is empty)
    let hideCart =
      (
        (
          this.selectedNavIndex === 1 //bongeszes
          || path.startsWith("/regisztracio")
          || path.startsWith("/bejelentkezes")
          || path.startsWith("/aszf")
          || path.startsWith("/mi-ez-a-blundee")
        ) && this.loggedIn === false
        && !this.selectedAddress
        && !this.selectedCity
        && (!this.cart || this.cart?.length <= 0)
      )
      //don't hide cart even if it's empty, only when the user has scanned a QR code
      || (this.atPlaceQRID <= 0 && !this.atPlaceTableElement && (this.cart === undefined || this.cart.length <= 0))
      || path.startsWith("/csatlakozas")
      || path.startsWith("/foglalas")
      || path.startsWith("/mi-ez-a-blundee")
      || path.startsWith("/start");

    this.setHideCart(hideCart);
  }

  static setMobileLayout() {
    let newValue = window.innerWidth <= Config.mobileMaxWidth;
    if (newValue !== this.mobileLayout) {
      this.mobileLayout = newValue;
      EventSystem.publish(EventSystem.events.contextSystemChanged, {mobileLayout: newValue});
    }
  }

  static setHideCart(hide) {
    if (hide === this.hideCart)
      return;
    this.hideCart = hide;

    EventSystem.publish(EventSystem.events.hideCartChanged, {hideCart: hide});
    EventSystem.publish(EventSystem.events.contextSystemChanged, {hideCart: hide});
  }

  static setHideNavBar(hide: boolean) {
    if (hide === this.hideNavBar && !hide === this.hideInfoButton)
      return;

    this.hideNavBar = hide;
    EventSystem.publish(EventSystem.events.hideNavBarChanged, {hideNavBar: hide});
    EventSystem.publish(EventSystem.events.contextSystemChanged, {hideNavBar: hide, hideInfoButton: !hide});

    this.setMobileNavbarHeight(hide ? 0 : Config.mobileNavbarHeightDefault);
  }

  static getZipCode(zipCodeID: number): ZipCode | null {
    for (let zipcode of this.zipcodes) {
      if (zipcode.id === zipCodeID)
        return zipcode;
    }
    return null;
  }

  // static updateScrollPosition(scrollPosition, scrollHeight, height) {
  //   let scrollPercentage = 100 * scrollPosition / (scrollHeight - height);
  //
  //   EventSystem.publish(EventSystem.events.containerScrolled, {
  //     scrollPosition,
  //     scrollHeight,
  //     height,
  //     scrollPercentage
  //   });
  //   this.scrollPosition = scrollPosition;
  // }
  //
  // static setContainerScrolling(enableContainerScrolling) {
  //   if (enableContainerScrolling === this.enableContainerScrolling)
  //     return;
  //
  //   this.enableContainerScrolling = enableContainerScrolling;
  //   EventSystem.publish(EventSystem.events.contextSystemChanged, { enableContainerScrolling });
  // }

  static setEmptySearchScreenEnabled(emptySearchScreenEnabled: boolean): void {
    this.emptySearchScreenEnabled = emptySearchScreenEnabled;
    EventSystem.publish(EventSystem.events.contextSystemChanged, {emptySearchScreenEnabled});
  }

  static setWelcomeScreenEnabled(welcomeScreenEnabled: boolean): void {
    if (this.welcomeScreenEnabled === welcomeScreenEnabled)
      return;

    this.welcomeScreenEnabled = welcomeScreenEnabled;
    EventSystem.publish(EventSystem.events.contextSystemChanged, {welcomeScreenEnabled});
  }

  static setLastRestaurantURL(lastRestaurantURL: string): void {
    if (this.lastRestaurantURL === lastRestaurantURL)
      return;

    this.lastRestaurantURL = lastRestaurantURL;
    EventSystem.publish(EventSystem.events.contextSystemChanged, {lastRestaurantURL});
    window.localStorage.setItem("lastRestaurantURL", lastRestaurantURL);
  }

  static changeProfile(firstName: string, lastName: string, email: string): void {
    if (!this.profile)
      return;

    this.profile.firstName = firstName;
    this.profile.lastName = lastName;
    this.profile.email = email;
    EventSystem.publish(EventSystem.events.contextSystemChanged, {profile: this.profile});
  }

  static calcNavIndex(): void {
    let path = window.location.pathname.toLowerCase();
    for (let navIndex of ContextSystem.navIndexes) {
      if (path.startsWith(navIndex.path?.toLowerCase())) {
        this.selectedNavIndex = navIndex.index;
        break;
      }
    }
  }

  static setContainerHeight(containerHeight: number): void {
    if (this.containerHeight === containerHeight)
      return;

    this.containerHeight = containerHeight;
    EventSystem.publish(EventSystem.events.contextSystemChanged, {containerHeight});
  }

  /**
   * returns (calls the cb function with) true if addition to the cart can proceed, false otherwise
   * @param product
   * @param cb
   */
  static addSomethingToCart(product: Product, cb: (added: boolean)=>{}) {
    if (!product) {
      if (cb)
        cb(false);
      return;
    }

    let partnerID = product.partnerID;
    if (this.cartPartnerID === -1) {
      this.cartPartnerID = partnerID;
      ContextSystem.loadPartnerProfile(false, partnerID, () => ContextSystem.preCartAdditionAfterPartnerLoaded(product, cb));
      return;
    }
    ContextSystem.preCartAdditionAfterPartnerLoaded(product, cb);
  }

  static preCartAdditionAfterPartnerLoaded(product: Product, cb: (added: boolean)=>{}) {
    let partnerID = product.partnerID;
    if (!ContextSystem.navigatingPartner.orderAvailable) {
      Toast.showToast(Language.getName(Config.getValue(ConfigKeys.order_not_available_text)), 10000);
      cb(false);
      return;
    }

    if (this.cartPartnerID !== partnerID) {
      EventSystem.publish(EventSystem.events.show_confirmation_modal, {
        title: Language.getName(Names.ClearCart),
        text: Language.getName(Names.MixedCartShops),
        yesText: Language.getName(Names.AddToCart),
        noText: Language.getName(Names.Cancel),
        cancelCb: () => {
          if (cb)
            cb(false);
        },
        proceedCb: () => {
          CartStorage.clearCart();
          this.cartPartnerID = partnerID;
          this.loadPartnerProfile(false, partnerID);
          if (cb)
            cb(true);
        }
      });
    } else {
      if (cb)
        cb(true);
    }
  }

  static setNavigatingPartner(navigatingPartner: Shop, auto: boolean) {
    if (!navigatingPartner || navigatingPartner === this.navigatingPartner)
      return;

    this.navigatingPartner = navigatingPartner;

    if (!navigatingPartner.enablePersonalOrder && !navigatingPartner.enableDeliveryOrder) {
      ContextSystem.setPickup(false, true);
    } else if (!navigatingPartner.enablePersonalOrder) {
      if (ContextSystem.pickup && !auto)
        Toast.showToast(Language.getName(Names.AutomaticDeliveryChangeNotificationText), 6000);
      ContextSystem.setPickup(false, !auto);
    } else if (!navigatingPartner.enableDeliveryOrder) {
      if (!ContextSystem.pickup && ContextSystem.atPlaceQRID <= 0 && !auto)
        Toast.showToast(Language.getName(Names.AutomaticTakeawayChangeNotificationText), 6000);
      ContextSystem.setPickup(true, !auto);
    }

    EventSystem.publish(EventSystem.events.contextSystemChanged, {navigatingPartner});
  }

  static setLoadingAvailableShippingPrices(loadingAvailableShippingPrices) {
    if (loadingAvailableShippingPrices === undefined || loadingAvailableShippingPrices === this.loadingAvailableShippingPrices) return;

    this.loadingAvailableShippingPrices = loadingAvailableShippingPrices;
    EventSystem.publish(EventSystem.events.contextSystemChanged, {loadingAvailableShippingPrices});
  }

  static loadAvailableShippingAddresses(partnerID: number, quite: boolean) {
    if (!partnerID || partnerID <= 0)
      return;

    if (!ContextSystem.selectedAddress) {
      if (!quite)
        Toast.showToast(Language.getName(Names.YouShouldEnterExactAddress));
      return;
    }

    this.setLoadingAvailableShippingPrices(true);
    AddressAPI.checkShippingPrice(true, partnerID, ContextSystem.selectedAddress.id, (data) => {
      if (data.error !== 0)
        return;

      // data.cities;
      // data.prices;
      if (quite === false && (data.prices === undefined || data.prices.length <= 0)) {
        Toast.showToast(Language.getName(Names.TooFarFromShop));
      }
      ContextSystem.mergeShippingPrices(data.prices);
    });
  }

  static loadGlobalCategories() {
    if (this.globalCategories !== undefined && this.globalCategories.length > 0)
      return;

    CategoriesAPI.getAll(false, (res) => {
      this.globalCategories = res.globalCategories;
      EventSystem.publish(EventSystem.events.contextSystemChanged, {globalCategories: res.globalCategories});
      ContextSystem.addLoadingApp();
    });
  }

  static setSearchScreenFiltersOpened(searchScreenFiltersOpened) {
    this.searchScreenFiltersOpened = searchScreenFiltersOpened;
    EventSystem.publish(EventSystem.events.contextSystemChanged, {searchScreenFiltersOpened});
  }

  static setMobileCartOpened(mobileCartOpened: boolean) {
    if (this.mobileCartOpened === mobileCartOpened)
      return;
    this.mobileCartOpened = mobileCartOpened;
    EventSystem.publish(EventSystem.events.contextSystemChanged, {mobileCartOpened});
  }

  static setStatusBarSpaceHeight(statusBarSpaceHeight: number) {
    this.statusBarSpaceHeight = statusBarSpaceHeight;
    EventSystem.publish(EventSystem.events.contextSystemChanged, {statusBarSpaceHeight});
  }

  static setMobileNavbarHeight(mobileNavbarHeight: number) {
    this.mobileNavbarHeight = mobileNavbarHeight;
    EventSystem.publish(EventSystem.events.contextSystemChanged, {mobileNavbarHeight});
  }

  // noinspection JSUnusedGlobalSymbols
  static setMobileCartHeight(mobileCartHeight: number) {
    this.mobileCartHeight = mobileCartHeight;
    EventSystem.publish(EventSystem.events.contextSystemChanged, {mobileCartHeight});
  }

  // noinspection JSUnusedGlobalSymbols
  static setMobileContentPaddingBottom(mobileContentPaddingBottom: number) {
    this.mobileContentPaddingBottom = mobileContentPaddingBottom;
    EventSystem.publish(EventSystem.events.contextSystemChanged, {mobileContentPaddingBottom});
  }

  static setLanguage(language: number) {
    window.localStorage.setItem("language", language);
    this.reloadLanguage();
  }

  static reloadLanguage() {
    ContextSystem.language = parseInt(window.localStorage.getItem("language") ?? "0");
    EventSystem.publish(EventSystem.events.contextSystemChanged, {language: ContextSystem.language});
  }

  static setAtPlace(qrid: number, cb: (res: GetTableQRID)=>{}) {
    if (qrid < 0) { //finish ordering at this table
      this.atPlaceQRID = -1;
      this.atPlaceTableElement = undefined;
      this.atPlaceTableReservation = undefined;

      EventSystem.publish(EventSystem.events.contextSystemChanged, {
        atPlaceQRID: this.atPlaceQRID,
        atPlaceTableReservation: this.atPlaceTableReservation,
        atPlaceTableElement: this.atPlaceTableElement
      });

      this.loadHideCart();
      return;
    }

    OrdersAPI.loadTableQRID(false, qrid, (res) => {
      if (res.error !== 0)
        return;

      let lastAtPlaceQRID = this.atPlaceQRID;

      this.atPlaceQRID = res.table?.pairedQRID;
      this.atPlaceTableElement = res.table;
      this.atPlaceTableReservation = res.reservation; //could be null, not problem

      EventSystem.publish(EventSystem.events.contextSystemChanged, {
        atPlaceQRID: this.atPlaceQRID,
        atPlaceTableReservation: this.atPlaceTableReservation,
        atPlaceTableElement: this.atPlaceTableElement
      });

      if (!!res.table && lastAtPlaceQRID !== this.atPlaceQRID) {
        if (CartStorage.getCart() && CartStorage.getCart().length > 0 && CartStorage.getCart()[0].partnerID !== res.shop.id) {
          CartStorage.clearCart();
        }
        this.loadHideCart();
      }

      this.mergeOrders(res.orders, res.products, res.cities, res.zipcodes);
      MessageSender.sendRegisterForTableReservation(ContextSystem.atPlaceTableReservation?.id);

      if (cb)
        cb(res);
    });
  }

  static setAtPlace2(tableReservation: TableReservation, qrid: number, atPlaceTableElement: Element) {
    this.atPlaceQRID = qrid;
    this.atPlaceTableElement = atPlaceTableElement;
    this.atPlaceTableReservation = tableReservation; //could be null, not problem

    EventSystem.publish(EventSystem.events.contextSystemChanged, {
      atPlaceQRID: this.atPlaceQRID,
      atPlaceTableReservation: this.atPlaceTableReservation,
      atPlaceTableElement: this.atPlaceTableElement
    });

    if (!!tableReservation) {
      CartStorage.clearCart();
      this.loadHideCart();
    }
    try {
      MessageSender.sendRegisterForTableReservation(ContextSystem.atPlaceTableReservation?.id);
    } catch (e) {
      console.log(e);
    }
  }

  static setPickup(pickup: boolean, silent: boolean = false) {
    if (this.atPlaceQRID > 0)
      return;

    if (pickup && ContextSystem.navigatingPartner && !ContextSystem.navigatingPartner?.enablePersonalOrder) {
      if (!silent)
        Toast.showToast(Language.getName(Names.TakeawayNotAvailableText));
      pickup = false;
    }
    if (!pickup && ContextSystem.navigatingPartner && !ContextSystem.navigatingPartner?.enableDeliveryOrder) {
      if (!silent)
        Toast.showToast(Language.getName(Names.DeliveryNotAvailableText));
      pickup = true;
    }

    //ContextSystem.cartPartner && !ContextSystem.cartPartner?.enablePersonalOrder

    this.pickup = pickup;
    window.localStorage.setItem("takeaway", pickup);
    EventSystem.publish(EventSystem.events.contextSystemChanged, {pickup});
  }

  static startWebSocket() {
    if (this.websocket)
      return;

    this.websocket = WSConnection.getInstance();
    this.websocket.open(() => {
      console.log("WebSocket connection opened!");

      MessageSender.sendRegisterForTableReservation(ContextSystem.atPlaceTableReservation?.id);
      if (!ContextSystem.loggedIn) {
        ContextSystem.authenticateWithOrderOnWebSocket();
      }
    });
  }

  static orderPlaced(order: Order, products: Product[]) {
    this.mergeOrders([order], products);
    if (!ContextSystem.loggedIn) {
      ContextSystem.addOrderToLocalStorage(order);
      ContextSystem.startWebSocket();
      ContextSystem.authenticateWithOrderOnWebSocket(order);
    }
  }

  static resetWebSocket() {
    if (ContextSystem.websocket)
      ContextSystem.websocket.close();
    ContextSystem.websocket = null;
    ContextSystem.startWebSocket();
  }

  static getLocalOrders(): Order[] {
    let o: Order[] = localStorage.getItem("localOrders") ? JSON.parse(localStorage.getItem("localOrders")) : [];
    o.forEach(oo => OrdersAPI.fixDates(oo));
    return o;
  }

  static mergeProducts(incomingProducts: Product[]) {
    if (!incomingProducts)
      return;

    let mergedProducts: Product[] = [];
    ContextSystem.products.forEach(p => mergedProducts.push(p));

    for (let i = 0; i < incomingProducts.length; i++) {
      let updated: boolean = false;
      for (let j = 0; j < mergedProducts.length; j++) {
        if (mergedProducts[j].id === incomingProducts[i].id) {
          mergedProducts[j] = incomingProducts[i];
          updated = true;
          break;
        }
      }
      if (!updated)
        mergedProducts.push(incomingProducts[i]);
    }
    ContextSystem.products = mergedProducts.sort((p1, p2) => p1.orderPlace - p2.orderPlace);
    EventSystem.publish(EventSystem.events.contextSystemChanged, {products: mergedProducts});
  }

  static mergeShops(incoming: Shop[]) {
    if (!incoming)
      return;

    let merged: Shop[] = [];
    ContextSystem.shops.forEach(p => merged.push(p));

    for (let i = 0; i < incoming.length; i++) {
      let updated: boolean = false;
      for (let j = 0; j < merged.length; j++) {
        if (merged[j].id === incoming[i].id) {
          merged[j] = incoming[i];
          updated = true;
          break;
        }
      }
      if (!updated)
        merged.push(incoming[i]);
    }
    ContextSystem.shops = merged;
    EventSystem.publish(EventSystem.events.contextSystemChanged, {shops: merged});
  }

  static mergeCategories(incoming: Category[]) {
    if (!incoming)
      return;

    let merged: Category[] = [];
    ContextSystem.categories.forEach(p => merged.push(p));

    for (let i = 0; i < incoming.length; i++) {
      let updated: boolean = false;
      for (let j = 0; j < merged.length; j++) {
        if (merged[j].id === incoming[i].id) {
          merged[j] = incoming[i];
          updated = true;
          break;
        }
      }
      if (!updated)
        merged.push(incoming[i]);
    }
    ContextSystem.categories = merged;
    EventSystem.publish(EventSystem.events.contextSystemChanged, {categories: merged});
  }

  static mergeShippingPrices(incoming: ShippingPrice[]) {
    if (!incoming)
      return;

    let merged: ShippingPrice[] = [];
    ContextSystem.availableShippingPrices.forEach(p => merged.push(p));

    for (let i = 0; i < incoming.length; i++) {
      let updated: boolean = false;
      for (let j = 0; j < merged.length; j++) {
        if (merged[j].id === incoming[i].id) {
          merged[j] = incoming[i];
          updated = true;
          break;
        }
      }
      if (!updated)
        merged.push(incoming[i]);
    }
    ContextSystem.availableShippingPrices = merged;
    EventSystem.publish(EventSystem.events.contextSystemChanged, {availableShippingPrices: merged});
  }

  static mergeRestaurantHearts(incoming: RestaurantHeart[]) {
    if (!incoming || incoming.length <= 0)
      return;

    let merged: RestaurantHeart[] = [];
    ContextSystem.restaurantHearts.forEach(p => merged.push(p));

    for (let i = 0; i < incoming.length; i++) {
      let updated: boolean = false;
      for (let j = 0; j < merged.length; j++) {
        if (merged[j]?.id === incoming[i]?.id) {
          merged[j] = incoming[i];
          updated = true;
          break;
        }
      }
      if (!updated)
        merged.push(incoming[i]);
    }
    ContextSystem.restaurantHearts = merged;
    EventSystem.publish(EventSystem.events.contextSystemChanged, {restaurantHearts: merged});
  }

  static addOrderToLocalStorage(order: Order) {
    if (ContextSystem.loggedIn || order === undefined)
      return;

    let localOrders: Order[] = ContextSystem.getLocalOrders();
    localOrders.push(order);

    localStorage.setItem("localOrders", JSON.stringify(localOrders));
  }

  static mergeDonations(incomingDonations: Donation[]) {
    let mergedDonations: Donation[] = [];
    ContextSystem.donations.forEach(d => mergedDonations.push(d));

    for (let id of incomingDonations) {
      let updated: boolean = false;
      for (let i = 0; i < mergedDonations.length; i++) {
        let md = mergedDonations[i];
        if (md.id === id.id) {
          mergedDonations[i] = id;
          updated = true;
          break;
        }
      }
      if (!updated)
        mergedDonations.push(id);
    }

    if (mergedDonations && mergedDonations.length > 0) {
      ContextSystem.donations = mergedDonations;
      EventSystem.publish(EventSystem.events.contextSystemChanged, {donations: ContextSystem.donations});
    }
  }

  static mergeOrders(incomingOrders: Order[], incomingProducts: Product[], cities: City[], zipCodes: ZipCode[]) {
    if (incomingProducts !== undefined)
      this.mergeProducts(incomingProducts);
    if (cities !== undefined && zipCodes !== undefined)
      this.mergeCities(cities, zipCodes);

    if (incomingOrders === undefined)
      return;

    incomingOrders.forEach(io => OrdersAPI.fixDates(io));

    let mergedOrders: Order[] = [];
    ContextSystem.orders.forEach(o => mergedOrders.push(o));

    for (let io of incomingOrders) {
      let updated: boolean = false;
      for (let i = 0; i < mergedOrders.length; i++) {
        let mo = mergedOrders[i];
        if (mo.number === io.number) {
          mergedOrders[i] = io;
          updated = true;
          break;
        }
      }
      if (!updated)
        mergedOrders.push(io);
    }

    if (mergedOrders && mergedOrders.length > 0) {
      ContextSystem.orders = mergedOrders;
      EventSystem.publish(EventSystem.events.contextSystemChanged, {orders: ContextSystem.orders});
    }
  }

  static loadOrdersMaxRange: number = 0;

  static loadMoreOrders(count: number) {
    this.loadOrders(this.loadOrdersMaxRange + 1, this.loadOrdersMaxRange + 1 + count);
  }

  static loadRestaurantHearts() {
    RestaurantAPI.getHearts(res => {
      if (res.error !== 0)
        return;
      this.mergeRestaurantHearts(res.restaurantHearts);
    });
  }

  static loadOrders(first: number, last: number) {
    OrdersAPI.getOrders(true, first, last, (res) => {
      if (res.error === 0) {
        let orders: Order[] = res.orders;
        let products: Product[] = res.products;
        let donations: Donation[] = res.donations;

        if (last > this.loadOrdersMaxRange)
          this.loadOrdersMaxRange = first + orders.length - 1;

        orders.forEach(o => {
          o.date = new Date(o.date);
          if (o.scheduleDate)
            o.scheduleDate = new Date(o.scheduleDate);
        });
        ContextSystem.mergeOrders(orders, products, res.cities, res.zipCodes);
        ContextSystem.mergeDonations(donations);
      }
    });
  }

  static getProduct(id: number): Product | null {
    return this.products.find(p => p.id === id);
  }

  static emptyLocalOrders() {
    let orders: Order[] = this.getLocalOrders();
    let newOrders: Order[] = [];
    if (orders.length > 0)
      newOrders.push(orders[orders.length - 1]);

    localStorage.setItem("localOrders", JSON.stringify(newOrders));
  }

  static resetLocalOrders() {
    let localOrders: Order[] = this.getLocalOrders();

    for (let localOrder of localOrders) {
      OrdersAPI.getOrder(true, localOrder.number, localOrder.randomToken, (res: {}) => {
        if (res.error === 0) {
          ContextSystem.mergeOrders(res.orders, res.products);
        }
      });
    }
  }

  static getLastLocalOrder(): Order | undefined {
    let o = this.getLocalOrders();
    return o.length > 0 ? o[o.length - 1] : undefined;
  }

  static authenticateWithOrderOnWebSocket(order: Order = undefined) {
    if (!order) {
      let localOrders: Order[] = this.getLocalOrders();
      if (localOrders.length <= 0)
        return;
      order = localOrders[localOrders.length - 1];
    }

    this.websocket.sendMessage(MessageType.AUTHENTICATE_BASED_ON_ORDER_RANDOM_TOKEN, {
      orderNumber: order.number,
      randomToken: order.randomToken
    });
  }

  static reloadOrders() {
    if (ContextSystem.loggedIn) {
      ContextSystem.loadOrders(0, this.loadOrdersMaxRange);
    } else {
      ContextSystem.resetLocalOrders();
    }
  }

  static setNavBarLoaded() {
    ContextSystem.navBarLoaded = true;
    EventSystem.publish(EventSystem.events.contextSystemChanged, {navBarLoaded: true});
  }

  static setAtPlaceTableReservation(tableReservation: TableReservation) {
    ContextSystem.atPlaceTableReservation = tableReservation;
    EventSystem.publish(EventSystem.events.contextSystemChanged, {atPlaceTableReservation: tableReservation});
    MessageSender.sendRegisterForTableReservation(ContextSystem.atPlaceTableReservation?.id);
  }

  static setScheduleDate(scheduleDate: Date) {
    ContextSystem.scheduleDate = scheduleDate;
    EventSystem.publish(EventSystem.events.contextSystemChanged, {scheduleDate});
  }

  static addRestaurantHeart(partnerID: number) {
    ContextSystem.restaurantHearts.push({
      enabled: true,
      partnerID: partnerID,
      id: -1,
      addedDate: new Date(),
      automaticallyAdded: false,
      profileID: ContextSystem.profile?.id
    });
    EventSystem.publish(EventSystem.events.contextSystemChanged, {restaurantHearts: ContextSystem.restaurantHearts});
  }

  static removeRestaurantHeart(partnerID: number) {
    ContextSystem.restaurantHearts = ContextSystem.restaurantHearts.filter(rh => rh.partnerID !== partnerID);
    EventSystem.publish(EventSystem.events.contextSystemChanged, {restaurantHearts: ContextSystem.restaurantHearts});
  }

  static setStartPageDone(startPageDone: boolean) {
    ContextSystem.startPageDone = startPageDone;
    EventSystem.publish(EventSystem.events.contextSystemChanged, {startPageDone});
  }

  static addLoadingApp() {
    ContextSystem.loadingApp++;
    if (ContextSystem.loadingApp >= 4) //we need 4 things to finish loading. This is a counter.
      ContextSystem.appLoaded = true;

    console.log("appLoading: " + ContextSystem.loadingApp + ", loaded:");

    EventSystem.publish(EventSystem.events.contextSystemChanged, {
      loadingApp: ContextSystem.loadingApp,
      appLoaded: ContextSystem.loadingApp >= 4 ? true : undefined
    });
  }

  static loadRestaurant(restaurantName: string) {
    let auto = !ContextSystem.manualShopRedirect;
    ContextSystem.manualShopRedirect = false;

    RestaurantAPI.get(restaurantName, (res) => {
      if (res.error !== ErrorMessages.OK)
        return;

      ContextSystem.mergeProducts(res.products);
      ContextSystem.mergeShops([res.partner]);
      ContextSystem.mergeCategories(res.categories);
      ContextSystem.mergeRestaurantHearts([res.restaurantHeart]);
      ContextSystem.mergeShippingPrices(res.shippingPrices);
      ContextSystem.mergeCities(res.shippingCities, res.zipCodes);
      //ContextSystem.mergeCountries(res.countries);

      ContextSystem.setNavigatingPartner(res.partner, auto);

      ContextSystem.loadAvailableShippingAddresses(res.partner.id, true);
    });
  }

  static buildNavIndexes() {
    ContextSystem.navIndexes = [];
    ContextSystem.navIndexes.push({
      path: "/kezdolap",
      index: 0,
      icon: <BlundeeHomeIconEmpty/>,
      iconSelected: <BlundeeHomeIconFilled/>,
      selectable: true,
      name: () => Language.getName(Names.MenuHome),
    });

    ContextSystem.navIndexes.push({
      path: "/bongeszes",
      index: 1,
      icon: <BlundeeSearchIconEmpty/>,
      iconSelected: <BlundeeSearchIconFilled/>,
      selectable: true,
      name: () => Language.getName(Names.MenuSearch)
    });

    let enable_table_reservation: boolean = Config.getBoolean(ConfigKeys.enable_table_reservation);
    if (enable_table_reservation) {
      ContextSystem.navIndexes.push({
        path: "/foglalas",
        index: 4,
        icon: <BlundeeTableIcon/>,
        iconSelected: <BlundeeTableIcon/>,
        selectable: true,
        name: () => Language.getName(Names.StartReservationBtnText)
      })
    }

    let show_address_menuitem: boolean = Config.getBoolean(ConfigKeys.show_address_menuitem);
    if (show_address_menuitem) {
      ContextSystem.navIndexes.push(
        {
          path: "",
          index: 5,
          icon: "nav-icon",
          iconSelected: "nav-icon",
          selectable: false,
          name: () => Language.getName(Names.Address)
        }
      );
    }

    ContextSystem.navIndexes.push({
      path: "/profil",
      index: 3,
      icon: <BlundeeProfileIconEmpty/>,
      iconSelected: <BlundeeProfileIconFilled/>,
      selectable: true,
      name: () => Language.getName(Names.MenuProfile)
    });
  }
}

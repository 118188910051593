import { MessageSender } from "./MessageSender";
import MessageType from "./MessageType";
import type { CrossEventDataBase } from "./CrossEventHandler";
import CrossEventHandler from "./CrossEventHandler";
import Toast from "../../components/Toast";
import Language, { Names } from "../../utils/Language";

type HandlerType = (data: any) => void;

const HandleOk: HandlerType = (data: {error: number, type: number, wsToken: string}) => {
  // AuthAPI.setUnregisteredToken(wsToken);
};

const HandleError: HandlerType = (data: { error: any }) => {
  Toast.showToast(Language.getName(Names.UnknownError) + " - #" + data.error);
  //MessageSender.sendError(Object.keys(ErrorMessage).includes(data.error) ? data.error : ErrorMessage.UNEXPECTED_ERROR);
};

const HandlePing: HandlerType = () => {
  MessageSender.sendPong();
};

export type MessageBase = { error: number, type: number };
type CrossEventMessage = MessageBase & { data: CrossEventDataBase };

const HandleCrossEventPublish: HandlerType = (msg: CrossEventMessage) => {
  let eventType: number = msg.data.type;
  if (CrossEventHandler[eventType])
    CrossEventHandler[eventType](msg.data, eventType);
  else
    console.log("eventType handler is not found - " + eventType);
};

// noinspection JSUnusedLocalSymbols
const HandleCrossEventRegistered: HandlerType = (msg: MessageBase) => {
//todo
};

// noinspection JSUnusedLocalSymbols
const HandleCrossEventUnregistered: HandlerType = (msg: MessageBase) => {
//todo
};

// noinspection JSUnusedLocalSymbols
const HandleAuthenticateOrderRandomToken: HandlerType = (msg: MessageBase & { randomToken: string, orderNumber: string }) => {

};
// noinspection JSUnusedLocalSymbols
const HandleRegisterTableReservationUpdateConfirmed: HandlerType = (msg: MessageBase & { randomToken: string, orderNumber: string }) => {
  //cool
};

let handlerTable: {
  [type: number]: HandlerType;
} = {};
handlerTable[MessageType.OK] = HandleOk;
handlerTable[MessageType.ERROR] = HandleError;
handlerTable[MessageType.PING] = HandlePing;
handlerTable[MessageType.CROSS_EVENT_PUBLISH] = HandleCrossEventPublish;
handlerTable[MessageType.CROSS_EVENT_REGISTERED] = HandleCrossEventRegistered;
handlerTable[MessageType.CROSS_EVENT_UNREGISTERED] = HandleCrossEventUnregistered;
handlerTable[MessageType.AUTHENTICATE_BASED_ON_ORDER_RANDOM_TOKEN] = HandleAuthenticateOrderRandomToken;
handlerTable[MessageType.REGISTER_TABLE_RESERVATION_UPDATE] = HandleRegisterTableReservationUpdateConfirmed;

export default handlerTable;

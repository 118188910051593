import React, { Component } from "react";
import styled, { css } from "styled-components";
import EventSystem from "../../utils/EventSystem";
import FloatingAddressPicker from "../addresspicker/FloatingAddressPicker";

const FloatingAddressPickerOuterWrapper = styled.div`
  width: 100vw;
  //noinspection CssInvalidPropertyValue
  height: -webkit-fill-available;
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 22;
  transform: translateY(-100%);
  transition: transform 200ms ease-in-out, background-color 70ms ease-in-out;

  color: var(--blundee_color_normal);

  ${({open}) => open === true && css`
    transform: translateY(0);
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
  `};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FloatingAddressPickerWrapper = styled.div`
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  //padding: 15px;
  //position: absolute;
  height: auto;
  max-width: 400px;
  max-height: 300px;
  min-width: 390px;
  border-radius: 4px;
  padding: 15px;
  overflow: hidden;
  background-color: var(--blundee_background_card_1);
  box-shadow: var(--blundee_popup_shadow);
  color: var(--blundee_color_normal);

  & > svg {
    font-size: 14pt;
  }
`;

export default class FloatingAddressPickerPopUp extends Component {
  state: {
    open: boolean,
    autoClose: boolean
  } = {
    open: false,
    autoClose: false
  };

  componentDidMount() {
    EventSystem.subscribe(EventSystem.events.addressSelected, ({ address }) => {
      if (address !== undefined && this.state.autoClose) {
        this.close(false);
      }
    });

    EventSystem.subscribe(EventSystem.events.open_floating_address_picker_popup, ({ autoClose }) => this.setState({
      open: true,
      autoClose: autoClose === true
    }));
  }

  close(fireNotSetupEvent: boolean) {
    if (fireNotSetupEvent) {
      EventSystem.publish(EventSystem.events.addressPickerClosedWithoutSetup);
    }
    this.setState({ open: false, autoClose: false });
  }

  render() {
    return (
      <FloatingAddressPickerOuterWrapper open={this.state.open} onClick={() => this.close(true)}>
        {this.state.open &&
        <FloatingAddressPickerWrapper onClick={(e) => e.stopPropagation()}>
          <FloatingAddressPicker handleCloseButton={(fireNotSetupEvent) => this.close(fireNotSetupEvent)}/>
        </FloatingAddressPickerWrapper>
        }
      </FloatingAddressPickerOuterWrapper>
    );
  }
}

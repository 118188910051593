import React, { Component } from "react";
import styled, { css } from "styled-components";
import EventSystem from "../../utils/EventSystem";
import ContextSystem from "../../utils/ContextSystem";
import { Element } from "../../model/BluePrint";
import { FaShoppingCart } from "react-icons/fa";
import Cart from "./Cart";
import Language, { Names } from "../../utils/Language";
import { BlundeeButton } from "../Form";
import { Order } from "../../model/Order";
import { OrdersAPI } from "../../utils/api/OrdersAPI";

const Wrapper = styled.div.attrs({ className: "cart bg-dark" })`
  user-select: none;
  width: 500px;

  position: fixed;
  top: 110px;
  right: 0;
  color: var(--blundee_color_normal);
  box-shadow: var(--blundee_popup_shadow);
  overflow: hidden;

  z-index: 9;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;

  transition: width 0.2s ease-in-out, height 0.2s ease-in-out, box-shadow 0.2s ease-in-out, background-color 80ms ease-in-out,
  padding 200ms ease-in-out;

  ${({ opened }) => opened === false && css`
    width: 150px;
    min-height: 0;
    /* height: fit-content; */
    border-radius: 15px 0 0 15px;
    padding: 20px 10px 20px 10px;

    &:hover {
      cursor: pointer;
    }
  `}
  & > span {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: var(--cart_mobil_opened_text);
    font-size: 10pt;
    font-family: var(--blundee_font_bold);

    & > svg {
      font-size: 20pt;
      margin-right: 10px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  ${({ pop, opened }) => opened === false && pop === true && css`
    width: 200px;
  `};

  @media screen and (max-width: 800px) {
    width: calc(100% - 48px);
    bottom: ${({ mobileNavbarHeight }) => mobileNavbarHeight + 12}px;
    left: 24px;
    top: unset;
    border-radius: 3px;
    height: calc(100% - (${({ mobileNavbarHeight }) => mobileNavbarHeight}px) - 24px);
    margin: 0;
    background-color: var(--cart_mobile_opened_fill);

    ${({ opened }) => opened === true && css`
      padding-top: ${({ statusBarSpaceHeight }) => statusBarSpaceHeight}px;
    `};

    ${({ opened }) => opened === false && css`
      background-color: var(--cart_mobile_closed_fill);
      width: calc(100vw - 48px);
      border-radius: 20px;
      height: ${({ mobileCartHeight }) => mobileCartHeight}px;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;

      & > span {
        font-size: 11pt;
      }
    `};

    ${({ pop, opened }) => opened === false && pop === true && css`
      height: 60px;
    `};

    ${({ hide }) => hide === true && css`
      height: 0;
      padding: 0;
      overflow: hidden;
      box-shadow: none;
    `}
  }

  ${({ hide }) => hide === true && css`
    height: 0;
    padding: 0;
    overflow: hidden;
    box-shadow: none;
  `};

  @media screen and (min-width: 801px) {
    height: 0;
    padding: 0;
    overflow: hidden;
    box-shadow: none;
  }
`;

const AtPlaceOrdersSum = styled.div`
  width: 35%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 7px 4px;
  font-size: 10pt;

  & > div {
    text-align: center;
  }
`;

export const FinishAtPlaceOrderButtonWrapper = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0 3px;
`;

export const FinishAtPlaceOrderButton = styled(BlundeeButton)`
`;

export const TableName = styled.div`
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 3px;
  font-size: 10pt;
  cursor: pointer;

  & > div {
    margin-left: 6px;
    padding: 3px 5px;
    border-radius: 4px;
    box-shadow: 0 0 5px 1px black;
    background-color: #444440;
    color: white;
  }
`;

const AtPlaceWrapper = styled.div`
  font-weight: normal;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #444440;

  & > svg {
    font-size: 20pt;
    margin-right: 10px;

    &:hover {
      cursor: pointer;
    }
  }
`;

export default class CartPopup extends Component {
  state: {
    opened: boolean,
    pop: boolean,
    hide: boolean,
    mobileNavbarHeight: number,
    mobileCartHeight: number,
    url: string,
    language: number,
    atPlaceTableElement: Element,
    atPlaceOrders: Order[],
  } = {
    opened: false,
    pop: false,
    hide: ContextSystem.hideCart,
    mobileNavbarHeight: ContextSystem.mobileNavbarHeight,
    mobileCartHeight: ContextSystem.mobileCartHeight,
    url: window.location.pathname,
    language: ContextSystem.language,
    atPlaceTableElement: ContextSystem.atPlaceTableElement,
    atPlaceOrders: Cart.getAtPlaceOrders()
  };

  pop(t) {
    setTimeout(() => {
      this.setState({ pop: true });
    }, t);
    t += 200;
    setTimeout(() => {
      this.setState({ pop: false });
    }, t);
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
    if (prevState.hide !== this.state.hide || prevState.opened !== this.state.opened) {
      ContextSystem.setMobileCartOpened(!this.state.hide && this.state.opened);
    }
  }

  componentDidMount() {
    EventSystem.subscribe(EventSystem.events.urlChanged, () => {
      this.setState({ url: window.location.pathname });
    });

    EventSystem.subscribe(EventSystem.events.cartUpdated, (cart) => {
      this.pop(0);
    });

    EventSystem.subscribe(EventSystem.events.hideCartChanged, ({ hideCart }) => {
      console.log("hidecart: " + this.state.hide);
      this.setState({ hide: hideCart });
      if (hideCart === true)
        this.setState({ opened: false });
    });

    EventSystem.subscribe(EventSystem.events.contextSystemChanged,
      ({ mobileNavbarHeight, language, atPlaceTableElement, orders, hideCart }) => {
        if (hideCart !== undefined) {
          this.setState({ hide: hideCart });
          if (hideCart === true)
            this.setState({ opened: false });
        }
        if (mobileNavbarHeight !== undefined)
          this.setState({ mobileNavbarHeight });
        if (language !== undefined) {
          this.setState({ language });
        }
        if (atPlaceTableElement !== undefined)
          this.setState({
            atPlaceTableElement,
            atPlaceOrders: Cart.getAtPlaceOrders()
          });

        if (orders !== undefined)
          this.setState({ atPlaceOrders: Cart.getAtPlaceOrders() });
      });

    let t = 1000;
    for (let i = 0; i < 2; i++) {
      this.pop(t);
      this.pop(t + 400);
    }
  }

  toggleOpened() {
    this.setState({ opened: !this.state.opened });
  }

  static finishAtPlaceButtonClicked(e) {
    e.stopPropagation();

    let url = window.location.pathname.toLowerCase();
    if (!(url.startsWith("/asztal/") || url.startsWith("/table/"))) {
      let path = "/asztal/" + ContextSystem.atPlaceQRID;
      EventSystem.publish(EventSystem.events.redirectIntent, { redirectPath: path });
      return;
    }

    EventSystem.publish(EventSystem.events.show_confirmation_modal, {
      title: Language.getName(Names.FinishAtPlaceConfirmationTitle),
      text: (
        <>
          {Language.getName(Names.FinishAtPlaceConfirmationText)}
        </>
      ),
      yesText: Language.getName(Names.FinishAtPlaceYesButton),
      noText: Language.getName(Names.Cancel),
      proceedCb: () => {
        OrdersAPI.finishTableReservation(ContextSystem.atPlaceTableReservation.id, (res) => {
          if (res.error !== 0)
            return;

          ContextSystem.setAtPlace(-1);

          EventSystem.publish(EventSystem.events.show_confirmation_modal, {
            title: Language.getName(Names.AtPlaceFinishedTitle),
            text: (
              <>
                {Language.getName(Names.AtPlaceFinishedText)}
              </>
            ),
            yesText: Language.getName(Names.Ok),
            noText: undefined
          });
        });
      }
    });
  }

  render() {
    let orderTotalQty = Cart.getOrderTotalQty(ContextSystem.cart);
    let atPlace: boolean = this.state.atPlaceTableElement !== undefined;

    let itemPcs: number = 0;
    if (atPlace === true && this.state.atPlaceOrders && this.state.atPlaceOrders.length > 0) {
      for (let atPlaceOrder: Order of this.state.atPlaceOrders) {
        for (let orderProduct of atPlaceOrder.productList) {
          itemPcs += orderProduct.qty;
        }
      }
    }

    return (
      <Wrapper
        mobileNavbarHeight={this.state.mobileNavbarHeight}
        mobileCartHeight={this.state.mobileCartHeight}
        statusBarSpaceHeight={ContextSystem.statusBarSpaceHeight}
        hide={this.state.hide}
        pop={this.state.pop}
        atplace={atPlace}
        opened={this.state.opened}
        onClick={() => {
          if (!this.state.opened)
            this.toggleOpened();
        }}
      >
        {this.state.opened === false &&
          <>
            {atPlace === false &&
              <span>
            <FaShoppingCart onClick={() => this.toggleOpened()}/>
                {Language.getName(Names.Cart)} ({orderTotalQty <= 0 ? Language.getName(Names.Empty) : orderTotalQty + " " + Language.getName(Names.pcs)})
          </span>
            }
            {atPlace === true &&
              <AtPlaceWrapper>
                <TableName onClick={() => Cart.backToTable()}>
                  {Language.getName(Names.TableName) + ":"}
                  <div>
                    {this.state.atPlaceTableElement.name}
                  </div>
                </TableName>
                <AtPlaceOrdersSum>
                  {itemPcs > 0 &&
                    <>
                      <div>{Language.getName(Names.Total2)}</div>
                      <div>{itemPcs + " " + Language.getName(Names.Product)}</div>
                    </>
                  }
                  {itemPcs <= 0 &&
                    <div>{Language.getName(Names.TableEmpty)}</div>
                  }
                </AtPlaceOrdersSum>
                {(((this.state.url.startsWith("/asztal/") || this.state.url.startsWith("/table/")) && itemPcs > 0) || !(this.state.url.startsWith("/asztal/") || this.state.url.startsWith("/table/"))) &&
                  <FinishAtPlaceOrderButtonWrapper>
                    <FinishAtPlaceOrderButton onClick={e => CartPopup.finishAtPlaceButtonClicked(e)}>
                      {(this.state.url.startsWith("/asztal/") || this.state.url.startsWith("/table/")) &&
                        Language.getName(Names.FinishTableReservationText)
                      }
                      {!(this.state.url.startsWith("/asztal/") || this.state.url.startsWith("/table/")) &&
                        Language.getName(Names.ToTable)
                      }
                    </FinishAtPlaceOrderButton>
                  </FinishAtPlaceOrderButtonWrapper>
                }
              </AtPlaceWrapper>
            }
          </>
        }
        {this.state.opened && <Cart showCloseButton={true} toggleOpened={(...args) => this.toggleOpened(args)}/>}
      </Wrapper>
    );
  }

}

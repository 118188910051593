export default class MessageType {
  static OK: number = 0;
  static ERROR: number = 1;

  static PING: number = 2000;
  static PONG: number = 2001;
  static JOB_OFFER_TIME_UP: number = 2002;
  static JOB_OFFER: number = 2003;
  //static JOB_EXAMPLE_REQUEST: number = 2004;
  static CROSS_EVENT_REGISTER: number = 2005;
  static CROSS_EVENT_REGISTERED: number = 2006;
  static CROSS_EVENT_UNREGISTER: number = 2007;
  static CROSS_EVENT_UNREGISTERED: number = 2008;
  static CROSS_EVENT_PUBLISH: number = 2009;
  static AUTHENTICATE_BASED_ON_ORDER_RANDOM_TOKEN: number = 2010;
  static REGISTER_TABLE_RESERVATION_UPDATE: number = 2011;
}

import "@ionic/react/css/core.css";

import React from "react";
import EventSystem from "../utils/EventSystem";
import { toast, ToastContainer as ToastContainer2 } from "react-toastify";
import styled from "styled-components";
import "react-toastify/dist/ReactToastify.css";

type ButtonType = { side: string, icon: string, text: string, handler: () => {} };
type ToastType = { message: string | any, duration: number, position: string, buttons: ButtonType[] };

const ToastContainer = styled(ToastContainer2)`
  &&&.Toastify__toast-container {
    z-index: 10001;
  }

  .Toastify__toast {
  }

  .Toastify__toast-body {
  }

  .Toastify__progress-bar {
    --toastify-color-progress-dark: var(--blundee_toast_progress_color);
    --toastify-color-progress-light: var(--blundee_toast_progress_color);
  }
`;

export default class Toast extends React.Component {
  state: {
    showToast: boolean,
    message: string | any,
    duration: number,
    position: string,
    buttons: ButtonType[],
  } = {
    showToast: false,
    message: "",
    duration: 3000,
    position: "bottom",
    buttons: []
  };

  componentDidMount() {
    EventSystem.subscribe(EventSystem.events.toast,
      ({ message, buttons, position, duration }: ToastType) => {
        this.setState({
          message, buttons, position, duration,
          showToast: true
        });
      }
    );
  }

  static showToast(message: string | any, duration: number = 3000, position: string = "top-center", buttons: ButtonType[] = []) {
    toast(message, {
      position: position,
      autoClose: duration,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    });

    // if (position === null)
    //   position = Config.mobile || ContextSystem.mobileLayout ? "bottom" : "top";
    //
    // let data: ToastType = { message, duration, position, buttons };
    // EventSystem.publish(EventSystem.events.toast, data);
  }

  render() {
    return (
      <>
        <ToastContainer
          limit={3}
          //theme={"dark"}
        />
      </>
      // <IonContent>
      //   <IonToast
      //     isOpen={this.state.showToast}
      //     message={this.state.message}
      //     position={this.state.position}
      //     buttons={this.state.buttons}
      //     duration={this.state.duration}
      //     mode={"ios"}
      //     onDidDismiss={() => this.setState({ showToast: false })}
      //     cssClass={"ion-toast-container"}
      //   />
      // </IonContent>
    );
  }
}

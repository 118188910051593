import React, { Component } from "react";
import styled, { css } from "styled-components";
import Config from "../utils/Config";
import ContextSystem from "../utils/ContextSystem";
import EventSystem from "../utils/EventSystem";
import Language, { Names } from "../utils/Language";

const CookiesNotificationContainer = styled.div`
  width: 100%;
  height: 0;
  //bottom: -100%;
  //left: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
  //z-index: 1000;
  //box-shadow: 0 0 5px 1px rgb(227, 8, 19);

  //position: fixed;
  background-color: var(--blundee_cookies_bg_color);
  color: var(--blundee_cookies_color);

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;

  a {
    color: var(--blundee_cookies_a_color);
  }

  & > span {
    color: var(--blundee_cookies_color);
    font-family: var(--blundee_font_medium);
    font-size: 12pt;
    text-align: left;
  }

  div {
    border-radius: 3px;
    width: fit-content;
    padding: 6px 12px;
    margin: 6px;
    color: var(--blundee_cookies_button_color);
    background-color: var(--blundee_cookies_button_bg_color);
    transition: filter 100ms ease-in-out, background-color 100ms ease-in-out, color 100ms ease-in-out;
    font-family: var(--blundee_font_bold);
    cursor: pointer;

    &:hover {
      filter: brightness(95%);
    }

    &:active {
      filter: brightness(98%);
    }
  }

  transition: height 300ms ease-in-out;
  ${({show}) => show === true && css`
    height: 60px;
    padding: 7px 10%;
  `};

  @media screen and (max-width: 800px) {
    width: 100%;
    padding: 0;
    height: 0;
    margin: 0;
    span {
      font-size: 9pt;
    }

    transition: height 300ms ease-in-out;
    ${({show}) => show === true && css`
      height: 80px;
      padding: 0 7px;
    `}
  }
`;

class CookiesNotification extends Component {
  state: {
    cookie: boolean,
    language: number,
  } = {
    cookie: true,
    language: ContextSystem.language,
  };

  handleCookieBtn() {
    window.localStorage.setItem("cookie", "1");
    this.setState({
      cookie: true
    });
  }

  componentDidMount() {
    this.setState({
      cookie: window.localStorage.getItem("cookie") === "1"
    });

    EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({language}) => {
      if (language !== undefined) {
        this.setState({language});
      }
    });
  }

  render() {
    return (
      <CookiesNotificationContainer show={Config.mobile ? false : this.state.cookie === false}>
        <span>
          {Language.getName(Names.CookiesDetails)}
        </span>
        <div onClick={() => this.handleCookieBtn()} className={"button_cookies"}>
          {Language.getName(Names.Close)}
        </div>
      </CookiesNotificationContainer>
    );
  }

}

export default CookiesNotification;

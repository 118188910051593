import React, { Component } from "react";
import styled from "styled-components";
import Cart, { ProductDiv as OrderProductDiv } from "./Cart";
import ContextSystem from "../../utils/ContextSystem";
import EventSystem from "../../utils/EventSystem";
import Language, { Names } from "../../utils/Language";
import { ShippingMethods, ShippingPrice } from "../../model/ShippingPrice";
import { Product } from "../../model/Product";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const OrderSumUpBody = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 0 5px 5px 5px;
  overflow: hidden;
`;

export default class CartContent extends Component<{ products: Product[], showShippingPrices: boolean }> {
  eventIDs: number[] = [];

  state: {
    products: Product[],
    availableShippingPrices: ShippingPrice[],
    loadingAvailableShippingPrices: boolean,
    shippingMethod: number,
    menuOpened: { [keys: number]: boolean },
    showShippingPrices: boolean,
    language: number,
    scheduleDate: Date,
  } = {
    products: [],
    availableShippingPrices: ContextSystem.availableShippingPrices,
    loadingAvailableShippingPrices: ContextSystem.loadingAvailableShippingPrices,
    shippingMethod: ContextSystem.pickup ? ShippingMethods.PICKUP : ShippingMethods.DELIVERY,
    menuOpened: {},
    showShippingPrices: true,
    language: ContextSystem.language,
    scheduleDate: ContextSystem.scheduleDate,
  };

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
    if (this.state.products !== prevState.products) {
      this.setState({products: this.props.products});
    }
    if (this.state.showShippingPrices !== prevState.showShippingPrices) {
      this.setState({showShippingPrices: this.props.showShippingPrices});
    }
  }

  componentWillUnmount() {
    for (let eventID of this.eventIDs) {
      EventSystem.unsubscribe(eventID);
    }
  }

  componentDidMount() {
    this.eventIDs = [];

    this.setState({
      products: this.props.products,
      showShippingPrices: this.props.showShippingPrices,
      loadingAvailableShippingPrices: ContextSystem.loadingAvailableShippingPrices,
      availableShippingPrices: ContextSystem.availableShippingPrices,
      shippingMethod: ContextSystem.pickup ? ShippingMethods.PICKUP : ShippingMethods.DELIVERY
    });

    let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({language, scheduleDate}) => {
      if (language !== undefined) {
        this.setState({language});
      }
      if (scheduleDate !== undefined) {
        this.setState({scheduleDate});
      }
    });
    this.eventIDs.push(eid);
  }

  toggleMenuOpen(index) {
    let menuOpened = {...this.state.menuOpened};
    menuOpened[index] = menuOpened[index] !== true;
    this.setState({menuOpened});
  }

  isMenuOpened(index) {
    return this.state.menuOpened[index];
  }

  render() {
    let products = this.state.products;
    let total = Cart.calcOrderProductsTotalPrice(products);
    let shippingPrice = 0;
    if (this.state.showShippingPrices) {
      shippingPrice = Cart.calcOrderShippingPrice(this.state.availableShippingPrices, total);
      if (this.state.shippingMethod === ShippingMethods.DELIVERY)
        total += shippingPrice;
    }

    return (
      <Wrapper>
        <OrderSumUpBody>
          <OrderProductDiv header={true}>
            <div><b>{Language.getName(Names.ProductName)}</b></div>
            <div><b>{Language.getName(Names.Quantity)}</b></div>
            <div><b>{Language.getName(Names.Price)}</b></div>
            <div><b>{Language.getName(Names.Total)}</b></div>
          </OrderProductDiv>
          {products && products.length > 0 && products.map((p, i) => {
            return (
              <React.Fragment key={i}>
                {Cart.renderOrderProductCart(p, i, (i) => this.isMenuOpened(i), (i) => this.toggleMenuOpen(i), false)}
              </React.Fragment>
            );
          })}
          {this.state.showShippingPrices && this.state.shippingMethod === ShippingMethods.DELIVERY && (
            <OrderProductDiv>
              <div>{Language.getName(Names.ShippingPrice)}</div>
              <div/>
              <div/>
              <div>{shippingPrice} Ft</div>
            </OrderProductDiv>
          )}
          <OrderProductDiv>
            <div/>
            <div/>
            <div>
              <b>{Language.getName(Names.Total)}:</b>
            </div>
            <div>
              <b>{total}Ft</b> {/*//TODO*/}
            </div>
          </OrderProductDiv>
        </OrderSumUpBody>
      </Wrapper>
    );
  }
}

import React, { Component } from "react";
import styled from "styled-components";
import Language, { Names } from "../../utils/Language";

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 0;
  flex-wrap: nowrap;
  width: fit-content;
  overflow-y: auto;
  min-width: 480px;

  & > span {
    margin-top: 0;
    text-align: justify;
    text-justify: inter-word;
    margin-bottom: 15pt;
  }

  @media screen and (max-width: 800px) {
    min-width: unset;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const Form = styled.form`
  display: flex;
  align-items: flex-start;
  min-width: 400px;
  margin-bottom: 30px;
  max-height: 100%;
  width: ${({ width }) => (width ? width : "auto")};
  flex-direction: ${({ direction }) => (direction ? direction : "column")};
  justify-content: ${({ justify }) => (justify ? justify : "flex-start")};
  flex-wrap: ${({ wrap }) => (wrap ? wrap : "auto")};

  h3 {
    width: 100%;
    font-size: 17px;
    margin: 10px 0;
  }

  @media screen and (min-width: 801px) and (max-width: 1400px) {
    margin-bottom: 0;
    h3 {
      font-size: 15px;
      margin: 8px 0;
    }
  }

  @media screen and (max-width: 800px) {
    min-width: unset;
    width: 100%;
    margin-bottom: 10px;
    h3 {
      font-size: 14px;
      margin: 6px 0;
    }
  }
`;

export const Input = styled.input`
  width: 100%;
  display: block;
  font-family: var(--blundee_font_medium);
  font-size: 16px;
  margin-bottom: 20pt;
  min-height: 25pt;
  border: solid 1px #ccc;
  border-radius: 3px;
  padding: 14px 0 14px 10px;

  @media screen and (max-width: 800px) {
    font-size: 14px;
    margin-bottom: 14pt;
    padding: 12px 0 12px 6px;
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  display: block;
  font-family: var(--blundee_font_medium);
  font-size: 16px;
  margin-bottom: 20pt;
  min-height: 25pt;
  padding: 5pt 2pt 2pt;
  border: solid 1px #ccc;
  border-radius: 3px;
`;

export const Select = styled.select`
  width: 100%;
  display: block;
  font-family: var(--blundee_font_medium)
  font-size: 16px;
  margin-bottom: 20pt;
  min-height: 25pt;
  border: solid 1px #ccc;
  border-radius: 3px;
  padding: 14px 0 14px 6px;

  @media screen and (max-width: 800px) {
    font-size: 14px;
    margin-bottom: 14pt;
    padding: 12px 0 12px 4px;
  }
`;

const FileUploadInput = styled(Input).attrs({ type: "file" })`
  min-height: unset;
  margin-bottom: unset;
`;

const ProgressBar = styled.progress`
  width: 100%;
`;

const Wrapper = styled.div`
  width: 100%;
  font-family: var(--blundee_font_medium);
  margin-bottom: 10pt;
  height: fit-content;
  padding: 2pt 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
`;

const UploadContent = styled.div`
  width: calc(100% - 151.5px);
  height: 33px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: white;
  color: black;
  padding: 8px 0 8px 5px;
  font-size: 13px;

  @media screen and (max-width: 800px) {
    width: calc(100% - 131.5px);
  }
`;

const UploadButton = styled.button`
  background-color: var(--blundee_yellow);
  border: 1px solid var(--blundee_yellow);
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 8px 20px;
  font-size: 13px;
  font-family: var(--blundee_font_bold);
  transition: height 0.2s ease-in-out, background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  color: var(--blundee_text_color_3);
  font-weight: bold;

  &:hover {
    background-color: transparent;
    cursor: pointer;
    color: white;
  }

  &:active {
    background-color: var(--blundee_yellow_active);
    cursor: pointer;
    color: white;
  }

  @media screen and (max-width: 800px) {
    padding: 8px 10px;
  }
`;

const HiddenWrapper = styled.div`
  display: none;
`;

const StatusWrapper = styled.div`
  display: ${({ inProgress }) => (inProgress ? "flex" : "none")};
  flex-direction: column;
  justify-items: flex-start;
  align-items: flex-start;
  @media screen and (max-width: 800px) {
    & > span {
      font-size: 90%;
    }
  }
`;

export const ToastPopUpContainer = styled.div`
  text-align: justify;
  text-justify: inter-word;
  padding: 6pt;
`;

export class FileUpload extends Component<{
  onChange: (e: number)=>{},
  name: string,
  title: string,
  id: number,
  accept: boolean,
  multiple: boolean,
  status: string,
  loadedtotal: string,
}> {
  fileUploadInput: HTMLInputElement;
  clearButton: HTMLButtonElement;

  state: {
    files: string,
  } = {
    files: ""
  };

  onChange(e) {
    if (this.props.onChange) {
      this.props.onChange(e);
    }
    let files: File[] = "";
    for (let file of e.target.files) {
      files += file.name + "\n";
    }
    this.setState({ files: files });
  }

  render() {
    return (
      <>
        <Wrapper>
          <UploadContent title={this.state.files}>
            {this.fileUploadInput?.files?.length > 0 &&
            <>
              {this.fileUploadInput?.files.length}
              {Language.getName(Names.FileUploadWaitingText)}
            </>
            }
            {!this.fileUploadInput?.files?.length &&
            <>
              {Language.getName(Names.NoFilesSelected)}
            </>
            }
          </UploadContent>
          <UploadButton onClick={(e) => this.fileUploadInput.click(e)}>
            {Language.getName(Names.SelectFiles)}
          </UploadButton>
        </Wrapper>
        <HiddenWrapper>
          <FileUploadInput
            id={this.props.id}
            name={this.props.name}
            multiple={this.props.multiple}
            accept={this.props.accept}
            ref={(r) => (this.fileUploadInput = r)}
            onChange={(e) => this.onChange(e)}
          />
          <button type="reset" ref={(r) => (this.clearButton = r)}/>
        </HiddenWrapper>
        <StatusWrapper inProgress={this.props.inProgress}>
          <ProgressBar value={this.props.uploadPercentage} max="100"/>
          <span>{this.props.status}</span>
          <span>{this.props.loadedtotal}</span>
        </StatusWrapper>
      </>
    );
  }
}

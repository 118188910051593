import { OrderProduct } from "./Order";
import AbstractModel from "./AbstractModel";
import ContextSystem from "../utils/ContextSystem";
import { languages } from "../pages/StartPage";
import type { Hour } from "./Hour";

export class Product extends AbstractModel {
  name: TranslatableString;
  details: TranslatableString;
  image: string;
  image2: string;
  image3: string;
  price: number;
  originalPrice: number;
  showPrice: number;
  qty: number;
  partnerID: number;
  categoryID: number;
  globalCategoryID: number;
  type: ProductType;
  orderPlace: number;
  menuSelected: boolean;
  active: boolean;
  products: Product[];
  productExtras: ProductExtra[];
  extraGroups: ExtraGroup[];
  versions: Version[];
  additives: string;
  allergens: string;
  vatPercentage: number;
  weekSchedule: Hour[];

  uid: string;
  availableExtras: Extra[];
  warehouseInfo: string;
  alcoholPercentage: number;
  countryOrigin: string;
  sku: string;
  gtin: string;
  maxOrder: number;
  countStock: boolean;
  expirationMinutes: boolean;
  nutritions: Nutrition;

  orderProduct: OrderProduct;
  prepared: boolean;
  availableOnline: boolean;
}

export class Nutrition {
  key: TranslatableString;
  value: TranslatableString;
}

export class ProductExtra extends AbstractModel {
  extraID: number;
  maxQty: number;
  productID: number;
  partnerID: number;
  orderPlace: number;
  extraGroupID: number;
  shownName: TranslatableString;
  ///not persistent
  qty: number;
}

export class Extra extends AbstractModel {
  partnerID: number;
  price: number;
  name: TranslatableString;
}

export class ExtraGroup extends AbstractModel {
  name: TranslatableString;
  groupExtras: ProductExtra[];
  versionID: number;
  productID: number;
  partnerID: number;
  minQty: number;
  maxQty: number;
  freeQty: number;
  saleID: number;
  minPrice: number;
}

export class Version extends AbstractModel {
  productID: number;
  partnerID: number;
  saleID: number; //not used yet
  name: TranslatableString;
  price: number;
}

export class Category extends AbstractModel {
  name: TranslatableString;
  img: string;
  parent: number;
  partnerID: number;
  orderNumber: number;
  view: boolean;
  specials: boolean; //only declared here, not saved in DB (non-persistent data member)
}

export const ProductTypes = {
  PRODUCT: 1,
  MENU: 2,
};

export type ProductType = $Values<typeof ProductTypes>

export class TranslatableString {
  // noinspection JSUnusedGlobalSymbols
  translation: { languageCode: string, value: string }[];

  static get(t: TranslatableString) {
    if (!t)
      return undefined;

    let selectedLanguage: number = ContextSystem.language;

    if (t.translation && t.translation.length > 0)
      return t.translation?.find(n => n.languageCode === languages[selectedLanguage].code)?.value ?? t.translation[0].value;
    else
      return t;
  }

  static getValues(s: TranslatableString | string): [] {
    if (!s)
      return [];
    if (s.translation)
      return s.translation.map(v => v.value);
    else
      return [s];
  }

  static check(t: TranslatableString, a: (({
                                             languageCode: string,
                                             value: string
                                           })=>boolean), and: boolean = true): boolean {
    if (!t || !a)
      return false;

    for (const n of t.translation) {
      if (and && !a(n))
        return false;
      if (!and && a(n))
        return true;
    }
  }

  static modify(s: TranslatableString, newValue: string, atLanguageCode: string) {
    let s2: TranslatableString = {
      _editing: s._editing,
      translation: [],
    };

    let found: boolean = false;
    for (let t of s?.translation ?? []) {
      if (t.languageCode === atLanguageCode) {
        s2.translation.push({languageCode: atLanguageCode, value: newValue});
        found = true;
      } else
        s2.translation.push(t);
    }
    if (!found)
      s2.translation.push({languageCode: atLanguageCode, value: newValue});
    return s2;
  }

  static create(defaultValue: string = ''): TranslatableString {
    return {
      _editing: 'HU-HU',
      translation: [{
        languageCode: 'HU-HU',
        value: defaultValue
      }, {
        languageCode: 'EN-GB',
        value: defaultValue
      }]
    }
  }

  static create2(defaultValue: string[]): TranslatableString {
    let obj: TranslatableString = {
      translation: []
    };
    for (let language of languages) {
      let v: string = defaultValue && language.id < defaultValue.length ? defaultValue[language.id] : "";
      obj.translation.push({languageCode: language.code, value: v});
    }
    return obj;
  }

  static toIndexedObject(s: TranslatableString): { [keys: string]: string } {
    if (!s)
      return {'HU-HU': '', 'EN-GB': ''};

    let v: {} = {};
    for (let record of s?.translation ?? []) {
      v[record.languageCode] = record.value;
    }
    return v;
  }

  static toTranslatableString(v: { [keys: string]: string }): TranslatableString {
    let trs: TranslatableString = {translation: [], _editing: ''};
    for (let languageCode in v) {
      trs.translation.push({value: v[languageCode], languageCode: languageCode});
    }
    return trs;
  }
}

export class GlobalCategory extends AbstractModel {
  name: TranslatableString;
  icon1: string;
  icon2: string;
  parentID: number;
  orderNumber: number;
  showInOffers: boolean;
}

export const VATValueModelTypes = {
  PRODUCT: 0,
  SHOP: 1,
};

export type VATValueModelType = $Values<typeof VATValueModelTypes>

export class VATValue extends AbstractModel {
  value: number;
  modelID: number;
  shopID: number;
  shippingMode: number;
  paymentMode: number;
  type: VATValueModelType
  date: Date;
}
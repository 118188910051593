import * as React from "react";

function BlundeeHomeIconFilled(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 21 21"
      fill={"currentColor"}
      stroke={"currentColor"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M8.2126 1.15016L8.21298 1.14986C9.29727 0.279038 11.0375 0.284138 12.1379 1.16073C12.1381 1.16084 12.1382 1.16094 12.1383 1.16105L18.684 6.39756C18.6846 6.39803 18.6852 6.3985 18.6857 6.39897C19.0629 6.70714 19.3896 7.18944 19.6004 7.74098C19.811 8.29216 19.8895 8.86985 19.8162 9.35094L18.5573 16.8845C18.5572 16.8849 18.5572 16.8853 18.5571 16.8857C18.3076 18.3187 16.9132 19.5 15.4699 19.5H4.86989C3.40541 19.5 2.04242 18.3476 1.79286 16.8965C1.7928 16.8961 1.79274 16.8958 1.79268 16.8954L0.533048 9.35759L0.532854 9.35644C0.450708 8.87178 0.524437 8.29294 0.734568 7.74192C0.944679 7.19094 1.27541 6.70909 1.66173 6.40086L1.6626 6.40016L8.2126 1.15016ZM10.1699 17.25C10.8561 17.25 11.4199 16.6861 11.4199 16V13C11.4199 12.3139 10.8561 11.75 10.1699 11.75C9.48378 11.75 8.91993 12.3139 8.91993 13V16C8.91993 16.6861 9.48378 17.25 10.1699 17.25Z"/>
    </svg>
  );
}

export default BlundeeHomeIconFilled;

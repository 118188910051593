import React, { Component } from "react";
import styled from "styled-components";
//import WelcomeScreen from "./WelcomeScreen";
import SearchScreen from "./SearchScreen";
import EventSystem from "../../utils/EventSystem";
import ContextSystem from "../../utils/ContextSystem";
import { scroll } from "../../utils/ScrollToTop";

const Wrapper = styled.div`
  width: 100%;
`;

export default class BrowsePage extends Component {
  eventIDs = [];
  state: {
    selectedAddressID: number,
    selectedCityID: number,
    language: number,
  } = {
    selectedAddressID: ContextSystem.selectedAddress ? ContextSystem.selectedAddress.id : -1,
    selectedCityID: ContextSystem.selectedCity ? ContextSystem.selectedCity.id : -1,
    language: ContextSystem.language
  };

  componentWillUnmount() {
    for (let eventID of this.eventIDs)
      EventSystem.unsubscribe(eventID);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let e = this.isWelcomeScreen(this.state.selectedAddressID, this.state.selectedCityID);
    let e_old = this.isWelcomeScreen(prevState.selectedAddressID, prevState.selectedCityID);
    if (e !== e_old) {
      scroll();
    }
    ContextSystem.setWelcomeScreenEnabled(e);
  }

  componentDidMount() {
    this.eventIDs = [];
    if (ContextSystem.selectedAddress) this.setState({selectedAddressID: ContextSystem.selectedAddress.id});
    let eid;

    eid = EventSystem.subscribe(EventSystem.events.addressSelected, ({address}) => {
      this.setState({selectedAddressID: address ? address.id : -1});
    });
    this.eventIDs.push(eid);

    eid = EventSystem.subscribe(EventSystem.events.citySelected, ({city}) => {
      this.setState({selectedCityID: city ? city.id : -1});
    });
    this.eventIDs.push(eid);

    eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({language}) => {
      if (language !== undefined) {
        this.setState({language});
      }
    });
    this.eventIDs.push(eid);

    ContextSystem.setWelcomeScreenEnabled(this.isWelcomeScreen(this.state.selectedAddressID, this.state.selectedCityID));
  }

  isWelcomeScreen(selectedAddressID: number = -1, selectedCityID: number = -1) {
    return selectedAddressID === -1 && selectedCityID === -1;
  }

  render() {
    //let welcomeScreen = this.isWelcomeScreen(this.state.selectedAddressID, this.state.selectedCityID);

    return (
      <Wrapper>
        <SearchScreen/>
        {/*welcomeScreen === true && <WelcomeScreen/>*/}
        {/*welcomeScreen === false && <SearchScreen/>*/}
      </Wrapper>
    );
  }
}

import React, { Component } from "react";
import styled, { css } from "styled-components";
import EventSystem from "../../utils/EventSystem";
import ContextSystem from "../../utils/ContextSystem";
import Config from "../../utils/Config";
import Toast from "../Toast";
import Language, { Names } from "../../utils/Language";
import { Button as Button2 } from "../Form";

const Button = styled(Button2)`
  background-color: var(--blundee_yellow);
  flex-grow: 2;
  color: var(--blundee_grey);
  font-weight: bold;

  &:disabled {
    background-color: var(--blundee_background_light);
    cursor: default;
  }

  &:hover {
    background-color: var(--blundee_yellow_hover);
  }

  &:active {
    background-color: var(--blundee_yellow_active);
  }

  margin: 0;
`;

const OuterWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 23;
  transform: translateY(110%);
  backdrop-filter: blur(2px);
  transition: transform 400ms ease-in-out, background-color 400ms ease-in-out;

  ${({ opened }) => opened === true && css`
    transform: translateY(0);
    background-color: ${({ bgcolor }) => (bgcolor ? bgcolor : "rgba(0,0,0,0.15)")};
  `};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > div {
    width: fit-content;
  }

  @media screen and (max-width: 800px) {
    align-items: flex-end;
    justify-content: flex-end;
    & > div > div {
      width: 100%;
    }

    & > div {
      width: 100%;
      justify-content: flex-end;
      max-height: 100%;
      overflow-y: auto;
      display: block;
    }
  }
`;

const LoginText = styled.p`
  font-size: 12pt;
  width: 100%;
  margin-bottom: 12px;
  font-family: var(--blundee_font_medium);
  color: var(--blundee_color_normal);
`;

const InnerWrapper = styled.div`
  min-width: 200px;
  padding: 60px 24px;
  background-color: var(--blundee_background_light);
  border-radius: 10px;
  box-shadow: var(--blundee_popup_shadow);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default class LoginPopup extends Component {
  state: {
    opened: boolean,
    bgColor: string,
    cb: ()=>{}
  } = {
    opened: false,
    bgColor: window.innerWidth > Config.mobileMaxWidth ? undefined : "rgb(40, 40, 40)",
    cb: undefined
  };

  close() {
    this.setState({ opened: false });
  }

  handleKeyDownEvent(e) {
    if (this.state.opened && e.key === "Escape") {
      this.close();
    }
  }

  updateBgColor() {
    this.setState({
      bgColor: window.innerWidth > Config.mobileMaxWidth ? undefined : "rgb(40, 40, 40)"
    });
  }

  handleOnClick() {
    this.close();
    EventSystem.publish(EventSystem.events.open_start_page, {
      cb: () => {
        if (this.state.cb)
          this.state.cb();
      }
    });
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", (e) => this.handleKeyDownEvent(e));
    document.removeEventListener("resize", () => this.updateBgColor());
  }

  componentDidMount() {
    EventSystem.subscribe(EventSystem.events.open_login_popup, ({ cb }) => {
      if (ContextSystem.loggedIn || !!ContextSystem.profile) {
        Toast.showToast(Language.getName(Names.AlreadyLoggedIn));
        return;
      }

      this.setState({ opened: true, cb });
    });

    EventSystem.subscribe(EventSystem.events.open_pass_forgot_popup, () => {
      if (this.state.opened) this.setState({ opened: false });
    });
    EventSystem.subscribe(EventSystem.events.open_register_popup, () => {
      if (this.state.opened) this.setState({ opened: false });
    });

    EventSystem.subscribe(EventSystem.events.authentication_changed, (auth) => {
      if (auth && this.state.opened) this.close();
    });

    document.addEventListener("keydown", (e) => this.handleKeyDownEvent(e));
    document.addEventListener("resize", () => this.updateBgColor());
  }

  render() {
    return (
      <OuterWrapper opened={this.state.opened} onClick={() => this.close()}>
        {this.state.opened &&
          <InnerWrapper onClick={e => e.stopPropagation()}>
            <LoginText>
              {Language.getName(Names.PleaseLogIn)}
            </LoginText>
            <Button onClick={() => this.handleOnClick()}>
              {Language.getName(Names.SignIn)}
            </Button>
          </InnerWrapper>
        }
      </OuterWrapper>
    );
  }

}

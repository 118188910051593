import AbstractModel from "./AbstractModel";
import { TranslatableString } from "./Product";

export class Point {
  latitude: number;
  longitude: number;
}

export class Municipality extends AbstractModel {
  polyID: number;
  countyID: number;
  name: TranslatableString;
}

export class ZipCode extends AbstractModel {
  cityID: number;
  zipcode: string;
}

export class County extends AbstractModel {
  polyID: number;
  name: TranslatableString;
}

export class Country extends AbstractModel {
  name: TranslatableString;
  mapFilters: string;
  code: string;
  polyID: number;
  currencyID: number;
  defaultLanguageID: number;
  paymentMethods: number[];
}

export class City extends AbstractModel {
  name: TranslatableString;
  municipalityID: number;
  countyID: number;
  polygonIDs: number[];
  center: Point;

  //non-persistent
  isBudapest: boolean;
}

export class Address extends AbstractModel {
  comment: string;
  street: string;
  countyID: number;
  municipalityID: number;
  cityID: number;
  zipCodeID: number;
  coordinates: Point;
  googlePlaceID: string;
  profileID: number;
  type: AddressType;
}

export const AddressTypes: { [keys: string]: number } = {
  HOME: 1,
  WORK: 2,
  OTHER: 3,
  TEMP: 4
};

export type AddressType = $Values<typeof AddressTypes>;

export class GeoMultiPolygon extends AbstractModel {
  allCoordinates: Point[];
  allHoles: Point[];

  polyPointLengths: number[];
  holePointLengths: number[];

  coordinates: Point[][];
  holes: Point[][];
  latitudeMin: number;
  latitudeMax: number;
  longitudeMin: number;
  longitudeMax: number;
}

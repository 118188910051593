import APICaller from "../APICaller";
import EmailValidator from "../EmailValidator";
import EventSystem from "../EventSystem";
import ContextSystem from "../ContextSystem";
import Toast from "../../components/Toast";
import Config from "../Config";
import { Profile } from "../../model/Profile";
import Language, { Names } from "../Language";
import type { MessageBase } from "../ws/MessageHandler";

let authenticated = null;
let profile = null;
let checkingAuthentication = false;

function requestPasswordReset(email, cb) {
  APICaller.ownFetch(false, "PUT", "/password", { email }, (res) => {
    let result = JSON.parse(res);
    if (result.error === 0) {
      //The server signs this user off, that's why we need to do it here as well.
      authenticated = false;
      EventSystem.publish(EventSystem.events.authentication_changed, false);
    }
    if (cb)
      cb(result);
  });
}

function sendNewPassword(token, password, cb) {
  APICaller.ownFetch(false, "POST", "/password", { token, pass: password }, (res) => {
    let result = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

function loginApple(quite: boolean, authorizationCode: string, firstName: string, lastName: string, user: string, cb: (authenticated: boolean)=>{}) {
  APICaller.ownFetch(false, "PUT", "/apple/signin", {
    forWeb: !Config.ios,
    authorizationCode,
    firstName: firstName ?? "",
    lastName: lastName ?? "",
    user
  }, res => {
    let result: { error: number, type: number, authenticated: boolean, profile: Profile, token: string } = JSON.parse(res);
    if (result.error === 0) {
      if (result.authenticated) {
        authenticated = true;
        window.localStorage.setItem("usertoken", result.token);
        profile = result.profile;
        if (cb)
          cb(true);
        EventSystem.publish(EventSystem.events.authentication_changed, true);
        return;
      } else {
        if (!quite)
          Toast.showToast(Language.getName(Names.ErrorTextAppleLogin));
      }
    } else {
      if (!quite)
        Toast.showToast(Language.getName(Names.ErrorTextAppleLogin));
    }
    authenticated = false;
    if (cb)
      cb(false);
    EventSystem.publish(EventSystem.events.authentication_changed, false);
  });
}

function loginFb(quite: boolean, res: { id: string, accessToken: string, email: string }, cb: (authenticated: boolean)=>{}) {
  const { id, accessToken, email } = res;
  if (!id || !accessToken || !email) {
    if (cb)
      cb(undefined);
    return;
  }

  APICaller.ownFetch(false, "PUT", "/login", { fbToken: accessToken, fbUserID: id, email }, (res) => {
    let result = JSON.parse(res);
    if (result.error === 0) {
      if (result.authenticated) {
        authenticated = true;
        window.localStorage.setItem("usertoken", result.token);
        profile = result.profile;
        if (cb)
          cb(true);
        EventSystem.publish(EventSystem.events.authentication_changed, true);
        return;
      } else {
        if (!quite)
          Toast.showToast(Language.getName(Names.ErrorTextFacebookLogin));
      }
    } else {
      if (!quite)
        Toast.showToast(Language.getName(Names.ErrorTextFacebookLogin));
    }
    authenticated = false;
    if (cb)
      cb(false);
    EventSystem.publish(EventSystem.events.authentication_changed, false);
  });
}

// function setUnregisteredToken(token: string) {
//   window.localStorage.setItem("usertoken", token);
// }

function login(email, password, cb) {
  if (!EmailValidator(email)) {
    return false;
  }
  APICaller.ownFetch(false, "POST", "/login", { email, password }, (res) => {
    let result = JSON.parse(res);
    if (result.error !== 0) {
      Toast.showToast(Language.getName(Names.PleaseTryAgain));
    } else {
      if (result.authenticated) {
        window.localStorage.setItem("usertoken", result.token);
        authenticated = true;
        profile = result.profile;
        if (cb) cb(true);
        EventSystem.publish(EventSystem.events.authentication_changed, true);
        return;
      } else {
        Toast.showToast(Language.getName(Names.EmailOrPasswordError));
      }
    }
    authenticated = false;
    if (cb)
      cb(false);
    EventSystem.publish(EventSystem.events.authentication_changed, false);
  });
}

function checkLogin(quite: boolean, cb: (auth: boolean, profile: Profile | null)=>{}) {
  if (authenticated != null) {
    if (!authenticated) {
      if (cb)
        cb(authenticated, null);
      return;
    } else if (authenticated && profile != null) {
      if (cb)
        cb(authenticated, profile);
      return;
    }
  }

  if (!window.localStorage.getItem("usertoken")) {
    if (cb)
      cb(false, null);
    if (authenticated !== false)
      EventSystem.publish(EventSystem.events.authentication_changed, false);
    return;
  }

  if (checkingAuthentication) {
    setTimeout(() => checkLogin(quite, cb), 40);
    return;
  }

  checkingAuthentication = true;

  APICaller.ownFetch(quite, "POST", "/login", {}, (res) => {
    let result = JSON.parse(res);
    let lastAuth = authenticated;
    if (result.error === 0) {
      authenticated = result.authenticated;
      profile = authenticated ? result.profile : null;
    } else {
      authenticated = false;
      profile = null;
    }
    if (authenticated !== lastAuth)
      EventSystem.publish(EventSystem.events.authentication_changed, true);
    if (cb)
      cb(authenticated, profile);
    checkingAuthentication = false;
  });
}

function logout() {
  APICaller.ownFetch(false, "DELETE", "/login", {});
  authenticated = false;
  window.localStorage.removeItem("usertoken");
  profile = null;
  Toast.showToast(Language.getName(Names.LoggedOut));
  EventSystem.publish(EventSystem.events.authentication_changed, false);
}

function register(firstName, lastName, email, password, cb) {
  APICaller.ownFetch(
    false,
    "PUT",
    "/profile",
    {
      firstName,
      lastName,
      email,
      password
    },
    (data) => {
      let result = JSON.parse(data);
      if (result.error === 0) {
        if (result.authenticated) {
          window.localStorage.setItem("usertoken", result.token);
          authenticated = true;
          EventSystem.publish(EventSystem.events.authentication_changed, true);
          // Toast.showToast("Sikeres regisztráció! Erősítsd meg a regisztrációdat az email-ben kiküldött linken keresztül!");
          Toast.showToast(Language.getName(Names.RegisterSuccessful));
        }
      }
      if (cb)
        cb(result);
    }
  );
}

function registerFromOrder(password, orderNumber, cb) {
  APICaller.ownFetch(
    false,
    "PUT",
    "/registerfromorder",
    {
      password,
      orderNumber
    },
    (data) => {
      let result = JSON.parse(data);
      if (result.error === 0) {
        if (result.authenticated) {
          window.localStorage.setItem("usertoken", result.token);
          authenticated = true;
          EventSystem.publish(EventSystem.events.authentication_changed, true);
          Toast.showToast(Language.getName(Names.RegisterSuccessful));
          if (cb) cb(true);
          return;
        }
      }
      if (cb) cb(false);
    }
  );
}

function saveProfileDetails(firstName, lastName, /*tel,*/ email, cb) {
  APICaller.ownFetch(false, "POST", "/profile", { firstName, lastName, /*tel, */ email }, (data) => {
    let res = JSON.parse(data);
    if (res.error === 0) {
      ContextSystem.changeProfile(firstName, lastName, email);
      profile = ContextSystem.profile;
    }
    if (cb)
      cb(res);
  });
}

function changePassword(oldPass, newPass, cb) {
  APICaller.ownFetch(false, "POST", "/profile", { oldPass, newPass }, (data) => {
    let res = JSON.parse(data);
    if (cb) cb(res);
  });
}

export type SMSResponseType = MessageBase & { seconds: number, /*long, Date*/smsRequired: number };

function requireSMS(phoneNumber: string, resend: boolean, cb: (res: SMSResponseType)=>{}) {
  APICaller.ownFetch(false, "POST", "/sms", { resend, phoneNumber, language: ContextSystem.language }, (data) => {
    let res = JSON.parse(data);
    if (cb)
      cb(res);
  });
}

export type SMSVerificationResponseType = MessageBase & { verified: boolean, tempToken: string };

function verifySMSCode(verificationCode: string, phoneNumber: string, cb: (res: SMSVerificationResponseType)=>{}) {
  APICaller.ownFetch(false, "POST", "/sms/verify", { verificationCode, phoneNumber }, (data) => {
    let res = JSON.parse(data);
    if (cb)
      cb(res);
  });
}

function reloadLoginDetails() {
  profile = undefined;
  authenticated = null;
  checkLogin(true);
}

export const AuthAPI = {
  //setUnregisteredToken,
  login,
  loginFb,
  loginApple,
  checkLogin,
  logout,
  register,
  registerFromOrder,
  requestPasswordReset,
  sendNewPassword,
  saveProfileDetails,
  changePassword,
  reloadLoginDetails,
  requireSMS,
  verifySMSCode
};

import React, { Component } from "react";
import styled, { css } from "styled-components";
import EventSystem from "../../utils/EventSystem";
import ContextSystem from "../../utils/ContextSystem";
import { Input as InputUnstyled, BlundeeButton, TextArea as TextAreaUnstyled } from "../Form";
import EmailValidator from "../../utils/EmailValidator";
import { AuthAPI } from "../../utils/api/AuthAPI";
import Toast from "../Toast";
import Language, { Names } from "../../utils/Language";
import { FaSignOutAlt } from "react-icons/fa";
import { Button } from "../../pages/ProfilePage";


export const OuterWrapper = styled.div`
  width: 100vw;
  //noinspection CssInvalidPropertyValue
  height: -webkit-fill-available;
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${({z}) => z ?? "300"};
  transform: translateY(100%);
  backdrop-filter: blur(0);
  transition: transform 500ms ease-in-out, background-color 500ms ease-in-out 500ms, backdrop-filter 300ms ease-in-out 500ms;

  ${({opened}) => opened === true && css`
    backdrop-filter: blur(2px);
    transform: translateY(0);
    background-color: rgba(0, 0, 0, 0.15);
    transition: transform 500ms ease-in-out, background-color 0ms ease-in-out, backdrop-filter 300ms ease-in-out 500ms;
  `};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 800px) {
    align-items: flex-end;
    justify-content: flex-end;
    & > div > div {
      width: 100%;
    }

    & > div {
      width: 100%;
      justify-content: flex-end;
    }
  }
`;

export const Panel = styled.div`
  width: 500px;
  min-height: 50%;
  max-height: 90%;
  background-color: var(--blundee_background);
  padding: ${({padding}) => padding ?? "0 20px 20px 20px"};
  height: ${({height}) => height ?? "auto"};
  border-radius: ${({border_radius}) => border_radius ?? "10px"};
  color: var(--blundee_color_normal);
  overflow-y: auto;
  box-shadow: var(--blundee_popup_shadow);
  position: relative;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  
  

  h1 {
    text-align: center;
    padding: 20px 0 5px 0;
    font-family: var(--blundee_font_medium);
    font-size: 19pt;
    margin: 0 0 10px 0;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 2;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;

    & > h4 {
      width: 100%;
      margin: 0;
    }
  }

  @media screen and (max-width: 600px) {
    border-radius: ${({border_radius_m}) => border_radius_m ?? "10px 10px 0 0"};
    width: 100%;
    justify-content: flex-start;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const SaveButton = styled(BlundeeButton)`
  margin: 0 20px;
  padding: 10px 14px;
  font-size: 13pt;
  width: fit-content;
  height: fit-content;
`;

export const Input = styled(InputUnstyled)`
  background-color: var(--blundee_input_bg_2);
  color: var(--blundee_input_color_2);
`;

export const TextArea = styled(TextAreaUnstyled)`
  background-color: var(--blundee_input_bg_2);
  color: var(--blundee_input_color_2);
`;

export default class ProfileDetailsPopup extends Component {
  formID1;
  formID2;

  state: {
    opened: boolean,
    email: string,
    oldPassword: string,
    password: string,
    tel: string,
    firstName: string,
    lastName: string,
    language: number,
    showLogout: boolean
  } = {
    opened: false,
    email: "",
    oldPassword: "",
    password: "",
    tel: "",
    firstName: "",
    lastName: "",
    language: ContextSystem.language,
    showLogout: false
  };

  close() {
    this.setState({opened: false});
    document.body.style.overflow = "unset";
  }

  handleKeyDownEvent(e) {
    if (this.state.opened && e.key === "Escape") {
      this.close();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", (e) => this.handleKeyDownEvent(e));
    document.removeEventListener("resize", () => this.updateBgColor());
  }

  componentDidMount() {
    this.formID1 = ContextSystem.getNextUniqueID();
    this.formID2 = ContextSystem.getNextUniqueID();
    EventSystem.subscribe(EventSystem.events.open_profile_details_popup, ({showLogout}) => {
      setTimeout(() => {
        document.body.style.overflow = "hidden";
      }, 200); //transition time

      this.setState({
        opened: true,
        email: ContextSystem.profile?.email,
        tel: ContextSystem.profile?.tel,
        firstName: ContextSystem.profile?.firstName,
        lastName: ContextSystem.profile?.lastName,
        showLogout: showLogout ?? false
      });
    });

    EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({language, profile}) => {
      if (language !== undefined || profile == null)
        this.setState({
          language: ContextSystem.language,
          email: ContextSystem.profile?.email,
          tel: ContextSystem.profile?.tel,
          firstName: ContextSystem.profile?.firstName,
          lastName: ContextSystem.profile?.lastName,
          opened: this.state.opened && ContextSystem.profile !== undefined
        });

    });

    document.addEventListener("keydown", (e) => this.handleKeyDownEvent(e));
    document.addEventListener("resize", () => this.updateBgColor());
  }

  saveOnEnter(e) {
    if (e.keyCode === 13) //enter
      this.save();
  }

  savePassOnEnter(e) {
    if (e.keyCode === 13) //enter
      this.savePass();
  }

  // validateTel(tel) {
  //   const phoneNumber = parsePhoneNumberFromString(tel, "HU");
  //   return phoneNumber && phoneNumber.country === "HU" && phoneNumber.isValid() === true;
  // }

  save() {
    if (!EmailValidator(this.state.email)) {
      Toast.showToast(Language.getName(Names.GivenEmailIsNotValid));
      return;
    }
    if (this.state.lastName.length < 2) {
      Toast.showToast(Language.getName(Names.GivenNameIsNotValid));
      return;
    }
    if (this.state.firstName.length < 2) {
      Toast.showToast(Language.getName(Names.GivenNameIsNotValid));
      return;
    }
    // if (!this.validateTel(this.state.tel)) {
    //   Toast.showToast(Language.getName(Names.GivenPhoneNumberIsNotValid));
    //   return;
    // }

    AuthAPI.saveProfileDetails(this.state.firstName, this.state.lastName, /*this.state.tel, */ this.state.email, (res) => {
      if (res.error === 0) {
        Toast.showToast(Language.getName(Names.Saved));
      }
    });
  }

  savePass() {
    if (this.state.oldPassword.length < 5) {
      Toast.showToast(Language.getName(Names.GivenLastPasswordTooShort));
      return;
    }
    if (this.state.password.length < 5) {
      Toast.showToast(Language.getName(Names.GivenNewPasswordTooShort));
      return;
    }

    AuthAPI.changePassword(this.state.oldPassword, this.state.password, (res) => {
      if (res.error === 0)
        Toast.showToast(Language.getName(Names.NewPasswordSaved));
    });
  }

  logout() {
    EventSystem.publish(EventSystem.events.show_confirmation_modal, {
      title: Language.getName(Names.LogOut),
      text: (
        <>
          {Language.getName(Names.SureLogout)}
        </>
      ),
      yesText: Language.getName(Names.LogOut),
      noText: Language.getName(Names.KeepIn),
      proceedCb: () => {
        AuthAPI.logout();
        this.setState({opened: false});
      }
    });
  }

  render() {
    return (
      <OuterWrapper opened={this.state.opened} onClick={() => this.close()}>
        {this.state.opened &&
          <Panel redirect={false} onClick={(e) => e.stopPropagation()}>
            <h1>{Language.getName(Names.Settings)}</h1>
            <form id={this.formID1} autoComplete={"on"} action={"#"} onSubmit={(e) => e.preventDefault()}>
              <h4>{Language.getName(Names.PersonalData)}</h4>
              <Input
                id={"lastname" + this.formID1}
                value={this.state.lastName}
                onKeyUp={(e) => this.saveOnEnter(e)}
                type="text"
                width={"49%"}
                mwidth={"100%"}
                placeholder={Language.getName(Names.LastName)}
                autoComplete={"family-name"}
                onChange={(e) => this.setState({lastName: e.target.value})}
              />
              <Input
                id={"firstname" + this.formID1}
                value={this.state.firstName}
                onKeyUp={(e) => this.saveOnEnter(e)}
                type="text"
                width={"49%"}
                mwidth={"100%"}
                placeholder={Language.getName(Names.FirstName)}
                autoComplete={"given-name"}
                onChange={(e) => this.setState({firstName: e.target.value})}
              />
              {/*<Input*/}
              {/*  id={"tel-" + this.formID1}*/}
              {/*  value={this.state.tel}*/}
              {/*  onKeyUp={(e) => this.saveOnEnter(e)}*/}
              {/*  type="tel"*/}
              {/*  width={"49%"}*/}
              {/*  mwidth={"100%"}*/}
              {/*  placeholder={Language.getName(Names.PhoneNumber)}*/}
              {/*  autoComplete={"tel"}*/}
              {/*  onChange={(e) => this.setState({tel: e.target.value})}*/}
              {/*/>*/}
              <Input
                id={"email" + this.formID1}
                value={this.state.email}
                onKeyUp={(e) => this.saveOnEnter(e)}
                type="email"
                width={"49%"}
                mwidth={"100%"}
                placeholder={Language.getName(Names.EmailAddress)}
                autoComplete={"email"}
                onChange={(e) => this.setState({email: e.target.value})}
              />
              <ButtonWrapper>
                <SaveButton onClick={() => this.save()}>{Language.getName(Names.Save)}</SaveButton>
              </ButtonWrapper>
            </form>
            <form id={this.formID2} autoComplete={"on"} action={"#"} onSubmit={(e) => e.preventDefault()}>
              <h4>{Language.getName(Names.ChangePass)}</h4>
              <Input
                id={"current-password-" + this.formID2}
                value={this.state.oldPassword}
                onKeyUp={(e) => this.savePassOnEnter(e)}
                type="password"
                width={"49%"}
                mwidth={"100%"}
                placeholder={Language.getName(Names.OldPassword)}
                autoComplete={"current-password"}
                onChange={(e) => this.setState({oldPassword: e.target.value})}
              />
              <Input
                id={"passwordNew"}
                type={"password"}
                value={this.state.password}
                onKeyUp={(e) => this.savePassOnEnter(e)}
                width={"49%"}
                mwidth={"100%"}
                placeholder={Language.getName(Names.NewPassword)}
                autoComplete={"new-password"}
                onChange={(e) => this.setState({password: e.target.value})}
              />
              <ButtonWrapper>
                <SaveButton onClick={() => this.savePass()}>{Language.getName(Names.SavePassword)}</SaveButton>
              </ButtonWrapper>
            </form>
            {this.state.showLogout &&
              <Button onClick={() => this.logout()}>
                <FaSignOutAlt/>
                <span>{Language.getName(Names.LogOut)}</span>
              </Button>
            }
          </Panel>
        }
      </OuterWrapper>
    );
  }
}

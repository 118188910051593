import React, { Component } from "react";
import styled, { css } from "styled-components";
import { MdInfoOutline } from "react-icons/md";
import { withRouter } from "react-router-dom";
import logo from "../../assets/ownLogos/blundee_text_logo_white.png";
import EventSystem from "../../utils/EventSystem";
import type { NavIndexType } from "../../utils/ContextSystem";
import ContextSystem from "../../utils/ContextSystem";
import { AuthAPI } from "../../utils/api/AuthAPI";
import { BlundeeButton } from "../Form";
import Config from "../../utils/Config";
import Toast from "../Toast";
import Language, { Names } from "../../utils/Language";
import FloatingAddressButton from "../addresspicker/FloatingAddressButton";

const LoginButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoginButton = styled(BlundeeButton)`
  margin: 0 auto 0 auto;
  padding: 10px 14px;
  font-size: 13pt;
  background-color: white;
  border-radius: 500px;
`;

const CoverPanel = styled.div`
  position: absolute;
  top: -100%;
  left: 0;
  height: 100%;
  width: 100%;

  ${({show}) => show && css`
    top: 0;
    backdrop-filter: blur(2px);
  `}
`;

const Wrapper = styled.div`
  background-color: var(--blundee_navbar_bg_color);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;

  height: 100%;
  min-width: 0;
  width: 0;
  max-width: 230px;
  transition: width 400ms ease-in-out, min-width 400ms ease-in-out;

  ${({hide}) => hide === false && css`
    min-width: 250px;
    width: 30%;
  `};

  @media screen and (max-width: 800px) {
    background-color: var(--blundee_navbar_bg_color);
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
    max-width: unset;
    width: 100%;

    height: 0;
    min-height: 0;
    min-width: unset;
    overflow: hidden;

    transition: height 400ms ease-in-out, min-height 400ms ease-in-out;

    ${({hide}) => hide === false && css`
      height: ${({mobileNavbarHeight}) => mobileNavbarHeight}px;
      min-height: ${({mobileNavbarHeight}) => mobileNavbarHeight}px;
      min-width: unset;
      overflow: auto;
    `};
  }
`;

const LogoWrapper = styled.div`
  margin: 24px 12px 12px 12px;
  
  background-color: var(--blundee_background_secondary);
  width: calc(100% - 24px);
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 24px;

  & > img {
    width: 75%;
  }

  @media screen and (max-width: 800px) {
    display: none;
  }

  &:hover, &:active {
    cursor: pointer;
  }
`;

const NavElementDiv = styled.div`
  background-color: transparent;
  border: none;
  outline: none;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 45px;

  width: fit-content;
  padding: 4px 15px;

  font-size: 17px;
  font-family: var(--blundee_font_medium);
  color: rgb(180, 180, 180);
  cursor: pointer;

  transition: background-color 150ms ease-in-out, color 150ms ease-in-out, font-size 150ms ease-in-out;

  &:hover {
    cursor: pointer;
    color: white;
  }

  &:active {
    color: rgb(120, 120, 120);
  }

  text-decoration: none;

  &:hover, &:active, &:visited {
    text-decoration: none;
  }

  ${({selected}) => selected === true && css`
    color: white;
    font-weight: bold;
    @media screen and (max-width: 800px) {
      color: #ffef42;
    }

    &:hover {
      color: #ffef42;
    }
  `};

  ${({padding}) => padding && css`
    padding: ${padding};
  `};

  & > svg {
    font-size: 19pt;
  }

  & > span {
    margin-left: 12px;
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-grow: 1;
    width: 25%;
    padding: 0 0 10px 0;

    & > span {
      font-size: 9pt;
      margin-left: 0;
      margin-top: 2px;
    }
  }

  ${({margin_top}) => margin_top && css`
    margin-top: ${margin_top};
  `}
`;

const NavElement = styled.a`
  background-color: transparent;
  border: none;
  outline: none;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 45px;

  width: fit-content;
  padding: 12px 12px 12px 36px;

  font-size: 17px;
  font-family: var(--blundee_font_medium);
  color: var(--blundee_color_nav_icon);
  cursor: pointer;

  transition: background-color 150ms ease-in-out, color 150ms ease-in-out, font-size 150ms ease-in-out;
  user-select: none;
  
  ${({selectable}) => selectable === true && css`
    &:hover {
      cursor: pointer;
      color: var(--blundee_color_nav_icon_selected);
      background-color: var(--blundee_bg_color_nav_icon_selected);
    }

    &:active {
      color: var(--blundee_color_nav_icon_selected);
      background-color: var(--blundee_bg_color_nav_icon_selected);
    }
  `};

  text-decoration: none;

  &:hover, &:active, &:visited {
    text-decoration: none;
  }

  ${({selectable, selected}) => selectable === true && selected === true && css`
    color: var(--blundee_color_nav_icon_selected);
    background-color: var(--blundee_bg_color_nav_icon_selected);
    font-weight: bold;

    &:hover {
      color: var(--blundee_color_nav_icon_selected);
      background-color: var(--blundee_bg_color_nav_icon_selected);
    }
  `};

  ${({padding}) => padding && css`
    padding: ${padding};
  `};

  & > svg {
    font-size: 19pt;
  }

  & > span {
    margin-left: 24px;
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-grow: 1;
    width: 25%;
    padding: 0;

    & > span {
      display: none;
    }

    & > svg {
      font-size: 21pt;
    }
  }

  ${({margin_top}) => margin_top && css`
    margin-top: ${margin_top};
  `}
`;

const Bottom = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: auto;
  margin-bottom: 48px;
`;

class History {
  pathname: string;
  search: string;
  hash: string;
  key: string;
}

class NavBar extends Component {
  static history: History[] = []; //bucket style: pop, push, peek... etc.

  state: {
    mobile: boolean,
    selectedItem: number,
    hide: boolean,
    loggedIn: boolean,
    mobileCartOpened: boolean,
    hideCart: boolean,
    mobileNavbarHeight: number,
    language: number,
  } = {
    mobile: window.innerWidth <= Config.mobileMaxWidth,
    selectedItem: ContextSystem.selectedNavIndex,
    hide: ContextSystem.hideNavBar,
    loggedIn: !!ContextSystem.profile,
    mobileCartOpened: ContextSystem.mobileCartOpened,
    hideCart: ContextSystem.hideCart,
    mobileNavbarHeight: ContextSystem.mobileNavbarHeight,
    language: ContextSystem.language
  };

  constructor(props) {
    super(props);
    if (!this.state) this.state = {};
    this.state.hide = ContextSystem.hideNavBar;
    this.state.loggedIn = ContextSystem.hideNavBar;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    // console.log("ROUTE CHANGED", this.props.history.location);
    /*example: {pathname: "/e/teszt", search: "?termek=522", hash: "", key: "vepo0k"}*/
    let historyLocation: History = this.props.history.location;

    if (NavBar.history.length <= 0) {
      NavBar.history.push(historyLocation);
    } else {
      let matchHistory: History = null;
      let matchIndex = -1;
      for (let i = 0; i < NavBar.history.length; i++) {
        let history = NavBar.history[i];
        if (history.key === historyLocation.key) {
          matchHistory = history;
          matchIndex = i;
          break;
        }
      }
      if (matchHistory) {
        NavBar.history = NavBar.history.slice(0, matchIndex + 1);
      } else {
        if (historyLocation.state?.goBack !== true) {
          NavBar.history.push(historyLocation);
        }
      }
    }
    console.log("ROUTE_CHANGED", NavBar.history);
    EventSystem.publish(EventSystem.events.urlChanged);
  }

  componentDidMount() {
    this.onRouteChanged(); //initialize

    window.addEventListener("resize", () => this.setState({mobile: window.innerWidth <= Config.mobileMaxWidth}));
    AuthAPI.checkLogin(true, (loggedIn) => {
      this.setState({loggedIn});
    });
    EventSystem.subscribe(EventSystem.events.authentication_changed, () => {
      this.setState({loggedIn: !!ContextSystem.profile});
    });
    EventSystem.subscribe(EventSystem.events.hideNavBarChanged, ({hideNavBar}) => {
      this.setState({hide: hideNavBar});
    });
    EventSystem.subscribe(EventSystem.events.contextSystemChanged,
      ({hideNavBar, selectedNavIndex, mobileCartOpened, hideCart, mobileNavbarHeight, language, profile}) => {
        if (mobileCartOpened !== undefined)
          this.setState({mobileCartOpened});
        if (profile !== undefined)
          this.setState({loggedIn: !!ContextSystem.profile});
        if (hideCart !== undefined)
          this.setState({hideCart});
        if (hideNavBar !== undefined) {
          this.setState({hide: hideNavBar});
        }
        if (selectedNavIndex !== undefined) {
          this.setState({selectedItem: selectedNavIndex});
        }
        if (mobileNavbarHeight !== undefined) {
          this.setState({mobileNavbarHeight});
        }
        if (language !== undefined) {
          this.setState({language});
        }
      });

    EventSystem.subscribe(EventSystem.events.redirectIntent, ({redirectPath, queryParams, goBack}) => {
      if (goBack === true) {
        if (NavBar.history.length <= 1) {
          if (!queryParams) {
            redirectPath = "/";
            queryParams = undefined;
          }
          //and continue
        } else {
          this.props.history.goBack();
          return;
        }
      }

      if (!redirectPath)
        redirectPath = this.props.history.location.pathname;

      let changeRedirectionTo = ContextSystem.loadRoutes(redirectPath);
      if (changeRedirectionTo !== undefined)
        redirectPath = changeRedirectionTo;

      let searchParamsText = this.props.history.location.search === "" ? "" : this.props.history.location.search.substring(1);
      let params: URLSearchParams = new URLSearchParams(searchParamsText);

      if (queryParams) {
        for (let k in queryParams) {
          // noinspection JSUnfilteredForInLoop
          if (queryParams[k] === undefined) {
            // noinspection JSUnfilteredForInLoop
            params.delete(k);
          } else {
            // noinspection JSUnfilteredForInLoop
            params.set(k, queryParams[k]);
          }
        }
      }

      let newCompletePath = redirectPath + (Array.from(params).length > 0 ? "?" + params.toString() : "");
      if (NavBar.history.length >= 2) {
        let h: History = NavBar.history[NavBar.history.length - 2];
        if (goBack && newCompletePath === h.pathname + h.search) {
          return;
        }
      }

      this.props.history.push({
        pathname: redirectPath,
        search: params.toString().length > 0 ? "?" + params.toString() : "",
        state: {goBack: goBack === true}
      });

      for (let navIndex of ContextSystem.navIndexes) {
        if (redirectPath.toLowerCase().includes(navIndex.path.toLowerCase())) {
          this.setState({selectedItem: navIndex.index});
          break;
        }
      }
    });
    ContextSystem.setNavBarLoaded();
  }

  selectNavItem(element: NavIndexType) {
    if (!element.path) {
      return;
    }

    if (ContextSystem.mobileCartOpened && ContextSystem.hideCart === false) {
      return;
    }
    this.redirect(element.path);
    if (element.index === 2 && !ContextSystem.lastRestaurantURL) {
      Toast.showToast(Language.getName(Names.MenuRedirectTo));
      return;
    }
    ContextSystem.setNavIndex(element.index);
  }

  redirect(path) {
    EventSystem.publish(EventSystem.events.redirectIntent, {redirectPath: path});
  }

  logoClicked() {
    EventSystem.publish(EventSystem.events.addressSelected, {address: null});
    EventSystem.publish(EventSystem.events.citySelected, {city: null});
    this.redirect("/");
  }

  loginClicked(){
    EventSystem.publish(EventSystem.events.open_start_page, {});
  }

  render() {
    return (
      <Wrapper
        hide={this.state.hide}
        mobileNavbarHeight={this.state.mobileNavbarHeight}
      >
        <CoverPanel show={this.state.mobileCartOpened && this.state.hideCart === false}/>
        <LogoWrapper onClick={() => this.logoClicked()}>
          <img src={logo} alt={"logo"}/>
        </LogoWrapper>
        {ContextSystem.navIndexes.map((element, i) => (
          <NavElement key={i} selectable={element.selectable}
                      selected={element.selectable && this.state.selectedItem === element.index}
                      onClick={() => this.selectNavItem(element)}>
            {element.icon === "nav-icon" &&
              <FloatingAddressButton usedInNavBar={true}/>
            }
            {element.icon !== "nav-icon" &&
              <>
                {element.selectable && this.state.selectedItem === element.index &&
                  element.iconSelected
                }
                {!(element.selectable && this.state.selectedItem === element.index) &&
                  element.icon
                }
              </>
            }
            <span>{element.name()}</span>
          </NavElement>
        ))}

        {this.state.mobile === false && (
          <>
            {this.state.loggedIn === false && (
              <LoginButtonWrapper>
                <NavElementDiv padding={"0"} onClick={() => this.loginClicked()} margin_top={"20px"}>
                  <LoginButton>{Language.getName(Names.SignIn)}</LoginButton>
                </NavElementDiv>
              </LoginButtonWrapper>
            )}
            <Bottom>
              <NavElement onClick={() => this.redirect("/csatlakozas")}>
                <MdInfoOutline/>
                <span>{Language.getName(Names.ForRestaurants)}</span>
              </NavElement>
              <NavElement onClick={() => this.redirect("/aszf")}>
                <MdInfoOutline/>
                <span>{Language.getName(Names.Information)}</span>
              </NavElement>
              {/*{this.state.loggedIn === true && (*/}
              {/*  <NavElement onClick={() => this.logOut()}>*/}
              {/*    <FaSignOutAlt/>*/}
              {/*    <span>{Language.getName(Names.LogOut)}</span>*/}
              {/*  </NavElement>*/}
              {/*)}*/}
            </Bottom>
          </>
        )}
      </Wrapper>
    );
  }
}

export default withRouter(NavBar);

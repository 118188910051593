import React, { Component } from "react";
import styled, { css } from "styled-components";
import ContextSystem from "../../utils/ContextSystem";
import EventSystem from "../../utils/EventSystem";
import { FaBriefcase, FaHome } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs";
import { AddressAPI } from "../../utils/api/AddressAPI";
import Toast from "../Toast";
import { Address, AddressTypes } from "../../model/Address";
import Language, { Names } from "../../utils/Language";

const Wrapper = styled.div`
  width: 100%;
  height: 70px;
  ${({ fontsize }) => fontsize === "s" && css`
    height: 60px;
  `};

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--blundee_background_card_2);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: var(--blundee_card_shadow);

  position: relative;

  & > h1 {
    font-family: var(--blundee_font_bold);
    color: var(--blundee_color_normal);
    font-size: 11pt;
    width: 100%;
    font-style: italic;
    padding: 0 2%;
    margin: 0;
  }

  @media screen and (max-width: 800px) {
    height: 90px;
  }
`;

const EditWrapper = styled.div`
  height: fit-content !important;
  position: absolute;
  font-size: 13pt;
  padding: 5px;
  top: 0;
  right: 0;
  z-index: 2;

  transition: font-size 100ms ease-in-out;
  cursor: pointer;

  &:hover {
    filter: brightness(95%);
  }

  &:active {
    filter: brightness(98%);
  }

  @media screen and (max-width: 800px) {
    padding: 3px;
  }
`;

const SavedAddressWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(33%);
  padding: 0 4px;
  position: relative;
  color: var(--blundee_color_normal);
  background-color: transparent;
  user-select: none;
  ${({ selected }) => selected === true && css`
    background-color: var(--blundee_background_card_1);
  `};
`;

const SavedAddress = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  transition: transform 100ms ease-in-out, font-size 100ms ease-in-out, color 100ms ease-in-out;

  ${({ fontsize }) => {
    if (fontsize === undefined)
      return css`
        font-size: 12pt;
      `;
    else if (fontsize === "s")
      return css`
        font-size: 10pt;
      `;
    else
      return css`
        font-size: 12pt;
      `;
  }};

  & > span {
    font-style: italic;
    text-align: center;
    font-size: 10.5pt;
    height: 50%;
  }

  & > div {
    text-align: center;
    height: 50%;
  }

  & > svg {
    ${({ fontsize }) => {
      if (fontsize === undefined)
        return css`
          font-size: 25pt;
        `;
      else if (fontsize === "s")
        return css`
          font-size: 17pt;
        `;
      else
        return css`
          font-size: 25pt;
        `;
    }};
    margin-bottom: 4px;
    height: 50%;
  }
  cursor: pointer;

  &:hover {
    transform: scale(1.02);
    filter: brightness(95%);
  }

  &:active {
    transform: scale(0.95);
    filter: brightness(98%);
  }

  @media screen and (max-width: 800px) {
    transform: scale(1);
    &:hover {
      transform: none;
      cursor: default;
    }

    &:active {
      transform: scale(0.98);
    }

    font-size: 9.5pt;

    & > span {
      font-size: 9pt;
    }

    & > svg {
      font-size: 20pt;
    }
  }
`;

const Separator = styled.div`
  width: 1px;
  height: 78%;
  background-color: var(--blundee_background);
`;

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgba(45, 45, 45, 0.6);
  backdrop-filter: blur(1px);
  z-index: 1;
  cursor: pointer;

  & > h1 {
    color: var(--blundee_yellow);
    font-size: 16px;
    margin: 0;
  }
`;

class SavedAddressPicker extends Component {
  eventIDs = [];

  state: {
    loggedIn: boolean,
    addresses: Address[],
    editingAddressType: boolean,
    showModifyIcons: boolean,
    modifyOnClick: boolean,
    fontsize: string | undefined,
    selected: {
      home: boolean,
      work: boolean,
      other: boolean,
    },
    language: number,
  } = {
    loggedIn: ContextSystem.loggedIn,
    addresses: ContextSystem.profile ? ContextSystem.profile.addresses : [],
    editingAddressType: -1,
    showModifyIcons: true,
    modifyOnClick: false,
    fontsize: undefined,
    selected: {
      home: false,
      work: false,
      other: false
    },
    language: ContextSystem.language
  };

  load() {
    this.setState({ loggedIn: ContextSystem.loggedIn });
    AddressAPI.getAddresses(true, (res) => {
      if (ContextSystem.profile) {
        ContextSystem.profile.addresses = res.addresses;
        EventSystem.publish(EventSystem.events.contextSystemChanged, { profile: ContextSystem.profile });
      }
      this.setState({ addresses: res.addresses });
      ContextSystem.mergeCities(res.cities, res.zipcodes);
    });
  }

  componentWillUnmount() {
    for (let eventID of this.eventIDs) {
      EventSystem.unsubscribe(eventID);
    }
    this.eventIDs = [];
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.showModifyIcons !== prevProps.showModifyIcons)
      this.setState({ showModifyIcons: this.props.showModifyIcons });
    if (this.props.modifyOnClick !== prevProps.modifyOnClick)
      this.setState({ modifyOnClick: this.props.modifyOnClick });
    if (this.props.fontsize !== prevProps.fontsize)
      this.setState({ fontsize: this.props.fontsize });
  }

  componentDidMount() {
    this.eventIDs = [];

    this.load();
    if (this.props.showModifyIcons !== undefined)
      this.setState({ showModifyIcons: this.props.showModifyIcons });
    if (this.props.modifyOnClick !== undefined)
      this.setState({ modifyOnClick: this.props.modifyOnClick });
    if (this.props.fontsize !== undefined)
      this.setState({ fontsize: this.props.fontsize });

    let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ loggedIn, language }) => {
      if (language !== undefined) {
        this.setState({ language });
      }
      if (loggedIn !== undefined) {
        if (!loggedIn) {
          this.setState({ loggedIn: false, addresses: [] });
        } else if (loggedIn === true && this.state.loggedIn !== true) {
          this.load();
        }
      }
    });
    this.eventIDs.push(eid);

    eid = EventSystem.subscribe(EventSystem.events.addressSelected, ({ address }) => this.setSelectedAddress(address));
    this.eventIDs.push(eid);

    this.setSelectedAddress.bind(this);
    this.setSelectedAddress(ContextSystem.selectedAddress);
  }

  setSelectedAddress(selectedAddress, updatedRecently = false) {
    if (!selectedAddress) {
      let selected = {
        home: false,
        work: false,
        other: false
      };
      this.setState({ selected: selected });
      return;
    }
    if (updatedRecently) {
      AddressAPI.getAddresses(true, (res) => {
        this.setSelectedAddressBase(res.addresses, selectedAddress);
      });
    } else {
      this.setSelectedAddressBase(ContextSystem.profile?.addresses, selectedAddress);
    }
  }

  setSelectedAddressBase(addresses, selectedAddress) {
    if (addresses && selectedAddress) {
      for (let address of addresses) {
        if (address.id === selectedAddress.id || address.googlePlaceID === selectedAddress.placeID) {
          let selected = {
            home: selectedAddress.type === AddressTypes.HOME,
            work: selectedAddress.type === AddressTypes.WORK,
            other: selectedAddress.type === AddressTypes.OTHER
          };
          this.setState({ selected: selected });
          break;
        }
      }
    }
  }

  getAddress(type) {
    if (!this.state.addresses) return null;

    for (let address of this.state.addresses) {
      if (address.type === type) return address;
    }
    return null;
  }

  handleEditClick(e, type) {
    if (e)
      e.stopPropagation();
    let address = this.getAddress(type);
    // if (!address)
    //   return;
    this.setState({ editingAddressType: type }, () => {
      EventSystem.publish(EventSystem.events.open_address_picker_popup, {
        address,
        cb: (selectedAddress) => this.handleAddressPicked(selectedAddress, false)
      });
    });
  }

  handleAddressPicked(selectedAddress, autoselect) {
    if (this.state.editingAddressType === -1)
      return;

    selectedAddress.type = this.state.editingAddressType;
    this.setState({ editingAddressType: -1 });
    AddressAPI.saveAddress(selectedAddress, (res) => {
      this.load();
      this.setSelectedAddress(res.address, true);
      if (autoselect === true) {
        EventSystem.publish(EventSystem.events.addressSelected, { address: res.address });
      }
    });
  }

  handleAddressClick(type) {
    let address = this.getAddress(type);
    if (this.state.modifyOnClick) {
      this.handleEditClick(null, type);
    } else {
      if (address) {
        this.setSelectedAddress(address);
        EventSystem.publish(EventSystem.events.addressSelected, { address });
      } else {
        this.setState({ editingAddressType: type }, () => {
          EventSystem.publish(EventSystem.events.open_address_picker_popup, {
            address,
            cb: (selectedAddress) => this.handleAddressPicked(selectedAddress, true)
          });
        });
      }
    }
  }

  render() {
    let homeAddress = this.getAddress(AddressTypes.HOME);
    let workAddress = this.getAddress(AddressTypes.WORK);
    let otherAddress = this.getAddress(AddressTypes.OTHER);
    return (
      <>
        <Wrapper fontsize={this.state.fontsize}>
          {this.state.loggedIn === false && (
            <Overlay
              onClick={() => {
                Toast.showToast(Language.getName(Names.ToSaveAddressMustBeLoggedInOrRegister));
              }}
            >
              <h1>{Language.getName(Names.ToSaveAddressMustBeLoggedIn)}</h1>
            </Overlay>
          )}

          <SavedAddressWrapper selected={this.state.selected.home}>
            <SavedAddress fontsize={this.state.fontsize} onClick={() => this.handleAddressClick(AddressTypes.HOME)}>
              <FaHome/>
              {homeAddress && <div>{homeAddress.street}</div>}
              {!homeAddress && <span>{Language.getName(Names.SetUpHomeAddress)}</span>}
            </SavedAddress>
            {homeAddress && this.state.showModifyIcons && (
              <EditWrapper onClick={(e) => this.handleEditClick(e, AddressTypes.HOME)}>
                <BsThreeDotsVertical/>
              </EditWrapper>
            )}
          </SavedAddressWrapper>
          <Separator/>
          <SavedAddressWrapper selected={this.state.selected.work}>
            <SavedAddress fontsize={this.state.fontsize} onClick={() => this.handleAddressClick(AddressTypes.WORK)}>
              <FaBriefcase/>
              {workAddress && <div>{workAddress.street}</div>}
              {!workAddress && <span>{Language.getName(Names.SetUpWorkAddress)}</span>}
              {workAddress && this.state.showModifyIcons && (
                <EditWrapper onClick={(e) => this.handleEditClick(e, AddressTypes.WORK)}>
                  <BsThreeDotsVertical/>
                </EditWrapper>
              )}
            </SavedAddress>
          </SavedAddressWrapper>
          <Separator/>
          <SavedAddressWrapper selected={this.state.selected.other}>
            <SavedAddress fontsize={this.state.fontsize} onClick={() => this.handleAddressClick(AddressTypes.OTHER)}>
              <MdLocationOn/>
              {otherAddress && <div>{otherAddress.street}</div>}
              {!otherAddress && <span>{Language.getName(Names.SetUpOtherAddress)}</span>}
            </SavedAddress>
            {otherAddress && this.state.showModifyIcons && (
              <EditWrapper onClick={(e) => this.handleEditClick(e, AddressTypes.OTHER)}>
                <BsThreeDotsVertical/>
              </EditWrapper>
            )}
          </SavedAddressWrapper>
        </Wrapper>
      </>
    );
  }
}

export default SavedAddressPicker;

import * as React from "react";

function BlundeeTableIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 315 265"
      fill={"currentColor"}
      stroke={"currentColor"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M308 68.368H88.728a7 7 0 00-7 7v41.282a7 7 0 007 7h102.636v126.43h-39.359a7 7 0 100 14h92.72a7 7 0 100-14h-39.36V123.65H308a7 7 0 007-7V75.368a7 7 0 00-7-7z"
        // fill="#000"
        // fillOpacity={0.3}
      />
      <path
        d="M107.839 145.859H14v-49.39h10.595a7.001 7.001 0 006.047-3.472c.426-.73 10.429-18.18 10.429-44.304 0-26.125-10.004-43.571-10.43-44.301A7 7 0 0024.595.919H7a7 7 0 00-7 7v249.159a7 7 0 1014 0v-67.443h86.839v67.443a7 7 0 1014 0v-104.22a7 7 0 00-7-6.999z"
        // fill="#000"
        // fillOpacity={0.3}
      />
    </svg>
  );
}

export default BlundeeTableIcon;

import EventSystem from "./EventSystem";
import MenuCombiner from "../components/MenuCombiner";
import Cart from "../components/cart/Cart";
import ContextSystem from "./ContextSystem";
import Toast from "../components/Toast";
import { Product, ProductTypes } from "../model/Product";
import { OrderProduct } from "../model/Order";
import Language, { Names } from "../utils/Language";

function getCart(): Product[] {
  let cartJSON = window.localStorage.getItem("cart");
  if (!cartJSON) {
    let cart = [];
    cartJSON = JSON.stringify(cart);
    window.localStorage.setItem("cart", cartJSON);
    EventSystem.publish(EventSystem.events.cartUpdated, cart);
    return cart;
  }

  return JSON.parse(cartJSON);
}

// function getOffers(orderProducts) {
//     if (!orderProducts) return [];
//
//     let offers = [];
//     let tempOffers = [];
//
//     orderProducts.sort((o1, o2) => o1.offerID - o2.offerID);
//     for (let op of orderProducts) {
//         if (op.offerID === -1) continue;
//         if (tempOffers.length > 0 && tempOffers[0].offerID === op.offerID) {
//             tempOffers.push(op);
//         } else {
//             if (tempOffers.length > 0) offers.push(tempOffers);
//             tempOffers = [];
//             tempOffers.push(op);
//         }
//     }
//     if (tempOffers.length > 0) offers.push(tempOffers);
//
//     return offers;
// }

// function getMenus(orderProducts) {
//     if (!orderProducts) return [];
//
//     let menus = [];
//     let tempMenu = [];
//
//     orderProducts.sort((o1, o2) => o1.menuID - o2.menuID);
//     for (let op of orderProducts) {
//         if (op.menuID === -1) continue;
//         if (tempMenu.length > 0 && tempMenu[0].menuID === op.menuID) {
//             tempMenu.push(op);
//         } else {
//             if (tempMenu.length > 0) menus.push(tempMenu);
//             tempMenu = [];
//             tempMenu.push(op);
//         }
//     }
//     if (tempMenu.length > 0) menus.push(tempMenu);
//
//     return menus;
// }

function isSameOrderProduct(leadOP1: OrderProduct, leadOP2: OrderProduct) {
  if (!leadOP1 || !leadOP2)
    return false;

  if (leadOP1.versionID !== leadOP2.versionID || leadOP1.id !== leadOP2.id || leadOP1.menuID !== leadOP2.menuID) return false;

  for (let e1 of leadOP1.extras) {
    let hasMatchExtra = false;
    for (let e2 of leadOP2.extras) {
      if (e1.id === e2.id && e1.qty === e2.qty && e1.extraGroupID === e2.extraGroupID) {
        hasMatchExtra = true;
        break;
      }
    }
    //nem találtuk meg a p1 termék e1 extráját a p2 extráiban, szóval eltér a kettő
    if (!hasMatchExtra)
      return false;
  }
  return true;
}

function isSameOrderMenu(menu1: Product, menu2: Product) {
  if (!menu1 || !menu2 || !menu1.type || !menu2.type || menu1.type !== ProductTypes.MENU || menu2.type !== ProductTypes.MENU) return false;

  let leadOP1 = Cart.getLeadOrderProduct(menu1.orderProduct);
  let leadOP2 = Cart.getLeadOrderProduct(menu2.orderProduct);

  if (leadOP1.versionID !== leadOP2.versionID)
    return false;

  //  !!! dont check ID !!!
  if (menu1.products.length !== menu2.products.length)
    return false;

  //lényeg: lecsekkoljuk, hogy ugyanazok a termékek és mennyiségek vannak a menüben
  //illetve, ha a menü összerakós volt, akkor még azt, hogy az extrák is ugyanazok a terméken belül
  // - összerakós, ha menu-nek az id-ja negatív (<= -1).
  // - ha nem összerakós, akkor nem kell nézni az extrákat termék szinten,
  //    elég menü szinten (következő blokk csekkolja)
  for (let p1 of menu1.products) {
    let hasMatch = false;
    A: for (let p2 of menu2.products) {
      let subLeadOP1 = Cart.getLeadOrderProduct(p1.orderProduct);
      let subLeadOP2 = Cart.getLeadOrderProduct(p2.orderProduct);
      if (p1.id === p2.id && subLeadOP1.qty === subLeadOP2.qty && subLeadOP1.versionID === subLeadOP2.versionID) {
        //kombinált menü, meg kell nézni az extrákat, hogy stimmelnek-e
        if (menu1.id < -1) {
          //nincsenek extrák, szóval azok "azonosak"
          if (subLeadOP1.extras.length !== subLeadOP2.extras.length)
            continue;

          //check extras on each product (if any)
          for (let e1 of subLeadOP1.extras) {
            let hasMatchExtra = false;
            for (let e2 of subLeadOP2.extras) {
              if (e1.id === e2.id && e1.qty === e2.qty && e1.extraGroupID === e2.extraGroupID) {
                hasMatchExtra = true;
                break;
              }
            }
            //nem találtuk meg a p1 termék e1 extráját a p2 extráiban, szóval eltér a kettő
            if (!hasMatchExtra)
              continue A;
          }
        }
        hasMatch = true;
        break;
      }
    }
    //nem találtuk meg a menu1 p1 termékét a menu2 termékeiben, szóval eltér a kettő
    if (!hasMatch)
      return false;
  }

  //products and qtyies are equal, now checking extras (if any)
  //menu1.id > 0, mert csak az összerakósnál lehet a terméknek is feltéte, míg a "kész" menünél csak a menünek lehet
  // feltéte (összességben)
  if (menu1.id > 0) {
    if (leadOP1.extras.length === leadOP2.extras.length && leadOP1.extras.length === 0)
      return true;

    if (leadOP1.extras.length === leadOP2.extras.length) {
      for (let e1 of leadOP1.extras) {
        let hasMatch = false;
        for (let e2 of leadOP2.extras) {
          if (e1.id === e2.id && e1.qty === e2.qty) {
            hasMatch = true;
            break;
          }
        }
        if (!hasMatch)
          return false;
      }
    } else {
      return false;
    }
  } else if (menu1.id < -1) {
    //kombinált menü, nem kell csekkolni a menü extráit (úgyis üres)
    return true;
  }
  return true;
}

function addMenu(menu: Product) {
  ContextSystem.addSomethingToCart(menu, (proceed) => {
    if (!proceed)
      return;

    let cart = getCart();
    let found = false;
    let tooMany = false;
    let leadOP = Cart.getLeadOrderProduct(menu.orderProduct);
    for (let cartElement of cart) {
      let cartElementLeadOP = Cart.getLeadOrderProduct(cartElement.orderProduct);
      if (cartElement.type && cartElement.type === ProductTypes.MENU && isSameOrderMenu(cartElement, menu)) {
        found = true;
        if (cartElementLeadOP.qty < 50) {
          cartElementLeadOP.qty += leadOP.qty;
        } else {
          tooMany = true;
        }
        break;
      }
    }
    if (!found)
      cart.push(menu);
    window.localStorage.setItem("cart", JSON.stringify(cart));
    EventSystem.publish(EventSystem.events.cartUpdated, cart);
    if (!tooMany) {
      //Toast.showToast("Kosárba");
    } else {
      Toast.showToast(Language.getName(Names.Max50Cart));
    }
  });
}

function addProduct(product: Product) {
  ContextSystem.addSomethingToCart(product, (proceed) => {
    if (!proceed)
      return;

    let cart = getCart();
    let leadOP = Cart.getLeadOrderProduct(product.orderProduct);
    let found = false;
    let tooMany = false;
    for (let opCart of cart) {
      if (opCart.type && opCart.type === ProductTypes.PRODUCT) {
        let cartLeadOP = Cart.getLeadOrderProduct(opCart.orderProduct);
        if (CartStorage.isSameOrderProduct(leadOP, cartLeadOP)) {
          found = true;
          if (cartLeadOP.qty < 50) {
            cartLeadOP.qty += leadOP.qty;
          } else {
            tooMany = true;
          }
          break;
        }
      }
    }
    if (!found)
      cart.push(product);
    window.localStorage.setItem("cart", JSON.stringify(cart));
    EventSystem.publish(EventSystem.events.cartUpdated, cart);
    if (!tooMany) {
      //Toast.showToast("Kosárba");
    } else {
      Toast.showToast(Language.getName(Names.Max50Cart));
    }
  });
}

/**
 * Remove combined or simple menu from the cart.
 * With this function you can not remove products.
 * @param menu
 */
function removeMenu(menu) {
  let cartJSON = window.localStorage.getItem("cart");
  let cart;
  if (!cartJSON) {
    cart = [];
    window.localStorage.setItem("cart", JSON.stringify(cart));
  } else {
    cart = JSON.parse(cartJSON);
  }

  let cartNew = [];

  for (let cartElement of cart) {
    if (!isSameOrderMenu(cartElement, menu)) cartNew.push(cartElement);
  }

  window.localStorage.setItem("cart", JSON.stringify(cartNew));
  EventSystem.publish(EventSystem.events.cartUpdated, cartNew);
}

/**
 * Remove product from the cart. Either a simple product or from a combined menu.
 * With this function you can not remove menus.
 * @param product
 */
function removeProduct(product) {
  if (!product) return;

  let cart = getCart();
  let cartNew = [];

  let leadOP = Cart.getLeadOrderProduct(product.orderProduct);

  let addProduct = null;

  for (let cartElement of cart) {
    let filtered = false;
    let cartLeadOP = Cart.getLeadOrderProduct(cartElement.orderProduct);

    if (cartElement.type === ProductTypes.PRODUCT) {
      //cartelement is a simple product
      if (CartStorage.isSameOrderProduct(cartLeadOP, leadOP)) filtered = true;
    } else if (cartElement.type === ProductTypes.MENU && cartElement.id < -1) {
      //cartelement is a combined menu
      let menuProducts = [];
      for (let cartSubProduct of cartElement.products) {
        let cartSubLeadOP = Cart.getLeadOrderProduct(cartSubProduct.orderProduct);

        let filteredInMenu = false;
        if (CartStorage.isSameOrderProduct(cartSubLeadOP, leadOP)) {
          filteredInMenu = true;
        }
        if (!filteredInMenu) menuProducts.push(cartSubProduct);
      }

      if (menuProducts.length <= 0) filtered = true;
      else if (menuProducts.length === 1) {
        filtered = true;
        cartElement = menuProducts[0];
        addProduct = cartElement;
        let addProductLeadOP = Cart.getLeadOrderProduct(addProduct.orderProduct);
        addProductLeadOP.menuID = -1;
      } else {
        cartElement.products = menuProducts;
        let menuTotal = 0;
        for (let cartSubProduct of cartElement.products) {
          let cartSubLeadOP = Cart.getLeadOrderProduct(cartSubProduct.orderProduct);
          menuTotal += cartSubLeadOP.qty * (cartSubLeadOP.price + MenuCombiner.getOrderProductExtrasPrice(cartSubLeadOP, cartSubProduct));
        }
        cartLeadOP.price = menuTotal;
      }
    }

    if (!filtered) cartNew.push(cartElement);
  }

  window.localStorage.setItem("cart", JSON.stringify(cartNew));
  EventSystem.publish(EventSystem.events.cartUpdated, cartNew);

  if (addProduct)
    CartStorage.addProduct(addProduct);
}

// function getProductQty(productID) {
//     let cartJSON = window.localStorage.getItem("cart");
//     if (!cartJSON) {
//         let cart = [];
//         window.localStorage.setItem("cart", JSON.stringify(cart));
//         return cart;
//     }
//     let cart = JSON.parse(cartJSON);
//
//     for (let cartContent of cart) {
//         if (cartContent.productID === productID) {
//             return cartContent.qty;
//         }
//     }
//     return -1;
// }

function clearCart() {
  window.localStorage.removeItem("cart");
  EventSystem.publish(EventSystem.events.cartCleared, {});
}

function getNextMenuID() {
  let cartJSON = window.localStorage.getItem("cart");
  let cart;
  if (!cartJSON) {
    cart = [];
    window.localStorage.setItem("cart", JSON.stringify(cart));
  } else {
    cart = JSON.parse(cartJSON);
  }

  let menuID = -3;
  for (let cartElement of cart) {
    if (cartElement.type && cartElement.type === ProductTypes.MENU && menuID > cartElement.id) menuID = cartElement.id;
  }
  return menuID - 1;
}

function addProductQuantity(product) {
  ContextSystem.addSomethingToCart(product, (proceed) => {
    if (!proceed)
      return;

    let cart = getCart();
    let leadOP = Cart.getLeadOrderProduct(product.orderProduct);
    let tooMany = false;
    for (let opCart of cart) {
      if (opCart.type && opCart.type === ProductTypes.PRODUCT) {
        let cartLeadOP = Cart.getLeadOrderProduct(opCart.orderProduct);
        if (CartStorage.isSameOrderProduct(leadOP, cartLeadOP)) {
          if (cartLeadOP.qty < 50) cartLeadOP.qty += 1;
          else tooMany = true;
          break;
        }
      }
    }
    window.localStorage.setItem("cart", JSON.stringify(cart));
    EventSystem.publish(EventSystem.events.cartUpdated, cart);
    if (tooMany)
      Toast.showToast(Language.getName(Names.Max50Cart));
  });
}

function removeProductQuantity(product) {
  ContextSystem.addSomethingToCart(product, (proceed) => {
    if (!proceed) return;

    let cart = getCart();
    let leadOP = Cart.getLeadOrderProduct(product.orderProduct);
    for (let opCart of cart) {
      if (opCart.type && opCart.type === ProductTypes.PRODUCT) {
        let cartLeadOP = Cart.getLeadOrderProduct(opCart.orderProduct);
        if (CartStorage.isSameOrderProduct(leadOP, cartLeadOP) && cartLeadOP.qty > 1) {
          cartLeadOP.qty -= 1;
          break;
        }
      }
    }
    window.localStorage.setItem("cart", JSON.stringify(cart));
    EventSystem.publish(EventSystem.events.cartUpdated, cart);
  });
}

function addMenuQuantity(menu) {
  ContextSystem.addSomethingToCart(menu, (proceed) => {
    if (!proceed) return;

    let cart = getCart();
    let tooMany = false;
    for (let cartElement of cart) {
      let cartElementLeadOP = Cart.getLeadOrderProduct(cartElement.orderProduct);
      if (cartElement.type && cartElement.type === ProductTypes.MENU && isSameOrderMenu(cartElement, menu)) {
        if (cartElementLeadOP.qty < 50) cartElementLeadOP.qty += 1;
        else tooMany = true;
        break;
      }
    }
    window.localStorage.setItem("cart", JSON.stringify(cart));
    EventSystem.publish(EventSystem.events.cartUpdated, cart);
    if (tooMany)
      Toast.showToast(Language.getName(Names.Max50Cart));
  });
}

function removeMenuQuantity(menu) {
  ContextSystem.addSomethingToCart(menu, (proceed) => {
    if (!proceed) return;

    let cart = getCart();
    for (let cartElement of cart) {
      let cartElementLeadOP = Cart.getLeadOrderProduct(cartElement.orderProduct);
      if (cartElement.type && cartElement.type === ProductTypes.MENU && isSameOrderMenu(cartElement, menu) && cartElementLeadOP.qty > 1) {
        cartElementLeadOP.qty -= 1;
        break;
      }
    }
    window.localStorage.setItem("cart", JSON.stringify(cart));
    EventSystem.publish(EventSystem.events.cartUpdated, cart);
  });
}

export const CartStorage = {
  getCart,
  addProduct,
  addMenu,
  removeProduct,
  removeMenu,
  clearCart,
  getNextMenuID,
  isSameOrderProduct,
  // getProductQty,
  // getMenus,
  // getOffers,
  addProductQuantity,
  removeProductQuantity,
  addMenuQuantity,
  removeMenuQuantity
};

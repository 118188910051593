import React, {Component} from "react";
import styled, {css} from "styled-components";
import {FiSearch} from "react-icons/fi";
import {FaTimes} from "react-icons/fa";
import {Input} from "../../components/Form";
import ContextSystem from "../../utils/ContextSystem";
import SearchAPI from "../../utils/api/SearchAPI";
import Config from "../../utils/Config";
import EventSystem from "../../utils/EventSystem";
import {withRouter} from "react-router-dom";
import {Helmet} from "react-helmet/es/Helmet";
import ProductComponent from "../../components/ProductComponent";
import ShopComponent from "../../components/ShopComponent";
import MultiSelect from "react-multi-select-component";
import SavedAddressPicker from "../../components/addresspicker/SavedAddressPicker";
import {BsExclamationCircle, BsFilterLeft} from "react-icons/bs";
import {GoLocation} from "react-icons/go";
import {IoIosArrowBack} from "react-icons/io";
import {AddressAPI} from "../../utils/api/AddressAPI";
import InputRangeOrg from "react-input-range";
import "react-input-range/lib/css/index.css";
import Loader from "react-loader-spinner";
import NotThereYet from "../../components/NotThereYet";
import * as HoursCalc from "../../utils/HoursCalc";
import {Address, City} from "../../model/Address";
import {DynamicOffer} from "../../model/nonpersistent/DynamicOffer";
import {HourStatuses, HourTypes} from "../../model/Hour";
import Language, {Names} from "../../utils/Language";
import {Category, GlobalCategory, Product, TranslatableString} from "../../model/Product";
import type {Shop} from "../../model/Shop";
import {LoaderWrapper as LoaderWrapperOrg} from "../StartPage";

const LoaderWrapper = styled(LoaderWrapperOrg)`
  height: 40vh;
  width: 58%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 800px) {
    width: 100%;
    height: 100%;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 15px 1% 24px 1%;
  box-sizing: border-box;
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  & > h1 {
    font-family: var(--blundee_font_bold);
    //letter-spacing: 0.3rem;;
    font-size: 30pt;
    font-weight: 700;

    color: var(--blundee_color_normal);
    padding-right: 15px;
    border-right: 1px solid var(--blundee_color_thin);
  }

  & > h2 {
    font-family: var(--blundee_font_medium);
    //letter-spacing: 0.28rem;
    font-size: 17pt;
    font-weight: 500;

    margin-left: 15px;
    color: var(--blundee_color_normal);
  }

  @media screen and (max-width: 600px) {
    justify-content: center;
    & > h1 {
      font-size: 25pt;
      border: none;
      padding: 0;
      margin: 12px 0 24px 0;
    }

    & > h2 {
      margin-left: 15px;
      font-size: 13.5pt;
      display: none;
    }
  }
`;

const LabelsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 8px;

  @media screen and (min-width: 801px) {
    display: none;
  }

  ${({hide}) => hide === true && css`
    width: 0;
    height: 0;
    overflow: hidden;
  `}
`;

const Label = styled.div`
  height: 25px;
  border-radius: 20px;
  padding: 5px 10px;
  background-color: var(--blundee_button_bg_color);
  color: var(--blundee_color_normal);
  font-family: var(--blundee_font_bold);
  box-shadow: var(--blundee_button_shadow);
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
  font-size: 12pt;
  margin: 3px;

  & > svg {
    font-size: 16pt;
    font-weight: bold;
    margin-right: 5px;
    transition: transform 100ms ease-in-out, color 100ms ease-in-out;

    &:hover {
      cursor: pointer;
      transform: scale(1.025);
      color: white;
    }

    &:active {
      cursor: pointer;
      transform: scale(0.9);
      color: rgb(140, 140, 140);
    }
  }
`;

const InputWrapper = styled.div`
  height: calc(36px + 12px);
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: sticky;
  top: ${({statusBarSpaceHeight}) => Config.mobile ? statusBarSpaceHeight : 0}px;
  padding-top: 12px;
  z-index: 5;
`;

const InputIcon = styled.button`
  border: none;
  border-radius: 0 30px 30px 0;
  height: 100%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: var(--blundee_button_bg_color);
  font-size: 12.5pt;
  color: var(--blundee_color_normal);
  font-family: var(--blundee_font_bold);
  box-shadow: var(--blundee_button_shadow);

  transition: filter 100ms ease-in-out, color 100ms ease-in-out, font-size 100ms ease-in-out;

  &:hover, &:active, &:focus {
    outline: none;
    cursor: default;
  }

  cursor: pointer;

  ${({interactive}) => interactive === true && css`
    &:hover {
      font-size: 14.5pt;
      filter: brightness(95%);
    }

    &:active {
      font-size: 11.5pt;
      filter: brightness(98%);
    }
  `};
`;

const SearchInput = styled(Input)`
  height: 100%;
  width: 400px;
  font-size: 12pt;
  font-family: var(--blundee_font_medium);
  background-color: var(--blundee_background);
  margin: 0;
  padding: 0 15px;
  border: none;
  border-radius: 30px 0 0 30px;
  color: var(--blundee_color_normal);
  box-shadow: var(--blundee_button_shadow);

  transition: width 330ms ease-in-out,
  font-size 300ms ease-in-out,
  background-color 300ms ease-in-out;

  &:focus {
    background-color: var(--blundee_background);
    box-shadow: var(--blundee_button_shadow);
    border: none;
    width: 550px;
    font-size: 14.5pt;
    outline: none;
  }

  ${({active}) => active === true && css`
    border: none;
    box-shadow: var(--blundee_button_shadow);
    width: 450px;
    font-size: 13.5pt;
  `};

  @media screen and (max-width: 600px) {
    width: 220px;
    &:focus {
      width: 98%;
    }
  }

  &::placeholder {
    color: var(--blundee_color_thin);
    font-size: 10pt;
  }
`;

const ResultsContainer = styled.div`
  width: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const TabsWrapper = styled.div`
  height: 45px;
  width: 65%;
  min-width: 600px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  background-color: var(--blundee_background_light);
  box-shadow: var(--blundee_button_shadow);
  border-radius: 150px;
  overflow: hidden;
  position: sticky;
  z-index: 1;
  top: ${({statusBarSpaceHeight}) => (statusBarSpaceHeight ?? 0) + 70}px;

  @media screen and (max-width: 800px) {
    min-width: auto;
    height: 40px;
  }
`;

const Tab = styled.div`
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;

  color: var(--blundee_color);
  font-family: var(--blundee_font_medium);
  font-size: 13pt;
  user-select: none;

  transition: filter 100ms ease-in-out, background-color 100ms ease-in-out, color 100ms ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: var(--blundee_background_card_2);
    filter: brightness(95%);
  }

  ${({selected}) => selected === false && css`
    &:hover {
      filter: brightness(90%);
    }
  `};

  ${({selected}) => selected === true && css`
    background-color: var(--blundee_background_card_2);
    font-family: var(--blundee_font_bold);
  `};
`;

const ResultsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-evenly;
  margin-bottom: 20px;
`;

const TooLessFound = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;

  & > svg {
    display: block;
    margin-right: 5px;
    color: #d9650e;
    font-size: 15pt;
    font-weight: 600;
  }

  & > h3 {
    display: block;
  }
`;

const ProductResultList = styled.div`
  width: 58%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @media screen and (max-width: 800px) {
    width: 100%;
  }

  transition: width 200ms ease-in-out, height 200ms ease-in-out;

  ${({show}) => show === false && css`
    width: 0;
    height: 0;
    overflow: hidden;
    @media screen and (max-width: 800px) {
      width: 0;
      height: 0;
      overflow: hidden;
    }
  `}
`;

const PartnersResultList = styled.div`
  width: ${({width}) => (width === undefined ? "66%" : width)};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-bottom: 10px;

  & > hr {
    width: 95%;
    height: 1px;
    background-color: transparent;
    display: block;
    padding: 0;
    margin: 0;
    outline: none;
    border: none;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
  }

  transition: width 200ms ease-in-out, height 200ms ease-in-out;

  ${({show}) => show === false && css`
    width: 0;
    height: 0;
    overflow: hidden;

    @media screen and (max-width: 800px) {
      width: 0;
      height: 0;
      overflow: hidden;
    }
  `}
`;

const FiltersContainer = styled.div`
  width: 39%;
  height: 60vh;
  margin: 0;

  @media screen and (min-width: 801px) {
    position: sticky;
    top: 120px;
    height: auto;
    min-height: calc(100vh - 130px);
  }

  @media screen and (max-width: 800px) {
    position: fixed;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    z-index: 9;
    border-radius: 0;
    box-shadow: none;

    transition: left 400ms ease-in-out;

    padding-bottom: ${({paddingbottom}) => paddingbottom};

    ${({opened}) => opened === true && css`
      left: 0;
    `}
  }
`;

const FiltersPanel = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: var(--blundee_background_light);
  top: 25px;
  box-shadow: var(--blundee_card_shadow);

  transition: top 100ms ease-in-out;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  padding: ${({statusBarSpaceHeight}) => (statusBarSpaceHeight ?? 0) + 10}px 10px;

  @media screen and (max-width: 800px) {
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 0;
    box-shadow: none;
  }
`;

const FiltersTopRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;

  & > h1 {
    font-family: var(--blundee_font_medium);
    font-size: 14pt;
    margin: 10px 0 0 0;
  }
`;

const FilterCloseButton = styled.div`
  padding: 5px;
  margin: 3px 3px 0 0;
  border-radius: 4px;
  position: absolute;
  top: 0;
  right: 0;

  ${({left}) => left === true && css`
    right: unset;
    left: 0;
  `};

  & > svg {
    font-size: 14.5pt;
  }

  @media screen and (min-width: 801px) {
    display: none;
  }
`;

const CategoryFilter = styled.div`
  width: 100%;

  & > h4 {
    margin: 10px 0 5px 0;
  }

  & > form {
    width: 100%;
    padding: 25px 15px;
  }
`;

export const SelectedAddress = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  font-size: 11pt;
  padding: 3px;
  margin: 5px 0;

  & > span {
    ${({italic}) => italic === true && css`
      font-style: italic;
    `};
  }

  & > svg:nth-of-type(1) {
    font-size: 12pt;
    margin-right: 5px;
  }

  & > svg:nth-of-type(2) {
    width: 15px;
    font-size: 11pt;
    margin: 0 10px 0 5px;
    transition: font-size 100ms ease-in-out;

    &:hover, &:active {
      cursor: pointer;
    }

    &:hover {
      color: rgb(250, 250, 250);
      font-size: 12pt;
    }

    &:active {
      color: rgb(200, 200, 200);
      font-size: 10pt;
    }
  }

  & > button {
    user-select: none;
    outline: none;
    border: none;

    margin-left: 5px;
    padding: 6px 8px;
    font-size: 10pt;
    background-color: rgb(120, 120, 120);
    color: rgb(230, 230, 230);
    border-radius: 4px;

    transition: background-color 100ms ease-in-out, color 100ms ease-in-out;
    cursor: pointer;

    &:hover {
      color: white;
      background-color: rgb(90, 90, 90);
    }

    &:active {
      color: rgb(220, 220, 220);
      background-color: rgb(70, 70, 70);
    }
  }
`;

const InputRange = styled(InputRangeOrg)``;

class SearchScreen extends Component {
  searchTimeOut = undefined;
  delaySearch = 350;
  eventIDs = [];

  state: {
    offersRespond: DynamicOffer[],
    categories: Category[],
    topCategories: Category[],
    text: string,
    focused: boolean,
    iconFocused: boolean,
    searching: boolean,
    filterCategories: Category[],
    loadingOffers: boolean,
    loadingResults: boolean,
    tooLessFound: boolean,
    foundProducts: ProductComponent[],
    partnerProfiles: Shop[],
    allPartnerProfiles: Shop[],
    sortedPartnerProfiles: Shop[],
    selectedAddress: Address | null | undefined,
    selectedCity: City | null | undefined,
    selectedTab: number,
    selectedFoodCategories: { label: string, value: number }[],
    openedFiltersContainer: boolean,
    hideCart: boolean,
    categoriesOptions: { label: string, value: number }[],
    priceFilterValue: { min: number, max: number },
    minProductPriceValue: number,
    maxProductPriceValue: number,
    statusBarSpaceHeight: number,
    language: number,
    loading: boolean,
  } = {
    offersRespond: [],
    categories: [],
    topCategories: [],
    text: "",
    focused: false,
    iconFocused: false,
    searching: false,
    filterCategories: [],
    loadingOffers: true,
    loadingResults: true,
    tooLessFound: false,
    foundProducts: [],
    partnerProfiles: [],
    allPartnerProfiles: ContextSystem.shops,
    sortedPartnerProfiles: [],
    selectedAddress: ContextSystem.selectedAddress,
    selectedCity: ContextSystem.selectedCity,
    selectedTab: 0,
    selectedFoodCategories: [],
    openedFiltersContainer: false,
    hideCart: ContextSystem.hideCart,
    categoriesOptions: [],
    priceFilterValue: {min: 0, max: 1000},
    minProductPriceValue: 0,
    maxProductPriceValue: 1000,
    statusBarSpaceHeight: ContextSystem.statusBarSpaceHeight,
    language: ContextSystem.language,
    loading: false
  };

  handleFocused(focused) {
    this.setState({focused});
  }

  handleIconFocused(focused) {
    this.setState({iconFocused: focused});
  }

  handleSearchChange(text: string, redirectURL: boolean = true) {
    this.setState({text});
    if (this.searchTimeOut)
      clearTimeout(this.searchTimeOut);

    this.searchTimeOut = setTimeout(() => {
      this.search(text);
    }, this.delaySearch);

    if (redirectURL) {
      EventSystem.publish(EventSystem.events.redirectIntent, {
        redirectPath: undefined,
        queryParams: {q: text.length > 0 ? text : undefined}
      });
    }
  }

  handleSelectTab(selectedTab, redirectURL: boolean = true) {
    if (redirectURL) {
      EventSystem.publish(EventSystem.events.redirectIntent, {
        redirectPath: undefined,
        queryParams: {tipus: selectedTab}
      });
    }
    this.setState({selectedTab});
  }

  handleIconClicked() {
    if (this.state.text.length <= 0 && this.state.selectedFoodCategories.length <= 0)
      return;

    if (this.state.text.length <= 0 && this.state.selectedFoodCategories.length > 0) {
      this.categoriesSelected([]);
    } else {
      this.handleSearchChange("");
      if (this.state.text === " ")
        this.categoriesSelected([]);
    }
  }

  loadDefaultSearchOffersAndGlobalCategories() {
    this.setState({loadingOffers: true});
    SearchAPI.searchOffers(false, ContextSystem.selectedAddress, ContextSystem.selectedCity, (res) => {
      if (res.error !== 0) {
        this.setState({loadingOffers: false});
        return;
      }

      this.setState({
        topCategories: res.offeredGlobalCategories,
        offersRespond: res.offersRespond
      });

      this.setCategories(res.globalCategories, () => {
        let searchParamsText = this.props.history.location.search === "" ? "" : this.props.history.location.search.substring(1);
        let params = new URLSearchParams(searchParamsText);
        if (params.has("kategoriak")) {
          this.categoriesSelected(this.parseCategoriesFromURL(params.get("kategoriak")));
          this.categoriesParsed = true;
        }
      });
      this.setState({loadingOffers: false});
    });
  }

  search(text: string = this.state.text) {
    if (!text)
      text = "";
    // if (this.state.foundProducts === undefined || this.state.foundProducts.length <= 0) {
    this.setState({loadingResults: true});
    // }
    let address = ContextSystem.selectedAddress;
    let city = ContextSystem.selectedCity;
    let selectedCategories = [];
    for (let selectedFoodCategory of this.state.selectedFoodCategories) {
      selectedCategories.push(selectedFoodCategory.value);
    }

    // if (text.length <= 2) {
    //   this.setState({ loadingResults: false });
    //   return;
    // }

    SearchAPI.search(false, address, city, text, selectedCategories, (res) => {
      let products = res.products;
      let partnerProfiles = res.partnerProfiles;
      let sortedPartnerProfiles = res.sortedPartnerProfiles;
      let tooLessFound = res.tooLessFound;

      this.setState({partnerProfiles, sortedPartnerProfiles, tooLessFound});
      if (products && products.length > 0) {
        let minPrice = -1;
        let maxPrice = -1;
        for (let product of products) {
          if (minPrice === -1 || product.price < minPrice) minPrice = product.price;
          if (maxPrice === -1 || product.price > maxPrice) maxPrice = product.price;
        }
        minPrice = Math.floor(minPrice / 500) * 500;
        maxPrice = Math.ceil(maxPrice / 500) * 500;
        let priceFilterValue = this.state.priceFilterValue;
        if (priceFilterValue.min === this.state.minProductPriceValue)
          priceFilterValue.min = minPrice;
        else if (priceFilterValue.min < minPrice || priceFilterValue.min > maxPrice)
          priceFilterValue.min = minPrice;
        if (priceFilterValue.max === this.state.maxProductPriceValue)
          priceFilterValue.max = maxPrice;
        else if (priceFilterValue.max < minPrice || priceFilterValue.max > maxPrice)
          priceFilterValue.max = maxPrice;

        this.setState({
          minProductPriceValue: minPrice,
          maxProductPriceValue: maxPrice,
          foundProducts: products,
          priceFilterValue
        });
      }
      this.setState({loadingResults: false});
    });
  }

  removeSelectedAddress() {
    if (ContextSystem.selectedNavIndex === 2 || (ContextSystem.selectedCity && ContextSystem.selectedAddress)) {
      EventSystem.publish(EventSystem.events.addressSelected, {address: null});
    } else {
      EventSystem.publish(EventSystem.events.show_confirmation_modal, {
        title: Language.getName(Names.RemoveAddressTitle),
        text: (
          <>
            {Language.getName(Names.RemoveAddressDetailsText)}
          </>
        ),
        yesText: Language.getName(Names.RemoveAddressYesButton),
        noText: Language.getName(Names.Cancel),
        proceedCb: () => {
          EventSystem.publish(EventSystem.events.addressSelected, {address: null});
        }
      });
    }
  }

  removeSelectedCity() {
    if (ContextSystem.selectedNavIndex === 2 || (ContextSystem.selectedCity && ContextSystem.selectedAddress)) {
      EventSystem.publish(EventSystem.events.citySelected, {city: null});
    } else {
      EventSystem.publish(EventSystem.events.show_confirmation_modal, {
        title: Language.getName(Names.RemoveAddressTitle),
        text: (
          <>
            {Language.getName(Names.RemoveAddressDetailsText)}
          </>
        ),
        yesText: Language.getName(Names.RemoveAddressYesButton),
        noText: Language.getName(Names.Cancel),
        proceedCb: () => {
          EventSystem.publish(EventSystem.events.citySelected, {city: null});
        }
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.selectedFoodCategories !== prevState.selectedFoodCategories || this.state.text !== prevState.text) {
      ContextSystem.setEmptySearchScreenEnabled(this.state.text.length > 0 || this.state.selectedFoodCategories.length > 0);
    }
  }

  componentWillUnmount() {
    for (let eventID of this.eventIDs) EventSystem.unsubscribe(eventID);
    this.eventIDs = [];
  }

  setCategories(globalCategories: GlobalCategory[], cb: ()=>{}) {
    if (globalCategories === undefined || globalCategories.length <= 0) return;

    let categoriesOptions: { label: string, value: number }[] = [];
    for (let gc of globalCategories) {
      categoriesOptions.push({label: gc.icon2 + " " + TranslatableString.get(gc.name), value: gc.id});
    }
    this.setState(
      {
        categories: globalCategories,
        categoriesOptions
      },
      () => {
        if (cb)
          cb();
      }
    );
  }

  parseCategoriesFromURL(urlString) {
    let values = urlString.split("-");
    let selectedCategories = [];
    for (let categoriesOption of this.state.categoriesOptions) {
      for (let v of values) {
        if (parseInt(v) === categoriesOption.value) {
          selectedCategories.push({...categoriesOption});
          break;
        }
      }
    }
    return selectedCategories;
  }

  categoriesParsed = false;

  urlChanged() {
    let searchParamsText = this.props.history.location.search === "" ? "" : this.props.history.location.search.substring(1);
    let params = new URLSearchParams(searchParamsText);
    if (params.has("q") && params.get("q") !== this.state.text)
      this.handleSearchChange(params.get("q"), false);
    if (params.has("tipus"))
      this.handleSelectTab(parseInt(params.get("tipus")), false);
    if (!params.has("kategoriak"))
      this.categoriesParsed = true;
  }

  componentDidMount() {
    let whatsc: number = localStorage.getItem("whatsc") === undefined ? 0 : parseInt(localStorage.getItem("whatsc"));
    let whatsrN: number = localStorage.getItem("whatsr") === undefined ? 0 : parseInt(localStorage.getItem("whatsr"));
    let rfrsear2whats: number = localStorage.getItem("rfrsear2whats") === undefined ? 0 : parseInt(localStorage.getItem("rfrsear2whats"));
    if (!Number.isInteger(whatsc))
      whatsc = 0;
    if (!Number.isInteger(whatsrN))
      whatsrN = 0;

    if (whatsc < 3 && whatsrN !== 1 && rfrsear2whats !== 1) {
      EventSystem.publish(EventSystem.events.redirectIntent, {redirectPath: "/"});
      localStorage.setItem("rfrsear2whats", "1");
    }

    this.eventIDs = [];
    this.urlChanged();

    // not needed, loadDefaultSearchOffersAndGlobalCategories loads all available stuff
    // CategoriesAPI.getFavouriteCategories(true, (res) => {
    //   this.setState({ topCategories: res.globalCategories });
    // });

    this.loadDefaultSearchOffersAndGlobalCategories();

    this.search();

    let eid = EventSystem.subscribe(EventSystem.events.addressSelected, ({address}) => {
      if (address !== this.state.selectedAddress) {
        this.setState({selectedAddress: address});
        if (this.state.selectedCity || (address !== undefined && address !== null))
          this.search(this.state.text);
      }
    });
    this.eventIDs.push(eid);
    eid = EventSystem.subscribe(EventSystem.events.citySelected, ({city}) => {
      this.setState({selectedCity: city});
      if (!this.state.selectedAddress && this.state.selectedCity !== city) {
        if (city !== undefined && city !== null)
          this.search(this.state.text);
      }
    });
    this.eventIDs.push(eid);
    eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged,
      ({
         /*globalCategories,*/ hideCart, selectedAddress, selectedCity, statusBarSpaceHeight,
         language, shops,
       }) => {
        if (shops !== undefined)
          this.setState({allPartnerProfiles: shops});

        if (hideCart !== undefined)
          if (this.state.hideCart !== hideCart) this.setState({hideCart});

        if (selectedAddress !== undefined)
          this.setState({selectedAddress});

        if (selectedCity !== undefined)
          this.setState({selectedCity});

        if (statusBarSpaceHeight !== undefined)
          this.setState({statusBarSpaceHeight});

        if (language !== undefined)
          this.setState({language});

        // if (globalCategories !== undefined && globalCategories !== this.state.categories) {
        //   this.setCategories(globalCategories, () => {
        //     let searchParamsText = this.props.history.location.search === "" ? "" : this.props.history.location.search.substring(1);
        //     let params = new URLSearchParams(searchParamsText);
        //
        //     if (!this.categoriesParsed && params.has("kategoriak")) {
        //       this.categoriesSelected(this.parseCategoriesFromURL(params.get("kategoriak")));
        //       this.categoriesParsed = true;
        //     }
        //   });
        // }
      }
    );
    this.eventIDs.push(eid);

    eid = EventSystem.subscribe(EventSystem.events.selectGlobalCategory, ({globalCategory}) => {
      this.handleCategoryClicked(globalCategory);
    });
    this.eventIDs.push(eid);

    eid = EventSystem.subscribe(EventSystem.events.urlChanged, () => this.urlChanged());
    this.eventIDs.push(eid);
  }

  getPartner(partnerID) {
    if (!this.state.partnerProfiles) return null;

    for (let p of this.state.partnerProfiles) {
      if (p.id === partnerID) return p;
    }
    return null;
  }

  handleCategoryClicked(category) {
    let selectedFoodCategories = [];
    for (let categoriesOption of this.state.categoriesOptions) {
      if (categoriesOption.value === category.id) {
        selectedFoodCategories.push(categoriesOption);
        break;
      }
    }

    if (selectedFoodCategories.length <= 0)
      return;

    EventSystem.publish(EventSystem.events.redirectIntent, {
      redirectPath: undefined,
      queryParams: {kategoriak: "" + selectedFoodCategories[0].value}
    });

    // let text = this.state.text;
    // if (text.length <= 0)
    // text = " ";

    this.setState({selectedFoodCategories}, () => {
      this.search(this.state.text);
    });
  }

  openSelectAddressPopup() {
    EventSystem.publish(EventSystem.events.open_address_picker_popup, {
      address: null,
      cb: (selectedAddress) => {
        AddressAPI.saveAddress(selectedAddress, (res) => {
          EventSystem.publish(EventSystem.events.addressSelected, {address: res.address});
        });
      }
    });
  }

  closeFiltersPane() {
    ContextSystem.setSearchScreenFiltersOpened(false);
    this.setState({openedFiltersContainer: false});
  }

  categoriesSelected(s) {
    EventSystem.publish(EventSystem.events.redirectIntent, {
      redirectPath: undefined,
      queryParams: {kategoriak: s.map((s) => s.value).join("-")}
    });
    this.setState({selectedFoodCategories: s}, () => {
      setTimeout(() => this.search(this.state.text), 0);
    });
  }

  isEmptySearchScreen() {
    return this.state.text.length <= 0 && this.state.selectedFoodCategories.length <= 0;
  }

  openFilters() {
    ContextSystem.setSearchScreenFiltersOpened(true);
    this.setState({openedFiltersContainer: true});
  }

  render() {
    return (
      <Wrapper>
        <Helmet>
          <title>{Language.getName(Names.SearchScreenTitle)}</title>
          <meta name="description" content={Language.getName(Names.SearchScreenTitle)}/>
        </Helmet>
        <TitleWrapper>
          <h1>{Language.getName(Names.MenuSearch)}</h1>
          <h2>{Language.getName(Names.FindFoodOrRestaurants)}</h2>
        </TitleWrapper>
        <InputWrapper
          statusBarSpaceHeight={this.state.statusBarSpaceHeight}
        >
          <SearchInput
            value={this.state.text}
            placeholder={Language.getName(Names.FindFoodOrRestaurantsPlaceholder)}
            active={this.state.focused || (this.state.text.length > 0 && this.state.iconFocused)}
            onChange={(e) => this.handleSearchChange(e.target.value)}
            onFocus={() => this.handleFocused(true)}
            onBlur={() => this.handleFocused(false)}
          />
          <InputIcon
            interactive={this.state.text.length > 0 || this.state.selectedFoodCategories.length > 0}
            onFocus={() => this.handleIconFocused(true)}
            onBlur={() => this.handleIconFocused(false)}
            onClick={() => this.handleIconClicked()}
          >
            {this.state.text.length <= 0 && this.state.selectedFoodCategories.length <= 0 && <FiSearch/>}
            {(this.state.text.length > 0 || this.state.selectedFoodCategories.length > 0) && <FaTimes/>}
          </InputIcon>
        </InputWrapper>
        {this.isEmptySearchScreen() &&
          <PartnersResultList show={true}>
            {this.state.allPartnerProfiles?.map((partnerProfile, i) => {
              if (partnerProfile.list)
                return (
                  <ShopComponent
                    key={i}
                    onClick={() => handlePartnerProfileClicked(partnerProfile)}
                    partnerProfile={partnerProfile}
                    with_margin={true}
                  />
                );
              else
                return <React.Fragment key={i}/>;
            })}
          </PartnersResultList>
        }
        <LabelsWrapper hide={this.isEmptySearchScreen()}>
          <Label onClick={() => this.openFilters()}>
            <BsFilterLeft/>
            <span>{Language.getName(Names.Filters)}</span>
          </Label>
          {this.state.selectedFoodCategories.map((c, i) => (
            <Label key={i}>
              <span>{c.label}</span>
            </Label>
          ))}
        </LabelsWrapper>
        {!this.isEmptySearchScreen() && (
          <ResultsContainer>
            <TabsWrapper statusBarSpaceHeight={this.state.statusBarSpaceHeight}>
              <Tab selected={this.state.selectedTab === 0} onClick={() => this.handleSelectTab(0)}>
                <span>{Language.getName(Names.Food)}</span>
              </Tab>
              <Tab selected={this.state.selectedTab === 1} onClick={() => this.handleSelectTab(1)}>
                <span>{Language.getName(Names.Restaurant)}</span>
              </Tab>
            </TabsWrapper>
            <ResultsWrapper>
              {this.state.loadingResults === true && (
                <LoaderWrapper show={true}>
                  <Loader visible={this.state.loading} type="ThreeDots" color="rgb(80,80,80)" height={20} width={100}/>
                </LoaderWrapper>
              )}
              {this.state.loadingResults === false && (
                <>
                  <ProductResultList show={this.state.selectedTab === 0}>
                    {(!this.state.foundProducts || this.state.foundProducts.length <= 0) && <NotThereYet/>}
                    {this.state.tooLessFound === true &&
                      <TooLessFound>
                        <BsExclamationCircle/>
                        <h3>
                          {Language.getName(Names.NothingBasedOnFiltersDescription)}
                        </h3>
                      </TooLessFound>
                    }
                    {this.state.foundProducts?.map((product, i) => {
                      if (product.price + 100 >= this.state.priceFilterValue.min && product.price - 100 <= this.state.priceFilterValue.max) {
                        let partnerProfile = this.getPartner(product.partnerID);
                        // noinspection RequiredAttributes
                        return (
                          <ProductComponent
                            key={i}
                            product={product}
                            partnerProfile={partnerProfile}
                            handleClick={() => handleProductClicked(product, partnerProfile)}
                          />
                        );
                      } else {
                        return <React.Fragment key={i}/>;
                      }
                    })}
                  </ProductResultList>
                  <PartnersResultList show={this.state.selectedTab === 1}>
                    {(!this.state.sortedPartnerProfiles || this.state.sortedPartnerProfiles.length <= 0) &&
                      <NotThereYet/>
                    }
                    {this.state.tooLessFound === true &&
                      <h3>
                        <BsExclamationCircle style={{marginRight: "4px"}}/>
                        {Language.getName(Names.NothingBasedOnFiltersDescription)}
                      </h3>
                    }
                    <hr/>
                    {this.state.sortedPartnerProfiles?.map((partnerProfile, i) => {
                      if (partnerProfile.list)
                        return (
                          <ShopComponent
                            key={i}
                            onClick={() => handlePartnerProfileClicked(partnerProfile)}
                            partnerProfile={partnerProfile}
                            with_margin={true}
                          />
                        );
                      else
                        return <React.Fragment key={i}/>;
                    })}
                  </PartnersResultList>
                </>
              )}
              <FiltersContainer
                opened={this.state.openedFiltersContainer}
                paddingbottom={this.state.hideCart ? ContextSystem.mobileNavbarHeight + "px" : ContextSystem.mobileCartHeight + 60 + "px"}
                onClick={() => this.closeFiltersPane()}
              >
                <FiltersPanel
                  statusBarSpaceHeight={this.state.statusBarSpaceHeight}
                  onClick={(e) => e.stopPropagation()}
                >
                  <FiltersTopRow>
                    <FilterCloseButton left onClick={() => this.closeFiltersPane()}>
                      <IoIosArrowBack/>
                    </FilterCloseButton>
                    <h1>{Language.getName(Names.Filters)}</h1>
                    <FilterCloseButton onClick={() => this.closeFiltersPane()}>
                      <FaTimes/>
                    </FilterCloseButton>
                  </FiltersTopRow>
                  <CategoryFilter>
                    <h4>{Language.getName(Names.Categories)}</h4>
                    <MultiSelect
                      options={this.state.categoriesOptions}
                      value={this.state.selectedFoodCategories}
                      onChange={(s) => this.categoriesSelected(s)}
                      labelledBy={"Select"}
                      disableSearch
                      styles={{
                        menu: provided => ({...provided, zIndex: 9999})
                      }}
                      overrideStrings={{
                        selectSomeItems: Language.getName(Names.FilterCategoriesPlaceHolder),
                        allItemsAreSelected: Language.getName(Names.AllSelected),
                        selectAll: Language.getName(Names.All),
                        search: Language.getName(Names.Search),
                        clearSearch: Language.getName(Names.Clear)
                      }}
                      hasSelectAll={false}
                    />
                    <h4>{Language.getName(Names.SelectedAddress)}</h4>
                    {!this.state.selectedAddress && !this.state.selectedCity && (
                      <SelectedAddress italic={true}>
                        <span>{Language.getName(Names.NoAddressSelected)}</span>
                      </SelectedAddress>
                    )}
                    {this.state.selectedAddress && (
                      <SelectedAddress>
                        <GoLocation/>
                        <span>{this.state.selectedAddress.street}</span>
                        <FaTimes onClick={() => this.removeSelectedAddress()}/>
                      </SelectedAddress>
                    )}
                    {this.state.selectedCity && !this.state.selectedAddress && (
                      <SelectedAddress>
                        <GoLocation/>
                        <span>{this.state.selectedCity.name}</span>
                        <FaTimes onClick={() => this.removeSelectedCity()}/>
                        <button
                          onClick={() => this.openSelectAddressPopup()}>{Language.getName(Names.EnterExactAddress)}</button>
                      </SelectedAddress>
                    )}
                    <SavedAddressPicker fontsize={"s"} modifyOnClick={false} showModifyIcons={false}/>
                    <h4>Ár</h4>
                    <form>
                      <InputRange
                        minValue={this.state.minProductPriceValue}
                        maxValue={this.state.maxProductPriceValue}
                        step={100}
                        formatLabel={(value) => `${value}Ft`}
                        value={this.state.priceFilterValue}
                        onChange={(value) => this.setState({priceFilterValue: value})}
                        // onChangeComplete={value => console.log(value)}
                      />
                    </form>
                  </CategoryFilter>
                </FiltersPanel>
              </FiltersContainer>
            </ResultsWrapper>
          </ResultsContainer>
        )}
      </Wrapper>
    );
  }
}

function getOpenStatus(partnerProfile) {
  let OpenStatus = HoursCalc.checkOpeningState(HourTypes.OPENING, partnerProfile);
  let AcceptingPickUpOrdersStatus = HoursCalc.checkOpeningState(HourTypes.ORDER_ACCEPT_FOR_PICKUP, partnerProfile);
  let DeliveringStatus = HoursCalc.checkOpeningState(HourTypes.DELIVERY, partnerProfile);
  let AcceptingOrdersStatus = HoursCalc.checkOpeningState(HourTypes.ORDER_ACCEPT, partnerProfile);
  let openState = HoursCalc.getOpeningHoursText(OpenStatus, AcceptingPickUpOrdersStatus, DeliveringStatus, AcceptingOrdersStatus);

  let openingStatus = {closed: false, preOrder: false};

  if (openState.openStringDL === HourStatuses.NO_DATA) {
    openingStatus.closed = true;
  } else if (openState.openStringDL === HourStatuses.LOADING) {
    openingStatus.closed = false;
    openingStatus.preOrder = false;
  } else if (openState.openStringDL.includes(Language.getName(Names.Closed))) {
    openingStatus.closed = true;
  } else if (openState.openStringDL.includes(Language.getName(Names.ForPreOrder))) {
    openingStatus.preOrder = true;
  }

  return openingStatus;
}

export function sortRestaurantsAndFoodsByOpenStatus(x, y) {
  let xOpenStatus = getOpenStatus(x);
  let yOpenStatus = getOpenStatus(y);
  if (xOpenStatus.closed === yOpenStatus.closed && xOpenStatus.preOrder === yOpenStatus.preOrder) {
    return 0;
  } else if (!xOpenStatus.closed && !xOpenStatus.preOrder) {
    return -1;
  } else if (!yOpenStatus.closed && !yOpenStatus.preOrder) {
    return 1;
  } else if (xOpenStatus.preOrder) {
    return -1;
  } else if (yOpenStatus.preOrder) {
    return 1;
  }

  return 0;
}

export function handleProductClicked(product: Product, partnerProfile: ShopComponent) {
  EventSystem.publish(EventSystem.events.redirectToProduct, {productID: product.id, url: partnerProfile.url});
}

export function handlePartnerProfileClicked(partnerProfile: ShopComponent) {
  if (!partnerProfile)
    return;
  ContextSystem.manualShopRedirect = true;
  EventSystem.publish(EventSystem.events.redirectIntent, {redirectPath: "/etterem/" + partnerProfile.url});
}

export default withRouter(SearchScreen);


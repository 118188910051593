export default {
  OK: 0,

  WRONG_EMAIL_OR_PASS: 1001,
  PERMISSION_DENIED: 1002,
  PARAMETER_NOT_VALID: 1003,
  DATA_NOT_FOUND: 1004,
  AMBIGUOUS: 1005,
  UNEXPECTED_ERROR: 1006,
  EMAIL_NOT_VALID: 1007,
  BODY_NOT_PARSABLE: 1008,
  TOKEN_NOT_VALID: 1009,
  DATABASE_ERROR: 1010,
  ILLEGAL_STATE: 1011,
  EMAIL_REGISTERED: 1012,
  SHIPPING_PRICE_NOT_FOUND: 1013,
  RESTAURANT_NOT_FOUND: 1014,
  RESTAURANT_CLOSED_FOR_TODAY: 1015,
  RESTAURANT_CLOSED: 1016,
  DOESNT_HIT_MINIMUM_ORDER: 1017,
  PRODUCT_NOT_FOUND: 1018,
  EXTRA_QTY_OVER_MAX: 1019,
  CITY_NOT_FOUND: 1020,
  MIXED_PRODUCTS: 1021,
  ADDRESS_PERMISSION_DENIED: 1022,
  ADDRESS_NOT_FOUND: 1023,
  ZIPCODE_NOT_FOUND: 1024,
  CURRENT_PASSWORD_INVALID: 1025,
  NEW_PASSWORD_TOO_SHORT: 1026,
  NO_SHIPPING_TO_THIS_ADDRESS: 1027,
  ORDER_NOT_FOUND: 1028,
  TOO_FREQUENT_REQUESTS: 1029,
  FIRSTNAME_NOT_VALID: 1030,
  LASTNAME_NOT_VALID: 1031,
  TEL_NOT_VALID: 1032,
  RESTAURANT_NAME_NOT_VALID: 1033,
  CANT_DELETE_DATA_FOUND: 1034,
  MENU_NOT_REMOVABLE: 1035,
  PENDING_OR_VALID_CONTRACT_IS_AVAILABLE: 1036,
  NO_TAKEAWAY_AT_THIS_SHOP: 1040,
  NO_DELIVERY_AT_THIS_SHOP: 1041,
  TABLE_QR_NOT_FOUND: 1044,
  PRODUCT_SCHEDULE_NOT_AVAILABLE: 1045,
  PREORDER_TOO_FAR: 1046,
  PREORDER_MAX_7_DAYS: 1047,
  PAYMENT_METHOD_NOT_VALID: 1048,
  PIN_NOT_VALID: 1049,
  CANCELLATION_ORDER_MIXED: 1050,
  TOO_EARLY_RESEND_REQUEST: 1051,
  NO_TABLE_AVAILABLE: 1052,

  PING: 2000,
  PONG: 2001,
  JOB_OFFER_TIME_UP: 2002,
  JOB_OFFER: 2003,
  //JOB_EXAMPLE_REQUEST: 2004,
  CROSS_EVENT_REGISTER: 2005,
  CROSS_EVENT_REGISTERED: 2006,
  CROSS_EVENT_UNREGISTER: 2007,
  CROSS_EVENT_UNREGISTERED: 2008,
  CROSS_EVENT_PUBLISH: 2009
};

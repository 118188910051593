import React, { Component } from "react";
import ContextSystem from "../../utils/ContextSystem";
import EventSystem from "../../utils/EventSystem";
import { IoIosTimer } from "react-icons/io";
import Language, { Names } from "../../utils/Language";
import { ScheduleIconTimerWrapper, SmallButton } from "../Form";
import styled from "styled-components";
import DatePicker from "react-multi-date-picker";
import { FaTimes } from "react-icons/fa";
import { Select as SelectOrg } from "../../components/about/FormElements";
import type { OpenStatus } from "../../utils/HoursCalc";
import { addLeadingZero, checkOpeningState, getShortDayName } from "../../utils/HoursCalc";
import { HourStatuses, HourTypes } from "../../model/Hour";
import Toast from "../Toast";
import {Shop} from "../../model/Shop";

export const Select = styled(SelectOrg)`
  width: fit-content;
  height: 15px;
  background-color: transparent;
  outline: none;
  border: none;
  margin: 0 3px;
  padding: 2px 3px;
  color: var(--blundee_color_normal);
  font-size: 11pt;

  & > option {
    font-size: 11pt;
    background-color: var(--blundee_background_light);
    color: var(--blundee_color_normal);
  }
`;

export const DatePickerStyled = styled(DatePicker)`
  background-color: #444440;
  color: white;
  box-shadow: 0 0 5px 1px black;
  margin: 10px;

  &.ep-arrow {
    display: none;
  }

  *.rmdp-arrow {
    border-color: #ffef42;
  }

  *.rmdp-arrow-container {
    &:hover {
      background-color: #686860;
    }
  }

  *.rmdp-header-values {
    color: white;
  }

  *.rmdp-day:not(.rmdp-disabled) {
    color: white;
  }

  *.rmdp-week-day {
    color: #ffef42;
  }

  & input {
    background-color: #686868;
    border-radius: 4px;
    color: whitesmoke;
  }
`;

const Wrapper = styled.div`
  min-height: 33px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  & > p {
    margin: 0;
    padding: 0;
    font-size: 10pt;
    flex-grow: 2;
  }

  & > div {
    margin: 0 2px;
  }

  & > svg {
    margin: 0 4px;
    font-size: 11pt;
    cursor: pointer;
    flex-shrink: 0;
  }
`;

export type DataSetRecord = {
  id: number,
  value: Date,
  label: string,
  minutes: Date[],
}

export default class ScheduleDatePicker extends Component {
  eventIDs: number[] = [];
  uniqueID;

  state: {
    language: number,
    scheduleDate: Date,
    shop: Shop,
    dataSet: DataSetRecord[],
    pickup: boolean,
    atPlaceQRID: number,
  } = {
    language: ContextSystem.language,
    scheduleDate: ContextSystem.scheduleDate,
    shop: ContextSystem.navigatingPartner,
    dataSet: [],
    pickup: ContextSystem.pickup,
    atPlaceQRID: ContextSystem.atPlaceQRID
  };

  componentDidMount() {
    this.eventIDs = [];

    this.uniqueID = ContextSystem.getNextUniqueID();

    this.buildDataSet();

    let eid: number = EventSystem.subscribe(EventSystem.events.contextSystemChanged,
      ({ language, scheduleDate, navigatingPartner, pickup, atPlaceQRID }) => {
        if (language !== undefined)
          this.setState({ language });
        if (scheduleDate !== undefined)
          this.setState({ scheduleDate });
        if (pickup !== undefined)
          this.setState({ pickup });
        if (atPlaceQRID !== undefined)
          this.setState({ atPlaceQRID });
        if (navigatingPartner !== undefined) {
          this.setState({ shop: navigatingPartner });
          this.buildDataSet();
        }
      });

    this.eventIDs.push(eid);
  }

  buildDataSet() {
    let dataSet: DataSetRecord[] = [];

    if ((ContextSystem.navigatingPartner?.preparedOrderMaxDays ?? 0) > 0) {
      let date: Date = new Date();

      let checkHourType = HourTypes.OPENING;
      if (this.state.atPlaceQRID !== undefined) {
        checkHourType = this.state.pickup ? HourTypes.ORDER_ACCEPT_FOR_PICKUP : HourTypes.ORDER_ACCEPT;
      }

      for (let i = 0; i <= ContextSystem.navigatingPartner.preparedOrderMaxDays; i++) {
        let minutes: Date[] = [];

        for (let h = 0; h < 24; h++) {
          for (let m = 0; m < 60; m += 15) {
            date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), h, m, 0, 0);

            if (date < new Date().addMinutes(75)) {
              continue;
            }

            let status: OpenStatus = checkOpeningState(checkHourType, ContextSystem.navigatingPartner, date);
            if (status && status.status === HourStatuses.OPEN) {
              minutes.push(new Date(date.getTime()));
            }
          }
        }
        if (minutes && minutes.length > 0) {
          dataSet.push({
            label:
              addLeadingZero(date.getMonth() + 1) + "." + addLeadingZero(date.getUTCDate()) + " "
              + "(" + getShortDayName(date.getDay() === 0 ? 7 : date.getDay()) + ")",
            value: new Date(date.getTime()),
            minutes,
            id: i
          });
        }
        date.addDays(1);
      }
    }

    this.setState({ dataSet });
  }

  componentWillUnmount() {
    for (let eventID of this.eventIDs) {
      EventSystem.unsubscribe(eventID);
    }
  }

  handleChange(scheduleDate: Date) {
    if (scheduleDate) {
      let nowPlusOneHour = new Date().addMinutes(75);
      if (scheduleDate <= nowPlusOneHour) {
        Toast.showToast(Language.getName(Names.ScheduleDateMin1HourText), 6000);
        this.setState({ scheduleDate: ContextSystem.scheduleDate });
        return;
      }
    }

    ContextSystem.setScheduleDate(scheduleDate);
  }

  handleDayChange(e) {
    let recId: number = parseInt(e.target.value);
    let rec: DataSetRecord = this.state.dataSet.find(d => d.id === recId);
    this.handleChange(rec.minutes[0]);
  }

  startSchedule() {
    if ((this.state.dataSet?.length ?? 0) <= 0) {
      Toast.showToast(Language.getName(Names.NoPreOrdersText));
      return;
    }

    let scheduleDate: Date = this.state.dataSet[0].minutes[0];

    ContextSystem.setScheduleDate(scheduleDate);
  }

  render() {
    let maxScheduleDate: Date = new Date();
    if ((this.state.shop?.preparedOrderMaxDays ?? 0) > 0) {
      maxScheduleDate.addDays(this.state.shop.preparedOrderMaxDays)
    }

    let selectedDay: DataSetRecord = undefined;
    if (this.state.scheduleDate) {
      selectedDay = this.state.dataSet.find(rec => rec.value.getDate() === this.state.scheduleDate.getDate());
    }

    // noinspection JSUnresolvedFunction
    return (
      <Wrapper>
        <ScheduleIconTimerWrapper>
          <IoIosTimer/>
        </ScheduleIconTimerWrapper>
        {(this.state.shop?.preparedOrderMaxDays ?? 0) <= 0 &&
        <p>{Language.getName(Names.NoPreOrdersText)}</p>
        }
        {(this.state.shop?.preparedOrderMaxDays ?? 0) > 0 &&
        <>
          {!this.state.scheduleDate &&
          <SmallButton onClick={() => this.startSchedule()}>
            {Language.getName(Names.ScheduleOrderButtonText)}
          </SmallButton>
          }

          {this.state.scheduleDate &&
          <>
            {this.state.dataSet.length > 0 &&
            <Select
              id={"schdp-d-" + this.uniqueID}
              onChange={e => this.handleDayChange(e)}
              value={selectedDay?.id}
            >
              {this.state.dataSet.map((record: DataSetRecord, i) => {
                if (record.minutes.length <= 0)
                  return <React.Fragment key={i}/>;

                return (
                  <option key={i} value={record.id}>
                    {record.label}
                  </option>
                );
              })}
            </Select>
            }

            <Select
              onChange={e => this.handleChange(new Date(e.target.value))}
              value={this.state.scheduleDate}
              id={"schdp-t-" + this.uniqueID}
            >
              {selectedDay && selectedDay.minutes.length > 0 && selectedDay.minutes.map((time, i) => {
                return (
                  <option key={i} value={time}>
                    {addLeadingZero(time.getHours()) + ":" + addLeadingZero(time.getMinutes())}
                  </option>
                );
              })}
            </Select>

            <FaTimes onClick={() => ContextSystem.setScheduleDate(null)}/>
          </>
          }
        </>
        }
      </Wrapper>
    );
  }

}

import React, { Component } from "react";
import styled from "styled-components";
import EventSystem from "../../utils/EventSystem";
import ContextSystem from "../../utils/ContextSystem";
import Language, { Names } from "../../utils/Language";
import Config, { ConfigKeys } from "../../utils/Config";
import { OuterWrapper } from "./ProfileDetailsPopup";
import { BlundeeButton } from "../Form";

const GoogleFormsIFrame = styled.iframe`
  width: 100%;
  height: 100%;
`;

const Wrapper = styled.div`
  width: 70vw;
  background-color: var(--blundee_background);
  padding: 20px;
  border-radius: 10px;
  min-height: 80vh;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: auto;

  @media screen and (max-width: 800px) {
    min-height: 65vh;
    width: 100%;
    justify-content: flex-start;
  }
`;

export default class GoogleFormsPopUp extends Component {
  state: {
    opened: boolean,
    language: number,
  } = {
    opened: false,
    language: ContextSystem.language,
  };

  close() {
    this.setState({opened: false});
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", (e) => {
      if (this.state.opened && e.key === "Escape") {
        this.close(true);
      }
    });
  }

  componentDidMount() {
    EventSystem.subscribe(EventSystem.events.open_google_forms_modal, () => {
      this.setState({opened: true});
    });

    EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({language}) => {
      if (language !== undefined)
        this.setState({language});
    });

    document.addEventListener("keydown", (e) => {
      if (this.state.opened && e.key === "Escape") {
        this.close(true);
      }
    });
  }

  render() {
    return (
      <OuterWrapper opened={this.state.opened} onClick={() => this.close(true)}>
        {this.state.opened &&
          <Wrapper onClick={(e) => e.stopPropagation()}>
            <GoogleFormsIFrame
              src={Config.getString(ConfigKeys.google_forms_url)}
              frameBorder="0" marginHeight="0" marginWidth="0">
              {Language.getName(Names.PleaseWait)}...
            </GoogleFormsIFrame>
            <BlundeeButton style={{alignSelf: "flex-end", margin:"12px 24px",  width: "calc(100% - 48px)"}} onClick={() => this.close()}>
              {Language.getName(Names.Close)}
            </BlundeeButton>
          </Wrapper>
        }
      </OuterWrapper>
    );
  }
}

import React, { Component } from "react";
import styled from "styled-components";
import { AuthAPI } from "../utils/api/AuthAPI";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet/es/Helmet";
import EventSystem from "../utils/EventSystem";
import Toast from "../components/Toast";
import ContextSystem from "../utils/ContextSystem";
import Language, { Names } from "../utils/Language";

const Wrapper = styled.div`
  width: 100%;
  height: ${({height}) => (height ? height : "100%")};

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
`;

const ActivatonPanel = styled.div`
  max-width: 600px;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  padding: 20px;
  //border-radius: 8px;
  //box-shadow: 0 0 10px 1px dimgray;

  & > h1 {
    margin-bottom: 30px;
    font-family: var(--blundee_font_medium);
    font-size: 28pt;
    width: 100%;
  }

  & > form {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;

    & > p {
      margin: 0;
      padding: 0 0 0 5px;
      width: 70%;
      text-align: left;
    }
  }

  @media screen and (max-width: 600px) {
    width: 90%;
  }
`;

export const Input = styled.input`
  width: ${({width}) => (width ? width : "70%")};
  padding: 5px 20px;
  margin: 10px 0;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  min-height: 38px;

  @media screen and (max-width: 600px) {
    width: ${({width}) => (width ? width : "100%")};
  }

  &:focus {
    border-color: rgba(147, 28, 32, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(147, 28, 32, 0.25);
  }
`;

const Button = styled.div`
  border: 1px solid #931c20;
  border-radius: 5px;
  padding: 8px 20px;

  font-size: 12pt;
  font-family: var(--blundee_font_medium);
  margin: 5px 20% 15px 20%;
  text-align: center;
  transition: height 0.2s ease-in-out;
  color: white;
  background-color: #a62024;

  &:hover, &:active {
    background-color: #d42a2f;
    cursor: pointer;
    color: white;
  }

  @media screen and (max-width: 600px) {
    margin: 0;
    padding-left: 2px;
    padding-right: 2px;
    font-size: 12pt;
  }
`;

class PasswordResetPage extends Component {
  eventIDs = [];

  state: {
    editPass: string,
    token: string,
    language: number,
  } = {
    editPass: "",
    token: "",
    language: ContextSystem.language,
  };

  activateOnEnter(e) {
    if (e.keyCode === 13)
      this.save();
  }

  save() {
    if (this.state.editPass.length < 5) {
      Toast.showToast("Jelszó legalább 5 karakterből állhat.");
      return;
    }
    AuthAPI.sendNewPassword(this.state.token, this.state.editPass, res => {
      if (res.error === 0) {
        EventSystem.publish(EventSystem.events.redirectIntent, {redirectPath: "/bejelentkezes"});
      }
    });
  }

  componentWillUnmount() {
    for (let eventID of this.eventIDs) {
      EventSystem.unsubscribe(eventID);
    }
    this.eventIDs = [];
  }

  componentDidMount() {
    this.eventIDs = [];
    let token = this.props.match.params.token;
    this.setState({token});

    AuthAPI.checkLogin(true, (authenticated) => {
      if (authenticated)
        EventSystem.publish(EventSystem.events.redirectIntent, {redirectPath: "/bejelentkezes"});
    });

    let eid = EventSystem.subscribe(EventSystem.events.authentication_changed, (auth) => {
      if (auth)
        EventSystem.publish(EventSystem.events.redirectIntent, {redirectPath: "/bejelentkezes"});
    });
    this.eventIDs.push(eid);

    eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({language}) => {
      if (language !== undefined) {
        this.setState({language});
      }
    });
    this.eventIDs.push(eid);
  }

  render() {
    return (
      <>
        <Helmet>
          <title>{Language.getName(Names.PasswordResetPageTitle)}</title>
          <meta name="description" content={Language.getName(Names.PasswordResetPageTitle)}/>
        </Helmet>
        <Wrapper>
          <ActivatonPanel>
            <h1>{Language.getName(Names.EnterYourNewPassword)}</h1>
            <form id={"activate"} method={"POST"} onSubmit={event => event.preventDefault()}>
              <p>{Language.getName(Names.EnterYourNewPasswordDescription)}</p>
              <Input
                autoComplete="new-password"
                value={this.state.editPass}
                onKeyUp={(e) => this.activateOnEnter(e)}
                type="password"
                placeholder={Language.getName(Names.NewPassword)}
                onChange={(e) => this.setState({editPass: e.target.value})}
              />
              <Button onClick={() => this.save()}>{Language.getName(Names.SavePassword)}</Button>
            </form>
          </ActivatonPanel>
        </Wrapper>
      </>
    );
  }
}

export default withRouter(PasswordResetPage);

import React, { Component } from "react";
import ContextSystem from "../utils/ContextSystem";
import EventSystem from "../utils/EventSystem";
import Language, { Names } from "../utils/Language";
import { Helmet } from "react-helmet/es/Helmet";
import styled, { css, keyframes } from "styled-components";
import Map, { Overlay } from "../components/map/Map";
import ReactResizeDetector from "react-resize-detector";
import { Element } from "../model/BluePrint";
import SpecialMarker from "../components/map/SpecialMarker";
import { ZoomControl } from "pigeon-maps";
import type { DataSetRecord } from "../components/addresspicker/ScheduleDatePicker";
import { DatePickerStyled, Select as Select2 } from "../components/addresspicker/ScheduleDatePicker";
import * as HoursCalc from "../utils/HoursCalc";
import { addLeadingZero, arrayRotate, getShortDayName, OpenStatus } from "../utils/HoursCalc";
import { Swiper, SwiperSlide } from "swiper/react";
import Config from "../utils/Config";
import { FaLocationArrow, FaTimes } from "react-icons/fa";
import { RestaurantAPI } from "../utils/api/RestaurantAPI";
import { BsFillDoorClosedFill, BsFillDoorOpenFill, BsHeart, BsHeartFill } from "react-icons/bs";
import { GiPaperBagOpen } from "react-icons/gi";
import { MdDeliveryDining } from "react-icons/md";
import { Button, Input as Input2 } from "../components/Form";
import { HourStatuses, HourTypes } from "../model/Hour";
import SearchAPI from "../utils/api/SearchAPI";
import Toast from "../components/Toast";
import AddressPicker from "../components/addresspicker/AddressPicker";
import { Pulse } from "../components/profile/OrderDetails";
import { TranslatableString } from "../model/Product";

const LocationAnimationKeyframes = keyframes`
  0% {
    transform: scale(1.0);
  }
  100% {
    transform: scale(0.8);
  }
`;

const LocationCircle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0 0 5px 1px black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const LocationInnerCircle = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 20px;
  background-color: #5383ec;
  animation: ${LocationAnimationKeyframes} 1.5s ease-in-out infinite alternate;
  transform-origin: center;
`;

const Select = styled(Select2)`
  color: var(--blundee_turkiz);
  font-size: 13pt;
  font-weight: bold;

  & > option:focus, & > option:active, & > option:checked {
    background-color: var(--blundee_input_bg_3) !important;
    background: var(--blundee_input_bg_3) linear-gradient(0deg, var(--blundee_input_bg_3) 0%, var(--blundee_input_bg_3) 100%) !important;
    transition: background-color 200ms ease-in-out !important;
  }
`;

const PluginSelect = styled(Select)`
  width: 100%;
`;

const Input = styled(Input2)`
  width: 100%;
  background-color: transparent;
  color: var(--blundee_turkiz);
  font-size: 13pt;
  font-weight: bold;
  outline: none;
  padding: 2px;
  margin: 0;
  height: 35px;

  &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
`;

const MapWrapper = styled.div.attrs({ className: "map-wrapper" })`
  height: 100%;
  width: 100%;
  flex-shrink: 0;
  flex-grow: 2;
  overflow: hidden;
`;

const FullMapOverlay = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--reservation_page_map_full_overlay_bg);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--blundee_turkiz);
  font-weight: bold;

  & > div {
    padding: 6px 12px;
    background-color: #444440c4;
    border-radius: 15px;
    color: white;
  }

  @media screen and (max-width: 800px) {
    font-size: 10pt;
  }
`;

const FiltersWrapper = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Filters = styled.div`
  pointer-events: initial;
  background-color: var(--blundee_grey_dark);
  box-shadow: 0 0 10px 1px black;
  padding: 3px 12px;
  border-radius: 100px;
  max-width: 60%;
  height: 65px;
  color: var(--blundee_text_color);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  @media screen and (max-width: 800px) {
    max-width: 90%;
    width: 90%;
    padding: 3px 6px;
  }
`;

const Filter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 3px;
  padding: 0 12px;
  background-color: transparent;
  border-radius: 12px;
  cursor: pointer;
  height: 50px;
  max-width: 150px;

  transition: background-color 200ms ease-in-out;

  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.2);
  }

  & > span {
    height: 15px;
    font-weight: bold;
    color: whitesmoke;
    font-size: 10pt;
  }

  @media screen and (max-width: 800px) {
    max-width: 40%;
    padding: ${({ mobile_padding }) => mobile_padding ?? "0"};
    width: ${({ m_width }) => m_width ?? "auto"};
  }
`;

const BottomWrapper = styled.div`
  pointer-events: none;
  position: absolute;
  left: 0;
  width: 100%;
  height: 300px;
  bottom: -300px;
  ${({ show }) => show === true && css`
    bottom: 0
  `};
  transition: bottom 300ms ease-in-out;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const DatePicker = styled(DatePickerStyled)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const SelectedShop = styled.div`
  pointer-events: initial;
  overflow: hidden;
  background-color: var(--blundee_grey_dark);
  box-shadow: 0 0 10px 1px black;
  padding: 3px 12px;
  border-radius: 10px;
  color: var(--blundee_text_color);
  max-width: 500px;
  width: 90%;
  height: 90%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const ShopName = styled.div`
  width: 100%;
  margin: 3px 3px 3px 0;
  padding: 0;
  font-size: 15pt;
  color: var(--blundee_turkiz);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & > h3 {
    flex-grow: 2;
    text-align: center;
    margin: 3px;
  }

  & > svg {
    margin: 3px 3px 0 3px;
    cursor: pointer;
    transition: transform 150ms ease-in-out;

    &:hover {
      transform: scale(1.15);
    }

    &:active {
      transform: scale(1.15);
    }
  }
`;

const ShopDetails = styled.div`
  width: 100%;
  height: 100%;
  flex-grow: 2;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const StartReservationButton = styled(Button)`
  min-height: 35px;

  margin: auto auto 6px auto;
  width: 95%;
  font-weight: bold;

  position: sticky;
  bottom: 0;

  background-color: var(--blundee_turkiz);
  color: var(--blundee_grey_dark);
  border-color: var(--blundee_turkiz);

  &:hover {
    background-color: var(--blundee_turkiz_hover);
  }

  &:active {
    background-color: var(--blundee_turkiz_active);
  }

  @media screen and (max-width: 800px) {
    min-width: unset;
    margin: auto auto 3px auto;
  };

`;

const ShopInfo = styled.div`
  width: 50%;
  height: 100%;
  padding: 3px 3px 3px 6px;

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: auto;
`;

export const ShopInfoRecord = styled.div`
  width: 100%;
  margin: 2px 0;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: white;

  & > p {
    font-size: 10pt;
    margin: 3px;
  }

  & > svg {
    width: 20px;
    font-size: 10pt;
    margin-right: 3px;
  }
`;

export const ShopOccupancy = styled(ShopInfoRecord)`
  align-items: center;

  & > div {
    width: 10px;
    height: 10px;
    margin: 0 10px 0 3px;
    animation: ${Pulse} 0.5s ease infinite alternate;
    background-color: green;
    border-radius: 100px;
  }
`;

export const ShopAddress = styled(ShopInfoRecord)`
  color: var(--blundee_yellow);
`;

export const ShopOpeningInfo = styled(ShopInfoRecord)`
  color: white;

  & > svg {
    font-size: 14pt;
    ${({ open_status }) => open_status.status === HourStatuses.OPEN && css`
      color: rgb(74, 213, 19);
    `};
    ${({ open_status }) => (open_status.status === HourStatuses.NO_DATA || open_status.status === HourStatuses.LOADING || open_status.status === HourStatuses.CLOSED) && css`
      color: rgb(208, 12, 12);
    `};
  }
`;

const ShopImages = styled.div`
  width: 50%;
  height: 100%;

  display: flex;
  flex-direction: column;
`;

const ShopImageSwiperContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: auto;

  &::-webkit-scrollbar-thumb {
    background-color: var(--blundee_scroll_thumb_color);
    border-radius: 100px;
  }

  &::-webkit-scrollbar {
    width: 15px;
    background-color: transparent;
    //border-left: 1px solid rgb(20, 20, 20);
  }
`;

const ShowImageWrapper = styled.div`
  width: 100%;
  height: 200px;
  background-color: rgba(255, 255, 255, 0.3);
  @media screen and (max-width: 800px) {
    height: 130px;
  }
`;

const ShopImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const HourSelectWrapper = styled.div`
  margin-top: 20px;
  width: 100px;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  & > span {
    width: 100%;
  }
`;

export default class ReservationPage extends Component {
  eventIDs: number[] = [];
  map: Map = undefined;
  ref: MapWrapper = undefined;
  delaySearch: number = 500;

  state: {
    language: number,
    mapWidth: number,
    mapHeight: number,
    bounds: Bounds,
    shops: Shop[],
    tablesAvailable: Element[],
    zoom: number,
    ppl: number,
    hoursDataSet: DataSetRecord,
    selectedShop: Shop,
    hearted: boolean,
    selectedShopCurrentTouch: { x: number, y: number },
    selectedDay: Date,
    arrival: Date,
    gpsCoordinates: { lat: number, lng: number },
    mapCenter: [number, number],
    occupancy: { [keys: number]: number }, //shopIDs -> all tables
    stay: number,
  } = {
    language: ContextSystem.language,
    mapWidth: 1000,
    mapHeight: 1000,
    bounds: undefined,
    ppl: 2,
    hoursDataSet: undefined,
    shops: [],
    tablesAvailable: [],
    zoom: 15,
    selectedShop: undefined,
    selectedShopCurrentTouch: undefined,
    selectedDay: new Date(),
    arrival: new Date(),
    hearted: false,
    gpsCoordinates: { lat: -1, lng: -1 },
    mapCenter: [47.499073, 19.044009],
    occupancy: {},
    stay: 60
  };

  buildDataSet() {
    let hoursDataSet: DataSetRecord = [];

    let date: Date = this.state.selectedDay;
    let now: Date = new Date();
    let checkHourType = HourTypes.ORDER_ACCEPT_FOR_PICKUP;

    let k = 0;
    let minutes: Date[] = [];
    let arrival = this.state.arrival;

    for (let h = 0; h < 24; h++) {
      for (let m = 0; m < 60; m += 30) {
        date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), h, m, 0, 0);
        if (date < new Date().addMinutes(30)) {
          continue;
        }
        minutes.push(new Date(date.getTime()));

        if (arrival === this.state.arrival && arrival.getHours() === h && arrival.getMinutes() === m) {
          arrival = new Date(date.getTime());
        }
      }
    }

    if (minutes && minutes.length > 0) {
      hoursDataSet = {
        label:
          addLeadingZero(date.getMonth() + 1) + "." + addLeadingZero(date.getUTCDate()) + " "
          + "(" + getShortDayName(date.getDay() === 0 ? 7 : date.getDay()) + ")",
        value: new Date(date.getTime()),
        minutes,
        id: k++
      };
    }

    if (arrival === this.state.arrival)
      arrival = minutes[0];

    this.setState({
      hoursDataSet,
      arrival
    });
  }

  handleReservationClick() {
    if (!this.state.selectedShop)
      return;

    let points: { [keys: number]: Element[] } = {};
    for (let element of this.state.tablesAvailable) {
      if (!points[element.partnerID])
        points[element.partnerID] = [];
      points[element.partnerID].push(element);
    }
    let freeTable: number = points[this.state.selectedShop.id].length;

    let occupancyRate: number = -1;

    if (this.state.selectedShop && this.state.occupancy[this.state.selectedShop.id]) {
      occupancyRate = Math.round((points[this.state.selectedShop.id].length / this.state.occupancy[parseInt(this.state.selectedShop.id)]) / 5) * 5;
    }
    if (occupancyRate >= 0 && occupancyRate < 5)
      occupancyRate = 5;
    if (occupancyRate >= 100)
      occupancyRate = 98;

    EventSystem.publish(EventSystem.events.open_reservation_popup, {
      shop: this.state.selectedShop,
      time: this.state.selectedDay,
      ppl: this.state.ppl,
      stay: this.state.stay,
      freeTable,
      occupancyRate
    });
  }

  static handleAddressClick(shop: Shop) {
    if (!shop)
      return;

    // let c: City = ContextSystem.getCityByID(this.state.selectedShop.address.cityID);
    // let z: ZipCode = ContextSystem.getZipCode(this.state.selectedShop.address.zipCodeID);

    let link: string = "https://maps.google.com/maps?q=" + shop.position.latitude + "," + shop.position.longitude;
    // if (z || c)
    //   link += ",";
    // if (c)
    //   link += " " + c.name;
    // if (z)
    //   link += " " + z.zipcode;

    window.open(link, "_blank");
  }

  handleHeartClicked() {
    if (!this.state.selectedShop)
      return;

    RestaurantAPI.heart(!this.state.hearted, this.state.selectedShop.id, (res) => {
      if (res.error !== 0)
        return;

      if (!this.state.hearted) {
        ContextSystem.addRestaurantHeart(this.state.selectedShop.id);
      } else {
        ContextSystem.removeRestaurantHeart(this.state.selectedShop.id);
      }
      this.setState({ hearted: !this.state.hearted });
    });
  }

  componentWillUnmount() {
    for (let eventID of this.eventIDs) {
      EventSystem.unsubscribe(eventID);
    }
  }

  updateDimension() {
    setTimeout(() => {
      if (this.ref && this.ref?.offsetWidth > 0 && this.ref?.offsetHeight > 0)
        this.setState({ mapWidth: this.ref.offsetWidth, mapHeight: this.ref.offsetHeight });
      else this.updateDimension();
    }, 100);
  }

  searchTimeOut;

  reloadIntent() {
    if (this.searchTimeOut)
      clearTimeout(this.searchTimeOut);

    this.searchTimeOut = setTimeout(() => {
      this.reload();
    }, this.delaySearch);
  }

  reload() {
    if (!this.state.bounds)
      return;

    if (this.state.zoom < 14)
      return;

    let stay: number = 30;

    SearchAPI.searchTable(true, this.state.bounds, this.state.ppl, this.state.arrival, stay, res => {
      if (res.error !== 0)
        return;

      this.setState({
        shops: res.shops,
        tablesAvailable: res.tables,
        occupancy: res.occupancy
      });
    });
  }

  async loadLocation() {
    // noinspection ES6RedundantAwait
    let location: { lat: number, lng: number } | null = await AddressPicker.getLocation();
    if (!location) {
      Toast.showToast(Language.getName(Names.LocationAccessError));
      return;
    }
    this.setState({ gpsCoordinates: location, mapCenter: [location.lat, location.lng] });
  }

  componentDidMount() {
    this.eventIDs = [];
    this.buildDataSet();
    this.reload();
    // noinspection JSIgnoredPromiseFromCall
    this.loadLocation();

    let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ language }) => {
      if (language !== undefined) {
        this.setState({ language });
      }
    });
    this.eventIDs.push(eid);
  }

  onBoundsChanged(mapCenter: [number, number], zoom: number, bounds, initial) {
    this.setState({ mapCenter, zoom, bounds });
    this.reloadIntent();
  }

  handleShopClick(shop: Shop) {
    this.setState({
      selectedShop: shop,
      hearted: !!ContextSystem.restaurantHearts.find(rh => rh.partnerID === shop.id && rh.enabled)
    });
  }

  handleArrivalChange(arrival: Date) {
    if (arrival) {
      let now = new Date();
      let nowPlusOneHour = new Date().addMinutes(30);
      if (arrival <= nowPlusOneHour) {
        Toast.showToast(Language.getName(Names.TableForMin1HourText), 4000);
        return;
      }
    }
    this.setState({ arrival }, () => this.reloadIntent());
  }

  render() {
    let points: { [keys: number]: Element[] } = {};
    for (let element of this.state.tablesAvailable) {
      if (!points[element.partnerID])
        points[element.partnerID] = [];
      points[element.partnerID].push(element);
    }

    let occupancyRate: number = -1;

    if (this.state.selectedShop && this.state.occupancy[this.state.selectedShop.id]) {
      occupancyRate = Math.round((points[this.state.selectedShop.id].length / this.state.occupancy[parseInt(this.state.selectedShop.id)]) / 5) * 5;
    }
    if (occupancyRate >= 0 && occupancyRate < 5)
      occupancyRate = 5;
    if (occupancyRate >= 100)
      occupancyRate = 98;

    let dayOfWeek: number = new Date().getDay();
    if (dayOfWeek === 0)
      dayOfWeek = 7;

    let shopOpenStatus: OpenStatus = HoursCalc.checkOpeningState(HourTypes.OPENING, this.state.selectedShop);
    let AcceptingPickUpOrdersStatus: OpenStatus = HoursCalc.checkOpeningState(HourTypes.ORDER_ACCEPT_FOR_PICKUP, this.state.selectedShop);
    let DeliveringStatus: OpenStatus = HoursCalc.checkOpeningState(HourTypes.DELIVERY, this.state.selectedShop);
    let AcceptingOrdersStatus: OpenStatus = HoursCalc.checkOpeningState(HourTypes.ORDER_ACCEPT, this.state.selectedShop);

    let openingHoursText = HoursCalc.getOpeningHoursText(shopOpenStatus, AcceptingPickUpOrdersStatus, DeliveringStatus, AcceptingOrdersStatus);

    let openingStatus: { closed: boolean, preOrder: boolean } = { closed: false, preOrder: false };

    if (openingHoursText.openStringDL === HourStatuses.NO_DATA) {
      openingStatus.closed = true;
    } else if (openingHoursText.openStringDL === HourStatuses.LOADING) {
      openingStatus.closed = false;
      openingStatus.preOrder = false;
    } else if (openingHoursText.openStringDL.includes(Language.getName(Names.Closed))) {
      openingStatus.closed = true;
    } else if (openingHoursText.openStringDL.includes(Language.getName(Names.ForPreOrder))) {
      openingStatus.preOrder = true;
    }

    if (openingHoursText.openStringDL === HourStatuses.NO_DATA)
      openingHoursText.openStringDL = Language.getName(Names.Closed);
    if (openingHoursText.openStringPK === HourStatuses.NO_DATA)
      openingHoursText.openStringPK = Language.getName(Names.Closed);
    if (openingHoursText.openStringDL === HourStatuses.LOADING)
      openingHoursText.openStringDL = "";
    if (openingHoursText.openStringPK === HourStatuses.LOADING)
      openingHoursText.openStringPK = "";

    // noinspection RequiredAttributes,JSUnresolvedFunction
    return (
      <>
        <Helmet>
          <title>{Language.getName(Names.ReservationPageTitle)}</title>
          <meta name="description" content={Language.getName(Names.ReservationPageTitle)}/>
        </Helmet>
        <Wrapper>
          <ReactResizeDetector onResize={() => this.updateDimension()}>
            <MapWrapper ref={(r) => (this.ref = r)}>
              <Map width={this.state.mapWidth} height={this.state.mapHeight}
                   center={this.state.mapCenter}
                   zoom={this.state.zoom}
                   ref={(r) => (this.map = r)}
                   onBoundsChanged={(res) => {
                     let { center, zoom, bounds, initial } = res;
                     this.onBoundsChanged(center, zoom, bounds, initial);
                   }}
              >
                <ZoomControl style={{ top: "initial", left: "initial", bottom: 10, right: 10 }}/>
                {this.state.gpsCoordinates && this.state.gpsCoordinates.lat !== -1 &&
                <Overlay center={true} anchor={[this.state.gpsCoordinates.lat, this.state.gpsCoordinates.lng]}>
                  <LocationCircle>
                    <LocationInnerCircle/>
                  </LocationCircle>
                </Overlay>
                }
                {this.state.zoom >= 14 && Object.keys(points).map((shopID, i) => {
                  shopID = parseInt(shopID);
                  let shop: Shop = this.state.shops.find(s => s.id === shopID);
                  if (!shop)
                    return <React.Fragment key={i}/>;
                  // noinspection RequiredAttributes
                  return (
                    <Overlay key={i} anchor={[shop.position.latitude, shop.position.longitude]}>
                      <SpecialMarker
                        width2={80}
                        text={points[shopID].length}
                        handleShopClick={() => this.handleShopClick(shop)}
                      />
                    </Overlay>
                  );
                })}
              </Map>
            </MapWrapper>
          </ReactResizeDetector>
          {this.state.zoom < 14 &&
          <FullMapOverlay>
            <div>
              {Language.getName(Names.PleaseZoomIn)}
            </div>
          </FullMapOverlay>
          }
          <FiltersWrapper>
            <Filters>
              <Filter m_width={"130px"} mobile_padding={"0 0 0 12px"}>
                {/*<span>{Language.getName(Names.ReservationFilterWhen)}</span>*/}
                <span>{Language.getName(Names.Time)}</span>
                <DatePicker
                  value={this.state.arrival}
                  onChange={(d: DateObject) => {
                    this.setState({ selectedDay: d.toDate() }, () => {
                      this.buildDataSet();
                      this.reloadIntent();
                    });
                  }}
                  disableYearPicker={true}
                  minDate={new Date()}
                  maxDate={new Date().setDate(new Date().getDate() + 14)}
                  weekStartDayIndex={1}
                  format="MM.DD. HH:mm"
                  weekDays={arrayRotate(Language.getName(Names.DayNamesShort), true)}
                  months={(Language.getName(Names.MonthNames)).map(s => [s, s])}
                  inputClass={"input_range_asdasd"}
                  plugins={[
                    <HourSelectWrapper position={"right"}>
                      <span>{Language.getName(Names.Arrival)}</span>
                      <PluginSelect
                        onChange={e => this.handleArrivalChange(new Date(e.target.value))}
                        value={this.state.arrival}
                        id={"schdp-t-"}
                      >
                        {this.state.hoursDataSet?.minutes?.map((time, i) => {
                          return (
                            <option key={i} value={time}>
                              {addLeadingZero(time.getHours()) + ":" + addLeadingZero(time.getMinutes())}
                            </option>
                          );
                        })}
                      </PluginSelect>
                    </HourSelectWrapper>
                  ]}
                />
              </Filter>
              <Filter m_width={"80px"} mobile_padding={"0 0 0 9px"}>
                <span>{Language.getName(Names.Guests)}</span>
                <Input
                  type={"number"}
                  value={this.state.ppl === "" ? "" : this.state.ppl}
                  onBlur={e => {
                    if (e.target.value === "")
                      this.setState({ ppl: 1 });
                  }}
                  onChange={e => {
                    let ppl = e.target.value;
                    if (ppl <= 0)
                      ppl = 1;
                    if (ppl > 20)
                      ppl = 20;
                    if (e.target.value === "")
                      ppl = "";
                    this.setState({ ppl });
                    this.reloadIntent();
                  }}
                />
              </Filter>
              <Filter mobile_padding={"0 0 0 9px"}>
                <span>{Language.getName(Names.Period)}</span>
                <Select
                  onChange={e => this.setState({ stay: e.target.value }, () => this.reloadIntent())}
                  value={this.state.stay}
                >
                  <option value={30}>{Language.getName(Names._30min)}</option>
                  <option value={60}>{Language.getName(Names._1h)}</option>
                  <option value={90}>{Language.getName(Names._1_5h)}</option>
                  <option value={120}>{Language.getName(Names._2h)}</option>
                  <option value={180}>{Language.getName(Names._3h)}</option>
                </Select>
              </Filter>
            </Filters>
          </FiltersWrapper>
          <BottomWrapper show={this.state.selectedShop !== undefined}>
            <SelectedShop key={this.state.selectedShop?.id ?? -1}>
              {this.state.selectedShop !== undefined &&
              <>
                <ShopName>
                  {!this.state.hearted && <BsHeart onClick={() => this.handleHeartClicked()}/>}
                  {this.state.hearted && <BsHeartFill onClick={() => this.handleHeartClicked()}/>}
                  <h3>{this.state.selectedShop.name}</h3>
                  <FaTimes onClick={() => this.setState({ selectedShop: undefined })}/>
                </ShopName>
                <ShopDetails>
                  <ShopImages>
                    <ShopImageSwiperContainer>
                      <Swiper
                        grabCursor
                        autoHeight={true}
                        initialSlide={0}
                        spaceBetween={10}
                        slidesOffsetBefore={10}
                        slidesOffsetAfter={10}
                        slidesPerView={1.2}
                        pagination={{
                          clickable: true,
                          renderBullet: (index, className) => {
                            className += " slider-bullet";
                            return `<span class="${className}"></span>`;
                          }
                        }}
                        // onSwiper={(s) => (this.swiperRefs[i] = s)}
                      >
                        {(() => {
                          let images = this.state.selectedShop?.images;
                          if (images.length > 6)
                            images = images.slice(0, 6);
                          return (
                            <>
                              {images?.map((image, i) => {
                                return (
                                  <SwiperSlide key={i}>
                                    <ShowImageWrapper>
                                      <ShopImage onError={(e) => (e.target.style.display = "none")}
                                                 src={Config.partnerImageUrlBase + image.fileName} alt={image.alt}
                                      />
                                    </ShowImageWrapper>
                                  </SwiperSlide>
                                );
                              })}
                            </>
                          );
                        })()}
                      </Swiper>
                    </ShopImageSwiperContainer>
                  </ShopImages>
                  <ShopInfo>
                    <ShopAddress onClick={() => ReservationPage.handleAddressClick(this.state.selectedShop)}>
                      <FaLocationArrow/>
                      <p>
                        {
                          this.state.selectedShop.address.street + ", "
                          + TranslatableString.get(ContextSystem.getCityByID(this.state.selectedShop.address.cityID)?.name)
                        }
                      </p>
                    </ShopAddress>
                    <ShopOpeningInfo open_status={shopOpenStatus}>
                      {shopOpenStatus.status === HourStatuses.OPEN &&
                      <BsFillDoorOpenFill/>
                      }
                      {shopOpenStatus.status !== HourStatuses.OPEN &&
                      <BsFillDoorClosedFill/>
                      }
                      <p>
                        {shopOpenStatus.status === HourStatuses.OPEN &&
                        <>
                          {Language.getName(Names.OpenOpenHoursTextPart1)}
                          {shopOpenStatus.nextCloseD !== dayOfWeek &&
                          <>{Language.getName(Names.DayNamesShort, shopOpenStatus.nextCloseD) + ". "}</>
                          }
                          {addLeadingZero(shopOpenStatus.nextCloseH) + ":" + addLeadingZero(shopOpenStatus.nextCloseM)}
                          {Language.getName(Names.OpenOpenHoursTextPart2)}
                        </>
                        }
                        {shopOpenStatus.status !== HourStatuses.OPEN &&
                        <>
                          {Language.getName(Names.ClosedOpenHoursText)}
                          {shopOpenStatus.nextOpenD !== dayOfWeek &&
                          <>{Language.getName(Names.DayNamesShort, shopOpenStatus.nextOpenD) + ". "}</>
                          }
                          {addLeadingZero(shopOpenStatus.nextOpenH) + ":" + addLeadingZero(shopOpenStatus.nextOpenM)}
                        </>
                        }
                      </p>
                    </ShopOpeningInfo>
                    {this.state.selectedShop.enableDeliveryOrder &&
                    <ShopOpeningInfo open_status={AcceptingOrdersStatus}>
                      <MdDeliveryDining/>
                      <p>
                        {Language.getName(Names.DeliveryShort) + ": "}
                        {AcceptingOrdersStatus.status === HourStatuses.OPEN &&
                        <>
                          {AcceptingOrdersStatus.nextCloseD !== dayOfWeek &&
                          <>{Language.getName(Names.DayNamesShort, AcceptingOrdersStatus.nextCloseD) + ". "}</>
                          }
                          {addLeadingZero(AcceptingOrdersStatus.nextCloseH) + ":" + addLeadingZero(AcceptingOrdersStatus.nextCloseM)}
                          {Language.getName(Names.OpenOpenHoursTextPart2)}
                        </>
                        }
                        {AcceptingOrdersStatus.status !== HourStatuses.OPEN &&
                        <>
                          {Language.getName(Names.ClosedOpenHoursText)}
                          {AcceptingOrdersStatus.nextOpenD !== dayOfWeek &&
                          <>{Language.getName(Names.DayNamesShort, AcceptingOrdersStatus.nextOpenD) + ". "}</>
                          }
                          {addLeadingZero(AcceptingOrdersStatus.nextOpenH) + ":" + addLeadingZero(AcceptingOrdersStatus.nextOpenM)}
                        </>
                        }
                      </p>
                    </ShopOpeningInfo>
                    }
                    {this.state.selectedShop.enablePersonalOrder &&
                    <ShopOpeningInfo open_status={AcceptingPickUpOrdersStatus}>
                      <GiPaperBagOpen/>
                      <p>
                        {Language.getName(Names.Takeaway) + ": "}
                        {AcceptingPickUpOrdersStatus.status === HourStatuses.OPEN &&
                        <>
                          {AcceptingPickUpOrdersStatus.nextCloseD !== dayOfWeek &&
                          <>{Language.getName(Names.DayNamesShort, AcceptingPickUpOrdersStatus.nextCloseD) + ". "}</>
                          }
                          {addLeadingZero(AcceptingPickUpOrdersStatus.nextCloseH) + ":" + addLeadingZero(AcceptingPickUpOrdersStatus.nextCloseM)}
                          {Language.getName(Names.OpenOpenHoursTextPart2)}
                        </>
                        }
                        {AcceptingPickUpOrdersStatus.status !== HourStatuses.OPEN &&
                        <>
                          {Language.getName(Names.ClosedOpenHoursText)}
                          {AcceptingPickUpOrdersStatus.nextOpenD !== dayOfWeek &&
                          <>{Language.getName(Names.DayNamesShort, AcceptingPickUpOrdersStatus.nextOpenD) + ". "}</>
                          }
                          {addLeadingZero(AcceptingPickUpOrdersStatus.nextOpenH) + ":" + addLeadingZero(AcceptingPickUpOrdersStatus.nextOpenM)}
                        </>
                        }
                      </p>
                    </ShopOpeningInfo>
                    }
                    {occupancyRate >= 0 &&
                    <ShopOccupancy>
                      <div/>
                      <p>
                        {Language.getName(Names.Occupancy) + ": "}
                        {occupancyRate}
                        %
                      </p>
                    </ShopOccupancy>
                    }
                    <StartReservationButton onClick={() => this.handleReservationClick()}>
                      {Language.getName(Names.StartReservationBtnText)}
                    </StartReservationButton>
                  </ShopInfo>
                </ShopDetails>
              </>
              }
            </SelectedShop>
          </BottomWrapper>
        </Wrapper>
      </>
    );
  }

}

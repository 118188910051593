import React, { Component } from "react";
import styled, { css } from "styled-components";
import { Button } from "./Form";
import EventSystem from "../utils/EventSystem";
import { CartStorage } from "../utils/CartStorage";
import Config from "../utils/Config";
import Cart from "./cart/Cart";
import Toast from "./Toast";
import {Category, Product, ProductTypes, TranslatableString} from "../model/Product";
import { OrderProduct } from "../model/Order";

const Wrapper = styled.div`
  width: 100%;
  max-height: ${({ maxheight }) => (maxheight ? maxheight : "unset")};
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  z-index: 17;

  ${({ height }) => height && css`
    height: ${height};
  `};

  @media screen and (max-width: 600px) {
    & > h2 {
      font-size: 15pt;
      max-height: ${({ maxheight }) => (maxheight ? maxheight : "85vh")};
    }
  }
`;

const Header = styled.div`
  width: 100%;
  padding: 0 3%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  font-family: var(--blundee_font_medium);
  color: var(--primary);

  & > h2 {
    font-size: 18pt;
    padding: 5px 0 0 5px;
  }

  & > i {
    font-size: 20pt;
    padding: 4px;
    border-radius: 4px;

    &:hover,
    &:active {
      background-color: #ebebeb;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 600px) {
    & > h2 {
      font-size: 14pt;
    }
  }
`;

const BigPanel = styled.div`
  flex-grow: 2;

  max-height: 70%;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  @media screen and (max-width: 600px) {
    flex-direction: column-reverse;
    margin-bottom: 3px;
    align-items: center;
    justify-content: flex-start;
  }
`;

const CombiningSpace = styled.div`
  max-height: 100%;
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 15px;

  & > span {
    font-size: 15pt;
    font-family: var(--blundee_font_medium);
    color: var(--primary);
    padding-left: 20px;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    padding: 0;
    & > span {
      font-size: 12pt;
      padding: 0;
      width: 100%;
      text-align: left;
    }
  }
`;

const BottomPanel = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px 20px;
  border-top: 1px solid #bdbdbd;
  @media screen and (max-width: 600px) {
    padding: 3px 15px;
  }
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  & > div {
    //Buttons
    margin: 3px;
  }
`;

const MenuContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  & > p {
    margin: 0;
  }

  & > div {
    width: 100%;
    white-space: nowrap;
    overflow-x: auto;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 8px;
  }
`;

const OrderProductDiv = styled.div`
  display: inline-block;
  margin: 0 5px;
  padding: 0 5px;
  border-radius: 5px;
  border: 1px solid #dbdbdb;

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    & i {
      margin: 0 5px;
      font-size: 14pt;
      color: #555555;

      &:hover {
        cursor: pointer;
      }
    }
  }
`;

const CategoriesListWrapper = styled.div`
  width: 20%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  @media screen and (max-width: 600px) {
    width: 100%;
    flex-direction: row;
    padding: 0;
  }
`;

const MainCategoryLink = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 5px;
  margin: 5px;
  user-select: none;

  & > span {
    margin-right: 3px;
    text-align: right;

    & > div {
      width: 3px;
      height: 30px;
      background-color: var(--primary);
      transition: width 200ms ease-in-out, height 200ms ease-in-out, margin-bottom 200ms ease-in-out;
    }
  }

  &:hover {
    cursor: pointer;

    & > span > div {
      width: 6px;
      background-color: #c9292d;
    }
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    margin: 0;
    padding: 0;

    & > span {
      margin-bottom: 3px;
      margin-right: 0;
      width: 100%;
      text-align: center;

      & > div {
        width: 100%;
        height: 3px;
        margin-bottom: 2px;
      }
    }

    &:hover {
      & > span > div {
        width: 100%;
        height: 5px;
        margin-bottom: 0;
        background-color: #c9292d;
      }
    }
  }

  ${({ current }) => current === true && css`
    & > span > div {
      width: 5px;
    }

    & > div {
      font-weight: bold;
    }

    @media screen and (max-width: 600px) {
      & > span > div {
        height: 5px;
        margin-bottom: 0;
        width: 100%;
      }
    }
  `};
`;

const ListWrapper = styled.div`
  width: 100%;
  max-height: 95%;
  height: 95%;
  padding: 5px;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  @media screen and (max-width: 600px) {
    height: 50vh;
    flex-grow: 2;
    padding: 0;
    align-content: initial;
    overflow-x: hidden;
    overflow-y: auto;
    flex-wrap: nowrap;
    ${({ holdscategories }) =>
            holdscategories &&
            css`
              flex-wrap: wrap;
              align-content: flex-start;
              justify-content: center;
              overflow-x: auto;
              overflow-y: hidden;
            `}
  }
`;

const CategoryDiv = styled.div`
  width: 20%;
  height: 12vw;
  margin: 15px;
  padding: 10px;
  border-radius: 8px;
  background-color: var(--primary);
  border: 1px solid var(--primary);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: background-color 100ms ease-in-out, color 100ms ease-in-out;
  text-align: center;

  @media screen and (max-width: 600px) {
    width: 35vw;
    height: calc(50% - 10px);
    margin: 5px;
    padding: 0;
  }

  &:hover {
    background-color: white;
    color: var(--primary);
    cursor: pointer;
  }
`;

const ProductDiv = styled.div`
  transition: height 250ms ease-in-out, width 250ms ease-in-out;
  width: max(25vh, 20%);
  height: fit-content;
  margin: 7px 20px;
  border-radius: 8px;
  border: 1px solid var(--success);
  background-color: white;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  & > img {
    display: none;
  }

  & > i {
    display: none;
  }

  & > span {
    display: block;
    width: 100%;
    text-align: right;
    padding-right: 4px;
    height: 20px;

    & > i {
      padding: 4px;
      border-radius: 3px;
      font-size: 12pt;

      &:hover {
        background-color: #ebebeb;
        cursor: pointer;
      }
    }
  }

  & > p {
    width: 100%;
    text-align: center;
    display: block;
    margin: 0;
    height: calc(75% - 20px);
    max-height: calc(75% - 20px);
    overflow: auto;
    line-height: 1.05rem;
    word-wrap: break-word;
    font-size: 11pt;
    padding: 5px 0;

    & > span {
      width: 100%;
      margin-top: 5px;
      padding: 5px 5px 0 5px;
      border-top: 1px solid #b8b8b8;
      font-size: 10.5pt;
      text-align: left;
      display: block;
      font-weight: normal;
    }

    & > img {
      margin: 3px 0;
      width: 100%;
    }
  }

  ${({ clicked }) =>
          clicked &&
          css`
            height: calc(50vh + 14px);
            width: 30vw;
            font-weight: bold;
          `};

  @media screen and (max-width: 600px) {
    width: 95vw;
    height: unset;
    min-height: 65px;
    margin: 5px;
    padding: 0;
    position: relative;

    flex-direction: row;

    & > span {
      display: none;
    }

    & > i {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      width: 20%;
      height: 100%;

      margin-right: 5px;
      text-align: center;
      font-size: 15pt;
      color: #acacac;
    }

    & > img {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;

      width: 20%;
      height: 100%;
      object-fit: cover;
      margin-right: 5px;
    }

    & > p {
      overflow: hidden;
      height: fit-content;
      max-height: unset;
      text-align: left;
      width: 44%;
      font-size: 11pt;
      padding-top: 0;
      white-space: pre-wrap;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }
  }
`;

const ProductPrice = styled.div`
  border-top: 1px solid #b8b8b8;
  width: 100%;
  text-align: center;

  @media screen and (max-width: 600px) {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 20%;
    padding: 3px 5px;
    border: none;
  }
`;

const AddProductToMenuButton = styled.div`
  width: 100%;
  border-top: 1px solid #b8b8b8;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-size: 11pt;
  padding: 8px 0;
  margin-top: auto;
  background-color: var(--success);
  color: white;

  &:hover,
  &:active {
    cursor: pointer;
    background-color: #3a971d;
  }

  @media screen and (max-width: 600px) {
    width: 16%;
    height: 100%;
    padding: 0 8px;
    margin: 0;
    border: none;
  }
`;

export default class MenuCombiner extends Component {
  listWrapperRef;

  state: {
    products: Product[],
    categories: Category[],
    currentCategory: Category,
    lastStep: boolean,
    orderProducts: OrderProduct[],
    sentForExtrasSelection: Product,
    clickedProductID: number,
    mobile: number,
    closeCb: ()=>{}
  } = {
    products: [],
    categories: [],
    currentCategory: null,
    lastStep: false,
    orderProducts: [],
    sentForExtrasSelection: null,
    clickedProductID: -1,
    mobile: window.innerWidth < 600,
    closeCb: undefined
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.products !== this.props.products && this.props.products) {
      this.setProducts(this.props.products);
    }
    if (prevProps.categories !== this.props.categories && this.props.categories) {
      this.setCategories(this.props.categories);
    }
    if (prevProps.closeCb !== this.props.closeCb && this.props.closeCb) {
      this.setState({ closeCb: this.props.closeCb });
    }
  }

  setProducts(products) {
    this.setState({ products, orderProducts: [] });
  }

  setCategories(categories) {
    categories = categories.sort((c1, c2) => c1.orderNumber - c2.orderNumber);
    this.nextCategory(categories);
    this.setState({ categories });
  }

  componentDidMount() {
    this.setState({ closeCb: this.props.closeCb });
    if (this.props.products) {
      this.setProducts(this.props.products);
    }
    if (this.props.categories) {
      this.setCategories(this.props.categories);
    }

    window.addEventListener("resize", () => {
      this.setState({ mobile: window.innerWidth < 600 });
    });
  }

  hasChildren(category, categories) {
    if (!category || !categories) return false;

    for (let c of categories) {
      if (c.parent === category.id) return true;
    }
    return false;
  }

  selectCategory(currentCategory) {
    if (!currentCategory) return;
    this.setState({ currentCategory });
  }

  isFirstStep(currentCategory) {
    if (!currentCategory) return false;

    return currentCategory.orderNumber <= 1 && currentCategory.parent === 0;
  }

  // isLastStep(currentCategory, categories) {
  //   if (!currentCategory) return false;
  //
  //   for (let c of categories) {
  //     if (c.parent === 0 && c.orderNumber > currentCategory.orderNumber) return false;
  //   }
  //   return true;
  // }

  getCategory(categoryID) {
    for (let category of this.state.categories) {
      if (category.id === categoryID) return category;
    }
  }

  hasPrevStep(categories) {
    let currentCategory = this.state.currentCategory;
    if (!currentCategory) return false;
    for (let c of categories) {
      if (c.parent === 0 && (c.orderNumber < currentCategory.orderNumber || c.id === currentCategory.parent)) return true;
    }
    return false;
  }

  hasNextStep(categories) {
    let currentCategory = this.state.currentCategory;
    if (!currentCategory) return true;
    for (let c of categories) {
      if (c.parent === 0 && c.orderNumber > currentCategory.orderNumber) return true;
    }
    return false;
  }

  nextCategory(categories) {
    if (!this.hasNextStep(categories)) return;

    let currentCategory = this.state.currentCategory;
    for (let c of categories) {
      if (c.parent === 0) {
        if (currentCategory) {
          if (currentCategory.parent === 0) {
            if (c.orderNumber > currentCategory.orderNumber) {
              currentCategory = c;
              break;
            }
          } else {
            if (c.orderNumber > this.getCategory(currentCategory.parent).orderNumber) {
              currentCategory = c;
              break;
            }
          }
        } else {
          currentCategory = c;
          break;
        }
      }
    }
    this.setState({ currentCategory });
  }

  hasProducts(products, category) {
    if (!category || !products) return false;
    for (let p of products) {
      if (p.categoryID === category.id) return true;
    }
    return false;
  }

  previousCategory(categories) {
    if (!this.hasPrevStep(categories)) return;
    let currentCategory = this.state.currentCategory;
    if (this.isFirstStep(currentCategory)) return;

    for (let c of categories) {
      if (c.parent === 0) {
        if (currentCategory) {
          if (currentCategory.parent === 0) {
            if (c.orderNumber === currentCategory.orderNumber - 1) {
              currentCategory = c;
              break;
            }
          } else {
            if (currentCategory.parent === c.id) {
              currentCategory = c;
              break;
            }
          }
        } else {
          currentCategory = c;
          break;
        }
      }
    }
    this.setState({ currentCategory });
  }

  addOrderProductToMenu(orderProduct) {
    if (!orderProduct) return;

    let orderProducts = [];
    //we need deep copy here
    for (let orderProduct1 of this.state.orderProducts) orderProducts.push({ ...orderProduct1 });

    let found = false;
    for (let op of orderProducts) {
      if (CartStorage.isSameOrderProduct(orderProduct, op)) {
        found = true;
        op.qty += orderProduct.qty;
        break;
      }
    }
    if (!found) orderProducts.unshift(orderProduct);

    this.setState({ orderProducts });
    Toast.showToast("Hozzáadva");
    this.nextCategory(this.state.categories);
  }

  selectProduct(product: Product) {
    if (!product)
      return;
    if (product.extraGroups.length > 0 || product.versions.length > 0) {
      this.setState({ sentForExtrasSelection: product });
    } else {
      let leadOP = Cart.getLeadOrderProduct(JSON.parse(JSON.stringify(product.orderProduct)));
      leadOP.qty = 1;
      this.addOrderProductToMenu(leadOP);
    }
  }

  static getOrderProductExtrasPrice(orderProduct: OrderProduct, product: Product) {
    if (!product || !orderProduct)
      return 0;

    let totalExtrasPrice = 0;
    for (let v of product.versions) {
      if (v.id === orderProduct.versionID) {
        totalExtrasPrice += v.price;
        break;
      }
    }

    for (let eg of product.extraGroups) {
      if (eg.versionID !== orderProduct.versionID)
        continue;

      let freeCount = 0;
      for (let oe of orderProduct.extras) {
        if (oe.extraGroupID !== eg.id)
          continue;

        let freeLeft = eg.freeQty - freeCount;
        if (freeLeft > oe.qty) {
          freeCount += oe.qty;
        } else {
          freeCount += freeLeft;
          totalExtrasPrice += (oe.qty - freeLeft) * oe.price;
        }
      }
    }

    return totalExtrasPrice;
  }

  removeOrderProduct(orderProduct) {
    let orderProducts = [];
    //we need deep copy here
    for (let orderProduct1 of this.state.orderProducts) orderProducts.push({ ...orderProduct1 });

    for (let i = 0; i < orderProducts.length; i++) {
      let op = orderProducts[i];
      if (CartStorage.isSameOrderProduct(op, orderProduct)) {
        orderProducts.splice(i, 1);
        break;
      }
    }
    this.setState({ orderProducts });
  }

  addToCart() {
    if (!this.state.orderProducts || this.state.orderProducts.length <= 0) return;

    if (this.state.orderProducts.length === 1) {
      //ADD ONE PRODUCT
      let product = null;
      for (let p of this.state.products) {
        if (p.id === this.state.orderProducts[0].id) {
          product = JSON.parse(JSON.stringify(p));
          product.orderProduct = [];
          product.orderProduct.push(JSON.parse(JSON.stringify(this.state.orderProducts[0])));
          break;
        }
      }
      if (!product) return;
      EventSystem.publish(EventSystem.events.addCombinedMenu, product);
      CartStorage.addProduct(product);
    } else {
      //ADD MENU
      let menu = null;

      for (let product of this.state.products) {
        if (product.id === this.state.orderProducts[0].id) {
          menu = JSON.parse(JSON.stringify(product));
          break;
        }
      }
      if (!menu) return;
      menu.availableExtras = [];
      menu.productExtras = [];
      menu.id = CartStorage.getNextMenuID();
      menu.categoryID = 0;
      menu.products = [];
      menu.versions = [];
      menu.extraGroups = [];
      menu.type = ProductTypes.MENU;
      menu.qty = 0;
      menu.image = "";
      menu.image2 = "";
      menu.image3 = "";
      menu.name = "Kombinált menü";
      menu.details = "";

      let leadOP = menu.orderProduct[0];
      leadOP.name = "Kombinált menü";
      leadOP.details = "";
      leadOP.image = "";
      leadOP.image2 = "";
      leadOP.image3 = "";
      leadOP.price = 0;
      leadOP.qty = 1;
      leadOP.type = ProductTypes.MENU;
      leadOP.productID = menu.id;

      menu.orderProduct = [];

      let price = 0;
      for (let orderProduct of this.state.orderProducts) {
        let op = JSON.parse(JSON.stringify(orderProduct));
        op.menuID = menu.id;

        for (let product of this.state.products) {
          if (product.id === orderProduct.id) {
            let p = JSON.parse(JSON.stringify(product));
            price += op.qty * (p.price + MenuCombiner.getOrderProductExtrasPrice(op, p));
            p.qty = op.qty;
            p.price = 0;
            p.orderProduct = [op];
            menu.products.push(p);
            menu.orderProduct.push(op);
            break;
          }
        }
      }
      leadOP.price = price;
      menu.orderProduct.push(leadOP);

      EventSystem.publish(EventSystem.events.addCombinedMenu, menu);
      CartStorage.addMenu(menu);
    }
    this.setState({ orderProducts: [] });
  }

  getMenuTotalPrice() {
    let total = 0;
    for (let op of this.state.orderProducts) {
      total += op.qty * (op.price + MenuCombiner.getOrderProductExtrasPrice(op, this.getProductByID(op.productID)));
    }
    return total;
  }

  toggleClicked(product) {
    if (this.state.clickedProductID === product.id) {
      this.setState({ clickedProductID: -1 });
    } else {
      this.setState({ clickedProductID: product.id });
    }
  }

  handleProductClick(product) {
    this.toggleClicked(product);
    this.scrollProductsTo(product);
  }

  scrollProductsTo(product) {
    let itemsShown = 0;
    let indexOfProduct = -1;
    for (let p of this.state.products) {
      if (p.categoryID === this.state.currentCategory.id) {
        if (p.id === product.id) indexOfProduct = itemsShown;
        itemsShown++;
      }
    }

    let itemsOnOneScreen = this.state.mobile ? 4 : 8;
    let allScreenScrollsNumber = Math.floor(indexOfProduct / itemsOnOneScreen);
    let remainder = indexOfProduct % itemsOnOneScreen;

    let width = this.listWrapperRef.offsetWidth;
    let margin = this.state.mobile ? 5 : 20;

    let remainderOffset = 0;
    if (itemsOnOneScreen === 4) {
      if (remainder === 1 || remainder === 2) remainderOffset = (0.9 * width) / 2;
      else if (remainder === 3) remainderOffset = 0.9 * width;
    } else if (itemsOnOneScreen === 8) {
      if (remainder === 1 || remainder === 2) remainderOffset = width / 4;
      else if (remainder === 3 || remainder === 4) remainderOffset = width / 2;
      else if (remainder === 5 || remainder === 6) remainderOffset = (3 * width) / 4;
      else if (remainder === 7) remainderOffset = width;
      remainderOffset /= 2;
    }

    let shouldScrollLeft = allScreenScrollsNumber * (width * 0.9 + 2 * margin) + remainderOffset;
    setTimeout(() => {
      if (this.listWrapperRef) {
        let actualScroll = this.listWrapperRef.scrollLeft;
        let easeInOutQuad = (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);

        this.animate({
          timing: easeInOutQuad,
          draw: (progress) => {
            this.listWrapperRef.scrollLeft = actualScroll + (shouldScrollLeft - actualScroll) * progress;
          },
          duration: 400
        });
      }
    }, 0);
  }

  animate({ timing, draw, duration }) {
    let start = performance.now();

    requestAnimationFrame(function animate(time) {
      // timeFraction goes from 0 to 1
      let timeFraction = (time - start) / duration;
      if (timeFraction > 1) timeFraction = 1;

      // calculate the current animation state
      let progress = timing(timeFraction);

      draw(progress); // draw it

      if (timeFraction < 1) {
        requestAnimationFrame(animate);
      }
    });
  }

  getProductByID(productID) {
    for (let product of this.state.products) {
      if (product.id === productID) return product;
    }
    return null;
  }

  close() {
    if (this.state.closeCb) this.state.closeCb();
  }

  render() {
    let hasChildren = this.hasChildren(this.state.currentCategory, this.state.categories);
    // eslint-disable-next-line no-unused-vars
    //let isLastStep = this.isLastStep(this.state.currentCategory, this.state.categories);
    return (
      <Wrapper height={this.props.height} maxheight={this.props.maxheight} onTouchStart={(e) => e.stopPropagation()}>
        <Header>
          <h2>Rakd össze a sajád menüdet!</h2>
          <i className="fas fa-times" onClick={() => this.close()}/>
        </Header>
        <BigPanel>
          <CombiningSpace>
            {hasChildren && <span>Válassz kategóriát</span>}
            {!hasChildren &&
              <span>Válassz a {this.state.currentCategory && TranslatableString.get(this.state.currentCategory.name).toLowerCase()} kategóriából</span>}
            <ListWrapper holdscategories={hasChildren} ref={(r) => (this.listWrapperRef = r)}>
              {hasChildren &&
                this.state.categories.map((c: Category, i: number) => {
                  if (c.parent === this.state.currentCategory.id && this.hasProducts(this.state.products, c))
                    return (
                      <CategoryDiv onClick={() => this.selectCategory(c)} key={i}>
                        {TranslatableString.get(c.name)}
                      </CategoryDiv>
                    );
                  else return null;
                })}
              {!hasChildren &&
                this.state.products.map((p: Product, i: number) => {
                  if (p.categoryID === this.state.currentCategory?.id && p.active === true) {
                    return (
                      <ProductDiv
                        onClick={() =>
                          EventSystem.publish(EventSystem.events.popup_product, {
                            product: p,
                            addCb: (p) => this.selectProduct(p)
                          })
                        }
                        clicked={this.state.clickedProductID === p.id}
                        key={i}
                      >
                        {(!p.image || p.image === "") && <i className="far fa-eye-slash"/>}
                        {p.image !== "" && <img src={Config.productImageUrlBase + p.image} alt={TranslatableString.get(p.name)}/>}
                        <span>
                          {this.state.clickedProductID === p.id &&
                            <i className="fas fa-compress" onClick={() => this.handleProductClick(p)}/>}
                          {this.state.clickedProductID !== p.id &&
                            <i className="fas fa-expand" onClick={() => this.handleProductClick(p)}/>}
                        </span>
                        <p>
                          {TranslatableString.get(p.name)}
                          {!this.state.mobile && <span>{TranslatableString.get(p.details)}</span>}
                          {!this.state.mobile && p.image && p.image !== "" &&
                            <img src={Config.productImageUrlBase + p.image} alt={TranslatableString.get(p.name)}/>}
                        </p>
                        <ProductPrice>{p.price}Ft</ProductPrice>
                        <AddProductToMenuButton
                          onClick={(e) => {
                            e.stopPropagation();
                            this.selectProduct(p);
                          }}
                        >
                          {(!this.state.mobile || (this.state.mobile && this.state.clickedProductID === p.id)) &&
                            <span>Hozzáadom a menühöz</span>}
                          {this.state.mobile && this.state.clickedProductID !== p.id && <i className="fas fa-plus"/>}
                        </AddProductToMenuButton>
                      </ProductDiv>
                    );
                  } else return null;
                })}
            </ListWrapper>
          </CombiningSpace>
          <CategoriesListWrapper>
            {this.state.categories &&
              this.state.categories.map((c, i) => {
                if (c.parent === 0)
                  return (
                    <MainCategoryLink
                      current={c === this.state.currentCategory || this.state.currentCategory.parent === c.id}
                      onClick={() => this.selectCategory(c)}
                      key={i}
                    >
                      <span>
                        <div/>
                      </span>
                      <div>{TranslatableString.get(c.name)}</div>
                    </MainCategoryLink>
                  );
                else return null;
              })}
          </CategoriesListWrapper>
        </BigPanel>
        <BottomPanel>
          <MenuContainer onTouchStart={(e) => e.stopPropagation()}>
            <p>Menü tartalma:</p>
            <div>
              {this.state.orderProducts.length <= 0 && (
                <OrderProductDiv>
                  <div>
                    <span>Még üres a kosár</span>
                  </div>
                </OrderProductDiv>
              )}
              {this.state.orderProducts.map((op, i) => {
                let product = this.getProductByID(op.productID);
                return (
                  <OrderProductDiv key={i}>
                    <div>
                      <span>
                        {TranslatableString.get(op.name)}({op.qty}db, {op.qty * (op.price + MenuCombiner.getOrderProductExtrasPrice(op, product))}
                        Ft
                        {op.extras.length > 0 ? ", " + op.extras.length + " féle feltét" : ""})
                      </span>
                      <i onClick={() => this.removeOrderProduct(op)} className="fas fa-times"/>
                    </div>
                  </OrderProductDiv>
                );
              })}
            </div>
            <p>Összesen: {this.getMenuTotalPrice()}Ft</p>
          </MenuContainer>
          <ButtonsWrapper>
            <Button valid={this.hasPrevStep(this.state.categories)} border={"none"}
                    onClick={() => this.previousCategory(this.state.categories)}>
              <i className="fas fa-chevron-left"/>
            </Button>
            <Button valid={true} validcolor={"var(--success)"} onClick={() => this.addToCart()}>
              Kosárba <i className="fas fa-shopping-bag"/>
            </Button>
            <Button valid={this.hasNextStep(this.state.categories)} border={"none"}
                    onClick={() => this.nextCategory(this.state.categories)}>
              <i className="fas fa-chevron-right"/>
            </Button>
          </ButtonsWrapper>
        </BottomPanel>
      </Wrapper>
    );
  }
}

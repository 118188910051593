import React, { Component } from "react";
import styled, { css } from "styled-components";
import EventSystem from "../../utils/EventSystem";
import ContextSystem from "../../utils/ContextSystem";
import { Address } from "../../model/Address";
import Language, { Names } from "../../utils/Language";
import { NonprofitOrganization } from "../../model/NonprofitOrganization";

const OuterWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 22;
  transform: translateY(100%);
  transition: transform 500ms ease-in-out, background-color 400ms ease-in-out;
  //backdrop-filter 100ms ease-in-out 500ms;

  ${({ opened }) => opened === true && css`
    transform: translateY(0);
    backdrop-filter: blur(2px);
    background-color: ${({ bgcolor }) => (bgcolor ? bgcolor : "rgba(0,0,0,0.20)")};
  `};

  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 800px) {
    align-items: flex-end;
    justify-content: flex-end;
  }
`;

const Wrapper = styled.div`
  width: 500px;
  background-color: rgb(40, 40, 40);
  padding: 20px;
  border-radius: 10px;
  min-height: 50vh;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: auto;

  & > h1 {
    font-family: var(--blundee_font_medium);
    font-size: 16pt;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
    justify-content: flex-start;
  }
`;

const NonprofitOrganizations = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 800px) {
    justify-content: center;
  }
`;

const NonprofitOrganizationDiv = styled.button`
  width: 80%;
  margin: 8px 1px;
  border-radius: 4px;
  padding: 10px 10px;
  background-color: rgb(40, 40, 40);
  color: #e3e3e3;
  border: none;
  box-shadow: 0 0 2px 1px #e3e3e3;
  font-size: 12pt;
  outline: none;
  transition: background-color 150ms ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: rgb(73, 73, 73);
  }

  &:focus {
    outline: none;
  }

  ${({ showmorebutton }) => showmorebutton === true && css`
    border: none;
    font-size: 10pt;
    box-shadow: none;

    &:hover {
      background-color: rgb(40, 40, 40);
    }
  `}
`;

export default class DonationSelectorPopup extends Component {
  timeOut = undefined;
  cb = undefined;

  state: {
    opened: boolean,
    address: Address | null,
    language: number,
    nonprofitOrganizations: NonprofitOrganization[],
    min: number
  } = {
    opened: false,
    address: null,
    language: ContextSystem.language,
    nonprofitOrganizations: ContextSystem.nonprofitOrganizations,
    min: 0
  };

  close() {
    this.setState({ opened: false, address: null });
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", (e) => {
      if (this.state.opened && e.key === "Escape") {
        this.close();
      }
    });
  }

  componentDidMount() {
    this.setState({
      nonprofitOrganizations: ContextSystem.nonprofitOrganizations
    });

    EventSystem.subscribe(EventSystem.events.open_donation_selector, ({ cb }) => {
      if (!cb)
        return;
      this.cb = cb;
      this.setState({
        nonprofitOrganizations: ContextSystem.nonprofitOrganizations
      });

      if (this.timeOut)
        clearTimeout(this.timeOut);

      this.setState({ opened: true });
    });

    EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ language, nonprofitOrganizations }) => {
      if (language !== undefined)
        this.setState({ language });
      if (nonprofitOrganizations !== undefined)
        this.setState({ nonprofitOrganizations });
    });

    document.addEventListener("keydown", (e) => {
      if (this.state.opened && e.key === "Escape") {
        this.close();
      }
    });
  }

  selectOrganization(n: NonprofitOrganization) {
    if (this.cb)
      this.cb(n);
    this.close();
  }

  next() {
    let min = this.state.min;
    min += 3;
    if (min >= this.state.nonprofitOrganizations.length)
      min = 0;

    this.setState({ min });
  }

  render() {
    return (
      <OuterWrapper opened={this.state.opened} onClick={() => this.close()}>
        {this.state.opened &&
        <Wrapper onClick={(e) => e.stopPropagation()}>
          <h1>{Language.getName(Names.DonationPopupTitle)}</h1>
          <p>{Language.getName(Names.DonationPopupDetails)}</p>
          <NonprofitOrganizations>
            {this.state.nonprofitOrganizations?.map((n, i) => {
              if (i >= this.state.min + 3 || i < this.state.min)
                return <React.Fragment key={i}/>;

              return (
                <NonprofitOrganizationDiv key={i} onClick={() => this.selectOrganization(n)}>
                  {n.name}
                </NonprofitOrganizationDiv>
              );
            })}
            <NonprofitOrganizationDiv showmorebutton={true} onClick={() => this.next()}>
              {Language.getName(Names.ShowMore)}...
            </NonprofitOrganizationDiv>
          </NonprofitOrganizations>
        </Wrapper>
        }
      </OuterWrapper>
    );
  }
}

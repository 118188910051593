import styled, { css } from "styled-components";

const sharedInputStyle = css`
  width: ${({ width }) => (width ? width : "70%")};
  padding: 18px 20px;
  margin: 6px 0;
  outline: none;
  border: none;
  background-color: var(--blundee_input_bg_1);
  color: var(--blundee_color_normal);
  border-radius: 4px;
  font-family: inherit;
  font-size: 11pt;

  @media screen and (max-width: 600px) {
    width: ${({ mwidth }) => (mwidth ? mwidth : "100%")};
  }

  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }

  &::placeholder {
    color: var(--blundee_color_thin);
  }
`;

export const Input = styled.input`
  ${sharedInputStyle};
`;

export const TextArea = styled.textarea`
  ${sharedInputStyle};
  padding: 6px 20px;
  min-width: ${({ width }) => (width ? width : "70%")};
  @media screen and (max-width: 600px) {
    min-width: ${({ mwidth }) => (mwidth ? mwidth : "100%")};
  }
`;

export const BlundeeButton = styled.button`
  border: none;
  outline: none;

  background-color: var(--blundee_button_bg_color);
  margin: 0 15px;
  padding: 12px 35px;
  font-family: var(--blundee_font_medium);
  color: var(--blundee_color_normal);
  text-decoration: none;

  font-size: 14pt;
  font-weight: bold;

  border-radius: 400px;

  transition: filter 100ms ease-in-out, background-color 100ms ease-in-out, color 100ms ease-in-out, box-shadow 100ms ease-in-out;
  user-select: none;
  user-focus: all;

  &:hover {
    text-decoration: none;
    box-shadow: var(--blundee_button_shadow);
    filter: brightness(98%);
    cursor: pointer;
  }

  &:active {
    text-decoration: none;
    box-shadow: var(--blundee_button_shadow);
    filter: brightness(99%);
    cursor: pointer;
  }

  @media screen and (max-width: 600px) {
    padding: 12px 20px;
    font-size: 12pt;
    margin: 0 7px;
  }
`;

export const Button = styled.button`
  outline: none;
  background-color: transparent;

  user-select: none;
  border: 1px solid ${({ validcolor }) => (validcolor ? validcolor : "var(--blundee_button_bg_color)")};
  border-radius: 5px;
  padding: 8px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-size: 12pt;
  font-family: var(--blundee_font_bold);
  text-align: center;
  transition: filter 200ms ease-in-out, height 0.2s ease-in-out, background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  color: var(--blundee_color_2);
  font-weight: bold;
  cursor: pointer;
  
  &:hover {
    filter: brightness(95%);
  }

  &:active {
    filter: brightness(98%);
  }

  ${({ margin }) => margin && css`
    margin: ${margin};
  `};

  @media screen and (max-width: 800px) {
    width: 90%;
    min-width: 200px;
  }

  @media screen and (max-width: 600px) {
    margin: 0;
    font-size: 12pt;
  }

  ${({ valid }) => valid && css`
    background-color: ${({ validcolor }) => (validcolor ? validcolor : "white")};
  `};

  ${({ border }) => border && css`
    border: ${border};
  `};
`;

export const ScheduleIconTimerWrapper = styled.div`
  margin: 0;
  padding: 0;
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  ${({ absolute }) => absolute === true && css`
    position: absolute;
    right: -5px;
    top: 0;
  `}
  background-color: #ffef42;
  border-radius: 100px;

  & > svg {
    font-size: 11pt;
    color: #444440;
  }
`;

export const SmallButton = styled.button`
  user-select: none;
  outline: none;
  border: none;

  margin-left: 5px;
  padding: 6px 8px;
  font-size: 10pt;
  background-color: rgb(120, 120, 120);
  color: rgb(230, 230, 230);
  border-radius: 4px;

  transition: background-color 100ms ease-in-out, color 100ms ease-in-out;
  cursor: pointer;

  &:hover {
    color: white;
    background-color: rgb(90, 90, 90);
  }

  &:active {
    color: rgb(220, 220, 220);
    background-color: rgb(70, 70, 70);
  }
`;

import React from "react";
import styled, { css } from "styled-components";
import { AuthAPI } from "../../utils/api/AuthAPI";
import SavedAddressPicker from "../../components/addresspicker/SavedAddressPicker";
import AddressPicker from "../../components/addresspicker/AddressPicker";
import { Input, BlundeeButton } from "../../components/Form";
import EventSystem from "../../utils/EventSystem";
import { AddressAPI } from "../../utils/api/AddressAPI";
import ContextSystem from "../../utils/ContextSystem";
import { Helmet } from "react-helmet/es/Helmet";
import { FaMapMarkerAlt } from "react-icons/fa";
import welcomeScreen_bg_mobile from "../../assets/welcomeScreenPics/welcomeScreen_bg_mobile2.png";
import welcomeScreen_bg_mobile_web from "../../assets/welcomeScreenPics/welcomeScreen_bg_mobile.png";
import welcomeScreen_bg from "../../assets/welcomeScreenPics/welcomeScreen_bg.png";
import RomanToArabicNumberConverter from "../../utils/RomanToArabicNumberConverter";
import Config from "../../utils/Config";
import { Profile } from "../../model/Profile";
import { Address, City } from "../../model/Address";
import Language, { Names } from "../../utils/Language";
import { TranslatableString } from "../../model/Product";

const FoodImageBG = styled.img`
  position: absolute;
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  left: ${({ left }) => left};
  width: ${({ width }) => width};
  //z-index: -10;

  @media screen and (max-width: 800px) {
    top: ${({ topM }) => topM};
    right: ${({ rightM }) => rightM};
    left: ${({ leftM }) => leftM};
    width: ${({ widthM }) => widthM};
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;

  padding: 300px 6% 10px 6%;

  & > h1 {
    font-family: var(--blundee_font_medium);
    color: white;
    font-size: 35pt;
    font-weight: 600;
    //letter-spacing: 0.27rem;
    margin: 0 0 10px 0;
  }

  & > h2 {
    font-family: var(--blundee_font_medium);
    color: white;
    font-size: 19pt;
    font-weight: 400;
    //letter-spacing: 0.1rem;
    margin-bottom: 26px;
  }

  @media screen and (max-width: 600px) {
    padding: 130px 5% 2px 5%;
    & > h1 {
      font-size: 25pt;
      //letter-spacing: 0.2rem;
      margin-top: 3px;
      margin-bottom: 5px;
    }

    & > h2 {
      font-size: 17pt;
      //letter-spacing: 0.06rem;
      margin-bottom: 2px;
      margin-top: 30px;
      max-width: 65%;
    }
  }
`;

const AuthButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
  padding: 0 10px;
`;

const AddressesWrapper = styled.div`
  margin-top: 20px;

  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  h3 {
    font-size: 17pt;
    font-family: var(--blundee_font_medium);
    font-weight: 600;
  }

  @media screen and (max-width: 600px) {
    margin-top: 10px;
    flex-wrap: wrap;
    h3 {
      font-size: 17pt;
      font-weight: 500;
    }
  }
`;

const LocationPickerWrapper = styled.div`
  width: 37%;
  padding: 10px 25px 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-right: 1px solid rgb(40, 40, 40);
  position: relative;

  & > svg {
    font-size: 70pt;
    color: rgba(255, 255, 255, 0.07);
    position: absolute;
    top: -23pt;
    left: -45px;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
    border: none;
    padding: 10px 0 0 0;
    & > h3 {
      margin: 3px 0;
    }

    & > svg {
      top: 0;
      right: -10px;
      left: unset;
    }
  }
`;

const CitySelector = styled.div`
  width: 63%;
  padding: 10px 0 0 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  @media screen and (max-width: 600px) {
    width: 100%;
    padding: 5px 0 0 0;
  }
`;

const CitiesAndDistricts = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  h4 {
    font-weight: normal;
  }
`;

const Cities = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;

  newline {
    width: 100%;
    height: 0;
    background-color: transparent;
  }

  & > h4 {
    width: fit-content;
    margin: 0 20px 0 0;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
    margin: 0 0 20px 0;

    & > h4 {
      margin-right: 10px;
      font-size: 15pt;
    }
  }
`;

const NewRow = styled.div`
  width: 100%;
  height: 0;
  background-color: transparent;
`;

const BudapestDistricts = styled.div`
  width: 100%;
  margin: 10px 5px;

  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;

  & > h4 {
    width: 100%;
    margin: 0 5px;
    font-weight: normal;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
    margin: 0 0 20px 0;

    & > h4 {
      margin-right: 10px;
      font-size: 15pt;
    }
  }
`;

const District = styled.div`
  margin: 10px 5px;
  padding: 4px 6px;
  background-color: rgb(40, 40, 40);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: rgb(180, 180, 180);
  height: 45px;
  min-width: 45px;
  user-select: none;

  transition: background-color 100ms ease-in-out, color 100ms ease-in-out;

  &:hover {
    background-color: rgb(80, 80, 80);
    color: white;
    cursor: pointer;
  }

  &:active {
    background-color: rgb(100, 100, 100);
    color: rgb(240, 240, 240);
    cursor: pointer;
  }
`;

const CityDiv = styled(District)`
  width: fit-content;
  padding: 5px 8px;
  overflow: hidden;
  user-select: none;

  ${({ show }) => show === false && css`
    display: none;
  `}
`;

const CitySearcher = styled(Input)`
  min-width: 150px;
  max-width: 250px;
  flex-grow: 0;
  font-size: 10pt;
  font-family: var(--blundee_font_medium);
  background-color: rgb(40, 40, 40);
  margin: 0;
  padding: 10px 10px 10px 15px;
  border: none;
  border-radius: 4px;
  color: rgb(180, 180, 180);

  &:focus {
    border: none;
    box-shadow: none;
  }

  @media screen and (max-width: 800px) {
    width: 0;
  }
`;

const LegalWrapper = styled.div`
  width: 100%;
  margin: 30px 0 5px 0;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-size: 10pt;
`;

const SavedAddressesWrapper = styled.div`
  width: 80%;
  margin: 19px auto;

  @media screen and (max-width: 800px) {
    width: 100%;
    margin: 12px 0;
  }
`;

export default class WelcomeScreen extends React.Component {
  eventIDs = [];

  state: {
    profile: Profile,
    cities: City[],
    cityFilter: string,
    selectedAddress: Address | null | undefined,
    selectedCity: City | null | undefined,
    addressPickerID: number,
    language: number,
  } = {
    profile: ContextSystem.profile,
    cities: ContextSystem.cities,
    cityFilter: "",
    selectedAddress: undefined,
    selectedCity: undefined,
    addressPickerID: ContextSystem.getNextUniqueID(),
    language: ContextSystem.language
  };

  componentWillUnmount() {
    for (let eventID of this.eventIDs) {
      EventSystem.unsubscribe(eventID);
    }
    this.eventIDs = [];
  }

  componentDidMount() {
    EventSystem.publish(EventSystem.events.redirectIntent, {
      queryParams: { kategoriak: undefined, q: undefined }
    });

    AuthAPI.checkLogin(true, (authenticated, profile) => {
      this.setState({ profile: authenticated === true ? profile : undefined });
    });

    let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ cities, language }) => {
      if (language !== undefined) {
        this.setState({ language });
      }
      if (!cities /*|| !zipcodes*/)
        return;
      this.setState({
        cities: ContextSystem.cities
        // zipcodes: ContextSystem.zipcodes
      });
    });
    this.eventIDs.push(eid);

    eid = EventSystem.subscribe(EventSystem.events.addressPicked, ({ selectedAddress, callerID }) => {
      if (callerID !== this.state.addressPickerID) return;
      this.saveAddress(selectedAddress);
    });
    this.eventIDs.push(eid);

    eid = EventSystem.subscribe(EventSystem.events.authentication_changed, (auth) => {
      if (auth) {
        AuthAPI.checkLogin(true, (loggedIn, profile) => {
          this.setState({ profile });
        });
      } else {
        this.setState({ profile: undefined });
      }
    });
    this.eventIDs.push(eid);
  }

  removeDistrictFromName(name) {
    let a = name.match(/([IXV]+\.) kerület/);
    if(a && a.length > 0)
      return a[1];
    return name;
  }

  handleChangeCityFilter(filter) {
    this.setState({ cityFilter: filter });
  }

  static convertString(phrase) {
    let maxLength = phrase.length;

    let returnString = phrase.toLowerCase();
    //Convert Characters
    returnString = returnString.replace(/ö/g, "o");
    returnString = returnString.replace(/ü/g, "u");
    returnString = returnString.replace(/ó/g, "o");
    returnString = returnString.replace(/ő/g, "o");
    returnString = returnString.replace(/ú/g, "u");
    returnString = returnString.replace(/é/g, "e");
    returnString = returnString.replace(/á/g, "a");
    returnString = returnString.replace(/ű/g, "u");
    returnString = returnString.replace(/í/g, "i");
    returnString = returnString.replace(/ä/g, "a");
    returnString = returnString.replace(/à/g, "a");
    returnString = returnString.replace(/â/g, "a");
    returnString = returnString.replace(/è/g, "e");
    returnString = returnString.replace(/ê/g, "e");
    returnString = returnString.replace(/ë/g, "e");
    returnString = returnString.replace(/ôš/g, "o");

    // if there are other invalid chars, convert them into blank spaces
    returnString = returnString.replace(/[^a-z0-9\s-]/g, "");
    // convert multiple spaces and hyphens into one space
    returnString = returnString.replace(/[\s-]+/g, " ");
    // trims current string
    returnString = returnString.replace(/^\s+|\s+$/g, "");
    // cuts string (if too long)
    if (returnString.length > maxLength) returnString = returnString.substring(0, maxLength);
    // add hyphens
    returnString = returnString.replace(/\s/g, "-");

    return returnString;
  }

  saveAddress(selectedAddress) {
    AddressAPI.saveAddress(selectedAddress, (res) => {
      this.setState({ selectedAddress: res.address });
      EventSystem.publish(EventSystem.events.addressSelected, { address: res.address });
    });
  }

  handleCitySelected(city) {
    EventSystem.publish(EventSystem.events.citySelected, { city });
  }

  handleLoginClicked() {
    EventSystem.publish(EventSystem.events.open_login_popup);
  }

  handleRegisterClicked() {
    EventSystem.publish(EventSystem.events.open_register_popup);
  }

  render() {
    this.state.cities.sort((a, b) => {
      if (a.isBudapest && !b.isBudapest) {
        return -1;
      } else if (a.isBudapest && b.isBudapest) {
        let aValue = RomanToArabicNumberConverter.romanToArabic(TranslatableString.get(a.name).split(".")[0]);
        let bValue = RomanToArabicNumberConverter.romanToArabic(TranslatableString.get(b.name).split(".")[0]);
        return aValue - bValue;
      } else {
        return TranslatableString.get(a.name).localeCompare(TranslatableString.get(b.name));
      }
    });
    return (
      <>
        <Helmet>
          <title>{Language.getName(Names.MenuHome)}</title>
          <meta name="description" content="Blundee.com"/>
        </Helmet>
        <Wrapper>
          <FoodImageBG
            src={Config.mobile ? welcomeScreen_bg_mobile : welcomeScreen_bg_mobile_web}
            alt={""}
            width={"0"}
            top={"0"}
            left={"0"}
            right={"0"}
            widthM={"100%"}
            topM={"0"}
            leftM={"0"}
            rightM={"0"}
          />
          <FoodImageBG
            src={welcomeScreen_bg}
            alt={""}
            width={"100%"}
            top={"0"}
            left={"0"}
            right={"0"}
            widthM={"0"}
            topM={"0"}
            leftM={"0"}
            rightM={"0"}
          />
          <h1>
            <>
              {Language.getName(Names.WelcomeScreenGreetings1)}
              {this.state.profile && this.state.profile.firstName !== "" && (
                <>
                  <br/>
                  {this.state.profile.firstName}
                </>
              )}
              {Language.getName(Names.WelcomeScreenGreetings2)}
            </>
          </h1>
          {this.state.profile && (
            <>
              <h2>{Language.getName(Names.WelcomeScreenText1)}</h2>
              <SavedAddressesWrapper>
                <SavedAddressPicker/>
              </SavedAddressesWrapper>
            </>
          )}
          {!this.state.profile && (
            <>
              <h2>{Language.getName(Names.WelcomeScreenText2)}</h2>
              <AuthButtonsWrapper>
                <BlundeeButton onClick={() => this.handleLoginClicked()}>{Language.getName(Names.SignIn)}</BlundeeButton>
                <BlundeeButton
                  onClick={() => this.handleRegisterClicked()}>{Language.getName(Names.RegisterButton)}</BlundeeButton>
              </AuthButtonsWrapper>
            </>
          )}
          <AddressesWrapper>
            <LocationPickerWrapper>
              <FaMapMarkerAlt/>
              <h3>{Language.getName(Names.WelcomeScreenAddressText1)}</h3>
              <AddressPicker callerID={this.state.addressPickerID}/>
            </LocationPickerWrapper>
            <CitySelector>
              <h3>{Language.getName(Names.WelcomeScreenAddressText2)}</h3>
              {this.state.cities && (
                <CitiesAndDistricts>
                  <BudapestDistricts>
                    <h4>{Language.getName(Names.Budapest)}</h4>
                    <NewRow/>
                    {this.state.cities.map((c, i) => {
                      if (c.isBudapest)
                        return (
                          <District onClick={() => this.handleCitySelected(c)} key={i}>
                            {this.removeDistrictFromName(TranslatableString.get(c.name))}
                          </District>
                        );
                      else return <React.Fragment key={i}/>;
                    })}
                  </BudapestDistricts>
                </CitiesAndDistricts>
              )}
            </CitySelector>
          </AddressesWrapper>
          <Cities>
            <h4>{Language.getName(Names.Cities)}</h4>
            <CitySearcher
              value={this.state.cityFilter}
              placeholder={Language.getName(Names.SearchCity) + "..."}
              autoComplete={"off"}
              type={"text"}
              onChange={(e) => this.handleChangeCityFilter(e.target.value)}
            />
            <NewRow/>

            {this.state.cities.map((c, i) => {
              if (!c.isBudapest)
                return (
                  <CityDiv
                    key={i}
                    onClick={() => this.handleCitySelected(c)}
                    show={WelcomeScreen.convertString(TranslatableString.get(c.name).toLowerCase()).includes(WelcomeScreen.convertString(this.state.cityFilter.toLowerCase()))}
                  >
                    {TranslatableString.get(c.name)}
                  </CityDiv>
                );
              else
                return <React.Fragment key={i}/>;
            })}
          </Cities>
          <LegalWrapper>
            {Language.getName(Names.WelcomeScreenASZF)}
          </LegalWrapper>
        </Wrapper>
      </>
    );
  }
}

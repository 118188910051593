import APICaller from "../APICaller";

function getAll(quite, cb) {
  APICaller.ownFetch(quite, "POST", "/categories", {}, (data) => {
    handleCategoriesFetch(data, cb);
  });
}

function getFavouriteCategories(quite, cb) {
  APICaller.ownFetch(quite, "PUT", "/categories", {}, (data) => {
    handleCategoriesFetch(data, cb);
  });
}

function handleCategoriesFetch(data, cb) {
  let res = JSON.parse(data);
  if (cb)
    cb(res);
}

export default {
  getAll,
  getFavouriteCategories,
};

import React, {Component} from "react";
import styled from "styled-components";
import Config from "../../utils/Config";
import {FaEyeSlash, FaTimes} from "react-icons/fa";
import {Product, ProductTypes, TranslatableString} from "../../model/Product";
import ContextSystem from "../../utils/ContextSystem";
import EventSystem from "../../utils/EventSystem";
import Language, {Names} from "../../utils/Language";
import ExtraSelector from "../ExtraSelector";
import Cart from "../cart/Cart";
import MenuCombiner from "../MenuCombiner";

const ProductWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: var(--blundee_popup_shadow);
  background-color: var(--blundee_background_card_3);
  color: var(--blundee_color_normal);
`;

const CloseButtonWrapper = styled.div`
  justify-self: flex-end;

  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  left: 0;
`;

const CloseButton = styled.button`
  border: none;
  outline: none;

  transition: background-color 100ms ease-in-out, color 100ms ease-in-out;
  padding: 5px;
  margin: 12px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: var(--blundee_color_normal);

  background-color: rgba(250, 250, 250, 0.7);
  border-radius: 600px;
  cursor: pointer;

  &:active {
    filter: brightness(98%);
  }

  &:hover {
    filter: brightness(95%);
  }

  & > svg {
    font-size: 16pt;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  text-align: center;
  height: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  & > img {
    width: 100%;
    height: auto;
  }

  & > svg {
    font-size: 25pt;
    color: #acacac;
    padding: 20px 0 0 0;
  }

  & > span {
    font-family: var(--blundee_font_medium);
    font-size: 15pt;
    color: #acacac;
    padding: 0 0 20px 0;
  }
`;

const Title = styled.div`
  width: calc(100% - 12px);
  font-family: var(--blundee_font_bold);
  font-size: 15pt;
  margin: 24px 6px 12px 6px;
  text-align: center;
`;

const Details = styled.div`
  font-family: var(--blundee_font_medium);
  color: var(--blundee_color_thin);
  width: calc(100% - 24px);
  font-size: 12pt;
  margin: 12px;
  text-align: left;
`;
const OppositPanel = styled.div`
  width: 100%;
  margin-top: auto;
  margin-left: auto;
  min-height: 60px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const Price = styled.div`
  width: 100%;
  padding: 6px 0;
  text-align: center;
  font-family: var(--blundee_font_bold);
  font-size: 14pt;
`;

const AddButton = styled.div`
  width: 75%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
  margin-top: auto;
  background-color: var(--blundee_button_bg_color);
  color: var(--blundee_color_normal);
  font-size: 15pt;
  font-family: var(--blundee_font_bold);

  position: fixed;
  bottom: 24px;
  border-radius: 600px;
  box-shadow: var(--blundee_popup_shadow);

  transition: filter 200ms ease-in-out, background-color 100ms ease-in-out;
  cursor: pointer;

  &:hover {
    filter: brightness(95%);
  }

  &:active {
    filter: brightness(98%);
  }

  & > svg {
    font-size: 15pt;
  }
`;

export default class FullProduct extends Component {
  state: {
    product: Product | undefined,
    addCb: ()=>{} | undefined,
    closeCb: ()=>{} | undefined,
    showAddButton: boolean,
    showCloseButton: boolean,
    selectedSliderImg: number,
    language: number,
  } = {
    product: undefined,
    addCb: undefined,
    closeCb: undefined,
    showAddButton: true,
    showCloseButton: true,
    selectedSliderImg: 0,
    language: ContextSystem.language
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.product !== this.props.product)
      this.setState({product: this.props.product});
    if (this.state.addCb !== this.props.addCb)
      this.setState({addCb: this.props.addCb});
    if (this.state.showAddButton !== this.props.showAddButton)
      this.setState({showAddButton: this.props.showAddButton});
    if (this.state.showCloseButton !== this.props.showCloseButton)
      this.setState({showCloseButton: this.props.showCloseButton});
    if (this.state.closeCb !== this.props.closeCb)
      this.setState({closeCb: this.props.closeCb});
  }

  componentDidMount() {
    this.setState({
      product: this.props.product
    });

    EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({language}) => {
      if (language !== undefined) {
        this.setState({language});
      }
    });
  }

  handleClose() {
    if (this.state.closeCb)
      this.state.closeCb();
  }

  render() {
    if (!this.state.product)
      return <></>;

    let price: number = this.state.product?.price ?? 0;
    let leadOP = Cart.getLeadOrderProduct(this.state.product?.orderProduct);

    if(!!leadOP){
      price += MenuCombiner.getOrderProductExtrasPrice(leadOP, this.state.product);
    }

    return (
      <ProductWrapper>
        <CloseButtonWrapper>
          <CloseButton onClick={() => this.handleClose()}>
            <FaTimes/>
          </CloseButton>
        </CloseButtonWrapper>
        {(!this.state.product.image || this.state.product.image === "") && (
          <ImageWrapper>
            <FaEyeSlash/>
            <span>{Language.getName(Names.NoImageUploaded)}</span>
          </ImageWrapper>
        )}
        {this.state.product.image !== "" && (
          <ImageWrapper>
            <img src={Config.productImageUrlBase + this.state.product.image} alt={"kép"}/>
          </ImageWrapper>
        )}
        <Title>{TranslatableString.get(this.state.product.name)}</Title>
        <Details>
          {TranslatableString.get(this.state.product.details)}
          {this.state.product.type === ProductTypes.MENU && (
            <p>
              {this.state.product && this.state.product.products && this.state.product.products.length > 0 &&
                <>
                  <b>{Language.getName(Names.ContentOfMenu)}:</b>
                  {this.state.product.products.map((product, i) => {
                    return (
                      <div key={i}>
                        {product.qty + Language.getName(Names.pcs) + " " + TranslatableString.get(product.name)}
                      </div>
                    );
                  })
                  }
                </>
              }
            </p>
          )}
        </Details>
        <ExtraSelector product={this.state.product} onSelectionChanged={(p: Product) => this.setState({product: p})}/>
        <OppositPanel>
          {this.state.showAddButton && (
            <AddButton onClick={(e) => this.handleAddButtonClicked(e)}>
              <Price>
                {Language.getName(Names.AddToCart) + " ("}
                {price}
                {"Ft)"}
              </Price>
            </AddButton>
          )}
        </OppositPanel>
      </ProductWrapper>
    );
  }

  handleAddButtonClicked() {
    if (this.state.addCb)
      this.state.addCb(this.state.product);
  }
}

import Config from "./Config";
import ErrorMessages from "./api/ErrorMessages";
import { AuthAPI } from "./api/AuthAPI";
import Analytics from "../components/Analytics";
import Toast from "../components/Toast";
import Language, { Names } from "./Language";
import ContextSystem from "./ContextSystem";

let lastServerIsNotAvailableToast;

async function ownFetch(quite, method, url, params, callback) {
  //console.log(Config.APIBaseURL + url + "::" + method + " called");

  let init = {
    method: method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json; charset=UTF-8"
    },
    credentials: "include",
    withCredentials: true
  };
  if (window.localStorage.getItem("usertoken") != null) {
    params.usertoken = window.localStorage.getItem("usertoken");
  }

  if (method !== "GET") {
    init.body = JSON.stringify(params);
  }

  return fetch(Config.APIBaseURL + url, init)
    .then((res) => {
      return res.text();
    })
    .then((res) => {
      try {
        let result = JSON.parse(res);
        if (!quite) {
          if (
            result.error === ErrorMessages.PARAMETER_NOT_VALID ||
            result.error === ErrorMessages.DATA_NOT_FOUND ||
            result.error === ErrorMessages.AMBIGUOUS ||
            result.error === ErrorMessages.UNEXPECTED_ERROR ||
            result.error === ErrorMessages.BODY_NOT_PARSABLE ||
            result.error === ErrorMessages.ILLEGAL_STATE ||
            result.error === ErrorMessages.TOO_FREQUENT_REQUESTS
          ) {
            Toast.showToast(Language.getName(Names.UnknownError));
          } else if (result.error === ErrorMessages.WRONG_EMAIL_OR_PASS) {
            Toast.showToast(Language.getName(Names.EmailOrPasswordError));
          } else if (result.error === ErrorMessages.PERMISSION_DENIED) {
            AuthAPI.reloadLoginDetails();
            Toast.showToast(Language.getName(Names.PermissionDenied));
          } else if (result.error === ErrorMessages.EMAIL_NOT_VALID) {
            Toast.showToast(Language.getName(Names.EmailAddressNotValid));
          } else if (result.error === ErrorMessages.TOKEN_NOT_VALID) {
            Toast.showToast(Language.getName(Names.PleaseLogIn));
          } else if (result.error === ErrorMessages.DATABASE_ERROR) {
            Toast.showToast(Language.getName(Names.DatabaseError));
          } else if (result.error === ErrorMessages.EMAIL_REGISTERED) {
            Toast.showToast(Language.getName(Names.EMAIL_REGISTERED));
          } else if (result.error === ErrorMessages.SHIPPING_PRICE_NOT_FOUND) {
            Toast.showToast(Language.getName(Names.SHIPPING_PRICE_NOT_FOUND));
          } else if (result.error === ErrorMessages.RESTAURANT_NOT_FOUND) {
            Toast.showToast(Language.getName(Names.RestaurantNotFound));
          } else if (result.error === ErrorMessages.RESTAURANT_CLOSED_FOR_TODAY) {
            Toast.showToast(Language.getName(Names.RESTAURANT_CLOSED_FOR_TODAY));
          } else if (result.error === ErrorMessages.EXTRA_QTY_OVER_MAX) {
            Toast.showToast("Az egyik rendelt ételedre túl sok feltétet választottál ki! Kérlek töltsd újra az oldalt és próbáld újra kosárba tenni az ételt.");
          } else if (result.error === ErrorMessages.RESTAURANT_CLOSED) {
            Toast.showToast("Az étterem nem fogad most új rendeléseket.");
          } else if (result.error === ErrorMessages.DOESNT_HIT_MINIMUM_ORDER) {
            Toast.showToast("Sajnos a minimum rendelési összeg alatt nem tudunk felvenni új rendeléseket. Adj hozzá néhány dolgot még a rendelésedhez.");
          } else if (result.error === ErrorMessages.PRODUCT_NOT_FOUND) {
            Toast.showToast("Rendelés leadása nem sikerült: Termék nem található.");
          } else if (result.error === ErrorMessages.CITY_NOT_FOUND) {
            Toast.showToast("A kiválasztott város egyelőre nem szerepel az adatbázisunkban :(");
          } else if (result.error === ErrorMessages.ZIPCODE_NOT_FOUND) {
            Toast.showToast("A kiválasztott irányítószám egyelőre nem szerepel az adatbázisunkban :(");
          } else if (result.error === ErrorMessages.CURRENT_PASSWORD_INVALID) {
            Toast.showToast("A megadott jelenlegi jelszó hibás");
          } else if (result.error === ErrorMessages.NEW_PASSWORD_TOO_SHORT) {
            Toast.showToast("A megadott új jelszó túl rövid.");
          } else if (result.error === ErrorMessages.NO_SHIPPING_TO_THIS_ADDRESS) {
            Toast.showToast("Sajnos a kiválasztott címre nem szállít az étterem 😥");
          } else if (result.error === ErrorMessages.MIXED_PRODUCTS) {
            Toast.showToast(Language.getName(Names.MixedProductsCartOrdered));
          } else if (result.error === ErrorMessages.ADDRESS_PERMISSION_DENIED) {
            //Toast.showToast("Az elmentett cím használatához nincs engedélyed!");
          } else if (result.error === ErrorMessages.ADDRESS_NOT_FOUND) {
            if (ContextSystem.loggedIn)
              Toast.showToast(Language.getName(Names.SavedAddressNotFound));
          } else if (result.error === ErrorMessages.ZIPCODE_NOT_FOUND) {
            Toast.showToast(Language.getName(Names.ZipCodeNotFound));
          } else if (result.error === ErrorMessages.ORDER_NOT_FOUND) {
            Toast.showToast(Language.getName(Names.OrderNumberNotFound));
          } else if (result.error === ErrorMessages.FIRSTNAME_NOT_VALID) {
            Toast.showToast(Language.getName(Names.GivenNameIsNotValid));
          } else if (result.error === ErrorMessages.LASTNAME_NOT_VALID) {
            Toast.showToast(Language.getName(Names.GivenNameIsNotValid));
          } else if (result.error === ErrorMessages.TEL_NOT_VALID) {
            Toast.showToast(Language.getName(Names.GivenPhoneNumberIsNotValid));
          } else if (result.error === ErrorMessages.RESTAURANT_NAME_NOT_VALID) {
            Toast.showToast(Language.getName(Names.RESTAURANT_NAME_NOT_VALID));
          } else if (result.error === ErrorMessages.CANT_DELETE_DATA_FOUND) {
            Toast.showToast(Language.getName(Names.CANT_DELETE_DATA_FOUND));
          } else if (result.error === ErrorMessages.NO_DELIVERY_AT_THIS_SHOP) {
            Toast.showToast(Language.getName(Names.NO_DELIVERY_AT_THIS_SHOP));
          } else if (result.error === ErrorMessages.NO_TAKEAWAY_AT_THIS_SHOP) {
            Toast.showToast(Language.getName(Names.NO_TAKEAWAY_AT_THIS_SHOP));
          } else if (result.error === ErrorMessages.TABLE_QR_NOT_FOUND) {
            Toast.showToast(Language.getName(Names.Table_QR_ID_NotFoundText));
          } else if (result.error === ErrorMessages.PRODUCT_SCHEDULE_NOT_AVAILABLE) {
            Toast.showToast(Language.getName(Names.PRODUCT_SCHEDULE_NOT_AVAILABLE));
          }
        }
        if (callback)
          callback(res);
      } catch (e) {
        Analytics.triggerGTMEvent("error", {error: e});
        console.log(e);
      }
    })
    .catch((reason) => {
      console.log(reason);
      if (!lastServerIsNotAvailableToast || Math.abs(new Date() - lastServerIsNotAvailableToast) >= 3500) {
        lastServerIsNotAvailableToast = new Date();
        Toast.showToast(Language.getName(Names.PleaseCheckYourInternetConnection));
      }
      if (callback) callback(JSON.stringify({error: ErrorMessages.SERVER_NOT_AVAILABLE}));
    });
}

// function ownFetch2(quite, method, url, params = {}) {
//   return new Promise((resolve, reject) => {
//     ownFetch(quite, method, url, params, (data) => {
//       let res = JSON.parse(data);
//       if (res.error) reject(ErrorMessages[res.error] || `Ismeretlen hibakód (${res.error})`);
//       else resolve(res);
//     });
//   });
// }

export default {
  ownFetch
};
